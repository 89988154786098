import React, { useEffect, useReducer, useRef } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import NotificationSystem from "react-notification-system";

EditModalComponent.propTypes = {};
EditModalComponent.defaultProps = {};

function EditModalComponent(props) {
	console.log('create:', props.dataEdit);
  const [infoInput, setInfoInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      pr_id: props.dataEdit?.pr_id || "",
      pr_csta_id: props.dataEdit?.pr_csta_id || "",
      pr_content: props.dataEdit?.pr_content || "",
      pr_forward_number: props.dataEdit?.pr_forward_number || "",
      pr_forward_yn: props.dataEdit?.pr_forward_yn || 0,
    }
  );
  const notificationSystem = useRef();
  const refCloseModal = useRef();

  useEffect(() => {
		console.log('start:', props.dataEdit);
    setInfoInput({
      pr_id: props.dataEdit?.pr_id || "",
      pr_csta_id: props.dataEdit?.pr_csta_id || "",
      pr_content: props.dataEdit?.pr_content || "",
      pr_forward_number: props.dataEdit?.pr_forward_number || "",
      pr_forward_yn: props.dataEdit?.pr_forward_yn || 0,
    });
  }, [props.dataEdit]);

  function handleClick() {
    const notification = notificationSystem.current;
    if (infoInput.pr_id === "") {
      notification.addNotification({
        title: (
          <FormattedMessage id="dashboard.Warning" defaultMessage="Warning!" />
        ),
        message: (
          <FormattedMessage
            id="dashboard.LineInfo.No.Required"
            defaultMessage="Line no is required!"
          />
        ),
        level: "warning",
      });
    } else {
      props.editModal(infoInput);
      refCloseModal.current.click();
    }
  }

  const handleChange = (evt) => {
    const name = evt.target.name;
    const newValue = evt.target.value;
		console.log('before:',infoInput);
    setInfoInput({ [name]: newValue });
		console.log('after:',infoInput);
  };

  const closeModal = () => {
    setInfoInput({
      pr_id: props.dataEdit?.pr_id || "",
      pr_csta_id: props.dataEdit?.pr_csta_id || "",
      pr_content: props.dataEdit?.pr_content || "",
      pr_forward_number: props.dataEdit?.pr_forward_number || "",
      pr_forward_yn: props.dataEdit?.pr_forward_yn || 0,
//      pr_id: "",
//      pr_csta_id: "",
//      pr_content: "",
//      pr_forward_number: "",
//      pr_forward_yn: 0,
    });
  };
  return (
    <div
      className="modal fade modal_add_preferences"
      id="modal_edit_information"
      tabIndex={-1}
      aria-labelledby="logoutModalLabel"
      aria-hidden="true"
      style={{ display: "none" }}
    >
      <NotificationSystem ref={notificationSystem} />
      <div className="modal-dialog modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              Update
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={closeModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="box_add_ppreferences_1">
              <div className="box_group_control">
                <div className="heading_title">
                  {props.type === "lineInfo" ? (
                    <FormattedMessage
                      id="dashboard.Line"
                      defaultMessage="Line #'"
                    />
                  ) : (
                    <FormattedMessage
                      id="dashboard.CounselorName"
                      defaultMessage="Counselor Name"
                    />
                  )}
                </div>
                <div className="group_control_in">
                  <input
                    type="text"
                    placeholder={
                      props.type === "lineInfo"
                        ? props.intl.formatMessage({
                            id: "dashboard.Line",
                            defaultMessage: "Line #",
                          })
                        : props.intl.formatMessage({
                            id: "dashboard.CounselorName",
                            defaultMessage: "Counselor Name",
                          })
                    }
                    name="pr_id"
                    value={infoInput.pr_id}
                    className="form_control"
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="box_group_control">
                <div className="heading_title">
                  {props.type === "lineInfo" ? (
                    <FormattedMessage
                      id="dashboard.LineText"
                      defaultMessage="Line Text"
                    />
                  ) : (
                    <FormattedMessage
                      id="dashboard.ModifiedName"
                      defaultMessage="Modified Name"
                    />
                  )}
                </div>
                <div className="group_control_in">
                  <input
                    type="text"
                    placeholder={
                      props.type === "lineInfo"
                        ? props.intl.formatMessage({
                            id: "dashboard.LineText",
                            defaultMessage: "Line Text",
                          })
                        : props.intl.formatMessage({
                            id: "dashboard.ModifiedName",
                            defaultMessage: "Modified Name",
                          })
                    }
                    className="form_control"
                    name="pr_content"
                    value={infoInput.pr_content}
                    onChange={handleChange}
                  />
                </div>

								<div className="box_group_control">
									<div className="heading_title">
										<FormattedMessage
											id="dashboard.Status.ForwardNumber"
											defaultMessage="Forward Number"
										/>
									</div>
									<div className="group_control_in">
										<input
											type="text"
											className="form_control"
											name="pr_forward_number"
											value={infoInput.pr_forward_number}
											onChange={handleChange}
										/>
									</div>
								</div>

								<div className="box_group_control">
									<div className="heading_title">
										<FormattedMessage
											id="dashboard.Status.Forward"
											defaultMessage="Forward"
										/>
									</div>
									<div className="group_control_in">
										<select className="form_control" onChange={handleChange} name="pr_forward_yn" value={infoInput.pr_forward_yn || 0}>
												<option value={1}>착신전환</option>
												<option value={0}>착신해제</option>                                      
										</select>
										<span className="item_dropdown" />
									</div>
								</div>

              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-outline-dark "
              data-dismiss="modal"
              aria-label="Close"
              ref={refCloseModal}
              onClick={closeModal}
            >
              <FormattedMessage
                id="dashboard.Cancel"
                defaultMessage="Cancel" />
            </button>
            <button
              type="button"
              className="btn btn-dark btn_add"
              onClick={handleClick}
            >
              <FormattedMessage
                id="dashboard.EditRow"
                defaultMessage="Edit Row"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default injectIntl(EditModalComponent);
