import {
    FETCH_MESSAGES,
    FETCH_MESSAGES_SUCCESS,
    FETCH_MESSAGES_FAIL,
} from './../type';
import api from './../../utils/api';

export const fetchMessages = () => {
    return {
        type: FETCH_MESSAGES
    }
};

export const fetchMessagesSucceed = data => {
    return {
        type: FETCH_MESSAGES_SUCCESS,
        payload: {
            data: data
        }
    }
};

export const fetchMessagesFailed = error => {
    return {
        type: FETCH_MESSAGES_FAIL,
        payload: {
            error: error
        }
    }
};

export const fetchMessagesAction = id => dispatch => {
    dispatch(fetchMessages());
    return api.textManager.fetchTextManager(id)
        .then(response => {
        dispatch(fetchMessagesSucceed(response.data));
        return Promise.resolve(response.data);
    })
        .catch(error => {
            dispatch(fetchMessagesFailed(error.response.data.errors.message));
            return Promise.reject(error.response)
        });
}