import {
    FETCH_MESSAGES,
    FETCH_MESSAGES_SUCCESS,
    FETCH_MESSAGES_FAIL
} from './../type';

const initialState = {
    data: [],
    loading: false,
    error: false
};

const messages = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_MESSAGES: {
            return {
                ...state,
                loading: true
            }
        }
        case FETCH_MESSAGES_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: action.payload.data
            }
        }
        case FETCH_MESSAGES_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        }
        default:
            return state;
    }
}

export default messages;
