import {
    FETCH_MESSAGES_DIRECT,
    FETCH_MESSAGES_DIRECT_SUCCESS,
    FETCH_MESSAGES_DIRECT_FAIL,

    CREATE_MESSAGES_DIRECT,
    CREATE_MESSAGES_DIRECT_SUCCESS,
    CREATE_MESSAGES_DIRECT_FAIL,

    UPDATE_MESSAGES_DIRECT,
    UPDATE_MESSAGES_DIRECT_SUCCESS,
    UPDATE_MESSAGES_DIRECT_FAIL
} from './../type';
import api from "../../utils/api";


export const fetchMessageDirect = () => {
    return {
        type: FETCH_MESSAGES_DIRECT
    }
};

export const fetchMessageDirectSucceed = data => {
    return {
        type: FETCH_MESSAGES_DIRECT_SUCCESS,
        payload: {
            data: data
        }
    }
};

export const fetchMessageDirectFailed = error => {
    return {
        type: FETCH_MESSAGES_DIRECT_FAIL,
        payload: {
            error: error
        }
    }
};


export const fetchMessageDirectAction = () => dispatch => {
    dispatch(fetchMessageDirect());
    return api.textManager.fetchDirect().then(response => {
        dispatch(fetchMessageDirectSucceed(response.data.data));
        return Promise.resolve(response.data.data);
    }).catch(error => {
        dispatch(fetchMessageDirectFailed(error.response.data.errors.message));
        return Promise.reject(error.response)
    })
}


export const createMessageDirect = () => {
    return {
        type: CREATE_MESSAGES_DIRECT
    }
};

export const createMessageDirectSucceed = data => {
    return {
        type: CREATE_MESSAGES_DIRECT_SUCCESS,
        payload: {
            data: data
        }
    }
};

export const createMessageDirectFailed = error => {
    return {
        type: CREATE_MESSAGES_DIRECT_FAIL,
        payload: {
            error: error
        }
    }
};


export const createMessageDirectAction = (data) => dispatch => {
    dispatch(createMessageDirect());
    return api.textManager.createDirectV2(data).then(response => {
        dispatch(createMessageDirectSucceed(response.data.data));
        return Promise.resolve(response.data.data);
    }).catch(error => {
        dispatch(createMessageDirectFailed(error.response.data.errors.message));
        return Promise.reject(error.response)
    })
}


export const updateMessageDirect = () => {
    return {
        type: UPDATE_MESSAGES_DIRECT
    }
};

export const updateMessageDirectSucceed = data => {
    return {
        type: UPDATE_MESSAGES_DIRECT_SUCCESS,
        payload: {
            data: data
        }
    }
};

export const updateMessageDirectFailed = error => {
    return {
        type: UPDATE_MESSAGES_DIRECT_FAIL,
        payload: {
            error: error
        }
    }
};


export const updateMessageDirectAction = (data) => dispatch => {
    dispatch(updateMessageDirect());
    return api.textManager.updateDirectV2(data).then(response => {
        dispatch(updateMessageDirectSucceed(response.data.data));
        return Promise.resolve(response.data.data);
    }).catch(error => {
        dispatch(updateMessageDirectFailed(error.response.data.errors.message));
        return Promise.reject(error.response)
    })
}
