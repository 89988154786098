import React from 'react'

class CommandButton extends React.Component {
    constructor(props) {
        super(props)
    }

    onClickExecute = (e,idDelete) => {
        if (this.props.isExecute) {
            this.props.onExecute();
        }
        e.stopPropagation();
    }

    render() {
        const {
            onExecute, icon, text, hint, color, button
        } = this.props
        return (
            <div
                className="btn btn-link"
                onClick={(e)=>this.onClickExecute(e)}
                title={hint}
            >
        <span className={color || 'undefined'}>
          { button ? (
              <button className={button}>
                  {icon ? (
                      <i
                          className={`fa fa-${icon}`}
                          style={{ marginRight: text ? 5 : 0 }}
                      />
                  ) : null}
                  {text}
              </button>
          ) : (
              <React.Fragment>
                  {icon ? (
                      <i
                          className={`fa fa-${icon} fa-2x`}
                          style={{ marginRight: text ? 5 : 0 }}
                      />
                  ) : null}
                  {text}
              </React.Fragment>
          )}
        </span>
            </div>
        )
    }
}

export default CommandButton