import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from "react-intl";
import { Helmet } from 'react-helmet';
import SmsSetting from './../SmsSetting';
import RichTextEditor from 'react-rte';
import Pagination from "react-js-pagination";
import NotificationSystem from 'react-notification-system';
import CreateTextManagerModal from './../../../components/SmsSetting/SMSMMS/TextManager/CreateTextManagerModal';
import UpdateTextManagerModal from './../../../components/SmsSetting/SMSMMS/TextManager/UpdateTextManagerModal';
import DeleteConfirmModal from '../../../components/DeleteConfirmModal/DeleteConfirmModal';
import TextareaAutosize from 'react-autosize-textarea';
import HasRole from "../../../utils/entrust/HasRole";
import { hasRole } from "../../../utils/entrust/entrust";
import progressBar from '../../../utils/progressBar';
import * as routerName from "../../../utils/routerName";
import api from '../../../utils/api';
import {renderMessage,removeItemInArray} from '../../../utils/helpers';
import { fetchCompaniesAction } from "../../../store/actions";
import * as webSocketApi from './../../../utils/websocketApi';
import * as apipostimageatalk from './../../../utils/apipostimageatalk';
import {
    fetchTextManagerAction,
    unmountTextManagerAction,
    createTextManagerAction,
    deleteTextManagerAction,
    deleteImageDetailAction,
    updateTextManagerAction,
} from "../../../store/actions/textManager";
import { fetchCompaniesCheckAuthAction } from "../../../store/actions/company";


class SmsTextManagement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: this.props.user.csta_id,
            password: this.props.user.csta_pwd,
            deviceObject: this.props.user.usr_tel,
            companies: [],
            currentCompany: null,
            infoSaleLotList: [],
            currentInfoSaleLot: null,
            textManagerList: [],
						saleLotConfig: [],
            currentTextManager: {},
            textManagerForm: {
                title: '',
                content: '',
                send_type: 0,
                status: 0,
                send_type: 0,
                mode: 0,
                duplicate: 0,
                week: [],
                start_hour: 0,
                start_min: 0,
                end_hour: 0,
                end_min: 0,
                currentImageBrowse: {},
                image_messages: [],
                removeImages: [],
            },
						week: [],
						weekAll: false,
            openModalCreateTextManager: false,
            openModalUpdateTextManager: false,
            isDeleteModalOpen: false,
            isDeleteConfirmModalOpen: false,

            deletingRow: null,
            notification: {
                allowHTML: false,
            },

            // Test Draft 2
            valueDraft: RichTextEditor.createEmptyValue(''),
            access_token: '',
            maxLength: 90,
						totalLength: 0,
            loading: false,
        };
    }

    notificationSystem = React.createRef();
    addNotification = (data) => {
        const notification = this.notificationSystem.current;
        notification.addNotification({
            message: data.message,
            title: data.title,
            level: data.level
        });
    };

    componentDidMount() {
        progressBar.start();
        this.props.onFetchCompanies().then(response => {
            this.setState({
                ...this.state,
                companies: response.data,
                //if user role is admin then not display select company and default currentCompany is company belong to admin
                currentCompany: hasRole(this.props.auth.user, 'admin') ? response.data[0] : null,
                infoSaleLotList: hasRole(this.props.auth.user, 'admin') ? response.data[0].info_sale_lots : [],
            }, () => {
                // console.log(this.state, 'after_didmount'); 
            });
            progressBar.done();
        }).catch(error => {
            progressBar.done();
        });

        webSocketApi.getAccessPermission(
            this.state.id,
            this.state.password,
            'wss://rtc.goodfone.co.kr:9807', // 2024-03-20 9807 -> 9850
            'https://rtc.goodfone.co.kr:9850', // 2024-03-20 9807 -> 9850
            this.csta_event_callback
        ).then(res => {
            this.addNotification({
                title: <FormattedMessage
                    id="dashboard.Succeed"
                    defaultMessage="Succeed!" />,
                message: <FormattedMessage
                    id="dashboard.TelephoneCounselling.ConnectToServerSucceed"
                    defaultMessage="Connect to server succeed!" />,
                level: 'success',
            })
        })
            .catch(error => {
                this.addNotification({
                    title: <FormattedMessage
                        id="dashboard.Error"
                        defaultMessage="Error!" />,
                    message: <FormattedMessage
                        id="dashboard.TelephoneCounselling.ConnectToServerFailed"
                        defaultMessage="Connect to server failed!" />,
                    level: 'error',
                })
                // progressBar.done();
            });
    };

    csta_event_callback = (event_type, dataObject) => {
        //this.trace_event_type(event_type);
        if (event_type == 'SIGN-IN') {
            console.log('event sign-in', dataObject);
            this.state.accessToken = dataObject.access_token;

            // Set Online when sign in
            webSocketApi.cstaSetDoNotDisturb(
                this.state.deviceObject,
                0,
                this.state.accessToken);
            webSocketApi.cstaMonitorStart(this.state.deviceObject, this.state.accessToken);
        } else if (event_type == 'SIGN_OFF') {
            console.log('event SIGN_OFF', dataObject);
            webSocketApi.cstaMonitorStop(this.state.deviceObject, this.state.accessToken);
        }
    };

		setTotalLength = (_byte) => {
				this.setState({
						...this.state,
						totalLength: _byte
				}, () => {
						
				})
		};

    selectCompanyHandler = (event) => {
        let { value } = event.target;
        if (value) {
            let company = JSON.parse(value);
            this.setState({
                ...this.state,
                currentCompany: company,
                infoSaleLotList: company.info_sale_lots,
                currentInfoSaleLot: null,
                textManagerList: [],
                currentTextManager: {}
            }, () => {
                console.log(this.state.infoSaleLotList, 'infoList');
            })
        } else {
            this.setState({
                ...this.state,
                currentCompany: null,
                infoSaleLotList: [],
                currentInfoSaleLot: null,
                textManagerList: [],
                currentTextManager: {},
            })
        }
    };

    selectInfoSaleLotHandler = (event) => {
        let { value } = event.target;
        if (value) {
            let infoSaleLot = JSON.parse(value);
            let params = {
                type: 'paginate',
            }                     
            this.getTextManagerList(infoSaleLot, params);
            
            // update rightnow saleLot selected
            this.setState({
                ...this.state,
                currentInfoSaleLot: infoSaleLot
            },() => {
								api.predefinesms.getConfigSaleLot(infoSaleLot.lots_id).then(response => {
									 this.setState({
											 ...this.state,
											 saleLotConfig: response.data.data.data
									 }, () => {
												console.log('saleLotConfig', this.state.saleLotConfig);
									 })
								}).catch(error => {        

								});		
						})

        } else {
            this.setState({
                ...this.state,
                currentInfoSaleLot: null,
                textManagerList: [],
                currentTextManager: {},
            })
        }
    };

    getTextManagerList = (infoSaleLot) => {
        progressBar.start();
        let params = {
            page: this.state.textManagerList?.current_page || 1, 
            type: 'paginate',
        }

        return this.props.onFetchTextManager(infoSaleLot.lots_id, params).then(response => {
            this.setState({
                ...this.state,
                lost_id: infoSaleLot.lots_id,
                textManagerList: response,

                currentInfoSaleLot: infoSaleLot
            });
            progressBar.done();
        }).catch(error => {
            progressBar.done();
        });
    }

    changePage = (pageNumber) => {
        this.setState({
            ...this.state,
            textManagerList: {
                ...this.state.textManagerList,
                current_page: pageNumber
            }
        }, () => {
            this.getTextManagerList(this.state.currentInfoSaleLot)
        })

        // let params = {
        //     type: 'paginate',
        //     page: pageNumber,
        // }
        // this.getTextManagerList(this.state.currentInfoSaleLot, params)
    }

    selectTextManager = (textManager) => {
        this.setState({
            // ...this.state,
            currentTextManager: textManager,
        }, () => {
            console.log(this.state.currentCompany, 'textManager_af');
        })
    }


    /**
     * CRUD Text Manager
     */
    handleChangeInputTextManager = (event) => {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            ...this.state,
            textManagerForm: {
                ...this.state.textManagerForm,
                [name]: value,
            }
        }, () => {

						console.log('target.name:',target.name);
						//console.log('target.value:',target.value);

						if (target.name == 'content') { // 길이에 따른

							const {maxlength} = this.state; 
							var _byte=0;
							var str= target.value;
							var charStr='';
							var finalStr='';

							for(var i=0;i<str.length;i++){
									charStr=str.charAt(i);
									if(escape(charStr).length>4){
											_byte+=2;
									}else{
											_byte++;
									}
									if (_byte >= 1999) break;
									else finalStr += charStr;
							}

							console.log('length:', _byte);

							//this.setTotalLength(_byte);

							if (_byte > 90 && this.state.textManagerForm.send_type !=1) { // LMS 변경 (MMS 가 아닐 경우)
								this.setState({
										...this.state,
										totalLength: _byte,
										textManagerForm: {
												...this.state.textManagerForm,
												content: finalStr,
												send_type: 2, // CHANGE TO LMS
										}
								});
							} else if (this.state.textManagerForm.send_type !=1) { //this.state.textManagerForm?.image_messages.length == 0) {
								this.setState({
										...this.state,
										totalLength: _byte,
										textManagerForm: {
												...this.state.textManagerForm,
												content: finalStr,
												send_type: 0, // CHANGE TO SMS
										}
								});
							}
						}/* else {
							if (this.state.textManagerForm?.image_messages.length>0) {
								this.setState({
										...this.state,
										textManagerForm: {
												...this.state.textManagerForm,
												send_type: 1, // CHANGE TO MMS
										}
								});
							} 
						}*/
            console.log(this.state.textManagerForm, 'after_form');
        });
    };

    editTextManager = (textManager) => {
        console.log(textManager, 'textManager_edit');
        textManager.removeImages = [];
				let week_array = textManager.week.split(',');
        this.setState({
            ...this.state,
						week: week_array,
						weekAll: false,
            textManagerForm: textManager,
        }, () => {
            this.toggleModalUpdateTextManager();
        });
    };

    onCreateTextManager = () => {
        progressBar.start();
        let { textManagerForm } = this.state;
        textManagerForm.lost_id = this.state.currentInfoSaleLot.lots_id;
        textManagerForm.data_api = textManagerForm.image_messages;
        console.log(textManagerForm, 'textManagerForm_data');

        this.props.createTextManager(textManagerForm).then(response => {
            this.toggleModalCreateTextManager();
            this.getTextManagerList(this.state.currentInfoSaleLot);  
            this.setState({
                ...this.state,
                notification: {
                    allowHTML: false,
                },
								week: [],
								weekAll: false,
                textManagerForm: {
										week: [],
                    title: '',
                    content: '',
                    send_type: 0,
                    status: 0,
                    currentImageBrowse: {},
                    image_messages: [],
                    removeImages: [],
                },
            }, () => {
                this.addNotification({
                    title: <FormattedMessage
                        id="dashboard.Succeed"
                        defaultMessage="Succeed!" />,
                    message: <FormattedMessage
                        id="dashboard.Counselor.CreateSucceed"
                        defaultMessage="Add new Counselor successfully!" />,
                    level: 'success',
                })
                progressBar.done();
            })

        })
            .catch(error => {
                if (error.data) {
                    this.setState({
                        ...this.state,
                        notification: {
                            allowHTML: true,
                        }
                    }, () => {
                        this.addNotification({
                            title: <FormattedMessage
                                id="dashboard.Error"
                                defaultMessage="Error !" />,
                            level: 'error',
                            message: renderMessage(error.data.errors.message),
                        })
                        progressBar.done();
                    })
                }
            });
    };

    onUpdateTextManager = () => {
        progressBar.start();
        let { textManagerForm } = this.state;
        textManagerForm.data_api = textManagerForm.image_messages;
        textManagerForm.lost_id = parseInt(textManagerForm.lost_id);
        console.log(textManagerForm, 'textManagerForm_data');
        
        this.props.updateTextManager(textManagerForm.mess_id, textManagerForm).then(response => {
            this.toggleModalUpdateTextManager();
            this.getTextManagerList(this.state.currentInfoSaleLot)
            this.selectTextManager(textManagerForm);  // re-fetch new value current saleLot selected

            this.setState({
                ...this.state,
                notification: {
                    allowHTML: false,
                },
                textManagerForm: {
                    title: '',
                    content: '',
                    send_type: 0,
                    status: 0,
                    currentImageBrowse: {},
                    image_messages: [],
                    removeImages: [],
                },
            }, () => {
                this.addNotification({
                    title: <FormattedMessage
                        id="dashboard.Succeed"
                        defaultMessage="Succeed!" />,
                    message: <FormattedMessage
                        id="dashboard.ItemValidate.UpdateSucceed"
                        defaultMessage="Update item successfully!" />,
                    level: 'success',
                })
                progressBar.done();
            });
        })
        .catch(error => {
            if (error.data) {
                this.setState({
                    ...this.state,
                    // textManagerForm: {
                    //     title: '',
                    //     content: '',
                    //     send_type: 0,
                    //     status: 0,
                    //     currentImageBrowse: {},
                    //     image_messages: [],
                    //     removeImages: [],

                    
                    //     lost_id: '',
                    // },

                    notification: {
                        allowHTML: true,
                    }
                }, () => {
                    this.addNotification({
                        title: <FormattedMessage
                            id="dashboard.Error"
                            defaultMessage="Error !" />,
                        level: 'error',
                        message: renderMessage(error.data.errors.message),
                    })
                    progressBar.done();
                })
            }
        });
    };

    setDeletingRow = (value) => {
        this.setState({
            ...this.state,
            deletingRow: value,
        }, () => {
            this.openDeleteModal();
        });
    };

		handleContents = (text) => {
        this.setState({
            ...this.state,
						textManagerForm: {
								...this.state.textManagerForm,
								content: text,
						}
        }, () => {

        });
		}

    deleteTextManagerHandler = () => {
        progressBar.start();
        this.props.deleteTextManager(this.state.deletingRow).then(response => {
            // check and re-set current page if delete last item of last page
            let {textManagerList} = this.state; 
            let current_page = textManagerList.from == textManagerList.to ? textManagerList.last_page - 1 : textManagerList.current_page;

            //check and update Text Manager after delete any item Text Manager
            let currentTextManager = this.state.currentTextManager.mess_id == this.state.deletingRow ? {} : this.state.currentTextManager;
            this.setState({
                ...this.state,
                currentTextManager,

                // deletingRow: null,
                isDeleteConfirmModalOpen: false,
                notification: {
                    allowHTML: false,
                },
                textManagerList: {
                    ...this.state.textManagerList,
                    current_page,
                }
            }, () => {
                this.getTextManagerList(this.state.currentInfoSaleLot);

                this.addNotification({
                    title: <FormattedMessage
                        id="dashboard.Succeed"
                        defaultMessage="Succeed!" />,
                    message: <FormattedMessage
                        id="dashboard.ItemValidate.DeleteSucceed"
                        defaultMessage="Delete item successfully!" />,
                    level: 'success',
                })

                progressBar.done();
            })

        }).catch(error => {
            progressBar.done();
        })
    };






    /**
     *  Modal Function
     */
    // Modal Company
    toggleModalCreateTextManager = () => {
        this.setState({
            ...this.state,            
            openModalCreateTextManager: !this.state.openModalCreateTextManager,
            textManagerForm: {
                title: '',
                content: '',
                send_type: 0,
                status: 0,
                currentImageBrowse: {},
                image_messages: [],
                removeImages: [],
            },
        }, () => {
            // console.log(this.state, 'state_after');
        });
    };

    toggleModalUpdateTextManager = () => {
        this.setState({
            ...this.state,
            openModalUpdateTextManager: !this.state.openModalUpdateTextManager,
        }, () => {
        });
    };

    openDeleteModal = () => {
        this.setState({
            isDeleteModalOpen: !this.state.isDeleteModalOpen
        });
    };

    openDeleteConfirmModal = () => {
        this.setState({
            isDeleteConfirmModalOpen: !this.state.isDeleteConfirmModalOpen
        });
    };




    /**
    *  process image Func
    */
    renderImageUpload = (images) => {
        return images.map((item, index) => {
            return (
                <div className="content-img" key={index}>
                    <img
                        src={item}
                        className="table-hover-cursor"
                    />
                </div>
            )
        })
    }

    renderMapImage = (images) => {
        if (Array.isArray(images)) {
            return images.map((item, index) => {
                return (
                    <div className="item_file" key={index}>
                        <div className="img">
                            <img src={item.image_path} />
                        </div>
                        {/* <div className="control_img">
                            <label htmlFor>{item.image_name}</label>
                        </div> */}
                    </div>
                )
            })
        }
    }

		setWeek = (week_string) => {

			this.setState({
					...this.state,
					textManagerForm: {
							...this.state.textManagerForm,
							week: week_string
					}
			})

		}

    handleBrowseImage = (event) => {
        let image = event.target.files[0];
        if (image) {
            this.setState({
                ...this.state,
                textManagerForm: {
                    ...this.state.textManagerForm,
                    currentImageBrowse: image,
										send_type: 1, // CHANGE TO MMS
                }
            })
        }
    }

    addImageAPICRM = () => {
        // var image_filter = images.filter(function (el) {
        //     return el != "";
        // });
        if (this.state.textManagerForm.image_messages.length <= 3) {
            console.log(this.state.textManagerForm.image_messages.length, 'leee');
            let {currentImageBrowse} = this.state.textManagerForm;
            if(currentImageBrowse){
                this.sendImageApiCRM(currentImageBrowse)
                // this.setState({
                //     loading : true,
                //     data : {
                //         ...this.state.data,
                //     },
                // })
            }
        } else {
            this.setState({
                ...this.state,
                notification: {
                    allowHTML: false,
                }
            }, () => {
                this.addNotification({
                    title: <FormattedMessage
                        id="dashboard.Error"
                        defaultMessage="Error !" />,
                    level: 'error',
                    message: renderMessage('Maximum 3 file'),
                })
                progressBar.done();
            })
        }
     
    }

    // sendImageCRMRecurs = (image) => {
    //     if(image) {
    //         this.sendImageApiCRM(image)
    //         this.sendImageCRMRecurs(i+1,images)
    //     }
    // }

    // ADD Recurs
    sendImageApiCRM = (image) => {
        apipostimageatalk.sendCrmSmsImage(image, this.props.apiToken).then(res => {
            this.setState({
                ...this.state,
                textManagerForm : {
                    ...this.state.textManagerForm,
                    image_messages: [
                        ...this.state.textManagerForm.image_messages,
                        {
                            image_name : res.file_name,
                            image_path : `https://rtc.goodfone.co.kr/mms/${res.file_name}`
                        }
                    ],
                    currentImageBrowse: {}
                },
            }, () => {
                console.log(this.state.textManagerForm.image_preview, 'imag_preccc');
            })
            // if(i+1 == images.length){
            //     this.setState({
            //         loading: false
            //     })
            // }
        })

        // let reader = new FileReader();
        // reader.onloadend = () => {
        //     this.setState({
        //         ...this.state,
        //         textManagerForm: {
        //             ...this.state.textManagerForm,
        //             image_preview: [...this.state.textManagerForm.image_preview, reader.result]
        //         }
        //     }, () => {
        //         console.log(this.state.textManagerForm.image_preview, 'image_preview_af');
        //     });
        // }
        // reader.readAsDataURL(images[i])

    }

    handleRemoveImage = (image) => {
      
        console.log(this.state.textManagerForm.removeImages, 'textManagerFormaaa');
        let {image_messages} = this.state.textManagerForm;
        console.log(image, 'image1');
        console.log(image_messages, 'image_messages1');

        let newData = image_messages.filter( ( item ) =>  {
            return item.image_name !== image.image_name;
        });

        this.setState({
            ...this.state,
            textManagerForm: {
                ...this.state.textManagerForm,
                image_messages: newData,
                removeImages: [
                    ...this.state.textManagerForm.removeImages,
                    image.image_id,
                ]
            }
        }, () => {
						// 이미지 ?
            console.log(this.state.textManagerForm.removeImages, 'removeImages');
        })
    }

    


    /**
    *  End process image Func
    */




    componentWillUnmount() {
        this.props.unmountTextManager();
    }


    handleWeekAll = (event) => {
        let { week } = this.state;
        let value = event.target.checked;
				week = [];
        if (value) {
						week = ['0','1','2','3','4','5','6'];
        }
        this.setState({
            ...this.state,
						week_all: value,
            week
        }, () => {
						this.setState({
								...this.state,
								textManagerForm: {
										...this.state.textManagerForm,
										week: week.toString()
								}
						});
        })
		}

    handleWeek = (event, item) => {
        let { week } = this.state;
        let value = event.target.checked;
        if (value) {
            if ( !week.includes(item) )
            week.push(item);
        } else {
            week = removeItemInArray(week, item)
        }
        this.setState({
            ...this.state,
            week,
        }, () => {
						this.setState({
								...this.state,
								textManagerForm: {
										...this.state.textManagerForm,
										week: week.toString()
								}
						});
        })
    }

    render() {
        const {
            companies,
            infoSaleLotList,
            textManagerList,
            currentCompany,
            currentInfoSaleLot,
            currentTextManager,
						saleLotConfig
        } = this.state;
        console.log(currentTextManager, 'currentTextManager2');

        return (
            <Fragment>
                <NotificationSystem ref={this.notificationSystem} allowHTML={this.state.notification.allowHTML} />


                <FormattedMessage id="dashboard.SmsSetting.TextManagement"
                    defaultMessage="Text Management">
                    {message => <Helmet>
                        <title>{message}</title>
                    </Helmet>}
                </FormattedMessage>

                <div className="main_top_bar static_bar">
                    <div className="box_sum_col_top">
                        <div className="box_left">
                            <ul className="nav nav_tabs">
                                <li className="nav_item">
                                    <span className="nav_link">
                                        <FormattedMessage id="dashboard.TextManagement.MainTitle" defaultMessage="Text Management!" />
                                        {/* <FormattedMessage id="dashboard.TextManagement.MainTitle" defaultMessage="Text Management!" /> */}
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div className="box_center">
                            <div className="tabs_static_item">
                                <div className="main_control_time_select">

                                    <HasRole role={['primarynet']}>
                                        <div className="main_select main_box">
                                            <div className="main_label">
                                                <FormattedMessage id="dashboard.Company" defaultMessage="Company" />
                                            </div>

                                            <div className="custom_select">
                                                {/* selectbox list company */}
                                                <select className="form_control" onChange={this.selectCompanyHandler} name="sltCompany">
                                                    <option value=''>
                                                        {this.props.intl.formatMessage({ id: 'dashboard.SelectCompany', defaultMessage: 'SelectCompany' })}
                                                    </option>
                                                    {
                                                        companies?.map((item, index) => {
                                                            return (
                                                                <option key={index} value={JSON.stringify(item)}>{item.company_name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                                <span className="item_dropdown" />
                                            </div>
                                        </div>
                                    </HasRole>

                                    <div className="main_select main_box">
                                        <div className="main_label">
                                            <FormattedMessage id="dashboard.AffiliatedCompany" defaultMessage="AffiliatedCompany" />
                                        </div>

                                        <div className="custom_select">
                                            {/* selectbox list info saleLot */}
                                            <select className="form_control" onChange={this.selectInfoSaleLotHandler} name="sltSaleLot" value={currentInfoSaleLot && JSON.stringify(currentInfoSaleLot) || '' }>
                                                <option value="">
                                                    {this.props.intl.formatMessage({ id: 'dashboard.SelectAffiliatedCompany', defaultMessage: 'SelectAffiliatedCompany' })}
                                                </option>
                                                {
                                                    infoSaleLotList?.map((item, index) => {
                                                        return (
                                                            <option key={index} value={JSON.stringify(item)}>{item.lots_name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            <span className="item_dropdown" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main_content">
                    <div className="content_tabs_main content_static_tabs tab-content">
                        <div className="tabs_static_item tabs_item1">
                            <div className="box_content_static">
                                <div className="left_col left_col_customer_care">
                                    <SmsSetting 
                                        location={this.props.location}
                                    />
                                </div>
                                <div className="content_col_nopading">
                                    <div className="list_control_box_preferences">
                                        <div className="content_left">
                                            <div className="content_column">
                                                <div className="column_box mw_50">
                                                    <div className="heading_box">
                                                        <h5 className="title_preferences">
                                                            <FormattedMessage id="dashboard.TextManagement.Consultant" defaultMessage="Title!" />
                                                        </h5>
                                                    </div>
                                                    <div className="control_box_item">
                                                        <div className="card_header">
                                                            <div className="box_search">
                                                            </div>
                                                            <div className="box_add">
                                                                <button type="button" className="btn btn_add" onClick={this.toggleModalCreateTextManager}
                                                                    disabled={currentCompany && currentInfoSaleLot ? '' : 'disabled'} >
                                                                    <i className="fas fa-plus" />
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="card_body">
                                                            <div className="custom_table">
                                                                <table className="table st_table_5">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>
                                                                                <FormattedMessage id="dashboard.TextManagement.Title" defaultMessage="Title!" />
                                                                            </th>
                                                                            <th>
                                                                                <FormattedMessage id="dashboard.TextManagement.Status" defaultMessage="Status!" />
                                                                            </th>
                                                                            <th />
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {/* render text manager list */}
                                                                    {
                                                                        textManagerList?.data?.length > 0 ?
                                                                            textManagerList.data.map((item, index) => {
                                                                                let active = this.state.currentTextManager?.mess_id == item.mess_id ? 'active' : '';
                                                                                return (
                                                                                    <tr key={index} className={active} onClick={() => this.selectTextManager(item)}>
                                                                                        <td>{item.title}</td>
                                                                                        <td>{item.status == 0 ? 'OFF' : 'ON'}</td>
                                                                                        <td className="inline_action">
                                                                                            <a onClick={() => this.editTextManager(item)}> <i className="fas fa-edit" /> </a>
                                                                                            <a onClick={() => this.setDeletingRow(item.mess_id)} ><i className="fas fa-trash-alt" /></a>
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            }) :
                                                                            (
                                                                                <tr>
                                                                                    <td colSpan={3}>
                                                                                        <div className="nodata">No Data</div>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                    }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        {
                                                            textManagerList?.data?.length > 0 && (
                                                                <div className="car_footer">
                                                                    <nav aria-label="...">
                                                                        <ul className="custom_pagination pagination">
                                                                            <Pagination
                                                                                itemClass="page-item"
                                                                                linkClass="page-link"
                                                                                activePage={textManagerList?.current_page}
                                                                                itemsCountPerPage={textManagerList?.per_page}
                                                                                totalItemsCount={textManagerList?.total || 0}
                                                                                pageRangeDisplayed={10}
                                                                                onChange={this.changePage}
                                                                            />
                                                                        </ul>
                                                                    </nav>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>

                                                {/* check to show more detail text manager  */}
                                                {
                                                    currentTextManager.mess_id && (
                                                        <div className="column_box mw_50 box_preferenc1"> 
                                                            <div className="heading_box">
                                                                <h5 className="title_preferences">
                                                                    <FormattedMessage id="dashboard.CompanyOverview" defaultMessage="CompanyOverview" />
                                                                </h5>
                                                            </div>
                                                            <div className="group_textarea">
                                                                <textarea
                                                                    value={currentTextManager.content}
                                                                    readOnly
                                                                />

                                                                {/* <TextareaAutosize
                                                                    className="form-control"
                                                                    rows={5}
                                                                    onChange={(e) => this.handleChangeTextArea(e)}
                                                                    value={currentTextManager.content}
                                                                    readOnly
                                                                >
                                                                </TextareaAutosize> */}
                                                            </div>
                                                            <div className="group_selection">
                                                                <div className="custom_select">
                                                                    <select className="form_control" value={currentTextManager.send_type} disabled>
                                                                        <option value={0}>SMS</option>
                                                                        <option value={1}>MMS</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            

                                                            {/* only show if send type = 1 (1: MMS, 0: SMS) */}
                                                            {
                                                                currentTextManager.send_type == 1 && (
                                                                    <div className="box_list_images">
                                                                        {this.renderMapImage(currentTextManager.image_messages)}
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    )
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>

                {/* Modal Component List  */}

                <CreateTextManagerModal
                    isOpen={this.state.openModalCreateTextManager}
                    toggle={this.toggleModalCreateTextManager}
                    currentTextManager={this.state.currentTextManager}
                    textManagerForm={this.state.textManagerForm}
										//week={this.state.week}
										setWeek={this.setWeek}
										totalLength={this.state.totalLength}
										saleLotConfig={this.state.saleLotConfig}
                    handleContents={this.handleContents}

                    // handleChangeEditor={this.handleChangeEditor}
                    handleChange={this.handleChangeInputTextManager}
                    handleBrowseImage={this.handleBrowseImage}
                    addImageAPICRM={this.addImageAPICRM}
                    handleRemoveImage={this.handleRemoveImage}
                    onCreateTextManager={this.onCreateTextManager}
                />

                <UpdateTextManagerModal
                    isOpen={this.state.openModalUpdateTextManager}
                    toggle={this.toggleModalUpdateTextManager}
                    currentTextManager={this.state.currentTextManager}
                    textManagerForm={this.state.textManagerForm}
										handleWeek={this.handleWeek}
										handleWeekAll={this.handleWeekAll}
										week={this.state.week}
										weekAll={this.state.weekAll}
										totalLength={this.state.totalLength}
										saleLotConfig={this.state.saleLotConfig}
                    handleContents={this.handleContents}

                    handleChange={this.handleChangeInputTextManager}
                    handleBrowseImage={this.handleBrowseImage}
                    addImageAPICRM={this.addImageAPICRM}
                    handleRemoveImage={this.handleRemoveImage}
                    onUpdateTextManager={this.onUpdateTextManager}
                />

                <DeleteConfirmModal
                    isDeleteModalOpen={this.state.isDeleteModalOpen}
                    openDeleteModal={this.openDeleteModal}
                    isDeleteConfirmModalOpen={this.state.isDeleteConfirmModalOpen}
                    openDeleteConfirmModal={this.openDeleteConfirmModal}
                    deleteInfoHandler={this.deleteTextManagerHandler}
                />

            </Fragment>
        )
    }
}



const mapStateToProps = state => {
    return {
        auth: state.auth,
        // textManager: state.textManager.data,
        user: state.auth.user
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchCompanies: () => dispatch(fetchCompaniesAction()),
        fetchCompaniesCheckAuth: () => dispatch(fetchCompaniesCheckAuthAction()),

        onFetchTextManager: (id, params) => dispatch(fetchTextManagerAction(id, params)),
        unmountTextManager: () => dispatch(unmountTextManagerAction()),
        createTextManager: (data) => dispatch(createTextManagerAction(data)),
        updateTextManager: (id, data) => dispatch(updateTextManagerAction(id, data)),

        deleteTextManager: (id) => dispatch(deleteTextManagerAction(id)),
        deleteImageDetail: (id) => dispatch(deleteImageDetailAction(id)),
    }
};


export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SmsTextManagement));
