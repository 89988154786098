import React from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import * as queryString from "querystring";
import querystring from "querystring";
import api from "../../utils/api";
import exportToExcel from './../../components/Excel/ExportToExcel';
import {
    renderStatusCall,
    parseParamArray
} from './../../utils/helpers';
import _ from 'lodash';

const FORMAT_DATE = 'YYYY-MM-DD';

class PopupTelephone extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            message: "",
            startDate : moment().startOf('month'),
            endDate :moment().endOf('month'),
            dataCounselors : [],
            dataCallOfDay : [],
        }
        this.onChangeHandler = this.onChangeHandler.bind(this);
    }

    componentDidMount () {

        // fetch call counselors
        this.__initDataCounselors();


        if (!window.opener) {
            window.close();
        }
    }

    __initDataCounselors = () => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        let {startDate,endDate} = this.state;
        let query = {
            start_date : moment(startDate).format(FORMAT_DATE),
            end_date : moment(endDate).format(FORMAT_DATE),
        }
        if(params.lots_id) {
            query['lots_id'] = params.lots_id;
        }
        query = querystring.stringify(query);
        api.exportV2.callCounselors(query).then(res => {
            this.setState({
                dataCounselors : res.data.data
            },()=>{
                api.exportV2.callOfDay(query).then(calls => {
                    this.setState({
                        dataCallOfDay : calls.data.data
                    })
                })
            })
        })
    }

    /**
     * changes the value of the input.
     *
     * @param {import("react").ChangeEvent<HTMLInputElement>} evt
     * @returns {void}
     */
    onChangeHandler (evt) {
        const {value} = evt.currentTarget;

        this.setState({message: value});

        window.opener.onSuccess(value)
    }

    apiCallExportExcel = ( startDate, endDate, call_check = [],nameExcel = 'excel' ) => { //call_check in array
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        let parse = parseParamArray(call_check,'call_check');
        let query = {
            start_date : moment(startDate).format(FORMAT_DATE),
            end_date : moment(endDate).format(FORMAT_DATE),
            ...parse
        }
        if(params.lots_id) query['lots_id'] = params.lots_id;

        query = querystring.stringify(query);
        return api.exportV2.outgoingCall(query).then(res => {
            let data = res.data.data.map(item =>{
                return {
                    '이름' : item.call_cust_name, // name
                    '휴대전화' : item.call_tel, // so dien thoai
                    '일반전화' : item.call_cust_tel,
                    '주제' : renderStatusCall(item.call_check), // chu de
                    '주소' : item.call_cust_address, // dia chi
                    '고객정보' :item.call_cust_info, // thong tin khach hang
                    '특기사항' : '', // hang muc dac biet
                    '등록일자' : item.call_date, // ngay dang ki
                    '상담사 ID' : item.user ? item.user. usr_code : '',
                    '상담사명' :  item.user ? item.user. usr_name : '',
                }
            });
            exportToExcel(data, nameExcel)
        })
    }

    //Lưu hình thức file excel của phần “발신목록 – 발신콜” danh sách cuộc gọi đi- call cuộc gọi đi của ngày tháng đã được chỉ định
    onClickOutgoingCall = () => {
        let {startDate,endDate} = this.state;
        let call_check =  [1];

        // startDate, endDate ,call_check , name excel
        this.apiCallExportExcel(
            startDate,
            endDate,
            call_check,
            '발신콜엑셀저장'
        );
    }

    //Lưu hình thức file excel trong phần “발신목록 – 부재중콜”  danh sách cuộc gọi đi- call đang vắng mặt của ngày tháng đã được chỉ định
    onClickExportExcelSecond = () => {
        let {startDate,endDate} = this.state;
        let call_check =  [4];
        this.apiCallExportExcel(
            startDate,
            endDate,
            call_check,
            '부재중엑셀저장'
        );
    }

    onClickExportExcelThird = () => {
        let {startDate,endDate} = this.state;
        let call_check =  [2];
        this.apiCallExportExcel(
            startDate,
            endDate,
            call_check,
            '부재콜엑셀저장'
        );
    }

    onChangeDate = (date,type) => {
        this.setState({
            [type] : date
        },()=>{
            // this.__initDataCounselors();
        })
    }

    exportExcelDataCounselors = () => {
        let {dataCounselors} = this.state;
        if(dataCounselors.length > 0 ){
            let data = dataCounselors.map((item,index) => {
                let sum = +item.call_check_1 + +item.call_check_2 + +item.call_check_4
                return {
                    'No' : (index + 1).toString(),
                    '이름' :item.user ? item.user.usr_name : '' ,
                    '발신콜' : item.call_check_1,
                    '부재중' : item.call_check_4,
                    '부재콜' : item.call_check_2,
                    '합계' : sum.toString()
                }
            })
            exportToExcel(data, 'counsellors')
        }
    }

    onClickChangeData = () => this.__initDataCounselors();

    exportExcelDataOfDay = () => {
        let {dataCallOfDay} = this.state;
        if(dataCallOfDay.length > 0 ){
            let data = dataCallOfDay.map((item,index) => {
                return {
                    '상담일자' : item.call_date_format,
                    '발신콜' : item.call_check_1,
                    '부재중' : item.call_check_2,
                    '부재콜' : item.call_check_4,
                    '합계' : +item.call_check_1 + +item.call_check_2 + +item.call_check_4,
                }
            })
            exportToExcel(data, 'data-of-day')
        }
    }

    render () {
        const {
            message,
            startDate,
            endDate,
            dataCounselors,
            dataCallOfDay,
        } = this.state;
        return (
            <React.Fragment>
                <section className="main_header_pop">
                    <div className="container-fluid">
                        <div className="box_left">
                            <div className="filler_to_from">
                                <div className="i_tem txt_form">상담일자</div>
                                <div className="i_tem box_control_input">
                                    <div className="box_control_date">
                                        <DatePicker
                                            className={'from_date form_control form-control-sm unstyled flatpickr-input'}
                                            popperClassName={'custom-width-poper'}
                                            selected={startDate}
                                            dateFormat="YYYY-MM-DD"
                                            onChange={(date)=>this.onChangeDate(date,'startDate')}
                                            startDate={startDate}
                                            endDate={endDate}
                                            selectsStart
                                            />
                                        <span className="icon_date" />
                                    </div>
                                </div>
                                <div className="i_tem txt_form hide">~</div>
                                <div className="i_tem box_control_input">
                                    <div className="box_control_date">
                                        <DatePicker
                                            className={'to_date form_control form-control-sm unstyled flatpickr-input'}
                                            popperClassName={'custom-width-poper'}
                                            selected={endDate}
                                            dateFormat="YYYY-MM-DD"
                                            onChange={(date)=>this.onChangeDate(date,'endDate')}
                                            startDate={startDate}
                                            endDate={endDate}
                                            selectsEnd
                                            minDate={startDate}
                                        />
                                        <span className="icon_date" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="box_right">
                            <div className="list_btn_control_pop">
                                <button
                                    type="button"
                                    className="btn_control btn_001"
                                    onClick={this.onClickChangeData}
                                >
                                    조회
                                </button>
                                <button
                                    type="button"
                                    className="btn_control  btn_001"
                                    onClick={()=>window.close()}
                                >
                                    종료</button>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="main_module1">
                    <div className="container-fluid">
                        <div className="box_row">
                            <div className="box_left_pop">
                                <div className="box_header">
                                    <div className="box_left">
                                        <label>고객명</label>
                                        <div className="list_btn_control_pop column">
                                            <button
                                                type="button"
                                                className="btn_control  btn_001"
                                                onClick={this.onClickOutgoingCall}
                                            >발신콜 엑셀 저장</button>
                                            <button
                                                type="button"
                                                className="btn_control  btn_001"
                                                //Lưu hình thức file excel trong phần “발신목록 – 부재중콜”  danh sách cuộc gọi đi- call đang vắng mặt của ngày tháng đã được chỉ định
                                                onClick={this.onClickExportExcelSecond}
                                            >
                                                부재중 엑셀 저장
                                            </button>
                                            <button
                                                type="button"
                                                className="btn_control  btn_001"
                                                //Lưu dưới hình thức file excel của phần “수신목록 – 금일부재콜” danh sách cuộc gọi đến- call cuộc gọi nhỡ trong nhỡ của ngày tháng đã được chỉ định
                                                onClick={this.onClickExportExcelThird}
                                            >부재콜 엑셀 저장</button>
                                        </div>
                                    </div>
                                    <div className="box_right">
                                        <div className="list_btn_control_pop">
                                            <button
                                                type="button"
                                                className="btn_control  btn_001"
                                                onClick={this.exportExcelDataCounselors}
                                            >
                                                엑셀저장
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="box_table">
                                    <table className="table">
                                        <thead>
                                        <tr><th width="5%">No</th>
                                            <th width="25%">이름</th>
                                            <th width="15%">발신콜</th>
                                            <th width="15%">부재중</th>
                                            <th width="15%">부재콜</th>
                                            <th width="15%">합계</th>
                                        </tr></thead>
                                        <tbody>
                                        {dataCounselors.map((item,index) => {
                                            let sum = +item.call_check_1 + +item.call_check_2 + +item.call_check_4;
                                            return(
                                                <tr key={index}>
                                                    <td>{index+1}</td>
                                                    <td>{item.user ? item.user.usr_name : '' }</td>
                                                    <td>{item.call_check_1}</td>
                                                    <td>{item.call_check_4}</td>
                                                    <td>{item.call_check_2}</td>
                                                    <td>{sum}</td>
                                                </tr>
                                            )
                                        })}

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="box_right_pop">
                                <div className="box_header">
                                    <div className="box_right">
                                        <div className="list_btn_control_pop">
                                            <button
                                                type="button"
                                                className="btn_control  btn_001"
                                                onClick={this.exportExcelDataOfDay}
                                            >엑셀저장</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="box_table">
                                    <table className="table">
                                        <thead>
                                            <th width="25%">상담일자</th>
                                            <th width="15%">발신콜</th>
                                            <th width="15%">부재중</th>
                                            <th width="15%">부재콜</th>
                                            <th width="15%">합계</th>
                                        </thead>
                                        <tbody>
                                        {dataCallOfDay.map((item,index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{item.call_date_format}</td>
                                                    <td>{item.call_check_1}</td>
                                                    <td>{item.call_check_2}</td>
                                                    <td>{item.call_check_4}</td>
                                                    <td>{+item.call_check_1 + +item.call_check_2 + +item.call_check_4}</td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>

        )
    }
}

export default PopupTelephone;