import React, { Fragment } from "react";
import Review from "../../assets/images/documentV2/user_registration/review.png";
import Custome from "../../assets/images/documentV2/user_registration/custome.png";
import Add from "../../assets/images/document/user_registration/add.png";
import Edit from "../../assets/images/document/user_registration/edit.png";
import Delete from "../../assets/images/document/user_registration/delete.png";
import Search from "../../assets/images/document/user_registration/search.png";

const UserRegistrationDoc = (props) => {
  return (
    <Fragment>
      <div className="row m-0">
        <div className="col-md-12 documentation-user-registration">
          <h2 className="mt-3 mb-4">사용자 등록</h2>
          <div className="row ml-0 pl-0">
            <div className="col-md-8 ml-0 pl-0">
              <img src={Review} width='100%'/>
            </div>
            <div className="col-md-4 pl-4 pr-0 ">
              <p>(1) 사용자가 소속된 업체 선택</p>
              <p>(2) 선택한 업체의 등록업체 표시</p>
              <p>(3) 선택한 등록업체의 상담원 리스트 표시</p>
              <p>
              (4) 상담원 검색 기능. 검색 조건 입력 후 (3)표에 검색 결과 표시
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row m-0">
        <div className="col-md-12 documentation-user-registration">
          <p className="mt-5"><b>상담원 계정 관리</b></p><br />
          <div className="row ml-0 pl-0">
            <div className="col-md-8 ml-0 pl-0">
              <img src={Custome} width='100%'/>
            </div>
            <div className="col-md-4 pl-4 pr-0 ">
              <p>(1) 상담원 추가 - 비밀번호: 기본 비밀번호는 "password"로 세팅되어 있 <br />으며, 비밀번호 변경 시 8자 이상만 입력 가능함</p>
              <p>(2) 상담원 수정</p>
              <p>(3) 상담원 삭제</p>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default UserRegistrationDoc;
