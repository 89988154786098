import React, { Fragment, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Helmet } from "react-helmet";
import { NavLink, Link } from "react-router-dom";
import * as routerName from "../../../utils/routerName";
import HeaderComponent from "../../../components/Statistics/DailyReport/HeaderComponent";
import SearchReportComponent from "../../../components/Statistics/DailyReport/SearchReportComponent";
import ResultReportComponent from "../../../components/Statistics/DailyReport/ResultReportComponent";
import moment from "moment/moment";
import PropTypes from "prop-types";
import progressBar from "../../../utils/progressBar";
import NotificationSystem from "react-notification-system";
import queryString from "query-string";
import {
  unmountCallListAction,
  unmountInfoMastAction,
  fetchInfoMastsAction,
  unmountInfoDetailAction,
} from "../../../store/actions";
import {
  createDailyReportNoteAction,
  fetchDailyReportAction,
  fetchNoteReportAction,
} from "../../../store/actions/infoMasts";
import api from "../../../utils/api";

DailyReport.propTypes = {};
function DailyReport(props) {
  const [inputName, setInputName] = useState({
    report1: "1. 상담건수 통계 자료 ",
    report2: "2. 청약순위 별 상담현황",
    report3: "3. 고객 반응도",
    report4: "4. 지역별 상담현황",
    report5: "5. 광고매체",
    note: "",
  });
  const [selectInfoMast, setSelectInfoMast] = useState({
    report1m: 8888,
    report2x: 0,
    report2y: 8888,
    report3x: 0,
    report3y: 8888,
    report4x: 9999,
    report4y: 8888,
    report5x: 0,
    report5y: 8888,
  });
  const [infoMastList, setInfoMastList] = useState([]);
  const [rep_id, setRepID] = useState(null);
  const [change_option, setChangeOption] = useState("e");
  const [date, setDate] = useState(moment().startOf("day"));
  const [tableReport1, setTableReport1] = useState(null);
  const [tableReport2, setTableReport2] = useState(null);
  const [tableReport3, setTableReport3] = useState(null);
  const [tableReport4, setTableReport4] = useState(null);
  const [tableReport5, setTableReport5] = useState(null);
  const [type_default, setTypeDefault] = useState(null);
  const [query, setQuery] = useState(null);
  const [searchType, setSearchType] = useState(false);
  const [isFirstTime, setIsFirstTime] = useState(true);

  const notificationSystem = useRef();

  function resetSelectInfoMast() {
    setSelectInfoMast({
      report1m: 8888,
      report2x: 0,
      report2y: 8888,
      report3x: 0,
      report3y: 8888,
      report4x: 9999,
      report4y: 8888,
      report5x: 0,
      report5y: 8888,
    })
  }

  useEffect(() => {
    progressBar.start();
    let dailyReports = localStorage.getItem("daily-report");
    if (dailyReports) {
      dailyReports = JSON.parse(dailyReports);
      if (dailyReports.hasOwnProperty(props.user.usr_id)) {
        let dailyReport = dailyReports[props.user.usr_id];
        setInputName(dailyReport.inputName);
        setSelectInfoMast(dailyReport.selectInfoMast);
      }
    }
    props.onFetchInfoMasts(props.sceneCompanyId).then((response) => {
      setInfoMastList(response);
      let data = {
        rep_date: date,
      }
      handleGetNoteReport(data, response);
    });
  }, []);

  useEffect(() => {
  }, [selectInfoMast])

  useEffect(() => {
    if(isFirstTime) setIsFirstTime(false);
    else {
      props.onFetchInfoMasts(props.sceneCompanyId).then((response) => {
        setInfoMastList(response);
        let data = {
          rep_date: date,
        }
        setTableReport1(null);
        setTableReport2(null);
        setTableReport3(null);
        setTableReport4(null);
        setTableReport5(null);
        handleGetNoteReport(data, response, false);
      });
    }
  }, [props.sceneCompanyId])

  function handleGetNoteReport(data, selectInfo, isInit = true, isChangeDate = false) {
    console.log(111111);
    props.getNoteReport(data).then((response) => {
      if (response.data != null) {
        setRepID(response.data ? response.data.rep_id : "");
        setTypeDefault(1);
        setInputName({
          ...inputName,
          report2: response.data.report2
            ? response.data.report2
            : "",
          report3: response.data.report3
            ? response.data.report3
            : "",
          report4: response.data.report4
            ? response.data.report4
            : "",
          report5: response.data.report5 ? response.data.report5 : "",
          note: response.data.rep_comment ? response.data.rep_comment : "",
        });
        setSelectInfoMast({
          report1m: response.data.report1m,
          report2x: response.data.report2x,
          report2y: response.data.report2y,
          report3x: response.data.report3x,
          report3y: response.data.report3y,
          report4x: response.data.report4x,
          report4y: 8888,
          report5x: response.data.report5x,
          report5y: 8888,
        });
        
      }else{
        setTypeDefault(0);
        setInputName({
          report1: "1. 상담건수 통계 자료 ",
          report2: "2. 청약순위 별 상담현황",
          report3: "3. 고객 반응도",
          report4: "4. 지역별 상담현황",
          report5: "5. 광고매체",
          note: "",
        });
        let select = {
          report1m: 8888,
          report2x: 0,
          report2y: 8888,
          report3x: 0,
          report3y: 8888,
          report4x: 9999,
          report4y: 8888,
          report5x: 0,
          report5y: 8888,
        }
        console.log(select);
        if (selectInfo.length > 0) {
          selectInfo.map((item, index) => {
            switch (item.m_name) {
              case '희망평형':
                select = {
                  ...select,
                  report1m: item.m_id,
                  report2x: item.m_id,
                  report3x: item.m_id,
                }
                break;
              case '청약순위':
                select = {
                  ...select,
                  report2y: item.m_id
                }
                break;
              case '호응도':
                select = {
                  ...select,
                  report3y: item.m_id
                }
                break;
              case '관리지역':
                select = {
                  ...select,
                  report4x: 9999
                }
                break;
              case '광고매체':
                select = {
                  ...select,
                  report5x: item.m_id
                }
                break;
              default:
                break;
            }
          });
        }
        setSelectInfoMast(select);
        }
      progressBar.done();
    });
  }

  function getDateReport(data) {
    setDate(data);
    let query = {
      rep_id: rep_id,
      rep_date: data,
    };
    handleGetNoteReport(query, infoMastList, true, true);
  }

  function getChangeOption(data) {
    setChangeOption(data);
  }

  function searchReport1(dataSearch) {
    // progressBar.start();
    const notification = notificationSystem.current;
    props
      .getDailyReport(dataSearch)
      .then((response1) => {
        if (response1.success == true) {
          setTableReport1(response1);
          
        } else {
          notification.addNotification({
            title: (
              <FormattedMessage id="dashboard.Error" defaultMessage="Error" />
            ),
            message: (
              <FormattedMessage
                id="dashboard.ErrorMessage"
                defaultMessage="문제가 발생하였습니다. 다시 시도해주세요."
              />
            ),
            level: "error",
          });
        }
      })
      .catch((err) => {
        // progressBar.done();
        notification.addNotification({
          title: (
            <FormattedMessage id="dashboard.Error" defaultMessage="Error" />
          ),
          message: (
            <FormattedMessage
              id="dashboard.ErrorMessage"
              defaultMessage="문제가 발생하였습니다. 다시 시도해주세요."
            />
          ),
          level: "error",
        });
      });
  }

  function searchReport2(dataSearch) {
    // progressBar.start();
    const notification = notificationSystem.current;
    props
      .getDailyReport(dataSearch)
      .then((response2) => {
        if (response2.success == true) {
          setTableReport2(response2);
          
        } else {
          notification.addNotification({
            title: (
              <FormattedMessage id="dashboard.Error" defaultMessage="Error" />
            ),
            message: (
              <FormattedMessage
                id="dashboard.ErrorMessage"
                defaultMessage="문제가 발생하였습니다. 다시 시도해주세요."
              />
            ),
            level: "error",
          });
        }
      })
      .catch((err) => {
        // progressBar.done();
        notification.addNotification({
          title: (
            <FormattedMessage id="dashboard.Error" defaultMessage="Error" />
          ),
          message: (
            <FormattedMessage
              id="dashboard.ErrorMessage"
              defaultMessage="문제가 발생하였습니다. 다시 시도해주세요."
            />
          ),
          level: "error",
        });
      });
  }

  function searchReport3(dataSearch) {
    // progressBar.start();
    const notification = notificationSystem.current;
    props
      .getDailyReport(dataSearch)
      .then((response3) => {
        if (response3.success == true) {
          setTableReport3(response3);
          
        } else {
          notification.addNotification({
            title: (
              <FormattedMessage id="dashboard.Error" defaultMessage="Error" />
            ),
            message: (
              <FormattedMessage
                id="dashboard.ErrorMessage"
                defaultMessage="문제가 발생하였습니다. 다시 시도해주세요."
              />
            ),
            level: "error",
          });
        }
      })
      .catch((err) => {
        // progressBar.done();
        notification.addNotification({
          title: (
            <FormattedMessage id="dashboard.Error" defaultMessage="Error" />
          ),
          message: (
            <FormattedMessage
              id="dashboard.ErrorMessage"
              defaultMessage="문제가 발생하였습니다. 다시 시도해주세요."
            />
          ),
          level: "error",
        });
      });
  }

  function searchReport4(dataSearch) {
    // progressBar.start();
    const notification = notificationSystem.current;
    props
      .getDailyReport(dataSearch)
      .then((response4) => {
        if (response4.success == true) {
          setTableReport4(response4);
          
        } else {
          notification.addNotification({
            title: (
              <FormattedMessage id="dashboard.Error" defaultMessage="Error" />
            ),
            message: (
              <FormattedMessage
                id="dashboard.ErrorMessage"
                defaultMessage="문제가 발생하였습니다. 다시 시도해주세요."
              />
            ),
            level: "error",
          });
        }
      })
      .catch((err) => {
        // progressBar.done();
        notification.addNotification({
          title: (
            <FormattedMessage id="dashboard.Error" defaultMessage="Error" />
          ),
          message: (
            <FormattedMessage
              id="dashboard.ErrorMessage"
              defaultMessage="문제가 발생하였습니다. 다시 시도해주세요."
            />
          ),
          level: "error",
        });
      });
  }

  function searchReport5(dataSearch) {
    // progressBar.start();
    const notification = notificationSystem.current;
    props
      .getDailyReport(dataSearch)
      .then((response5) => {
        if (response5.success == true) {
          setTableReport5(response5);
          // progressBar.done();
          setSearchType(false);
        } else {
          notification.addNotification({
            title: (
              <FormattedMessage id="dashboard.Error" defaultMessage="Error" />
            ),
            message: (
              <FormattedMessage
                id="dashboard.ErrorMessage"
                defaultMessage="문제가 발생하였습니다. 다시 시도해주세요."
              />
            ),
            level: "error",
          });
        }
      })
      .catch((err) => {
        // progressBar.done();
        notification.addNotification({
          title: (
            <FormattedMessage id="dashboard.Error" defaultMessage="Error" />
          ),
          message: (
            <FormattedMessage
              id="dashboard.ErrorMessage"
              defaultMessage="문제가 발생하였습니다. 다시 시도해주세요."
            />
          ),
          level: "error",
        });
      });
  }
  /**
   * search report
   * @param {*} data 
   */
  function dataSearch(data) {
    setSearchType(true);
    setTableReport1(null);
    setTableReport2(null);
    setTableReport3(null);
    setTableReport4(null);
    setTableReport5(null);
    setInputName(data.inputName);
    setSelectInfoMast(data.selectInfoMast);
    let dataSearch = {
      date: date.format("YYYY-MM-DD HH:mm"),
      change_option: change_option,
      ...data.inputName,
      ...data.selectInfoMast,
      missCall: data.missCall,
      deleteCall: data.deleteCall,
      type_default: data.type_default,
      calculation_standard: data.calculation_standard
    };
    if(props.sceneLostId) {
      dataSearch['lots_id'] = props.sceneLostId;
    }
    if(props.sceneCompanyId) {
      dataSearch['company_id'] = props.sceneCompanyId;
    }
    setQuery(dataSearch);
    dataSearch.reportRender = 1;
    searchReport1(dataSearch);
    dataSearch.reportRender = 2;
    searchReport2(dataSearch);
    dataSearch.reportRender = 3;
    searchReport3(dataSearch);
    dataSearch.reportRender = 4;
    searchReport4(dataSearch);
    dataSearch.reportRender = 5;
    searchReport5(dataSearch);
  }

  /**
   * save report
   * @param {*} data 
   */
  function dataSave(data) {
    setInputName(data.inputName);
    setSelectInfoMast(data.selectInfoMast);
    setTypeDefault(data.type_default);
    const notification = notificationSystem.current;
    progressBar.start();
    let dataSave = {
      rep_date: date.format("YYYY-MM-DD HH:mm:ss"),
      rep_comment: data.type_default == 1 ? data.inputName.note : "",
      rep_id: rep_id,
      ...data.inputName,
      ...data.selectInfoMast,
      type_default: data.type_default,
    };
    if (data.type_default == 0) {
      setInputName({
        ...inputName,
        note: "",
      })
    }
    delete dataSave.note;
    delete dataSave.report1;
    props
      .createDailyReportNote(dataSave)
      .then((response) => {
        console.log(response, 'create');
        if (response.data == 1) {
          setInputName({
            ...inputName,
            note: "",
          })
          let query = {
            rep_date: date,
          };
          handleGetNoteReport(query, infoMastList);
        }
        if (response.success == true) {
          progressBar.done();
          notification.addNotification({
            title: (
              <FormattedMessage
                id="dashboard.Succeed"
                defaultMessage="Succeed!"
              />
            ),
            message: (
              <FormattedMessage
                id="dashboard.DailyReport.Save"
                defaultMessage="Save note successfully !"
              />
            ),
            level: "success",
          });
        }
      })
      .catch((err) => {
        notification.addNotification({
          title: (
            <FormattedMessage id="dashboard.Error" defaultMessage="Error" />
          ),
          message: (
            <FormattedMessage
              id="dashboard.ErrorMessage"
              defaultMessage="문제가 발생하였습니다. 다시 시도해주세요."
            />
          ),
          level: "error",
        });
      });

      
  }

  function exportExcel() {
    progressBar.start();
    const notification = notificationSystem.current;
    let exportPr = {
      ...query,
      isExport: true,
    };
    setQuery(exportPr);
    let data = queryString.stringify(exportPr);
    props
      .getDailyReport(exportPr)
      .then((response) => {
        let token = window.localStorage.getItem("token");
        let url =
          process.env.NODE_ENV === "development"
            ? process.env.REACT_APP_API_URL_DEV
            : process.env.REACT_APP_API_URL_PROD;

        url = url + `/statistics/daily-report?${data}&token=` + token;
        window.location.href = url;
        progressBar.done();
      })
      .catch((err) => {
        notification.addNotification({
          title: (
            <FormattedMessage id="dashboard.Error" defaultMessage="Error" />
          ),
          message: (
            <FormattedMessage
              id="dashboard.ErrorMessage"
              defaultMessage="문제가 발생하였습니다. 다시 시도해주세요."
            />
          ),
          level: "error",
        });
        progressBar.done();
      });
  }

  function printReport() {
    let printContents = document.getElementById("daily-report-print").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    window.location.reload();

    // document.body.innerHTML = originalContents;
  }

  function showNotifySearchForm() {
    const notification = notificationSystem.current;
    notification.addNotification({
      title: <FormattedMessage id="dashboard.Error" defaultMessage="Error" />,
      message: (
        <FormattedMessage
          id="dashboard.ErrorMessageDuplicate"
          defaultMessage="중복된 항목을 선택하면 안됩니다."
        />
      ),
      level: "error",
    });
  }

  function defaultSave(data) {
    setTypeDefault(data);
  }

  return (
    <Fragment>
      <FormattedMessage
        id="dashboard.DailyReport"
        defaultMessage="Daily Report"
      >
        {(message) => (
          <Helmet>
            <title>{message}</title>
          </Helmet>
        )}
      </FormattedMessage>
      <NotificationSystem ref={notificationSystem} />
      <div className="main_top_bar static_bar">
        <div className="box_sum_col_top">
          <div className="box_left">
            <ul className="nav nav_tabs nav-tabs">
              <li className="nav_item nav-item">
                <NavLink
                  to={`/${routerName.DASHBOARD}/${routerName.STATISTICS}/${routerName.DAILY_REPORT}`}
                  className="nav_link nav-link"
                  activeClassName="active"
                >
                  <FormattedMessage
                    id="dashboard.DailyReport"
                    defaultMessage="Daily report"
                  />
                </NavLink>
              </li>
              <li className="nav_item nav-item">
                <NavLink
                  to={`/${routerName.DASHBOARD}/${routerName.STATISTICS}/${routerName.ANALYSIS}`}
                  className="nav_link nav-link"
                  activeClassName="active"
                >
                  <FormattedMessage
                    id="dashboard.Analysis"
                    defaultMessage="Analysis"
                  />
                </NavLink>
              </li>
              <li className="nav_item nav-item">
                <NavLink
                  to={`/${routerName.DASHBOARD}/${routerName.STATISTICS}/${routerName.GRAPH}`}
                  className="nav_link nav-link"
                  activeClassName="active"
                >
                  <FormattedMessage
                    id="dashboard.Graph"
                    defaultMessage="Graph"
                  />
                </NavLink>
              </li>
            </ul>
          </div>
          
          <HeaderComponent
            dateReport={getDateReport}
            changeOption={getChangeOption}
            query={query}
            exportExcel={exportExcel}
            printReport={printReport}
          />
        </div>
      </div>
      <div className="main_content">
        <div className="content_tabs_main content_static_tabs tab-content">
          <div className="tabs_static_item tabs_item1 tab-pane fade show  active ">
            <div className="box_content_static">
              <SearchReportComponent
                inputName={inputName}
                infoMastList={infoMastList}
                selectInfoMast={selectInfoMast}
                typeDefault={type_default}
                dataSearch={dataSearch}
                dataSave={dataSave}
                showNotifySearchForm={showNotifySearchForm}
                setDefaultChildren={defaultSave}
                company={props.sceneCompanyId}
                user={props.user}
              />
              <ResultReportComponent 
                searchType={searchType}
                tableReport1={tableReport1} 
                tableReport2={tableReport2} 
                tableReport3={tableReport3} 
                tableReport4={tableReport4} 
                tableReport5={tableReport5}
                searchData={selectInfoMast}
              />
            </div>
          </div>
          <div
            className="tabs_static_item tabs_item2 tab-pane fade"
            id="static2"
          >
            <div className="box_content_static">
              <div className="left_col">left</div>
              <div className="content_col">
                <div className="row_table_custom">
                  <div className="heading">
                    <div className="txt_heading">Report Analysis</div>
                  </div>
                  <div className="content">
                    <div className="control_box_item">
                      <div className="card_body">
                        <div className="custom_table">
                          <table className="table st_table_6">
                            <thead>
                              <tr>
                                <th scope="col" colSpan={1} rowSpan={1}>
                                  일자
                                </th>
                                <th scope="col" colSpan={1} rowSpan={1}>
                                  총
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>October-27</td>
                                <td>5</td>
                              </tr>
                              <tr>
                                <td>October-27</td>
                                <td>5</td>
                              </tr>
                              <tr>
                                <td>October-27</td>
                                <td>5</td>
                              </tr>
                              <tr>
                                <td>October-27</td>
                                <td>5</td>
                              </tr>
                              <tr>
                                <td>October-27</td>
                                <td>5</td>
                              </tr>
                              <tr>
                                <td>October-27</td>
                                <td>5</td>
                              </tr>
                              <tr>
                                <td>October-27</td>
                                <td>5</td>
                              </tr>
                              <tr>
                                <td>October-27</td>
                                <td>5</td>
                              </tr>
                              <tr>
                                <td>October-27</td>
                                <td>5</td>
                              </tr>
                              <tr>
                                <td>October-27</td>
                                <td>5</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="car_footer">
                        <nav aria-label="...">
                          <ul className="custom_pagination pagination">
                            <li className="page-item">
                              <a className="page-link" href="#">
                                <i className="fas fa-chevron-left" />
                              </a>
                            </li>
                            <li
                              className="page-item active"
                              aria-current="page"
                            >
                              <span className="page-link">1</span>
                            </li>
                            <li className="page-item">
                              <a className="page-link" href="#">
                                2
                              </a>
                            </li>
                            <li className="page-item">
                              <a className="page-link" href="#">
                                3
                              </a>
                            </li>
                            <li className="page-item">
                              <a className="page-link" href="#">
                                <i className="fas fa-chevron-right" />
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tabs_static_item tabs_item3 tab-pane fade">3</div>
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    sceneCompanyId: state.sceneSelect.company_id,
    sceneLostId: state.sceneSelect.lots_id,
    typeOfChange: state.sceneSelect.typeOfChange,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUnmountCallList: () => dispatch(unmountCallListAction()),
    onUnmountInfoMast: () => dispatch(unmountInfoMastAction()),
    onUnmountInfoDetail: () => dispatch(unmountInfoDetailAction()),
    onFetchInfoMasts: (id) => dispatch(fetchInfoMastsAction(id)),
    getNoteReport: (data) => dispatch(fetchNoteReportAction(data)),
    getDailyReport: (data) => dispatch(fetchDailyReportAction(data)),
    createDailyReportNote: (data) =>
      dispatch(createDailyReportNoteAction(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DailyReport);
