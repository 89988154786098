import React, { Fragment, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Helmet } from "react-helmet";
import DataSetting from "./../DataSetting";
import RealEstateAgent from "./.././../../components/DataSetting/MgmAdmin/RealEstateAgent";
import DatePicker from "react-datepicker";
import {
  fetchUsersManagerAction,
  fetchPlacementAction,
  deleteUserPlacementApprovedAction,
} from "./../../../store/actions/MgmAdmin/userManager";
import PropTypes from "prop-types";
import moment from "moment/moment";
import "react-datepicker/dist/react-datepicker.css";
import Pagination from "react-js-pagination";
import DeleteConfirmModal from "../../../components/DeleteConfirmModal/DeleteConfirmModal";
import NotificationSystem from "react-notification-system";
import progressBar from "../../../utils/progressBar";

RealEstateAgentContainer.propTypes = {
  props: PropTypes.object,
};

function RealEstateAgentContainer(props) {
  const [currentType, setCurrentType] = useState("type");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchWord, setSearchWord] = useState(null);
  const [places, setPlaces] = useState([]);
  const [placeID, setPlaceID] = useState();
  const [userManager, setUserManager] = useState([]);
  const [pagination, setPagination] = useState({
    activePage: 1,
    per_page: null,
    total: null,
  });

  const [deleteRowID, setDeleteRowID] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(null);
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] = useState(
    null
  );
  const notificationSystem = useRef();
  useEffect(() => {
    progressBar.start();
    let data = {
      page: 1,
    };
    getDataUserManager(data);
    props.placementApproved().then((res) => {
      setPlaces(res);
      progressBar.done();
    });
  }, []);

  /**
   * event change start date
   * @param {*} startDate
   */
  function changeStartDateHandler(startDate) {
    console.log(startDate, "startDate");
    setStartDate(startDate);
  }

  /**
   * event change end date
   * @param {*} startDate
   */
  function changeEndDateHandler(endDate) {
    setEndDate(endDate);
  }

  function renderPlaces(data) {
    if (data && data.length > 0) {
      return data.map((item, index) => {
        return (
          <option key={index} value={item.place_id}>
            {item.place_name}
          </option>
        );
      });
    }
  }

  function selectRowDelete(id) {
    setDeleteRowID(id);
    openDeleteModal();
  }
  /**
   *
   * modal delete first
   */
  function openDeleteModal() {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  }

  /**
   *
   * modal delete second
   */
  function openDeleteConfirmModal() {
    setIsDeleteConfirmModalOpen(!isDeleteConfirmModalOpen);
  }

  function deleteInfoHandler() {
    progressBar.start();
    const notification = notificationSystem.current;
    props.deleteUserPlacementApproved(deleteRowID).then((response) => {
      setIsDeleteConfirmModalOpen(null);
      if (response) {
        let data = {
          page: pagination.activePage,
        };
        notification.addNotification({
          title: (
            <FormattedMessage
              id="dashboard.Succeed"
              defaultMessage="Succeed!"
            />
          ),
          message: (
            <FormattedMessage
              id="dashboard.ItemValidate.DeleteSucceed"
              defaultMessage="Delete item successfully!"
            />
          ),
          level: "success",
        });
        getDataUserManager(data);
        progressBar.done();
      } else {
        notification.addNotification({
          title: (
            <FormattedMessage id="dashboard.Error" defaultMessage="Error!" />
          ),
          message: (
            <FormattedMessage
              id="dashboard.ErrorMessage"
              defaultMessage="Sorry! Something went wrong. Retry!"
            />
          ),
          level: "error",
        });
        progressBar.done();
      }
    });
  }

  function renderTableUserManager(data) {
    if (data && data.length > 0) {
      return data.map((item, index) => {
        return (
          <tr key={index}>
            <td>
              <div>{item.place_name}</div>
            </td>
            <td>
              <div>{item.usr_company}</div>
            </td>
            <td>
              <div>{item.usr_name}</div>
            </td>
            <td>
              <div>{item.usr_cellphone}</div>
            </td>
            <td>
              <div>{item.company_registration_no}</div>
            </td>
            <td>
              <div>{item.usr_email}</div>
            </td>
            <td className="inline_action">
              {item.link_id ? (
                <a
                  href="#"
                  className=""
                  onClick={() => selectRowDelete(item.link_id)}
                >
                  <i className="fas fa-trash-alt"></i>
                </a>
              ) : (
                ""
              )}
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan={7}>
            <div className="nodata">No Data</div>
          </td>
        </tr>
      );
    }
  }

  function getDataUserManager(data) {
    const notification = notificationSystem.current;
    props
      .usersManager(data.page)
      .then((response) => {
        setUserManager(response.data.data);
        setPagination({
          activePage: response.data.current_page,
          per_page: response.data.per_page,
          total: response.data.total,
        });
      })
      .catch((err) => {
        notification.addNotification({
          title: (
            <FormattedMessage id="dashboard.Error" defaultMessage="Error!" />
          ),
          message: (
            <FormattedMessage
              id="dashboard.ErrorMessage"
              defaultMessage="Sorry! Something went wrong. Retry!"
            />
          ),
          level: "error",
        });
      });
  }

  /**
   *
   * change page
   */
  function handlePageChange(pageNumber) {
    progressBar.start();
    setPagination({
      ...pagination,
      activePage: pageNumber,
    });
    let data = {
      page: pageNumber,
    };
    getDataUserManager(data);
    progressBar.done();
  }

  function handleChangeInput(key) {
    setSearchWord(key);
  }

  function handleSelectPlace(place_id) {
    setPlaceID(place_id);
  }

  function handleSearchPlace() {
    progressBar.start();
    const notification = notificationSystem.current;
    let place_id = placeID;
    let start_date = startDate ? startDate.format("YYYY-MM-DD HH:mm") : "";
    let end_date = endDate ? endDate.format("YYYY-MM-DD HH:mm") : "";
    let search_word = searchWord;
    props
      .usersManager(1, null, place_id, start_date, end_date, search_word)
      .then((response) => {
        console.log(response, 'response');
        setUserManager(response.data.data);
        setPagination({
          activePage: response.data.page,
          per_page: response.data.per_page,
          total: response.data.total,
        });
        progressBar.done();
      })
      .catch((error) => {
        notification.addNotification({
          title: (
            <FormattedMessage id="dashboard.Error" defaultMessage="Error!" />
          ),
          message: (
            <FormattedMessage
              id="dashboard.ErrorMessage"
              defaultMessage="Sorry! Something went wrong. Retry!"
            />
          ),
          level: "error",
        });
        progressBar.done();
      });
  }

  return (
    <Fragment>
      <FormattedMessage
        id="mgm.admin.real_estate_agent"
        defaultMessage="Real estate agent"
      >
        {(message) => (
          <Helmet>
            <title>{message}</title>
          </Helmet>
        )}
      </FormattedMessage>

      <div className="main_top_bar static_bar">
        <div className="box_sum_col_top">
          <div className="box_left">
            <ul className="nav nav_tabs">
              <li className="nav_item">
                <span className="nav_link">
                  <FormattedMessage
                    id="mgm.admin.mgm_management"
                    defaultMessage="MGM management"
                  />
                  /{" "}
                  <FormattedMessage
                    id="mgm.admin.real_estate_agent"
                    defaultMessage="Real estate agent"
                  />
                </span>
              </li>
            </ul>
          </div>
          <div className="box_center">
            <div className="tabs_static_item">
              <div className="main_control_time_select">
                <div className="main_select main_box">
                  <div className="main_label">
                    <FormattedMessage
                      id="mgm.admin.placement"
                      defaultMessage="Placement"
                    />
                  </div>
                  <div className="custom_select">
                    <select
                      className="form_control"
                      onChange={(e) => handleSelectPlace(e.target.value)}
                    >
                      <option value="">
                        {props.intl.formatMessage({
                          id: "dashboard.SelectCompany",
                          defaultMessage: "Select Company",
                        })}
                        {/* All */}
                      </option>
                      {renderPlaces(places)}
                    </select>
                    <span className="item_dropdown" />
                  </div>
                </div>
                <div className="filler_to_from">
                  <div className="i_tem txt_form">
                    <FormattedMessage
                      id="mgm.admin.period"
                      defaultMessage="Period"
                    />
                  </div>
                  <div className="i_tem box_control_input">
                    <div className="box_control_date">
                      <span className="icon_date" />
                      <div className="date_picker_custom">
                        <DatePicker
                          disabled={!currentType}
                          dateFormat={
                            currentType === "time"
                              ? "YYYY/MM/DD HH:mm"
                              : "YYYY/MM/DD"
                          }
                          selected={startDate}
                          showTimeSelect={currentType === "time"}
                          timeFormat="HH:mm"
                          timeIntervals={60}
                          timeCaption="Time"
                          autoComplete = 'off'
                          id="FromDatePicker"
                          name="FromDatePicker"
                          className="from_date form_control form-control-sm unstyled"
                          startDate={startDate}
                          endDate={endDate}
                          onChange={changeStartDateHandler}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="i_tem txt_form hide">~</div>
                  <div className="i_tem box_control_input">
                    <div className="box_control_date">
                      <span className="icon_date" />
                      <div className="date_picker_custom">
                        <DatePicker
                          disabled={!currentType}
                          dateFormat={
                            currentType === "time"
                              ? "YYYY/MM/DD HH:mm"
                              : "YYYY/MM/DD"
                          }
                          selected={endDate}
                          autoComplete = 'off'
                          showTimeSelect={currentType === "time"}
                          timeFormat="HH:mm"
                          timeIntervals={60}
                          timeCaption="Time"
                          id="FromDatePicker"
                          name="FromDatePicker"
                          className="from_date form_control form-control-sm unstyled"
                          startDate={endDate}
                          endDate={endDate}
                          onChange={changeEndDateHandler}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="search">
                    <input
                        name="searchWord"
                        type="text"
                        className="to_date form_control form-control-sm unstyled"
                        id="inputDate"
                        onChange={(e) => handleChangeInput(e.target.value)}
                        placeholder={props.intl.formatMessage({
                          id: "mgm.admin.search_word",
                          defaultMessage: "Search Word",
                        })}
                      />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="box_right right">
              <button
                className="btn btn_preference"
                onClick={handleSearchPlace}
              >
                <FormattedMessage
                  id="dashboard.mgm.Search"
                  defaultMessage="Search"
                />
              </button>
          </div>
        </div>
      </div>
      <div className="main_content">
        <div className="content_tabs_main content_static_tabs tab-content">
          <div className="tabs_static_item tabs_item1">
            <div className="box_content_static">
              <div className="left_col left_col_customer_care">
                <DataSetting location={props.location} />
              </div>
              <NotificationSystem ref={notificationSystem} />
              <div className="content_col_nopading">
                <div className="list_control_box_preferences">
                  <div className="content_left">
                    <div className="content_column">
                      <div className="column_box ">
                        <div className="control_box_item">
                          <div className="card_body">
                            <div className="custom_table">
                              <table className="table st_table_5">
                                <thead>
                                  <tr>
                                    <th>
                                      <FormattedMessage
                                        id="mgm.admin.place_name"
                                        defaultMessage="Placement"
                                      />
                                    </th>
                                    <th>
                                      <FormattedMessage
                                        id="mgm.admin.usr_company"
                                        defaultMessage="Company Name"
                                      />
                                    </th>
                                    <th>
                                      <FormattedMessage
                                        id="mgm.admin.usr_name"
                                        defaultMessage="Ceo Name"
                                      />
                                    </th>
                                    <th>
                                      <FormattedMessage
                                        id="mgm.admin.usr_cellphone"
                                        defaultMessage="Mobile phone"
                                      />
                                    </th>
                                    <th>
                                      <FormattedMessage
                                        id="mgm.admin.company_registration_no"
                                        defaultMessage="Business license"
                                      />
                                    </th>
                                    <th>
                                      <FormattedMessage
                                        id="mgm.admin.email"
                                        defaultMessage="Email"
                                      />
                                    </th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {renderTableUserManager(userManager)}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="car_footer">
                            <nav aria-label="...">
                              <ul className="custom_pagination pagination">
                                {userManager && userManager.length > 0 ? (
                                  <Pagination
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    activePage={
                                      pagination.activePage
                                        ? pagination.activePage
                                        : 1
                                    }
                                    itemsCountPerPage={pagination.per_page}
                                    totalItemsCount={pagination.total}
                                    pageRangeDisplayed={10}
                                    onChange={handlePageChange}
                                  />
                                ) : (
                                  ""
                                )}
                              </ul>
                            </nav>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DeleteConfirmModal
        isDeleteModalOpen={isDeleteModalOpen}
        openDeleteModal={openDeleteModal}
        isDeleteConfirmModalOpen={isDeleteConfirmModalOpen}
        openDeleteConfirmModal={openDeleteConfirmModal}
        deleteInfoHandler={deleteInfoHandler}
      />
    </Fragment>
  );
}

const mapStateToProps = (state) => {
  console.log(state, "state");
  return {
    users: state.usersManager,
  };
};

const mapDispatchToProps = (dispatch) => ({
  usersManager: (
    page,
    limit = null,
    place_id = null,
    start_date = null,
    end_date = null,
    search_word
  ) =>
    dispatch(
      fetchUsersManagerAction(
        page,
        limit,
        place_id,
        start_date,
        end_date,
        search_word
      )
    ),
  placementApproved: () => fetchPlacementAction(),
  deleteUserPlacementApproved: (id) => deleteUserPlacementApprovedAction(id),
});

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(RealEstateAgentContainer)
);
