import {
    FETCH_COMPANIES,
    FETCH_COMPANIES_SUCCEED,
    FETCH_COMPANIES_FAILED
} from '../actions/actionTypes';

const initialState = {
    data: null,
    loading: false,
    error: false
};

const companies = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_COMPANIES: {
            return {
                ...state,
                loading: true
            }
        }
        case FETCH_COMPANIES_SUCCEED: {
            return {
                ...state,
                loading: false,
                data: action.payload.data
            }
        }
        case FETCH_COMPANIES_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        }
        default:
            return state;
    }
};

export default companies;