// get lít tư vấn
export const FETCH_CALL_LIST = 'FETCH_CALL_LIST';
export const FETCH_CALL_LIST_SUCCEED = 'FETCH_CALL_LIST_SUCCEED';
export const FETCH_CALL_LIST_FAILED = 'FETCH_CALL_LIST_FAILED';
export const RESET_CALL_LIST_DATA = 'RESET_CALL_LIST_DATA';

// get id call
export const FETCH_CALL = 'FETCH_CALL';
export const FETCH_CALL_SUCCEED = 'FETCH_CALL_SUCCEED';
export const FETCH_CALL_FAILED = 'FETCH_CALL_FAILED';
export const EDIT_CALL_COLUMN = 'EDIT_CALL_COLUMN';
export const RESET_CALL = 'RESET_CALL';
export const FETCH_CALL_CREATE_NEW = 'FETCH_CALL_CREATE_NEW'; // là khi tạo cuộc gọi mới là fetch dữ liệu lại trong redux call
export const SET_CALL = 'SET_CALL';


//search key input name
export const FETCH_CALL_KEY_SEARCH =  'FETCH_CALL_KEY_SEARCH';
export const FETCH_CALL_KEY_SEARCH_SUCCESS =  'FETCH_CALL_KEY_SEARCH_SUCCESS';
export const FETCH_CALL_KEY_SEARCH_FAILED =  'FETCH_CALL_KEY_SEARCH_FAILED';

// search call by number
export const SEARCH_CALL_BY_NUMBER = 'SEARCH_CALL_BY_NUMBER';
export const SEARCH_CALL_BY_NUMBER_SUCCESS = 'SEARCH_CALL_BY_NUMBER_SUCCESS';
export const SEARCH_CALL_BY_NUMBER_FAIL = 'SEARCH_CALL_BY_NUMBER_FAIL';
export const CLEAR_SEARCH_CALL_BY_NUMBER = 'CLEAR_SEARCH_CALL_BY_NUMBER';

// report telephone

export const FETCH_REPORT_TELEPHONE = 'FETCH_REPORT_TELEPHONE';
export const FETCH_REPORT_TELEPHONE_SUCCESS = 'FETCH_REPORT_TELEPHONE_SUCCESS';
export const FETCH_REPORT_TELEPHONE_FAIL = 'FETCH_REPORT_TELEPHONE_FAIL';
export const RESET_REPORT_TELEPHONE = 'RESET_REPORT_TELEPHONE'

// fetch message

export const FETCH_MESSAGES  = 'FETCH_MESSAGES';
export const FETCH_MESSAGES_SUCCESS  = 'FETCH_MESSAGES_SUCCESS';
export const FETCH_MESSAGES_FAIL  = 'FETCH_MESSAGES_FAIL';

// fetch message direct for user

export const FETCH_MESSAGES_DIRECT = 'FETCH_MESSAGES_DIRECT';
export const FETCH_MESSAGES_DIRECT_SUCCESS = 'FETCH_MESSAGES_DIRECT_SUCCESS';
export const FETCH_MESSAGES_DIRECT_FAIL = 'FETCH_MESSAGES_DIRECT_FAIL';

export const CREATE_MESSAGES_DIRECT = 'CREATE_MESSAGES_DIRECT';
export const CREATE_MESSAGES_DIRECT_SUCCESS = 'CREATE_MESSAGES_DIRECT_SUCCESS';
export const CREATE_MESSAGES_DIRECT_FAIL = 'CREATE_MESSAGES_DIRECT_FAIL';

export const UPDATE_MESSAGES_DIRECT = 'UPDATE_MESSAGES_DIRECT';
export const UPDATE_MESSAGES_DIRECT_SUCCESS = 'UPDATE_MESSAGES_DIRECT_SUCCESS';
export const UPDATE_MESSAGES_DIRECT_FAIL = 'UPDATE_MESSAGES_DIRECT_FAIL';

export const CHANGE_SCENE_COMPANY = 'CHANGE_SCENE_COMPANY';
export const CHANGE_SCENE_LOT = 'CHANGE_SCENE_LOT';
export const CHANGE_SCENE = 'CHANGE_SCENE';
export const CHANGE_TYPE_OF_CHANGE = 'CHANGE_TYPE_OF_CHANGE';