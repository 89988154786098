import {
    FETCH_REJECTLIST,
    FETCH_REJECTLIST_SUCCEED,
    FETCH_REJECTLIST_FAILED,
    UNMOUNT_REJECTLIST,

} from './actionTypes';
import api from '../../utils/api';
//RejectList
export const fetchRejectList = () => {
    return {
        type: FETCH_REJECTLIST,
    }
};

export const fetchRejectListSucceed = data => {
    return {
        type: FETCH_REJECTLIST_SUCCEED,
        payload: {
            data: data
        }
    }
};

export const fetchRejectListFailed = error => {
    return {
        type: FETCH_REJECTLIST_FAILED,
        payload: {
            error: error
        }
    }
};

export const unmountRejectList = () => {
    return {
        type: UNMOUNT_REJECTLIST
    }
};
/**
 * Fetch RejectList
 */
export const fetchRejectListAction = (query) => dispatch => {
    dispatch(fetchRejectList());
    return api.rejectList.getRejectList(query)
        .then(response => {
            dispatch(fetchRejectListSucceed(response.data.data));
            return Promise.resolve(response.data);
        })
        .catch(error => {
            dispatch(fetchRejectListFailed(error.response.data.errors.message));
            return Promise.reject(error.response)
        });
};

export const unmountRejectListAction = () => dispatch => {
    dispatch(unmountRejectList())
};


