import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Helmet } from 'react-helmet';
import { NavLink, Link } from "react-router-dom";
import { Modal, ModalHeader, ModalFooter, ModalBody, Button } from 'reactstrap';
import RichTextEditor from 'react-rte';
import './CustomerEditer.css';

class CreateSaleLotCompanyModal extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { currentCompany, saleLotForm } = this.props;
        let lots_desc = saleLotForm && saleLotForm.lots_desc ? saleLotForm.lots_desc : '';

        return (
            <Fragment>
                <Modal
                    isOpen={this.props.isOpen}
                    toggle={this.props.toggle}
                    modalClassName='modal_add_preferences'
                    className="modal-dialog modal-dialog-scrollable"
                    contentClassName="modal-content"
                >
                    <div className="modal-header">
                        <h5 className="modal-title">
                            <FormattedMessage id="dashboard.Common.New" defaultMessage="New" />
                        </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.props.toggle}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>

                    <div className="modal-body">
                        <div className="box_add_ppreferences_1">
                            {/* <div className="box_group_control">
                                <div className="heading_title">Company</div>
                                <div className="group_control_in">
                                    <input
                                        type="text"
                                        className="form_control"
                                        name="company_name"
                                        value={currentCompany?.company_name}
                                        onChange={this.props.handleChange}
                                        readOnly
                                    />
                                </div>
                            </div> */}

                            <div className="box_group_control">
                                <div className="heading_title">
                                    <FormattedMessage id="dashboard.Name" defaultMessage="Name" />
                                </div>
                                <div className="group_control_in">
                                    <input
                                        type="text"
                                        className="form_control"
                                        name="lots_name"
                                        value={saleLotForm?.lots_name || ''}
                                        onChange={this.props.handleChange}
                                    />
                                </div>
                            </div>
                            <div className="box_group_control">
                                <div className="heading_title">
                                    ACD
                                </div>
                                <div className="group_control_in">
                                    <select
                                        name="acd" className={'form-control'}
                                        value={saleLotForm?.acd || 0}
                                        onChange={this.props.handleChange}
                                    >
                                        <option value="0">Hunt</option>
                                        <option value="1">ACD</option>
                                    </select>
                                </div>
                            </div>

                            <div className="box_group_control">
                                <div className="heading_title">
                                    회사코드
                                </div>
                                <div className="group_control_in">
                                    <input
                                        type="text"
                                        className="form_control"
                                        name="company_code" className={'form-control'}
                                        value={saleLotForm?.company_code || ''}
                                        onChange={this.props.handleChange}
                                    />
                                </div>
                            </div>

                            <div className="box_group_control">
                                <div className="heading_title">
                                    무료 수신거부 번호
                                </div>
                                <div className="group_control_in">
                                    <input
                                        type="text"
                                        className="form_control"
                                        name="reject_number" className={'form-control'}
                                        value={saleLotForm?.reject_number || ''}
                                        onChange={this.props.handleChange}
                                    />
                                </div>
                            </div>

                            <div className="box_group_control">
                                <div className="heading_title">
                                    대표 발신번호(SMS/LMS/MMS)
                                </div>
                                <div className="group_control_in">
                                    <input
                                        type="text"
                                        className="form_control"
                                        name="send_number" className={'form-control'}
                                        value={saleLotForm?.send_number || ''}
                                        onChange={this.props.handleChange}
                                    />
                                </div>
                            </div>

                            <div className="box_group_control">
                                <div className="heading_title">
                                    <FormattedMessage id="dashboard.CompanyOverview" defaultMessage="CompanyOverview" />
                                </div>
                                <div className="group_control_in">
                                    <RichTextEditor
                                        value={this.props.valueDraft}
                                        onChange={this.props.handleChangeEditor}
                                    /> 
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal-footer">
                        <button onClick={this.props.toggle}
                            type="button"
                            className="btn btn-outline-dark "
                            data-dismiss="modal"
                            aria-label="Close">
                            <FormattedMessage
                                id="dashboard.Cancel"
                                defaultMessage="Cancel" />
                        </button>
                        <button onClick={this.props.onCreateSaleLot}
                            type="button"
                            className="btn btn-dark btn_add"
                            data-dismiss="modal"
                            aria-label="Close">
                            <FormattedMessage
                                id="dashboard.Save"
                                defaultMessage="Save" />
                        </button>
                    </div>
                </Modal>

            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateSaleLotCompanyModal)
