import React, { Fragment, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Helmet } from "react-helmet";
import SmsSetting from "./../SmsSetting";
import PropTypes from "prop-types";
import {
  fetchCompaniesAction,
  fetchCompaniesCheckAuthAction,
} from "../../../store/actions/company";
import { updateSaleLotsAction } from "../../../store/actions/saleLots";
import NotificationSystem from "react-notification-system";
import TextOnOfComponent from "../../../components/SmsSetting/SMSMMS/TextOnOf/TextOnOfComponent";
import progressBar from "../../../utils/progressBar";

TextOnOff.propTypes = {
  props: PropTypes.object,
};
// TextOnOff.defaultProps = {
// 	props: {}
// };

function TextOnOff(props) {
  const [companies, setCompanies] = useState([]);
  const [currentCompany, setCurrentCompany] = useState([]);
  const [infoSaleLotList, setInfoSaleLotList] = useState([]);
  const [curentInfoSaleLot, setCurentInfoSaleLot] = useState([]);

  const notificationSystem = useRef();

  useEffect(() => {
    onLoadAffiliatedCompany();
  }, []);

  function onLoadAffiliatedCompany() {
    const notification = notificationSystem.current;
    props
      .onFetchCompanies()
      .then((response) => {
        setCompanies(response.data);
      })
      .catch((error) => {
        notification.addNotification({
          title: (
            <FormattedMessage id="dashboard.Error" defaultMessage="Error" />
          ),
          message: (
            <FormattedMessage
              id="dashboard.ErrorMessage"
              defaultMessage="Sorry! Something went wrong. Retry!"
            />
          ),
          level: "error",
        });
      });
  }

  function renderCompany(data) {
    if (data && data.length > 0) {
      return data.map((item, index) => {
        return (
          <option key={index} value={item.company_id}>
            {item.company_name}
          </option>
        );
      });
    }
  }

  function selectCompanyHandler(id) {
    if (id === "" || id == null) {
      setCurrentCompany([]);
      setInfoSaleLotList([]);
      return;
    }
    let data;
    companies.map((item) => {
      if (item.company_id == id) {
        data = item;
      }
    });
    setCurrentCompany(data);
    setInfoSaleLotList(data.info_sale_lots);
  }

  function showModalEdit(data) {
    setCurentInfoSaleLot(data);
  }

  function renderUser(data) {
    if (data && data.length > 0) {
      return data.map((item, index) => {
        return (
          <tr key={index}>
            <td>
              <div>{item.lots_name}</div>
            </td>
            <td>
              <div>
                {item.status_text == 0 ? (
                  <span className="badge badge-secondary"> OFF </span>
                ) : (
                  <span className="badge badge-info">On </span>
                )}
              </div>
            </td>
            <td className="inline_action">
              <a
                className=""
                type="button"
                className="btn btn_add"
                data-target="#modal_edit_salelots"
                data-toggle="modal"
                onClick={() => showModalEdit(item)}
              >
                <i className="fas fa-edit"></i>
              </a>
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan={3}>
            <div className="nodata">No Data</div>
          </td>
        </tr>
      );
    }
  }

  const findIndexAnswer = (answers, lots_id) => {
    var result = -1;
    answers.forEach((answer, index) => {
      if (answer.lots_id == lots_id) {
        result = index;
      }
    });
    return result;
  };

  function changeStatus(status) {
    progressBar.start();
    const notification = notificationSystem.current;
    let lots_id = curentInfoSaleLot.lots_id;
    let status_text = {
      status_text: status,
      lots_name: curentInfoSaleLot.lots_name
    };
    // console.log(curentInfoSaleLot, 'curentInfoSaleLot');
    props
      .onUpdateSaleLots(lots_id, status_text)
      .then((response) => {
        onLoadAffiliatedCompany();
        let index = findIndexAnswer(infoSaleLotList, lots_id);
        infoSaleLotList[index] = response.data;
        setInfoSaleLotList(infoSaleLotList);
        progressBar.done();
        notification.addNotification({
          title: (
            <FormattedMessage
              id="dashboard.Succeed"
              defaultMessage="Succeed!"
            />
          ),
          message: (
            <FormattedMessage
              id="dashboard.AffCompanyValidate.UpdateSucceed"
              defaultMessage="Update affiliated company successfully!"
            />
          ),
          level: "success",
        });
      })
      .catch((error) => {
        progressBar.done();
        notification.addNotification({
          title: (
            <FormattedMessage id="dashboard.Error" defaultMessage="Error!" />
          ),
          message: (
            <FormattedMessage
              id="dashboard.ErrorMessage"
              defaultMessage="Sorry! Something went wrong. Retry!"
            />
          ),
          level: "error",
        });
      });
  }
  return (
    <Fragment>
      <FormattedMessage
        id="dashboard.SmsSetting.TextOnOff"
        defaultMessage="Text On/off"
      >
        {(message) => (
          <Helmet>
            <title>{message}</title>
          </Helmet>
        )}
      </FormattedMessage>

      <div className="main_top_bar static_bar">
        <div className="box_sum_col_top">
          <div className="box_left">
            <ul className="nav nav_tabs">
              <li className="nav_item">
                <span className="nav_link">
                  <FormattedMessage
                    id="dashboard.Sms"
                    defaultMessage="SMS/MMS"
                  />
                  /{" "}
                  <FormattedMessage
                    id="dashboard.SmsSetting.TextOnOff"
                    defaultMessage="Text On/Off"
                  />
                </span>
              </li>
            </ul>
          </div>
          <div className="box_center">
            <div className="tabs_static_item">
              <div className="main_control_time_select">
                <div className="main_select main_box">
                  <div className="main_label">
                    <FormattedMessage
                      id="dashboard.Company"
                      defaultMessage="Company"
                    />
                  </div>
                  <div className="custom_select">
                    <select
                      className="form_control"
                      value={
                        currentCompany.company_id
                          ? currentCompany.company_id
                          : ""
                      }
                      onChange={(e) => selectCompanyHandler(e.target.value)}
                    >
                      <option value="">
                        {props.intl.formatMessage({
                          id: "dashboard.SelectCompany",
                          defaultMessage: "Select Company",
                        })}
                      </option>
                      {renderCompany(companies)}
                    </select>
                    <span className="item_dropdown" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main_content">
        <div className="content_tabs_main content_static_tabs tab-content">
          <div className="tabs_static_item tabs_item1">
            <div className="box_content_static">
              <div className="left_col left_col_customer_care">
                <SmsSetting location={props.location} />
              </div>
              <NotificationSystem ref={notificationSystem} />
              <div className="content_col_nopading">
                <div className="list_control_box_preferences">
                  <div className="content_left">
                    <div className="content_column">
                      <div className="column_box ">
                        <div className="control_box_item">
                          <div className="card_body">
                            <div className="custom_table">
                              <table className="table st_table_5">
                                <thead>
                                  <tr>
                                    <th>
                                      <FormattedMessage
                                        id="mgm.admin.registered_placement"
                                        defaultMessage="Text On/Off"
                                      />
                                    </th>
                                    <th>
                                      <FormattedMessage
                                        id="dashboard.SmsSetting.TextOnOff"
                                        defaultMessage="Text On/Off"
                                      />
                                    </th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>{renderUser(infoSaleLotList)}</tbody>
                              </table>
                            </div>
                          </div>
                          <div className="car_footer">
                            <nav aria-label="...">
                              <ul className="custom_pagination pagination"></ul>
                            </nav>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TextOnOfComponent
        changeStatus={changeStatus}
        statusText={curentInfoSaleLot.status_text}
      />
    </Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchCompanies: () => dispatch(fetchCompaniesAction()),
    fetchCompaniesCheckAuth: () => dispatch(fetchCompaniesCheckAuthAction()),
    onUpdateSaleLots: (id, saleLots) =>
      dispatch(updateSaleLotsAction(id, saleLots)),
  };
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(TextOnOff)
);
