import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Helmet } from 'react-helmet';
import { NavLink, Link } from "react-router-dom";
import * as routerName from "../../../utils/routerName";
import DataSetting from './../DataSetting';
import moment from "moment/moment";
import queryString from 'query-string';
import DatePicker from 'react-datepicker';
import NotificationSystem from 'react-notification-system';
import {Card, Button} from 'reactstrap';
import progressBar from '../../../utils/progressBar';
import Pagination from "react-js-pagination";
import api from "../../../utils/api";
import "react-datepicker/dist/react-datepicker.css";
import {
    // fetchCallHistoryListAction,
    // unmountCallListAction
} from "../../../store/actions";

class CallHistory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            historyList: {
                current_page: 1,
                total: 0,
            },
            currentType: 'day',
            // startDate: moment().subtract(377, 'days').startOf('day'),
            startDate: moment().startOf('day'),
            endDate: moment(),

            // Notification
            notification: {        
                allowHTML: false,
            },
        }
    }

    notificationSystem = React.createRef();
    addNotification = (data) => {
      const notification = this.notificationSystem.current;
      notification.addNotification({
        message: data.message,
        title: data.title,
        level: data.level,
      });
    };

    componentDidMount() {
        this.getCallHistory();
    }

    selectHistoryTypeHandler = (event) => {
        let { value } = event.target;
        this.setState({           
            currentType: value || null,
            historyList: {
                current_page: 1,
                total: 0,
            },
        })
    };

    changeHandler = ({startDate, endDate}) => {
        startDate = startDate || this.state.startDate;
        endDate = endDate || this.state.endDate;

        if (startDate !== null && startDate.isAfter(endDate)) {
            endDate = startDate
        }
        this.setState({
            ...this.state,
            startDate,
            endDate,
        })
    };

    changeStartDateHandler = (startDate) => {
        this.changeHandler({startDate});
    };

    changeEndDateHandler = (endDate) => {
        this.changeHandler({endDate})
    };

    // Number of calls by Month || Date || Time
    getCallHistory = (pageNumber=1) => {
        let type = this.state.currentType;
        let startDate = this.state.startDate?.format('YYYY-MM-DD HH:mm') || null;
        let endDate = this.state.endDate?.format('YYYY-MM-DD HH:mm') || null;
        let params = {
            type: type,
            startDate,
            endDate,
            page: pageNumber
        };
        let query = queryString.stringify(params);
        progressBar.start();
        // this.props.onFetchCallHistoryList(query).then(response => {
        //     this.setState({
        //         ...this.state,                     
        //         historyList: response?.data?.data,
        //     }, () => {
        //     });
        // })
        api.callList.callHistory(query).then(response => {
            this.setState({
                ...this.state,                     
                historyList: response?.data?.data,
            }, () => {
            });
            progressBar.done();
        }).catch(error => {
            progressBar.done();
        });        
    };

    handlePageChange = (pageNumber) => {
        this.getCallHistory(pageNumber);
    }

    fixedFormatter = value => {
        return (
            <Fragment>
                {this.state.currentType === 'time' ? value +'h' : value}
            </Fragment>
        )
    };

    componentWillUnmount() {
        // this.props.onUnmountCallList();
    }

    render() {
        const { location } = this.props;
        const { historyList } = this.state;

        // debugger
        return (
            <Fragment>
                <NotificationSystem ref={this.notificationSystem} allowHTML={this.state.notification.allowHTML}/>

                <FormattedMessage id="dashboard.CallHistory"
                    defaultMessage="CallHistory">
                    {message => <Helmet>
                        <title>{message}</title>
                    </Helmet>}
                </FormattedMessage>

                <div className="main_top_bar static_bar">
                    <div className="box_sum_col_top">
                        <div className="box_left">
                            <ul className="nav nav_tabs">
                                <li className="nav_item">
                                    <span className="nav_link">
                                        <FormattedMessage id="dashboard.CallHistory" defaultMessage="CallHistory" />
                                    </span>
                                </li>
                            </ul>
                        </div>

                        <div className="box_center">
                            <div className="tabs_static_item">
                                <div className="main_control_time_select">
                                    <div className="main_select main_box">
                                        <div className="main_label">
                                            <FormattedMessage id="dashboard.Type" defaultMessage="Type" />
                                        </div>
                                        <div className="custom_select">
                                            <select className="form_control"
                                                name="sltType"
                                                onChange={this.selectHistoryTypeHandler}
                                                value={this.state.currentType}>
                                                     <option value="time">
                                                        {this.props.intl.formatMessage({id: 'dashboard.Time',defaultMessage: 'Time'})}
                                                     </option>
                                                     <option value="day">
                                                        {this.props.intl.formatMessage({id: 'dashboard.Day',defaultMessage: 'Day'})}
                                                     </option>
                                                     <option value="month">
                                                        {this.props.intl.formatMessage({id: 'dashboard.Month',defaultMessage: 'Month'})}
                                                     </option>
                                            </select>
                                            <span className="item_dropdown" />
                                        </div>
                                    </div>
                                    <div className="filler_to_from">
                                        <div className="i_tem txt_form">
                                            <FormattedMessage id="dashboard.Date" defaultMessage="Date" />
                                        </div>
                                        <div className="i_tem box_control_input">
                                            <div className="box_control_date">
                                                <span className="icon_date" />
                                                <div className="date_picker_custom">
                                                    <DatePicker
                                                        disabled={!this.state.currentType}
                                                        dateFormat={
                                                            this.state.currentType == 'time' ?
                                                                "YYYY/MM/DD HH:mm" : "YYYY/MM/DD"
                                                        }
                                                        selected={this.state.startDate}
                                                        showTimeSelect={this.state.currentType == 'time'}
                                                        timeFormat="HH:mm"
                                                        timeIntervals={60}
                                                        timeCaption="Time"
                                                        selectsStart
                                                        id="FromDatePicker"
                                                        name="FromDatePicker"
                                                        className="from_date form_control form-control-sm unstyled"
                                                        startDate={this.state.startDate}
                                                        endDate={this.state.endDate}
                                                        onChange={this.changeStartDateHandler}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="i_tem txt_form hide">~</div>

                                        <div className="i_tem box_control_input">
                                            <div className="box_control_date">
                                                <span className="icon_date" />
                                                <div className="date_picker_custom">
                                                    <DatePicker
                                                        disabled={!this.state.currentType}
                                                        dateFormat={
                                                            this.state.currentType == 'time' ?
                                                                "YYYY/MM/DD HH:mm" : "YYYY/MM/DD"
                                                        }
                                                        selected={this.state.endDate}
                                                        showTimeSelect={this.state.currentType == 'time'}
                                                        timeFormat="HH:mm"
                                                        timeIntervals={60}
                                                        timeCaption="Time"
                                                        selectsStart
                                                        id="FromDatePicker"
                                                        name="FromDatePicker"
                                                        className="to_date form_control form-control-sm unstyled"
                                                        startDate={this.state.startDate}
                                                        endDate={this.state.endDate}
                                                        onChange={this.changeEndDateHandler}
                                                    />
                                                </div>
                                            </div>
                                        </div>   

                                        <div className="i_tem box_control_input">
                                            <div className="box_control_date">
                                                <button className="btn btn_preference" 
                                                    disabled={!this.state.currentType || !this.state.startDate || !this.state.endDate}
                                                    onClick={() => this.getCallHistory(1)}
                                                >
                                                    <FormattedMessage
                                                        id="dashboard.Search"
                                                        defaultMessage="Search"
                                                    />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main_content">
                    <div className="content_tabs_main content_static_tabs tab-content">
                        <div className="tabs_static_item tabs_item1">
                            <div className="box_content_static">
                                <div className="left_col left_col_customer_care">
                                    <DataSetting 
                                        location={this.props.location}
                                    />
                                </div>
                                <div className="content_col_nopading">
                                    <div className="list_control_box_preferences">
                                        <div className="content_left">
                                            <div className="content_column">
                                                <div className="column_box ">
                                                    <div className="control_box_item">
                                                        <div className="card_body">
                                                            <div className="custom_table">
                                                                <table className="table st_table_5">
                                                                    <thead>
                                                                        <tr>
                                                                            <th> <FormattedMessage id="dashboard.Time" defaultMessage="Time"/> </th>
                                                                            <th> <FormattedMessage id="dashboard.ReceivedCalls" defaultMessage="Received Calls"/> </th>
                                                                            <th> <FormattedMessage id="dashboard.CallHistory.SendingCalls" defaultMessage="Sending Calls"/> </th>
                                                                            <th> <FormattedMessage id="dashboard.UnansweredCalls" defaultMessage="Unanswered Calls"/> </th>
                                                                            <th> <FormattedMessage id="dashboard.TotalCalls" defaultMessage="Total Calls"/> </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {/* render call history list */}
                                                                    {
                                                                        historyList?.data?.length > 0 ? 
                                                                            historyList.data.map((item, index) => {
                                                                                return (
                                                                                    <tr key={index}>
                                                                                        <td>{this.fixedFormatter(item.time)}</td>
                                                                                        <td>{item.received}</td>
                                                                                        <td>{item.sending}</td>
                                                                                        <td>{item.answered}</td>
                                                                                        <td>{item.total}</td>
                                                                                    </tr>
                                                                                )
                                                                            }) :
                                                                            (
                                                                                <tr>
                                                                                    <td colSpan={5}>
                                                                                        <div className="nodata">No Data</div>
                                                                                    </td>
                                                                                </tr> 
                                                                            )
                                                                    }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>

                                                        {
                                                            historyList?.data?.length > 0 && (
                                                                <div className="car_footer">
                                                                    <nav aria-label="...">
                                                                        <ul className="custom_pagination pagination">
                                                                            <Pagination
                                                                                itemClass="page-item"
                                                                                linkClass="page-link"
                                                                                activePage={this.state.historyList?.current_page}
                                                                                itemsCountPerPage={this.state.historyList?.per_page}
                                                                                totalItemsCount={this.state.historyList?.total}
                                                                                pageRangeDisplayed={10}
                                                                                onChange={this.handlePageChange}
                                                                            />
                                                                        </ul>
                                                                    </nav>
                                                                </div>
                                                            )
                                                        }                                                        
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
};

const mapDispatchToProps = dispatch => {
    return {
        // onFetchCallHistoryList: (query) => dispatch(fetchCallHistoryListAction(query)),
        // onUnmountCallList: () => dispatch(unmountCallListAction())
    }
};


export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CallHistory));
