//User
export const FETCH_USER_MANAGER = 'FETCH_USER_MANAGER';
export const FETCH_USER_MANAGER_SUCCEED = 'FETCH_USER_MANAGER_SUCCEED';
export const FETCH_USER_MANAGER_FAILED = 'FETCH_USER_MANAGER_FAILED';

// Company Placement Search
export const FETCH_COMPANY_PLACEMENT  = 'FETCH_COMPANY_PLACEMENT';
export const FETCH_COMPANY_PLACEMENT_SUCCESS  = 'FETCH_COMPANY_PLACEMENT_SUCCESS';
export const FETCH_COMPANY_PLACEMENT_FAILED = 'FETCH_COMPANY_PLACEMENT_FAILED';

// Placement Register
export const FETCH_PLACEMENT_REGISTERED = 'FETCH_PLACEMENT_REGISTERED'
export const FETCH_PLACEMENT_REGISTERED_SUCCESS = 'FETCH_PLACEMENT_REGISTERED_SUCCESS'
export const FETCH_PLACEMENT_REGISTERED_FAILED = 'FETCH_PLACEMENT_REGISTERED_FAILED'