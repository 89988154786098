import {
    FETCH_SMSHISTORY,
    FETCH_SMSHISTORY_SUCCEED,
    FETCH_SMSHISTORY_FAILED,

    FETCH_SMS_BULKSENDING,
    FETCH_SMS_BULKSENDING_SUCCEED,
    FETCH_SMS_BULKSENDING_FAILED,
    UNMOUNT_SMSHISTORY,
    
} from './actionTypes';
import api from '../../utils/api';
//SMSHISTORY
export const fetchSmsHistory = () => {
    return {
        type: FETCH_SMSHISTORY,
    }
};

export const fetchSmsHistorySucceed = data => {
    return {
        type: FETCH_SMSHISTORY_SUCCEED,
        payload: {
            data: data
        }
    }
};

export const fetchSmsHistoryFailed = error => {
    return {
        type: FETCH_SMSHISTORY_FAILED,
        payload: {
            error: error
        }
    }
};

//SMSBULKSENDING
export const fetchSmsBulkSending = () => {
    return {
        type: FETCH_SMS_BULKSENDING,
    }
};

export const fetchSmsBulkSendingSucceed = data => {
    return {
        type: FETCH_SMS_BULKSENDING_SUCCEED,
        payload: {
            data: data
        }
    }
};

export const fetchSmsBulkSendingFailed = error => {
    return {
        type: FETCH_SMS_BULKSENDING_FAILED,
        payload: {
            error: error
        }
    }
};
export const unmountSmsHistory = () => {
    return {
        type: UNMOUNT_SMSHISTORY
    }
};
/**
 * Fetch SmsHistory
 */
export const fetchSmsHistoryAction = (query) => dispatch => {
    dispatch(fetchSmsHistory());
    return api.smsHistory.getSMSHistory(query)
        .then(response => {
            dispatch(fetchSmsHistorySucceed(response.data.data));
            return Promise.resolve(response.data);
        })
        .catch(error => {
            dispatch(fetchSmsHistoryFailed(error.response.data.errors.message));
            return Promise.reject(error.response)
        });
};

export const fetchSmsBulkSendingAction = (params) => dispatch => {
    dispatch(fetchSmsBulkSending());
    return api.smsHistory.getSMSBulkSending(params)
        .then(response => {
            dispatch(fetchSmsBulkSendingSucceed(response.data.data));
            return Promise.resolve(response.data);
        })
        .catch(error => {
            dispatch(fetchSmsBulkSendingFailed(error.response.data.errors.message));
            return Promise.reject(error.response)
        });
};

export const unmountSmsHistoryAction = () => dispatch => {
    dispatch(unmountSmsHistory())
};


