import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import * as routerName from "../../utils/routerName";
import { FormattedMessage } from "react-intl";
import { NavLink, Link, Switch } from 'react-router-dom';
import HasRole from "../../utils/entrust/HasRole";
import { hasRole } from '../../utils/entrust/entrust';
import { Collapse, Button, CardBody, Card } from 'reactstrap';
// import 'react-datepicker/dist/react-datepicker.css';


class SmsSetting extends Component {

    constructor(props) {
        super(props);
        console.log(this.props, 'this.props2');


        // let currentPath = this.props.location.pathname.substr(this.props.location.pathname.lastIndexOf('/') + 1);
        // let userPath = '';
        // if (hasRole(this.props.auth.user, 'primarynet')) {
        //     userPath = routerName.COMPANY_REGISTRATION;
        // } else {
        //     userPath = routerName.AFFILITED_COMPANY_MANAGEMENT;
        // }

        // if (currentPath === (`${routerName.DATA_SETTING}`)) {
        //     currentPath = currentPath === routerName.DATA_SETTING ? userPath : currentPath;
        //     this.props.history.push(`/${routerName.DASHBOARD}/${routerName.DATA_SETTING}/${currentPath}`);
        // }

        this.state = {
            collapseSMS: false,
            collapseMgmAdmin: false
        };
    }
    toggleSMS = () => {
        // debugger
        // this.props.history.push(`/${routerName.DASHBOARD}/${routerName.DATA_SETTING}/${routerName.SMS_MMS}/${routerName.SMS_TEXT_MANAGEMENT}`);
        this.setState({
            collapseSMS: !this.state.collapseSMS,
            collapseMgmAdmin: false
        }, () => {
            console.log(this.state, 'sssss');
        });
    }
    toggleMgmAdmin = () => {
        // this.props.history.push(`/${routerName.DASHBOARD}/${routerName.DATA_SETTING}/${routerName.MGM_ADMIN}/${routerName.MGM_USER_MANGER}`);
        this.setState({
            collapseMgmAdmin: !this.state.collapseMgmAdmin,
            collapse: false
        });
    }

    componentDidMount() {
        // let currentPath = this.props.location.pathname.substr(this.props.location.pathname.lastIndexOf('/') + 1);
        // if (routerName.MGM_USER_MANGER === currentPath || routerName.MGM_PLACEMENT === currentPath) {
        //     this.setState({
        //         collapseMgmAdmin: true
        //     })
        // }

        // if (routerName.SMS_BULK_SENDING === currentPath
        //     || routerName.SMS_TEXT_MANAGEMENT === currentPath
        //     || routerName.SMS_HISTORY === currentPath
        //     || routerName.TEXT_ON_OFF === currentPath) {
        //     this.setState({
        //         collapse: true
        //     })
        // }
    }

    removeActive = () => {
        this.setState({
            collapseMgmAdmin: false,
            collapse: false
        })
    }

    checkPathSMS = (path) => {
        let basePath = `/${routerName.DASHBOARD}/${routerName.SMS_SETTING}/${routerName.SMS_MMS}`
        return path.includes(basePath);
    }
    
    checkPathMGM = (path) => {
        let basePath = `/${routerName.DASHBOARD}/${routerName.DATA_SETTING}/${routerName.MGM_ADMIN}`
        return path.includes(basePath);
    }

    render() {
        const { location } = this.props;
        const { auth } = this.props;
        
        return (
            <Fragment>
                <ul className="list_menu_preferences">

                    {
											/*(auth.user.info_sale_lot && auth.user.info_sale_lot.status_text == 1) || hasRole(auth.user, 'primarynet')*/
											(auth.user.info_sale_lot) || hasRole(auth.user, 'primarynet')
                        ? <li>
                            <div>
                                <NavLink
                                    to={`/${routerName.DASHBOARD}/${routerName.SMS_SETTING}/${routerName.SMS_MMS}/${routerName.SMS_TEXT_MANAGEMENT}`}
                                    activeClassName="active">
                                    <FormattedMessage
                                        id="dashboard.Sms"
                                        defaultMessage="SMS/MMS" />
                                </NavLink>
                            </div>
                            <Collapse isOpen={this.checkPathSMS(location?.pathname)} >
                                <ul className="dropdown_preferents show">
                                    <li className={location?.pathname == `/${routerName.DASHBOARD}/${routerName.SMS_SETTING}/${routerName.SMS_MMS}/${routerName.SMS_TEXT_MANAGEMENT}` ? 'active' : ''}>
                                        <NavLink to={`/${routerName.DASHBOARD}/${routerName.SMS_SETTING}/${routerName.SMS_MMS}/${routerName.SMS_TEXT_MANAGEMENT}`}
                                            exact
                                            activeClassName="active">

                                            <FormattedMessage
                                                id="dashboard.SmsSetting.TextManagement"
                                                defaultMessage="Text management" />
                                        </NavLink>
                                    </li>

                                    <li className={location?.pathname == `/${routerName.DASHBOARD}/${routerName.SMS_SETTING}/${routerName.SMS_MMS}/${routerName.SMS_HISTORY_V2}` ? 'active' : ''}>
                                        <NavLink to={`/${routerName.DASHBOARD}/${routerName.SMS_SETTING}/${routerName.SMS_MMS}/${routerName.SMS_HISTORY_V2}`}
                                            exact
                                            activeClassName="active">
                                            <FormattedMessage
                                                id="dashboard.SmsSetting.History"
                                                defaultMessage="History" />
                                        </NavLink>
                                    </li>

									<li className={location?.pathname == `/${routerName.DASHBOARD}/${routerName.SMS_SETTING}/${routerName.SMS_MMS}/${routerName.PREDEFINE_SMS}` ? 'active' : ''}>
											<NavLink to={`/${routerName.DASHBOARD}/${routerName.SMS_SETTING}/${routerName.SMS_MMS}/${routerName.PREDEFINE_SMS}`}
													exact
													activeClassName="active">

													<FormattedMessage
															id="dashboard.PredefineSMS.menu"
															defaultMessage="Pre-Define SMS" />
											</NavLink>
									</li>

										<HasRole role={['primarynet','admin']}>

											<li className={location?.pathname == `/${routerName.DASHBOARD}/${routerName.SMS_SETTING}/${routerName.SMS_MMS}/${routerName.REJECT_LIST}` ? 'active' : ''}>
											<NavLink to={`/${routerName.DASHBOARD}/${routerName.SMS_SETTING}/${routerName.SMS_MMS}/${routerName.REJECT_LIST}`}
												exact
												activeClassName="active">
												<FormattedMessage
														id="dashboard.RejectList.menu"
														defaultMessage="Reject List" />
											</NavLink>
										</li>

										<li className={location?.pathname == `/${routerName.DASHBOARD}/${routerName.SMS_SETTING}/${routerName.SMS_MMS}/${routerName.SMS_BULK_SENDING}` ? 'active' : ''}>
											<NavLink to={`/${routerName.DASHBOARD}/${routerName.SMS_SETTING}/${routerName.SMS_MMS}/${routerName.SMS_BULK_SENDING}`}
												exact
												activeClassName="active">
												<FormattedMessage
														id="dashboard.SmsSetting.BulkSending"
														defaultMessage="Bulk Sending" />
											</NavLink>
										</li>

										<li className={location?.pathname == `/${routerName.DASHBOARD}/${routerName.SMS_SETTING}/${routerName.SMS_MMS}/${routerName.SMS_AUTO_SEND}` ? 'active' : ''}>
											<NavLink to={`/${routerName.DASHBOARD}/${routerName.SMS_SETTING}/${routerName.SMS_MMS}/${routerName.SMS_AUTO_SEND}`}
												exact
												activeClassName="active">
												<FormattedMessage
														id="dashboard.AutoReservedSMS.menu"
														defaultMessage="Auto Reserved SMS" />
											</NavLink>
										</li>

                  </HasRole>

									<HasRole role={['primarynet']}>

                                        <li className={location?.pathname == `/${routerName.DASHBOARD}/${routerName.SMS_SETTING}/${routerName.SMS_MMS}/${routerName.TEXT_ON_OFF}` ? 'active' : ''}>
                                            <NavLink to={`/${routerName.DASHBOARD}/${routerName.SMS_SETTING}/${routerName.SMS_MMS}/${routerName.TEXT_ON_OFF}`}
                                                exact
                                                activeClassName="active">
                                                <FormattedMessage
                                                    id="dashboard.SmsSetting.TextOnOff"
                                                    defaultMessage="Text On/Off" />
                                            </NavLink>
                                        </li>

                                    </HasRole>
                                </ul>
                            </Collapse>
                        </li>
                        : ""
                    }



                </ul>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
};

const mapDispatchToProps = dispatch => {
    return {}
};


export default connect(mapStateToProps, mapDispatchToProps)(SmsSetting);
