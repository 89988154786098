import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from "react-intl";
import { Helmet } from 'react-helmet';
import SmsSetting from './../SmsSetting';
import { NavLink, Link } from "react-router-dom";
import { renderMessage } from '../../../utils/helpers';
import Pagination from "react-js-pagination";
import NotificationSystem from 'react-notification-system';
import TextareaAutosize from 'react-autosize-textarea';
import {
    fetchSendAutoSMSAction, unmountSmsHistoryAction
} from "../../../store/actions/smsHistory";
import { createMessageHistoryAction, importExcelAction } from '../../../store/actions/textManager'
import HasRole from "../../../utils/entrust/HasRole";
import { hasRole } from "../../../utils/entrust/entrust";
import progressBar from '../../../utils/progressBar';
import * as webSocketApi from './../../../utils/websocketApi';

import * as apipostimageatalk from "./../../../utils/apipostimageatalk";
import _ from 'lodash';
import api from "./../../../utils/api";

import DatePicker from 'react-datepicker';
import moment from 'moment';

import {CHANGE_SCENE_COMPANY, CHANGE_SCENE_LOT} from "../../../store/type";

class SendAutoSMS extends Component {
    constructor(props) {

			console.log('props:',props);

        super(props);
        this.state = {
            id: this.props.user.csta_id,
            password: this.props.user.csta_pwd,
            deviceObject: this.props.user.usr_tel,
            lots_id: this.props.sceneLostId ? this.props.sceneLostId : 0,
            company_id: this.props.sceneCompanyId ? this.props.sceneCompanyId : 0,
            accessToken: '',
						length_1: 0, 
						length_2: 0, 
						length_3: 0,
						descr: '',
						phone: '',
            AutoSendForm: null,
						sms_send_list: [],
            notification: {
                allowHTML: false,
            },
        }
    }

		set_lots_config = (lots_id) => {

			let params = {
				lots_id
			}

			if (lots_id == "" || !lots_id) { // 초기화 기능을 탑재
					this.setState({
						...this.state,
						sms_send_list: [],
						AutoSendForm: {
							sms_content: "",
							start_date: moment().format('YYYY-MM-DD'),
							end_date: moment().format('YYYY-MM-DD'),
							send_hour: 0,
							send_minute: 0,
							sms_send: 0,
							send_agree: 0,
							send_disagree: 0,
							send_absent_call: 0,
							send_agree_period: 0,
							send_disagree_period: 0,
							send_agree_content: "",
							send_disagree_content: "",
							send_absent_call_content: "",
						}
					});
			} else {

				api.bulkSending.getSMSConfig(params).then(res => {
					console.log('res:',res);
					let info = res?.data?.data;
					if (info)
					{
						this.setState({
								...this.state,
								AutoSendForm: {
									sms_content: info[0].sms_content,
									start_date: info[0].start_date,
									end_date: info[0].end_date,
									send_hour: info[0].send_hour,
									send_minute: info[0].send_minute,
									sms_send: info[0].sms_send,
									send_agree: info[0].send_agree,
									send_disagree: info[0].send_disagree,
									send_absent_call: info[0].send_absent_call,
									send_agree_period: info[0].send_agree_period,
									send_disagree_period: info[0].send_disagree_period,
									send_agree_content: info[0].send_agree_content,
									send_disagree_content: info[0].send_disagree_content,
									send_absent_call_content: info[0].send_absent_call_content,
								}
						}, () => { // lots_id 가 변했다.

							api.bulkSending.getSMSSendList(params).then(res => {
								console.log('res:',res);
								let list = res?.data?.data;
								if (list)
								{
									this.setState({
											...this.state,
											sms_send_list: list
									}, () => { // lots_id 가 변했다.

									});
								}
							});
						});
					}
				});

			}

		}
		
    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.isChangeScene && nextProps.sceneLostId !== this.state.sceneLostId && nextProps.typeOfChange === CHANGE_SCENE_LOT) {
            this.setState({
                lots_id: nextProps.sceneLostId,
            }, () => { // lots_id 가 변했다.
							this.set_lots_config(nextProps.sceneLostId);
            })
        }/* else if(nextProps.isChangeScene && nextProps.sceneLostId == "" && nextProps.typeOfChange === CHANGE_SCENE_LOT) {
						this.set_lots_config(nextProps.sceneLostId);
				}*/
        if(nextProps.isChangeScene && nextProps.sceneCompanyId !== this.state.sceneCompanyId && nextProps.typeOfChange === CHANGE_SCENE_COMPANY) {
            this.setState({
                company_id: nextProps.sceneCompanyId,
            }, () => {

            })
        }
    }

		onChangeSendTarget = (e) => {

        const target = e.target;
        let value = target.type === 'checkbox' ? (target.checked ? 1 : 0): target.value;
        const name = target.name;

				this.setState({
						...this.state,
						[name]: value
				});        

		}

		onChangeOpt = (e) => {
				console.log('e:',e);
        const target = e.target;
        let value = target.type === 'checkbox' ? (target.checked ? 1 : 0): target.value;
        const name = target.name;

				this.setState({
						...this.state,
						AutoSendForm: {
							...this.state.AutoSendForm,
							[name]: value
						}
				});        
		}

		onChangeStartDate = (date) => {
				let formatDate = moment(date).format('YYYY-MM-DD');
				this.setState({
						...this.state,
						AutoSendForm: {
							...this.state.AutoSendForm,
							start_date: formatDate
						}
				}, () => { // lots_id 가 변했다.

				});    
    }

		onChangeEndDate = (date) => {
				let formatDate = moment(date).format('YYYY-MM-DD');
				this.setState({
						...this.state,
						AutoSendForm: {
							...this.state.AutoSendForm,
							end_date: formatDate
						}
				}, () => { // lots_id 가 변했다.

				});    
    }

		contentsChange = (e, type) => { // 변경시 직접입력으로 변경되고~~

			const { AutoSendForm, length_1, length_2, length_3 } = this.state;

			var maxlength = 90 ;
			var _byte = 0;
			var str = e.target.value;
			var charStr = '';
			var finalStr = '';

			for(var i=0;i<str.length;i++){
					charStr=str.charAt(i);
					if(escape(charStr).length>4){
							_byte+=2;
					}else{
							_byte++;
					}
					if (_byte > 1999) break;
					else finalStr += charStr;
			}

			if (type == 0) {
				AutoSendForm.sms_content = finalStr;
				this.setState({
						...this.state,
						AutoSendForm: AutoSendForm	
				});
			} else if (type == 1) {
				AutoSendForm.send_agree_content = finalStr;
				this.setState({
						...this.state,
						length_1: _byte,
						AutoSendForm: AutoSendForm	
				});
			} else if (type == 2) {
				AutoSendForm.send_disagree_content = finalStr;
				this.setState({
						...this.state,
						length_2: _byte,
						AutoSendForm: AutoSendForm	
				});
			} else if (type == 3) {
				AutoSendForm.send_absent_call_content = finalStr;
				this.setState({
						...this.state,
						length_3: _byte,
						AutoSendForm: AutoSendForm	
				});
			}
			

		}

    notificationSystem = React.createRef();
    addNotification = (data) => {
        const notification = this.notificationSystem.current;
        notification.addNotification({
            message: data.message,
            title: data.title,
            level: data.level
        });
    };

    componentDidMount() {

				if (!this.state.lots_id || this.state.lots_id == '')
				{
            this.addNotification({
                title: <FormattedMessage
                    id="dashboard.Error"
                    defaultMessage="Error!" />,
                message: <FormattedMessage
                    id="dashboard.smssetting.chooselots"
                    defaultMessage="Please choose lots!" />,
                level: 'error',
            })
				} else this.set_lots_config(this.state.lots_id);

    }

    buildOptions(max, unit, def) {
        var arr = [];

        for (let i = 0; i <= max; i++) {
					if (i == def) arr.push(<option key={i} value={i} selected>{i}{unit}</option>)
					else arr.push(<option key={i} value={i}>{i}{unit}</option>)            
        }

        return arr; 
    }


		insertContents = (e, text) => {

			const {AutoSendForm} = this.state;
			let prev_input = AutoSendForm?.sms_content;

			const contentsRef = document.getElementById('sms_content');

			const selectionStart = contentsRef.selectionStart;
			const selectionEnd = contentsRef.selectionEnd;

			let newValue =
				 prev_input.substring(0, selectionStart) +
				 text +
				 prev_input.substring(selectionEnd, prev_input.length);

			let new_e = { target: { value: newValue } };
			console.log('new_e:', new_e);
			this.contentsChange(new_e, 0);

		}

		onSaveForm = (e) => {

			const { 
				AutoSendForm,
				company_id,
				lots_id 
			} = this.state;

			let params = { 
					AutoSendForm,company_id,lots_id
			}
			console.log('AutoSendForm:',AutoSendForm);
			api.bulkSending.setSMSConfig(params).then(response => {

				this.addNotification({
						title: 'Information',
						message: '자동 예약 문자 설정이 저장되었습니다',
						level: 'error',
				})
			}).catch(error => {

			});
		}

		onSaveTarget = (e) => {

			const { 
				descr,
				phone,
				lots_id 
			} = this.state;

			let params = { 
					descr,phone,lots_id
			}
			console.log('params:',params);
			api.bulkSending.createSMSSendList(params).then(response => {

				api.bulkSending.getSMSSendList(params).then(res => {
					console.log('res:',res);
					let list = res?.data?.data;
					if (list)
					{
						this.setState({
								...this.state,
								descr: '',
								phone: '',
								sms_send_list: list
						}, () => { // lots_id 가 변했다.

						});
					}
				});

				this.addNotification({
						title: 'Information',
						message: '문자 발송 대상 설정이 추가되었습니다',
						level: 'error',
				})
			}).catch(error => {

			});

		}

		onSendControl = (e) => {

			const { 
				AutoSendForm,
				lots_id 
			} = this.state;

			let params = { 
					AutoSendForm,lots_id
			}
			let	b_send = true;
			let b_send_msg = '';
			if (AutoSendForm?.sms_send == 1)
			{
				if (!window.confirm('예약문자 전송을 정지 하시겠습니까?')) b_send = false;
				b_send_msg = '자동 예약 문자 설정이 정지 되었습니다';
			} else {
				if (!window.confirm('예약문자 전송을 살행 하시겠습니까?')) b_send = false;
				b_send_msg = '자동 예약 문자 설정이 실행 되었습니다';
			}
			if (AutoSendForm && b_send) {

				api.bulkSending.setSMSControl(params).then(response => {

					this.setState({
							...this.state,
							AutoSendForm: {
								...this.state.AutoSendForm,
								sms_send: AutoSendForm?.sms_send == 1 ? 0:1
							}
					}, () => { // lots_id 가 변했다.

					});   
					this.addNotification({
							title: 'Information',
							message: b_send_msg,
							level: 'error',
					})
				}).catch(error => {

				});

			}

		}

		deleteList = (idx) => {

			const { 
				lots_id 
			} = this.state;

			let params = { 
					idx, lots_id
			}
			if (window.confirm('삭제 하시겠습니까?'))
			{
				api.bulkSending.deleteSMSSendList(params).then(response => {

					api.bulkSending.getSMSSendList(params).then(res => {
						console.log('res:',res);
						let list = res?.data?.data;
						if (list)
						{
							this.setState({
									...this.state,
									sms_send_list: list
							}, () => { // lots_id 가 변했다.

							});
						}
					});
					this.addNotification({
							title: 'Information',
							message: '선택된 문자 발송 대상이 삭제 되었습니다',
							level: 'error',
					})
				}).catch(error => {

				});
			}

		}

    render() {
        const {
            AutoSendForm,
						sms_send_list,
						length_1, 
						length_2, 
						length_3,
        } = this.state;

			 const var_list = ['송신시간','전일까지 수신 누계','전일 수신 건수','금일 수신 건수','현재까지 수신 총 건수','전일까지 발신 누계','전일 발신 건수','금일 발신 건수','현재까지 발신 총 건수','전일까지 수/발신 누계','전일까지 수/발신 건수','금일 수/발신 건수','금일까지 수/발신 총 건수'];

        return (
            <Fragment>
                <NotificationSystem ref={this.notificationSystem} allowHTML={this.state.notification.allowHTML} />

                <FormattedMessage id="dashboard.SmsSetting.BulkSending"
                    defaultMessage="BulkSending">
                    {message => <Helmet>
                        <title>{message}</title>
                    </Helmet>}
                </FormattedMessage>

                <div className="main_top_bar static_bar">
                    <div className="box_sum_col_top">
                        <div className="box_left">
                            <ul className="nav nav_tabs">
                                <li className="nav_item">
                                    <span className="nav_link">
                                    <FormattedMessage
                                        id="dashboard.SmsSetting.BulkSendingTitle"
                                        defaultMessage="BulkSending"
                                    />
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="main_content">
                    <div className="content_tabs_main content_static_tabs tab-content">
                        <div className="tabs_static_item tabs_item1">
                            <div className="box_content_static">
                                <div className="left_col left_col_customer_care">
                                    <SmsSetting 
                                        location={this.props.location}
                                    />
                                </div>

                                <div className="content_col_nopading">
                                    <div className="list_control_box_preferences">

                                        <div className="content_left">
                                            <div className="content_column">

                                                <div className="column_box col-4">
                                                    <div className="heading_box">
                                                        <h5 className="title_preferences">
                                                            자동 예약 문자 메세지 내용
                                                        </h5>
                                                    </div>
                                                    <div className="control_box_item">

																												<div className="card_body">
                                                            <div className="custom_table">

																																<textarea className="text_area_in" 
																																	id="sms_content"
																																	name="sms_content"
																																	style={{width:"100%"}} 
																																	rows={8}
																																	value={AutoSendForm && AutoSendForm?.sms_content ? AutoSendForm.sms_content:""} 
																																	onChange={(e) => this.contentsChange(e,0)}>
																																</textarea>

																														</div>

                                                            <div className="custom_table" style={{border: "1px solid #666", marginTop: "10px"}}>

																															<div className="date" style={{borderBottom: "1px solid #666", padding: "3px"}}>
																																<h6 style={{fontWeight: "600", marginRight: "3px", textAlign: "center"}}>발송 기간</h6>
																																<div className="cont">
																																	<div className="col-5">
																																			<DatePicker
																																						className="form-control"
																																						selected={AutoSendForm?.start_date ? moment(AutoSendForm?.start_date):moment()}
																																						onChange={this.onChangeStartDate}
																																						popperProps={{
																																							positionFixed: true
																																						}}
																																				/>
																																	</div>
																																	<div className="wave col-1">~</div>
																																	<div className="col-5">
																																			<DatePicker
																																						className="form-control"
																																						selected={AutoSendForm?.end_date ? moment(AutoSendForm?.end_date):moment()}
																																						onChange={this.onChangeEndDate}
																																						popperProps={{
																																							positionFixed: true
																																						}}
																																				/>
																																	</div>
																																</div>
																															</div>

																															<div className="time" style={{borderBottom: "1px solid #666", padding: "3px"}}>
																																<h6 style={{fontWeight: "600", marginRight: "3px", textAlign: "center"}}>전송 시간</h6>
																																<div className="cont">
																																	<div className="col-3">
																																				<select className="form-control" name="send_hour" onChange={this.onChangeOpt}>
																																				{ this.buildOptions(23, '시', AutoSendForm?.send_hour) }
																																				</select>
																																	</div>
																																	<div className="col-3">
																																				<select className="form-control" name="send_minute" onChange={this.onChangeOpt}>
																																				{ this.buildOptions(59, '분', AutoSendForm?.send_minute) }
																																				</select>
																																	</div>
																																</div>
																															</div>

																														</div>
																													</div>

																													<div className="custom_table" style={{overflowY: "inherit", marginTop: "15px"}}>
																														<table className="table st_table_5">
																															<tbody>
																																<tr>
																																	<td style={{fontSize: "15px", fontWeight: "600", margin: "10px 0 8px", textAlign: "center", width: "20%", border: "1px solid #343a40"}}>선택 함수</td>
																																	<td colspan="3" style={{padding: "5px", border: "1px solid #343a40"}}>
																																		<ul>
																																			{ 
																																					var_list.map((item) => {
																																						return (
																																							<li style={{width:"100%",lineHeight: "1.3"}} className="li-css"><button className="btn_add" style={{height:"25px",width:"auto",backgroundColor:"#17A2B8",borderRadius:"3px",color:"#ffffff",border:"1px solid #343a40",fontSize:"14px"}} onClick={ (e) =>this.insertContents(e, '{@'+item+'@}') }>{item} 삽입</button></li>
																																						)
																																					})
																																			}
																																			</ul>
																																		</td>
																																	</tr>
																																	</tbody>
																																</table>
																																<button className="btn_add" style={{width: "100%", padding: "5px 13px", backgroundColor: "rgb(23, 162, 184)", color: "rgb(255, 255, 255)", border: "none", fontSize: "14px", borderRadius: "5px", border: "1px solid #343a40", marginTop: "8px"}} onClick={this.onSaveForm}>설정 저장</button>

																														</div>

																												</div>

																								</div>

                                                <div className="column_box col-4">
                                                    <div className="heading_box">
                                                        <h5 className="title_preferences">
                                                            문자 발송 대상
                                                        </h5>
                                                    </div>
                                                    <div className="control_box_item">

																												<div className="card_body">
                                                            <div className="custom_table">

																															<div className="sms-flex">
																																<h6>대상 정보</h6>
																																<input type="text" style={{border: "1px solid #343a40"}} className="form-control" name="descr" onChange={this.onChangeSendTarget}/> 
																															</div>
																															<div className="sms-flex">
																																<h6>전화 번호</h6>
																																<input type="text" style={{border: "1px solid #343a40"}} className="form-control" name="phone" onChange={this.onChangeSendTarget}/>
																															</div>
																															<div className="but-list">
																																<div className="but-left">
																																	<button className="btn_add" style={{height:"33px",backgroundColor:"rgb(23, 162, 184)",color: "rgb(255, 255, 255)", padding: "3px", border: "1px solid #343a40", fontSize: "14px", borderRadius: "5px"}} onClick={ (e) => this.onSaveTarget(e) }>저장</button>
																																</div>
																																<div className="but-right">

																																	<button className="btn_add" style={{height:"33px",backgroundColor:"#00b494",color: "rgb(255, 255, 255)", padding: "3px", border: "1px solid #343a40", fontSize: "14px", borderRadius: "5px"}}>{ AutoSendForm?.sms_send == 1 ? '예약 문자 실행중':'예약 문자 정지됨' }</button>

																																	<button className="btn_add" style={{height:"33px",backgroundColor:"#f85c5c",color: "rgb(255, 255, 255)", padding: "3px", border: "1px solid #343a40", fontSize: "14px", borderRadius: "5px"}} onClick={ (e) => this.onSendControl(e) }>{ AutoSendForm?.sms_send == 1 ? '문자 정지':'문자 실행' }</button>

																																</div>
																															</div>
																															<div className="custom_table" style={{overflowY: "inherit", marginTop: "15px", minHeight:"605px", backgroundColor:"#fff"}}>
																															<table className="table st_table_5">
																																<thead>
																																<tr className="table-tr">
																																	<th style={{textAlign:"center"}}>대상 정보</th><th style={{textAlign:"center"}}>전화 번호</th><th style={{textAlign:"center"}}>삭제</th>
																																</tr>
																																</thead>
																																<tbody className="table-info">
																																{
																																		sms_send_list?.length > 0 ?
																																				sms_send_list.map((item, index) => {
																																						return (
																																								<tr key={item.idx}>
																																										<td style={{textAlign:"center"}}>{item.descr}</td>
																																										<td style={{textAlign:"center"}}>{item.phone}</td>
																																										<td style={{textAlign:"center"}}><div><a onClick={() => this.deleteList(item.idx)} ><i className="fas fa-trash-alt"/></a></div></td>
																																								</tr>
																																						)
																																				}) :
																																				(
																																						<tr>
																																								<td colSpan={3}>
																																										<div className="nodata">No Data</div>
																																								</td>
																																						</tr>
																																				)
																																}
																																</tbody>
																															</table>
																															</div>
																														</div>
																												</div>

																										</div>
																								</div>

                                                <div className="column_box col-4">
                                                    <div className="heading_box">
                                                        <h5 className="title_preferences">
                                                            수신 동의/비동의 고객 자동 문자
                                                        </h5>
                                                    </div>
                                                    <div className="control_box_item">

																												<div className="card_body">

                                                            <div className="custom_table">

																																<div className="table st_table_5">

																																	<div className="auto-sms">

																																		<div className="agree" style={{marginBottom: "15px"}}>
																																			<div style={{margin: "5px 0"}}>
																																				<input type="checkbox" id="list_001" style={{border: "2px solid #343a40"}} name="send_agree" value="1" checked={ AutoSendForm?.send_agree == 1 ? true : false } onChange={(e) => this.onChangeOpt(e)}/> <label className="action_check" for="list_001" style={{fontWeight: "500", fontSize: "14.5px"}}>수신 동의 고객</label>
																																			</div>
																																			<div style={{margin: "5px 0"}}>
																																				<select className="form-control col-3" name="send_agree_period" onChange={this.onChangeOpt} style={{lineHeight: "1", margin: "inherit", cursor: "pointer", appearance: "auto", height: "30px", padding: "0px 3px", border: "1px solid #343a40", borderRadius: "0", fontSize: "14px"}}>
																																					<option key={0} value={0} selected={AutoSendForm?.send_agree_period == 0 ? true:false}>즉시</option>)
																																					<option key={1} value={10} selected={AutoSendForm?.send_agree_period == 10 ? true:false}>10분뒤</option>)
																																					<option key={2} value={30} selected={AutoSendForm?.send_agree_period == 30 ? true:false}>30분뒤</option>)
																																					<option key={3} value={1440} selected={AutoSendForm?.send_agree_period == 1440 ? true:false}>하루뒤</option>)
																																				</select>
																																			</div>
																																			<div style={{border: "1px solid #343a40", backgroundColor:"#fff"}}>

																																				<textarea className="text_area_in" 
																																					name="send_agree_content"
																																					style={{width: "100%", border: "none"}} 
																																					rows={10}
																																					value={AutoSendForm?.send_agree_content} 
																																					onChange={(e) => this.contentsChange(e,1)}>
																																				</textarea>

																																				<div className="byte" style={{backgroundColor: "#fff", width: "100%", textAlign: "right", padding: "4px 7px", paddingBottom: "5px"}}><span style={{fontSize: "10px", color: "#666"}}>{ length_1 } Bytes / { length_1 > 90 ? 'LMS' : 'SMS' }</span></div>

																																			</div>
																																		</div>

																																		<div className="disagree" style={{marginBottom: "15px"}}>
																																			<div style={{margin: "5px 0"}}>
																																				<input type="checkbox" id="list_002" style={{border: "2px solid #343a40"}} name="send_disagree" value="1" checked={ AutoSendForm?.send_disagree == 1 ? true : false } onChange={(e) => this.onChangeOpt(e)}/> <label className="action_check" for="list_002" style={{fontWeight: "500", fontSize: "14.5px"}}>수신 비동의 고객</label>
																																			</div>
																																			<div style={{margin: "5px 0"}}>
																																				<select className="form-control col-3" name="send_disagree_period" onChange={this.onChangeOpt} style={{lineHeight: "1", margin: "inherit", cursor: "pointer", appearance: "auto", height: "30px", padding: "0px 3px", border: "1px solid #343a40", borderRadius: "0", fontSize: "14px"}}>
																																					<option key={0} value={0} selected={AutoSendForm?.send_disagree_period == 0 ? true:false}>즉시</option>)
																																					<option key={1} value={10} selected={AutoSendForm?.send_disagree_period == 10 ? true:false}>10분뒤</option>)
																																					<option key={2} value={30} selected={AutoSendForm?.send_disagree_period == 30 ? true:false}>30분뒤</option>)
																																					<option key={3} value={1440} selected={AutoSendForm?.send_disagree_period == 1440 ? true:false}>하루뒤</option>)
																																				</select>
																																			</div>
																																			<div style={{border: "1px solid #343a40", backgroundColor:"#fff"}}>

																																				<textarea className="text_area_in" 
																																					name="send_disagree_content"
																																					style={{width: "100%", border: "none"}} 
																																					rows={10}
																																					value={AutoSendForm?.send_disagree_content} 
																																					onChange={(e) => this.contentsChange(e,2)}>
																																				</textarea>

																																				<div className="byte" style={{backgroundColor: "#fff", width: "100%", textAlign: "right", padding: "4px 7px", paddingBottom: "5px"}}><span style={{fontSize: "10px", color: "#666"}}>{ length_2 } Bytes / { length_2 > 90 ? 'LMS' : 'SMS' }</span></div>

																																			</div>
																																		</div>

																																		<div className="disagree" style={{marginBottom: "15px"}}>
																																			<div style={{margin: "5px 0"}}>

																																			<input type="checkbox" id="list_003" name="send_absent_call" value="1" checked={ AutoSendForm?.send_absent_call == 1 ? true : false } onChange={(e) => this.onChangeOpt(e)}/> <label className="action_check" for="list_003" style={{fontWeight: "500", fontSize: "14.5px"}}>미 응답(발신 부재) 고객</label>
																																			</div>
																																			<div style={{border: "1px solid #343a40", backgroundColor:"#fff"}}>

																																				<textarea className="text_area_in" 
																																					name="send_absent_call_content"
																																					style={{border:"none",width:"100%"}} 
																																					rows={10}
																																					value={AutoSendForm?.send_absent_call_content} 
																																					onChange={(e) => this.contentsChange(e,3)}>
																																				</textarea>

																																				<div className="byte" style={{backgroundColor: "#fff", width: "100%", textAlign: "right", padding: "4px 7px", paddingBottom: "5px"}}><span style={{fontSize: "10px", color: "#666"}}>{ length_3 } Bytes / { length_3 > 90 ? 'LMS' : 'SMS' }</span></div>
																																			</div>
																																		</div>
																																</div>
																														</div>
																												</div>

																											</div>
																										</div>
																								</div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.user,
        sceneCompanyId: state.sceneSelect.company_id,
        sceneLostId: state.sceneSelect.lots_id,
        typeOfChange: state.sceneSelect.typeOfChange,
        isChangeScene: state.sceneSelect.isChangeScene,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        //onFetchBulkSending: (params) => dispatch(fetchSendAutoSMSAction(params)),
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(SendAutoSMS)
