import {
    FETCH_INFO_DETAIL_LIST_BY_INFO_MAST,
    FETCH_INFO_DETAIL_LIST_BY_INFO_MAST_SUCCEED,
    FETCH_INFO_DETAIL_LIST_BY_INFO_MAST_FAILED,
    CREATE_INFO_DETAIL,
    CREATE_INFO_DETAIL_SUCCEED,
    CREATE_INFO_DETAIL_FAILED,
    UPDATE_INFO_DETAIL,
    UPDATE_INFO_DETAIL_SUCCEED,
    UPDATE_INFO_DETAIL_FAILED,
    DELETE_INFO_DETAIL,
    DELETE_INFO_DETAIL_SUCCEED,
    DELETE_INFO_DETAIL_FAILED,
    UNMOUNT_INFO_DETAIL
} from '../actions/actionTypes';
import api from "../../utils/api";

export const fetchInfoDetailByInfoMast = id => {
    return {
        type: FETCH_INFO_DETAIL_LIST_BY_INFO_MAST
    }
};

export const fetchInfoDetailByInfoMastSucceed = data => {
    return {
        type: FETCH_INFO_DETAIL_LIST_BY_INFO_MAST_SUCCEED,
        payload: {
            data: data
        }
    }
};

export const fetchInfoDetailByInfoMastFailed = error => {
    return {
        type: FETCH_INFO_DETAIL_LIST_BY_INFO_MAST_FAILED,
        payload: {
            error: error
        }
    }
};

export const createInfoDetail = () => {
    return {
        type: CREATE_INFO_DETAIL
    }
};

export const createInfoDetailSucceed = company => {
    return {
        type: CREATE_INFO_DETAIL_SUCCEED,
        payload: {
            company
        }
    }
};

export const createInfoDetailFailed = error => {
    return {
        type: CREATE_INFO_DETAIL_FAILED,
        payload: {
            error: error
        }
    }
};

export const updateInfoDetail = () => {
    return {
        type: UPDATE_INFO_DETAIL
    }
};

export const updateInfoDetailSucceed = company => {
    return {
        type: UPDATE_INFO_DETAIL_SUCCEED,
        payload: {
            company
        }
    }
};

export const updateInfoDetailFailed = error => {
    return {
        type: UPDATE_INFO_DETAIL_FAILED,
        payload: {
            error: error
        }
    }
};

export const deleteInfoDetail = () => {
    return {
        type: DELETE_INFO_DETAIL
    }
};

export const deleteInfoDetailSucceed = company => {
    return {
        type: DELETE_INFO_DETAIL_SUCCEED,
        payload: {
            company
        }
    }
};

export const deleteInfoDetailFailed = error => {
    return {
        type: DELETE_INFO_DETAIL_FAILED,
        payload: {
            error: error
        }
    }
};

export const unmountInfoDetail = () => {
    return {
        type: UNMOUNT_INFO_DETAIL
    }
};


/**
 * Action
 */

export const unmountInfoDetailAction = () => dispatch => {
    dispatch(unmountInfoDetail())
};

export const fetchInfoDetailByInfoMastAction = id => dispatch => {
    dispatch(fetchInfoDetailByInfoMast());
    return api.telephone.getInfoDetailByInfoMast(id)
        .then(response => {
            dispatch(fetchInfoDetailByInfoMastSucceed(response.data.data));
            return Promise.resolve(response.data.data);
        })
        .catch(error => {
            dispatch(fetchInfoDetailByInfoMastFailed(error.response.data.errors.message));
            return Promise.reject(error.response)
        });
};


/**
 * Create info detail
 */
export const createInfoDetailAction = infoDetail => dispatch => {
    dispatch(createInfoDetail());
    return api.infoDetail.createInfoDetail(infoDetail)
        .then(response => {
            dispatch(createInfoDetailSucceed(response.data.data));
            return Promise.resolve(response.data);
        })
        .catch(error => {
            dispatch(createInfoDetailFailed(error.response.data.errors.message));
            return Promise.reject(error.response)
        });
};

/**
 * Update info detail
 */
export const updateInfoDetailAction = (id, infoDetail) => dispatch => {
    dispatch(updateInfoDetail());
    return api.infoDetail.updateInfoDetail(id, infoDetail)
        .then(response => {
            dispatch(updateInfoDetailSucceed(response.data.data));
            return Promise.resolve(response.data);
        })
        .catch(error => {
            dispatch(updateInfoDetailFailed(error.response.data.errors.message));
            return Promise.reject(error.response)
        });
};

/**
 * Delete info detail
 */
export const deleteInfoDetailAction = infoDetailId => dispatch => {
    dispatch(deleteInfoDetail());
    const id = {
        params: {
            m_dtl_id: infoDetailId
        }
    };
    return api.infoDetail.deleteInfoDetail(id)
        .then(response => {
            dispatch(deleteInfoDetailSucceed(response.data.data));
            return Promise.resolve(response.data);
        })
        .catch(error => {
            dispatch(deleteInfoDetailFailed(error.response.data.errors.message));
            return Promise.reject(error.response)
        });
};
