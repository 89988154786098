import {
    FETCH_REPORT_TELEPHONE,
    FETCH_REPORT_TELEPHONE_SUCCESS,
    FETCH_REPORT_TELEPHONE_FAIL,
    RESET_REPORT_TELEPHONE
} from './../type';
import api from './../../utils/api';


export const fetchReportTelephone = type => {
    return {
        type: FETCH_REPORT_TELEPHONE
    }
};

export const fetchReportTelephoneSucceed = data => {
    return {
        type: FETCH_REPORT_TELEPHONE_SUCCESS,
        payload: {
            data: data
        }
    }
};

export const fetchReportTelephoneFailed = error => {
    return {
        type: FETCH_REPORT_TELEPHONE_FAIL,
        payload: {
            error: error
        }
    }
};

export const fetchReportTelephoneAction = query => dispatch => {
    dispatch(fetchReportTelephone())
    return api.telephone.getReportTelePhone(query).then(response => {
        dispatch(fetchReportTelephoneSucceed(response.data.data));
        return Promise.resolve(response.data.data);
    })
        .catch(error => {
            dispatch(fetchReportTelephoneFailed(error.response.data.errors.message));
            return Promise.reject(error.response)
        });
}

export const resetReportTelephoneAction = () => {
    return {
        type: RESET_REPORT_TELEPHONE,
    }
};