import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from "react-intl";
import { Helmet } from 'react-helmet';
import {
    fetchCompaniesAction
} from "../../../store/actions";
import RichTextEditor from 'react-rte';
// import 'react-rte/lib/Draft.global.css'
import './CustomerEditer.css';
import DataSetting from './../DataSetting';
import { NavLink, Link } from "react-router-dom";
import { hasRole } from "../../../utils/entrust/entrust";
import progressBar from '../../../utils/progressBar';
import * as routerName from "../../../utils/routerName";
import api from '../../../utils/api';
import * as helpers from '../../../utils/helpers';
import Pagination from "react-js-pagination";
import NotificationSystem from 'react-notification-system';
import ConfirmModal from '../../../components/ConfirmModal/ConfirmModal';

class Notice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            companies: {},
            currentCompany: {},
            noticeForm: {},
            selection: [],
						check_all: false,
						isConfirmModalOpen: false,
            notification: {
                allowHTML: false,
            },
        };
    }

    notificationSystem = React.createRef();
    addNotification = (data) => {
      const notification = this.notificationSystem.current;
      notification.addNotification({
        message: data.message,
        title: data.title,
        level: data.level
      });
    };

    componentDidMount() {
        // paginate: get data Paginate Format  || list: get data List Format
        this.getCompanies();
    };

		handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            ...this.state,
            noticeForm: {
                ...this.state.noticeForm,
                [name]: value,
            }
        });    

		}

    getCompanies = () => {
        progressBar.start();
        let params = {
            page: this.state.companies?.current_page || 1,
            type: 'paginate',
						per_page: 100000
        }
        console.log(params, 'params2');
        this.props.onFetchCompanies(params).then(response => {
            this.setState({
                ...this.state,
                companies: response.data
            })
            progressBar.done();
        }).catch(error => {
            progressBar.done();
        });
    }

    handleClickItemCheckAll = (event) => {
        let { selection, companies } = this.state;
        let value = event.target.checked;
				console.log('companies:',companies);
				console.log('selection:',selection);
				console.log('value:',value);
				selection = [];
        if (value) {
						selection = companies?.data?.map((item, index) => {
													return +item.company_id;
												});
        }
        this.setState({
            ...this.state,
						check_all: value,
            selection,
        }, () => {
						console.log('selection:',selection);
						console.log('value:',value);
            // console.log(this.state.selection, 'selection_af');
        })
		}

    handleClickItemCheckbox = (event, item) => {
        let { selection } = this.state;
        let value = event.target.checked;
        if (value) {
            if ( !selection.includes(item.company_id) )
            selection.push(item.company_id);
        } else {
            selection = helpers.removeItemInArray(selection, item.company_id)
        }
        this.setState({
            ...this.state,
            selection,
        }, () => {
            // console.log(this.state.selection, 'selection_af');
        })
    }


    changePageCompany = (pageNumber) => {
        this.setState({
            ...this.state,
            companies: {
                ...this.state.companies,
                current_page: pageNumber
            }
        }, () => {
            this.getCompanies();
        })
    }

    openConfirmModal = () => {
        this.setState({
            isConfirmModalOpen: !this.state.isConfirmModalOpen
        });
    };

		onConfirm = () => {
				progressBar.start();
				const { selection,noticeForm } = this.state;
        let params = { 
            selection,
						noticeForm
        }
				// 조회수 처리 ?
				console.log('params:',params);
        api.notice.send(params).then(response => {
           this.setState({
               ...this.state,
               selection: [],
							 check_all: false,
               //noticeForm: []
           }, () => {
							this.addNotification({
									title: <FormattedMessage
											id="dashboard.datasetting.notice"
											defaultMessage="Notice send"/>,
									message: <FormattedMessage
											id="dashboard.notice.send.success"
											defaultMessage="Notice successfully!"/>,
									level: 'success',
							})
							this.openConfirmModal();
              console.log(this.state.noticeForm, 'noticeForm');
           })
            progressBar.done();
        }).catch(error => {        
						this.addNotification({
								title: <FormattedMessage
										id="dashboard.datasetting.notice"
										defaultMessage="Notice send"/>,
								message: <FormattedMessage
										id="dashboard.notice.send.fail"
										defaultMessage="Notice failed!"/>,
								level: 'error',
						})
						this.openConfirmModal();
            progressBar.done();
        });
    };

    render() {
        const { 
            companies,
            currentCompany,
						selection,
						noticeForm,
						check_all
        } = this.state;
        // console.log(this.props.location, 'location2');

        return (
            <Fragment>
                <NotificationSystem ref={this.notificationSystem} allowHTML={this.state.notification.allowHTML}/>

                <FormattedMessage id="dashboard.datasetting.notice"
                    defaultMessage="Notice">
                    {message => <Helmet>
                        <title>{message}</title>
                    </Helmet>}
                </FormattedMessage>

                <div className="main_top_bar static_bar">
                    <div className="box_sum_col_top">
                        <div className="box_left">
                            <ul className="nav nav_tabs">
                                <li className="nav_item">
                                    <span className="nav_link">
                                    <FormattedMessage
                                        id="dashboard.datasetting.notice"
                                        defaultMessage="Notice"
                                    />
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="main_content">
                    <div className="content_tabs_main content_static_tabs tab-content">
                        <div className="tabs_static_item tabs_item1">
                            <div className="box_content_static">
                                <div className="left_col left_col_customer_care">
                                    <DataSetting 
                                        location={this.props.location}
                                    />
                                </div>

                                <div className="content_col_nopading">
                                    <div className="list_control_box_preferences">
                                        <div className="content_left">
                                            <div className="content_column">

                                                {/* Company Box */}
                                                <div className="column_box">
                                                    <div className="heading_box">
                                                        <h5 className="title_preferences">
                                                            <FormattedMessage
                                                                id="dashboard.Company"
                                                                defaultMessage="Company"
                                                            />
                                                        </h5>
                                                    </div>
                                                    <div className="control_box_item">
                                                        <div className="card_header">
                                                            <div className="box_title">
                                                                <FormattedMessage
                                                                    id="dashboard.Name"
                                                                    defaultMessage="Name"
                                                                />
                                                            </div>
                                                            <div className="box_add">
                                                                <input type="checkbox" checked={ check_all ? true : false } name="check_all" onClick={(e) => this.handleClickItemCheckAll(e)}/>
																																 전체
                                                            </div>
                                                        </div>

                                                        <div className="card_body">
                                                            <div className="custom_table">
                                                                <table className="table st_table_5">
                                                                    <tbody>
                                                                    {/* render companies list */}
                                                                    {
                                                                        companies?.data?.length > 0 ? 
                                                                            companies.data.map((item, index) => {
                                                                                let active = '';
                                                                                return (
                                                                                    <tr 
                                                                                        key={index}
                                                                                        className={active}
                                                                                    >
                                                                                        <td style={{width: '90%'}}>{item.company_name}</td>
                                                                                        <td className="inline_action">

																																													<input type="checkbox" 
																																															checked={ selection.includes(item.company_id) ? true : false }
																																															ref={(input) => this[`checkbox_${item.company_id}`] = input}                                                                                       
																																															onChange={(e) => this.handleClickItemCheckbox(e, item)}
																																															onClick={e => e.stopPropagation()}
																																													/>

                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            }) :
                                                                            (
                                                                                <tr>
                                                                                    <td colSpan={2}>
                                                                                        <div className="nodata">No Data</div>
                                                                                    </td>
                                                                                </tr> 
                                                                            )
                                                                    }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>        
                                                        {/* render paginate companies list */}
                                                        {
                                                            companies?.data?.length > 100 && (
                                                                <div className="car_footer">
                                                                    <nav aria-label="...">
                                                                        <ul className="custom_pagination pagination">
                                                                            <Pagination
                                                                                itemClass="page-item"
                                                                                linkClass="page-link"
                                                                                activePage={companies?.current_page}
                                                                                itemsCountPerPage={companies?.per_page}
                                                                                totalItemsCount={companies?.total || 0}
                                                                                pageRangeDisplayed={20}
                                                                                onChange={this.changePageCompany}
                                                                            />
                                                                        </ul>
                                                                    </nav>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                                    
                                            </div>
                                        </div>


                                        <div className="content_right" style={{width:'50%'}}>

                                            <div className="heading_box">

																							<div className="card_header">
																									<div className="box_title">
																										<h5 className="title_preferences">
																										<FormattedMessage
																												id="dashboard.datasetting.notice"
																												defaultMessage="Notice"
																										/>
																										</h5>
																									</div>
																									<div className="box_add">
																											<button type="button" className="btn btn_add" onClick={this.openConfirmModal}>
																													<i className="fas fa-plus" /> 전송
																											</button>
																									</div>

	                                            </div>

                                            </div>
																						
																						<textarea
																								style={{width:'100%', height:'600px'}}
																								type="text"
																								className="form_control"
																								name="content"
																								value={noticeForm?.content}
																								onChange={this.handleChange}
																						/>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

														
                <ConfirmModal
                    isConfirmModalOpen={this.state.isConfirmModalOpen}
                    openConfirmModal={this.openConfirmModal}
                    title="공지사항"
                    body="전송 하시겠습니까 ?"
                    onConfirm={this.onConfirm}
                />

            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        companies: state.companies,
        company: state.company,
        auth: state.auth
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchCompanies: (params={type:'paginate'}) => dispatch(fetchCompaniesAction(params)),
    }
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Notice));