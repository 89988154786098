import {
    FETCH_PLACEMENT_REGISTERED,
    FETCH_PLACEMENT_REGISTERED_SUCCESS,
    FETCH_PLACEMENT_REGISTERED_FAILED,
} from "./../../actions/MgmAdmin/actionType";
const initialState = {
    data: null,
    loading: false,
    error: false
};

const placementRegistered = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PLACEMENT_REGISTERED: {
            return {
                ...state,
                loading: true
            }
        };
        case FETCH_PLACEMENT_REGISTERED_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: action.payload.data.data,
                ...action.payload.data
            }
        };
        case FETCH_PLACEMENT_REGISTERED_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        }
        default:
            return state;
    }
};

export default placementRegistered;