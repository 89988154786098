import {
    FETCH_MESSAGES_DIRECT,
    FETCH_MESSAGES_DIRECT_SUCCESS,
    FETCH_MESSAGES_DIRECT_FAIL,


    CREATE_MESSAGES_DIRECT,
    CREATE_MESSAGES_DIRECT_SUCCESS,
    CREATE_MESSAGES_DIRECT_FAIL,


    UPDATE_MESSAGES_DIRECT,
    UPDATE_MESSAGES_DIRECT_SUCCESS,
    UPDATE_MESSAGES_DIRECT_FAIL
} from './../type';


const initialState = {
    data: null,
    loading: false,
    error: false
};

const directMessage = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_MESSAGES_DIRECT: {
            return {
                ...state,
                loading: true
            }
        }
        case FETCH_MESSAGES_DIRECT_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: {
                    ...state.data,
                    ...action.payload.data,
                }
            }
        }
        case FETCH_MESSAGES_DIRECT_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        }


        case CREATE_MESSAGES_DIRECT: {
            return {
                ...state,
                loading: true
            }
        }
        case CREATE_MESSAGES_DIRECT_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: {
                    ...state.data,
                    ...action.payload.data,
                }
            }
        }
        case CREATE_MESSAGES_DIRECT_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        }

        case UPDATE_MESSAGES_DIRECT: {
            return {
                ...state,
                loading: true
            }
        }
        case UPDATE_MESSAGES_DIRECT_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: {
                    ...state.data,
                    ...action.payload.data,
                }
            }
        }
        case UPDATE_MESSAGES_DIRECT_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        }


        default:
            return state;
    }
};

export default directMessage;