import {
    SEARCH_CALL_BY_NUMBER,
    SEARCH_CALL_BY_NUMBER_SUCCESS,
    SEARCH_CALL_BY_NUMBER_FAIL,
    CLEAR_SEARCH_CALL_BY_NUMBER
} from './../type';


const initialState = {
    data: null,
    loading: false,
    error: false
};

const callListSearchByCallNumber = (state = initialState, action) => {
    switch (action.type) {
        case SEARCH_CALL_BY_NUMBER: {
            return {
                ...state,
                loading: true
            }
        }
        case SEARCH_CALL_BY_NUMBER_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: action.payload.data
            }
        }
        case SEARCH_CALL_BY_NUMBER_FAIL: {
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        }
        case CLEAR_SEARCH_CALL_BY_NUMBER: {
            return initialState
        }
        default:
            return state;
    }
}
export default callListSearchByCallNumber;