import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment/moment";
import { FormattedMessage, injectIntl } from "react-intl";

HeaderComponent.propTypes = {
  props: PropTypes.object,
};

function HeaderComponent(props) {
  const [startDate, setStartDate] = useState(moment().startOf("day"));
  const [currentType, setCurrentType] = useState("type");
  const [change_option, setChangeOption] = useState("e");
  const [query, setQuery] = useState(props.query);
  const changeDateRef = useRef();

  useEffect(() => {
    console.log('changeOption');
    setQuery(props.query);
  }, [props.query]);

  function exportExcelHandler(){
    props.exportExcel();
  }

  function printReportHandler(){
    props.printReport();
  }

  /**
   * event change start date
   * @param {*} startDate
   */
  function changeStartDateHandler(startDate) {
    setStartDate(startDate);
    props.dateReport(startDate);
    changeDateRef.current.handleBlur();
  }

  /**
   * minus date
   * @param {*} date
   */
  function handleMinusDay(date) {
    props.dateReport(date);
    setStartDate(date);
    changeDateRef.current.handleBlur();
  }

  /**
   * plus date
   * @param {*} date
   */
  function handlePlusDay(date) {
    props.dateReport(date);
    setStartDate(date);
    changeDateRef.current.handleBlur();
  }

  /**
   * reset date today
   */
  function handleResetToday() {
    setStartDate(moment().startOf("day"));
    props.dateReport(moment().startOf("day"));
    changeDateRef.current.handleBlur();
  }

  function changeOption(data) {
    console.log(data, ' changeOption');
    setChangeOption(data);
    props.changeOption(data);
  }

  return (
    <div className="box_center tab-content">
      <div className=" tabs_static_item " id>
        <div className="main_control_time_select justify_content">
          <div className="box_left_static">
            <div className="main_time main_box">
              <ul className="list_time">
                <li>날짜 선택</li>
                <li>
                  <button
                    type="button"
                    className=" btn_flat_custom flatpickr-next-month"
                    onClick={() => handleMinusDay(startDate.subtract(1, "days"))}
                  >
                    <i className="fa fa-angle-left" />
                  </button>
                </li>
                <li>
                  <div className="box_control_date">
                    <DatePicker
                      disabled={!currentType}
                      dateFormat={
                        currentType === "time" ? "YYYY/MM/DD HH:mm" : "YYYY/MM/DD"
                      }
                      selected={startDate}
                      showTimeSelect={currentType === "time"}
                      timeFormat="HH:mm"
                      timeIntervals={60}
                      timeCaption="Time"
                      // selectsStart
                      id="FromDatePicker"
                      name="FromDatePicker"
                      className="from_date form_control form-control-sm unstyled"
                      startDate={startDate}
                      // endDate={endDate}
                      ref={changeDateRef}
                      onChange={changeStartDateHandler}
                    />
                    <span className="icon_date" />
                  </div>
                </li>
                <li>
                  <button
                    type="button"
                    className="btn_flat_custom"
                    onClick={() => handlePlusDay(startDate.add(1, "days"))}
                  >
                    <i className="fa fa-angle-right" />
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className="btn_flat_custom"
                    onClick={handleResetToday}
                  >
                    오늘
                  </button>
                </li>
              </ul>
            </div>
            <div className="main_select main_box">
            <div className="main_label">유형</div>
            <div className="custom_select">
              <select
                className={"form_control"}
                name="change_option"
                onChange={(e) => changeOption(e.target.value)}
                value={change_option}
              >
                <option value="e">
                  {props.intl.formatMessage({
                    id: "dashboard.e",
                    defaultMessage: "all",
                  })}
                </option>
                <option value="a">
                  {props.intl.formatMessage({
                    id: "dashboard.a",
                    defaultMessage: "All (inclusive)",
                  })}
                </option>
                <option value="b">
                  {props.intl.formatMessage({
                    id: "dashboard.b",
                    defaultMessage: "Received calls",
                  })}
                </option>
                <option value="c">
                  {props.intl.formatMessage({
                    id: "dashboard.c",
                    defaultMessage: "Sent calls",
                  })}
                </option>
                <option value="d">
                  {props.intl.formatMessage({
                    id: "dashboard.d",
                    defaultMessage: "All (including absence)",
                  })}
                </option>
                
              </select>
              <span className="item_dropdown" />
            </div>
          </div>
          </div>
          <div className="box_right_static">
            <div className="list_btn_control_static ">
                <button type="button" class="btn_control " disabled={query && query.change_option ? false : true} onClick={exportExcelHandler}>
                  <FormattedMessage
                    id="dashboard.ExcelDownload"
                    defaultMessage="Excel download"
                  />
                </button>
                <button type="button" class="btn_control " disabled={query && query.change_option ? false : true} onClick={printReportHandler}>
                  <FormattedMessage id="dashboard.Print" defaultMessage="Print" />
                </button>
            </div>
            </div>
        </div>
      </div>
    </div>
  );
}

export default injectIntl(HeaderComponent);
