import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Helmet } from 'react-helmet';
import queryString from 'query-string';
import NotificationSystem from 'react-notification-system';
import { Card, Button } from 'reactstrap';
import progressBar from '../../utils/progressBar';
import api from "../../utils/api";
import * as helpers from "../../utils/helpers";
import Pagination from "react-js-pagination";
import CreateUserModal from './../../components/UserRegistration/CreateUserModal';
import UpdateUserModal from './../../components/UserRegistration/UpdateUserModal';
import DeleteConfirmModal from './../../components/DeleteConfirmModal/DeleteConfirmModal';
import {
    fetchCompaniesAction
} from "../../store/actions/company";
import {
    auth,
    authFetchCurrentUser
} from "../../store/actions/auth";
import {
    createUserAction,
    updateUserAction,
    deleteUserAction,
    fetchUsersByInfoSaleLotAction,
    unmountUsersAction, updateSaleLotsAction
} from "../../store/actions";

import {changeInfoSaleLotAction} from '../../store/actions/auth'

const arrRoleExcept = [
    helpers.roles.primarynet,
    helpers.roles.admin,
    helpers.formatNumberPhone
]
const allRoles = helpers.roles;

class TelephoneCounselling extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            companies: {},
            saleLots: {}, 
            currentCompany: {},
            currentSaleLot: {},
            userForm: {},
            users: {
            },
            roles: [],
            deletingRow: null,
            cond: {
                search_in_company: '',
                lots_id: '',
                company_id: '',
                type: 'paginate',
            },
            openModalCreateUser: false,
            openModalUpdateUser: false,
            isDeleteModalOpen: false,
            isDeleteConfirmModalOpen: false,
            notification: {
                allowHTML: false,
            },
        };
        this.notificationSystem = React.createRef();

    }

    addNotification = (data) => {
        const notification = this.notificationSystem.current;
        notification.addNotification({
            message: data.message,
            title: data.title,
            level: data.level
        });
    };

    componentDidMount() {
        // paginate: get data Paginate Format  || list: get data List Format
        this.getCompanies();
    }

    getCompanies = () => {
        progressBar.start();
        let params = {
            page: this.state.companies?.current_page || 1,
            type: 'paginate'
        }
        this.props.onFetchCompanies(params).then(response => {
            this.setState({
                ...this.state,
                companies: response.data
            })
            progressBar.done();
        }).catch(error => {
            progressBar.done();
        });
    }

    // must used to 2nd parram to determine Click from companies List or Paginate
    getSaleLotsByCompanyFunc = (company, pageSaleLot = 1) => {
        progressBar.start();
        let params = {
            page: pageSaleLot,
            type: 'paginate',
        }
        api.saleLots.getSaleLotsByCompany(company.company_id, params).then(response => {
            this.setState({
                ...this.state,
                currentCompany: company,
                saleLots: response.data.data,
                currentSaleLot: {},
                users: {},
            })
            progressBar.done();
        }).catch(error => {
            progressBar.done();
        });
    }

    getUserList = (saleLot, page = 1) => {
        progressBar.start();
        this.setState({
            ...this.state,
            currentSaleLot: saleLot,
            users: {
                ...this.state.users,
                current_page: page,
            }
        }, () => {
            let params = {
                ...this.state.cond,
                page: this.state.users?.current_page || 1,
                lots_id: this.state.currentSaleLot?.lots_id,
                company_id: this.state.currentCompany?.company_id,
            }
            let query = queryString.stringify(params, { arrayFormat: 'bracket' });
            api.user.getUsersBySaleLot(query).then(response => {
                this.setState({
                    ...this.state,
                    users: response.data.data.users,
                    roles: response.data.data.roles,
                }, () => {
                })
                progressBar.done();
            }).catch(error => {
                progressBar.done();
            });
        })
    }

    changePageCompany = (pageNumber) => {
        this.setState({
            ...this.state,
            companies: {
                ...this.state.companies,
                current_page: pageNumber
            }
        }, () => {
            this.getCompanies();
        })
    }

    changePageSaleLot = (pageNumber) => {
        this.getSaleLotsByCompanyFunc(this.state.currentCompany, pageNumber);
    }

    // search list customer with current page
    changePageUser = (pageNumber) => {
        this.getUserList(this.state.currentSaleLot, pageNumber);
    }

    // search list customer with Reset page = 1
    handleSearch = (event) => {

        this.setState({
            ...this.state,
            cond: {
                ...this.state.cond,
                'search_in_company': event.target.value
            },
        }, () => {
            let { cond } = this.state;
            if (cond.search_in_company.length > 1 || cond.search_in_company.length == 0) {
                this.getUserList(this.state.currentSaleLot, 1)
            }
        });
    }

    fixedFormatter = (roles) => {

        if (roles && roles[0]) {
            let roleName = roles[0].name;
            let matchClass = ''
            switch (roleName) {
                case 'primarynet':
                    matchClass = 'primarynet';
                    break;
                case 'admin':
                    matchClass = 'admin';
                    break;
                case 'manager':
                    matchClass = 'manager';
                    break;
                case 'telephone':
                    matchClass = 'telephone';
                    break;
                case 'visiting':
                    matchClass = 'visiting';
                    break;
                case 'mgm':
                    matchClass = 'mgm';
                    break;
                default:
                    matchClass = 'callback';
                    break;
            }

            return roles && roles[0] && (
                <span className={`txt_s ${matchClass}`}>
                    { roles[0].display_name}
                </span>
            )
        } 
    }

     /**
     *  Modal Function
     */
    // Modal User
    toggleModalCreateUser = () => {
        this.setState({
            ...this.state,
            userForm: {},
            openModalCreateUser: !this.state.openModalCreateUser,
        }, () => {
            // console.log(this.state, 'state_after');
        });
    };

    toggleModalUpdateUser = () => {
        this.setState({
            ...this.state,
            openModalUpdateUser: !this.state.openModalUpdateUser,
        }, () => {
        });
    };


    /**
     * CRUD User
    */
    handleChangeInputUser = (event) => {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;


        this.setState({
            ...this.state,
            userForm: {
                ...this.state.userForm,
                [name]: value,
            }
        }, () => {
            // console.log(this.state.userForm,'this.state.userForm')
            //see_call always = 1 if role=admin||primarynet
            if (arrRoleExcept.includes(+this.state.userForm.role)) {
                this.setState({
                    ...this.state,
                    userForm: {
                        ...this.state.userForm,
                        'see_call': 1       //1: ON | 0: OFF
                    }
                })
            }
        });
    };

    handlePhoneInput = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        let { userForm } = this.state;
        if (value && helpers.checkValidPhone(value)) {
            this.setState({
                ...this.state,
                userForm: {
                    ...this.state.userForm,
                    [name]: helpers.phoneFormat_2(value),
                    // [name]: userForm.role && userForm.role == allRoles.callback ? helpers.formatNumberPhone(value) :helpers.phoneFormat_2(value),
                }
            }, () => {
            });
        }
    };

    onCreateUser = () => { 
        progressBar.start();

        let { currentCompany, currentSaleLot } = this.state;
        let user = this.state.userForm;
        user.company_id = currentCompany.company_id;
        user.lots_id = currentSaleLot.lots_id;
        user.role = user?.role || 4;                        //4: role Telephone Counselor
        user.usr_type_code = user?.role || 4;
        user.btn_save = user?.btn_save || 1;                //1: ON-default || 0: OFF     
        user.see_call = user?.see_call || 1;                //1: ON-default || 0: OFF   
        user.usr_tel = user?.usr_tel?.replace(/\D/g,'');
        if(user.role == helpers.roles.callback ){ // truong hop callback
            user.csta_id = "callback";
            user.csta_pwd = "callback";
            user.usr_password = helpers.makeRandomChar(10);
        }

        this.props.onCreateUser(user)
            .then(response => {
                this.toggleModalCreateUser();
                this.getUserList(currentSaleLot, 1);
                this.setState({
                    ...this.state,
                    userForm: {},
                    notification: {
                        allowHTML: false,
                    }
                }, () => {
                    this.addNotification({
                        title: <FormattedMessage
                            id="dashboard.Succeed"
                            defaultMessage="Succeed!" />,
                        message: <FormattedMessage
                            id="dashboard.User.CreateSucceed"
                            defaultMessage="Add new user successfully!" />, 
                        level: 'success',
                    })
                    progressBar.done();
                });
            })
            .catch(error => {
                if(error.status == 422){
                    const message = error.data.errors.message;
                    const firstMessage = message[Object.keys(message)[0]][0];
                    this.setState({
                        ...this.state,
                        notification: {
                            allowHTML: true,
                        }
                    }, () => {
                        this.addNotification({
                            title: <FormattedMessage
                                id="dashboard.Error"
                                defaultMessage="Error !" />,
                            level: 'error',
                            message: firstMessage,
                        })
                        progressBar.done();
                    })
                }
                // if (error.data) {
                //     // console.log(error.data.errors,'error.data.errors')
                //     // console.log(helpers.renderMessage(error.data.errors.message),'lkkkkkk')
                //
                // }
            });
    };

    editUser = (user) => {
        let role = user?.roles?.[0]?.role_id || 4;
        let user_2 = {
            ...user,
            role,
            usr_tel: user.usr_tel ? helpers.phoneFormat_2(user.usr_tel) : ''
            // usr_tel: role == allRoles.callback ? user.usr_tel : helpers.phoneFormat_2(user.usr_tel ? user.usr_tel : ''),
        }

        this.setState({
            ...this.state,
            userForm: user_2,
        }, () => {
            this.toggleModalUpdateUser();
            // console.log(this.state, 'afterrrrr');
        });
    };

    onUpdateUser = () => {
        progressBar.start();
        let { currentCompany, currentSaleLot } = this.state;  // nullable current salot
        let user = this.state.userForm;
        user.role = user?.role || 4;                        //4: role Telephone Counselor
        user.btn_save = user?.btn_save || 1;                //1: ON-default || 0: OFF     
        user.see_call = user?.see_call || 1;                //1: ON-default || 0: OFF     
        user.usr_tel = user?.usr_tel.replace(/\D/g,'');
        user.usr_type_code = user?.role || 4;

        // csta_pwd=null => delete csta_pwd in USER prevent update null
        !user.csta_pwd && delete user.csta_pwd;

        if(user.role == helpers.roles.callback ){ // truong hop callback
            user.csta_id = "callback";
            user.csta_pwd = "callback";
            user.usr_password = helpers.makeRandomChar(10);
        }


        this.props.onUpdateUser(user.usr_id, user).then(response => {   
            this.toggleModalUpdateUser();
            this.getUserList(currentSaleLot, 1);  // re-fetch new value current User selected 

            this.setState({
                ...this.state,
                userForm: {},
                notification: {
                    allowHTML: false
                }
            }, () => {
                api.auth.getCurrentUser().then(response => {
                    let dataUser = {
                        token: localStorage.getItem('token'),
                        user: response.data.data
                    };
                    this.props.onGetCurrentUser(dataUser)
                });

                this.addNotification({
                    title: <FormattedMessage
                        id="dashboard.Succeed"
                        defaultMessage="Succeed!"/>,
                    message: <FormattedMessage
                        id="dashboard.User.UpdateSucceed"
                        defaultMessage="Update User successfully!"/>,
                    level: 'success',
                })
                progressBar.done();
            });
        }).catch(error => {
            if (error.data) {
                this.setState({
                    ...this.state,
                    notification: {
                        allowHTML: true,
                    }
                }, () => {
                    this.addNotification({
                        title: <FormattedMessage
                                id="dashboard.Error"
                                defaultMessage="Error !"/>,
                        level: 'error',
                        message: helpers.renderMessage(error.data.errors.message),
                    })
                    progressBar.done();
                })
            };
        });
    };

    deleteUserHandler = () => {
        this.props.onDeleteUser(this.state.deletingRow).then(response => {
            // check and re-set current page if delete last item of last page
            let {users} = this.state; 
            let current_page = users.from == users.to ? users.last_page - 1 : users.current_page;

            this.setState({
                ...this.state,
                selection: [],
                isDeleteConfirmModalOpen: false,
                notification: {
                    allowHTML: false,
                },
                // customers: {
                //     ...this.state.customers,
                //     current_page,
                // }
            }, () => {
                this.getUserList(this.state.currentSaleLot, current_page);

                this.addNotification({
                    title: <FormattedMessage
                        id="dashboard.Succeed"
                        defaultMessage="Succeed!"/>,
                    message: <FormattedMessage
                        id="dashboard.User.DeleteSucceed"
                        defaultMessage="Delete User successfully!"/>,
                    level: 'success',
                })
                progressBar.done();
            })
        }).catch(error => {
            progressBar.done();
        });
    };


    setDeletingRow = (value) => {
        this.setState({
            ...this.state,
            deletingRow: value,
        }, () => {
            this.openDeleteModal();
        });
    };

    openDeleteModal = () => {
        this.setState({
            isDeleteModalOpen: !this.state.isDeleteModalOpen
        });
    };

    openDeleteConfirmModal = () => {
        this.setState({
            isDeleteConfirmModalOpen: !this.state.isDeleteConfirmModalOpen
        });
    };

    onChangeACD = (e,itemSaleLot,pageSaleLot) => {
        let saleLot = {
            ...itemSaleLot,
            acd : e.target.value
        }
        this.props.onUpdateSaleLot(saleLot.lots_id, saleLot).then(response => {
            console.log(response.data,'data')

            // thay doi xong neu lost id trung voi user thay doi redux
            let {auth} = this.props;


            if(saleLot.lots_id == auth.user.lots_id) {
                this.props.changeInfoSaleLot(response.data)
            }

            let params = {
                page: pageSaleLot,
                type: 'paginate',
            }



            api.saleLots.getSaleLotsByCompany(saleLot.company_id, params) .then(res => {
                this.setState({
                    saleLots: res.data.data,
                },()=>{
                    if(Object.keys(this.state.users).length > 0){
                        let pageUser = this.state.users && this.state.users.current_page ? this.state.users.current_page : 1;
                        this.getUserList(this.state.currentSaleLot,pageUser);
                    }
                })
            });

        });

    }

    render() {
        const {
            companies,
            saleLots,
            users,
            currentCompany,
            currentSaleLot,
            cond,
        } = this.state;

        // console.log(saleLots,'saleLots')

        return (
            <Fragment>
                <NotificationSystem ref={this.notificationSystem} allowHTML={this.state.notification.allowHTML}/>

                <FormattedMessage id="dashboard.UserRegistration"
                    defaultMessage="User Registration">
                    {message => <Helmet>
                        <title>{message}</title>
                    </Helmet>}
                </FormattedMessage>

                <div className="main_top_bar user_registion">
                    <div className="box_sum_col_top">
                        <div className="box_search">
                            <div className="box_control_input ">
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main_content three_column">
                    <div className="custom_col_2 custom_col">
                        <div className="heading">
                            <h5 className="main_title">
                                <FormattedMessage id="dashboard.Company" defaultMessage="Company" />
                            </h5>
                        </div>
                        <div className="content">
                            <div className="control_box_item">
                                <div className="card_body">
                                    <div className="custom_table">
                                        <table className="table st_table_5">
                                            <tbody>
                                                <tr>
                                                    <th> <FormattedMessage id="dashboard.Name" defaultMessage="Name" /> </th>
                                                </tr>
                                                {/* render companies list */}
                                                {
                                                    companies?.data?.length > 0 ?
                                                        companies.data.map((item, index) => {
                                                            let active = currentCompany?.company_id == item.company_id ? 'active' : '';
                                                            return (
                                                                <tr key={index} className={active} onClick={() => this.getSaleLotsByCompanyFunc(item, 1)}>
                                                                    <td>{item.company_name}</td>
                                                                </tr>
                                                            )
                                                        }) :
                                                        (
                                                            <tr>
                                                                <td>
                                                                    <div className="nodata">No Data</div>
                                                                </td>
                                                            </tr>
                                                        )
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                {/* render paginate companies list */}
                                {
                                    companies?.data?.length > 0 && (
                                        <div className="car_footer">
                                            <nav aria-label="...">
                                                <ul className="custom_pagination pagination">
                                                    <Pagination
                                                        itemClass="page-item"
                                                        linkClass="page-link"
                                                        activePage={companies?.current_page}
                                                        itemsCountPerPage={companies?.per_page}
                                                        totalItemsCount={companies?.total || 0}
                                                        pageRangeDisplayed={10}
                                                        onChange={this.changePageCompany}
                                                    />
                                                </ul>
                                            </nav>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className="custom_col_2 custom_col">
                        <div className="heading">
                            <h5 className="main_title">
                                <FormattedMessage id="dashboard.AffiliatedCompany" defaultMessage="Affiliated company" />
                            </h5>
                        </div>
                        <div className="content">
                            <div className="control_box_item">
                                <div className="card_body">
                                    <div className="custom_table">
                                        <table className="table st_table_5">
                                            <tbody>
                                                <tr>
                                                    <th> <FormattedMessage id="dashboard.Name" defaultMessage="Name" /> </th>
                                                    <th> ACD </th>
                                                </tr>
                                                {/* render info saleLots list */}
                                                {
                                                    saleLots?.data?.length > 0 ?
                                                        saleLots.data.map((item, index) => {
                                                            let active = this.state.currentSaleLot?.lots_id == item.lots_id ? 'active' : '';
                                                            return (
                                                                <tr key={index} className={active}>
                                                                    <td onClick={() => this.getUserList(item, 1)}>
                                                                        {item.lots_name}
                                                                    </td>
                                                                    <td>
                                                                        <select
                                                                            name=""
                                                                            className={'form-control'}
                                                                            onChange={(e) => this.onChangeACD(e,item,saleLots.current_page || 1)}
                                                                            value={item.acd}
                                                                        >
                                                                            <option value="0">Hunt</option>
                                                                            <option value="1">ACD</option>
                                                                        </select>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }) :
                                                        (
                                                            <tr>
                                                                <td colSpan={2}>
                                                                    <div className="nodata">No Data</div>
                                                                </td>
                                                            </tr>
                                                        )
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                {/* render paginate saleLots list */}
                                {
                                    saleLots?.data?.length > 0 && (
                                        <div className="car_footer">
                                            <nav aria-label="...">
                                                <ul className="custom_pagination pagination">
                                                    <Pagination
                                                        itemClass="page-item"
                                                        linkClass="page-link"
                                                        activePage={saleLots?.current_page}
                                                        itemsCountPerPage={saleLots?.per_page}
                                                        totalItemsCount={saleLots?.total || 0}
                                                        pageRangeDisplayed={10}
                                                        onChange={this.changePageSaleLot}
                                                    />
                                                </ul>
                                            </nav>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className="custom_col_full custom_col">
                        <div className="heading">
                            <h5 className="main_title">
                                <FormattedMessage id="dashboard.Customer" defaultMessage="Customer" />
                            </h5>
                        </div>
                        <div className="content">
                            <div className="control_box_item">
                                <div className="card_header">
                                    <div className="box_search">
                                        <div className="box_control_input ">
                                            <input type="text"
                                                name="sub_search"
                                                value={cond.search}
                                                onChange={this.handleSearch}
                                                className="control"/>
                                            <button className="btn_form btn_secondary"><i className="fas fa-search" /></button>
                                        </div>
                                    </div>
                                    <div className="box_add">
                                        <button 
                                            type="button"
                                            className="btn btn_add"
                                            onClick={this.toggleModalCreateUser}
                                            disabled={!currentSaleLot.lots_id}
                                        >
                                            <i className="fas fa-plus" />
                                        </button>
                                    </div>
                                </div>
                                <div className="card_body">
                                    <div className="custom_table table_respontive">
                                        <table className="table st_table_5">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <FormattedMessage id="dashboard.userCode" defaultMessage="userCode" />
                                                    </th>
                                                    <th>
                                                        <FormattedMessage id="dashboard.NewPassword" defaultMessage="NewPassword" />
                                                    </th>
                                                    <th>
                                                        <FormattedMessage id="dashboard.CounselorName" defaultMessage="CounselorName" />
                                                    </th>
                                                    <th>
                                                        <FormattedMessage id="dashboard.Phone" defaultMessage="Phone" />
                                                    </th>
                                                    <th>
                                                        <FormattedMessage id="dashboard.CSTA_ID" defaultMessage="CSTA_ID" />
                                                    </th>
                                                    <th>
                                                        <FormattedMessage id="dashboard.CSTA_PWD" defaultMessage="CSTA_PWD" />
                                                    </th>
                                                    <th>
                                                        <FormattedMessage id="dashboard.Authority" defaultMessage="Authority" />
                                                    </th>
                                                    <th>
                                                        <FormattedMessage id="dashboard.BtnSave" defaultMessage="BtnSave" />
                                                    </th>
                                                    <th>
                                                        <FormattedMessage id="dashboard.SeeCall" defaultMessage="SeeCall" />
                                                    </th>
                                                    <th />
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {/* render users list */}
                                                {
                                                    users?.data?.length > 0 ?
                                                        users.data.map((item, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{item.usr_code}</td>
                                                                    <td></td>
                                                                    <td>{item.usr_name}</td>
                                                                    {/* <td>{helpers.phoneFormat_2(item.usr_tel)}</td> */}
                                                                    <td>{item.usr_tel}</td>
                                                                    <td>{item.csta_id}</td>
                                                                    <td></td>
                                                                    <td>
                                                                        {this.fixedFormatter(item.roles)}
                                                                    </td>
                                                                    <td>{item.btn_save == 1 ? 'ON' : 'OFF'}</td>
                                                                    <td>{item.see_call == 1 ? 'ON' : 'OFF'}</td>
                                                                    <td className="inline_action">
                                                                        <a onClick={() => this.editUser(item)}> <i className="fas fa-edit" /> </a>
                                                                        <a onClick={() => this.setDeletingRow(item.usr_id)}> <i className="fas fa-trash-alt" /> </a>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }) :
                                                        (
                                                            <tr>
                                                                <td colSpan={10}>
                                                                    <div className="nodata">No Data</div>
                                                                </td>
                                                            </tr>
                                                        )
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {/* render paginate companies list */}
                                {
                                    users?.data?.length > 0 && (
                                        <div className="car_footer">
                                            <nav aria-label="...">
                                                <ul className="custom_pagination pagination">
                                                    <Pagination
                                                        itemClass="page-item"
                                                        linkClass="page-link"
                                                        activePage={users?.current_page}
                                                        itemsCountPerPage={users?.per_page}
                                                        totalItemsCount={users?.total || 0}
                                                        pageRangeDisplayed={10}
                                                        onChange={this.changePageUser}
                                                    />
                                                </ul>
                                            </nav>
                                        </div>
                                    )
                                }

                            </div>
                        </div>
                    </div>
                </div>


                  {/* Modal Component List  */}

                <CreateUserModal
                    isOpen={this.state.openModalCreateUser}
                    toggle={this.toggleModalCreateUser}
                    roles={this.state.roles}
                    userForm={this.state.userForm}
                    handleChange={this.handleChangeInputUser}
                    handlePhoneInput={this.handlePhoneInput}
                    onCreateUser={this.onCreateUser}
                    // addImageAPICRM={this.addImageAPICRM}
                />

                <UpdateUserModal
                    isOpen={this.state.openModalUpdateUser}
                    toggle={this.toggleModalUpdateUser}
                    roles={this.state.roles}
                    userForm={this.state.userForm}
                    handleChange={this.handleChangeInputUser}
                    handlePhoneInput={this.handlePhoneInput}
                    onCreateUser={this.onUpdateUser}
                />

                <DeleteConfirmModal
                    isDeleteModalOpen={this.state.isDeleteModalOpen}
                    openDeleteModal={this.openDeleteModal}
                    isDeleteConfirmModalOpen={this.state.isDeleteConfirmModalOpen}
                    openDeleteConfirmModal={this.openDeleteConfirmModal}
                    deleteInfoHandler={this.deleteUserHandler}
                />
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchCompanies: (params = { type: 'paginate' }) => dispatch(fetchCompaniesAction(params)),
        // onFetchCustomerList: (query) => dispatch(fetchCustomerListAction(query)),

        onFetchUsersByInfoSaleLot: (infoSaleLotId) => dispatch(fetchUsersByInfoSaleLotAction(infoSaleLotId)),
        onCreateUser: user => dispatch(createUserAction(user)),
        onUpdateUser: (id, user) => dispatch(updateUserAction(id, user)),
        onDeleteUser: id => dispatch(deleteUserAction(id)),
        onUnmountUsers: () => dispatch(unmountUsersAction()),

        onGetCurrentUser: (data) => dispatch(authFetchCurrentUser(data)),
        onUpdateSaleLot: (id, saleLots) => dispatch(updateSaleLotsAction(id, saleLots)),
        changeInfoSaleLot : data => dispatch(changeInfoSaleLotAction(data)), // thay doi infoSalelot redux

    }
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(TelephoneCounselling));
