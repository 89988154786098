import {combineReducers} from 'redux';
import auth from './auth';
import appState from './appState';
import callList from "./callList";
import companies from './companies';
import company from './company';
import saleLots from './saleLots';
import report from './report';
import placementRegistered from './MgmAdmin/placementRegistered';
import call from './call';
import callListSearchByCallNumber from './searchCallByNumber';
import reportTelephone from './reportTelephone';
import messages from './messages';
import directMessage from './directMessage'
import sceneSelect from "./sceneSelect";

export default combineReducers({
    auth: auth,
    appState: appState,
    callList,
    companies: companies,
    company: company,
    saleLots: saleLots,
    placementRegistered,
    report: report,
    call,// redux 1 call
    callListSearchByCallNumber,
    reportTelephone,
    messages,
    directMessage,
    sceneSelect,
});
