import React from 'react';
import {Modal} from "reactstrap";
import {formatNumberPhone} from "../../../utils/helpers";



function ModalForwardCall (props) {

    const {
        isOpen,
        toggle,
        forwardCall,
        changeForwardCall,
        submitForwardCall
    } = props

    return(
        <Modal
            isOpen={isOpen}
            toggle={()=>toggle()}
            className={'modal-dialog-scrollable'}
            wrapClassName={'modal_notification_edit custom_modal_sum'}
        >
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">착신전환 번호 입력</h5>
                    <button
                        type="button" className="close"
                        onClick={()=>toggle()}
                    >
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="box_notification_edit">
                        <div className="box_input_edit">
                            <input
                                type="text"
                                className="control_inut"
                                value={forwardCall}
                                onChange={(e)=>changeForwardCall(formatNumberPhone(e.target.value))}
                            />
                            <label className="icon_edit"><i className="fas fa-edit"></i></label>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        className="btn btn_light "
                        onClick={()=>toggle()}
                    >
                        닫기
                    </button>
                    <button
                        type="button"
                        className="btn btn_blue"
                        onClick={()=>submitForwardCall()}
                    >
                        입력
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default ModalForwardCall;