import {
    FETCH_PREDEFINE_SMS,
    FETCH_PREDEFINE_SMS_SUCCEED,
    FETCH_PREDEFINE_SMS_FAILED,
    UNMOUNT_PREDEFINE_SMS,
} from './actionTypes'

import api from '../../utils/api';

export const fetchPredefineSMS = () => {
    return {
        type: FETCH_PREDEFINE_SMS,
    }
};

export const fetchPredefineSMSSucceed = data => {
    return {
        type: FETCH_PREDEFINE_SMS_SUCCEED,
        payload: {
            data: data
        }
    }
};

export const fetchPredefineSMSFailed = error => {
    return {
        type: FETCH_PREDEFINE_SMS_FAILED,
        payload: {
            error: error
        }
    }
};

export const unmountPredefineSMS = () => {
    return {
        type: UNMOUNT_PREDEFINE_SMS
    }
};


export const fetchPredefineSMSAction = (id, params) => dispatch => {
    dispatch(fetchPredefineSMS());
    return api.predefinesms.fetchPredefineSMS(id, params)
        .then(response => {
            dispatch(fetchPredefineSMSSucceed(response.data));
            return Promise.resolve(response.data);
        })
        .catch(error => {
            dispatch(fetchPredefineSMSFailed(error.response.data.errors.message));
            return Promise.reject(error.response)
        });
};


export const unmountPredefineSMSAction = () => dispatch => {
    dispatch(unmountPredefineSMS())
};


// Create PredefineSMSAction

export const createPredefineSMSAction = (data) => dispatch => {
    // dispatch(fetchPredefineSMS());
    return api.predefinesms.cretePredefineSMS(data)
        .then(response => {
            // dispatch(fetchPredefineSMSSucceed(response.data));
            return Promise.resolve(response.data);
        })
        .catch(error => {
            // dispatch(fetchPredefineSMSFailed(error.response.data.errors.message));
            return Promise.reject(error.response)
        });
};


// delete textManagerAction

export const deletePredefineSMSAction = (id) => dispatch => {
    return api.predefinesms.deletePredefineSMS(id)
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            return Promise.reject(error.response)
        });
};

// Find text manager
export const findPredefineSMSAction = (id) => dispatch => {
    return api.predefinesms.findPredefineSMS(id)
        .then(response => {
            return Promise.resolve(response.data.data);
        })
        .catch(error => {
            return Promise.reject(error.response)
        });
};

// Delete image detail
export const deleteImageDetailAction = (id) => dispatch => {
    return api.predefinesms.deleteImageDetail(id)
        .then(response => {
            return Promise.resolve(response.data.data);
        })
        .catch(error => {
            return Promise.reject(error.response)
        });
};


// Update

export const updatePredefineSMSAction = (id,data) => dispatch => {
    return api.predefinesms.updatePredefineSMS(id,data)
        .then(response => {
            return Promise.resolve(response.data.data);
        })
        .catch(error => {
            return Promise.reject(error.response)
        });
};


// fetch auto sending

export const autoSendingAction = (id) => dispatch => {
    return api.predefinesms.fetchAutoSending(id)
        .then(response => {
            return Promise.resolve(response.data.data);
        })
        .catch(error => {
            return Promise.reject(error.response)
        });
};


export const createDirectAction = (data) =>dispatch => {
    return api.predefinesms.createDirect(data)
        .then(response => {
            return Promise.resolve(response.data.data);
        })
        .catch(error => {
            return Promise.reject(error.response)
        });
}

export const fetchDirectAction = () => dispatch => {
    return api.predefinesms.fetchDirect()
        .then(response => {
            return Promise.resolve(response.data.data);
        })
        .catch(error => {
            return Promise.reject(error.response)
        });
}


export const createMessageHistoryAction = (data) => dispatch => {
    return api.predefinesms.createMessageHistory(data)
        .then(response => {
            return Promise.resolve(response.data.data);
        })
        .catch(error => {
            return Promise.reject(error.response)
        });
}

export const removeOneImageAction = (id) => dispatch => {
    return api.predefinesms.removeOneImage(id)
        .then(response => {
            return Promise.resolve(response.data.data);
        })
        .catch(error => {
            return Promise.reject(error.response)
        });
}
// import excel

export const importExcelAction = (data) => dispatch => {
    return api.predefinesms.importExcel(data)
        .then(response => {
            return Promise.resolve(response.data.data);
        })
        .catch(error => {
            return Promise.reject(error.response)
        });
}



// get infoSaleLot
export const getInfoSaleLotAction = (company_id) => dispatch => {
    return api.predefinesms.getInfoSaleLot(company_id)
        .then(response => {
            return Promise.resolve(response.data.data);
        })
        .catch(error => {
            return Promise.reject(error.response)
        });
}
