import React, {Fragment} from 'react'
import android_step1 from '../../assets/images/document/getting_started/android_step1.jpg';
import android_step2 from '../../assets/images/document/getting_started/android_step2.jpg';
import android_step3 from '../../assets/images/document/getting_started/android_step3.jpg';
import ios_step1 from '../../assets/images/document/getting_started/ios_step1.png';
import ios_step2 from '../../assets/images/document/getting_started/ios_step2.png';
import ios_step3 from '../../assets/images/document/getting_started/ios_step3.png';
import ios_step4 from '../../assets/images/document/getting_started/ios_step4.png';

const gettingStarted = props => {

    return (
        <Fragment>
            <div>
                <section className="resume-section d-flex d-column" id="about">
                    <div className="my-auto">
                        <h1 className="mb-0">분양
                            <span className="text-primary"> CRM</span>
                        </h1>
                        {/*<div className="subheading mb-5">3542 Berry Street · Cheyenne Wells, CO 80810 · (317) 585-8468 ·*/}
                            {/*<a href="mailto:name@email.com">name@email.com</a>*/}
                        {/*</div>*/}
                    </div>
                </section>
                <section className="resume-section d-flex flex-column" id="experience">
                    <div className="my-auto">
                        <h2 className="mb-5">구성</h2>
                        <div className="resume-item d-flex flex-column flex-md-row mb-5">
                            <div className="resume-content mr-auto">
                                <h3 className="mb-0">데스크톱</h3>
                                <div className="subheading mb-3"></div>
                                <p>
                                    시스템의 안정성 및 기능 확보를 위한 구글 크롬 브라우저를 사용하는 것을 추천합니다.</p>
                                <p>구글 브라우저가 설치되어 있지 않으면
                                    <a href="https://www.google.com/intl/ko_kr/chrome/"> 여기서</a>다운로드 가능합니다.</p>
                                <p>구글 브라우저 외에도
                                    <a href="https://www.mozilla.org/ko/firefox/channel/desktop/"> Firefox </a>
                                    또는 <a href="https://www.microsoft.com/ko-kr/windows/microsoft-edge"> Microsoft Edge</a> 를 사용 가능합니다.</p>
                                <p><b>참고사항: </b> 현재 Internet Explorer는 지원하지 않습니다.  </p>
                            </div>
                        </div>
                        {/*<div className="resume-item d-flex flex-column flex-md-row mb-5">*/}
                            {/*<div className="resume-content mr-auto">*/}
                                {/*<h3 className="mb-0">Thiết bị di động</h3>*/}
                                {/*<div className="subheading mb-3">Android</div>*/}
                                {/*<p>Nếu bạn sử dụng điện thoại hoặc máy tính bảng sử dụng hệ điều hành Android thì có thể*/}
                                 {/*cài đặt và sử dụng Eple-CRM website như một Ứng dụng điện thoại bình thường</p>*/}
                                {/*<p>Sử dụng trình duyện Google Chrome trên thiết bị di đông để truy cập vào website Eple-CRM*/}
                                 {/*sau đó làm theo các bước sau:</p>*/}
                                {/*<p><b>Bước 1:</b> Mở menu của Google Chrome và chọn Add to Home Screen.</p>*/}
                                {/*<div className="col-md-8">*/}
                                    {/*<img src={android_step1}/>*/}
                                {/*</div>*/}
                                {/*<br/>*/}
                                {/*<p><b>Bước 2:</b> Chọn Add để tiếp tục.</p>*/}
                                {/*<div className="col-md-8">*/}
                                    {/*<img src={android_step2}/>*/}
                                {/*</div>*/}
                                {/*<br/>*/}
                                {/*<p><b>Bước 3:</b> Sau khi hoàn thành, ở Home Screen sẽ xuất hiện icon*/}
                                {/*của Eple-CRM, bạn có thể chọn nó để vào nhanh ứng dụng</p>*/}
                                {/*<div className="col-md-8">*/}
                                    {/*<img src={android_step3}/>*/}
                                {/*</div>*/}
                                {/*<br/>*/}
                                {/*<div className="subheading mb-3">iOS</div>*/}
                                {/*<p>Tương tự như Android, nếu bạn sử dụng iPhone hoặc iPad thì có thể*/}
                                    {/*cài đặt và sử dụng Eple-CRM website như một Ứng dụng điện thoại bình thường</p>*/}
                                {/*<p>Sử dụng trình duyện Safari để truy cập vào website Eple-CRM*/}
                                    {/*sau đó làm theo các bước sau:</p>*/}
                                {/*<p><b>Bước 1:</b> Mở menu của Safari</p>*/}
                                {/*<div className="col-md-8">*/}
                                    {/*<img src={ios_step1}/>*/}
                                {/*</div>*/}
                                {/*<br/>*/}
                                {/*<p><b>Bước 2:</b> Chọn Add to Home Screen để tiếp tục.</p>*/}
                                {/*<div className="col-md-8">*/}
                                    {/*<img src={ios_step2}/>*/}
                                {/*</div>*/}
                                {/*<br/>*/}
                                {/*<p><b>Bước 3:</b> Chọn Add để tiếp tục.</p>*/}
                                {/*<div className="col-md-8">*/}
                                    {/*<img src={ios_step3}/>*/}
                                {/*</div>*/}
                                {/*<br/>*/}
                                {/*<p><b>Bước 3:</b> Sau khi hoàn thành, ở Home Screen sẽ xuất hiện icon*/}
                                    {/*của Eple-CRM, bạn có thể chọn nó để vào nhanh ứng dụng</p>*/}
                                {/*<div className="col-md-8">*/}
                                    {/*<img src={ios_step4}/>*/}
                                {/*</div>*/}
                                {/*<br/>*/}

                            {/*</div>*/}
                        {/*</div>*/}
                    </div>

                </section>

                {/*<section className="resume-section d-flex flex-column" id="education">*/}
                    {/*<div className="my-auto">*/}
                        {/*<h2 className="mb-5">Education</h2>*/}

                        {/*<div className="resume-item d-flex flex-column flex-md-row mb-5">*/}
                            {/*<div className="resume-content mr-auto">*/}
                                {/*<h3 className="mb-0">University of Colorado Boulder</h3>*/}
                                {/*<div className="subheading mb-3">Bachelor of Science</div>*/}
                                {/*<div>Computer Science - Web Development Track</div>*/}
                                {/*<p>GPA: 3.23</p>*/}
                            {/*</div>*/}
                            {/*<div className="resume-date text-md-right">*/}
                                {/*<span className="text-primary">August 2006 - May 2010</span>*/}
                            {/*</div>*/}
                        {/*</div>*/}

                        {/*<div className="resume-item d-flex flex-column flex-md-row">*/}
                            {/*<div className="resume-content mr-auto">*/}
                                {/*<h3 className="mb-0">James Buchanan High School</h3>*/}
                                {/*<div className="subheading mb-3">Technology Magnet Program</div>*/}
                                {/*<p>GPA: 3.56</p>*/}
                            {/*</div>*/}
                            {/*<div className="resume-date text-md-right">*/}
                                {/*<span className="text-primary">August 2002 - May 2006</span>*/}
                            {/*</div>*/}
                        {/*</div>*/}

                    {/*</div>*/}
                {/*</section>*/}

                {/*<section className="resume-section d-flex flex-column" id="skills">*/}
                    {/*<div className="my-auto">*/}
                        {/*<h2 className="mb-5">Skills</h2>*/}

                        {/*<div className="subheading mb-3">Programming Languages &amp; Tools</div>*/}
                        {/*<ul className="list-inline list-icons">*/}
                            {/*<li className="list-inline-item">*/}
                                {/*<i className="devicons devicons-html5"></i>*/}
                            {/*</li>*/}
                            {/*<li className="list-inline-item">*/}
                                {/*<i className="devicons devicons-css3"></i>*/}
                            {/*</li>*/}
                            {/*<li className="list-inline-item">*/}
                                {/*<i className="devicons devicons-javascript"></i>*/}
                            {/*</li>*/}
                            {/*<li className="list-inline-item">*/}
                                {/*<i className="devicons devicons-jquery"></i>*/}
                            {/*</li>*/}
                            {/*<li className="list-inline-item">*/}
                                {/*<i className="devicons devicons-sass"></i>*/}
                            {/*</li>*/}
                            {/*<li className="list-inline-item">*/}
                                {/*<i className="devicons devicons-less"></i>*/}
                            {/*</li>*/}
                            {/*<li className="list-inline-item">*/}
                                {/*<i className="devicons devicons-bootstrap"></i>*/}
                            {/*</li>*/}
                            {/*<li className="list-inline-item">*/}
                                {/*<i className="devicons devicons-wordpress"></i>*/}
                            {/*</li>*/}
                            {/*<li className="list-inline-item">*/}
                                {/*<i className="devicons devicons-grunt"></i>*/}
                            {/*</li>*/}
                            {/*<li className="list-inline-item">*/}
                                {/*<i className="devicons devicons-gulp"></i>*/}
                            {/*</li>*/}
                            {/*<li className="list-inline-item">*/}
                                {/*<i className="devicons devicons-npm"></i>*/}
                            {/*</li>*/}
                        {/*</ul>*/}

                        {/*<div className="subheading mb-3">Workflow</div>*/}
                        {/*<ul className="fa-ul mb-0">*/}
                            {/*<li>*/}
                                {/*<i className="fa-li fa fa-check"></i>*/}
                                {/*Mobile-First, Responsive Design*/}
                            {/*</li>*/}
                            {/*<li>*/}
                                {/*<i className="fa-li fa fa-check"></i>*/}
                                {/*Cross Browser Testing &amp; Debugging*/}
                            {/*</li>*/}
                            {/*<li>*/}
                                {/*<i className="fa-li fa fa-check"></i>*/}
                                {/*Cross Functional Teams*/}
                            {/*</li>*/}
                            {/*<li>*/}
                                {/*<i className="fa-li fa fa-check"></i>*/}
                                {/*Agile Development &amp; Scrum*/}
                            {/*</li>*/}
                        {/*</ul>*/}
                    {/*</div>*/}
                {/*</section>*/}

                {/*<section className="resume-section d-flex flex-column" id="interests">*/}
                    {/*<div className="my-auto">*/}
                        {/*<h2 className="mb-5">Interests</h2>*/}
                        {/*<p>Apart from being a web developer, I enjoy most of my time being outdoors. In the winter, I am*/}
                            {/*an avid skiier and novice ice climber. During the warmer months here in Colorado, I enjoy*/}
                            {/*mountain biking, free climbing, and kayaking.</p>*/}
                        {/*<p className="mb-0">When forced indoors, I follow a number of sci-fi and fantasy genre movies*/}
                            {/*and television shows, I am an aspiring chef, and I spend a large amount of my free time*/}
                            {/*exploring the latest technolgy advancements in the front-end web development world.</p>*/}
                    {/*</div>*/}
                {/*</section>*/}

                {/*<section className="resume-section d-flex flex-column" id="awards">*/}
                    {/*<div className="my-auto">*/}
                        {/*<h2 className="mb-5">Awards &amp; Certifications</h2>*/}
                        {/*<ul className="fa-ul mb-0">*/}
                            {/*<li>*/}
                                {/*<i className="fa-li fa fa-trophy text-warning"></i>*/}
                                {/*Google Analytics Certified Developer*/}
                            {/*</li>*/}
                            {/*<li>*/}
                                {/*<i className="fa-li fa fa-trophy text-warning"></i>*/}
                                {/*Mobile Web Specialist - Google Certification*/}
                            {/*</li>*/}
                            {/*<li>*/}
                                {/*<i className="fa-li fa fa-trophy text-warning"></i>*/}
                                {/*1*/}
                                {/*Place - University of Colorado Boulder - Emerging Tech Competition 2009*/}
                            {/*</li>*/}
                            {/*<li>*/}
                                {/*<i className="fa-li fa fa-trophy text-warning"></i>*/}
                                {/*1*/}
                                {/*Place - University of Colorado Boulder - Adobe Creative Jam 2008 (UI Design Category)*/}
                            {/*</li>*/}
                            {/*<li>*/}
                                {/*<i className="fa-li fa fa-trophy text-warning"></i>*/}
                                {/*2*/}
                                {/*Place - University of Colorado Boulder - Emerging Tech Competition 2008*/}
                            {/*</li>*/}
                            {/*<li>*/}
                                {/*<span>*/}
                                    {/*<i className="fa-li fa fa-trophy text-warning"></i>*/}
                                    {/*1*/}
                                    {/*Place - James Buchanan High School - Hackathon 2006*/}
                                {/*</span>*/}
                                {/*<span>*/}
                                    {/*<i className="fa-li fa fa-trophy text-warning"></i>*/}
                                    {/*3*/}
                                    {/*Place - James Buchanan High School - Hackathon 2005*/}
                                {/*</span>*/}
                            {/*</li>*/}
                        {/*</ul>*/}
                    {/*</div>*/}
                {/*</section>*/}
            </div>
        </Fragment>
    )
}

export default gettingStarted;