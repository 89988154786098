import React, {Component} from 'react';
import NotificationSystem from 'react-notification-system';

let _notificationSystem = null;


class notification extends Component {
    constructor() {
        super()
        this._notificationSystem = null
        this.state = {
            isAlert: false,
            allowHTML: true
        }
    }

    // static getDerivedStateFromProps(nextProps, prevState) {
    //     return {
    //         isAlert: nextProps.isAlert
    //     };

    // }


    componentDidUpdate() {
        if (this.state.isAlert) {
            this._addNotification(this)
        }
    }


    _addNotification(event) {
        //event.preventDefault();
        let errorMessage = '';
        if (typeof this.props.message === 'object' &&
            this.props.type === 'server') {
            for (let key in this.props.message) {
                //let errorMessage = '';
                if (this.props.message.hasOwnProperty(key)) {
                    this.props.message[key].map(row => {
                        errorMessage = errorMessage + row + '<br/>'
                    });
                }
            }
            // if(!this.props.allowHTML) {
            //     console.log('In here')
            //     this.setState({
            //         allowHTML: true
            //     })
            // }
        } else {
            errorMessage = this.props.message;
            // if (this.props.allowHTML) {
            //     this.setState({
            //         allowHTML: this.props.allowHTML
            //     })
            // }

        }
        if( this.props.allowHTML === false) {
            this.setState({
                allowHTML: false
            })
        }else {
            this.setState({
                allowHTML: true
            })
        }
        if (this._notificationSystem && errorMessage !== '') {
            this._notificationSystem.addNotification({
                ...this.props,
                title: this.props.title,
                message: errorMessage,
                level: this.props.level,
                position: this.props.position,
                onAdd: () => this.props.onAdd(),
                // ...this.props
            })
        }

        // Check allowHTML


        // if (this._notificationSystem) {
        //     this._notificationSystem.addNotification({
        //         title: this.props.title,
        //         message: this.props.message,
        //         level: this.props.level,
        //         position: this.props.position,
        //         onAdd: () => this.props.onAdd()
        //     })
        // }
    }


    render() {

        return (
            <div>
                {/*<button onClick={}>Add notification</button>*/}
                <NotificationSystem ref={n => this._notificationSystem = n}
                                    allowHTML={this.state.allowHTML}
                                    />
            </div>
        );
    }
};


export default notification;