import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Helmet } from 'react-helmet';
import { NavLink, Link } from "react-router-dom";
import * as routerName from "../../../utils/routerName";
import moment from "moment/moment";
import queryString from 'query-string';
import DatePicker from 'react-datepicker';
import NotificationSystem from 'react-notification-system';
import { Card, Button } from 'reactstrap';
import progressBar from '../../../utils/progressBar';
import * as helpers from './../../../utils/helpers';
import Pagination from "react-js-pagination";
import api from "../../../utils/api";
import "react-datepicker/dist/react-datepicker.css";
import { exportAnalysisAction} from "../../../store/actions/calls";
import _ from 'lodash';

class RowContentTable extends React.Component {
    render() {
        const {row} = this.props
        return (
            <Fragment>
                     <tr>
                        <td colSpan="1" className="while_space custom"> {row.time} </td>
                        <td colSpan="1" className="custom"> {row.total} </td>
                        {
                            Object.keys(row).map(function(key, index) {
                                if ( _.isObject(row[key]) ){
                                    return (<td key={index}> {row[key].count } </td>)
                                }
                            })
                        }
                    </tr>
            </Fragment>
        )
    }
};


class Analysis extends Component {
    constructor(props) {
        super(props);
        this.state = {
            analysisList: {
                current_page: 1,
                total: 0,
            },
            analysisData: {},
            cond: {
                startDate: moment().startOf('month'),
                endDate: moment(),
                // startDate: moment('2020-12'),
                // endDate: moment('2020-12'),
                currentType:'day',
                items: [],
                export_excel: '',
            },
            infoMasts: [],

            // Notification
            notification: {
                allowHTML: false,
            },        
            formatDate: "YYYY/MM/DD",
            // formatDate: "YYYY/MM",
            checkAll: false,
						viewHideOption: 0
        }
    }

    notificationSystem = React.createRef();
    addNotification = (data) => {
        const notification = this.notificationSystem.current;
        notification.addNotification({
            message: data.message,
            title: data.title,
            level: data.level,
        });
    };

    componentDidMount() {
        // this.getCallHistory();
        this.getInfoMastByCompanyFunc(true, this.props.sceneCompanyId);
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.isChangeScene) {
            this.getInfoMastByCompanyFunc(true, nextProps.sceneCompanyId)
        }
    }

    getInfoMastByCompanyFunc = (callTable=false, companyId) => {
        let params = {
            type: 'list',
        }
        // null => server auto get company_id depend token (auth_user()->company_id))
        api.infoMast.getInfoMastByCompany(companyId, params).then(response => {
            this.setState({
                ...this.state,
                infoMasts: response.data.data,
            }, () => {
                // CHECK to call main data table
                if (callTable) {
                    let { infoMasts } = this.state;
                    let items = infoMasts?.map((item, index) => {
                        if (this.state.viewHideOption == 1 || item.m_show == 1) return +item.m_id
                    });

                    this.setState({
                        ...this.state,
                        cond: {
                            ...this.state.cond,
                            items,
                        }
                    }, () => {
                        this.getAnalysisData();
                        // console.log(this.state.cond.items, 'itemmmm_1');
                    });
                }
            });       

        })
    }

    // getAnalysisList = (pageNumber=1) => { 
    //     progressBar.start();
    //     let params = {
    //         ...this.state.cond,
    //         page: pageNumber,
    //     };
    //     let query = queryString.stringify(params, {arrayFormat: 'bracket'});

    //     return api.statistics.getAnalysisData(query).then(response => {
    //         this.setState({
    //             ...this.state,                     
    //             analysisList: response?.data?.data,
    //         }, () => {
    //             console.log(this.state.analysis, 'state_af');
    //             progressBar.done();
    //         });
    //     }).catch(error => {
    //         progressBar.done();
    //     });      
    // };

    getAnalysisData = () => {
        progressBar.start();
        let scene = {};
        if(this.props.sceneCompanyId) {
            scene['company_id'] = this.props.sceneCompanyId
        }
        if(this.props.sceneLostId) {
            scene['lots_id'] = this.props.sceneLostId
        }
        let params = {
            ...{...this.state.cond, ...scene},
            table_excel: true,
            export_excel: true,
        };
        let query = queryString.stringify(params, {arrayFormat: 'bracket'});

        return api.statistics.getAnalysisData(query).then(response => {
            this.setState({
                ...this.state,                     
                analysisData: response?.data?.data,
            }, () => {
                // console.log(this.state.analysisData, 'staanalysisData_af');
                progressBar.done();
            });
        }).catch(error => {
            progressBar.done();
        });      
    };

    // exportExcelHandler Func
    exportExcelHandler = () => {
        progressBar.start();
        let scene = {};
        if(this.props.sceneCompanyId) {
            scene['company_id'] = this.props.sceneCompanyId
        }
        if(this.props.sceneLostId) {
            scene['lots_id'] = this.props.sceneLostId
        }
        let params = {
            ...{...this.state.cond, ...scene},
            export_excel: true
        };
        let query = queryString.stringify(params, {arrayFormat: 'bracket'});
        this.props.onAnalysisExportExcel(query).then(response => {
            progressBar.done();
        }).catch(error => {
            this.setState({
                ...this.state,
                notification: {
                    allowHTML: false
                }              
            }, () => {
                this.addNotification({
                    title: <FormattedMessage
                            id="dashboard.Error"
                            defaultMessage="Error!"/>,
                    message: <FormattedMessage
                            id="dashboard.ErrorMessage"
                            defaultMessage="Sorry! Something wen't wrong. Retry!"/>,
                    level: 'error',
                })
            });
            progressBar.done();
        });    
    };

    selectTypeHandler = (event) => {
        let { value } = event.target;
        let formatDate = value === 'month'
            ? "YYYY/MM"
            : value === 'year'
                ? "YYYY"
                : "YYYY/MM/DD";

        this.setState({
            cond: {
                ...this.state.cond,
                currentType: value || null,
            },
            formatDate: formatDate,
            analysisList: {
                current_page: 1,
                total: 0,
            },
        })
    };

    changeDateHandler = ({ startDate, endDate }) => {
        startDate = startDate || this.state.cond.startDate;
        endDate = endDate || this.state.cond.endDate;
        if (startDate !== null && startDate.isAfter(endDate)) {
            endDate = startDate
        }

        this.setState({
            ...this.state,
            cond: {
                ...this.state.cond,
                startDate,
                endDate,
            }
        })
    };

    changeStartDateHandler = (startDate) => {
        this.changeDateHandler({ startDate });
    };

    changeEndDateHandler = (endDate) => {
        this.changeDateHandler({ endDate })
    };

    fixedFormatter = value => {
        return (
            <Fragment>
                {this.state.currentType === 'time' ? value + 'h' : value}
            </Fragment>
        )
    };

    // handleClickOptions = (event) => {
    //     let { items } = this.state.cond;
    //     let { target } = event;
    //     // console.log(target.value, 'valueee');
    //     if (target.checked) {
    //         if (!this.state.cond.items.includes(+target.value) ) {
    //             items.push(+target.value)            
    //         }
    //     } else {
    //         items = helpers.removeItemInArray(items, +target.value)
    //     }

    //     this.setState({
    //         ...this.state,
    //         cond: {
    //             ...this.state.cond,
    //             items,
    //         },
    //     }, () => {
    //         // console.log(this.state.cond.items, 'itemmmm');
    //     });
    // }


    handleViewHideOption = (value) => {
			console.log('value:', value);
			this.setState({
					...this.state,
					viewHideOption: value == true ? 1 : 0,
			}, () => {
					console.log(this.state.viewHideOption, 'viewHideOption');
			});

		}

    handleClickOptions = (event) => {
        let { target } = event;
        let { items }     = this.state.cond;
        let { infoMasts } = this.state;
        let { viewHideOption } = this.state;

        if (target.checked) {
            if (target.name === 'all') {
                items = infoMasts?.map((item, index) => {
									if (viewHideOption == 1 || item.m_show == 1) return +item.m_id // viewHideOption
									else items = helpers.removeItemInArray(items, +target.value); // all 이지만 선택된 것이 아닐경우 제거 한다.
                });
            } else {
                items.push(+target.value);
            }
        } else {
            if (target.name === 'all') {
                items = [];
            } else {
                //find and remove item from columnOptions
                items = helpers.removeItemInArray(items, +target.value);
            }
        }

        this.setState({
            ...this.state,
            cond: {
                ...this.state.cond,
                items,
            },
            checkAll: target.name === 'all' && target.checked ? true : false,
        }, () => {
            console.log(this.state.cond.items, 'itemmmm');
        });
    }

    renderHeaderTable_Line1 = (mainData) => {
        let xhtml = '';
        if (mainData.infoMasts) {
            xhtml = mainData.infoMasts.map((infoMast, indexMast) => {
                let colspan = 0;
                if (mainData?.rows?.length > 0) {
                   let infoDetails = mainData.rows[0]; // get first line (value other line same this line)
                    Object.keys(infoDetails).map(function(key, index) {
                        if ( _.isObject(infoDetails[key]) && (+infoDetails[key].m_id == +infoMast.m_id) ){
                            colspan++;
                        }
                    });
                }
                return (<th key={indexMast} colSpan={colspan}> {infoMast.m_name} </th>)
            })
            return xhtml;
        }
    }

    renderHeaderTable_Line2 = (mainData) => {
        let xhtml = '';
        if (mainData.infoMasts && mainData.rows?.length > 0) {
            let allInfoDetails = mainData.rows[0];
            xhtml = Object.keys(allInfoDetails).map(function(key, index) {
                if ( _.isObject(allInfoDetails[key]) ){
                    let infoDetailName = helpers.getInfoDetailName(key);
                    return (<td key={index} > {infoDetailName} </td>)
                }
            });
        }
        return xhtml
    }

    renderContentTable = (mainData) => {
        let xhtml = '';
        if (mainData.infoMasts && mainData.rows?.length > 0) {
            let rows = mainData.rows;
            xhtml = rows.map((row, index) => {
               return (
                    <RowContentTable key={index} row={row}>
                    </RowContentTable>
               )   
            })
            return xhtml
        }
        return xhtml
    }




    render() {
        const {
            analysisList,
            analysisData,
            infoMasts,
            cond,
            checkAll,
						viewHideOption
        } = this.state;
        // console.log(analysisData, '8888');

        return (
            <Fragment>
                <NotificationSystem ref={this.notificationSystem} allowHTML={this.state.notification.allowHTML} />

                <FormattedMessage id="dashboard.Analysis"
                    defaultMessage="Analysis">
                    {message => <Helmet>
                        <title>{message}</title>
                    </Helmet>}
                </FormattedMessage>

                <div className="main_top_bar static_bar">
                    <div className="box_sum_col_top">
                        <div className="box_left">
                            <ul className="nav nav_tabs nav-tabs" id="myTab" role="tablist">
                                <li className="nav_item nav-item">
                                    <NavLink
                                        to={`/${routerName.DASHBOARD}/${routerName.STATISTICS}/${routerName.DAILY_REPORT}`}
                                        className="nav_link nav-link"
                                        activeClassName="active">
                                        <FormattedMessage
                                            id="dashboard.DailyReport"
                                            defaultMessage="Daily report" />
                                    </NavLink>
                                </li>
                                <li className="nav_item nav-item">
                                    <NavLink
                                        to={`/${routerName.DASHBOARD}/${routerName.STATISTICS}/${routerName.ANALYSIS}`}
                                        className="nav_link nav-link"
                                        activeClassName="active">
                                        <FormattedMessage
                                            id="dashboard.Analysis"
                                            defaultMessage="Analysis" />
                                    </NavLink>
                                </li>
                                <li className="nav_item nav-item">
                                    <NavLink
                                        to={`/${routerName.DASHBOARD}/${routerName.STATISTICS}/${routerName.GRAPH}`}
                                        className="nav_link nav-link"
                                        activeClassName="active">
                                        <FormattedMessage
                                            id="dashboard.Graph"
                                            defaultMessage="Graph" />
                                    </NavLink>
                                </li>
                            </ul>
                        </div>

                        {/* BOX FILTER */}
                        <div className="box_center tab-content">
                            <div className="main_control_time_select justify_content">
                                <div className="main_control_time_select">
                                    <div className="main_select main_box">
                                        <div className="main_label">
                                            <FormattedMessage id="dashboard.Type" defaultMessage="Type" />
                                        </div>
                                        <div className="custom_select">
                                            <select className="form_control"
                                                name="sltType2"
                                                onChange={this.selectTypeHandler}
                                                value={cond.currentType}>                                          
                                                <option value="day">
                                                    {this.props.intl.formatMessage({ id: 'dashboard.Day', defaultMessage: 'Day' })}
                                                </option>
                                                <option value="month">
                                                    {this.props.intl.formatMessage({ id: 'dashboard.Month', defaultMessage: 'Month' })}
                                                </option>
                                                <option value="year">
                                                    {this.props.intl.formatMessage({ id: 'dashboard.Year', defaultMessage: 'Year' })}
                                                </option>
                                            </select>
                                            <span className="item_dropdown" />
                                        </div>
                                    </div>
                                    <div className="main_time main_box">
                                        <ul className="list_time">
                                            <li>
                                                <FormattedMessage id="dashboard.Period" defaultMessage="Period" />
                                            </li>
                                            <li>
                                                <div className="box_control_date">
                                                    <span className="icon_date" />
                                                    <div className="date_picker_custom">
                                                        <DatePicker
                                                            disabled={!cond.currentType}                                                         
                                                            dateFormat={this.state.formatDate}
                                                            selected={cond.startDate}
                                                            selectsStart
                                                            peekNextMonth
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                            id="FromDatePicker"
                                                            name="FromDatePicker"
                                                            className="from_date form_control form-control-sm unstyled"
                                                            startDate={cond.startDate}
                                                            endDate={cond.endDate}
                                                            onChange={this.changeStartDateHandler}
                                                        />
                                                    </div>
                                                </div>
                                            </li>
                                            <li>~</li>
                                            <li>
                                                <div className="box_control_date">
                                                    <span className="icon_date" />
                                                    <div className="date_picker_custom">
                                                        <DatePicker
                                                            disabled={!cond.currentType}                                                         
                                                            dateFormat={this.state.formatDate}
                                                            selected={cond.endDate}
                                                            selectsStart
                                                            peekNextMonth
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                            id="FromDatePicker"
                                                            name="FromDatePicker"
                                                            className="from_date form_control form-control-sm unstyled"
                                                            startDate={cond.startDate}
                                                            endDate={cond.endDate}
                                                            onChange={this.changeEndDateHandler}
                                                        />
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>                               
                                </div>

                                <div className="box_search">
                                    <button className="btn_fillter"
                                            disabled={cond.items.length < 1}
                                            onClick={() => this.getAnalysisData()}
                                    >
                                        <FormattedMessage
                                            id="dashboard.Search"
                                            defaultMessage="Search"
                                        />
                                    </button>
                                </div>                               
                            </div>
                        </div>
                    </div>
                </div>

                {/* BOX MAIN CONTENT */}
                <div className="main_content">
                    <div className="content_tabs_main content_static_tabs tab-content">
                        <div className="tabs_static_item " id="static2">
                            <div className="box_content_static">
                                <div className="left_col">
                                    {
                                        infoMasts.length ?
                                            <div className="box_check_all">
																								<div className="row" style={{padding:"5px 20px 5px 20px"}}>
																										<input type="checkbox" 
                                                        name="view_hide_item"
																												style={{display:""}}
                                                        type="checkbox"
                                                        onChange={(e) =>this.handleViewHideOption(e.target.checked)}
                                                        checked={viewHideOption == 1 ? true : false}
                                                        id="check0002"
																										/>&nbsp;
																										<label className="" htmlFor="check0002">&nbsp;숨김처리 항목 표시</label>
                                                </div>
                                                <div className="group_checkbox">
                                                    <input
                                                        name="all"
                                                        type="checkbox"
                                                        onChange={this.handleClickOptions}
                                                        checked={ checkAll }
                                                        id="check0001"
                                                    />
                                                    <label className="control_label_checkbox" htmlFor="check0001">
                                                        <FormattedMessage id="dashboard.CheckAll" defaultMessage="CheckAll" />
                                                    </label>
																								</div>
                                            </div> : ''
                                    }

                                    <ul className="list_check">
                                        {
                                            infoMasts.map((option, index) => {
                                                let isChecked = cond.items.includes(option.m_id) ? true : false;
                                                return viewHideOption == 1 || option.m_show == 1 ? (
                                                    <li key={index}>
                                                          <div className="group_checkbox">
                                                            <input
                                                                // name={option.m_id}
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                onChange={this.handleClickOptions}
                                                                checked={isChecked}
                                                                value={option.m_id}
                                                                id={option.m_id}
                                                            />
                                                            <label className="control_label_checkbox" htmlFor={option.m_id}>
                                                                {option.m_name}
                                                            </label>
                                                        </div>
                                                    </li>
                                                ) : ""
                                            })
                                        }
                                    </ul>
                                </div>
                                <div className="content_col">
                                    <div className="row_table_custom">
                                        <div className="heading flex_justcontent">
                                            <div className="txt_heading">Report Analysis</div>
                                            <div className="box_list_btn">
                                                <button className="btn_pri"
                                                    type="button"
                                                    onClick={() => this.exportExcelHandler()}
                                                    disabled={analysisData?.rows?.length > 0 ? false : true }
                                                >
                                                    <FormattedMessage id="dashboard.ExcelDownload"defaultMessage="ExcelDownload"/>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="content">
                                            <div className="control_box_item">
                                                <div className="card_body">
                                                    <div className="custom_table">
                                                        {
                                                            analysisData?.rows?.length > 0 ? (
                                                                <table className="table st_table_6 table_analysic">
                                                                    <thead>
                                                                        <tr>
                                                                            <th colSpan="1" rowSpan="2" valign="middle"  className="custom">일자</th>
                                                                            <th colSpan="1" rowSpan="2" valign="middle"  className="custom">계</th>
                                                                            {
                                                                                this.renderHeaderTable_Line1(analysisData)
                                                                            } 
                                                                        </tr>
                                                                        <tr>
                                                                            {/* <th colSpan="2"></th>
                                                                            <th colSpan="2"></th> */}
                                                                            {
                                                                                this.renderHeaderTable_Line2(analysisData)
                                                                            }                                                                
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {/* render analysis list */}
                                                                        {
                                                                            this.renderContentTable(analysisData)
                                                                        } 
        
        
                                                                        {/* {
                                                                            analysisList?.data?.length > 0 ? 
                                                                                analysisList.data.map((item, index) => {
                                                                                    return (
                                                                                        <tr 
                                                                                            key={index}
                                                                                            onClick={() => this.getSaleLotsByCompanyFunc(item, 1)}
                                                                                        >
                                                                                            <td>{item.time}</td>
                                                                                            <td>{item.total}</td>
                                                                                        </tr>
                                                                                    )
                                                                                }) :
                                                                                (
                                                                                    <tr>
                                                                                        <td colSpan={2}>
                                                                                            <div className="nodata">No Data</div>
                                                                                        </td>
                                                                                    </tr> 
                                                                                )
                                                                            } */}
                                                                    </tbody>
                                                                </table>
                                                            ) : (
                                                                <table className="table st_table_6">
                                                                    <thead>
                                                                        {/* <tr>
                                                                            <th colSpan="1" rowSpan="1" valign="middle"></th>                                                                             
                                                                        </tr> */}
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td colSpan={1}>
                                                                                <div className="nodata" style={{
                                                                                    display: 'flex',
                                                                                    width: '100%',
                                                                                    minHeight: '200px',
                                                                                    justifyContent: 'center',
                                                                                    alignItems: 'center',
                                                                                }}>
                                                                                    No Data
                                                                                </div>
                                                                            </td>
                                                                        </tr> 
                                                                    </tbody>
                                                                </table>
                                                            )
                                                        }                                                
                                                    </div>
                                                </div>


                                                {/* render paginate analysicList list */}
                                                {/* {
                                                    analysisList?.data?.length > 0 && (
                                                        <div className="car_footer">
                                                            <nav aria-label="...">
                                                                <ul className="custom_pagination pagination">
                                                                    <Pagination
                                                                        itemClass="page-item"
                                                                        linkClass="page-link"
                                                                        activePage={analysisList?.current_page}
                                                                        itemsCountPerPage={analysisList?.per_page}
                                                                        totalItemsCount={analysisList?.total || 0}
                                                                        pageRangeDisplayed={10}
                                                                        onChange={this.changePage}
                                                                    />
                                                                </ul>
                                                            </nav>
                                                        </div>
                                                    )
                                                } */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Fragment>
        )
    }
}


const mapStateToProps = state => {
    return {
        auth: state.auth,
        isChangeScene: state.sceneSelect.isChangeScene,
        sceneCompanyId: state.sceneSelect.company_id,
        sceneLostId: state.sceneSelect.lots_id,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onAnalysisExportExcel: (query) => dispatch(exportAnalysisAction(query))
    }
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Analysis));
