import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";
import { hasRole } from "../../../utils/entrust/entrust";

SearchReportComponent.propTypes = {
  props: PropTypes.object,
};

function SearchReportComponent(props) {
  const [inputName, setInputName] = useState(props.inputName);
  const [selectInfoMast, setSelectInfoMast] = useState(props.selectInfoMast);
  const [type_default, setTypeDefault] = useState(props.typeDefault);
  const [missCall, setMissCall] = useState(0);
  const [deleteCall, setDeleteCall] = useState(0);
  const [viewHideOption, setViewHideOption] = useState(0);
  const [calculationStandard, setCalculationStandard] = useState('all_call');
  let territory = {
    m_id: 9999,
    m_name: "관리지역",
}
  const infoMastList = [...props.infoMastList, territory];
  const select = props.typeDefault;
  useEffect(() => {
    setInputName(props.inputName);
    setSelectInfoMast(props.selectInfoMast);
    // setTypeDefault(props.typeDefault);
  }, [props.selectInfoMast]);

  useEffect(() =>{
    setTypeDefault(props.typeDefault);
    setInputName(props.inputName);
    console.log(props.typeDefault,props.inputName, 'setTypeDefault useeff');
  }, [props.typeDefault])

  function renderInfoMastList(data) {
    if (data && data.length > 0) {
      return data.map((item, index) => {
        return viewHideOption || item.m_show == 1 ? 
				(
          <option key={index} value={item.m_id}>
            {item.m_name}
          </option>
        ) : '' ;
      });
    }
  }

  function selectInfoMastList(e) {
    setSelectInfoMast({
      ...selectInfoMast,
      [e.target.name]: e.target.value,
    });
  }

  function handleChangeInput(e) {
    setInputName({
      ...inputName,
      [e.target.name]: e.target.value,
    });
  }

  function handleSearchReport() {
    let data = {
      inputName,
      selectInfoMast,
      calculation_standard: calculationStandard,
      missCall,
			viewHideOption,
      deleteCall
    };
    let isError = false;
    if ((parseInt(data.selectInfoMast.report2x) == parseInt(data.selectInfoMast.report2y))
    || (parseInt(data.selectInfoMast.report3x) == parseInt(data.selectInfoMast.report3y))
    || (parseInt(data.selectInfoMast.report4x)  == parseInt(data.selectInfoMast.report4y))
    || (parseInt(data.selectInfoMast.report5x) == parseInt(data.selectInfoMast.report5y))) {
      isError = true;
    } else {
      if(
          parseInt(data.selectInfoMast.report1m) == 8888
          && (parseInt(data.selectInfoMast.report2x) == 0 && parseInt(data.selectInfoMast.report2y) == 8888)
          && (parseInt(data.selectInfoMast.report3x) == 0 && parseInt(data.selectInfoMast.report3y) == 8888)
          && (parseInt(data.selectInfoMast.report4x) == 0)
          && (parseInt(data.selectInfoMast.report5x) == 0)
      ) {
        isError = true;
      } else {
        isError = false;
      }
    }



    if(!isError) {
      props.dataSearch(data);
    } else {
      props.showNotifySearchForm();
    }
    
  }
  function handleCheckMissCallReport(value) {
    setMissCall(value == true ? 1 : 0)
  }

  function handleCheckDeleteCallReport(value) {
    setDeleteCall(value == true ? 1 : 0)
  }

  function calculationStandardHandle(data, value) {
    setCalculationStandard(value);
  }

	function viewHideOptionHandle(value) {
		setViewHideOption(value == true ? 1 : 0)
	}

  function handleSaveReport(value) {
    console.log(value, 'value');
    let data;
    if (value) {
      setTypeDefault(1)
      props.setDefaultChildren(1)
    } else {
      setTypeDefault(0)
      props.setDefaultChildren(0)
    }
    data = {
      inputName,
      selectInfoMast,
      type_default: value ? 1 : 0,
      calculation_standard: calculationStandard,
      viewHideOption
    };
    props.dataSave(data);
  }

  return (
    <div className="left_col">
      <div className="box_heading_widget">
        <div className="box_control_checkbox">
          <div className="box_txt">산정기준</div>
          <div className="box_check">
            <input type="radio" 
            id="cb001" 
            checked = {calculationStandard == 'all_call' ? true : false}
            name="calculation_call" 
            onChange={(e) => calculationStandardHandle(e, 'all_call')} 
            />
            <label className="action_check" htmlFor="cb001">건수별 </label>
          </div>
          <div className="box_check">
            <input type="radio" 
            id="cb002" 
            checked = {calculationStandard == 'duplicate_call' ? true : false}
            name="calculation_call" 
            onChange={(e) => calculationStandardHandle(e, 'duplicate_call')}
            />
            <label className="action_check" htmlFor="cb002">고객별</label>
          </div>
        </div>
      </div>
			<div className="box_control_checkbox">
        <div className="box_txt"></div>
				<div className="box_check">
					<input type="checkbox" 
					id="cb003" 
					checked = {viewHideOption ? true : false}
					name="view_hide_option" 
					onChange={(e) => viewHideOptionHandle(e.target.checked)}
					/>
					<label className="action_check" htmlFor="cb003">숨김처리 항목 표시</label>
				</div>
			</div>
      <div className="box_count">
        <div className="heading">
          <FormattedMessage id="dashboard.Report" defaultMessage="Report" /> 1
        </div>
        <div className="content">
          <div className="custom_group_colum">
            <div className="group_item">
              <input
                type="text"
                className="input_group form_control"
                value={inputName.report1}
              />
            </div>
            <div className="group_item select_item">
              <select
                className="form_control"
                value={selectInfoMast.report1m}
                name="report1m"
                onChange={(e) => selectInfoMastList(e)}
              >
                <option value="8888">
                  {props.intl.formatMessage({
                    id: "dashboard.SelectItem",
                    defaultMessage: "Select Item",
                  })}
                </option>
                {renderInfoMastList(infoMastList)}
              </select>
              <span className="item_dropdown" />
            </div>
          </div>
        </div>
      </div>
      {/* box_count */}
      <div className="box_count">
        <div className="heading">
          <FormattedMessage id="dashboard.Report" defaultMessage="Report" /> 2
        </div>
        <div className="content">
          <div className="custom_group_colum">
            <div className="group_item">
              <input
                type="text"
                className="input_group form_control"
                value={inputName.report2 ? inputName.report2 : ""}
                name="report2"
                onChange={(e) => handleChangeInput(e)}
              />
            </div>
            <div className="group_item select_item">
              <select
                className="form_control"
                value={selectInfoMast.report2x}
                name="report2x"
                onChange={(e) => selectInfoMastList(e)}
              >
                <option value="0">
                  {props.intl.formatMessage({
                    id: "dashboard.SelectItem",
                    defaultMessage: "Select Item",
                  })}
                </option>
                {renderInfoMastList(infoMastList)}
              </select>
              <span className="item_dropdown" />
            </div>
            <div className="group_item select_item">
              <select
                className="form_control"
                value={selectInfoMast.report2y}
                name="report2y"
                onChange={(e) => selectInfoMastList(e)}
              >
                <option value="8888">
                  {props.intl.formatMessage({
                    id: "dashboard.SelectItem",
                    defaultMessage: "Select Item",
                  })}
                </option>
                {renderInfoMastList(infoMastList)}
              </select>
              <span className="item_dropdown" />
            </div>
          </div>
        </div>
      </div>
      {/* box_count */}
      <div className="box_count">
        <div className="heading">
          <FormattedMessage id="dashboard.Report" defaultMessage="Report" /> 3
        </div>
        <div className="content">
          <div className="custom_group_colum">
            <div className="group_item">
              <input
                type="text"
                className="input_group form_control"
                value={inputName.report3 ? inputName.report3 : ""}
                name="report3"
                onChange={(e) => handleChangeInput(e)}
              />
            </div>
            <div className="group_item select_item">
              <select
                className="form_control"
                value={selectInfoMast.report3x}
                name="report3x"
                onChange={(e) => selectInfoMastList(e)}
              >
                <option value="0">
                  {props.intl.formatMessage({
                    id: "dashboard.SelectItem",
                    defaultMessage: "Select Item",
                  })}
                </option>
                {renderInfoMastList(infoMastList)}
              </select>
              <span className="item_dropdown" />
            </div>
            <div className="group_item select_item">
              <select
                className="form_control"
                value={selectInfoMast.report3y}
                name="report3y"
                onChange={(e) => selectInfoMastList(e)}
              >
                <option value="8888">
                  {props.intl.formatMessage({
                    id: "dashboard.SelectItem",
                    defaultMessage: "Select Item",
                  })}
                </option>
                {renderInfoMastList(infoMastList)}
              </select>
              <span className="item_dropdown" />
            </div>
          </div>
        </div>
      </div>
      {/* box_count */}
      <div className="box_count">
        <div className="heading">
          <FormattedMessage id="dashboard.Report" defaultMessage="Report" /> 4
        </div>
        <div className="content">
          <div className="custom_group_colum">
            <div className="group_item">
              <input
                type="text"
                className="input_group form_control"
                value={inputName.report4 ? inputName.report4 : ""}
                name="report4"
                onChange={(e) => handleChangeInput(e)}
              />
            </div>
            <div className="group_item select_item">
              <select
                className="form_control"
                value={selectInfoMast.report4x}
                name="report4x"
                onChange={(e) => selectInfoMastList(e)}
              >
                <option value="0">
                  {props.intl.formatMessage({
                    id: "dashboard.SelectItem",
                    defaultMessage: "Select Item",
                  })}
                </option>
                {renderInfoMastList(infoMastList)}
              </select>
              <span className="item_dropdown" />
            </div>
          </div>
        </div>
      </div>
      {/* box_count */}
      <div className="box_count">
        <div className="heading">
          <FormattedMessage id="dashboard.Report" defaultMessage="Report" /> 5
        </div>
        <div className="content">
          <div className="custom_group_colum">
            <div className="group_item">
              <input
                type="text"
                className="input_group form_control"
                value={inputName.report5 ? inputName.report5 : ""}
                name="report5"
                onChange={(e) => handleChangeInput(e)}
              />
            </div>
            <div className="group_item select_item">
              <select
                className="form_control"
                value={selectInfoMast.report5x}
                name="report5x"
                onChange={(e) => selectInfoMastList(e)}
              >
                <option value="0">
                  {props.intl.formatMessage({
                    id: "dashboard.SelectItem",
                    defaultMessage: "Select Item",
                  })}
                </option>
                {renderInfoMastList(infoMastList)}
              </select>
              <span className="item_dropdown" />
            </div>
          </div>
        </div>
      </div>
      {/* box_count */}
      <div className="box_count">
        <div className="heading">
          <FormattedMessage id="dashboard.Note" defaultMessage="Note" /> 6
        </div>
        <div className="content">
          <div className="custom_group_colum">
            <div className="group_item textarea_control">
              <textarea
                className="form_control"
                name="note"
                value={inputName.note}
                onChange={(e) => handleChangeInput(e)}
              />
            </div>
          </div>
        </div>
      </div>
      {/* box_count */}
      <div className="box">
        <div class="box_count list_btn left">
          <input type="checkbox" className=" mr-1" onClick={(e) => handleSaveReport(e.target.checked)} checked={type_default == 1 ? true : false}/>
          <FormattedMessage id="dashboard.Save" defaultMessage="Save" />
        </div>

        {/* <div class="box_count list_btn left">
          <input type="checkbox" className=" mr-1" onClick={(e) => handleCheckMissCallReport(e.target.checked)}/>
          부재콜 추가
        </div>
        <div class="box_count list_btn left">
          <input type="checkbox" className=" mr-1" onClick={(e) => handleCheckDeleteCallReport(e.target.checked)}/>
          삭제콜 추가
        </div> */}

        <div class="box_count list_btn left">
          <button class="btn_custom" onClick={handleSearchReport} disabled={hasRole(props.user, 'primarynet') && !props.company}>
            <FormattedMessage id="dashboard.Search" defaultMessage="Search" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default injectIntl(SearchReportComponent);
