import React, {Fragment} from 'react'
import telephone_1 from '../../../src/assets/images/documentV2/telephone_counselling/telephone_2.png';
import telephone_2 from '../../../src/assets/images/document/telephone_counselling/telephone_3.png';

const TelephoneCounsellingDoc = props => {

    return (
        <Fragment>
            <div className="row m-0">
                <div className="col-md-12 documentation-user-registration">
                    <div className='pb-3'>
                        <h2 className='text-align-center mt-3 mb-2'>전화상담</h2>
                        <p className='text-left mb-4'>전화상담 수신, 업데이트 및 관리</p>
                    </div>
                    <div className='row mb-5'>
                        <div className='col-sm-8'>
                            <img className='pull-left' src={telephone_1} alt='' width='100%'/>
                            {/* <img className='pull-left' src={telephone_2} alt='' width='100%' height='auto'/> */}
                        </div>

                        <div className='col-md-4 pl-4 pr-0'>
                            <ol className='pull-left' style={{listStyle: 'none'}}>
                                <li>(1) 전화 상담 리스트 : 수신목록과 발신목록 <br />
                            목록에서 한 건을 클릭하면 세부내용이 (2)번에 표시됨</li>
                                <li>(2) 전화상담 내용 표시</li>
                                <li>(3) 상담일자</li>
                                <li>(4) 부재중콜</li>
                                <li>(5) 고객명</li>
                                <li>(6) 고객의 전화번호</li>
                                <li>(7) 고객정보</li>
                                <li>(8) 회선 정보</li>
                                <li>(9) 전화</li>
                                <li>(10) 일반전화</li>
                                <li>(11) 주소</li>
                                <li>(12) 관리지역</li>
                                <li>(13) 수신동의</li>
                                <li>(14) 전화받기</li>
                                <li>(15) 수신거부</li>
                                <li>(16) 착신전환</li>
                                <li>(17) 상담현황</li>
                                <li>(18)새로고짐</li>
                                <li>(19) 삭제</li>
                                <li>(20) 환경설정</li>
                                <li>(21) 호응도</li>
                                <li>(22) 희망평형</li>
                                <li>(23) 상담횟수</li>
                                <li>(24) 수신동의</li>
                                <li>(25) 상담항목<br />
                                - 상담항목: 선택 시 오른쪽의 세부항목이 변경됨<br />
                                - 선택값: 세부항목에서 선택한 값이 입력됨<br />
                                "삭제" 아이콘 클릭 시 선택 값이 취소됨<br />
                                </li>
                                <li>(26) 선택한 상담항목의 세부항목이 표시됨</li>
                                <li>(27) 특이사항. "저장" 버튼 클릭 후, 입력한 전화 상담 정보를 저장함</li>
                                <li>(28) 상담 내역 이력 확인</li>
                            </ol>
                        </div>
                    </div>

                </div>
            </div>


        </Fragment>
    )
}

export default TelephoneCounsellingDoc;
