import React from 'react'

const Page404 = () => {

    return (
        <div className="page">
            <div className="page-content">
                <div className="container text-center">
                    <div className="display-1 text-muted mb-5"><i className="si si-exclamation"></i> 403</div>
                    <h1 className="h2 mb-3">Oops.. You just found an error page..</h1>
                    <p className="h4 text-muted font-weight-normal mb-7">We are sorry but you do not have permission to
                        access this page&hellip;</p>
                    <a className="btn btn-primary" href="/">
                        <i className="fa fa-arrow-left"></i> Go back
                    </a>
                </div>
            </div>
        </div>
    )
};
export default Page404;
