import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
// import Select from 'react-select';
import Notification from '../../../components/Notification/Notification';
import DeleteConfirmModal from '../../../components/DeleteConfirmModal/DeleteConfirmModal';
import {
    fetchCompaniesAction,
    fetchDistrictsByCompanyAction,
    createDistrictAction,
    updateDistrictAction,
    deleteDistrictAction,
    unmountDistrictsAction
} from "../../../store/actions";
import { Helmet } from 'react-helmet';
import progressBar from '../../../utils/progressBar';
import {renderMessage} from '../../../utils/helpers';
import { FormattedMessage, injectIntl } from "react-intl";
import HasRole from "../../../utils/entrust/HasRole";
import { Card, Input } from 'reactstrap';
import { hasRole } from "../../../utils/entrust/entrust";
import { NavLink, Link } from "react-router-dom";
import * as routerName from "../../../utils/routerName";
import DataSetting from './../DataSetting';
import Pagination from "react-js-pagination";
import CreateDistrictModal from './../../../components/DataSetting/TerritoryManagement/CreateDistrictModal';
import UpdateDistrictModal from './../../../components/DataSetting/TerritoryManagement/UpdateDistrictModal';
import NotificationSystem from 'react-notification-system';

class TerritoryManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            keyword:'',
            companies: [],
            currentCompany: null,
            districtList: {},
            currentDistrict: {},
            districtForm: {},
            deletingRow: null,
            isDeleteModalOpen: false,
            isDeleteConfirmModalOpen: false,

            openModalCreateDistrict: false,
            openModalUpdateDistrict: false,

            // Notification
            notification: {
                allowHTML: false,
            },
        }
    }

    notificationSystem = React.createRef();
    addNotification = (data) => {
      const notification = this.notificationSystem.current;
      notification.addNotification({
        message: data.message,
        title: data.title,
        level: data.level,
        autoDismiss: data.autoDismiss || 4
      });
    };

    notificationSystemSimple = React.createRef();
    addNotificationSimple = (data) => {
      const notificationSimple = this.notificationSystemSimple.current;
      notificationSimple.addNotification({
        message: data.message,
        title: data.title,
        level: data.level,
        autoDismiss: data.autoDismiss || 4
      });
    };

    componentDidMount() {
        // paginate: get data Paginate Format  || list: get data List Format
        let params = { 
            type: 'list'
        }
        this.getCompanies(params);

        //check role and get data role by role
        if (hasRole(this.props.auth.user, 'admin')) {
            this.props.onFetchDistrictsByCompany(this.props.auth.user.company_id).then(response => {
                this.setState({
                    districtList: response,
                    getCompanies: this.props.auth.user.company_id,
                }, () => {
                    // console.log(this.state, 'steeeee');
                });
                progressBar.done();
            }).catch(error => {             
                progressBar.done();
            });
        }
    }

    getCompanies = (params) => {
        progressBar.start();
        this.props.onFetchCompanies(params).then(response => {
            this.setState({
                companies: response.data,

                // if user role is admin then not display select company and default currentCompany is company belong to admin
                // currentCompany: hasRole(this.props.auth.user, 'admin') ? response.data[0] : null
                currentCompany: hasRole(this.props.auth.user, 'admin') ? response.data[0].company_id : null
            }, () => {
                console.log(this.state, 'state_after');
            });
            progressBar.done();
        }).catch(error => {          
            progressBar.done();
        });
    }

    getDistrictsByCompany = () => {
        progressBar.start();
        let params = {
            keyword: this.state.keyword?.toString(),
            page: this.state.districtList?.current_page || 1,
            type: 'paginate'
        }
        let company_id= this.state.currentCompany;
        this.props.onFetchDistrictsByCompany(company_id, params).then(response => {
            this.setState({
                districtList: response,
                currentCompany: company_id
                // currentCompany: company
            }, () => {
                // console.log(this.state.districtList, 'districtList_after');
            });
            progressBar.done();
        }).catch(error => {     
            progressBar.done();
        });
    }

    selectCompanyHandler = (event) => {
        let company_id = event.target.value;
        this.setState({
            ...this.state,
            currentCompany: company_id,
        },() => {
            console.log(this.state.currentCompany, 'currentCompany');
            this.getDistrictsByCompany();
        })
    };

    handleSearch = (event) => {
        let keyword = event.target.value;
        this.setState({
            ...this.state,
            keyword,
            districtList: {
                ...this.state.districtList,
                current_page: 1
            }
        }, () => {
            this.getDistrictsByCompany();
        })
    }

    changePage = (pageNumber) => {
        this.setState({
            ...this.state,
            districtList: {
                ...this.state.districtList,
                current_page: pageNumber,
            }
        },() => {
            this.getDistrictsByCompany();
        })
    }


    /**
     * CRUD District
     */
    handleChangeInputDistrict = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        console.log(value, 'value22');

        this.setState({
            ...this.state,
            districtForm: {
                ...this.state.districtForm,
                [name]: value,
            }
        });        
    };

    onCreateDistrict = () => {
        progressBar.start();
        const district = this.state.districtForm;
        district.company_id = this.state.currentCompany;

        this.props.onCreateDistrict(district)
            .then(response => {
                this.toggleModalCreateDistrict();
                this.getDistrictsByCompany();
                this.setState({
                    ...this.state,
                    districtForm:{},
                    notification: {
                        allowHTML: false,
                    }
                }, () => {
                    this.addNotificationSimple({
                        title: <FormattedMessage
                                id="dashboard.Succeed"
                                defaultMessage="Succeed!"/>,
                        message: <FormattedMessage
                            id="dashboard.Common.CreateSucceed"
                            defaultMessage="Add new territory successfully!"/>,
                        level: 'success',
                    })
                }) 
            })
            .catch(error => {
                if (error.data) {
                    this.setState({
                        ...this.state,
                        notification: {
                            allowHTML: true,
                        }
                    }, () => {
                        this.addNotification({
                            title: <FormattedMessage
                                    id="dashboard.Error"
                                    defaultMessage="Error !"/>,
                            level: 'error',
                            message: renderMessage(error.data.errors.message),
                        })
                        progressBar.done();
                    })
                }
            });
    };

    editDistrict = (district) => {
        // console.log(district, 'districte');
        this.setState({
            ...this.state,
            districtForm: district,
        }, () => {
            this.toggleModalUpdateDistrict();
        });
    };

    onUpdateDistrict = () => {
        progressBar.start();
        const district = this.state.districtForm;
        // const company_id = this.state.currentCompany;

        this.props.onUpdateDistrict(district.dist_id, district)
            .then(response => {
                console.log(response, 'responses');
                this.toggleModalUpdateDistrict();
                this.getDistrictsByCompany();
                this.setState({
                    ...this.state,
                    notification: {
                        allowHTML: false
                    }
                }, () => {
                    this.addNotificationSimple({
                        title: <FormattedMessage
                                id="dashboard.Succeed"
                                defaultMessage="Succeed!"/>,
                        message: <FormattedMessage
                            id="dashboard.Common.UpdateSucceed"
                            defaultMessage="Add new territory successfully!"/>,
                        level: 'success',
                    })
                })
            })
            .catch(error => {
                if (error.data) {
                    this.setState({
                        ...this.state,
                        notification: {
                            allowHTML: true,
                        }
                    }, () => {
                        this.addNotification({
                            title: <FormattedMessage
                                    id="dashboard.Error"
                                    defaultMessage="Error !"/>,
                            level: 'error',
                            message: renderMessage(error.data.errors.message),
                        })
                        progressBar.done();
                    })
                }
            });
    };    

    setDeletingRow = (value) => {
        this.setState({
            ...this.state,
            deletingRow: value,
        }, () => {
            this.openDeleteModal();
        });
    };

    deleteDistrict = () => {
        progressBar.start();
        this.props.onDeleteDistrict(this.state.deletingRow)
            .then(response => {
                // check and re-set current page if delete last item of last page
                let {districtList} = this.state; 
                let current_page = districtList.from == districtList.to ? districtList.last_page - 1 : districtList.current_page;
                this.setState({
                    ...this.state,
                    deletingRow: null,
                    isDeleteConfirmModalOpen: false,
                    notification: {
                        allowHTML: false,
                    },
                    districtList: {
                        ...this.state.districtList,
                        current_page,
                    }
                }, () => {
                    this.getDistrictsByCompany();

                    this.addNotificationSimple({
                        title: <FormattedMessage
                            id="dashboard.Succeed"
                            defaultMessage="Succeed!"/>,
                        message: <FormattedMessage
                            id="dashboard.Common.DeleteSucceed"
                            defaultMessage="Delete territory successfully!"/>,
                        level: 'success',
                    })
                })
            })
            .catch(error => {               
            });
    };



    /**
     *  Modal Function
     */
    toggleModalCreateDistrict = () => {
        this.setState({
            ...this.state,
            districtForm: {},
            openModalCreateDistrict: !this.state.openModalCreateDistrict,
        }, () => {
            console.log(this.state, 'state_after');
        });
    };

    toggleModalUpdateDistrict = () => {
        this.setState({
            ...this.state,
            openModalUpdateDistrict: !this.state.openModalUpdateDistrict,
        }, () => {
            // console.log(this.state, 'state_after');
        });
    };

    openDeleteModal = () => {
        this.setState({
            isDeleteModalOpen: !this.state.isDeleteModalOpen
        });
    };

    openDeleteConfirmModal = () => {
        this.setState({
            isDeleteConfirmModalOpen: !this.state.isDeleteConfirmModalOpen
        });
    };

    render() {
        const { companies, districtList} = this.state;
        const { location } = this.props;

        // debugger
        return (
            <Fragment>
                <NotificationSystem ref={this.notificationSystem} allowHTML={true}/>
                <NotificationSystem ref={this.notificationSystemSimple} allowHTML={false}/>

                <FormattedMessage id="dashboard.TerritoryManagement"
                    defaultMessage="TerritoryManagement">
                    {message => <Helmet>
                        <title>{message}</title>
                    </Helmet>}
                </FormattedMessage>

                <div className="main_top_bar static_bar">
                    <div className="box_sum_col_top">
                        <div className="box_left">
                            <ul className="nav nav_tabs">
                                <li className="nav_item">
                                    <span className="nav_link">
                                        <FormattedMessage id="dashboard.TerritoryManagement" defaultMessage="TerritoryManagement" />
                                    </span>
                                </li>
                            </ul>
                        </div>

                        <HasRole role={['primarynet']}>
                            <div className="box_center">
                                <div className="tabs_static_item">
                                    <div className="main_control_time_select">
                                        <div className="main_select main_box">
                                            <div className="main_label" >
                                                <FormattedMessage id="dashboard.Company" defaultMessage="Company" />
                                            </div>
                                            <div className="custom_select">
                                                <select className="form_control" onChange={this.selectCompanyHandler} name="sltCompany">
                                                    <option>
                                                        {this.props.intl.formatMessage({id: 'dashboard.SelectCompany',defaultMessage: 'SelectCompany'})}
                                                    </option>
                                                    {
                                                        companies?.map((item, index) => {
                                                            return (
                                                                <option key={index} value={item.company_id}>{item.company_name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                                <span className="item_dropdown" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </HasRole>
                    </div>
                </div>

                <div className="main_content">
                    <div className="content_tabs_main content_static_tabs tab-content">
                        <div className="tabs_static_item tabs_item1">
                            <div className="box_content_static">
                                <div className="left_col left_col_customer_care">
                                    <DataSetting 
                                        location={this.props.location}
                                    />
                                </div>

                                <div className="content_col_nopading">
                                    <div className="list_control_box_preferences">
                                        <div className="content_left">
                                            <div className="content_column">
                                                <div className="column_box mw_50">
                                                    <div className="heading_box">
                                                        <h5 className="title_preferences">
                                                        <FormattedMessage
                                                            id="dashboard.Territory"
                                                            defaultMessage="Territory"
                                                        />
                                                        </h5>                                                
                                                    </div>
                                                    <div className="control_box_item">
                                                        <div className="card_header">
                                                            <div className="box_search">
                                                                <div className="box_control_input ">
                                                                    <input type="text" className="control" name="keyword" onChange={this.handleSearch} value={this.state.keyword}/>
                                                                    <button className="btn_form btn_secondary"><i className="fas fa-search" /></button>
                                                                </div>
                                                            </div>
                                                            <div className="box_add">
                                                                <button type="button" className="btn btn_add" onClick={this.toggleModalCreateDistrict}
                                                                        disabled={this.state.currentCompany ? false : true}>
                                                                    <i className="fas fa-plus" />
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="card_body">
                                                            <div className="custom_table">
                                                                <table className="table st_table_5">
                                                                    <thead>
                                                                        <tr>
                                                                            <th style={{width: '70%'}}>
                                                                                <FormattedMessage id="dashboard.Name" defaultMessage="Name" />
                                                                            </th>
                                                                            <th>
                                                                                <FormattedMessage id="dashboard.Order" defaultMessage="Order" />
                                                                            </th>
                                                                            <th />
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {/* render district list */}
                                                                    {
                                                                        districtList?.data?.length > 0 ? 
                                                                            districtList.data.map((item, index) => {
                                                                                return (
                                                                                    <tr key={index}>
                                                                                        <td>{item.dist_name}</td>
                                                                                        <td>{item.dist_order}</td>
                                                                                        <td className="inline_action">
                                                                                            <a onClick={() => this.editDistrict(item)}> <i className="fas fa-edit"/> </a>
                                                                                            <a onClick={() => this.setDeletingRow(item.dist_id)}> <i className="fas fa-trash-alt"/> </a>
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            }) :
                                                                            (
                                                                                <tr>
                                                                                    <td colSpan={3}>
                                                                                        <div className="nodata">No Data</div>
                                                                                    </td>
                                                                                </tr> 
                                                                            )
                                                                    }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        {  
                                                            districtList?.data?.length > 0 && (
                                                                <div className="car_footer">
                                                                    <nav aria-label="...">
                                                                        <ul className="custom_pagination pagination">
                                                                            <Pagination
                                                                                itemClass="page-item"
                                                                                linkClass="page-link"
                                                                                activePage={districtList?.current_page}
                                                                                itemsCountPerPage={districtList?.per_page}
                                                                                totalItemsCount={districtList?.total || 0}
                                                                                pageRangeDisplayed={10}
                                                                                onChange={this.changePage}
                                                                            />
                                                                        </ul>
                                                                    </nav>
                                                                 </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <CreateDistrictModal
                    isOpen = {this.state.openModalCreateDistrict}
                    toggle = {this.toggleModalCreateDistrict}
                    districtForm = {this.state.districtForm}
                    handleChange = {this.handleChangeInputDistrict}
                    onCreateDistrict = {this.onCreateDistrict}
                />
                
                <UpdateDistrictModal
                    isOpen = {this.state.openModalUpdateDistrict}
                    toggle = {this.toggleModalUpdateDistrict}
                    districtForm = {this.state.districtForm}
                    handleChange = {this.handleChangeInputDistrict}
                    onUpdateDistrict = {this.onUpdateDistrict}
                />

                <DeleteConfirmModal
                    isDeleteModalOpen={this.state.isDeleteModalOpen}
                    openDeleteModal={this.openDeleteModal}
                    isDeleteConfirmModalOpen={this.state.isDeleteConfirmModalOpen}
                    openDeleteConfirmModal={this.openDeleteConfirmModal}
                    deleteInfoHandler={this.deleteDistrict}
                />

            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        companies: state.companies,
        auth: state.auth
    }
};

const mapDispatchToProps = dispatch => {
    let paramsDefault = { type: 'paginate' };
    return {
        // onFetchCompanies: () => dispatch(fetchCompaniesAction()),
        onFetchCompanies: (params=paramsDefault) => dispatch(fetchCompaniesAction(params)),
        onFetchDistrictsByCompany: (company_id, params=paramsDefault) => dispatch(fetchDistrictsByCompanyAction(company_id, params)),
        onCreateDistrict: district => dispatch(createDistrictAction(district)),
        onUpdateDistrict: (id, district) => dispatch(updateDistrictAction(id, district)),
        onDeleteDistrict: id => dispatch(deleteDistrictAction(id)),
        onUnmountDistricts: () => dispatch(unmountDistrictsAction())
    }
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(TerritoryManagement));
