import {LOCALE_SET, LOCALE_SET_SUCCEED} from "./actionTypes";
import axios from "../../utils/axios";


export const setLocale = lang => ({
    type: LOCALE_SET,
    payload: {
        lang: lang
    }
});


export const setLocaleSucceed = () => ({
    type: LOCALE_SET_SUCCEED,
});


export const setLocaleAction = lang => dispatch => {
    dispatch(setLocale(lang));
    try {
        localStorage.setItem('lang', lang);
        axios.defaults.headers.common['Content-Language'] = lang;
        dispatch(setLocaleSucceed())
    } catch (error) {

    }
};


