import {
    FETCH_DISTRICTS,
    FETCH_DISTRICTS_SUCCEED,
    FETCH_DISTRICTS_FAILED,
    FETCH_DISTRICTS_BY_COMPANY,
    FETCH_DISTRICTS_BY_COMPANY_SUCCEED,
    FETCH_DISTRICTS_BY_COMPANY_FAILED,
    CREATE_DISTRICT,
    CREATE_DISTRICT_SUCCEED,
    CREATE_DISTRICT_FAILED,
    UPDATE_DISTRICT,
    UPDATE_DISTRICT_SUCCEED,
    UPDATE_DISTRICT_FAILED,
    DELETE_DISTRICT,
    DELETE_DISTRICT_SUCCEED,
    DELETE_DISTRICT_FAILED,
    UNMOUNT_DISTRICTS
} from '../actions/actionTypes';
import api from "../../utils/api";

export const fetchDistricts = () => {
    return {
        type: FETCH_DISTRICTS
    }
};

export const fetchDistrictsSucceed = data => {
    return {
        type: FETCH_DISTRICTS_SUCCEED,
        payload: {
            data: data
        }
    }
};

export const fetchDistrictsFailed = error => {
    return {
        type: FETCH_DISTRICTS_FAILED,
        payload: {
            error: error
        }
    }
};

export const fetchDistrictsByCompany = () => {
    return {
        type: FETCH_DISTRICTS_BY_COMPANY
    }
};

export const fetchDistrictsByCompanySucceed = data => {
    return {
        type: FETCH_DISTRICTS_BY_COMPANY_SUCCEED,
        payload: {
            data: data
        }
    }
};

export const fetchDistrictsByCompanyFailed = error => {
    return {
        type: FETCH_DISTRICTS_BY_COMPANY_FAILED,
        payload: {
            error: error
        }
    }
};

export const createDistrict = () => {
    return {
        type: CREATE_DISTRICT
    }
};

export const createDistrictSucceed = district => {
    return {
        type: CREATE_DISTRICT_SUCCEED,
        payload: {
            district
        }
    }
};

export const createDistrictFailed = error => {
    return {
        type: CREATE_DISTRICT_FAILED,
        payload: {
            error: error
        }
    }
};

export const updateDistrict = () => {
    return {
        type: UPDATE_DISTRICT
    }
};

export const updateDistrictSucceed = district => {
    return {
        type: UPDATE_DISTRICT_SUCCEED,
        payload: {
            district
        }
    }
};

export const updateDistrictFailed = error => {
    return {
        type: UPDATE_DISTRICT_FAILED,
        payload: {
            error: error
        }
    }
};

export const deleteDistrict = () => {
    return {
        type: DELETE_DISTRICT
    }
};

export const deleteDistrictSucceed = district => {
    return {
        type: DELETE_DISTRICT_SUCCEED,
        payload: {
            district
        }
    }
};

export const deleteDistrictFailed = error => {
    return {
        type: DELETE_DISTRICT_FAILED,
        payload: {
            error: error
        }
    }
};

export const unmountDistricts = () => {
    return {
        type: UNMOUNT_DISTRICTS
    }
};

/**
 * Action
 */


export const fetchDistrictsAction = () => dispatch => {
    dispatch(fetchDistricts());
    return api.districts.getDistricts()
        .then(response => {
            dispatch(fetchDistrictsSucceed(response.data.data));
            return Promise.resolve(response.data.data);
        })
        .catch(error => {
            dispatch(fetchDistrictsFailed(error.response.data.errors.message));
            return Promise.reject(error.response)
        });
};


export const fetchDistrictsByCompanyAction = (id = null, params) => dispatch => {
    dispatch(fetchDistrictsByCompany());
    return api.districts.getDistrictsByCompany(id, params)
        .then(response => {
            dispatch(fetchDistrictsByCompanySucceed(response.data.data));
            return Promise.resolve(response.data.data);
        })
        .catch(error => {
            dispatch(fetchDistrictsByCompanyFailed(error.response.data.errors.message));
            return Promise.reject(error.response)
        });
};


export const unmountDistrictsAction = () => dispatch => {
    dispatch(unmountDistricts())
};


/**
 * Create district
 */
export const createDistrictAction = district => dispatch => {
    dispatch(createDistrict());
    return api.districts.createDistrict(district)
        .then(response => {
            dispatch(createDistrictSucceed(response.data.data));
            return Promise.resolve(response.data);
        })
        .catch(error => {
            dispatch(createDistrictFailed(error.response.data.errors.message));
            return Promise.reject(error.response)
        });
};

/**
 * Update district
 */
export const updateDistrictAction = (id, district) => dispatch => {
    dispatch(updateDistrict());
    return api.districts.updateDistrict(id, district)
        .then(response => {
            dispatch(updateDistrictSucceed(response.data.data));
            return Promise.resolve(response.data);
        })
        .catch(error => {
            dispatch(updateDistrictFailed(error.response.data.errors.message));
            return Promise.reject(error.response)
        });
};

/**
 * Delete district
 */
export const deleteDistrictAction = districtId => dispatch => {
    dispatch(deleteDistrict());
    const id = {
        params: {
            dist_id: districtId
        }
    };
    return api.districts.deleteDistrict(id)
        .then(response => {
            dispatch(deleteDistrictSucceed(response.data.data));
            return Promise.resolve(response.data);
        })
        .catch(error => {
            dispatch(deleteDistrictFailed(error.response.data.errors.message));
            return Promise.reject(error.response)
        });
};
