import React, {useState} from 'react';
import {Modal} from 'reactstrap';
import ItemMessage from "./Message/ItemMessage";
import moment from "moment";
import {formatNumberPhone} from './../../../utils/helpers';
import isEmpty from 'lodash/isEmpty'

function ModalMessage (props) {
    const {
        isOpen,
        toggle,
        messages,
        accessToken, // accessToken ben thu 3
        submitMessage,
        tell, // so dien thoai input
        saveDirectMessage, // save saveDirectMessage
        directMessage, // tin nhan direct cua user,
        modalStatus
    } = props;

    const [message, setMessage] = useState({});

    const [tellPhone, setTellPhone] = useState('');

    React.useEffect(() => {
        setTellPhone(tell);
    },[modalStatus]);

    function itemMessage (message,type) { // type : item, direct
        message = {
            ...message,
            type
        }
        setMessage(message);
    }

    function changeValueMessage (value) {
        value = {
            ...message,
            ...value,
        }
        setMessage(value);
    }

    return (
        <Modal
            isOpen={isOpen}
            toggle={(e) => {
                toggle('close', e)
                setMessage({})
            }}
            className={'modal-lg modal-dialog-scrollable'}
            wrapClassName={'modal_send_mail custom_modal_sum'}
        >
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">상담전화</h5>
                    <button type="button" className="close" onClick={(e) => {
                        toggle('close', e)
                        setMessage({})
                    }}>
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="box_send_mail">
                        <div className="header_mail">
                            <div className="box_control_input bn_r">
                                <input
                                    type="text"
                                    placeholder="휴대전화"
                                    className="form_control form-control-sm"
                                    value={tellPhone}
                                    onChange={(e)=>{
                                        setTellPhone(formatNumberPhone(e.target.value))
                                    }}
                                />
                                <div className="input_group_inline">
                                    <button className="btn_form btn_succsec"><i className="fas fa-phone" /></button>
                                </div>
                            </div>
                        </div>
                        <div className="box_content_mail">
                            <div className="box_left">
                                <table className="tablec">
                                    <thead>
                                    <tr><th width="50%">발송문구</th>
                                        <th width="50%">발송문구</th>
                                    </tr></thead>
                                    <tbody>
                                    {messages.map((item,index) => {
                                        return (
                                            <tr
                                                key={index}
                                                onClick={() => itemMessage(item,'item')}
                                            >
                                                <td>{item.title}</td>
                                                <td>
                                                    <div className="box_check custom">
                                                        <input
                                                            type="checkbox"
                                                            id={`checkc00${index}`}
                                                            checked={item.status == 1 ? true : false}
                                                            readOnly
                                                        />
                                                        <label
                                                            htmlFor={`checkc00${index}`}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}


                                    </tbody>
                                </table>
                                <div className="group_control">
                                    <button
                                        type="button"
                                        className={`btn ${message.type === 'direct' ? 'btn_002' : 'btn_001' }`}
                                        onClick={()=>{
                                            let itemDefault = {};

                                            if(!isEmpty(message)){
                                                itemDefault = {...message, image_messages: []};
                                            }else{
                                                itemDefault = { // set bien mac dinh để đúng định dạng item api fetch về
                                                    content : '',
                                                    send_type : 0, // mac dinh sms
                                                    image_messages : []
                                                };
                                            }

                                            itemMessage(itemDefault,'direct')
                                        }}
                                    >
                                        직접입력
                                    </button>
                                </div>
                            </div>
                            {message.type ?
                                <ItemMessage
                                    message={message}
                                    accessToken={accessToken}
                                    changeValueMessage={changeValueMessage}
                                    saveDirectMessage={saveDirectMessage}
                                />
                            : ''}

                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        className="btn btn_green"
                        onClick={()=>submitMessage(message,tellPhone)}
                    >발송</button>
                </div>
            </div>
        </Modal>
    )
}

export default ModalMessage;