import {
    CREATE_SALE_LOTS,
    CREATE_SALE_LOTS_SUCCEED,
    CREATE_SALE_LOTS_FAILED,
    UPDATE_SALE_LOTS,
    UPDATE_SALE_LOTS_SUCCEED,
    UPDATE_SALE_LOTS_FAILED,
    DELETE_SALE_LOTS,
    DELETE_SALE_LOTS_SUCCEED,
    DELETE_SALE_LOTS_FAILED
} from '../actions/actionTypes';
import api from '../../utils/api';

export const createSaleLots = () => {
    return {
        type: CREATE_SALE_LOTS
    }
};

export const createSaleLotsSucceed = saleLots => {
    return {
        type: CREATE_SALE_LOTS_SUCCEED,
        payload: {
            saleLots
        }
    }
};

export const createSaleLotsFailed = error => {
    return {
        type: CREATE_SALE_LOTS_FAILED,
        payload: {
            error: error
        }
    }
};

export const updateSaleLots = () => {
    return {
        type: UPDATE_SALE_LOTS
    }
};

export const updateSaleLotsSucceed = saleLots => {
    return {
        type: UPDATE_SALE_LOTS_SUCCEED,
        payload: {
            saleLots
        }
    }
};

export const updateSaleLotsFailed = error => {
    return {
        type: UPDATE_SALE_LOTS_FAILED,
        payload: {
            error: error
        }
    }
};

export const deleteSaleLots = () => {
    return {
        type: DELETE_SALE_LOTS
    }
};

export const deleteSaleLotsSucceed = saleLots => {
    return {
        type: DELETE_SALE_LOTS_SUCCEED,
        payload: {
            saleLots
        }
    }
};

export const deleteSaleLotsFailed = error => {
    return {
        type: DELETE_SALE_LOTS_FAILED,
        payload: {
            error: error
        }
    }
};


/**
 * Create saleLots
 */
export const createSaleLotsAction = (saleLots) => dispatch => {
    dispatch(createSaleLots());
    return api.saleLots.createSaleLots(saleLots)
        .then(response => {
            dispatch(createSaleLotsSucceed(response.data.data));
            return Promise.resolve(response.data);
        })
        .catch(error => {
            dispatch(createSaleLotsFailed(error.response.data.errors.message));
            return Promise.reject(error.response)
        });
};

/**
 * Update saleLots
 */
export const updateSaleLotsAction = (id, saleLots) => dispatch => {
    dispatch(updateSaleLots());
    return api.saleLots.updateSaleLots(id, saleLots)
        .then(response => {
            dispatch(updateSaleLotsSucceed(response.data.data));
            return Promise.resolve(response.data);
        })
        .catch(error => {
            dispatch(updateSaleLotsFailed(error.response.data.errors.message));
            return Promise.reject(error.response)
        });
};

/**
 * Delete saleLots
 */
export const deleteSaleLotsAction = lots_id => dispatch => {
    dispatch(deleteSaleLots());
    const id = {
        params: {
            lots_id: lots_id
        }
    }
    return api.saleLots.deleteSaleLots(id)
        .then(response => {
            dispatch(deleteSaleLotsSucceed(response.data.data));
            return Promise.resolve(response.data);
        })
        .catch(error => {
            dispatch(deleteSaleLotsFailed(error.response.data.errors.message));
            return Promise.reject(error.response)
        });
};



