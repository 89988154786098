import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Route, Redirect} from "react-router-dom";

const GuestRoute = ({isAuthenticated, component: Component,  layout: Layout, ...rest}) => {

  if(Layout) {

    return (
      <Route
        {...rest}
        render={props => !isAuthenticated ? <Layout {...props} {...rest}>
          <Component {...props}/>
        </Layout> : <Redirect to={'/'}/>}
      />
    )
  }

  return (
    <Route
      {...rest}
      render={props => !isAuthenticated ? <Component {...props} /> :
        <Redirect to={'/'}/>
      }
    />

  )
};

GuestRoute.propTypes = {
  // component: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired
};

const mapStateToProps = state => {
  return {
    isAuthenticated: !!state.auth.token
  };
};

export default connect(mapStateToProps)(GuestRoute);
