import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from "react-intl";
import { Helmet } from 'react-helmet';
import SmsSetting from './../SmsSetting';
import RichTextEditor from 'react-rte';
import Pagination from "react-js-pagination";
import NotificationSystem from 'react-notification-system';
import CreatePredefineSMSModal from './../../../components/SmsSetting/SMSMMS/PredefineSMS/CreatePredefineSMSModal';
import UpdatePredefineSMSModal from './../../../components/SmsSetting/SMSMMS/PredefineSMS/UpdatePredefineSMSModal';
import DeleteConfirmModal from '../../../components/DeleteConfirmModal/DeleteConfirmModal';
import TextareaAutosize from 'react-autosize-textarea';
import HasRole from "../../../utils/entrust/HasRole";
import { hasRole } from "../../../utils/entrust/entrust";
import progressBar from '../../../utils/progressBar';
import * as routerName from "../../../utils/routerName";
import api from '../../../utils/api';
import {renderMessage,removeItemInArray} from '../../../utils/helpers';
import { fetchCompaniesAction } from "../../../store/actions";
import * as webSocketApi from './../../../utils/websocketApi';
import * as apipostimageatalk from './../../../utils/apipostimageatalk';
import {
    fetchPredefineSMSAction,
    unmountPredefineSMSAction,
    createPredefineSMSAction,
    deletePredefineSMSAction,
    updatePredefineSMSAction,
} from "../../../store/actions/predefinesms";
import { fetchCompaniesCheckAuthAction } from "../../../store/actions/company";


class PredefineSMS extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: this.props.user.csta_id,
            password: this.props.user.csta_pwd,
            deviceObject: this.props.user.usr_tel,
            companies: [],
            currentCompany: null,
            infoSaleLotList: [],
            currentInfoSaleLot: null,
            predefineSMSList: [],
						saleLotConfig: [],
            currentPredefineSMS: {},
            predefineSMSForm: {
								company_id: this.props.user.company_id, 
								lots_id: 0, 
                name: '',
                phone: ''
            },
            openModalCreate: false,
            openModalUpdate: false,
            isDeleteModalOpen: false,
            isDeleteConfirmModalOpen: false,

            deletingRow: null,
            notification: {
                allowHTML: false,
            },
						company_id: this.props.user.company_id, 
						lots_id: 0,
            access_token: '',
            maxLength: 90,
						totalLength: 0,
            loading: false,
        };
    }

    notificationSystem = React.createRef();
    addNotification = (data) => {
        const notification = this.notificationSystem.current;
        notification.addNotification({
            message: data.message,
            title: data.title,
            level: data.level
        });
    };

    componentDidMount() {
        progressBar.start();
        this.props.onFetchCompanies().then(response => {
            this.setState({
                ...this.state,
                companies: response.data,
                //if user role is admin then not display select company and default currentCompany is company belong to admin
                currentCompany: hasRole(this.props.auth.user, 'admin') ? response.data[0] : null,
                infoSaleLotList: hasRole(this.props.auth.user, 'admin') ? response.data[0].info_sale_lots : [],
            }, () => {
                // console.log(this.state, 'after_didmount'); 
            });
            progressBar.done();
        }).catch(error => {
            progressBar.done();
        });

    };

		setTotalLength = (_byte) => {
				this.setState({
						...this.state,
						totalLength: _byte
				}, () => {
						
				})
		};

    selectCompanyHandler = (event) => {
        let { value } = event.target;
        if (value) {
            let company = JSON.parse(value);
            this.setState({
                ...this.state,
                currentCompany: company,
                infoSaleLotList: company.info_sale_lots,
                currentInfoSaleLot: null,
                predefineSMSList: [],
                currentPredefineSMS: {}
            }, () => {
                console.log(this.state.infoSaleLotList, 'infoList');
            })
        } else {
            this.setState({
                ...this.state,
                currentCompany: null,
                infoSaleLotList: [],
                currentInfoSaleLot: null,
                predefineSMSList: [],
                currentPredefineSMS: {},
            })
        }
    };

		saveConfig = () => {
        let params = {
            config: JSON.stringify(this.state.saleLotConfig),
						lots_id: this.state.lots_id
        }
				api.predefinesms.setConfigSaleLot(params).then(response => {
            this.setState({
                ...this.state,
                notification: {
                    allowHTML: false,
                },
                predefineSMSForm: {
										lots_id: 0, 
                    name: '',
                    phone: ''
                },
            }, () => {
                this.addNotification({
                    title: "Success!",
                    message: "설정 저장 성공 !",
                    level: 'success',
                })
            })
				}).catch(error => {        
            this.setState({
                ...this.state,
                notification: {
                    allowHTML: false,
                },
                predefineSMSForm: {
										lots_id: 0, 
                    name: '',
                    phone: ''
                },
            }, () => {
                this.addNotification({
                    title: "Fail!",
                    message: "설정 저장 실패 !",
                    level: 'error',
                })
            })
				});
		}

    selectInfoSaleLotHandler = (event) => {
        let { value } = event.target;
        if (value) {
            let infoSaleLot = JSON.parse(value);
            let params = {
                type: 'paginate',
            }                     
            this.getPredefineSMSList(infoSaleLot, params);
            
            // update rightnow saleLot selected
            this.setState({
                ...this.state,
                currentInfoSaleLot: infoSaleLot
            },() => {
							// 선택을 하면 해당 설정을 가져온다.
							api.predefinesms.getConfigSaleLot(infoSaleLot.lots_id).then(response => {
								 this.setState({
										 ...this.state,
										 saleLotConfig: response.data.data.data
								 }, () => {
									    console.log('saleLotConfig', this.state.saleLotConfig);
								 })
							}).catch(error => {        

							});
						});
        } else {
            this.setState({
                ...this.state,
                currentInfoSaleLot: null,
                predefineSMSList: [],
                currentPredefineSMS: {},
            })
        }
    };

    getPredefineSMSList = (infoSaleLot) => {
        progressBar.start();
        let params = {
            page: this.state.predefineSMSList?.current_page || 1, 
            type: 'paginate',
        }

        return this.props.onFetchPredefineSMS(infoSaleLot.lots_id, params).then(response => {
            this.setState({
                ...this.state,
                lots_id: infoSaleLot.lots_id,
                predefineSMSList: response,
                currentInfoSaleLot: infoSaleLot
            });
            progressBar.done();
        }).catch(error => {
            progressBar.done();
        });
    }

    changePage = (pageNumber) => {
        this.setState({
            ...this.state,
            predefineSMSList: {
                ...this.state.predefineSMSList,
                current_page: pageNumber
            }
        }, () => {
            this.getPredefineSMSList(this.state.currentInfoSaleLot)
        })
    }

    selectTextManager = (textManager) => {
        this.setState({
            // ...this.state,
            currentPredefineSMS: textManager,
        }, () => {
            console.log(this.state.currentCompany, 'textManager_af');
        })
    }


    /**
     * CRUD Text Manager
     */
    handleMaster = (event, item) => {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

				console.log('item:', item);

				let key = item.key;
				let {saleLotConfig} = this.state;

				saleLotConfig.master_list.map((item, index) => {
					if (item.key == key)
					{
						saleLotConfig.master_list[index].val = target.checked ? 1: 0;
					}
				});

        this.setState({
					...this.state,
					saleLotConfig: saleLotConfig,
        }, () => {
					// 상태 변경
        });

    };

    handleProfile = (event, item) => {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

				console.log('item:', item);

				let key = item.key;
				let {saleLotConfig} = this.state;

				saleLotConfig.profile_list.map((item, index) => {
					if (item.key == key)
					{
						saleLotConfig.profile_list[index].val = target.checked ? 1: 0;
					}
				});

        this.setState({
					...this.state,
					saleLotConfig: saleLotConfig,
        }, () => {
					// 상태 변경
        });

    };

    editList = (item) => {
        this.setState({
            ...this.state,
            predefineSMSForm: item,
        }, () => {
            this.toggleModalUpdate();
        });
    };

    onCreate = () => {
        progressBar.start();
        let { predefineSMSForm } = this.state;
        predefineSMSForm.lots_id = this.state.currentInfoSaleLot.lots_id;
        predefineSMSForm.company_id = this.state.currentInfoSaleLot.lots_id;
        console.log(predefineSMSForm, 'predefineSMSForm_data');

        this.props.createPredefineSMS(predefineSMSForm).then(response => {
            this.toggleModalCreate();
            this.getPredefineSMSList(this.state.currentInfoSaleLot);  
            this.setState({
                ...this.state,
                notification: {
                    allowHTML: false,
                },
                predefineSMSForm: {
										lots_id: 0, 
                    name: '',
                    phone: ''
                },
            }, () => {
                this.addNotification({
                    title: <FormattedMessage
                        id="dashboard.Succeed"
                        defaultMessage="Succeed!" />,
                    message: <FormattedMessage
                        id="dashboard.Counselor.CreateSucceed"
                        defaultMessage="Add new Counselor successfully!" />,
                    level: 'success',
                })
                progressBar.done();
            })

        })
            .catch(error => {
                if (error.data) {
                    this.setState({
                        ...this.state,
                        notification: {
                            allowHTML: true,
                        }
                    }, () => {
                        this.addNotification({
                            title: <FormattedMessage
                                id="dashboard.Error"
                                defaultMessage="Error !" />,
                            level: 'error',
                            message: renderMessage(error.data.errors.message),
                        })
                        progressBar.done();
                    })
                }
            });
    };

    onUpdate = () => {
        progressBar.start();
        let { predefineSMSForm } = this.state;
        predefineSMSForm.data_api = predefineSMSForm.image_messages;
        predefineSMSForm.lots_id = parseInt(predefineSMSForm.lots_id);
        console.log(predefineSMSForm, 'predefineSMSForm_data');
        
        this.props.updatePredefineSMS(predefineSMSForm.idx, predefineSMSForm).then(response => {
            this.toggleModalUpdate();
            this.getPredefineSMSList(this.state.currentInfoSaleLot)
           // this.selectTextManager(predefineSMSForm);  // re-fetch new value current saleLot selected

            this.setState({
                ...this.state,
                notification: {
                    allowHTML: false,
                },
                predefineSMSForm: {
										lots_id: 0, 
                    name: '',
                    phone: ''
                },
            }, () => {
                this.addNotification({
                    title: <FormattedMessage
                        id="dashboard.Succeed"
                        defaultMessage="Succeed!" />,
                    message: <FormattedMessage
                        id="dashboard.ItemValidate.UpdateSucceed"
                        defaultMessage="Update item successfully!" />,
                    level: 'success',
                })
                progressBar.done();
            });
        })
        .catch(error => {
            if (error.data) {
                this.setState({
                    ...this.state,
                    notification: {
                        allowHTML: true,
                    }
                }, () => {
                    this.addNotification({
                        title: <FormattedMessage
                            id="dashboard.Error"
                            defaultMessage="Error !" />,
                        level: 'error',
                        message: renderMessage(error.data.errors.message),
                    })
                    progressBar.done();
                })
            }
        });
    };

    setDeletingRow = (value) => {
        this.setState({
            ...this.state,
            deletingRow: value,
        }, () => {
            this.openDeleteModal();
        });
    };

    deleteListHandler = () => {
        progressBar.start();
        this.props.deletePredefineSMS(this.state.deletingRow).then(response => {
            // check and re-set current page if delete last item of last page
            let {predefineSMSList} = this.state; 
            let current_page = predefineSMSList.from == predefineSMSList.to ? predefineSMSList.last_page - 1 : predefineSMSList.current_page;

            //check and update Text Manager after delete any item Text Manager
            let currentPredefineSMS = this.state.currentPredefineSMS.idx == this.state.deletingRow ? {} : this.state.currentPredefineSMS;
            this.setState({
                ...this.state,
                currentPredefineSMS,

                // deletingRow: null,
                isDeleteConfirmModalOpen: false,
                notification: {
                    allowHTML: false,
                },
                predefineSMSList: {
                    ...this.state.predefineSMSList,
                    current_page,
                }
            }, () => {
                this.getPredefineSMSList(this.state.currentInfoSaleLot);

                this.addNotification({
                    title: <FormattedMessage
                        id="dashboard.Succeed"
                        defaultMessage="Succeed!" />,
                    message: <FormattedMessage
                        id="dashboard.ItemValidate.DeleteSucceed"
                        defaultMessage="Delete item successfully!" />,
                    level: 'success',
                })

                progressBar.done();
            })

        }).catch(error => {
            progressBar.done();
        })
    };






    /**
     *  Modal Function
     */
    // Modal Company
    toggleModalCreate = () => {
        this.setState({
            ...this.state,            
            openModalCreate: !this.state.openModalCreate,
            predefineSMSForm: {
								lots_id: 0, 
                name: '',
                phone: ''
            },
        }, () => {
            // console.log(this.state, 'state_after');
        });
    };

    toggleModalUpdate = () => {
        this.setState({
            ...this.state,
            openModalUpdate: !this.state.openModalUpdate,
        }, () => {
        });
    };

    openDeleteModal = () => {
        this.setState({
            isDeleteModalOpen: !this.state.isDeleteModalOpen
        });
    };

    openDeleteConfirmModal = () => {
        this.setState({
            isDeleteConfirmModalOpen: !this.state.isDeleteConfirmModalOpen
        });
    };




    /**
    *  process image Func
    */
    renderImageUpload = (images) => {
        return images.map((item, index) => {
            return (
                <div className="content-img" key={index}>
                    <img
                        src={item}
                        className="table-hover-cursor"
                    />
                </div>
            )
        })
    }

    renderMapImage = (images) => {
        if (Array.isArray(images)) {
            return images.map((item, index) => {
                return (
                    <div className="item_file" key={index}>
                        <div className="img">
                            <img src={item.image_path} />
                        </div>
                        {/* <div className="control_img">
                            <label htmlFor>{item.image_name}</label>
                        </div> */}
                    </div>
                )
            })
        }
    }

    handleChangeInput = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            ...this.state,
            predefineSMSForm: {
                ...this.state.predefineSMSForm,
                [name]: value,
            }
        });        
    };


    componentWillUnmount() {
        this.props.unmountPredefineSMS();
    }

    render() {
        const {
            companies,
            infoSaleLotList,
            predefineSMSList,
            currentCompany,
						saleLotConfig,
            currentInfoSaleLot,
            currentPredefineSMS
        } = this.state;
        console.log(currentPredefineSMS, 'currentPredefineSMS2');

        return (
            <Fragment>
                <NotificationSystem ref={this.notificationSystem} allowHTML={this.state.notification.allowHTML} />

                <FormattedMessage id="dashboard.SmsSetting.TextManagement"
                    defaultMessage="Text Management">
                    {message => <Helmet>
                        <title>{message}</title>
                    </Helmet>}
                </FormattedMessage>

                <div className="main_top_bar static_bar">
                    <div className="box_sum_col_top">
                        <div className="box_left">
                            <ul className="nav nav_tabs">
                                <li className="nav_item">
                                    <span className="nav_link">
                                        <FormattedMessage id="dashboard.TextManagement.MainTitle" defaultMessage="Text Management!" />
                                        {/* <FormattedMessage id="dashboard.TextManagement.MainTitle" defaultMessage="Text Management!" /> */}
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div className="box_center">
                            <div className="tabs_static_item">
                                <div className="main_control_time_select">

                                    <HasRole role={['primarynet']}>
                                        <div className="main_select main_box">
                                            <div className="main_label">
                                                <FormattedMessage id="dashboard.Company" defaultMessage="Company" />
                                            </div>

                                            <div className="custom_select">
                                                {/* selectbox list company */}
                                                <select className="form_control" onChange={this.selectCompanyHandler} name="sltCompany">
                                                    <option value=''>
                                                        {this.props.intl.formatMessage({ id: 'dashboard.SelectCompany', defaultMessage: 'SelectCompany' })}
                                                    </option>
                                                    {
                                                        companies?.map((item, index) => {
                                                            return (
                                                                <option key={index} value={JSON.stringify(item)}>{item.company_name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                                <span className="item_dropdown" />
                                            </div>
                                        </div>
                                    </HasRole>

                                    <div className="main_select main_box">
                                        <div className="main_label">
                                            <FormattedMessage id="dashboard.AffiliatedCompany" defaultMessage="AffiliatedCompany" />
                                        </div>

                                        <div className="custom_select">
                                            {/* selectbox list info saleLot */}
                                            <select className="form_control" onChange={this.selectInfoSaleLotHandler} name="sltSaleLot" value={currentInfoSaleLot && JSON.stringify(currentInfoSaleLot) || '' }>
                                                <option value="">
                                                    {this.props.intl.formatMessage({ id: 'dashboard.SelectAffiliatedCompany', defaultMessage: 'SelectAffiliatedCompany' })}
                                                </option>
                                                {
                                                    infoSaleLotList?.map((item, index) => {
                                                        return (
                                                            <option key={index} value={JSON.stringify(item)}>{item.lots_name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            <span className="item_dropdown" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main_content">
                    <div className="content_tabs_main content_static_tabs tab-content">
                        <div className="tabs_static_item tabs_item1">
                            <div className="box_content_static">
                                <div className="left_col left_col_customer_care">
                                    <SmsSetting 
                                        location={this.props.location}
                                    />
                                </div>
                                <div className="content_col_nopading">
                                    <div className="list_control_box_preferences">
                                        <div className="content_left">
                                            <div className="content_column">
                                                <div className="column_box mw_50">
                                                    <div className="heading_box">
                                                        <h5 className="title_preferences">
                                                            <FormattedMessage id="dashboard.PredefineSMS.Config" defaultMessage="Title!" />
                                                        </h5>
                                                    </div>
                                                    <div className="control_box_item">
                                                        <div className="card_header">
                                                            <div className="box_search">
                                                            </div>
                                                            <div className="box_add">
																														{
																																predefineSMSList?.data?.length < 9 ? 
                                                                (<button type="button" className="btn btn_add" onClick={this.toggleModalCreate}
                                                                    disabled={currentCompany && currentInfoSaleLot ? '' : 'disabled'} >
                                                                    <i className="fas fa-plus" />
                                                                </button>) : ""
																														}
                                                            </div>
                                                        </div>
                                                        <div className="card_body">
                                                            <div className="custom_table">
                                                                <table className="table st_table_5">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>
                                                                                <FormattedMessage id="dashboard.PredefineSMS.Board.Name" defaultMessage="Name!" />
                                                                            </th>
                                                                            <th>
                                                                                <FormattedMessage id="dashboard.PredefineSMS.Board.Phone" defaultMessage="Phone!" />
                                                                            </th>
                                                                            <th />
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {/* render text manager list */}
                                                                    {
                                                                        predefineSMSList?.data?.length > 0 ?
                                                                            predefineSMSList.data.map((item, index) => {
                                                                                let active = this.state.currentPredefineSMS?.idx == item.idx ? 'active' : '';
                                                                                return (
                                                                                    <tr key={index} className={active}>
                                                                                        <td>{item.name}</td>
                                                                                        <td>{item.display_phone}</td>
                                                                                        <td className="inline_action">
                                                                                            <a onClick={() => this.editList(item)}> <i className="fas fa-edit" /> </a>
                                                                                            <a onClick={() => this.setDeletingRow(item.idx)} ><i className="fas fa-trash-alt" /></a>
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            }) :
                                                                            (
                                                                                <tr>
                                                                                    <td colSpan={3}>
                                                                                        <div className="nodata">No Data</div>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                    }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        {
                                                            predefineSMSList?.data?.length > predefineSMSList?.per_page && (
                                                                <div className="car_footer">
                                                                    <nav aria-label="...">
                                                                        <ul className="custom_pagination pagination">
                                                                            <Pagination
                                                                                itemClass="page-item"
                                                                                linkClass="page-link"
                                                                                activePage={predefineSMSList?.current_page}
                                                                                itemsCountPerPage={predefineSMSList?.per_page}
                                                                                totalItemsCount={predefineSMSList?.total || 0}
                                                                                pageRangeDisplayed={10}
                                                                                onChange={this.changePage}
                                                                            />
                                                                        </ul>
                                                                    </nav>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>

                                                {
                                                    currentCompany && currentInfoSaleLot && (

                                                        <div className="column_box mw_50 box_preferenc1" style={{backgroundColor: "#F7F7F7"}}> 

                                                            <div className="heading_box" style={{ width:"100%", padding: "10px", clear: "both", backgroundColor: "#fff", border: "1px"}}>
                                                                <h5 className="title_preferences">
                                                                    프로필
                                                                </h5>
                                                            </div>

                                                            <div className="group_textarea" style={{ width:"100%", padding: "10px", clear: "both"}}>

																														{
																															saleLotConfig?.profile_list?.length > 0 ?
																																	saleLotConfig.profile_list.map((item, index) => {
																																			let checked = item.val == 1 ? true : false;
																																			return (
																																			<div style={{ width:"150px", float: "left"}}>
																																				<table>
																																					<tr key={index}>
																																							<td><input type="checkbox" name={item.key} checked={checked} 
																																										onChange={ (e) =>this.handleProfile(e, item) }/></td>
																																							<td>{item.descr}</td>
																																					</tr>
																																				</table>
																																			</div>
																																			)
																																	}) :
																																	(
																																		<table>
																																			<tr>
																																					<td colSpan={3}>
																																							<div className="nodata">No Profile</div>
																																					</td>
																																			</tr>
																																		</table>
																																	)
																														}

                                                            </div>

																														<div className="heading_box" style={{ width:"100%", padding: "10px", clear: "both"}}></div>
                                                            <div className="heading_box" style={{ width:"100%", padding: "10px", clear: "both", backgroundColor: "#fff", border: "1px"}}>
                                                                <h5 className="title_preferences">
                                                                    상담내용
                                                                </h5>
                                                            </div>

                                                            <div className="group_textarea" style={{ width:"100%", padding: "10px", clear: "both", border: "1px"}}>

																														{
																															saleLotConfig?.master_list?.length > 0 ?
																																	saleLotConfig.master_list.map((item, index) => {
																																			let checked = item.val == 1 ? true : false;
																																			return (
																																			<div style={{ width:"150px", float: "left"}}>
																																				<table>
																																					<tr key={index}>
																																							<td><input type="checkbox" name={item.key} checked={checked} 
																																										onChange={ (e) =>this.handleMaster(e, item) }/></td>
																																							<td>{item.descr}</td>
																																					</tr>
																																				</table>
																																			</div>
																																			)
																																	}) :
																																	(
																																		<table>
																																			<tr>
																																					<td colSpan={3}>
																																							<div className="nodata">No Profile</div>
																																					</td>
																																			</tr>
																																		</table>
																																	)
																														}

                                                            </div>

																														<div className="heading_box" style={{ width:"100%", padding: "10px", clear: "both"}}></div>
                                                            <div className="heading_box" style={{ width:"100%", clear: "both", paddingLeft: "50px"}}>
                                                                <button onClick={() => this.saveConfig()}> <i className="fas fa-edit" /> 저장 </button>
                                                            </div>

                                                        </div>
                                                    )
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>

                {/* Modal Component List  */}

                <CreatePredefineSMSModal
                    isOpen={this.state.openModalCreate}
                    toggle={this.toggleModalCreate}
                    currentPredefineSMS={this.state.currentPredefineSMS}
                    predefineSMSForm={this.state.predefineSMSForm}
										//week={this.state.week}
										setWeek={this.setWeek}
										totalLength={this.state.totalLength}

                    // handleChangeEditor={this.handleChangeEditor}
                    handleChange={this.handleChangeInput}
                    handleBrowseImage={this.handleBrowseImage}
                    addImageAPICRM={this.addImageAPICRM}
                    handleRemoveImage={this.handleRemoveImage}
                    onCreate={this.onCreate}
                />

                <UpdatePredefineSMSModal
                    isOpen={this.state.openModalUpdate}
                    toggle={this.toggleModalUpdate}
                    currentPredefineSMS={this.state.currentPredefineSMS}
                    predefineSMSForm={this.state.predefineSMSForm}
										handleWeek={this.handleWeek}
										handleWeekAll={this.handleWeekAll}
										week={this.state.week}
										weekAll={this.state.weekAll}
										totalLength={this.state.totalLength}

                    handleChange={this.handleChangeInput}
                    handleBrowseImage={this.handleBrowseImage}
                    addImageAPICRM={this.addImageAPICRM}
                    handleRemoveImage={this.handleRemoveImage}
                    onUpdate={this.onUpdate}
                />

                <DeleteConfirmModal
                    isDeleteModalOpen={this.state.isDeleteModalOpen}
                    openDeleteModal={this.openDeleteModal}
                    isDeleteConfirmModalOpen={this.state.isDeleteConfirmModalOpen}
                    openDeleteConfirmModal={this.openDeleteConfirmModal}
                    deleteInfoHandler={this.deleteListHandler}
                />

            </Fragment>
        )
    }
}



const mapStateToProps = state => {
    return {
        auth: state.auth,
        user: state.auth.user
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchCompanies: () => dispatch(fetchCompaniesAction()),
        fetchCompaniesCheckAuth: () => dispatch(fetchCompaniesCheckAuthAction()),
        onFetchPredefineSMS: (id, params) => dispatch(fetchPredefineSMSAction(id, params)),
        unmountPredefineSMS: () => dispatch(unmountPredefineSMSAction()),
        createPredefineSMS: (data) => dispatch(createPredefineSMSAction(data)),
        updatePredefineSMS: (id, data) => dispatch(updatePredefineSMSAction(id, data)),
        deletePredefineSMS: (id) => dispatch(deletePredefineSMSAction(id)),
    }
};


export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PredefineSMS));
