import {
    CHANGE_SCENE,
    CHANGE_SCENE_COMPANY,
    CHANGE_SCENE_LOT, CHANGE_TYPE_OF_CHANGE
} from "../type";

const changeSceneCompany = (companyId) => {
    return {
        type: CHANGE_SCENE_COMPANY,
        payload: companyId
    }
}

const changeSceneLot = (lotsId) => {
    return {
        type: CHANGE_SCENE_LOT,
        payload: lotsId
    }
}

const changeScene = status => {
    return {
        type: CHANGE_SCENE,
        payload: status
    }
}

const changeSceneAction = (type) => dispatch => {
    new Promise(resolve => {
        dispatch(changeScene(true))
        dispatch(changeTypeOfChange(type))
        resolve(true);
    }).then(res => {
        dispatch(changeScene(false))
        dispatch(changeTypeOfChange(null))
    })

}

const changeTypeOfChange = (type) => {
    return {
        type: CHANGE_TYPE_OF_CHANGE,
        payload: type
    }
}

export const changeSceneCompanyAction = companyId => dispatch => {
    dispatch(changeSceneCompany(companyId))
    dispatch(changeSceneAction(CHANGE_SCENE_COMPANY))
}

export const changeSceneLotAction = lotsId => dispatch => {
    dispatch(changeSceneLot(lotsId))
    dispatch(changeSceneAction(CHANGE_SCENE_LOT))
}

