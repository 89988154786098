import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from "react-intl";
import { Helmet } from 'react-helmet';
import RichTextEditor from 'react-rte';
// import 'react-rte/lib/Draft.global.css'
import './CustomerEditer.css';
import {
    fetchCompaniesAction,
    createCompanyAction,
    updateCompanyAction,
    deleteCompanyAction,
    createSaleLotsAction,
    updateSaleLotsAction,
    deleteSaleLotsAction
} from "../../../store/actions";
import HasRole from "../../../utils/entrust/HasRole";
import progressBar from '../../../utils/progressBar';
import * as routerName from "../../../utils/routerName";
import { hasRole } from "../../../utils/entrust/entrust";
import api from '../../../utils/api';
import {renderMessage} from '../../../utils/helpers';
import DataSetting from './../DataSetting';
import Pagination from "react-js-pagination";
import NotificationSystem from 'react-notification-system';
import CreateSaleLotCompanyModal from './../../../components/DataSetting/CompanyRegistration/CreateSaleLotCompanyModal';
import UpdateSaleLotCompanyModal from './../../../components/DataSetting/CompanyRegistration/UpdateSaleLotCompanyModal';
import DeleteConfirmModal from '../../../components/DeleteConfirmModal/DeleteConfirmModal';

class AffiliatedCompanyManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saleLots: {
                total: 0,
            },
            currentCompany: {},
            currentSaleLot:  {},
            saleLotForm: {},
            deletingRow: null,
            valueDraft: RichTextEditor.createEmptyValue(''),

            openModalCreateCompany: false,
            openModalUpdateCompany: false,
            openModalCreateSaleLot: false,
            openModalUpdateSaleLot: false,
            isDeleteModalOpen: false,
            isDeleteConfirmModalOpen: false,
            notification: {
                allowHTML: false,
            }
        };
    }

    notificationSystem = React.createRef();
    addNotification = (data) => {
      const notification = this.notificationSystem.current;
      notification.addNotification({
        message: data.message,
        title: data.title,
        level: data.level
      });
    };

    componentDidMount() {
        // paginate: get data Paginate Format  || list: get data List Format
        let params = { 
            type: 'list'
        }
        this.getCompanies(params);
    };

    getCompanies = (params) => {
        progressBar.start();
        this.props.onFetchCompanies(params).then(response => {
            // call list salelots match company of current user ( Only used to role Admin )
            this.setState({
                ...this.state,
                currentCompany: response?.data[0] || null,
            }, () => {
                this.getSaleLotsByCompany(this.state.currentCompany)
            });

            progressBar.done();
        }).catch(error => {         
        });
    }

    getSaleLotsByCompany = (company) => {
        progressBar.start();   
        let params = {
            page: this.state.saleLots?.current_page || 1,
            type: 'paginate',
        }
        api.saleLots.getSaleLotsByCompany(company.company_id, params).then(response => {
           this.setState({
               ...this.state,
               currentCompany: company,
               saleLots: response.data.data
           }, () => {
            //    console.log(this.state.saleLots, 'saleLotss');
           })
            progressBar.done();
        }).catch(error => {        
            progressBar.done();
        });
    }

    changePageSaleLot = (pageNumber) => {
        this.setState({
            ...this.state,
            saleLots: {
                ...this.state.saleLots,
                current_page: pageNumber
            }
        }, () => {
            this.getSaleLotsByCompany(this.state.currentCompany);
        })
    }

    getInfoSaleLot = (saleLot) => {
        this.setState({
            // ...this.state,
            currentSaleLot: saleLot
        })
    }



    /**
     *  Modal Function
     */
    // Modal SaleLot
    toggleModalCreateSaleLot = () => {
        this.setState({
            ...this.state,
            saleLotForm: {},
            valueDraft: RichTextEditor.createEmptyValue(''),
            openModalCreateSaleLot: !this.state.openModalCreateSaleLot,
        }, () => {
            // console.log(this.state, 'state_after');
        });
    };

    toggleModalUpdateSaleLot = () => {
        this.setState({
            ...this.state,
            openModalUpdateSaleLot: !this.state.openModalUpdateSaleLot,
        }, () => {
            // console.log(this.state, 'state_after');
        });
    };


    /**
     * CRUD SaleLot
    */
    handleChangeInputSaleLot = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            ...this.state,
            saleLotForm: {
                ...this.state.saleLotForm,
                [name]: value,
            }
        });        
    };

    handleChangeEditor = (value) => {
        this.setState({
            ...this.state,
            saleLotForm: {
                ...this.state.saleLotForm,
                lots_desc: value.toString('html'),
            },
            valueDraft: value,
        }, () => {
        });        
    };


    onCreateSaleLot = () => {
        progressBar.start();
        let currentCompany = this.state.currentCompany;
        let saleLot = this.state.saleLotForm;
        saleLot.company_id = currentCompany.company_id;

        this.props.onCreateSaleLot(saleLot).then(response => {             
            this.toggleModalCreateSaleLot();
            this.getSaleLotsByCompany(currentCompany);
            this.setState({
                ...this.state,
                saleLotForm: {},
                notification: {
                    allowHTML: false,
                }
            }, () => {
                this.addNotification({
                    title: <FormattedMessage
                        id="dashboard.Succeed"
                        defaultMessage="Succeed!"/>,
                    message: <FormattedMessage
                        id="dashboard.Common.CreateSucceed"
                        defaultMessage="Add successfully!"/>,
                    level: 'success',
                })
                progressBar.done();
            });
        }).catch(error => {
            if (error.data) {
                this.setState({
                    ...this.state,
                    notification: {
                        allowHTML: true,
                    }
                }, () => {
                    this.addNotification({
                        title: <FormattedMessage
                                id="dashboard.Error"
                                defaultMessage="Error !"/>,
                        level: 'error',
                        message: renderMessage(error.data.errors.message),
                    })
                    progressBar.done();
                })
            }
        });
    };

    editSaleLot = (saleLot) => {
        // console.log(saleLot, 'saleLote');
        this.setState({
            ...this.state,
            saleLotForm: saleLot,
        }, () => {
            this.toggleModalUpdateSaleLot();
        });
    };

    onUpdateSaleLot = () => {
        progressBar.start();
        let currentCompany = this.state.currentCompany; 
        let saleLot = this.state.saleLotForm;

        this.props.onUpdateSaleLot(saleLot.lots_id, saleLot).then(response => {   
            this.toggleModalUpdateSaleLot();
            this.getSaleLotsByCompany(currentCompany);
            this.getInfoSaleLot(saleLot);  // re-fetch new value current saleLot selected

            this.setState({
                ...this.state,
                saleLotForm: {},
                notification: {
                    allowHTML: false
                }
            }, () => {
                this.addNotification({
                    title: <FormattedMessage
                        id="dashboard.Succeed"
                        defaultMessage="Succeed!"/>,
                    message: <FormattedMessage
                        id="dashboard.Common.UpdateSucceed"
                        defaultMessage="Update successfully!"/>,
                    level: 'success',
                })
                progressBar.done();
            });
        }).catch(error => {
            if (error.data) {
                this.setState({
                    ...this.state,
                    notification: {
                        allowHTML: true,
                    }
                }, () => {
                    this.addNotification({
                        title: <FormattedMessage
                                id="dashboard.Error"
                                defaultMessage="Error !"/>,
                        level: 'error',
                        message: renderMessage(error.data.errors.message),
                    })
                    progressBar.done();
                })
            };
        });
    }; 

    /**
     *  Delete Sale lot
    */
    deleteCompanyHandler = () => {
        progressBar.start();
        this.props.onDeleteSaleLot(this.state.deletingRow)
            .then(response => {                
                // check and re-set current page if delete last item of last page
                let {saleLots} = this.state; 
                let current_page = saleLots.from == saleLots.to ? saleLots.last_page - 1 : saleLots.current_page;
                
                //check and update saleLot after delete any item saleLot
                let currentSaleLot = this.state.currentSaleLot.lots_id == this.state.deletingRow ? {} : this.state.currentSaleLot;
                this.setState({
                    ...this.state,
                    currentSaleLot,
                    deletingRow: null,
                    isDeleteConfirmModalOpen: false,
                    deleteCompanyOrAfCompany: null,
                    notification: {
                        allowHTML: false,
                    },
                    saleLots: {
                        ...this.state.saleLots,
                        current_page,
                    }
                }, () => {
                    this.getSaleLotsByCompany(this.state.currentCompany);

                    this.addNotification({
                        title: <FormattedMessage
                            id="dashboard.Succeed"
                            defaultMessage="Succeed!"/>,
                        message: <FormattedMessage
                            id="dashboard.Common.DeleteSucceed"
                            defaultMessage="Delete successfully!"/>,
                        level: 'success',
                    })
                    progressBar.done();
                })   
            })
            .catch(error => {              
            });
    };

    setDeletingRow = (value, type) => {
        this.setState({
            ...this.state,
            deletingRow: value,
        }, () => {
            this.openDeleteModal();
        });
    };

    openDeleteModal = () => {
        this.setState({
            isDeleteModalOpen: !this.state.isDeleteModalOpen
        });
    };

    openDeleteConfirmModal = () => {
        this.setState({
            isDeleteConfirmModalOpen: !this.state.isDeleteConfirmModalOpen
        });
    };

    render() {
        const { 
            saleLots,
            currentCompany,
        } = this.state;

        console.log(this.state.saleLots, 'saleLots22');
        
        return (
            <Fragment>
                <NotificationSystem ref={this.notificationSystem} allowHTML={this.state.notification.allowHTML}/>

                <FormattedMessage id="dashboard.AffiliatedCompanyManagement"
                    defaultMessage="AffiliatedCompanyManagement">
                    {message => <Helmet>
                        <title>{message}</title>
                    </Helmet>}
                </FormattedMessage>

                <div className="main_top_bar static_bar">
                    <div className="box_sum_col_top">
                        <div className="box_left">
                            <ul className="nav nav_tabs">
                                <li className="nav_item">
                                    <span className="nav_link">
                                        <FormattedMessage
                                            id="dashboard.AffiliatedCompanyManagement"
                                            defaultMessage="AffiliatedCompanyManagement"
                                        />
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="main_content">
                    <div className="content_tabs_main content_static_tabs tab-content">
                        <div className="tabs_static_item tabs_item1">
                            <div className="box_content_static">
                                <div className="left_col left_col_customer_care">
                                    <DataSetting 
                                        location={this.props.location}
                                    />
                                </div>

                                <div className="content_col_nopading">
                                    <div className="list_control_box_preferences">
                                        <div className="content_left">
                                            <div className="content_column">
                                                <div className="column_box">
                                                    <div className="heading_box">
                                                        <h5 className="title_preferences">
                                                            <FormattedMessage
                                                                id="dashboard.AffiliatedCompany"
                                                                defaultMessage="AffiliatedCompany"
                                                            />
                                                        </h5>
                                                    </div>
                                                    <div className="control_box_item">
                                                        <div className="card_header">
                                                            <div className="box_title">
                                                                <FormattedMessage
                                                                    id="dashboard.Name"
                                                                    defaultMessage="Name"
                                                                />
                                                            </div>
                                                            <div className="box_add">
                                                                <button type="button" className="btn btn_add" onClick={this.toggleModalCreateSaleLot} 
                                                                    disabled={currentCompany.company_id ? false : true}>
                                                                    <i className="fas fa-plus" />
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="card_body">
                                                            <div className="custom_table">
                                                                <table className="table st_table_5">
                                                                    {/* render info saleLots list */}
                                                                    <tbody>
                                                                    {
                                                                       saleLots?.data?.map((item, index) => {
                                                                            let active = this.state.currentSaleLot?.lots_id == item.lots_id ? 'active' : '';
                                                                            return (
                                                                                <tr key={index} className={active} onClick={() => this.getInfoSaleLot(item)}>
                                                                                    <td>{item.lots_name}</td>
                                                                                    <td>{item.company_code}</td>
                                                                                    <td>{item.reject_number}</td>
                                                                                    <td>{item.send_number}</td>
                                                                                    <td className="inline_action">
                                                                                        <a onClick={() => this.editSaleLot(item)}> <i className="fas fa-edit"/> </a>
                                                                                        <a onClick={() => this.setDeletingRow(item.lots_id, 1)}> <i className="fas fa-trash-alt"/> </a>
                                                                                    </td>                                                                                        
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        {
                                                            saleLots?.data?.length > 0 && (
                                                                <div className="car_footer">
                                                                    <nav aria-label="...">
                                                                        <ul className="custom_pagination pagination">
                                                                            <Pagination
                                                                                itemClass="page-item"
                                                                                linkClass="page-link"
                                                                                activePage={saleLots?.current_page}
                                                                                itemsCountPerPage={saleLots?.per_page}
                                                                                totalItemsCount={saleLots?.total || 0}
                                                                                pageRangeDisplayed={10}
                                                                                onChange={this.changePageSaleLot}
                                                                            />
                                                                        </ul>
                                                                    </nav>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="content_right">
                                            <div className="heading_box">
                                                <h5 className="title_preferences">
                                                <FormattedMessage
                                                    id="dashboard.CompanyOverview"
                                                    defaultMessage="CompanyOverview"
                                                />
                                                </h5>
                                            </div>
                                            <article className="main_article_preferences">
                                                <div dangerouslySetInnerHTML={{ __html: this.state.currentSaleLot?.lots_desc }} />
                                            </article>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <CreateSaleLotCompanyModal
                    isOpen = {this.state.openModalCreateSaleLot}
                    toggle = {this.toggleModalCreateSaleLot}
                    currentCompany = {this.state.currentCompany}
                    saleLotForm = {this.state.saleLotForm}
                    valueDraft={this.state.valueDraft}
                    handleChange = {this.handleChangeInputSaleLot}
                    handleChangeEditor = {this.handleChangeEditor}
                    onCreateSaleLot = {this.onCreateSaleLot}
                />

                <UpdateSaleLotCompanyModal
                    isOpen = {this.state.openModalUpdateSaleLot}
                    toggle = {this.toggleModalUpdateSaleLot}
                    currentCompany = {this.state.currentCompany}
                    saleLotForm = {this.state.saleLotForm}
                    valueDraft={this.state.valueDraft}
                    handleChange = {this.handleChangeInputSaleLot}
                    handleChangeEditor = {this.handleChangeEditor}
                    onUpdateSaleLot = {this.onUpdateSaleLot}
                />

                <DeleteConfirmModal
                    isDeleteModalOpen={this.state.isDeleteModalOpen}
                    openDeleteModal={this.openDeleteModal}
                    isDeleteConfirmModalOpen={this.state.isDeleteConfirmModalOpen}
                    openDeleteConfirmModal={this.openDeleteConfirmModal}
                    deleteInfoHandler={this.deleteCompanyHandler}
                />

            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        companies: state.companies,
        company: state.company,
        auth: state.auth
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchCompanies: (params={type:'paginate'}) => dispatch(fetchCompaniesAction(params)),
        onCreateCompany: company => dispatch(createCompanyAction(company)),
        onUpdateCompany: (id, company) => dispatch(updateCompanyAction(id, company)),
        onDeleteCompany: id => dispatch(deleteCompanyAction(id)),
        
        onCreateSaleLot: saleLots => dispatch(createSaleLotsAction(saleLots)),
        onUpdateSaleLot: (id, saleLots) => dispatch(updateSaleLotsAction(id, saleLots)),
        onDeleteSaleLot: id => dispatch(deleteSaleLotsAction(id))

    }
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AffiliatedCompanyManagement));