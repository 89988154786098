import React, {Fragment} from 'react';
import {Modal, ModalHeader, ModalFooter, ModalBody, Button} from 'reactstrap';
import {FormattedMessage} from 'react-intl';

const NoticeModal = props => {

		let contents = props.body;
		console.log('contents:', contents);
		console.log('contents:', contents?.length);

    return (
        <Fragment>
            <Modal size="lg" style={{maxWidth: '700px', width: '100%',maxHeight: '700px'}} isOpen={props.isNoticeModalOpen} toggle={props.openNoticeModal}>
                <ModalHeader toggle={props.openNoticeModal}>
                    {props.title}
                </ModalHeader>
                <ModalBody style={{overflow:'scroll'}}>
									<div className="table st_table_5" style={{width:'100%'}}>
											{
												contents?.length > 0 ? 
													contents.map((item, index) => {
														let active = '';
														//let display = item.notice_contents ? item.notice_contents.replace(/\n/g, "<br />") : "";
														return (
															<Fragment>
																<div key={index}>
																		<div className="table st_table_5" style={{width: "100%", border: "1px solid #333", padding: "5px", backgroundColor: "#ccc"}}>공지일자 : {item.notice_at}</div>
																</div>
																<div style={{width: "100%", wordWrap: "break-word"}}>
																		<div style={{width: "100%", border: "1px solid #333", padding: "5px", backgroundColor: "#eee"}}>
																			{
																				item.notice_contents.split("\n").map((item, key) => {
																					return <Fragment key={key}>{item}<br/></Fragment>
																				})
																			}
																		</div>
																</div>
															</Fragment>
														)
													}) :
													(
																<div style={{width: "100%", border: "1px solid #333", padding: "5px", backgroundColor: "#eee"}}>
																		공지 사항 없음
																</div>
													)
											}
									</div>
                </ModalBody>
                <ModalFooter>
                    <Button color="warning"
                            onClick={props.openNoticeModal}>
                        <FormattedMessage
                            id="dashboard.Yes"
                            defaultMessage="Yes"/>
                    </Button>
										{/*<Button color="primary" onClick={props.openNoticeModal}>
                        <FormattedMessage
                            id="dashboard.Cancel"
                            defaultMessage="Cancel"/>
                    </Button>*/}
                </ModalFooter>
            </Modal>
        </Fragment>
    )
};
export default NoticeModal;