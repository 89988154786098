import {
    CREATE_COMPANY,
    CREATE_COMPANY_SUCCEED,
    CREATE_COMPANY_FAILED,
    UPDATE_COMPANY,
    UPDATE_COMPANY_SUCCEED,
    UPDATE_COMPANY_FAILED,
    DELETE_COMPANY,
    DELETE_COMPANY_SUCCEED,
    DELETE_COMPANY_FAILED
} from '../actions/actionTypes';


const initialState = {
    data: null,
    loading: false,
    error: false
};

const company = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_COMPANY: {
            return {
                ...state,
                loading: true
            }
        }
        case CREATE_COMPANY_SUCCEED: {
            return {
                ...state,
                loading: false,
                data: action.payload.company
            }
        }
        case CREATE_COMPANY_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        }
        case UPDATE_COMPANY: {
            return {
                ...state,
                loading: true
            }
        }
        case UPDATE_COMPANY_SUCCEED: {
            return {
                ...state,
                loading: false,
                data: action.payload.data
            }
        }
        case UPDATE_COMPANY_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        }
        case DELETE_COMPANY: {
            return {
                ...state,
                loading: true
            }
        }
        case DELETE_COMPANY_SUCCEED: {
            return {
                ...state,
                loading: false,
                data: action.payload.data
            }
        }
        case DELETE_COMPANY_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        }
        default:
            return state;
    }
};

export default company;