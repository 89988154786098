import {
    CREATE_FREE_ARTICLE,
    CREATE_FREE_ARTICLE_SUCCEED,
    CREATE_FREE_ARTICLE_FAILED,
    UPDATE_FREE_ARTICLE,
    UPDATE_FREE_ARTICLE_SUCCEED,
    UPDATE_FREE_ARTICLE_FAILED,
    REPLY_FREE_ARTICLE,
    REPLY_FREE_ARTICLE_SUCCEED,
    REPLY_FREE_ARTICLE_FAILED,
    DELETE_FREE_ARTICLE,
    DELETE_FREE_ARTICLE_SUCCEED,
    DELETE_FREE_ARTICLE_FAILED,
    CREATE_REQ_ARTICLE,
    CREATE_REQ_ARTICLE_SUCCEED,
    CREATE_REQ_ARTICLE_FAILED,
    UPDATE_REQ_ARTICLE,
    UPDATE_REQ_ARTICLE_SUCCEED,
    UPDATE_REQ_ARTICLE_FAILED,
    REPLY_REQ_ARTICLE,
    REPLY_REQ_ARTICLE_SUCCEED,
    REPLY_REQ_ARTICLE_FAILED,
    DELETE_REQ_ARTICLE,
    DELETE_REQ_ARTICLE_SUCCEED,
    DELETE_REQ_ARTICLE_FAILED,
} from '../actions/actionTypes';
import api from '../../utils/api';

export const createFreeArticle = () => {
    return {
        type: CREATE_FREE_ARTICLE
    }
};

export const createFreeArticleSucceed = articles => {
    return {
        type: CREATE_FREE_ARTICLE_SUCCEED,
        payload: {
            articles
        }
    }
};

export const createFreeArticleFailed = error => {
    return {
        type: CREATE_FREE_ARTICLE_FAILED,
        payload: {
            error: error
        }
    }
};

export const updateFreeArticle = () => {
    return {
        type: UPDATE_FREE_ARTICLE
    }
};

export const updateFreeArticleSucceed = articles => {
    return {
        type: UPDATE_FREE_ARTICLE_SUCCEED,
        payload: {
            articles
        }
    }
};

export const replyFreeArticleFailed = error => {
    return {
        type: REPLY_FREE_ARTICLE_FAILED,
        payload: {
            error: error
        }
    }
};

export const replyFreeArticle = () => {
    return {
        type: REPLY_FREE_ARTICLE
    }
};

export const replyFreeArticleSucceed = articles => {
    return {
        type: REPLY_FREE_ARTICLE_SUCCEED,
        payload: {
            articles
        }
    }
};

export const updateFreeArticleFailed = error => {
    return {
        type: UPDATE_FREE_ARTICLE_FAILED,
        payload: {
            error: error
        }
    }
};

export const deleteFreeArticle = () => {
    return {
        type: DELETE_FREE_ARTICLE
    }
};

export const deleteFreeArticleSucceed = articles => {
    return {
        type: DELETE_FREE_ARTICLE_SUCCEED,
        payload: {
            articles
        }
    }
};

export const deleteFreeArticleFailed = error => {
    return {
        type: DELETE_FREE_ARTICLE_FAILED,
        payload: {
            error: error
        }
    }
};


/**
 * Create articles
 */
export const createFreeArticleAction = (articles) => dispatch => {
    dispatch(createFreeArticle());
    return api.articles.createFreeArticle(articles)
        .then(response => {
            dispatch(createFreeArticleSucceed(response.data.data));
            return Promise.resolve(response.data);
        })
        .catch(error => {
            dispatch(createFreeArticleFailed(error.response.data.errors.message));
            return Promise.reject(error.response)
        });
};

/**
 * Reply articles
 */
export const replyFreeArticleAction = (id, articles) => dispatch => {
    dispatch(replyFreeArticle());
    return api.articles.replyFreeArticle(id, articles)
        .then(response => {
            dispatch(replyFreeArticleSucceed(response.data.data));
            return Promise.resolve(response.data);
        })
        .catch(error => {
            dispatch(replyFreeArticleFailed(error.response.data.errors.message));
            return Promise.reject(error.response)
        });
};

/**
 * Update articles
 */
export const updateFreeArticleAction = (id, articles) => dispatch => {
    dispatch(updateFreeArticle());
    return api.articles.updateFreeArticle(id, articles)
        .then(response => {
            dispatch(updateFreeArticleSucceed(response.data.data));
            return Promise.resolve(response.data);
        })
        .catch(error => {
            dispatch(updateFreeArticleFailed(error.response.data.errors.message));
            return Promise.reject(error.response)
        });
};

/**
 * Delete articles
 */
export const deleteFreeArticleAction = article_id => dispatch => {
    dispatch(deleteFreeArticle());
    const id = {
        params: {
            article_id: article_id
        }
    }
    return api.articles.deleteFreeArticle(id)
        .then(response => {
            dispatch(deleteFreeArticleSucceed(response.data.data));
            return Promise.resolve(response.data);
        })
        .catch(error => {
            dispatch(deleteFreeArticleFailed(error.response.data.errors.message));
            return Promise.reject(error.response)
        });
};


export const createReqArticle = () => {
    return {
        type: CREATE_REQ_ARTICLE
    }
};

export const createReqArticleSucceed = articles => {
    return {
        type: CREATE_REQ_ARTICLE_SUCCEED,
        payload: {
            articles
        }
    }
};

export const createReqArticleFailed = error => {
    return {
        type: CREATE_REQ_ARTICLE_FAILED,
        payload: {
            error: error
        }
    }
};

export const updateReqArticle = () => {
    return {
        type: UPDATE_REQ_ARTICLE
    }
};

export const updateReqArticleSucceed = articles => {
    return {
        type: UPDATE_REQ_ARTICLE_SUCCEED,
        payload: {
            articles
        }
    }
};

export const replyReqArticleFailed = error => {
    return {
        type: REPLY_REQ_ARTICLE_FAILED,
        payload: {
            error: error
        }
    }
};

export const replyReqArticle = () => {
    return {
        type: REPLY_REQ_ARTICLE
    }
};

export const replyReqArticleSucceed = articles => {
    return {
        type: REPLY_REQ_ARTICLE_SUCCEED,
        payload: {
            articles
        }
    }
};

export const updateReqArticleFailed = error => {
    return {
        type: UPDATE_REQ_ARTICLE_FAILED,
        payload: {
            error: error
        }
    }
};

export const deleteReqArticle = () => {
    return {
        type: DELETE_REQ_ARTICLE
    }
};

export const deleteReqArticleSucceed = articles => {
    return {
        type: DELETE_REQ_ARTICLE_SUCCEED,
        payload: {
            articles
        }
    }
};

export const deleteReqArticleFailed = error => {
    return {
        type: DELETE_REQ_ARTICLE_FAILED,
        payload: {
            error: error
        }
    }
};


/**
 * Create articles
 */
export const createReqArticleAction = (articles) => dispatch => {
    dispatch(createReqArticle());
    return api.articles.createReqArticle(articles)
        .then(response => {
            dispatch(createReqArticleSucceed(response.data.data));
            return Promise.resolve(response.data);
        })
        .catch(error => {
            dispatch(createReqArticleFailed(error.response.data.errors.message));
            return Promise.reject(error.response)
        });
};

/**
 * Reply articles
 */
export const replyReqArticleAction = (id, articles) => dispatch => {
    dispatch(replyReqArticle());
    return api.articles.replyReqArticle(id, articles)
        .then(response => {
            dispatch(replyReqArticleSucceed(response.data.data));
            return Promise.resolve(response.data);
        })
        .catch(error => {
            dispatch(replyReqArticleFailed(error.response.data.errors.message));
            return Promise.reject(error.response)
        });
};

/**
 * Update articles
 */
export const updateReqArticleAction = (id, articles) => dispatch => {
    dispatch(updateReqArticle());
    return api.articles.updateReqArticle(id, articles)
        .then(response => {
            dispatch(updateReqArticleSucceed(response.data.data));
            return Promise.resolve(response.data);
        })
        .catch(error => {
            dispatch(updateReqArticleFailed(error.response.data.errors.message));
            return Promise.reject(error.response)
        });
};

/**
 * Delete articles
 */
export const deleteReqArticleAction = article_id => dispatch => {
    dispatch(deleteReqArticle());
    const id = {
        params: {
            article_id: article_id
        }
    }
    return api.articles.deleteReqArticle(id)
        .then(response => {
            dispatch(deleteReqArticleSucceed(response.data.data));
            return Promise.resolve(response.data);
        })
        .catch(error => {
            dispatch(deleteReqArticleFailed(error.response.data.errors.message));
            return Promise.reject(error.response)
        });
};


