import React, {Fragment} from 'react'
import customer_list_img from '../../../src/assets/images/documentV2/customer_list_1.png';

const CustomerListDoc = props => {

    return (
        <Fragment>
            <div className="row m-0">
                <div className="col-md-12 documentation-user-registration">
                    <h2 className='text-align-center mt-3 mb-1'>고객 List</h2>
                    <p className='text-align-center'>상담 전화를 이용한 고객 리스트</p>
                    <div className='row mt-4'>
                        <div className='col-sm-8'>
                            <img className='pb-5' src={customer_list_img} alt='' width='100%'/>
                        </div>
                        <div className='col-md-4 pl-4 pr-0'>
                            <ul className='text-justify' style={{listStyle: 'none'}}>
                                <li>(1) 업체 리스트 표시</li>
                                <li>(2) 등록현장 표시</li>
                                <li>(3) 상담 고객 정보 리스트 표시</li>
                                <li>(4) 상담 일자를 입력하여 고객 리스트 조회 </li>
                                <li>(5) 업체, 등록현장 선택 후, 해당되는 고객을 검색함</li>
                                <li>(6) 선택한 고객 정보를 삭제</li>
                                <li>(7) 한 건 혹은 다건 선택 가능</li>
                                <li>(8) 엑셀 출력: 검색된 데이터를 엑셀파일로 출력함</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default CustomerListDoc;
