import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from "react-intl"
import { Button } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Spinner from '../../components/Spinner/Spinner';
import {
    deleteCustomerAction,
    exportCounsellingDataAction,
    fetchCounsellingDataAction,
    unmountCallListAction
} from '../../store/actions/calls';
import { fetchInfoMastsAction, unmountInfoMastAction } from "../../store/actions/infoMasts";
import moment from "moment/moment";
import "moment/locale/ko";
import queryString from 'query-string';
import DatePicker, { registerLocale } from 'react-datepicker';
import NotificationSystem from 'react-notification-system';
import Pagination from "react-js-pagination";
import api from "../../utils/api";
import * as helpers  from "../../utils/helpers";
import progressBar from '../../utils/progressBar';
import * as routerName from "../../utils/routerName";
// import "react-datepicker/dist/react-datepicker.css";
import DeleteConfirmModal from './../../components/DeleteConfirmModal/DeleteConfirmModal';
import exportToExcel from './../../components/Excel/ExportToExcel';
import _, { countBy } from 'lodash';
const callListTypes = [
    { value: 'received', text: {id: 'dashboard.CounsellingData.Received', defaultMessage: 'Received'} },
    { value: 'sending', text: {id: 'dashboard.CounsellingData.Sending', defaultMessage: 'Sending'} },
    { value: 'all', text: {id: 'dashboard.CounsellingData.All', defaultMessage: 'All'} },
    { value: 'all_with_unanswer', text: {id: 'dashboard.CounsellingData.AllWithUnAnswer', defaultMessage: 'AllWithUnAnswer'} },
    { value: 'all_in', text: {id: 'dashboard.CounsellingData.AllIn', defaultMessage: 'AllIn'} },
]

class CounsellingData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            counsellingList: {
                current_page: 1,
                total: 0,
            },
            notification: {
                allowHTML: false,
            },
            cond: {
                // startDate: moment('2020-10-23'),
                startDate: moment().subtract(2, 'months'),
                endDate: moment(), 
                type: 'paginate',
                search: '',
                item: [],
                columnOptions: [
                    'call_date_original',
                    'call_check',
                    'call_cust_name',
                    'call_tel',
                    'call_cust_hp',
                    'call_cust_tel',
                    'call_cust_address',
                    'dist_name',
                    'call_desc',
                    'usr_name',
                    'call_line_info',
                    'call_cust_info',
                    'call_agree',
                ],
                oldColumnsOptions: [
                    'call_date_original',
                    'call_check',
                    'call_cust_name',
                    'call_tel',
                    'call_cust_hp',
                    'call_cust_tel',
                    'call_cust_address',
                    'dist_name',
                    'call_desc',
                    'usr_name',
                    'call_line_info',
                    'call_cust_info',
                    'call_agree',
                ],
                callListType: 'all',
            },       

            // list option condition init
            optionList: [
                // { value: 'all', text: <FormattedMessage id="dashboard.All" defaultMessage="All" /> },
                { value: 'call_date_original', text: <FormattedMessage id="dashboard.Date" defaultMessage="Date" /> },
                { value: 'call_check', text: <FormattedMessage id="dashboard.Type" defaultMessage="Type" /> },
                { value: 'call_cust_name', text: <FormattedMessage id="dashboard.Customer_Name" defaultMessage="Name" /> },
                { value: 'call_tel', text: <FormattedMessage id="dashboard.ReceivedPhone" defaultMessage="Received Phone #" /> },
                { value: 'call_cust_hp', text: <FormattedMessage id="dashboard.MobilePhone" defaultMessage="Mobile phone #" /> },
                { value: 'call_cust_tel', text: <FormattedMessage id="dashboard.Telephone" defaultMessage="Telephone #" /> },
                { value: 'call_cust_address', text: <FormattedMessage id="dashboard.Address" defaultMessage="Address" /> },
                { value: 'dist_name', text: <FormattedMessage id="dashboard.Territory" defaultMessage="Territory" /> },
                { value: 'call_desc', text: <FormattedMessage id="dashboard.Comment" defaultMessage="Comment" /> },
                { value: 'usr_name', text: <FormattedMessage id="dashboard.Counselor" defaultMessage="Counselor" /> },
                { value: 'call_line_info', text: <FormattedMessage id="dashboard.LineInformation" defaultMessage="LineInformation" /> },
                { value: 'call_cust_info', text: <FormattedMessage id="dashboard.CustomerInformation" defaultMessage="Customer Info" /> },
                { value: 'call_agree', text: <FormattedMessage id="dashboard.ReceiveCallAgree" defaultMessage="Receive Call Agree" /> },
            ],
            oldOptionList: [
                { value: 'call_date_original', text: <FormattedMessage id="dashboard.Date" defaultMessage="Date" /> },
                { value: 'call_check', text: <FormattedMessage id="dashboard.Type" defaultMessage="Type" /> },
                { value: 'call_cust_name', text: <FormattedMessage id="dashboard.Customer_Name" defaultMessage="Name" /> },
                { value: 'call_tel', text: <FormattedMessage id="dashboard.ReceivedPhone" defaultMessage="Received Phone #" /> },
                { value: 'call_cust_hp', text: <FormattedMessage id="dashboard.MobilePhone" defaultMessage="Mobile phone #" /> },
                { value: 'call_cust_tel', text: <FormattedMessage id="dashboard.Telephone" defaultMessage="Telephone #" /> },
                { value: 'call_cust_address', text: <FormattedMessage id="dashboard.Address" defaultMessage="Address" /> },
                { value: 'dist_name', text: <FormattedMessage id="dashboard.Territory" defaultMessage="Territory" /> },
                { value: 'call_desc', text: <FormattedMessage id="dashboard.Comment" defaultMessage="Comment" /> },
                { value: 'usr_name', text: <FormattedMessage id="dashboard.Counselor" defaultMessage="Counselor" /> },
                { value: 'call_line_info', text: <FormattedMessage id="dashboard.LineInformation" defaultMessage="LineInformation" /> },
                { value: 'call_cust_info', text: <FormattedMessage id="dashboard.CustomerInformation" defaultMessage="Customer Info" /> },
                { value: 'call_agree', text: <FormattedMessage id="dashboard.ReceiveCallAgree" defaultMessage="Receive Call Agree" /> },
            ],
            infoMastOptions: [],
            checkAll: true,
            checkAllTable: false,

            // list fields table init
            columns: [
                { name: 'call_date_original', title: <FormattedMessage id="dashboard.Date" defaultMessage="Date" /> },
                { name: 'call_check', title: <FormattedMessage id="dashboard.Type" defaultMessage="Type" /> },
                { name: 'call_cust_name', title: <FormattedMessage id="dashboard.Customer_Name" defaultMessage="Name" /> },
                { name: 'call_tel', title: <FormattedMessage id="dashboard.ReceivedPhone" defaultMessage="Received Phone #" /> },
                { name: 'call_cust_hp', title: <FormattedMessage id="dashboard.MobilePhone" defaultMessage="Mobile phone #" /> },
                { name: 'call_cust_tel', title: <FormattedMessage id="dashboard.Telephone" defaultMessage="Telephone #" /> },
                { name: 'call_cust_address', title: <FormattedMessage id="dashboard.Address" defaultMessage="Address" /> },
                { name: 'dist_name', title: <FormattedMessage id="dashboard.Territory" defaultMessage="Territory" /> },
                { name: 'call_desc', title: <FormattedMessage id="dashboard.Comment" defaultMessage="Comment" /> },
                { name: 'usr_name', title: <FormattedMessage id="dashboard.Counselor" defaultMessage="Counselor" /> },
                { name: 'call_line_info', title: <FormattedMessage id="dashboard.LineInformation" defaultMessage="LineInformation" /> },
                { name: 'call_cust_info', title: <FormattedMessage id="dashboard.CustomerInformation" defaultMessage="Customer Info" /> },
                { name: 'call_agree', title: <FormattedMessage id="dashboard.ReceiveCallAgree" defaultMessage="Receive Call Agree" /> },
            ],
            oldColumns: [
                { name: 'call_date_original', title: <FormattedMessage id="dashboard.Date" defaultMessage="Date" /> },
                { name: 'call_check', title: <FormattedMessage id="dashboard.Type" defaultMessage="Type" /> },
                { name: 'call_cust_name', title: <FormattedMessage id="dashboard.Customer_Name" defaultMessage="Name" /> },
                { name: 'call_tel', title: <FormattedMessage id="dashboard.ReceivedPhone" defaultMessage="Received Phone #" /> },
                { name: 'call_cust_hp', title: <FormattedMessage id="dashboard.MobilePhone" defaultMessage="Mobile phone #" /> },
                { name: 'call_cust_tel', title: <FormattedMessage id="dashboard.Telephone" defaultMessage="Telephone #" /> },
                { name: 'call_cust_address', title: <FormattedMessage id="dashboard.Address" defaultMessage="Address" /> },
                { name: 'dist_name', title: <FormattedMessage id="dashboard.Territory" defaultMessage="Territory" /> },
                { name: 'call_desc', title: <FormattedMessage id="dashboard.Comment" defaultMessage="Comment" /> },
                { name: 'usr_name', title: <FormattedMessage id="dashboard.Counselor" defaultMessage="Counselor" /> },
                { name: 'call_line_info', title: <FormattedMessage id="dashboard.LineInformation" defaultMessage="LineInformation" /> },
                { name: 'call_cust_info', title: <FormattedMessage id="dashboard.CustomerInformation" defaultMessage="Customer Info" /> },
                { name: 'call_agree', title: <FormattedMessage id="dashboard.ReceiveCallAgree" defaultMessage="Receive Call Agree" /> },
            ],
            selection: [],
            isDeleteModalOpen: false,
            isDeleteConfirmModalOpen: false,
            loading: false,
            errors: {},
        }
    }

    notificationSystem = React.createRef();
    addNotification = (data) => {
        const notification = this.notificationSystem.current;
        notification.addNotification({
            message: data.message,
            title: data.title,
            level: data.level,
        });
    };

    componentDidMount() {
        this.getData(this.props.sceneCompanyId);
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.isChangeScene) {
            this.resetPaging();
            this.getData(nextProps.sceneCompanyId);
        }
    }

    resetPaging = () => {
        this.setState({
            counsellingList: {
                current_page: 1,
                total: 0,
            },
        })
    }

    getData = (companyId) => {
        //get more colum option from infoMast List
        this.props.onFetchInfoMast(companyId).then(response => {
            let moreColumnOptions = [];  //more conditions option
            let columns = [];   //more fields table

            response.map((item, key) => {
                columns.push(
                    {
                        name: item.m_name,
                        title: item.m_name,
                    }
                )
                moreColumnOptions.push(item.m_name);
            })

            this.setState({
                infoMastOptions: response,
                cond: {
                    ...this.state.cond,
                    columnOptions: [   // array name fields table
                        ...this.state.cond.oldColumnsOptions,
                        ...moreColumnOptions
                    ]
                },
                columns: [
                    ...this.state.oldColumns,
                    ...columns
                ]
            });
            this.getCounsellingDataFunc();
            progressBar.done();
        }).catch(error => {
            progressBar.done();
        });
    }

    fixedFormatter = value => {
        let arrReceived = [0];
        let arrSending  = [1];
        let arrUnAnswer = [2, 3, 4, 5];
        if (arrReceived.includes(parseInt(value))) {
            return (
                <span className="badge badge-success">
                     <FormattedMessage id="dashboard.Received" defaultMessage="Received"/>
                </span>
            )
        }
        if (arrSending.includes(parseInt(value))) {
            return (
                <span className="badge badge-secondary">
                     <FormattedMessage id="dashboard.Hanging" defaultMessage="Hanging"/>
                </span>
            )
        }
        if (arrUnAnswer.includes(parseInt(value))) {
            return (
                <span className="badge badge-danger">
                     <FormattedMessage id="dashboard.Unanswered" defaultMessage="Unanswered"/>
                </span>
            )
        }
    };

    fixedFormatter_2 = value => {
        let arrReceived = [0];
        let arrSending  = [1];
        let arrUnAnswer = [2, 3, 4, 5];
        if (arrReceived.includes(parseInt(value))) {
            return '수신콜'
        }
        if (arrSending.includes(parseInt(value))) {
            return '발신콜'
        }
        if (arrUnAnswer.includes(parseInt(value))) {
            return '부재콜'
        }
    };

    callAgreeFormatter = value => {
        if(value === '0') {
            return '동의함'
        }
        if(value === '1') {
            return '동의안함'
        }
    }

    changePage = (pageNumber) => {
        this.setState({
            ...this.state,
            counsellingList: {
                ...this.state.counsellingList,
                current_page: pageNumber
            }
        }, () => {
            this.getCounsellingDataFunc();
        })
    }

    selectRowTable = (item) => {
        // console.log('selectRowTable');
        let value = !(this[`checkbox_${item.call_id}`].checked);
        this[`checkbox_${item.call_id}`].checked = value;  

        let {selection} = this.state;
        if (value) {
            if ( !selection.includes(item.call_id) )
            selection.push(item.call_id);
        } else {
            selection = helpers.removeItemInArray(selection, item.call_id)
        }

         // check all = true if all row of current page checked
         let allCallIDCurrentPage = this.state.counsellingList?.data.map(item => {
            return item.call_id
        })        
        let selectionCurrentPage = selection.filter(call_id => {
            return allCallIDCurrentPage.includes(call_id);
        })        
        let checker = _.isEqual(allCallIDCurrentPage.sort(), selectionCurrentPage.sort());
        console.log(checker, 'checkerr');

        this.setState({
            ...this.state,
            selection,
            checkAllTable: checker
        }, () => {
            console.log(this.state.selection, 'selection_af_ref');
        })
    }

    handleCheckAllTable = (event) => {
        let valueCheck = event.target.checked;
        console.log(valueCheck, 'valueChek');
        // return;

        let { selection } = this.state;
        let { counsellingList } = this.state
        let allCallIDCurrentPage = counsellingList?.data.map(item => {
            return item.call_id
        })

        if (valueCheck) {
            //check and add all call_id of current page to selection[]
            allCallIDCurrentPage.map(call_id => {
                if ( !selection.includes(call_id) )
                selection.push(call_id);
            })
      
        } else {
            //remove all call_id of current page in selection[]
            allCallIDCurrentPage.map(call_id => {
               selection = helpers.removeItemInArray(selection, call_id)
            })
        }

        this.setState({
            ...this.state,
            selection,
            checkAllTable: valueCheck,
        }, () => {
            // console.log(this.state.selection, 'selection_af');
        })
    }

    handleClickItemCheckbox = (event, item) => {
        // event.stopPropagation();
        // event.nativeEvent.stopImmediatePropagation();
        // console.log('handleClickItemCheckbox');

        let {selection} = this.state;
        let value = event.target.checked;
        if (value) {
            if ( !selection.includes(item.call_id) )
            selection.push(item.call_id);
        } else {
            selection = helpers.removeItemInArray(selection, item.call_id)
        }

        this.setState({
            ...this.state,
            selection,
        }, () => {
            // console.log(this.state.selection, 'selection_af');
        })
    }

    handleClickOptions = (event) => {
        let { target } = event;
        let { columnOptions } = this.state.cond; // columns is display

        let optionList = this.state.optionList.map(option => option.value); // name option
        let infoMastOptions = this.state.infoMastOptions.map(item => item.m_name);// name info mast

        if (target.checked) {
            if (target.name === 'all') {
                columnOptions = [...optionList, ...infoMastOptions];
            } else {
                columnOptions.push(target.name);
            }
        } else {
            if (target.name === 'all') {
                columnOptions = [];
            } else {
                //find and remove item from columnOptions
                columnOptions = helpers.removeItemInArray(columnOptions, target.name);
            }
        }

        this.setState({
            ...this.state,
            cond: {
                ...this.state.cond,
                columnOptions: columnOptions,
            },
            checkAll: target.name === 'all' && target.checked ? true : false,
        }, () => {
        });
    }

    // 
    getCounsellingDataFunc = () => {
        progressBar.start();
        let scene = {};
        if(this.props.sceneCompanyId) {
            scene['company_id'] = this.props.sceneCompanyId
        }
        if(this.props.sceneLostId) {
            scene['lots_id'] = this.props.sceneLostId
        }
        let query = queryString.stringify({ ...{...this.state.cond, ...scene}, page: this.state.counsellingList?.current_page || 1 });
        api.customerManagement.getSearchCounsellingData(query).then(response => {
            // check all = true if all row checked
            let counsellingList = response.data.data
            let allCallIDCurrentPage = counsellingList?.data.map(item => {
                return item.call_id
            })
            let { selection } = this.state;
            let checker = helpers.checkEveryIncArray(allCallIDCurrentPage, selection) && selection?.length > 0;

            this.setState({
                ...this.state,
                counsellingList,
                checkAllTable: checker
            }, () => {
                // console.log(this.state.counsellingList, 'counsellingList');
            })
            progressBar.done();
        }).catch(error => {
            progressBar.done();
        });
    };


    changeStartDateHandler = (startDate) => { 
        this.changeHandler({ startDate });
    };

    changeEndDateHandler = (endDate) => {
        this.changeHandler({ endDate })
    };

    changeHandler = ({startDate, endDate}) => {
        startDate = startDate || this.state.cond.startDate;
        endDate = endDate || this.state.cond.endDate;

        if (startDate !== null && startDate.isAfter(endDate)) {
            endDate = startDate
        }
        this.setState({
            ...this.state,
            cond: {
                ...this.state.cond,
                startDate,
                endDate,
            }
        })
    };

    handleSearch = (event) => {
        let keyword = event.target.value;
        this.setState({
            ...this.state,
           cond: {
               ...this.state.cond,
               search: keyword,
           },
           counsellingList: {
                ...this.state.counsellingList,
                current_page: 1
            },
            checkAllTable: false,
            selection: [],
        }, () => {
            this.getCounsellingDataFunc();
        })
    }

    handleSubSearch = () => {
        this.setState({
            ...this.state,
           counsellingList: {
                ...this.state.counsellingList,
                current_page: 1
            },
            checkAllTable: false,
            selection: [],
        }, () => {
            this.getCounsellingDataFunc();
        })
    }


    selectCallListType = (event) => {
        let { value } = event.target;
        this.setState({
            ...this.state,
           cond: {
               ...this.state.cond,
               callListType: value,
           }
        }, () => {
        })
    }


    exportExcelHandler = (e) => {
        progressBar.start();
        let scene = {};
        if(this.props.sceneCompanyId) {
            scene['company_id'] = this.props.sceneCompanyId
        }
        if(this.props.sceneLostId) {
            scene['lots_id'] = this.props.sceneLostId
        }
        let data = {
            ...{...this.state.cond, ...scene},
            export_excel: true,
            type: 'list'
        }
        let query = queryString.stringify(data, { arrayFormat: 'bracket' });
        // let token = window.localStorage.getItem('token');
        // let url = process.env.NODE_ENV === 'development' ?
        //     process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD;
        // url = url + `/customer-manage/export-counselling-data?${query}&token=` + token;
        // return window.open(url, '_blank');

        // let infoMastOptionNames = this.state.infoMastOptions.map((item, index) => {
        //     return item.m_name
        // })
        // console.log(infoMastOptionNames, 'infoMastOptionNames');
        
        api.customerManagement.getExportCounsellingData(query).then(response => {
            let mainData =  response.data.data;
            let newData = mainData.map(item => {
                return {
                    ...item,
                    infoMast2: this.state.infoMastOptions
                }
             })
            //  console.log(newData, 'newData1');
            
            newData = newData.map(item => { 
                let call_detail_obj = {};
                (item.call_detail2 || []).map((e) => {
                    call_detail_obj[+e.call_m_id] = e
                });
                // console.log(call_detail_obj, 'call_detail_obj');

                return {
                    ...item,
                    infoMast2: item.infoMast2.map((e) => ({
                        ...e,
                        subObjInfoMast: call_detail_obj[+e.m_id] || null
                    }))
                }
            });
            // console.log(newData, 'newData2');

            let finalData = newData.map(item =>{
                let infoMastMatch = {};

                item.infoMast2.map(subInfoMast2 => {
                    infoMastMatch = {
                        ...infoMastMatch,
                        [subInfoMast2.m_name] : subInfoMast2.subObjInfoMast ? subInfoMast2.subObjInfoMast.call_dtl_name : ''
                    }
                });
                // console.log(infoMastMatch, 'infoMastMatch2');


                return {
                    'id ': item.call_id,
                    '일자 ': item.call_date_original,
                    '유형 ': this.fixedFormatter_2(item.call_check),
                    '이름 ': item.call_cust_name,
                    '상담전화 ': item.call_tel,
                    '휴대전화 ': item.call_cust_hp,
                    '일반전화 ': item.call_cust_tel,
                    '주소 ': item.call_cust_address,
                    '관리지역 ': item?.district?.dist_name || '',
                    '특이사항 ': item.call_desc,
                    '상담원 ': item?.user?.usr_name || '',
                    '회선 정보 ': item.call_line_info,
                    '고객정보 ': item.call_cust_info,
                    '수신동의 ': this.callAgreeFormatter(item.call_agree),
                    ...infoMastMatch
                }
            });
            exportToExcel(finalData, 'counselling')
            
            progressBar.done();
        }).catch(error => {
            progressBar.done();
        });
    };

    openDeleteModal = () => {
        this.setState({
            isDeleteModalOpen: !this.state.isDeleteModalOpen
        });
    };
    
    openDeleteConfirmModal = () => {
        this.setState({
            isDeleteConfirmModalOpen: !this.state.isDeleteConfirmModalOpen
        });
    };

    deleteCustomerHandler = (e) => {
        e.preventDefault();
        let cond = {
            call_id: this.state.selection
        }
        let query = queryString.stringify(cond, {arrayFormat: 'bracket'});
        progressBar.start();

        this.props.onDeleteCustomer(query).then(response => {
            // check and re-set current page if delete last item of last page
            let {counsellingList} = this.state; 
            let current_page = (counsellingList.from + (cond.call_id.length) - 1) == counsellingList.to
                            ? counsellingList.last_page - 1
                            : counsellingList.current_page;

            this.setState({
                ...this.state,
                selection: [],
                isDeleteConfirmModalOpen: false,
                notification: {
                    allowHTML: false,
                },
                counsellingList: {
                    ...this.state.counsellingList,
                    current_page,
                }
            }, () => {
                this.getCounsellingDataFunc();

                this.addNotification({
                    title: <FormattedMessage
                        id="dashboard.Succeed"
                        defaultMessage="Succeed!"/>,
                    message: <FormattedMessage
                        id="dashboard.Customer.DeleteSucceed"
                        defaultMessage="Delete customer successfully!"/>,
                    level: 'success',
                })
                progressBar.done();
            })
        }).catch(error => {
            progressBar.done();
        });
    }



    render() {
        const {
            counsellingList,
            optionList,
            infoMastOptions,
            columns,
            selection,
            checkAll,
            checkAllTable,
        } = this.state;
        const { columnOptions } = this.state.cond;
        console.log(columnOptions,  'columnOptions');

        return (
            <Fragment>
                <NotificationSystem ref={this.notificationSystem} allowHTML={this.state.notification.allowHTML}/>

                <FormattedMessage id="dashboard.CounsellingData"
                    defaultMessage="CounsellingData">
                    {message => <Helmet>
                        <title>{message}</title>
                    </Helmet>}
                </FormattedMessage>

                <div className="main_top_bar static_bar">
                    <div className="box_sum_col_top">
                        <div className="box_left">
                            <ul className="nav nav-tabs nav_tabs">
                                <li className="nav_item nav-item">
                                    <NavLink
                                        to={`/${routerName.DASHBOARD}/${routerName.CUSTOMER_MANAGEMENT}/${routerName.COUNSELLING_DATA}`}
                                        className="nav_link nav-link"
                                        activeClassName="active">
                                        <FormattedMessage
                                            id="dashboard.CounsellingData"
                                            defaultMessage="Counselling Data" />
                                    </NavLink>
                                </li>
                                <li className="nav_item nav-item">
                                    <NavLink
                                        to={`/${routerName.DASHBOARD}/${routerName.CUSTOMER_MANAGEMENT}/${routerName.CUSTOMER_DATA}`}
                                        className="nav_link nav-link"
                                        activeClassName="active">
                                        <FormattedMessage
                                            id="dashboard.CustomerData"
                                            defaultMessage="Customer data" />
                                    </NavLink>
                                </li>
                                <li className="nav_item nav-item">
                                    <NavLink
                                        to={`/${routerName.DASHBOARD}/${routerName.CUSTOMER_MANAGEMENT}/${routerName.IVR_DATA}`}
                                        className="nav_link nav-link"
                                        activeClassName="active">
                                        <FormattedMessage
                                            id="dashboard.IVRData"
                                            defaultMessage="IVR Data" />
                                    </NavLink>
                                </li>
                            </ul>
                        </div>

                        <div className="box_center">
                            <div className="tabs_static_item">
                                <div className="main_control_time_select">
                                    <div className="filler_to_from">
                                        <div className="i_tem txt_form">
                                            <FormattedMessage id="dashboard.Period" defaultMessage="Period" />
                                        </div>
                                        <div className="i_tem box_control_input">
                                            <div className="box_control_date">
                                                <span className="icon_date" />
                                                <div className="date_picker_custom">
                                                    <DatePicker
                                                        dateFormat="YYYY/MM/DD"
                                                        selected={this.state.cond.startDate}
                                                        selectsStart
                                                        // disabled={!this.state.cond.startDate}
                                                        id="FromDatePicker"
                                                        name="FromDatePicker"
                                                        className="from_date form_control form-control-sm unstyled"
                                                        startDate={this.state.cond.startDate}
                                                        endDate={this.state.cond.endDate}
                                                        onChange={this.changeStartDateHandler}
                                                        autoComplete='off'
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="i_tem txt_form hide">~</div>

                                        <div className="i_tem box_control_input">
                                            <div className="box_control_date">
                                                <span className="icon_date" />
                                                <div className="date_picker_custom">
                                                    <DatePicker
                                                        dateFormat="YYYY/MM/DD"
                                                        selected={this.state.cond.endDate}
                                                        selectsStart
                                                        disabled={!this.state.cond.endDate}
                                                        id="FromDatePicker"
                                                        name="FromDatePicker"
                                                        className="from_date form_control form-control-sm unstyled"
                                                        startDate={this.state.cond.startDate}
                                                        endDate={this.state.cond.endDate}
                                                        onChange={this.changeEndDateHandler}
                                                        autoComplete='off'
                                                    />
                                                </div>
                                            </div>
                                        </div>                                        
                                    </div>


                                    <div className="main_select main_box margin_left_50">
                                        <div className="main_label">
                                            <FormattedMessage id="dashboard.Type" defaultMessage="Type" />
                                        </div>
                                        <div className="custom_select">
                                            <select className="form_control"
                                                name="call_check"
                                                onChange={this.selectCallListType}
                                                value={this.state.cond.callListType}>
                                                {
                                                    callListTypes?.map((item, index) => {
                                                        return (
                                                            <option key={index} value={item.value}>
                                                                {this.props.intl.formatMessage(item.text)}
                                                            </option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            <span className="item_dropdown" />
                                        </div>
                                        <div className="control_btn">
                                            <button className="btn_control btn_002" 
                                                disabled={!this.state.cond.endDate}
                                                onClick={() => this.handleSubSearch()}
                                            >
                                                <FormattedMessage id="dashboard.Search" defaultMessage="Search" />
                                            </button>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* BOX MAIN CONTENT */}
                <div className="main_content">
                    <div className="content_tabs_main content_static_tabs tab-content">
                        <div className="tabs_static_item tabs_item1 tab-pane fade show  active " id="static1">
                            <div className="box_content_static">

                                {/* LEFT BOX OPTION COLUMN */}
                                <div className="left_col left_col_customer_care">
                                    <div className="group_checkbox checkall">
                                        <input
                                            name="all"     
                                            type="checkbox"
                                            onChange={this.handleClickOptions}
                                            checked={ checkAll }
                                            id="check0001"
                                        />
                                        <label className="control_label_checkbox" htmlFor="check0001">
                                            <FormattedMessage id="dashboard.CheckAll" defaultMessage="CheckAll" />
                                        </label>
                                    </div>
                                    {/* render MAIN option list */}
                                    <ul className="list_check">
                                        {
                                            optionList.map((option, index) => {
                                                let isChecked = columnOptions.includes(option.value) ? true : false;
                                                return (
                                                    <li key={index}>
                                                        <div className="group_checkbox">
                                                            <input
                                                                name={option.value}
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                onChange={this.handleClickOptions}
                                                                checked={isChecked}
                                                                id={option.value}
                                                            />
                                                            <label className="control_label_checkbox" htmlFor={option.value}>
                                                                {option.text}
                                                            </label>
                                                        </div>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>


                                    {/* render MORE option from InfoMast List */}
                                    <ul className="list_check">
                                        {
                                            infoMastOptions.map((option, index) => {
                                                let isChecked = columnOptions.includes(option.m_name) ? true : false;
                                                return (
                                                    <li key={index}>
                                                        <div className="group_checkbox">
                                                            <input
                                                                name={option.m_name}
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                onChange={this.handleClickOptions}
                                                                checked={isChecked}
                                                                id={option.m_name}
                                                            />
                                                            <label className="control_label_checkbox" htmlFor={option.m_name}>
                                                                {option.m_name}
                                                            </label>
                                                        </div>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                                {/* END LEFT BOX OPTION COLUMN */}


                                <div className="content_col">
                                    <div className="row_table_custom">
                                        <div className="content">
                                            <div className="card_heading">
                                                <ul className="list_custom_btn">
                                                    <li>
                                                        <Button
                                                            className="btn_control btn_001"
                                                            onClick={this.openDeleteModal}
                                                            disabled={selection.length == 0}
                                                        >
                                                            <FormattedMessage
                                                                id="dashboard.Delete"
                                                                defaultMessage="Delete"
                                                            />
                                                        </Button>
                                                    </li>
                                                    <li>
                                                        <Button
                                                            className="btn_control btn_002"
                                                            onClick={this.exportExcelHandler}
                                                            disabled={counsellingList?.data?.length > 0 ? false : true}
                                                        >
                                                            <FormattedMessage
                                                                id="dashboard.ExcelDownload"
                                                                defaultMessage="Excel download"
                                                            />
                                                        </Button>
                                                    </li>
                                                </ul>
                                            </div>

                                            {/* Main Content Table */}
                                            <div className="card_header">
                                                <div className="box_search">
                                                    <div className="box_control_input ">
                                                    <input type="text" className="control" onChange={this.handleSearch} value={this.state.cond.search}/>
                                                    <button className="btn_form btn_secondary"><i className="fas fa-search" /></button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="custom_table limit_row">
                                                <table className="table st_table_5">
                                                    <thead>
                                                        <tr>
                                                            {/* { counsellingList?.data?.length > 0  && ( <th/> )} */}
                                                            { counsellingList?.data?.length > 0 && columnOptions.length > 0 && ( 
                                                                <th className="align_center">
                                                                  <input
                                                                      name="check_all_table"     
                                                                      type="checkbox"
                                                                      checked={ checkAllTable }
                                                                      onChange={this.handleCheckAllTable}
                                                                      // onClick={e => e.stopPropagation()}
                                                                  />
                                                              </th>
                                                            )}
                                                            
                                                            {/* render Titles table  */}
                                                            {
                                                                columns.map((column, index) => {      
                                                                    // console.log(column, 'column');                                                             
                                                                    if (columnOptions.includes(column.name)) {
                                                                        // if (column.name == 'call_cust_address') {
                                                                        //     return (
                                                                        //         <th key={index} style={{width:'7%'}}>
                                                                        //             { column.title }
                                                                        //         </th>
                                                                        //     )
                                                                        // }   
                                                                        return (
                                                                            <th key={index} style={{width:''}}>
                                                                                {column.title}
                                                                            </th>
                                                                        );
                                                                    }
                                                                })
                                                            }   
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {/* render counselling list */}
                                                        {
                                                            counsellingList?.data?.length > 0 && columnOptions.length > 0 ?

                                                                counsellingList.data.map((itemCounSelling, index) => {
                                                                    // console.log(itemCounSelling, 'itemCounSelling');
                                                                    return (
                                                                        <tr key={index} onClick={() => this.selectRowTable(itemCounSelling)}>
                                                                            <td className="align_center" style={{width: '12px'}} >
                                                                                <input type="checkbox" 
                                                                                    checked={ selection.includes(itemCounSelling.call_id) ? true : false }
                                                                                    ref={(input) => this[`checkbox_${itemCounSelling.call_id}`] = input}
                                                                                    onChange={(e) => this.handleClickItemCheckbox(e, itemCounSelling)}
                                                                                    onClick={e => e.stopPropagation()}
                                                                                />
                                                                            </td>                                                                         
                                                                            
                                                                            {/* render value column matching one by one TITLE COLUMN */}
                                                                            {
                                                                                // columns: arr List columns with multi languages
                                                                                // columnOptions: arr List column name IS Checked
                                                                                columns.map((column, col_index) => { 
                                                                                    if (columnOptions.includes(column.name)) {
                                                                                        if (column.name == 'call_date_original') {
                                                                                            return (
                                                                                                <td key={col_index} className="while_space">
                                                                                                    { itemCounSelling.call_date_original }
                                                                                                </td>
                                                                                            )
                                                                                        }                                                                                        
                                                                                        if (column.name == 'call_check') {
                                                                                            return (
                                                                                                <td key={col_index}>
                                                                                                    { this.fixedFormatter(itemCounSelling.call_check) }
                                                                                                </td>
                                                                                            )
                                                                                        }                                                                                        
                                                                                        if (column.name == 'usr_name') {
                                                                                            return (
                                                                                                <td key={col_index}>
                                                                                                    { itemCounSelling?.user?.usr_name }
                                                                                                </td>
                                                                                            )
                                                                                        }
                                                                                        if (column.name == 'dist_name') {
                                                                                            return (
                                                                                                <td key={col_index}>
                                                                                                    {itemCounSelling?.district?.dist_name}
                                                                                                </td>
                                                                                            )
                                                                                        }
                                                                                        if (column.name == 'call_line_info') {
                                                                                            return (
                                                                                                <td key={col_index}>
                                                                                                     {itemCounSelling?.info_line?.line_info}
                                                                                                    {/* { itemCounSelling?.call_line_info } */}
                                                                                                </td>
                                                                                            )
                                                                                        }

                                                                                        if (column.name == 'call_cust_info') {
                                                                                            return (
                                                                                                <td key={col_index}>
                                                                                                    {itemCounSelling.call_cust_info}
                                                                                                </td>
                                                                                            )
                                                                                        }

                                                                                        if (column.name == 'call_agree') {
                                                                                            return (
                                                                                                <td key={col_index}>
                                                                                                    {
                                                                                                        itemCounSelling.call_agree == '0'
                                                                                                        ? <FormattedMessage id="dashboard.Agree" defaultMessage="Agree"/>
                                                                                                        : <FormattedMessage id="dashboard.Disagree" defaultMessage="Disagree"/>
                                                                                                    }
                                                                                                </td>
                                                                                            )
                                                                                        }

                                                                                        //check and get Value CALL_DETAIL matching with COLUMN
                                                                                        let infoMast = helpers.findObjectInArray(infoMastOptions, column.name, 'm_name');
                                                                                        if (infoMast) {
                                                                                            let matchCallDetail = helpers.findObjectInArray(itemCounSelling.call_detail2, infoMast.m_id, 'call_m_id');
                                                                                            if (matchCallDetail) {
                                                                                                return (
                                                                                                    <td key={col_index}>
                                                                                                        {matchCallDetail.call_dtl_name}
                                                                                                    </td>
                                                                                                )
                                                                                            } else {
                                                                                                return (
                                                                                                    <td key={col_index}></td>
                                                                                                )
                                                                                            }
                                                                                        }

                                                                                        return (
                                                                                            <td key={col_index}>
                                                                                                {/* <FormattedMessage id="dashboard.Date" defaultMessage="Date"/> */}
                                                                                                { helpers.limitString(itemCounSelling[`${column.name}`], 20) }
                                                                                            </td>
                                                                                        );
                                                                                    }
                                                                                })
                                                                            } 
                                                                        </tr>
                                                                    )
                                                                }) :
                                                                (
                                                                    <tr>
                                                                        <td colSpan={columnOptions.length + 1}>
                                                                            <div className="nodata">No Data</div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                        }

                                                    </tbody>
                                                </table>
                                            </div>



                                            <div className="car_footer btw">
                                                <div className="txt_sum_col">
                                                    <strong> <FormattedMessage id="dashboard.TotalCount" defaultMessage="TotalCount" /> </strong> : {counsellingList?.total || 0}
                                                </div>
                                                {
                                                    counsellingList?.data?.length > 0 && (
                                                        <nav aria-label="...">
                                                            <ul className="custom_pagination pagination">
                                                                <Pagination
                                                                    itemClass="page-item"
                                                                    linkClass="page-link"
                                                                    activePage={counsellingList?.current_page}
                                                                    itemsCountPerPage={counsellingList?.per_page}
                                                                    totalItemsCount={counsellingList?.total || 0}
                                                                    pageRangeDisplayed={10}
                                                                    onChange={this.changePage}
                                                                />
                                                            </ul>
                                                        </nav>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* END BOX MAIN CONTENT */}


                <DeleteConfirmModal
                    isDeleteModalOpen={this.state.isDeleteModalOpen}
                    openDeleteModal={this.openDeleteModal}
                    isDeleteConfirmModalOpen={this.state.isDeleteConfirmModalOpen}
                    openDeleteConfirmModal={this.openDeleteConfirmModal}
                    deleteInfoHandler={this.deleteCustomerHandler}
                />
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        counsellingList: state.calls,
        isChangeScene: state.sceneSelect.isChangeScene,
        sceneCompanyId: state.sceneSelect.company_id,
        sceneLostId: state.sceneSelect.lots_id,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        // onFetchCallList: (query) => dispatch(fetchCounsellingDataAction(query)),
        onFetchInfoMast: (companyId) => dispatch(fetchInfoMastsAction(companyId)),
        onUnmountCallList: () => dispatch(unmountCallListAction()),
        onUnmountInfoMast: () => dispatch(unmountInfoMastAction()),

        onCounsellingDataExportExcel: (query) => dispatch(exportCounsellingDataAction(query)),
        onDeleteCustomer: (query) => dispatch(deleteCustomerAction(query)),
    }
};


export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CounsellingData));
