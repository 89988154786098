import React, {Fragment} from 'react';
import {Modal, ModalHeader, ModalFooter, ModalBody, Button} from 'reactstrap';
import {FormattedMessage} from "react-intl";

const DeleteConfirmModal = props => {

    return (
        <Fragment>
            <Modal isOpen={props.isDeleteModalOpen} toggle={props.openDeleteModal}>
                <ModalHeader toggle={props.openDeleteModal}>
                    <FormattedMessage
                        id="dashboard.Warning"
                        defaultMessage="Warning"/>
                </ModalHeader>
                <ModalBody>
                    <FormattedMessage
                        id="dashboard.ConfirmWarning"
                        defaultMessage="Selected data is going to be deleted."/>
                </ModalBody>
                <ModalFooter>
                    <Button color="warning" onClick={() => {
                        props.openDeleteModal()
                        props.openDeleteConfirmModal();
                    }}>
                        <FormattedMessage
                            id="dashboard.Yes"
                            defaultMessage="Yes"/>
                    </Button>
                    <Button color="primary" onClick={props.openDeleteModal}>
                        <FormattedMessage
                            id="dashboard.Cancel"
                            defaultMessage="Cancel"/>
                    </Button>
                </ModalFooter>
            </Modal>

            
            <Modal isOpen={props.isDeleteConfirmModalOpen} toggle={props.openDeleteConfirmModal}>
                <ModalHeader toggle={props.openDeleteConfirmModal}>
                    <FormattedMessage
                        id="dashboard.Danger"
                        defaultMessage="Danger"/>
                </ModalHeader>
                <ModalBody>
                    <FormattedMessage
                        id="dashboard.ConfirmDanger"
                        defaultMessage="All data will be deleted. Do you really want to delete it?"/>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger"
                            onClick={props.deleteInfoHandler}>
                        <FormattedMessage
                            id="dashboard.Delete"
                            defaultMessage="Delete"/>
                    </Button>
                    <Button color="primary"
                            onClick={props.openDeleteConfirmModal}>
                        <FormattedMessage
                            id="dashboard.Cancel"
                            defaultMessage="Cancel"/>
                    </Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    )
};


export default DeleteConfirmModal;