import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from "react-intl"
import { Button } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Spinner from '../../components/Spinner/Spinner';
import {
    exportIVRDataAction,
} from '../../store/actions/calls';

import moment from "moment/moment";
import "moment/locale/ko";
import queryString from 'query-string';
import DatePicker, { registerLocale } from 'react-datepicker';
import NotificationSystem from 'react-notification-system';
import Pagination from "react-js-pagination";
import api from "../../utils/api";
import * as helpers  from "../../utils/helpers";
import progressBar from '../../utils/progressBar';
import * as routerName from "../../utils/routerName";
// import "react-datepicker/dist/react-datepicker.css";
import DeleteConfirmModal from './../../components/DeleteConfirmModal/DeleteConfirmModal';
import exportToExcel from './../../components/Excel/ExportToExcel';
import _, { countBy } from 'lodash';

class IVRData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            IVRDataList: {
                current_page: 1,
                total: 0,
            },
            notification: {
                allowHTML: false,
            },
            cond: {
                // startDate: moment('2020-10-23'),
                startDate: moment().subtract(2, 'months'),
                endDate: moment(), 
                type: 'paginate',
                search: '',
                item: [],
                columnOptions: [
                    'ivr_dn',
                    'CallingNum',
                    'EventString',
                    'EventTime',
                ],
                callListType: 'all',
            },       

            // list option condition init
            optionList: [
                { value: 'ivr_dn', text: "IVR 내선번호" },
                { value: 'CallingNum', text: "발신자 번호" },
                { value: 'EventString', text: "이벤트 코드" },
                { value: 'EventTime', text: "이벤트 발생시각" },
            ],

            columns: [
                { name: 'ivr_dn', title: "IVR 내선번호" },
                { name: 'CallingNum', title: "발신자 번호" },
                { name: 'EventString', title: "이벤트 코드" },
                { name: 'EventTime', title: "이벤트 발생시각" },
            ],
            selection: [],
            isDeleteModalOpen: false,
            isDeleteConfirmModalOpen: false,
            loading: false,
            errors: {},
        }
    }

    notificationSystem = React.createRef();
    addNotification = (data) => {
        const notification = this.notificationSystem.current;
        notification.addNotification({
            message: data.message,
            title: data.title,
            level: data.level,
        });
    };

    componentDidMount() {
        this.getData(this.props.sceneCompanyId);
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.isChangeScene) {
            this.resetPaging();
            this.getData(nextProps.sceneCompanyId);
        }
    }

    resetPaging = () => {
        this.setState({
            IVRDataList: {
                current_page: 1,
                total: 0,
            },
        })
    }

    getData = (companyId) => {
        this.getIVRDataFunc();
    }

    changePage = (pageNumber) => {
        this.setState({
            ...this.state,
            IVRDataList: {
                ...this.state.IVRDataList,
                current_page: pageNumber
            }
        }, () => {
            this.getIVRDataFunc();
        })
    }

    getIVRDataFunc = () => {
        progressBar.start();
        let scene = {};
        if(this.props.sceneCompanyId) {
            scene['company_id'] = this.props.sceneCompanyId
        }
        if(this.props.sceneLostId) {
            scene['lots_id'] = this.props.sceneLostId
        }
        let query = queryString.stringify({ ...{...this.state.cond, ...scene}, page: this.state.IVRDataList?.current_page || 1 });
        api.customerManagement.getSearchIVRData(query).then(response => {
            // check all = true if all row checked
            let IVRDataList = response.data.data

            this.setState({
                ...this.state,
                IVRDataList,
            }, () => {
                // console.log(this.state.IVRDataList, 'IVRDataList');
            })
            progressBar.done();
        }).catch(error => {
            progressBar.done();
        });
    };


    changeStartDateHandler = (startDate) => { 
        this.changeHandler({ startDate });
    };

    changeEndDateHandler = (endDate) => {
        this.changeHandler({ endDate })
    };

    changeHandler = ({startDate, endDate}) => {
        startDate = startDate || this.state.cond.startDate;
        endDate = endDate || this.state.cond.endDate;

        if (startDate !== null && startDate.isAfter(endDate)) {
            endDate = startDate
        }
        this.setState({
            ...this.state,
            cond: {
                ...this.state.cond,
                startDate,
                endDate,
            }
        })
    };

    handleSubSearch = () => {
        this.setState({
            ...this.state,
						IVRDataList: {
                ...this.state.IVRDataList,
                current_page: 1
            },
        }, () => {
            this.getIVRDataFunc();
        })
    }

    handleSearch = (event) => {
        let keyword = event.target.value;
        this.setState({
            ...this.state,
           cond: {
               ...this.state.cond,
               search: keyword,
           },
           IVRDataList: {
                ...this.state.IVRDataList,
                current_page: 1
            },
        }, () => {
            this.getIVRDataFunc();
        })
		}

    exportExcelHandler = (e) => {
        progressBar.start();
        let scene = {};
        if(this.props.sceneCompanyId) {
            scene['company_id'] = this.props.sceneCompanyId
        }
        if(this.props.sceneLostId) {
            scene['lots_id'] = this.props.sceneLostId
        }
        let data = {
            ...{...this.state.cond, ...scene},
            export_excel: true,
            type: 'list'
        }
        let query = queryString.stringify(data, { arrayFormat: 'bracket' });
        // let token = window.localStorage.getItem('token');
        // let url = process.env.NODE_ENV === 'development' ?
        //     process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD;
        // url = url + `/customer-manage/export-counselling-data?${query}&token=` + token;
        // return window.open(url, '_blank');

        // let infoMastOptionNames = this.state.infoMastOptions.map((item, index) => {
        //     return item.m_name
        // })
        // console.log(infoMastOptionNames, 'infoMastOptionNames');
        
        api.customerManagement.getExportIVRData(query).then(response => {
            let mainData =  response.data.data.data;

						console.log('mainData:',mainData);

            let finalData = mainData.map(item =>{
                return {
                    'id ': item.Idx,
                    'IVR 내선번호 ': item.ivr_dn,
                    '발신자 번호 ': item.CallingNum,
                    '이벤트 코드 ': item.EventString,
                    '이벤트 발생시각 ': item.EventTime,
                }
            });
						console.log('finalData:',finalData);
            exportToExcel(finalData, 'IVRData')
            
            progressBar.done();
        }).catch(error => {
            progressBar.done();
        });
    };

    openDeleteModal = () => {
        this.setState({
            isDeleteModalOpen: !this.state.isDeleteModalOpen
        });
    };
    
    openDeleteConfirmModal = () => {
        this.setState({
            isDeleteConfirmModalOpen: !this.state.isDeleteConfirmModalOpen
        });
    };

    render() {
        const {
            IVRDataList,
            columns,
            selection,
        } = this.state;

        const { columnOptions } = this.state.cond;

        return (
            <Fragment>
                <NotificationSystem ref={this.notificationSystem} allowHTML={this.state.notification.allowHTML}/>

                <FormattedMessage id="dashboard.CounsellingData"
                    defaultMessage="CounsellingData">
                    {message => <Helmet>
                        <title>{message}</title>
                    </Helmet>}
                </FormattedMessage>
                

                <div className="main_top_bar static_bar">
                    <div className="box_sum_col_top">
                        <div className="box_left">
                            <ul className="nav nav-tabs nav_tabs">
                                <li className="nav_item nav-item">
                                    <NavLink
                                        to={`/${routerName.DASHBOARD}/${routerName.CUSTOMER_MANAGEMENT}/${routerName.COUNSELLING_DATA}`}
                                        className="nav_link nav-link"
                                        activeClassName="active">
                                        <FormattedMessage
                                            id="dashboard.CounsellingData"
                                            defaultMessage="Counselling Data" />
                                    </NavLink>
                                </li>
                                <li className="nav_item nav-item">
                                    <NavLink
                                        to={`/${routerName.DASHBOARD}/${routerName.CUSTOMER_MANAGEMENT}/${routerName.CUSTOMER_DATA}`}
                                        className="nav_link nav-link"
                                        activeClassName="active">
                                        <FormattedMessage
                                            id="dashboard.CustomerData"
                                            defaultMessage="Customer data" />
                                    </NavLink>
                                </li>
                                <li className="nav_item nav-item">
                                    <NavLink
                                        to={`/${routerName.DASHBOARD}/${routerName.CUSTOMER_MANAGEMENT}/${routerName.IVR_DATA}`}
                                        className="nav_link nav-link"
                                        activeClassName="active">
                                        <FormattedMessage
                                            id="dashboard.IVRData"
                                            defaultMessage="IVR Data" />
                                    </NavLink>
                                </li>
                            </ul>
                        </div>

                        <div className="box_center">
                            <div className="tabs_static_item">
                                <div className="main_control_time_select">
                                    <div className="filler_to_from">
                                        <div className="i_tem txt_form">
                                            <FormattedMessage id="dashboard.Period" defaultMessage="Period" />
                                        </div>
                                        <div className="i_tem box_control_input">
                                            <div className="box_control_date">
                                                <span className="icon_date" />
                                                <div className="date_picker_custom">
                                                    <DatePicker
                                                        dateFormat="YYYY/MM/DD"
                                                        selected={this.state.cond.startDate}
                                                        selectsStart
                                                        // disabled={!this.state.cond.startDate}
                                                        id="FromDatePicker"
                                                        name="FromDatePicker"
                                                        className="from_date form_control form-control-sm unstyled"
                                                        startDate={this.state.cond.startDate}
                                                        endDate={this.state.cond.endDate}
                                                        onChange={this.changeStartDateHandler}
                                                        autoComplete='off'
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="i_tem txt_form hide">~</div>

                                        <div className="i_tem box_control_input">
                                            <div className="box_control_date">
                                                <span className="icon_date" />
                                                <div className="date_picker_custom">
                                                    <DatePicker
                                                        dateFormat="YYYY/MM/DD"
                                                        selected={this.state.cond.endDate}
                                                        selectsStart
                                                        disabled={!this.state.cond.endDate}
                                                        id="FromDatePicker"
                                                        name="FromDatePicker"
                                                        className="from_date form_control form-control-sm unstyled"
                                                        startDate={this.state.cond.startDate}
                                                        endDate={this.state.cond.endDate}
                                                        onChange={this.changeEndDateHandler}
                                                        autoComplete='off'
                                                    />
                                                </div>
                                            </div>
                                        </div>                                        
                                    </div>


                                    <div className="main_select main_box margin_left_50">
                                        <div className="main_label">
                                            검색
                                        </div>
                                        <div className="custom_select">
																						<input type="text"
																								onChange={this.handleSearch} value={this.state.cond.search}
																								className="input_group form_control"/>
                                        </div>
                                        <div className="control_btn">
                                            <button className="btn_control btn_002" 
                                                disabled={!this.state.cond.endDate}
                                                onClick={() => this.handleSubSearch()}
                                            >
                                                <FormattedMessage id="dashboard.Search" defaultMessage="Search" />
                                            </button>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                {/* BOX MAIN CONTENT */}
                <div className="main_content">
                    <div className="content_tabs_main content_static_tabs tab-content">
                        <div className="tabs_static_item tabs_item1 tab-pane fade show  active " id="static1">
                            <div className="box_content_static">

                                {/* BOX CONDITION SEARCH */}
                                <div className="left_col">
                                    <div className="heading title_left">
                                        <h3>
                                            <FormattedMessage id="dashboard.SearchFilters" defaultMessage="Search Filters"/>
                                        </h3>
                                    </div>
                                    <div className="box_count">
                                        <div className="heading">
                                            발신자 번호
                                        </div>
                                        <div className="content">
                                            <div className="custom_group_colum">
                                                <div className="group_item">
                                                    <input type="text"
                                                        onChange={this.handleSearch} value={this.state.cond.search}
                                                        className="input_group form_control"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>{/* box_count */}

                                </div>
                                {/* END LEFT BOX OPTION COLUMN */}

                                <div className="content_col">
                                    <div className="row_table_custom">
                                        <div className="content">
                                            <div className="card_heading">
                                                <ul className="list_custom_btn">
																									{/*<li>
                                                        <Button
                                                            className="btn_control btn_001"
                                                            onClick={this.openDeleteModal}
                                                            disabled={selection.length == 0}
                                                        >
                                                            <FormattedMessage
                                                                id="dashboard.Delete"
                                                                defaultMessage="Delete"
                                                            />
                                                        </Button>
                                                    </li>*/}
                                                    <li>
                                                        <Button
                                                            className="btn_control btn_002"
                                                            onClick={this.exportExcelHandler}
                                                            disabled={IVRDataList?.data?.length > 0 ? false : true}
                                                        >
                                                            <FormattedMessage
                                                                id="dashboard.ExcelDownload"
                                                                defaultMessage="Excel download"
                                                            />
                                                        </Button>
                                                    </li>
                                                </ul>
                                            </div>

                                            {/* Main Content Table */}
                                            <div className="card_header">
                                                <div className="box_search">
                                                    <div className="box_control_input ">
                                                    <input type="text" className="control" onChange={this.handleSearch} value={this.state.cond.search}/>
                                                    <button className="btn_form btn_secondary"><i className="fas fa-search" /></button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="custom_table limit_row">
                                                <table className="table st_table_5">
                                                    <thead>
                                                        <tr>
                                                            {
                                                                columns.map((column, index) => {      
                                                                    if (columnOptions.includes(column.name)) {
                                                                        return (
                                                                            <th key={index} style={{width:''}}>
                                                                                {column.title}
                                                                            </th>
                                                                        );
                                                                    }
                                                                })
                                                            }   
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {/* render counselling list */}
                                                        {
                                                            IVRDataList?.data?.length > 0 && columnOptions.length > 0 ?

                                                                IVRDataList.data.map((itemCounSelling, index) => {
                                                                    // console.log(itemCounSelling, 'itemCounSelling');
                                                                    return (
                                                                        <tr key={index}>                                                                       
                                                                            
                                                                            {/* render value column matching one by one TITLE COLUMN */}
                                                                            {
                                                                                // columns: arr List columns with multi languages
                                                                                // columnOptions: arr List column name IS Checked
                                                                                columns.map((column, col_index) => { 
                                                                                    if (columnOptions.includes(column.name)) {
                                                                                        if (column.name == 'ivr_dn') {
                                                                                            return (
                                                                                                <td key={col_index} className="while_space">
                                                                                                    { itemCounSelling.ivr_dn }
                                                                                                </td>
                                                                                            )
                                                                                        }                                                                                        
                                                                                        if (column.name == 'CallingNum') {
                                                                                            return (
                                                                                                <td key={col_index} className="while_space">
                                                                                                    { itemCounSelling.CallingNum }
                                                                                                </td>
                                                                                            )
                                                                                        }                                                                                        
                                                                                        if (column.name == 'EventString') {
                                                                                            return (
                                                                                                <td key={col_index} className="while_space">
                                                                                                    { itemCounSelling.EventString }
                                                                                                </td>
                                                                                            )
                                                                                        }                                                                                        
                                                                                        if (column.name == 'EventTime') {
                                                                                            return (
                                                                                                <td key={col_index} className="while_space">
                                                                                                    { itemCounSelling.EventTime }
                                                                                                </td>
                                                                                            )
                                                                                        }                                                                                        
                                                                                    }
                                                                                })
                                                                            } 
                                                                        </tr>
                                                                    )
                                                                }) :
                                                                (
                                                                    <tr>
                                                                        <td colSpan={columnOptions.length + 1}>
                                                                            <div className="nodata">No Data</div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                        }

                                                    </tbody>
                                                </table>
                                            </div>



                                            <div className="car_footer btw">
                                                <div className="txt_sum_col">
                                                    <strong> <FormattedMessage id="dashboard.TotalCount" defaultMessage="TotalCount" /> </strong> : {IVRDataList?.total || 0}
                                                </div>
                                                {
                                                    IVRDataList?.data?.length > 0 && (
                                                        <nav aria-label="...">
                                                            <ul className="custom_pagination pagination">
                                                                <Pagination
                                                                    itemClass="page-item"
                                                                    linkClass="page-link"
                                                                    activePage={IVRDataList?.current_page}
                                                                    itemsCountPerPage={IVRDataList?.per_page}
                                                                    totalItemsCount={IVRDataList?.total || 0}
                                                                    pageRangeDisplayed={10}
                                                                    onChange={this.changePage}
                                                                />
                                                            </ul>
                                                        </nav>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* END BOX MAIN CONTENT */}


                <DeleteConfirmModal
                    isDeleteModalOpen={this.state.isDeleteModalOpen}
                    openDeleteModal={this.openDeleteModal}
                    isDeleteConfirmModalOpen={this.state.isDeleteConfirmModalOpen}
                    openDeleteConfirmModal={this.openDeleteConfirmModal}
                    deleteInfoHandler={this.deleteCustomerHandler}
                />
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        IVRDataList: state.calls,
        isChangeScene: state.sceneSelect.isChangeScene,
        sceneCompanyId: state.sceneSelect.company_id,
        sceneLostId: state.sceneSelect.lots_id,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onIVRDataExportExcel: (query) => dispatch(exportIVRDataAction(query)),
    }
};


export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(IVRData));
