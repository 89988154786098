import axios from 'axios';
import setAuthorizationHeader from '../utils/setAuthorizationHeader';

const instance = new axios.create({
    baseURL: (process.env.NODE_ENV === 'development') ?
        process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD
});

instance.interceptors.response.use(function (response) {
    const newToken = response.headers.authorization;
    if (newToken) {
        localStorage.setItem('token', newToken);
        setAuthorizationHeader(newToken);
    };
    return response;
}, function (error) {
    // Do something with response error
    if (error.response.status === 401) {
        localStorage.removeItem('token');
        setAuthorizationHeader();
        window.location.replace('/');
        //store.dispatch(authLogoutAction());
    }
    return Promise.reject(error);
});

export default instance;