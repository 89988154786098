import {
    FETCH_COMPANIES,
    FETCH_COMPANIES_SUCCEED,
    FETCH_COMPANIES_FAILED,
    CREATE_COMPANY,
    CREATE_COMPANY_SUCCEED,
    CREATE_COMPANY_FAILED,
    UPDATE_COMPANY,
    UPDATE_COMPANY_SUCCEED,
    UPDATE_COMPANY_FAILED,
    DELETE_COMPANY,
    DELETE_COMPANY_SUCCEED,
    DELETE_COMPANY_FAILED
} from '../actions/actionTypes';
import api from '../../utils/api';

export const fetchCompanies = () => {
    return {
        type: FETCH_COMPANIES
    }
};

export const fetchCompaniesSucceed = data => {
    return {
        type: FETCH_COMPANIES_SUCCEED,
        payload: {
            data: data
        }
    }
};

export const fetchCompaniesFailed = error => {
    return {
        type: FETCH_COMPANIES_FAILED,
        payload: {
            error: error
        }
    }
};

export const createCompany = () => {
    return {
        type: CREATE_COMPANY
    }
};

export const createCompanySucceed = company => {
    return {
        type: CREATE_COMPANY_SUCCEED,
        payload: {
            company
        }
    }
};

export const createCompanyFailed = error => {
    return {
        type: CREATE_COMPANY_FAILED,
        payload: {
            error: error
        }
    }
};

export const updateCompany = () => {
    return {
        type: UPDATE_COMPANY
    }
};

export const updateCompanySucceed = company => {
    return {
        type: UPDATE_COMPANY_SUCCEED,
        payload: {
            company
        }
    }
};

export const updateCompanyFailed = error => {
    return {
        type: UPDATE_COMPANY_FAILED,
        payload: {
            error: error
        }
    }
};

export const deleteCompany = () => {
    return {
        type: DELETE_COMPANY
    }
};

export const deleteCompanySucceed = company => {
    return {
        type: DELETE_COMPANY_SUCCEED,
        payload: {
            company
        }
    }
};

export const deleteCompanyFailed = error => {
    return {
        type: DELETE_COMPANY_FAILED,
        payload: {
            error: error
        }
    }
};


/**
 * Fetch Companies
 */
export const fetchCompaniesAction = (params) => dispatch => {
    dispatch(fetchCompanies());
    return api.company.getCompanies(params)
        .then(response => {
            dispatch(fetchCompaniesSucceed(response.data.data));
            return Promise.resolve(response.data);
        })
        .catch(error => {
            dispatch(fetchCompaniesFailed(error.response.data.errors.message));
            return Promise.reject(error.response)
        });
};


/**
 * Create company
 */
export const createCompanyAction = company => dispatch => {
    dispatch(createCompany());
    return api.company.createCompany(company)
        .then(response => {
            dispatch(createCompanySucceed(response.data.data));
            return Promise.resolve(response.data);
        })
        .catch(error => {
            dispatch(createCompanyFailed(error.response.data.errors.message));
            return Promise.reject(error.response)
        });
};

/**
 * Update company
 */
export const updateCompanyAction = (id, company) => dispatch => {
    dispatch(updateCompany());
    return api.company.updateCompany(id, company)
        .then(response => {
            dispatch(updateCompanySucceed(response.data.data));
            return Promise.resolve(response.data);
        })
        .catch(error => {
            dispatch(updateCompanyFailed(error.response.data.errors.message));
            return Promise.reject(error.response)
        });
};

/**
 * Delete Company
 */
export const deleteCompanyAction = company_id => dispatch => {
    dispatch(deleteCompany());
    const id = {
        params: {
            company_id: company_id
        }
    }
    return api.company.deleteCompany(id)
        .then(response => {
            dispatch(deleteCompanySucceed(response.data.data));
            return Promise.resolve(response.data);
        })
        .catch(error => {
            dispatch(deleteCompanyFailed(error.response.data.errors.message));
            return Promise.reject(error.response)
        });
};



/**
 * Fetch Companies
 */
export const fetchCompaniesCheckAuthAction = () => dispatch => {
    // dispatch(fetchCompanies());
    return api.company.getCompaniesCheckAuth()
      .then(response => {
          // dispatch(fetchCompaniesSucceed(response.data.data));
          return Promise.resolve(response.data);
      })
      .catch(error => {
          // dispatch(fetchCompaniesFailed(error.response.data.errors.message));
          return Promise.reject(error.response)
      });
};
