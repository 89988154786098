import React, { Fragment, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Helmet } from "react-helmet";
import SmsSetting from "./../SmsSetting";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import {
  fetchRejectListAction,
  unmountRejectListAction,
} from "../../../store/actions/rejectList";
import NotificationSystem from "react-notification-system";
import Pagination from "react-js-pagination";
import PropTypes from "prop-types";
import progressBar from "../../../utils/progressBar";
import exportToExcel from '../../../components/Excel/ExportToExcel';
import queryString from 'query-string';
import api from "../../../utils/api";

RejectList.propTypes = {};
RejectList.defaultProps = {
  props: {},
};

function RejectList(props) {

  const [currentType, setCurrentType] = useState("type");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(moment());
  const [deletingRow, setdeletingRow] = useState(null);
  const [historyList, setHistoryList] = useState([]);
  const [sorting, setSorting] = useState("DESC");
  const [searchWord, setSearchWord] = useState(null);
  const [changeStart, setChangeStart] = useState(false);
  const [pagination, setPagination] = useState({
    activePage: 1,
    per_page: null,
    total: null,
  });
  const notificationSystem = useRef();

  /**
   * useEffect
   */
  useEffect(() => {
    let data = {
      page: 1,
      date_start: null,
      date_end: endDate ? endDate.format("YYYY-MM-DD HH:mm") : null,
      orderBy: sorting,
      search_word: searchWord,
      columnName: "i_date",
    };
    onFetchRejectList(data);
  }, []);

  /**
   * event change start date
   * @param {*} startDate
   */
  function changeStartDateHandler(startDate) {
    setChangeStart(true);
    setStartDate(startDate);
  }

  /**
   * event change end date
   * @param {*} startDate
   */
  function changeEndDateHandler(endDate) {
    setEndDate(endDate);
  }

	function handleSearchWord(e) {
		setSearchWord(e.target.value);
	}

  /**
   * get data from serve
   * @param {*} data
   */
  function onFetchRejectList(data) {
    progressBar.start();
    const notification = notificationSystem.current;
    props
      .onFetchRejectList(data)
      .then((response) => {
 
        setHistoryList(response.data.data);
        setPagination({
          activePage: response.data.current_page,
          per_page: response.data.per_page,
          total: response.data.total,
        });
        progressBar.done();
      })
      .catch((error) => {
        notification.addNotification({
          title: (
            <FormattedMessage id="dashboard.Error" defaultMessage="Error" />
          ),
          message: (
            <FormattedMessage
              id="dashboard.ErrorMessage"
              defaultMessage="Sorry! Something went wrong. Retry!"
            />
          ),
          level: "error",
        });
        progressBar.done();
      });
  }

  function setDeletingRow (value, type) {
		if (window.confirm('무료수신거부 리스트는 삭제 하시겠습니까?'))
		{
			progressBar.start();
			let params = { 
					deleteRow: value
			}
			api.rejectList.deleteRow(params).then(response => {

					let data = {
						page: 1,
						date_start: null,
						date_end: endDate ? endDate.format("YYYY-MM-DD HH:mm") : null,
						orderBy: sorting,
						search_word: searchWord,
						columnName: "i_date",
					};
					onFetchRejectList(data);
					progressBar.done();

			}).catch(error => {
					progressBar.done();
			});
		}
    
  };

  /**
   * show list history
   */
  function renderTableHistory(data) {
    console.log(data, "data");
    if (data && data.length > 0) {
      return data.map((item, index) => {
        return (
          <tr key={item.list_id}>
            <td>
							<div><a onClick={() => setDeletingRow(item.list_id, 0)} ><i className="fas fa-trash-alt"/></a></div>
            </td>
            <td>
              <div>{item.i_date}</div>
            </td>
            <td>
              <div>{item.GroupNum}</div>
            </td>
            <td>
              <div>{item.FromNum}</div>
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan={4}>
            <div className="nodata">No Data</div>
          </td>
        </tr>
      );
    }
  }

  /**
   * pagination
   * @param {*} pageNumber
   */
  function handlePageChange(pageNumber) {
    let data = {
      page: pageNumber,
      date_start: changeStart == true ? startDate.format("YYYY-MM-DD HH:mm") : null,
      date_end: endDate ? endDate.format("YYYY-MM-DD HH:mm") : null,
      orderBy: sorting,
      columnName: "i_date",
			search_word: searchWord,
      sel_lots_id: localStorage.getItem('sel_lots_id'),
      sel_company_id: localStorage.getItem('sel_company_id')
    };
    onFetchRejectList(data);
  }

  function handleSearchDate() {
    let data = {
      page: 1,
      date_start: changeStart == true ? startDate.format("YYYY-MM-DD HH:mm") : null,
      date_end: endDate ? endDate.format("YYYY-MM-DD HH:mm") : null,
      orderBy: sorting,
      columnName: "i_date",
			search_word: searchWord,
      sel_lots_id: localStorage.getItem('sel_lots_id'),
      sel_company_id: localStorage.getItem('sel_company_id')
    };
    onFetchRejectList(data);
  }

  function  exportExcelHandler(e) {

			let data = {
				page: 1,
				date_start: changeStart == true ? startDate.format("YYYY-MM-DD HH:mm") : null,
				date_end: endDate ? endDate.format("YYYY-MM-DD HH:mm") : null,
				orderBy: sorting,
				search_word: searchWord,
				columnName: "i_date",
				per_page: 1000000,
		    sel_lots_id: localStorage.getItem('sel_lots_id'),
	      sel_company_id: localStorage.getItem('sel_company_id')
			};

			//let query = queryString.stringify(data[0], { arrayFormat: 'bracket' });
			progressBar.start();
			const notification = notificationSystem.current;
			props
				.onFetchRejectList(data)
				.then((response) => {
										
					let mainData =  response.data.data;
					console.log('mainData',mainData);

					let finalData = mainData.map(item =>{
							return {
									'입력일자 ': item.i_date,
									'그룹번호 ': item.GroupNum,
									'전화번호 ': item.FromNum
							}
					});
					exportToExcel(finalData, 'RejectList')

					progressBar.done();
				})
				.catch((error) => {
					notification.addNotification({
						title: (
							<FormattedMessage id="dashboard.Error" defaultMessage="Error" />
						),
						message: (
							<FormattedMessage
								id="dashboard.ErrorMessage"
								defaultMessage="Sorry! Something went wrong. Retry!"
							/>
						),
						level: "error",
					});
					progressBar.done();
				});

	};

  return (
    <Fragment>
      <FormattedMessage
        id="dashboard.SmsSetting.History"
        defaultMessage="History"
      >
        {(message) => (
          <Helmet>
            <title>{message}</title>
          </Helmet>
        )}
      </FormattedMessage>

      <div className="main_top_bar static_bar">
        <div className="box_sum_col_top">
          <div className="box_left">
            <ul className="nav nav_tabs">
              <li className="nav_item">
                <span className="nav_link">
                  <FormattedMessage
                    id="dashboard.Sms"
                    defaultMessage="SMS/MMS"
                  />
                  /{" "}
                  무료 수신거부 리스트
                </span>
              </li>
            </ul>
          </div>
          <div className="box_center">
            <div className="tabs_static_item">
              <div className="main_control_time_select">
                <div className="filler_to_from">
                  <div className="i_tem txt_form">
                    <FormattedMessage
                      id="dashboard.Date"
                      defaultMessage="Date"
                    />
                  </div>
                  <div className="i_tem box_control_input">
                    <div className="box_control_date">
                      <span className="icon_date" />
                      <div className="date_picker_custom">
                        <DatePicker
                          disabled={!currentType}
                          dateFormat={
                            currentType === "time"
                              ? "YYYY/MM/DD HH:mm"
                              : "YYYY/MM/DD"
                          }
                          selected={startDate}
                          showTimeSelect={currentType === "time"}
                          timeFormat="HH:mm"
                          timeIntervals={60}
                          timeCaption="Time"
                          selectsStart
                          autoComplete = 'off'
                          id="FromDatePicker"
                          name="FromDatePicker"
                          className="from_date form_control form-control-sm unstyled"
                          startDate={startDate}
                          endDate={endDate}
                          onChange={changeStartDateHandler}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="i_tem txt_form hide">~</div>
                  <div className="i_tem box_control_input">
                    <div className="box_control_date">
                      <span className="icon_date" />
                      <div className="date_picker_custom">
                        <DatePicker
                          disabled={!currentType}
                          dateFormat={
                            currentType === "time"
                              ? "YYYY/MM/DD HH:mm"
                              : "YYYY/MM/DD"
                          }
                          selected={endDate}
                          showTimeSelect={currentType === "time"}
                          timeFormat="HH:mm"
                          timeIntervals={60}
                          timeCaption="Time"
                          selectsStart
                          id="FromDatePicker"
                          name="FromDatePicker"
                          className="from_date form_control form-control-sm unstyled"
                          startDate={endDate}
                          endDate={endDate}
                          onChange={changeEndDateHandler}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="i_tem txt_form">
                    전화번호
                  </div>
                  <div className="i_tem box_control_input">
											<input type="text" className="form-control" name="search_word" onChange={handleSearchWord} value={searchWord}/>
                  </div>
                </div>
                <div className="box_right right">
                  <button
                    className="btn btn_preference mr-2"
                    onClick={handleSearchDate}
                  >
											<FormattedMessage
													id="dashboard.Search"
													defaultMessage="Search"
											/>
                  </button>

									<button
											className="btn btn_preference"
											onClick={(e) => exportExcelHandler(e)}
											disabled={historyList?.length > 0 ? false : true}
									>
											<FormattedMessage
													id="dashboard.ExcelDownload"
													defaultMessage="Excel download"
											/>
									</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main_content">
        <div className="content_tabs_main content_static_tabs tab-content">
          <div className="tabs_static_item tabs_item1">
            <div className="box_content_static">
              <div className="left_col left_col_customer_care">
                <SmsSetting location={props.location} />
              </div>
              <NotificationSystem ref={notificationSystem} />
              <div className="content_col_nopading">
                <div className="list_control_box_preferences">
                  <div className="content_left">
                    <div className="content_column">
                      <div className="column_box ">
                        <div className="control_box_item">
                          <div className="card_body">
                            <div className="custom_table">
                              <table className="table st_table_5">
                                <thead>
                                  <tr>
                                    <th style={{width:"100px"}}>
                                      삭제
                                    </th>
                                    <th style={{width:"250px"}}>
                                      입력일자
                                    </th>
                                    <th style={{width:"250px"}}>
                                      그룹번호
                                    </th>
                                    <th style={{width:"500px"}}>
                                      전화번호
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>{renderTableHistory(historyList)}</tbody>
                              </table>
                            </div>
                          </div>
                          <div className="car_footer">
                            <nav aria-label="...">
                              <ul className="custom_pagination pagination">
                                {historyList && historyList.length > 0 ? (
                                  <Pagination
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    activePage={pagination.activePage}
                                    itemsCountPerPage={pagination.per_page}
                                    totalItemsCount={pagination.total}
                                    pageRangeDisplayed={10}
                                    onChange={handlePageChange}
                                  />
                                ) : (
                                  ""
                                )}
                              </ul>
                            </nav>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    RejectList: state.RejectList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchRejectList: (query) => dispatch(fetchRejectListAction(query)),
    onUnmountRejectList: () => dispatch(unmountRejectListAction()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RejectList);
