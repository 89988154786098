import "./App.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  withRouter,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import GuestRoute from "./router/GuestRoute";
import UserRoute from "./router/UserRoute";
import * as routerName from "./utils/routerName";
// import { Helmet } from 'react-helmet';
// import PropTypes from "prop-types";
import { Helmet } from 'react-helmet';
import WindowOpener from './containers/TelephoneCounselling/windown-opener'

import Login from "./containers/Login/Login";
import Home from "./containers/Home/Home";
import Dashboard from "./containers/Dashboard/Dashboard";
import UserRegistration from "./containers/UserRegistration/UserRegistration";
import CustomerList from "./containers/CustomerList/CustomerList";
import TelephoneCounselling from "./containers/TelephoneCounselling/TelephoneCounselling";
import DailyReport from "./containers/Statistics/DailyReport/DailyReport";
import Analysis from "./containers/Statistics/Analysis/Analysis";
import Graph from "./containers/Statistics/Graph/Graph";
import CounsellingData from "./containers/CustomerManagement/CounsellingData";
import CustomerData from "./containers/CustomerManagement/CustomerData";
import IVRData from "./containers/CustomerManagement/IVRData";
import DataSetting from "./containers/DataSetting/DataSetting";
import CompanyRegistration from "./containers/DataSetting/CompanyRegistration/CompanyRegistration";
import AffiliatedCompanyManagement from "./containers/DataSetting/CompanyRegistration/AffiliatedCompanyManagement";
import ItemManagement from "./containers/DataSetting/ItemManagement/ItemManagement";
import CallHistory from "./containers/DataSetting/CallHistory/CallHistory";
import CounselorManagement from "./containers/DataSetting/CounselorManagement/CounselorManagement";
import TerritoryManagement from "./containers/DataSetting/TerritoryManagement/TerritoryManagement";
import LineInformation from "./containers/DataSetting/LineInfomation/LineInformation";
import SmsSetting from "./containers/SmsSetting/SmsSetting";
//import SmsHistory from "./containers/SmsSetting/Sms-Mms/SmsHistory";
import SmsHistoryV2 from "./containers/SmsSetting/Sms-Mms/SmsHistoryV2";
import SmsBulkSending from "./containers/SmsSetting/Sms-Mms/SmsBulkSending";
import SendAutoSMS from "./containers/SmsSetting/Sms-Mms/SendAutoSMS";
import SmsTextManagement from "./containers/SmsSetting/Sms-Mms/SmsTextManagement";
import TextOnOff from "./containers/SmsSetting/Sms-Mms/TextOnOff";
import RealEstateAgentContainer from "./containers/DataSetting/MgmAdmin/RealEstateAgentContainer";
import RegisteredPlacement from "./containers/DataSetting/MgmAdmin/RegisteredPlacement";
import DocLayout from "./containers/Documentation/DocLayout/DocLayout";
import GettingStarted from "./containers/Documentation/GettingStarted";
import CustomerListDoc from './containers/Documentation/CustomerListDoc';
import UserRegistrationDoc from './containers/Documentation/UserRegistrationDoc';
import TelephoneCounsellingDoc from './containers/Documentation/TelephoneCounsellingDoc';
import DataSettingDoc from './containers/Documentation/DataSettingDoc';
import SmsSettingDoc from './containers/Documentation/SmsSettingDoc';
import StatisticsDoc from './containers/Documentation/StatisticsDoc';
import CustomerManagementDoc from './containers/Documentation/CustomerManagementDoc';
import PopupTelephone from "./containers/TelephoneCounselling/PopupTelephone";

import FreeBoard from "./containers/Board/FreeBoard/FreeBoard";
import ReqBoard from "./containers/Board/ReqBoard/ReqBoard";

import Notice from "./containers/DataSetting/Notice/Notice";

import PredefineSMS from "./containers/SmsSetting/Sms-Mms/PredefineSMS";

import RejectList from "./containers/SmsSetting/Sms-Mms/RejectList";
import ReservedSMS from "./containers/SmsSetting/Sms-Mms/ReservedSMS";

class App extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { location } = this.props;
    // const { lang } = this.props.languages;

    return (
      <React.Fragment>
        <Switch>
          {/*<Helmet>*/}
          {/*              <title>EPLE CRM</title>*/}
          {/*              <meta name="description" content="EPLE CRM application" />*/}
          {/*</Helmet>*/}
          <GuestRoute
            exact
            location={location}
            path={`/${routerName.LOGIN}`}
            component={Login}
          />
          <UserRoute
            exact
            location={location}
            path={`/`}
            component={Home}
            layout={Dashboard}
          />
          <UserRoute
            exact
            location={location}
            path={`/${routerName.DASHBOARD}`}
            component={Home}
            layout={Dashboard}
          />
          <UserRoute
            exact
            location={location}
            path={`/${routerName.DASHBOARD}/${routerName.USER_REGISTRATION}`}
            component={UserRegistration}
            layout={Dashboard}
            role={["primarynet"]}
          />
          <UserRoute
            exact
            location={location}
            path={`/${routerName.DASHBOARD}/${routerName.CUSTOMER_LIST}`}
            component={CustomerList}
            layout={Dashboard}
          />
          <UserRoute
            exact
            location={location}
            path={`/${routerName.DASHBOARD}/${routerName.TELEPHONE}`}
            component={TelephoneCounselling}
            layout={Dashboard}
          />
          <UserRoute
            exact
            location={location}
            path={`/${routerName.DASHBOARD}/${routerName.STATISTICS}`}
            component={DailyReport}
            layout={Dashboard}
          />
          <UserRoute
            exact
            location={location}
            path={`/${routerName.DASHBOARD}/${routerName.STATISTICS}/${routerName.DAILY_REPORT}`}
            layout={Dashboard}
            component={DailyReport}
          />
          <UserRoute
            exact
            location={location}
            path={`/${routerName.DASHBOARD}/${routerName.STATISTICS}/${routerName.ANALYSIS}`}
            layout={Dashboard}
            component={Analysis}
          />
          <UserRoute
            exact
            location={location}
            path={`/${routerName.DASHBOARD}/${routerName.STATISTICS}/${routerName.GRAPH}`}
            layout={Dashboard}
            component={Graph}
          />
          <UserRoute
            exact
            location={location}
            path={`/${routerName.DASHBOARD}/${routerName.CUSTOMER_MANAGEMENT}/${routerName.COUNSELLING_DATA}`}
            layout={Dashboard}
            component={CounsellingData}
          />
          <UserRoute
            exact
            location={location}
            path={`/${routerName.DASHBOARD}/${routerName.CUSTOMER_MANAGEMENT}/${routerName.CUSTOMER_DATA}`}
            layout={Dashboard}
            component={CustomerData}
          />
          <UserRoute
            exact
            location={location}
            path={`/${routerName.DASHBOARD}/${routerName.CUSTOMER_MANAGEMENT}/${routerName.IVR_DATA}`}
            layout={Dashboard}
            component={IVRData}
          />
          <UserRoute
            exact
            location={location}
            path={`/${routerName.DASHBOARD}/${routerName.DATA_SETTING}`}
            layout={Dashboard}
            component={DataSetting}
            role={["primarynet", "admin"]}
          />

          <UserRoute
            location={location}
            exact
            path={`/${routerName.DASHBOARD}/${routerName.DATA_SETTING}/${routerName.COMPANY_REGISTRATION}`}
            layout={Dashboard}
            component={CompanyRegistration}
            role={["primarynet"]}
          />
          <UserRoute
            location={location}
            exact
            path={`/${routerName.DASHBOARD}/${routerName.DATA_SETTING}/${routerName.AFFILITED_COMPANY_MANAGEMENT}`}
            layout={Dashboard}
            component={AffiliatedCompanyManagement}
            role={["admin"]}
          />

          <UserRoute
            location={location}
            path={`/${routerName.DASHBOARD}/${routerName.DATA_SETTING}/${routerName.ITEM_MANAGEMENT}`}
            exact
            layout={Dashboard}
            component={ItemManagement}
          />

          <UserRoute
            location={location}
            path={`/${routerName.DASHBOARD}/${routerName.DATA_SETTING}/${routerName.TERRITORY_MANAGEMENT}`}
            exact
            layout={Dashboard}
            component={TerritoryManagement}
          />
          <UserRoute
            location={location}
            path={`/${routerName.DASHBOARD}/${routerName.DATA_SETTING}/${routerName.CALL_HISTORY}`}
            exact
            layout={Dashboard}
            component={CallHistory}
          />
          <UserRoute
            location={location}
            path={`/${routerName.DASHBOARD}/${routerName.DATA_SETTING}/${routerName.COUNSELOR_MANAGEMENT}`}
            exact
            layout={Dashboard}
            component={CounselorManagement}
          />
          <UserRoute
            location={location}
            path={`/${routerName.DASHBOARD}/${routerName.DATA_SETTING}/${routerName.LINE_INFORMATION}`}
            exact
            layout={Dashboard}
            component={LineInformation}
          />
          <UserRoute
            location={location}
            path={`/${routerName.DASHBOARD}/${routerName.SMS_SETTING}/${routerName.SMS_MMS}/${routerName.SMS_HISTORY_V2}`}
            exact
            layout={Dashboard}
            component={SmsHistoryV2}
          />
          <UserRoute
            location={location}
            path={`/${routerName.DASHBOARD}/${routerName.SMS_SETTING}/${routerName.SMS_MMS}/${routerName.SMS_TEXT_MANAGEMENT}`}
            exact
            layout={Dashboard}
            component={SmsTextManagement}
          />
          <UserRoute
            location={location}
            path={`/${routerName.DASHBOARD}/${routerName.SMS_SETTING}/${routerName.SMS_MMS}/${routerName.PREDEFINE_SMS}`}
            exact
            layout={Dashboard}
            component={PredefineSMS}
          />

          <UserRoute
            location={location}
            path={`/${routerName.DASHBOARD}/${routerName.SMS_SETTING}/${routerName.SMS_MMS}/${routerName.SMS_BULK_SENDING}`}
            exact
            layout={Dashboard}
            component={SmsBulkSending}
          />

          <UserRoute
            location={location}
            path={`/${routerName.DASHBOARD}/${routerName.SMS_SETTING}/${routerName.SMS_MMS}/${routerName.TEXT_ON_OFF}`}
            exact
            layout={Dashboard}
            component={TextOnOff}
          />

          <UserRoute
            location={location}
            path={`/${routerName.DASHBOARD}/${routerName.SMS_SETTING}/${routerName.SMS_MMS}/${routerName.REJECT_LIST}`}
            exact
            layout={Dashboard}
            component={RejectList}
          />

          <UserRoute
            location={location}
            path={`/${routerName.DASHBOARD}/${routerName.SMS_SETTING}/${routerName.SMS_MMS}/${routerName.SMS_AUTO_SEND}`}
            exact
            layout={Dashboard}
            component={SendAutoSMS}
          />

					<UserRoute
            location={location}
            path={`/${routerName.BOARD}/${routerName.FREE_BOARD}`}
            layout={Dashboard}
            exact
            component={FreeBoard}
          />

					<UserRoute
            location={location}
            path={`/${routerName.BOARD}/${routerName.REQ_BOARD}`}
            layout={Dashboard}
            exact
            component={ReqBoard}
          />

          <UserRoute
            location={location}
            path={`/${routerName.DASHBOARD}/${routerName.DATA_SETTING}/${routerName.MGM_ADMIN}/${routerName.MGM_USER_MANGER}`}
            exact
            layout={Dashboard}
            component={RealEstateAgentContainer}
          />

          <UserRoute
            location={location}
            path={`/${routerName.DASHBOARD}/${routerName.DATA_SETTING}/${routerName.MGM_ADMIN}/${routerName.MGM_PLACEMENT}`}
            exact
            layout={Dashboard}
            component={RegisteredPlacement}
          />

          <UserRoute
            location={location}
            path={`/${routerName.DASHBOARD}/${routerName.DATA_SETTING}/${routerName.NOTICE}`}
            exact
            layout={Dashboard}
            component={Notice}
          />

          <UserRoute
            location={location}
            path={`/${routerName.DASHBOARD}/${routerName.SMS_SETTING}/${routerName.SMS_MMS}`}
            layout={Dashboard}
            component={SmsSetting}
            role={["primarynet", "admin"]}
          />

          <Redirect
            from={`/${routerName.DASHBOARD}/${routerName.DATA_SETTING}`}
            to={`/${routerName.DASHBOARD}/${routerName.DATA_SETTING}/${routerName.COMPANY_REGISTRATION}`}
          />
          <UserRoute
            location={location}
            path={`/${routerName.DOCUMENTATION}/${routerName.DOCUMENTATION_GETTING_STARTED}`}
            layout={DocLayout}
            exact
            component={GettingStarted}
          />
          <UserRoute
            location={location}
            path={`/${routerName.DOCUMENTATION}/${routerName.INSTRUCTIONS}/${routerName.CUSTOMER_LIST}`}
            layout={DocLayout}
            exact
            component={CustomerListDoc}
          />
          <UserRoute
            location={location}
            path={`/${routerName.DOCUMENTATION}/${routerName.INSTRUCTIONS}/${routerName.USER_REGISTRATION}`}
            layout={DocLayout}
            exact
            component={UserRegistrationDoc}
          />
          <UserRoute
            location={location}
            path={`/${routerName.DOCUMENTATION}/${routerName.INSTRUCTIONS}/${routerName.TELEPHONE}`}
            layout={DocLayout}
            exact
            component={TelephoneCounsellingDoc}
          />

          <UserRoute
            location={location}
            path={`/${routerName.DOCUMENTATION}/${routerName.INSTRUCTIONS}/${routerName.DATA_SETTING}`}
            layout={DocLayout}
            exact
            component={DataSettingDoc}
          />
          <UserRoute
            location={location}
            path={`/${routerName.DOCUMENTATION}/${routerName.INSTRUCTIONS}/${routerName.SMS_SETTING}`}
            layout={DocLayout}
            exact
            component={SmsSettingDoc}
          />
          <UserRoute
            location={location}
            path={`/${routerName.DOCUMENTATION}/${routerName.INSTRUCTIONS}/${routerName.STATISTICS}`}
            layout={DocLayout}
            exact
            component={StatisticsDoc}
          />

					<UserRoute
            location={location}
            path={`/${routerName.DOCUMENTATION}/${routerName.INSTRUCTIONS}/${routerName.CUSTOMER_MANAGEMENT}`}
            layout={DocLayout}
            exact
            component={CustomerManagementDoc}
          />

          <UserRoute
              location={location}
              path={`/popup`}
              // layout={DocLayout}
              exact
              component={PopupTelephone}
          />

        </Switch>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // auth: state.auth,
    // loading: state.auth.loading,
    // lang: state.appState.lang
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
