import {
    AUTH_SUCCEED,
    AUTH_FAILED,
    AUTH_LOGOUT,
    AUTH_USER,
    AUTH_INIT_LOGOUT,
    AUTH_FETCH_CURRENT_USER,
    AUTH_CHANGE_DISTURB_USER,
    AUTH_CHANGE_INFO_SALE_LOT,
    AUTH_CHANGE_MEMO
} from '../actions/actionTypes';
import api from "../../utils/api";
import setAuthorizationHeader from "../../utils/setAuthorizationHeader";
import {changeSceneLotAction} from "./sceneSelect";

export const authSucceed = (token, user) => {
    return {
        type: AUTH_SUCCEED,
        payload: {
            token: token,
            user: user
        }
    }
};

export const authFail = error => {
    return {
        type: AUTH_FAILED,
        payload: {
            error: error
        }
    }
};

export const auth = (credentials) => {
    return {
        type: AUTH_USER,
        payload: {
            credentials
        }
    }
};

export const authFetchCurrentUser = (data) => {

		console.log('login authFetchCurrentUser');
		localStorage.setItem("menu_1", data.user.en_menu_1);
		localStorage.setItem("menu_2", data.user.en_menu_2);
		localStorage.setItem("menu_3", data.user.en_menu_3);
		localStorage.setItem("menu_4", data.user.en_menu_4);
		localStorage.setItem("change_manager", data.user.change_manager);
		localStorage.setItem("see_history", data.user.see_history);

    return {
        type: AUTH_FETCH_CURRENT_USER,
        payload: {
            user: data.user,
            token: data.token
        }
    }
};

export const logout = () => {
    return {
        type: AUTH_INIT_LOGOUT
    }
};

export const logoutSucceed = () => {
    return {
        type: AUTH_LOGOUT
    }
};



/**
 * Action
 */


export const authAction = (credentials) => dispatch => {
    dispatch(auth());
    return api.auth.login(credentials)
        .then(response => {
            let user_mgm = response.data.user
            if(user_mgm && user_mgm.roles.length > 0 && user_mgm.roles[0].name == 'mgm'){
                 api.mgm.login(credentials).then(res => {
                     let token = res.data.token.accessToken
                     let URL = process.env.NODE_ENV === 'production'
                         ? `${process.env.REACT_APP_REDIRECT_PROD}`
                         : `${process.env.REACT_APP_REDIRECT_DEV}`
                     return window.location.href = `${URL}/auth?access_token=${token}`;
                 })
                return null
            }
            localStorage.setItem('token', response.data.token);
            setAuthorizationHeader(localStorage.token);
            // Get role and permission of user after login succeed
            api.auth.getCurrentUser().then(response => {
                let data = {
                    token: localStorage.token,
                    user: response.data.data
                };
                const roles = response.data.data.roles;
                dispatch(authFetchCurrentUser(data));
                if(roles[0].name !== 'admin' && roles[0].name !== 'primarynet') {
                    dispatch(changeSceneLotAction(response.data.data.lots_id));
                }
            });
            dispatch(authSucceed(response.data.token));
            return Promise.resolve(response.data.data);

        })
        .catch(error => {
            dispatch(authFail(error.response.data.errors.message));
            return Promise.reject(error.response)
        });
};


export const authLogoutAction = () => dispatch => {
    dispatch(logout());
    let token;
    if (localStorage.getItem('token')) {
        token = localStorage.getItem('token');
    }

    return api.auth.logout({token})
        .then(response => {
            if (localStorage.token) {
                localStorage.removeItem('token');
            }
            if(localStorage.getItem('call-date')){
                localStorage.removeItem("call-date");
            }
            setAuthorizationHeader();
            dispatch(logoutSucceed());
            dispatch(changeSceneLotAction(null));
        })
        .catch(error => {
            if (localStorage.token) {
                localStorage.removeItem('token');
            }
            setAuthorizationHeader();
            dispatch(logoutSucceed());
            dispatch(changeSceneLotAction(null));
        });
};


export const disturbResponse = dnd_type => {
    return {
        type: AUTH_CHANGE_DISTURB_USER,
        payload: {
            dnd_type
        }
    }
}

export const disturbResponseAction = data => dispatch => {
    return api.auth.disturbResponse(data).then(response => {
        console.log(response.data.data.dnd_type,'response.data.data.dnd_type')
        dispatch(disturbResponse(response.data.data.dnd_type));
        return Promise.resolve(response.data.data);
    })
}


export const changeInfoSaleLotAction = data => dispatch => {
    dispatch({
        type : AUTH_CHANGE_INFO_SALE_LOT,
        payload : data
    })
}

export const changeMemoAction = data => dispatch => {
    console.log(data,'memo action')
    dispatch({
        type : AUTH_CHANGE_MEMO,
        payload : data
    })
}