import React, { Fragment } from 'react';
import { Modal, ModalHeader, ModalFooter, ModalBody, Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

const LogoutModal = props => {
    return (
        <Fragment>
            <Modal
                isOpen={props.isOpen}
                toggle={props.toggle}
                className="modal-dialog modal-dialog-scrollable"
                contentClassName="modal-content pl-4 pr-4"
            >

                <div className="modal-header">
                    <h5 className="modal-title">
                        <FormattedMessage
                            id="dashboard.Logout"
                            defaultMessage="Logout"/>
                    </h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.toggle}>
                        <span aria-hidden="true">×</span>
                    </button>
                </div>

                <div className="modal-body">
                <FormattedMessage
                    id="dashboard.ConfirmLogout"
                    defaultMessage="Are you sure you want to logout?"/>
                </div>

                <div className="modal-footer">
                    <button onClick={props.toggle}
                        type="button"
                        className="btn btn-dark flex-fill"
                        data-dismiss="modal"
                        aria-label="Close">
                        <FormattedMessage
                            id="dashboard.Cancel"
                            defaultMessage="Cancel" />
                    </button>
                    <button onClick={props.onConfirm}
                        type="button"
                        className="btn btn-outline-dark flex-fill"
                        data-dismiss="modal"
                        aria-label="Close">
                        <FormattedMessage
                            id="dashboard.Yes"
                            defaultMessage="Yes" />
                    </button>
                </div>
            </Modal>
        </Fragment>
    )
};
export default LogoutModal;
