import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Helmet } from 'react-helmet';
import { NavLink, Link } from "react-router-dom";
import moment from "moment/moment";
import queryString from 'query-string';
import DatePicker from 'react-datepicker';
import NotificationSystem from 'react-notification-system';
import { Card, Button } from 'reactstrap';
import DataSetting from './../DataSetting/DataSetting';
import progressBar from '../../utils/progressBar';
import api from "../../utils/api";
import * as routerName from "../../utils/routerName";
import * as helpers  from "../../utils/helpers";
import Pagination from "react-js-pagination";
import DeleteConfirmModal from './../../components/DeleteConfirmModal/DeleteConfirmModal'; 
import exportToExcel from './../../components/Excel/ExportToExcel';
import {
    fetchCompaniesAction
} from "../../store/actions/company";
import {
    // fetchCustomerListAction,
    deleteCustomerAction,
    exportCustomerAction
} from "../../store/actions/calls";

class CustomerList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            companies: {},
            saleLots: {},
            customers: {},
            currentCompany: {},
            currentSaleLot: {},
            cond: {
                startDate: moment().subtract(1, 'month'),
                endDate: moment(),
                search: '',
                // lots_id: '',
                type: 'paginate',
            },
            notification: {
                allowHTML: false,
            },
            selection: [],
            isDeleteModalOpen: false,
            isDeleteConfirmModalOpen: false,
        };
    }

    notificationSystem = React.createRef();
    addNotification = (data) => {
      const notification = this.notificationSystem.current;
      notification.addNotification({
        message: data.message,
        title: data.title,
        level: data.level
      });
    };

    componentDidMount() {
        // paginate: get data Paginate Format  || list: get data List Format
        this.getCompanies();    
    }

    getCompanies = () => {
        progressBar.start();
        let params = {
            page: this.state.companies?.current_page || 1,
            type: 'paginate'
        }
        this.props.onFetchCompanies(params).then(response => {
            this.setState({
                ...this.state,
                companies: response.data
            })
            progressBar.done();
        }).catch(error => {
            progressBar.done();
        });
    }

    // must used to 2nd parram to determine Click from companies List or Paginate
    getSaleLotsByCompanyFunc = (company, pageSaleLot=1) => {
        progressBar.start();   
        let params = {
            // page: this.state.saleLots?.current_page || 1,
            page: pageSaleLot,
            type: 'paginate',
        }
        api.saleLots.getSaleLotsByCompany(company.company_id, params).then(response => {
           this.setState({
               ...this.state,
               currentCompany: company,
               saleLots: response.data.data,
               currentSaleLot: {},
               customers: {} 
           }, () => {
               console.log(this.state, 'state_af');
           })
            progressBar.done();
        }).catch(error => {
            progressBar.done();
        });
    }

    getCustomerList = (saleLot, page=1) => {
        progressBar.start();   
        this.setState({
            ...this.state,
            currentSaleLot: saleLot,
            customers: {
                ...this.state.customers,
                current_page: page,
            }
        }, () => {
            console.log(this.state.currentSaleLot,'currentSaleLot22');

            let params = {
                ...this.state.cond,
                page: this.state.customers?.current_page || 1,
                lots_id: this.state.currentSaleLot?.lots_id
            }
            let query = queryString.stringify(params, { arrayFormat: 'bracket' });
            api.callList.getCustomerBySaleLot(query).then(response => {
               this.setState({
                   ...this.state,
                //    currentCompany: company,
                   customers: response.data.data
               }, () => {
                   console.log(this.state.customers, 'customers333');
               })
                progressBar.done();
            }).catch(error => {
                progressBar.done();
            });
        })
    }


    changePageCompany = (pageNumber) => {
        this.setState({
            ...this.state,
            companies: {
                ...this.state.companies,
                current_page: pageNumber
            }
        }, () => {
            this.getCompanies();
        })
    }

    
    changePageSaleLot = (pageNumber) => {
        this.getSaleLotsByCompanyFunc(this.state.currentCompany, pageNumber);
    }

    // search list customer with current page
    changePageCustomer = (pageNumber) => {
        this.getCustomerList(this.state.currentSaleLot, pageNumber);
    }

    // search list customer with Reset page = 1
    handleSearch = () => {
        // this.setState({
        //     ...this.state,
        //     customers: {
        //         ...this.state.customers,
        //         current_page: 1
        //     }
        // }, () => {
        //     this.getCustomerList(this.state.currentSaleLot);
        // })
        this.getCustomerList(this.state.currentSaleLot, 1);
    }

    changeStartDateHandler = (startDate) => { 
        this.changeHandler({ startDate });
    };

    changeEndDateHandler = (endDate) => {
        this.changeHandler({ endDate })
    };

    changeHandler = ({startDate, endDate}) => {
        startDate = startDate || this.state.cond.startDate;
        endDate = endDate || this.state.cond.endDate;

        if (startDate !== null && startDate.isAfter(endDate)) {
            endDate = startDate
        }
        this.setState({
            ...this.state,
            cond: {
                ...this.state.cond,
                startDate,
                endDate,
            }
        })
    };

    fixedFormatter = value => {
        let arrReceived = [0];
        let arrSending  = [1];
        let arrUnAnswer = [2, 3, 4, 5];
        if (arrReceived.includes(parseInt(value))) {
            return (
                <span className="badge badge-success">
                     <FormattedMessage id="dashboard.Received" defaultMessage="Received"/>
                </span>
            )
        }
        if (arrSending.includes(parseInt(value))) {
            return (
                <span className="badge badge-secondary">
                     <FormattedMessage id="dashboard.Hanging" defaultMessage="Hanging"/>
                </span>
            )
        }
        if (arrUnAnswer.includes(parseInt(value))) {
            return (
                <span className="badge badge-danger">
                     <FormattedMessage id="dashboard.Unanswered" defaultMessage="Unanswered"/>
                </span>
            )
        }
    };

    fixedFormatter_2 = value => {
        let arrReceived = [0];
        let arrSending  = [1];
        let arrUnAnswer = [2, 3, 4, 5];
        if (arrReceived.includes(parseInt(value))) {
            return '수신콜'
        }
        if (arrSending.includes(parseInt(value))) {
            return '발신콜'
        }
        if (arrUnAnswer.includes(parseInt(value))) {
            return '부재콜'
        }
    };

    onChangeValueForm = event => {
        this.setState({
            ...this.state,
            cond: {
                ...this.state.cond,
                [event.target.name]: event.target.value
            }
        }, () => {
            console.log(this.state.cond, 'conddd');
        });
    }

    handleClickItemCheckbox = (event, item) => {
        let { selection } = this.state;
        let value = event.target.checked;
        if (value) {
            if ( !selection.includes(item.call_id) )
            selection.push(item.call_id);
        } else {
            selection = helpers.removeItemInArray(selection, item.call_id)
        }
        this.setState({
            ...this.state,
            selection,
        }, () => {
            // console.log(this.state.selection, 'selection_af');
        })
    }

    selectRowTable = (item) => {
        let value = !(this[`checkbox_${item.call_id}`].checked);
        this[`checkbox_${item.call_id}`].checked = value;  

        let {selection} = this.state;
        if (value) {
            if ( !selection.includes(item.call_id) )
            selection.push(item.call_id);
        } else {
            selection = helpers.removeItemInArray(selection, item.call_id)
        }

        this.setState({
            ...this.state,
            selection,
        }, () => {
            // console.log(this.state.selection, 'seclectt');
        })
    } 

    openDeleteModal = () => {
        this.setState({
            isDeleteModalOpen: !this.state.isDeleteModalOpen
        });
    };

    openDeleteConfirmModal = () => {
        this.setState({
            isDeleteConfirmModalOpen: !this.state.isDeleteConfirmModalOpen
        });
    };

    deleteCustomerHandler = (e) => {
        e.preventDefault();
        let cond = {
            call_id: this.state.selection
        }
        let query = queryString.stringify(cond, {arrayFormat: 'bracket'});
        progressBar.start();

        this.props.onDeleteCustomer(query).then(response => {
            // check and re-set current page if delete last item of last page
            let {customers} = this.state; 
            let current_page = (customers.from + (cond.call_id.length) - 1) == customers.to
                            ? customers.last_page - 1
                            : customers.current_page;
            this.setState({
                ...this.state,
                selection: [],
                isDeleteConfirmModalOpen: false,
                notification: {
                    allowHTML: false,
                },
                // customers: {
                //     ...this.state.customers,
                //     current_page,
                // }
            }, () => {
                this.getCustomerList(this.state.currentSaleLot, current_page);

                this.addNotification({
                    title: <FormattedMessage
                        id="dashboard.Succeed"
                        defaultMessage="Succeed!"/>,
                    message: <FormattedMessage
                        id="dashboard.Customer.DeleteSucceed"
                        defaultMessage="Delete customer successfully!"/>,
                    level: 'success',
                })
                progressBar.done();
            })
        }).catch(error => {
            progressBar.done();
        });
    }

    // exportExcelHandler = (e) => {
    //     let cond = {
    //         ...this.state.cond,
    //         export_excel: true,
    //         lots_id: this.state.currentSaleLot?.lots_id || ''
    //     }
    //     let query = queryString.stringify(cond);
    //     progressBar.start();
    //     this.props.onCustomerExportExcel(query).then(response => {
    //         progressBar.done();
    //     }).catch(error => {         
    //         progressBar.done();
    //     });
    // }


    exportExcelHandler = (e) => {
        progressBar.start();
        let cond = {
            ...this.state.cond,
            export_excel: true,
            lots_id: this.state.currentSaleLot?.lots_id || '',
            type: 'list'
        }
        let query = queryString.stringify(cond);  
        return api.callList.exportCustomerList(query).then(response => {
            let mainData = response.data.data.map(item => {
                return {
                    '일자': item.call_date,
                    '유형': this.fixedFormatter_2(item.call_check),
                    '이름': item.call_cust_name,
                    '상담전화': item.call_tel,
                    '휴대전화': item.call_cust_hp,
                    '일반전화': item.call_cust_tel,
                    '주소': item.call_cust_address,
                    '관리지역': item.district?.dist_name  || '',
                    '특이사항': item.call_desc,
                    '상담원': item.user?.usr_name,
                }
            });
            exportToExcel(mainData, 'CustomerList')
            progressBar.done();
        })
    };


    render() {
        const { 
            companies,
            saleLots,
            customers,
            currentCompany,
            currentSaleLot,
            selection,
            cond,
        } = this.state;
        // console.log( customers, 'customers111'); 

        return (
            <Fragment>
                <NotificationSystem ref={this.notificationSystem} allowHTML={this.state.notification.allowHTML}/>

                <FormattedMessage id="dashboard.CustomerList"
                    defaultMessage="Customer List">
                    {message => <Helmet>
                        <title>{message}</title>
                    </Helmet>}
                </FormattedMessage>

                <div className="main_top_bar user_registion customer_list nofix">
                    <div className="box_sum_col_top inline_betwen">
                        <div className="filler_to_from">
                            <div className="i_tem txt_form">기간</div>
                            <div className="i_tem box_control_input">
                                <div className="box_control_date">
                                    <span className="icon_date" />
                                    <div className="date_picker_custom">
                                        <DatePicker
                                            dateFormat="YYYY/MM/DD"
                                            selected={this.state.cond.startDate}
                                            selectsStart
                                            // disabled={!this.state.cond.startDate}
                                            id="FromDatePicker"
                                            name="FromDatePicker"
                                            className="from_date form_control form-control-sm unstyled"
                                            startDate={this.state.cond.startDate}
                                            endDate={this.state.cond.endDate}
                                            onChange={this.changeStartDateHandler}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="i_tem txt_form hide">~</div>

                            <div className="i_tem box_control_input">
                                <div className="box_control_date">
                                    <span className="icon_date" />
                                    <div className="date_picker_custom">
                                        <DatePicker
                                            dateFormat="YYYY/MM/DD"
                                            selected={this.state.cond.endDate}
                                            selectsStart
                                            disabled={!this.state.cond.endDate}
                                            id="FromDatePicker"
                                            name="FromDatePicker"
                                            className="from_date form_control form-control-sm unstyled"
                                            startDate={this.state.cond.startDate}
                                            endDate={this.state.cond.endDate}
                                            onChange={this.changeEndDateHandler}
                                        />
                                    </div>
                                </div>
                            </div> 
                            <div className="box_search">
                                <input type="text"
                                    placeholder={ this.props.intl.formatMessage({id: 'mgm.admin.search_word', defaultMessage: 'Keyword'}) }
                                    name="search"
                                    value={cond.search}
                                    onChange={this.onChangeValueForm}
                                    className="control"/> 
                            </div>
                                
                            <div className="box_search margin_left">
                                <div className="box_control_search ">
                                    <button className="btn_form btn_secondary" onClick={() => this.handleSearch()}>
                                        <FormattedMessage id="dashboard.Search" defaultMessage="Search" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="main_content three_column">
                    <div className="custom_col_2 custom_col">
                        <div className="heading">
                            <h5 className="main_title">
                                <FormattedMessage id="dashboard.Company" defaultMessage="Company"/>
                            </h5>
                        </div>
                        <div className="content">
                            <div className="control_box_item">
                                <div className="card_body">
                                    <div className="custom_table">
                                        <table className="table st_table_5">
                                            <tbody>
                                            <tr>
                                                <th> <FormattedMessage id="dashboard.Name" defaultMessage="Name"/> </th>
                                            </tr>
                                            {/* render companies list */}
                                            {
                                                companies?.data?.length > 0 ? 
                                                    companies.data.map((item, index) => {
                                                        let active = currentCompany?.company_id == item.company_id ? 'active' : '';
                                                        return (
                                                            <tr key={index} className={active} onClick={() => this.getSaleLotsByCompanyFunc(item, 1)}>
                                                                <td>{item.company_name}</td>
                                                            </tr>
                                                        )
                                                    }) :
                                                    (
                                                        <tr>
                                                            <td>
                                                                <div className="nodata">No Data</div>
                                                            </td>
                                                        </tr> 
                                                    )
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                {/* render paginate companies list */}
                                {
                                    companies?.data?.length > 0 && (
                                        <div className="car_footer">
                                            <nav aria-label="...">
                                                <ul className="custom_pagination pagination">
                                                    <Pagination
                                                        itemClass="page-item"
                                                        linkClass="page-link"
                                                        activePage={companies?.current_page}
                                                        itemsCountPerPage={companies?.per_page}
                                                        totalItemsCount={companies?.total || 0}
                                                        pageRangeDisplayed={10}
                                                        onChange={this.changePageCompany}
                                                    />
                                                </ul>
                                            </nav>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className="custom_col_2 custom_col">
                        <div className="heading">
                            <h5 className="main_title">
                                <FormattedMessage id="dashboard.AffiliatedCompany" defaultMessage="Affiliated company"/>
                            </h5>
                        </div>
                        <div className="content">
                            <div className="control_box_item">
                                <div className="card_body">
                                    <div className="custom_table">
                                        <table className="table st_table_5">
                                            <tbody>
                                                <tr>
                                                    <th> <FormattedMessage id="dashboard.Name" defaultMessage="Name"/> </th>
                                                </tr>
                                                {/* render info saleLots list */}
                                                {
                                                    saleLots?.data?.length > 0 ? 
                                                        saleLots.data.map((item, index) => {
                                                            let active = this.state.currentSaleLot?.lots_id == item.lots_id ? 'active' : '';
                                                            return (
                                                                <tr key={index} className={active} onClick={() => this.getCustomerList(item, 1)}>
                                                                    <td>{item.lots_name}</td>
                                                                </tr>
                                                            )
                                                        }) :
                                                        (
                                                            <tr>
                                                                <td>
                                                                    <div className="nodata">No Data</div>
                                                                </td>
                                                            </tr> 
                                                        )
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                
                                {/* render paginate saleLots list */}
                                {
                                    saleLots?.data?.length > 0 && (
                                        <div className="car_footer">
                                            <nav aria-label="...">
                                                <ul className="custom_pagination pagination">
                                                    <Pagination
                                                        itemClass="page-item"
                                                        linkClass="page-link"
                                                        activePage={saleLots?.current_page}
                                                        itemsCountPerPage={saleLots?.per_page}
                                                        totalItemsCount={saleLots?.total || 0}
                                                        pageRangeDisplayed={10}
                                                        onChange={this.changePageSaleLot}
                                                    />
                                                </ul>
                                            </nav>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className="custom_col_full custom_col">
                        <div className="heading">
                            <h5 className="main_title">
                                <FormattedMessage id="dashboard.Customer" defaultMessage="Customer"/>
                            </h5>
                            <div className="box_right">
                                <ul className="list_btn">
                                    <li>
                                        <button className="btn_item btn_item_2"
                                            onClick={this.openDeleteModal}
                                            disabled={selection.length == 0}
                                        >
                                            <FormattedMessage  id="dashboard.Delete" defaultMessage="Delete"/>
                                        </button>
                                    </li>
                                    <li>
                                        <button 
                                            className="btn_item btn_item_1"
                                            onClick={this.exportExcelHandler}
                                            disabled={customers?.data?.length == 0 || !currentSaleLot?.lots_id}
                                        >
                                            <FormattedMessage  id="dashboard.ExcelDownload" defaultMessage="ExcelDownload"/>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="content">
                            <div className="control_box_item">
                                <div className="card_body">
                                    <div className="custom_table table_respontive">
                                        <table className="table st_table_5">
                                            <thead>
                                                <tr>
                                                    <th style={{width: '3%'}}/>
                                                    <th style={{width: '5%'}}>
                                                        <FormattedMessage id="dashboard.Date" defaultMessage="Date"/>
                                                    </th>
                                                    <th style={{width: '10%'}}>
                                                        <FormattedMessage id="dashboard.Type" defaultMessage="Type"/>
                                                    </th>
                                                    <th style={{width: '10%'}}>
                                                        <FormattedMessage id="dashboard.Name" defaultMessage="Name"/>
                                                    </th>
                                                    <th style={{width: '12%'}}>
                                                        <FormattedMessage id="dashboard.ReceivedPhone" defaultMessage="ReceivedPhone"/>
                                                    </th>
                                                    <th style={{width: '12%'}}>
                                                        <FormattedMessage id="dashboard.MobilePhone" defaultMessage="MobilePhone"/>
                                                    </th>
                                                    <th style={{width: '10%'}}>
                                                        <FormattedMessage id="dashboard.Telephone" defaultMessage="Telephone"/>
                                                    </th>
                                                    <th style={{width: '10%'}}>
                                                        <FormattedMessage id="dashboard.Address" defaultMessage="Address"/>
                                                    </th>
                                                    <th style={{width: '10%'}}>
                                                        <FormattedMessage id="dashboard.Territory" defaultMessage="Territory"/>
                                                    </th>
                                                    <th style={{width: '10%'}}>
                                                        <FormattedMessage id="dashboard.Comment" defaultMessage="Comment"/>
                                                    </th>
                                                    <th style={{width: '10%'}}>
                                                        <FormattedMessage id="dashboard.Counselor" defaultMessage="Counselor"/>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {/* render customers list */}
                                            { 
                                                customers?.data?.length > 0 ? 
                                                    customers.data.map((item, index) => {
                                                        return (
                                                            <tr key={index} onClick={() => this.selectRowTable(item)} >
                                                                <td className="align_center">                                                                  
                                                                    <input type="checkbox" 
                                                                        checked={ selection.includes(item.call_id) ? true : false }
                                                                        ref={(input) => this[`checkbox_${item.call_id}`] = input}                                                                                       
                                                                        onChange={(e) => this.handleClickItemCheckbox(e, item)}
                                                                        onClick={e => e.stopPropagation()}
                                                                    />
                                                                </td>   
                                                                <td className="while_space">{item.call_date}</td>
                                                                <td>{this.fixedFormatter(item.call_check)}</td>        
                                                                <td>{item.call_cust_name}</td>
                                                                <td>{item.call_tel}</td>
                                                                <td>{item.call_cust_hp}</td>
                                                                <td>{item.call_cust_tel}</td>
                                                                <td>{item.call_cust_address}</td>
                                                                <td>{item?.district?.dist_name}</td>
                                                                <td>{item.call_desc}</td>
                                                                <td>{item?.user?.usr_name}</td>
                                                            </tr>
                                                        )
                                                    }) :
                                                    (
                                                        <tr>
                                                            <td colSpan={11}>
                                                                <div className="nodata">No Data</div>
                                                            </td>
                                                        </tr> 
                                                    )
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                
                                {  
                                    customers?.data?.length > 0 && (
                                        <div className="car_footer">
                                            <nav aria-label="...">
                                                <ul className="custom_pagination pagination">
                                                    <Pagination
                                                        itemClass="page-item"
                                                        linkClass="page-link"
                                                        activePage={customers?.current_page}
                                                        itemsCountPerPage={customers?.per_page}
                                                        totalItemsCount={customers?.total || 0}
                                                        pageRangeDisplayed={10}
                                                        onChange={this.changePageCustomer}
                                                    />
                                                </ul>
                                            </nav>
                                        </div>
                                    ) 
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <DeleteConfirmModal
                    isDeleteModalOpen={this.state.isDeleteModalOpen}
                    openDeleteModal={this.openDeleteModal}
                    isDeleteConfirmModalOpen={this.state.isDeleteConfirmModalOpen}
                    openDeleteConfirmModal={this.openDeleteConfirmModal}
                    deleteInfoHandler={this.deleteCustomerHandler}
                />
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchCompanies: (params={type:'paginate'}) => dispatch(fetchCompaniesAction(params)),
        // onFetchCustomerList: (query) => dispatch(fetchCustomerListAction(query)),
        onDeleteCustomer: (query) => dispatch(deleteCustomerAction(query)),
        onCustomerExportExcel: (query) => dispatch(exportCustomerAction(query))

    }
};


export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CustomerList));

