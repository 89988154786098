import React, {useState} from 'react';
import useDebouncedEffect from "../useDebouncedEffect";
import api from "../../utils/api";

function ChangeMemoUser(props) {

    let {
        changeMemo,
        user
    } = props;

    const [memo, setMemo] = useState(user?.memo || '');

    useDebouncedEffect(
        () => {
            api.user.changeDataUser({
                memo
            })
        },
        1000,
        [memo]
    );

    return (
        <div className="control_textarea">
            <textarea
                className="form_control"
                value={memo}
                onChange={(e)=>{
                    setMemo(e.target.value)
                    changeMemo(e.target.value)
                }}
            />
        </div>
    )

}

export default ChangeMemoUser;