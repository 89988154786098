import React, { useEffect, useReducer, useRef } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import NotificationSystem from "react-notification-system";
import PropTypes from 'prop-types';

AddModalComponent.propTypes = {
};
AddModalComponent.defaultProps = {
};

function AddModalComponent(props) {
  const [infoInput, setInfoInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      pr_id: "",
      pr_content: "",
    }
  );

  useEffect(() => {
    setInfoInput({
      pr_id: props.dataEdit?.pr_id || "",
      pr_content: props.usrNameDefault,
    });
  }, [props.usrNameDefault]);

  const refCloseModal = useRef();
  const notificationSystem = useRef();

  function handleClick() {
    const notification = notificationSystem.current;
    console.log(infoInput,props.type, 'infoInput');
    if (infoInput.pr_id === "") {
      notification.addNotification({
        title: (
          <FormattedMessage id="dashboard.Warning" defaultMessage="Warning!" />
        ),
        message: (
          props.type === "lineInfo" ? 
          <FormattedMessage
            id="dashboard.LineInfo.No.Required"
            defaultMessage="Line no is required!"
          /> : 
          <FormattedMessage
            id="dashboard.Counselor.Name.Required"
            defaultMessage="Line no is required!"
          />
        ),
        level: "warning",
      });
      
    } else {
      if (infoInput.pr_content == 'undefined' || infoInput.pr_content == null || infoInput.pr_content == "" && props.type === "lineInfo") {
        notification.addNotification({
          title: (
            <FormattedMessage id="dashboard.Warning" defaultMessage="Warning!" />
          ),
          message: (
            <FormattedMessage
              id="dashboard.LineInfo.Name.Required"
              defaultMessage="Line Name is required!"
            /> 
          ),
          level: "warning",
        });
      }else{
        props.addModal(infoInput);
        setInfoInput({
          pr_id: "",
          pr_content: "",
        });
        refCloseModal.current.click();
      }
      
    }
  }

  const handleChange = (evt) => {
    const name = evt.target.name;
    const newValue = evt.target.value;
    setInfoInput({ [name]: newValue });
  };
  return (
    <div
      className="modal fade modal_add_preferences"
      id="modal_add_preferences"
      tabIndex={-1}
      aria-labelledby="logoutModalLabel"
      aria-hidden="true"
      style={{ display: "none" }}
    >
      <NotificationSystem ref={notificationSystem} />
      <div className="modal-dialog modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">New</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="box_add_ppreferences_1">
              <div className="box_group_control">
                <div className="heading_title">
                  {props.type === "lineInfo" ? (
                    <FormattedMessage
                      id="dashboard.Line"
                      defaultMessage="Line #"
                    />
                  ) : (
                    <FormattedMessage
                      id="dashboard.CounselorName"
                      defaultMessage="Counselor Name"
                    />
                  )}
                </div>
                <div className="group_control_in">
                  <input
                    type="text"
                    placeholder={
                      props.type === "lineInfo"
                        ? props.intl.formatMessage({
                            id: "dashboard.Line",
                            defaultMessage: "Line #",
                          })
                        : props.intl.formatMessage({
                            id: "dashboard.CounselorName",
                            defaultMessage: "Counselor Name",
                          })
                    }
                    name="pr_id"
                    value={infoInput.pr_id}
                    disabled={
                      props.usrCodeDisable ? props.usrCodeDisable : false
                    }
                    className="form_control"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="box_group_control">
                <div className="heading_title">
                  {props.type === "lineInfo" ? (
                    <FormattedMessage
                      id="dashboard.LineText"
                      defaultMessage="Line Text"
                    />
                  ) : (
                    <FormattedMessage
                      id="dashboard.ModifiedName"
                      defaultMessage="Modified Name"
                    />
                  )}
                </div>
                <div className="group_control_in">
                  <input
                    type="text"
                    placeholder={
                      props.type === "lineInfo"
                        ? props.intl.formatMessage({
                            id: "dashboard.LineText",
                            defaultMessage: "Line Text",
                          })
                        : props.intl.formatMessage({
                            id: "dashboard.ModifiedName",
                            defaultMessage: "Modified Name",
                          })
                    }
                    className="form_control"
                    name="pr_content"
                    value={infoInput.pr_content}
                    onChange={handleChange}
                    disabled={
                      props.usrCodeDisable ? props.usrCodeDisable : false
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-outline-dark "
              data-dismiss="modal"
              aria-label="Close"
              ref={refCloseModal}
            >
              <FormattedMessage
                id="dashboard.Cancel"
                defaultMessage="Cancel" />
            </button>
            <button
              type="button"
              className="btn btn-dark btn_add"
              onClick={handleClick}
            >
              <FormattedMessage id="dashboard.Add" defaultMessage="Add" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default injectIntl(AddModalComponent);
