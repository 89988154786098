import React, {Fragment} from 'react';
import Pagination from "react-js-pagination";
import _ from 'lodash';
import ModalDelete from "./Modals/ModalDelete";


class InfoMastNoPagination extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            paginationInfoMastDetail : {
                itemsCountPerPage : 7, // tong item cua 1 phan trang
                pageRangeDisplayed : 3, // do keo dai cua 1 phan trang ex: 123....456
                activePage : 1, // active cua phan trang
                totalItemsCount : 0, // du lieu co may item,
                data : [], // xu li data de phan trang data xet lai state: infoMastDetail ( infoMastDetail.info_detail )
            },

            infoMastDetail : [], // dữ liệu khi click item info mast

            // dữ liệu này là dữ liệu call_detail của redux call
            // khi click item infoMastDetail se show du lieu neu thay doi se thay doi trong redux call : info_detail
            callDetailResult : [],
            itemModalDetailDelete : false, // modal delete
            itemTemporarilySave : null, // item luu tam mo modal se setState con dong modal se null
        }
    }



    // du item infomast detail
    onClickInfoMastDetail = (infoMastDetail) => {
        this.setState({
            infoMastDetail : infoMastDetail.info_detail_with_counter
        })
    }

    // xu li change call_detail set lai vao redux call
    changeCallDetail = (detail) => {
        let {call_detail} = this.props.call;
        let check = true
				//console.log('call_detail.m_id:',call_detail);
        if(call_detail.length > 0) {
            call_detail.map((item,index) => {
								//console.log('item.m_id:',item);
                if(detail.m_id == item.call_m_id){
										//console.log('detail.m_id:',detail);
										// 알람이다.. 처리하지 못한다.
										if (parseInt(detail.m_dtl_limit) > 0 && parseInt(detail.m_dtl_limit)<=parseInt(detail.m_dtl_count)) {
											let disabled = true;
											alert("더이상 선택할수 없습니다.");
											check = false;
											return false;
										}
                    check = false;
                    call_detail[index] = {
                        call_m_id : detail.m_id,
                        call_m_dtl_id : detail.m_dtl_id,
                        call_dtl_name : detail.m_dtl_name
                    }
                }
            });
        }
        if(check){
            call_detail.push({
                call_m_id : detail.m_id,
                call_m_dtl_id : detail.m_dtl_id,
                call_dtl_name : detail.m_dtl_name
            })
        }

        let data = {
            call_detail : call_detail
        }

        this.props.onChangeCallDetail(data)
    }

    onClickDeleteItemDetail = detail => {
        if(detail){
            this.setState({
                itemTemporarilySave : detail
            },()=>{
                this.itemModalDetailDeleteAction();
            })
        }
    }

    itemModalDetailDeleteAction = () => {
        this.setState({
            itemModalDetailDelete : !this.state.itemModalDetailDelete,
        })
    }

    deleteDetailItem = detail => {
        let {call_detail} = this.props.call;
        if(detail){
            call_detail.map((item,index) => {
                if(item.call_m_id ==detail.call_m_id){
                    call_detail.splice(index,1)
                }
            })
        }
        let data = {
            call_detail : call_detail
        }
        this.props.onChangeCallDetail(data);
        this.itemModalDetailDeleteAction(); // an modal
    }

    render() {
        let {
            infoMastDetail, // du lieu co khi click item info mast
            itemModalDetailDelete,
            itemTemporarilySave,// luu tam dong modal se null

        } = this.state;

        let {infoMasts} = this.props;
        const {call_detail} = this.props.call;

        return(
            <React.Fragment>
                <div className="col-lg-12">
                    <div className="box_sum_card">
                        <div className="card_box_item">
                            <div className="custom_table table_scroll">
                                <table className="table st_table_2">
                                    <thead>
                                    <tr>
                                        <th>상담항목</th>
                                        <th>선택값</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {infoMasts.map((item,index) => {
                                        // console.log(item,'vkl')
                                        // them call_detail vao du lieu de map ra
                                        item['call_detail'] = null;
                                        if(call_detail.length > 0){
                                            call_detail.map(detail =>{
                                                if(item.m_id == detail.call_m_id){
                                                    item['call_detail'] = detail
                                                }
                                            })
                                        }

                                        return item.m_show == 1 ? (
                                            <tr
                                                key={index}
                                            >
                                                <td
                                                    onClick={()=>this.onClickInfoMastDetail(item)}
                                                    className={infoMastDetail.m_id == item.m_id ? 'active' : ''}
                                                >
                                                    {item.m_name}
                                                </td>
                                                {item.call_detail
                                                    ? <td onClick={()=>this.onClickInfoMastDetail(item)}>{item.call_detail.call_dtl_name}</td>

                                                    : <td onClick={()=>this.onClickInfoMastDetail(item)}></td>}

                                                <td
                                                    className="inline_action"
                                                    onClick={()=>this.onClickDeleteItemDetail(item.call_detail)}
                                                >
                                                    <i className="fas fa-trash-alt"></i>
                                                </td>
                                            </tr>
                                        ):""
                                    })}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="card_box_item">
                            <div className="custom_table table_scroll">
                                <table className="table st_table_2">
                                    <thead>
                                    <tr>
                                        <th style={{width:'80%'}}>세부항목</th>
                                        <th className="inline_action" style={{width:'20%'}}>추가정보</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {infoMastDetail.map((item,index) => {
																				let disabled = false;
																				if (parseInt(item.m_dtl_limit) > 0 && parseInt(item.m_dtl_limit)<=parseInt(item.m_dtl_count)) disabled = true;
																				//console.log('limit',parseInt(item.m_dtl_limit)); 
																				//console.log('count',parseInt(item.m_dtl_count)); 
                                        return (
                                            <tr
                                                key={index}
                                                // xu li change call_detail cua redux call
                                                onClick={()=>this.changeCallDetail(item)}
                                            >
                                                <td>{ item.m_dtl_name }</td>
																								<td className="inline_action" style={ disabled ? {color:'red'} : {color:'blue'} }>{ item.m_dtl_limit > 0 ? item.m_dtl_count+' / '+item.m_dtl_limit : '' }</td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalDelete
                    isOpen={itemModalDetailDelete}
                    toggle={this.itemModalDetailDeleteAction}
                    item={itemTemporarilySave} // truyen id vao de xoa
                    deleteItem={this.deleteDetailItem} // function de lay id
                />
            </React.Fragment>
        )
    }
}

export default InfoMastNoPagination;