import React, {useState,useRef} from 'react';
import * as apipostimageatalk from "./../../../../utils/apipostimageatalk";
import _ from 'lodash';
function ItemMessage ({
                          message,
                          accessToken,
                          changeValueMessage,
                          saveDirectMessage, //save direct cua message
}) {
    let fileInput = useRef();

		const [file, setFile] = useState();
    const [totalLength, setTotalLength] = useState();

    if(message && message.type === 'direct'){ // co the chinh sua

//				var _byte=0;
//				var str = message.content ? message.content : '';
//				var charStr='';
//
//				for(var i=0;i<str.length;i++){
//						charStr=str.charAt(i);
//						if(escape(charStr).length>4){
//								_byte+=2;
//						}else{
//								_byte++;
//						}
//				}
//				setTotalLength(_byte);

        return (
            <div className="box_right">
                <div className="box_textarea">
								{totalLength} Bytes
                <textarea
                    name={'content'}
										rows="10"
                    value={message.content ? message.content : ''}
                    onChange={(e)=>{

                        var maxlength = 90 ; /// sms chi co 90 ki tu
                        var _byte=0;
                        var str= e.target.value;
                        var charStr='';
                        var finalStr='';

                        for(var i=0;i<str.length;i++){
                            charStr=str.charAt(i);
                            if(escape(charStr).length>4){
                                _byte+=2;
                            }else{
                                _byte++;
                            }
														if (_byte > 1999) break;
														else finalStr += charStr;
                        }

												setTotalLength(_byte);
                        if(_byte < maxlength){
                            changeValueMessage({
                                content : finalStr,
																send_type : 0
                            })
                        } else {
                            if(_byte <= 2000){
                                changeValueMessage({
                                    content : finalStr,
                                    send_type : 2
                                })
                            }
                        }
//                        for(var i=0;i<str.length;i++){
//													charStr=str.charAt(i);
//													if(escape(charStr).length>4){
//															_byte+=2;
//													}else{
//															_byte++;
//													}
//                        }
												
                    }}
                />
                </div>

                <div className="select_box">
                    <select
                        value={message.send_type}
                        onChange={(e)=>{
                            changeValueMessage({
                                send_type : e.target.value
                            })

                        }}
                    >
                        <option value="0">SMS</option>
                        <option value="2">LMS</option>
                        <option value="1">MMS</option>
                    </select>
                    <span className="dropdown"><i className="fas fa-angle-down" /></span>
                </div>


								<div className="box_upload_file">
										<div className="box_input">
												<input
														type="file"
														className="add_file"
														id="add_file"
														multiple={false}
														disabled={message.image_messages.length === 3 ? true : false}
														onChange={(event)=>{
																let f = event.target.files;
																if(f && f.length > 0){
																		setFile(f[0]);
																		fileInput.value = "";
																}
														}}
														ref={(ref) => (fileInput = ref)}
												/>
												<label htmlFor="add_file" className="lable_input_file">{file ? file.name : 'Choose file ...'}</label>
										</div>
										<button
												type="button"
												className="btn_001"
												onClick={()=>{
														if(file){
																apipostimageatalk.sendCrmSmsImage(file,accessToken).then(res => {
																		let imagePush = message.image_messages;
																		imagePush.push({
																				image_name : res.file_name,
																				image_path : `https://rtc.goodfone.co.kr/mms/${res.file_name}`
																		});
																		changeValueMessage({
																				image_messages : imagePush,
																				send_type : 1
																		})

																		setFile(null);// set file null
																});
														}
												}}
												disabled={message.image_messages.length === 3 ? true : false}
										>Add</button>
								</div>

								<div className="box_list_images">
										{message.image_messages.length > 0 && message.image_messages.map((image,index) => {
												return (
														<div className="item_file" key={index}>
										<span
												className="close"
												onClick={(e)=> {
														var itemSetImage = message.image_messages;
														_.remove(itemSetImage,function (n) {
																return n.image_name == image.image_name;
														})
														changeValueMessage({
																image_messages : itemSetImage
														})
												}}
										>
												<i className="fas fa-times" />
										</span>
																<div className="img">
																		<img src={image.image_path} />
																</div>
														</div>
												)
										})}
								</div>


                <div className="box_btn_save">
                    <button
                        type="button"
                        className="btn_save"
                        onClick={()=>saveDirectMessage(message)}
                    >Save</button>
                </div>
            </div>
        )
    }

    // khong the chinh sua
    return (
        <div className="box_right">
            <div className="box_textarea">
                <textarea
                    disabled
										rows="10"
                    value={message.content}
                    onChange={(e)=>{
                        var maxlength = 90 ; /// sms chi co 90 ki tu
                        var _byte=0;
                        var str= message.content;
                        var charStr='';
                        for(var i=0;i<str.length;i++){
                            charStr=str.charAt(i);
                            if(escape(charStr).length>4){
                                _byte+=2;
                            }else{
                                _byte++;
                            }
                        }
												setTotalLength(_byte);
                    }}

                />
            </div>
            <div className="select_box">
                <select
                    disabled
                    value={message.send_type}
                >
                    <option value="0">SMS</option>
                    <option value="2">LMS</option>
                    <option value="1">MMS</option>
                </select>
                <span className="dropdown"><i className="fas fa-angle-down" /></span>
            </div>
            <div className="box_list_images">
                {message.image_messages.map((image,index) => {
                    return (
                        <div className="item_file" key={index}>
                            {/*<span className="close"><i className="fas fa-times" /></span>*/}
                            <div className="img">
                                <img src={image.image_path} />
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )

}

export default ItemMessage