import XLSX from 'xlsx'

function Workbook() {
    if (!(this instanceof Workbook))
        return new Workbook()

    this.SheetNames = []

    this.Sheets = {}
}

const download = (url, name) => {
    let a = document.createElement('a')
    a.href = url
    a.download = name
    a.click()

    window.URL.revokeObjectURL(url)
}


function s2ab(s) {
    const buf = new ArrayBuffer(s.length)

    const view = new Uint8Array(buf)

    for (let i=0; i !== s.length; ++i)
        view[i] = s.charCodeAt(i) & 0xFF
    // console.log(buf)
    return buf
}

export default (data, name) => {
    // import('xlsx').then(XLSX => {
    //     let  wb = new Workbook()
        let  ws = XLSX.utils.json_to_sheet(data)
        let wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        // wb.SheetNames.push('')
        // wb.Sheets[''] = ws


        const wbout = XLSX.write(wb, {bookType:'xlsx', bookSST:true, type: 'binary'})


        // let url = window.URL.createObjectURL(new Blob([s2ab(wbout)], {type:'application/octet-stream'}))
        let url = window.URL.createObjectURL(new Blob([s2ab(wbout)], {type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'}))

        download(url, name + '.xlsx')
    // })
}
