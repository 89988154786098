import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
// import 'react-datepicker/dist/react-datepicker.css';
import { FormattedMessage, injectIntl } from "react-intl";
import { Card } from 'reactstrap';
import HasRole from "../../utils/entrust/HasRole";
import { NavLink, Link } from 'react-router-dom';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer  } from 'recharts';
import queryString from "query-string";
import api from "../../utils/api";
import progressBar from "../../utils/progressBar";
import * as routerName from "../../utils/routerName";
import * as helper from "../../utils/helpers";
import { Helmet } from 'react-helmet';
import Pagination from "react-js-pagination";
import ContentHomeTable from "../../components/Home/ContentHomeTable";
import ContentMobileAdmin from "../../components/Home/ContentMobileAdmin";
import NoticeModal from '../../components/NoticeModal/NoticeModal';
import {hasRole} from "./../../utils/entrust/entrust";
import {CHANGE_SCENE_COMPANY, CHANGE_SCENE_LOT} from "../../store/type";

class Home extends Component {
    constructor(props) {
        super(props);
				console.log('props:',props);
				console.log('hasRole:',hasRole(props.auth.user, ['admin', 'primarynet']));
        this.state = {
            startDate: hasRole(props.auth.user, ['admin', 'primarynet']) ? moment().startOf('day') : moment().subtract(7, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            endDate: hasRole(props.auth.user, ['admin', 'primarynet']) ? moment().startOf('hour').add(1, 'hour') : moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'), 
            // startDate: moment('2021-02-06').format('YYYY-MM-DD'),
            // endDate: moment('2021-03-10').format('YYYY-MM-DD'),
            keyword:'',

            activePage: 10,
            currentType: '',
            historyList: {
                current_page: 1,
                total: 0,
            },
            dataChart: null,
            statistics: [], 

            // Table History
            columns: [
                { name: 'time', title: 'Time' },
                { name: 'received', title: 'Received Calls' },
                { name: 'sending', title: 'Sending Calls' },
                { name: 'answered', title: 'Unanswered Calls' },
                { name: 'total', title: 'Total Calls' }
            ],
            fixedColumns: ['time'],
            rows: [],

            // Notification
            notification: {
                isAlert: false,
                title: '',
                message: '',
                level: '',
                position: ''
            },

						isNoticeModalOpen: false,
						notices: [],

						width: window.innerWidth,

            sel_company_id: localStorage.getItem('sel_company_id'),
            sel_lots_id: localStorage.getItem('sel_lots_id'),

            // lam moi
            historyListV2 : [],
            dataChartV2 : []


        }
    }

    openNoticeModal = () => {
        this.setState({
            isNoticeModalOpen: !this.state.isNoticeModalOpen
        });
    };

		onNoticeConfirm = () => {

				progressBar.start();
				// 조회수 처리 ?
				//console.log('params:',params);
        api.notice.view().then(response => {

						this.addNotification({
								title: <FormattedMessage
										id="dashboard.datasetting.notice"
										defaultMessage="Notice send"/>,
								message: <FormattedMessage
										id="dashboard.notice.send.success"
										defaultMessage="Notice successfully!"/>,
								level: 'success',
						})

            progressBar.done();

        }).catch(error => { 
					
						this.addNotification({
								title: <FormattedMessage
										id="dashboard.datasetting.notice"
										defaultMessage="Notice send"/>,
								message: <FormattedMessage
										id="dashboard.notice.send.fail"
										defaultMessage="Notice failed!"/>,
								level: 'error',
						})

            progressBar.done();
        });
    };
//
//    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
//				console.log('nextProps:',nextProps);
//        if(nextProps.isChangeScene && nextProps.sceneLostId !== this.state.sceneLostId && nextProps.typeOfChange === CHANGE_SCENE_LOT) {
//            this.setState({
//                sceneLostId: nextProps.sceneLostId,
//            }, () => {
//            })
//        }
//        if(nextProps.isChangeScene && nextProps.sceneCompanyId !== this.state.sceneCompanyId && nextProps.typeOfChange === CHANGE_SCENE_COMPANY) {
//            this.setState({
//                sceneCompanyId: nextProps.sceneCompanyId,
//            });
//				}
//    }

		componentWillUnmount() {
			window.removeEventListener('resize', this.handleWindowSizeChange);
		};

		handleWindowSizeChange = () => {
			this.setState({ width: window.innerWidth });
		};

    componentDidMount() {
        // let data = {
        //     startDate: this.state.startDate,
        //     endDate: this.state.endDate,
        // };
        // let condTable = {
        //     ...data,
        //     type: 'time',
        // };
        // let condChart = {
        //     ...data,
        //     type: 'day',
        //     forChart: true
        // };

				window.addEventListener('resize', this.handleWindowSizeChange);

        let data = {
            // startDate: moment(this.state.startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            // endDate: moment(this.state.endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
            startDate: this.state.startDate,
            endDate: this.state.endDate,
        };
        let condTable = {
            ...data,
            type: 'time',
        };
        let condChart = {
            ...data,
            type: 'day',
            forChart: true
        };


        progressBar.start();

				//console.log('공지사항이 있다면 여기서 보여준다 ?');
				this.checkNotice();

        // this.getCallHistory(condTable, 'table');
        // this.getCallHistory(condChart, 'chart');
        this.getIndex();
        // lam moi

        this.getCallHistoryV2();
        this.getCallHistoryChartV2();
    }

    checkNotice = () => {
        api.notice.check().then(res => {
					console.log('res:',res);
          this.setState({
            notices : res.data.data
          }, () => {
							if (res.data?.data?.length > 0) // 공지사항이 존재 한다.
							{
								this.openNoticeModal(); // Open
							}
          }); // notices ?
        }).catch(error => {
            //progressBar.done();
        });
    }

    getCallHistoryV2 = () => {

				let {startDate,endDate,keyword,sel_lots_id,sel_company_id} = this.state;
        let query = queryString.stringify({
            startDate: moment(startDate).format('YYYY-MM-DD'),
            endDate: moment(endDate).format('YYYY-MM-DD'),
            keyword : keyword,
            sceneLostId: localStorage.getItem('sel_lots_id'),
            sceneCompanyId: localStorage.getItem('sel_company_id'),
        });
						console.log('query:',query);
        api.callList.callHistoryV2(query).then(res => {
            this.setState({
                historyListV2 : res.data.data
            })
        })

    }

    getCallHistoryChartV2 = () => {
        let query = queryString.stringify({
            startDate: moment(this.state.startDate).format('YYYY-MM-DD'),
            endDate: moment(this.state.endDate).format('YYYY-MM-DD'),
        });
        api.callList.callHistoryChartV2(query).then(res => {
            this.setState({
                dataChartV2 : res.data.data
            })
        })

    }


    // Number of calls by hour (TABLE) || DAY (CHART) for the last 7 days
    getCallHistory = (cond, showType = 'table') => {
        let query = queryString.stringify(cond);
        api.callList.callHistory(query).then(response => {
            // console.log(response, 'response2');

            switch (showType) {
                case 'table':
                    this.setState({
                        ...this.state,                     
                        historyList: response?.data?.data,
                    }, () => {
                    });
                    break;
                default:
                    this.setState({
                        ...this.state,
                        dataChart: response?.data?.data || [],
                    });
                    break;
            }

            progressBar.done();
        }).catch(error => {
            progressBar.done();
        });
    }

    // show box statistic GENERAL
    getIndex = () => {
        api.dashboard.getIndex().then(response => {
            this.setState({
                statistics: response?.data?.data || []
            });

            this.setState({
                notification: {
                    isAlert: true,
                    title: <FormattedMessage
                        id="dashboard.Error"
                        defaultMessage="Error" />,
                    message: <FormattedMessage
                        id="dashboard.ErrorMessage"
                        defaultMessage="Sorry! Something wen't wrong. Retry!" />,
                    level: 'error',
                    position: 'tr',
                    allowHTML: false
                }
            });

            progressBar.done();
        }).catch(error => {
            this.setState({
                notification: {
                    isAlert: true,
                    title: <FormattedMessage
                        id="dashboard.Error"
                        defaultMessage="Error" />,
                    message: <FormattedMessage
                        id="dashboard.ErrorMessage"
                        defaultMessage="Sorry! Something wen't wrong. Retry!" />,
                    level: 'error',
                    position: 'tr',
                    allowHTML: false
                }
            });
            progressBar.done();
        });;
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.intl) {
            return {
                columns: [
                    { name: 'time', title: nextProps.intl.formatMessage({ id: 'dashboard.Time', defaultMessage: 'Time' }) },
                    {
                        name: 'received',
                        title: nextProps.intl.formatMessage({
                            id: 'dashboard.Home.Received',
                            defaultMessage: 'Received Calls'
                        })
                    },
                    {
                        name: 'sending',
                        title: nextProps.intl.formatMessage({
                            id: 'dashboard.Home.SendingCalls',
                            defaultMessage: 'Sending Calls'
                        })
                    },
                    {
                        name: 'answered',
                        title: nextProps.intl.formatMessage({
                            id: 'dashboard.Home.UnansweredCalls',
                            defaultMessage: 'Unanswered Calls'
                        })
                    },
                    {
                        name: 'total',
                        title: nextProps.intl.formatMessage({ id: 'dashboard.TotalCalls', defaultMessage: 'Total Calls' })
                    },
                ],
            }
        }
        return null;
    }

    fixedFormatter = value => {
        return (
            <Fragment>
                {value + 'h'}
            </Fragment>
        )
    };

    handlePageChange = (pageNumber) => {
        let condTable = {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            type: 'time',
            page: pageNumber
        };
        this.getCallHistory(condTable, 'table');
    }

    handleSearch = (event) => {
        let keyword = event.target.value;
        this.setState({
            keyword,
        }, () => {
            this.getCallHistoryV2();
        })
    }

    handleAdminSearch = (startDate,endDate) => {
        let keyword = "";
        this.setState({
            startDate: startDate,
            endDate: endDate,
            keyword,
        }, () => {
            this.getCallHistoryV2();
        })
    }

		render() {
        const {
            dataChart,
            statistics,
            historyList,
            historyListV2,
            keyword,
            dataChartV2,
						width
        } = this.state;
        let user = this.props.auth?.user;

			  const isMobile = width <= 500;

        //render chart
        let graphChart = null;
        if (dataChartV2.length) {
            graphChart = (
                <ResponsiveContainer width="97%" height={400}>
                    <LineChart width={900} data={dataChartV2} style={{marginTop: 10}}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="time" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        {
                            dataChartV2?.length > 0 && Object.keys(dataChartV2[0]).map((key, i) => {
                                if (key !== 'time') {
                                    return (<Line key={key}
                                        type="monotone"
                                        dataKey={key}
                                        activeDot={{ r: 8 }}
                                        stroke='green' />)
                                }
                                return null
                            })
                        }
                    </LineChart>
                </ResponsiveContainer>
            );
        }


        return (
            <Fragment>
                <FormattedMessage id="dashboard.dashboard"
                    defaultMessage="Dashboard">
                    {message => <Helmet>
                        <title>{message}</title>
                    </Helmet>}
                </FormattedMessage>

								<div className="main_top_bar">
									<div className="container-fluid">
											<span className="txt_text"><FormattedMessage id="dashboard.Welcome" defaultMessage="Welcome" /></span>
									</div>
								</div>

                <div className="main_content">

										{ (isMobile) ?
												(
												<div className="">
													<HasRole role={['admin', 'primarynet']}>
															<ContentMobileAdmin
																	historyListV2={historyListV2}
																	handleAdminSearch={this.handleAdminSearch}
																	keyword={keyword}
															/>
													</HasRole>
												</div>
												) : ""
										}

                    <div className="left_col">
                        <div className="box_count">
                            <div className="heading"><FormattedMessage id="dashboard.Informations" defaultMessage="Informations" /></div>
                            <div className="content">
                                <div className="custom_table">
                                    <table className="table">
                                        <tbody>
                                            <tr>
                                                <th><FormattedMessage id="dashboard.Name" defaultMessage="Name" /></th>
                                                <td>{user && user.usr_code ? user.usr_name : ''}</td>
                                            </tr>
                                            <tr>
                                                <th><FormattedMessage id="dashboard.Level" defaultMessage="Level" /></th>
                                                <td>{user && user.roles ? user.roles[0].name : ''}</td>
                                            </tr>
                                            <tr>
                                                <th><FormattedMessage id="dashboard.Company" defaultMessage="Company" /></th>
                                                <td>{user?.info_sale_lot?.info_company?.company_name}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content_col">
                        <div className="dashboard_box">
                            <div className="list_box">
                                <HasRole role={'primarynet'}>
                                    <div className=" item_box item_box_1">
                                        <div className="box_content shadow">
                                            <div className="box_top">
                                                <div className="inner">
                                                    <span className="txt_1"> {statistics?.users || 0} </span>
                                                    <span className="txt_2">
                                                        <FormattedMessage
                                                            id='dashboard.Users'
                                                            defaultMessage='Users'
                                                        />
                                                    </span>
                                                </div>
                                                <div className="icon">
                                                    <i className="fas fa-users" />
                                                </div>
                                            </div>
                                            <div className="box_footer">
                                                <NavLink to={`/${routerName.DASHBOARD}/${routerName.USER_REGISTRATION}`}>
                                                    <FormattedMessage
                                                        id="dashboard.MoreInfo"
                                                        defaultMessage="More info" />
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </HasRole>

                                <HasRole role={['primarynet', 'admin']}>
                                    <div className=" item_box item_box_2">
                                        <div className="box_content shadow">
                                            <div className="box_top">
                                                <div className="inner">
                                                    <span className="txt_1"> {statistics?.customers || 0} </span>
                                                    <span className="txt_2">
                                                        <FormattedMessage
                                                            id='dashboard.Customers'
                                                            defaultMessage='Customers'
                                                        />
                                                    </span>
                                                </div>
                                                <div className="icon">
                                                    <i className="fas fa-address-card" />
                                                </div>
                                            </div>
                                            <div className="box_footer">
                                                <HasRole role={'primarynet'}>
                                                    <NavLink to={`/${routerName.DASHBOARD}/${routerName.CUSTOMER_LIST}`}>
                                                        <FormattedMessage
                                                            id="dashboard.MoreInfo"
                                                            defaultMessage="More info" />
                                                    </NavLink>
                                                </HasRole>
                                                <HasRole role={'admin'}>
                                                    <NavLink to={`/${routerName.DASHBOARD}/${routerName.CUSTOMER_MANAGEMENT}/${routerName.CUSTOMER_DATA}`}>
                                                        <FormattedMessage
                                                            id="dashboard.MoreInfo"
                                                            defaultMessage="More info" />
                                                    </NavLink>
                                                </HasRole>
                                            </div>
                                        </div>
                                    </div>
                                </HasRole>

                                <HasRole role={['primarynet']}>
                                    <div className=" item_box item_box_3">
                                        <div className="box_content shadow">
                                            <div className="box_top">
                                                <div className="inner">
                                                    <span className="txt_1"> {statistics?.companies || 0} </span>
                                                    <span className="txt_2">
                                                        <FormattedMessage
                                                            id='dashboard.Companies'
                                                            defaultMessage='Company'
                                                        />
                                                    </span>
                                                </div>
                                                <div className="icon">
                                                    <i className="fas fa-building" />
                                                </div>
                                            </div>
                                            <div className="box_footer">
                                                <NavLink to={`/${routerName.DASHBOARD}/${routerName.DATA_SETTING}/${routerName.COMPANY_REGISTRATION}`}>
                                                    <FormattedMessage
                                                        id="dashboard.MoreInfo"
                                                        defaultMessage="More info" />
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </HasRole>

                                <HasRole role={['primarynet', 'admin']}>
                                    <div className=" item_box item_box_4">
                                        <div className="box_content shadow">
                                            <div className="box_top">
                                                <div className="inner">
                                                    <span className="txt_1"> {statistics?.saleLots || 0} </span>
                                                    <span className="txt_2">
                                                        <FormattedMessage
                                                            id='dashboard.AffiliatedCompanies'
                                                            defaultMessage='Affiliated Companies'
                                                        />
                                                    </span>
                                                </div>
                                                <div className="icon">
                                                    <i className="fas fa-globe" />
                                                </div>
                                            </div>
                                            <div className="box_footer">
                                                <HasRole role={['primarynet']}>
                                                    <NavLink
                                                        to={`/${routerName.DASHBOARD}/${routerName.DATA_SETTING}/${routerName.COMPANY_REGISTRATION}`}>
                                                        <FormattedMessage
                                                            id="dashboard.MoreInfo"
                                                            defaultMessage="More info" />
                                                    </NavLink>
                                                </HasRole>
                                                <HasRole role={['admin']}>
                                                    <NavLink
                                                        to={`/${routerName.DASHBOARD}/${routerName.DATA_SETTING}/${routerName.AFFILITED_COMPANY_MANAGEMENT}`}>
                                                        <FormattedMessage
                                                            id="dashboard.MoreInfo"
                                                            defaultMessage="More info" />
                                                    </NavLink>
                                                </HasRole>
                                            </div>
                                        </div>
                                    </div>
                                </HasRole>
                            </div>


                            <HasRole role={['manager', 'telephone', 'visiting']}>
                                <div className="row_table_custom">
                                    <div className="heading">
                                        <div className="txt_heading">
                                            <FormattedMessage
                                                id='dashboard.StatisticsByHour'
                                                defaultMessage='Number of calls by hour for the last 7 days'
                                            />
                                        </div>
                                    </div>


                                    <ContentHomeTable
                                        historyListV2={historyListV2}
                                        handleSearch={this.handleSearch}
                                        keyword={keyword}
                                    />

                                </div>

                                <div className="row_table_custom">
                                    <div className="heading">
                                        <div className="txt_heading">
                                            <FormattedMessage
                                                id='dashboard.StatisticsByDay'
                                                defaultMessage='Number of calls by day for the last 7 days'
                                            />
                                        </div>
                                    </div>
                                    <div className="content report_graph">
                                        <div className="box_report_graph">
                                            {graphChart}
                                        </div>
                                    </div>
                                </div> 
                            </HasRole>
                        </div>
                    </div>
                </div>

                <NoticeModal
                    isNoticeModalOpen={this.state.isNoticeModalOpen}
                    openNoticeModal={this.openNoticeModal}
                    title="공지사항"
                    body={this.state.notices}
                    onNoticeConfirm={this.onConfirm}
                />

            </Fragment>


        )
    }
}

const mapStateToProps = state => {
    // console.log(state, 'stateee');
    return {
        auth: state.auth,
        sel_company_id: state.company_id,
        sel_lots_id: state.lots_id,
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
