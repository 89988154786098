import {
    FETCH_CALL_LIST,
    FETCH_CALL_LIST_SUCCEED,
    FETCH_CALL_LIST_FAILED,
    DELETE_CALL,
    DELETE_CALL_SUCCEED,
    DELETE_CALL_FAILED,
    FETCH_CALLS_BY_USER,
    FETCH_CALLS_BY_USER_SUCCEED,
    FETCH_CALLS_BY_USER_FAILED, 
    UNMOUNT_CALL_LIST,
    SEARCH_CUSTOMER_DATA,
    EXPORT_CUSTOMER_DATA,
    IMPORT_CUSTOMER_DATA,
    FETCH_ANALYSIS_DATA,
    FETCH_ANALYSIS_DATA_SUCCEED,
    FETCH_ANALYSIS_DATA_FAILED,
    // FETCH_CALLS_BY_USER,
    // FETCH_CALLS_BY_USER_SUCCEED,
    // FETCH_CALLS_BY_USER_FAILED, 
    // FETCH_ANALYSIS_DATA,
    // FETCH_ANALYSIS_DATA_SUCCEED,
    // FETCH_ANALYSIS_DATA_FAILED,
} from '../actions/actionTypes';
import api from "../../utils/api";


export const fetchCalls = type => {
    return {
        type: FETCH_CALL_LIST,
        payload: {
            type: type
        }
    }
};

export const fetchCallsSucceed = data => {
    return {
        type: FETCH_CALL_LIST_SUCCEED,
        payload: {
            data: data
        }
    }
};

export const fetchCallsFailed = error => {
    return {
        type: FETCH_CALL_LIST_FAILED,
        payload: {
            error: error
        }
    }
};

export const deleteCall = id => {
    return {
        type: DELETE_CALL,
        payload: {
            id: id
        }
    }
};

export const deleteCallSucceed = data => {
    return {
        type: DELETE_CALL_SUCCEED,
        payload: {
            data: data
        }
    }
};

export const deleteCallFailed = error => {
    return {
        type: DELETE_CALL_FAILED,
        payload: {
            error: error
        }
    }
};

export const fetchCallsByUser = type => {
    return {
        type: FETCH_CALLS_BY_USER,
        payload: {
            type: type
        }
    }
};

export const fetchCallsByUserSucceed = data => {
    return {
        type: FETCH_CALLS_BY_USER_SUCCEED,
        payload: {
            data: data
        }
    }
};

export const fetchCallsByUserFailed = error => {
    return {
        type: FETCH_CALLS_BY_USER_FAILED,
        payload: {
            error: error
        }
    }
};

export const unmountCallList = () => {
    return {
        type: UNMOUNT_CALL_LIST
    }
};

export const searchByName = data => {
    return {
        type: SEARCH_CUSTOMER_DATA,
        payload: {
            data: data
        }
    }
};
export const exportCustomerData = data => {
    return {
        type: EXPORT_CUSTOMER_DATA,
        payload: {
            data: data
        }
    }
};
export const importCustomerData = data => {
    return {
        type: IMPORT_CUSTOMER_DATA,
        payload: {
            data: data
        }
    }
};

export const fetchAnalysis = type => {
    return {
        type: FETCH_ANALYSIS_DATA,
        payload: {
            type: type
        }
    }
};

export const fetchAnalysisSucceed = data => {
    return {
        type: FETCH_ANALYSIS_DATA_SUCCEED,
        payload: {
            data: data
        }
    }
};

export const fetchAnalysisFailed = error => {
    return {
        type: FETCH_ANALYSIS_DATA_FAILED,
        payload: {
            error: error
        }
    }
};

export const fetchStatisticsGraph = () => {
    return {
        type: FETCH_ANALYSIS_DATA,
    }
};

export const fetchStatisticsGraphSucceed = data => {
    return {
        type: FETCH_ANALYSIS_DATA_SUCCEED,
        payload: {
            data: data
        }
    }
};

export const fetchStatisticsGraphFailed = error => {
    return {
        type: FETCH_ANALYSIS_DATA_FAILED,
        payload: {
            error: error
        }
    }
};

export const exportCallListAction = (query) => dispatch => {
    dispatch(exportCustomerData());
    return api.customerManagement.getExportCustomerData(query)
        .then(response => {
        let token = window.localStorage.getItem('token');
        let url = process.env.NODE_ENV === 'development' ?
            process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD;
        return window.location.href =   url + `/customer-manage/export-customer-data?${query}&token=` + token;
        })
        .catch(error => {
            dispatch(fetchCallsFailed(error.response.data.errors.message));
            return Promise.reject(error.response)
        });
};
export const importCallListAction = (data) => dispatch => {
    dispatch(importCustomerData());
    return api.customerManagement.import(data)
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            dispatch(fetchCallsFailed(error.response.data.errors.message));
            return Promise.reject(error.response)
        });
};


export const fetchCallListAction = () => dispatch => {
    dispatch(fetchCalls());
    return api.callList.getCallList()
        .then(response => {
            dispatch(fetchCallsSucceed(response.data.data.data));
            return Promise.resolve(response.data);
        })
        .catch(error => {
            dispatch(fetchCallsFailed(error.response.data.errors.message));
            return Promise.reject(error.response)
        });
};
// // export const importDataAction = data => () =>
// //     api.customerManagement.import(data).then(response =>
// //         Promise.resolve(response.data)
// //     ).catch(error =>
// //         Promise.reject(error.response)
// //     )



// export const fetchCallListAction = () => dispatch => {
//     dispatch(fetchCalls());
//     return api.callList.getCallList()
//         .then(response => {
//             dispatch(fetchCallsSucceed(response.data.data.data));
//             return Promise.resolve(response.data);
//         })
//         .catch(error => {
//             dispatch(fetchCallsFailed(error.response.data.errors.message));
//             return Promise.reject(error.response)
//         });
// };

export const searchByNameAction = query => dispatch => {
    dispatch(searchByName());
    return api.customerManagement.getSearchCustomerData(query).then(response => {
        dispatch(fetchCallsSucceed(response.data.data.data));
        return Promise.resolve(response.data);
    })
        .catch(error => {
            dispatch(fetchCallsFailed(error.response.data.errors.message));
            return Promise.reject(error.response)
        });
};

/**
* Counselling data
*/
export const fetchCounsellingDataAction = (data) => dispatch => {
    dispatch(fetchCalls());
    return api.customerManagement.getSearchCounsellingData(data)
        .then(response => {
            dispatch(fetchCallsSucceed(response.data.data.data));
            return Promise.resolve(response.data);
        })
        .catch(error => {
            dispatch(fetchCallsFailed(error.response.data.errors.message));
            return Promise.reject(error.response);
        });

}

export const exportCounsellingDataAction = (query) => dispatch => {
    return api.customerManagement.getExportCounsellingData(query)
        .then(response => {
            let token = window.localStorage.getItem('token');
            let url = process.env.NODE_ENV === 'development' ?
                process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD;

            url =  url + `/customer-manage/export-counselling-data?${query}&token=` + token;
            return window.location.href = url;
        })
        .catch(error => {
            dispatch(deleteCallFailed(error.response.data.errors.message));
            return Promise.resolve(error.response);
        })
}

export const sampleDownloadExcelAction = (data) => dispatch => {
		console.log('data:',data);
    return api.customerManagement.getSampleDownloadExcel(data)
        .then(response => {
            let token = window.localStorage.getItem('token');
            let url = process.env.NODE_ENV === 'development' ?
                process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD;
            url =  url + `/customer-manage/sample-download?token=` + token;
            return window.location.href = url;
        })
        .catch(error => {
            dispatch(deleteCallFailed(error.response.data.errors.message));
            return Promise.resolve(error.response);
        })
};

// customer list
export const fetchCustomerListAction = (query) => dispatch => {
    dispatch(fetchCalls());
    return api.callList.getCustomerBySaleLot(query)
        .then(response => {
            dispatch(fetchCallsSucceed(response.data.data));
            return Promise.resolve(response.data);
        })
        .catch(error => {
            dispatch(fetchCallsFailed(error.response.data.errors.message));
            return Promise.reject(error.response);
        });
}

export const exportCustomerAction = (query) => dispatch => {
    return api.callList.exportCustomerList(query)
        .then(response => {
            let token = window.localStorage.getItem('token');
            let url = process.env.NODE_ENV === 'development' ?
                process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD;
            url =  url + `/call-list/export-customer-list?${query}&token=` + token;
            return window.location.href = url;
        })
        .catch(error => {
            dispatch(deleteCallFailed(error.response.data.errors.message));
            return Promise.resolve(error.response);
        })
}

export const exportIVRDataAction = (query) => dispatch => {
    return api.customerManagement.getExportIVRData(query)
        .then(response => {
            let token = window.localStorage.getItem('token');
            let url = process.env.NODE_ENV === 'development' ?
                process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD;

            url =  url + `/customer-manage/export-ivr-data?${query}&token=` + token;
            return window.location.href = url;
        })
        .catch(error => {
            dispatch(deleteCallFailed(error.response.data.errors.message));
            return Promise.resolve(error.response);
        })
}

export const deleteCustomerAction = (query) => dispatch => {
    dispatch(deleteCall());
    return api.callList.deleteCustomer(query)
        .then(response => {
            console.log(response);
            dispatch(deleteCallSucceed(response.data.data));
            return Promise.resolve(response.data);
        })
        .catch(error => {
            if(error.response){
                console.log(error.response);
                dispatch(deleteCallFailed(error.response.data.errors.message));
                return Promise.resolve(error.response);
            }
        })
}
// //statictisc - analysis
// export const fetchAnalysisDataAction = (query) => dispatch => {
//     dispatch(fetchAnalysis());
//     return api.statistics.getAnalysisData(query)
//         .then(response => {
//             dispatch(fetchAnalysisSucceed(response.data.data));
//             return Promise.resolve(response.data);
//         })
//         .catch(error => {
//             dispatch(fetchAnalysisFailed(error.response.data.errors.message));
//             return Promise.reject(error.response);
//         })
// };

export const exportAnalysisAction = (query) => dispatch=> {
    dispatch(fetchAnalysis());
    return api.statistics.getExportAnalysis(query)
        .then(response => {
            let token = window.localStorage.getItem('token');
            let url = process.env.NODE_ENV === 'development' ?
                process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD;
            url =  url + `/statistics/analysis/export-excel?${query}&token=` + token;
            return window.location.href = url;
        })
        .catch(error => {
            dispatch(deleteCallFailed(error.response.data.errors.message));
            return Promise.resolve(error.response);
        })
};

export const fetchStatisticsGraphAction = (data) => dispatch => {
    dispatch(fetchStatisticsGraph());
    return api.statistics.getStatisticsData(data)
        .then(response => {
            dispatch(fetchStatisticsGraphSucceed(response.data.data));
            return Promise.resolve(response.data);
        })
        .catch(error => {
            dispatch(fetchStatisticsGraphFailed(error.response.data.errors.message));
            return Promise.reject(error.response);
        });
};

export const fetchStatisticsGraphPieAction = (data) => dispatch => {
    dispatch(fetchStatisticsGraph());
    return api.statistics.getStatisticsDataPie(data)
        .then(response => {
            dispatch(fetchStatisticsGraphSucceed(response.data.data));
            return Promise.resolve(response.data);
        })
        .catch(error => {
            dispatch(fetchStatisticsGraphFailed(error.response.data.errors.message));
            return Promise.reject(error.response);
        });
};

export const unmountCallListAction = () => dispatch => {
    dispatch(unmountCallList())
};



export const importExcelCustomerAction = (data) => dispatch => {
    return api.customerManagement.importExcelCustomer(data)
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            return Promise.reject(error.response)
        });
};