import React, { Component, Fragment,useEffect,useRef } from 'react';
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Helmet } from 'react-helmet';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';
import querystring from 'querystring'
import {
    fetchCallsAction,
    resetCallListAction, // reset call list = null
} from './../../store/actions/callList';
import {
    fetchCallAction, //get id call
    editCallColumnAction, // chinh sua cot trong redux call
    resetCallAction,
    fetchCreateCallAction, // fetch call khi co cuoc goi moi
    fetchSearchKeyCallAction, setCallAction, // search key
} from './../../store/actions/call';
import moment from "moment";
import ListCall from "../../components/TelephoneCounselling/ListCall";
import Pagination from "react-js-pagination";
import progressBar from '../../utils/progressBar';
import FormCall from "../../components/TelephoneCounselling/FormCall";
import api from "../../utils/api";
import InfoMast from "../../components/TelephoneCounselling/InfoMast";
import {
    searchByCallNumberAction,
    clearSearchCallByNumberAction
} from './../../store/actions/searchCallByNumber'
import ListCalSearchByNumber from "../../components/TelephoneCounselling/ListCallSerchByNumber";
import FunctionButtonCenter from "../../components/TelephoneCounselling/FunctionButtonCenter";
import ItemSummaryBox from "../../components/TelephoneCounselling/ItemSummaryBox";
import NotificationSystem from 'react-notification-system';
import FunctionButtonRight from "../../components/TelephoneCounselling/FunctionButtonRight";
import * as webSocketApi from './../../utils/websocketApi';

import ModalDelete from "../../components/TelephoneCounselling/Modals/ModalDelete";
import {hasRole} from "./../../utils/entrust/entrust";
import ModalReportTelePhone from "../../components/TelephoneCounselling/Modals/ModalReportTelephone";
import {fetchReportTelephoneAction,resetReportTelephoneAction} from './../../store/actions/reportTelephone'
import ModalMessage from "../../components/TelephoneCounselling/Modals/ModalMessage";
import {
    fetchMessagesAction, // fetch messages
} from './../../store/actions/messages';

import {
    fetchMessageDirectAction,
    createMessageDirectAction,
    updateMessageDirectAction,
} from './../../store/actions/directMessage';
import {
    disturbResponseAction, // thay doi trang thai disturb (isOnline)
    changeMemoAction,
} from './../../store/actions/auth';

import NavTabReceived from './../../components/TelephoneCounselling/NavTab/NavTabReceived';
import NavTabSendingCall from './../../components/TelephoneCounselling/NavTab/NavTabSendingCall';
import ModalForwardCall from "../../components/TelephoneCounselling/Modals/ModalForwardCall";
import  WindowOpener  from "./windown-opener";
import ModalRecording from "../../components/TelephoneCounselling/Modals/ModalRecording";
import ModalLastAccess from "../../components/TelephoneCounselling/Modals/ModalLastAccess";
import InfoMastNoPagination from "../../components/TelephoneCounselling/InfoMastNoPagination";

import ModalChangeManager from "../../components/TelephoneCounselling/Modals/ModalChangeManager";

import ConfirmModal from '../../components/ConfirmModal/ConfirmModal';

import axios from "axios";
import Echo from "laravel-echo";
import ChangeMemoUser from "../../components/TelephoneCounselling/ChangeMemoUser";
import {CHANGE_SCENE_COMPANY, CHANGE_SCENE_LOT} from "../../store/type";

const RELOADCSTATIME = 1000;
const CALLBACK_DEBOUNCE_DELAY = 2000;

const errorNumber = {
    title : 'Error',
    message :'고객명은 12자 이하 입력해주세요.', //'vui long nhap call tel va tren 10 so',
    level : 'error'
}

const errorTextValid = {
    title : 'Error',
    message :'고객명은 12자 이하 입력해주세요.', //'vui long nhap cust name duoi 11 ky tu',
    level : 'error'
}

const errorNumberValid = {
    title : 'Error',
    message :'자리에서 12자 이하 입력해주세요.', //'vui long nhap call tel tu 8 -> 12 so',
    level : 'error'
}

const errorCheckBox = {
    title : 'Error',
    message : '부재콜을 체크해주세요.',//'vui long check vao check box cuoc goi nho',
    level : 'error'
}

const errorContent = {
    title : 'Error',
    message : '내용을 입력해주세요.',//'Vui lòng nhập nội dung
    level : 'error'
}

const errorMessage = {
    title : 'Error',
    message : '메시지를 선택해주세요.',//'Vui lòng chọn tin nhắn
    level : 'error'
}

const notificationCallEnd = {
    title :  "Notification",
    message :'A phone call is ended!',
    level : 'info'
}


const notificationRecording = {
    title :  "Error",
    message :'Not found in the directory',
    level : 'error'
}

const callTellRequirement = {
    title: 'Error',
    message: '상담 전화(필수)를 입력해주세요',
    level: 'error'
}

const callRequirement = {
    title: 'Error',
    message: '전화번호를 입력해주세요.',
    level: 'error'
}

const duplicateError = message => {
    return {
        title: "Error",
        message,
        level: 'error'
    }
}

let interval = null;
let refreshInterval = null;

window.io = require('socket.io-client');

function debounce(func, wait = 100) {
    let timeout;
    return function (...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            func.apply(this, args);
        }, wait);
    };
}


//export default function useInterval (callback, delay) => {
//		const savedCallback = useRef();
//
//		useEffect(() => {
//				savedCallback.current = callback;
//		});
//
//		useEffect(() => {
//				const tick = () => {
//						savedCallback.current();
//				}
//
//				const timerId = setInterval(tick, delay);
//				return () => clearInterval(timerId);
//		}, [delay]);
//}

class TelephoneCounselling extends Component {

    constructor(props) {
        super(props);
        this.state = {

            callTab : 1, // 1 : tab danh sách cuộc gọi đến, 2 : tab danh sách cuộc gọi di
            /** callTabChild
             * 0 : la cuoc goi khách hàng gọi đến tư vấn viên nhận
             * 1 : là cuộc gọi tư vấn viên điện cho khách hàng nhận
             * 2 : là cuộc gọi nhỡ khách hàng điện cho tư vấn viên
             * 3 : cuộc gọi chờ, ở trạng thái DeliveredEvent khi kết thúc nó sẽ chuyển trạng thái.
             * 4 : cuộc gọi nhỡ khách hàng tạo sau khi nhấn save ( cuộc gọi vắng mặt )
             * 5 : cuoc goi cho tu van vien dien cho khach hang
             * 6 : cuộc gọi callpickup
             * */
            callTabReceived : 0,
            callTabSendingCall : 2,

            dateSetting : moment().format('YYYY-MM-DD'), // cai dat ngay tren he thong
            dateSettingStatus : 'day', // thay doi che do cai dat du lieu 1 ngay hoac tat ca cac ngay, mac dinh la all


            // 4 state trong he thong cu
            idOfFirstItemSummaryBox: null,
            idOfSecondItemSummaryBox: null,
            valueOfSecondItemSummaryBox: '',
            valueOfFirstItemSummaryBox: '',

            //fetch info mast
            infoMasts : [],
            // fetch districts
            districts : [],

						predefineSMS : [],
						send_sms_item : [],

            receiveCallStatus : 'manual', // che do thu cong hay tu dong mac dinh la manual

            absentCall : false, // state nay dung de co luu cuoc goi nho hay khong (부재중콜) nut check box tren man hinh


            // state notification
            notification: {
                allowHTML: false,
            },

            // id password websocket ben thu 3
            id: this.props.user.csta_id,
            password: this.props.user.csta_pwd,
            deviceObject: this.props.user.usr_tel,// số máy bàn của user
            accessToken : '', // token ben thu 3 do ve


            callerType: 'customer', // check cuoc goi khach hang hay tu van vien ( mac dinh la khach hang )
            pickupThePhone : false, // state truong hop co nhac may len hay khong,
            callIdCurrent : '', // luu call id lai de update du lieu ( * truong hop thu cong khong nhac may luu id de update )

            isHasACall: 1,  // 1: no call,  2: has a call, 3: during call (state cua chuông điện thoại)


            processDuplicate : 0, // state này xử lí quá trình nhân đôi khi tư vấn viên gọi điện cho khách hàng,

            //modal
            callModalDelete : false, // state xoa cuoc goi
            reportModal : false, // state nay la mmodal report
            messageModal : false, // modal gui tin nhan

            forwardModal : false, //modal forward
            forwardCall : '', // call forward
            forward : "N", // check co foward hay ko
            forwardNumber : '', // xem forward so nao

            isDelete : false, // state the hien xoa hay ko xoa 0 : khong xoa, 1 xoa, false = 0, 1 = true,
            dnd_type : 2, //  chuc nang nay tu choi cuoc goi hay bat cuoc goi (68,69)

            saveWithoutChange : false,

            recordingModal : false,
            namePatchRecoding : '', // response tra ve patch setState vao day

            notificationChrome : '',
            callTime : '', // dem thoi gian dien thoai
            lastAccess : true, // la truy cap sau cung
            lastAccessModal : false,

            dataCallTemporarilySave : {}, // state này lưu trữ tạm dữ liệu call để set vào redux ( Trường hợp manual ),

            callbackNotification  : {}, // server tra count callback ve client
            search_key : '', // state nay search ô input
            search_key_input : '',// state s
            acd : 0, // 0 : HUNT, 1 ACD (trạng thái này lấy từ DB xuống rồi setState)
            acdStatus : 0, // 0 : trang thai normal chua lam gi ca, 1 :AgentReadyEvent, 2 : AgentNotReadyEvent , 3 : Khi ở trạng thái này là khi ban đầu nó trạng thái (AgentReadyEvent) có 1 cuộc gọi chưa bấm save thì vào đây. Khi save rồi chuyên trạng thái bằng AgentReadyEvent
            myInfo : {}, // state nay luu value cua CSTA,
            callSmsNumber: '',
            sceneLostId: this.props.sceneLostId,
            sceneCompanyId: this.props.sceneCompanyId,

						// 상담자 변경 대화상자
						isOpenChangeManagerModal : false,
						recentChangeManagerList : [],
						managerList : [],
						selUsrId : '',
						selLotsId: '',
						selCallId: '',
						isConfirmModalOpen: false,
						dailyItemReport: [],
        }

        this.notificationSystem = React.createRef();
        this.addNotification = this.addNotification.bind(this);

        window.Echo = new Echo({
            broadcaster: 'socket.io',
            host: process.env.REACT_APP_API_REAL_TIME,
        });

    }

    // fetch api call list
    __initCallListRedux = (page = 1) => {
        let {
            callTab,   // 1 : tab danh sách cuộc gọi đến, 2 : tab danh sách cuộc gọi di
            callTabReceived, // tab danh sach khach hang goi den
            callTabSendingCall, // tab danh sach cuoc goi di
            dateSettingStatus,
            dateSetting,
            isDelete, // state the hien xoa hay ko xoa 0 : khong xoa, 1 xoa
            search_key, // state nay searach o input
        } = this.state;

        /** callTabChild
         * 0 : la cuoc goi khách hàng gọi đến tư vấn viên nhận
         * 1 : là cuộc gọi tư vấn viên điện cho khách hàng nhận
         * 2 : là cuộc gọi nhỡ khách hàng điện cho tư vấn viên
         * 3 : cuộc gọi chờ khách hàng điện cho tư vấn viên , ở trạng thái DeliveredEvent khi kết thúc nó sẽ chuyển trạng thái.
         * 4 : cuộc gọi nhỡ khách hàng tạo sau khi nhấn save ( cuộc gọi vắng mặt )
         * 5 :  cuộc gọi chờ tư vấn viên điện cho khách hàng
         * */

        let type = callTab == 1 ? callTabReceived : callTabSendingCall;

        let param = {
            page,
        };

        param = {
            ...param,
            is_deleted : isDelete ? 1 : 0,
            // search_key
        }

        if(this.state.sceneLostId) {
            param['lots_id'] = this.state.sceneLostId;						
        }

        if(this.state.sceneCompanyId) {
            param['company_id'] = this.state.sceneCompanyId;
        }

        if(search_key){ // search key co ton tai thi  dateSettingStatus = all  nhung khong setState
            dateSettingStatus = 'all';
        }

        switch (callTab) {
            case 1 : // 1 : tab danh sách cuộc gọi đến

                switch (callTabReceived) {// tab danh sach khach hang goi den
                    case 0 :
                        param['call_date'] = dateSettingStatus === 'all' ? '' :dateSetting;
                        break;
                    case 2 :
                        param['call_date'] = dateSetting;
                        break;
                }

                break;
            case 2: //2 : tab danh sách cuộc gọi di

                switch (callTabSendingCall) { // tab danh sach cuoc goi di
                    case 2 :
                        param['call_date'] = '';
                        break;

                    case 4 :
                        param['call_date'] = dateSettingStatus === 'all' ? '' :dateSetting;
                        break;

                    case 1 :
                        param['call_date'] = dateSettingStatus === 'all' ? '' :dateSetting;
                        break;
                }

                break
        }

        let query = querystring.stringify(param) + (search_key ? "&"+querystring.stringify(search_key) : "" );
        // console.log(type,'type')
        // console.log(param,'param')
        progressBar.start();
        this.props.fetchCalls(parseInt(type), query).then(res => {
            progressBar.done();
        }).catch(e => {
            progressBar.done();
        });
    }

		refreshCount = () => {

			if (this.state.sceneLostId) {

				let params = {
						page: 1, 
						type: 'paginate',
				}
				// 상담 문자 전송 가능 2023-04-25
				api.predefinesms.fetchPredefineSMS(this.state.sceneLostId, params)
				.then(response => {
					console.log("response:",response);
					this.setState({
						...this.state,
						predefineSMS : response.data
					});
				})
				.catch(error => {
						console.log("ERROR");
				});

			}

		}

    // fetch api call list search by number
    __initSearchByCallNumber = (callNumber,page = 1) => {
        let param = {
            page : page,
            call_number : callNumber,
            isDelete : this.state.isDelete ? 1 : 0, // state the hien xoa hay ko xoa 0 : khong xoa, 1 xoa
        }

        if(this.state.sceneCompanyId) {
            param['company_id'] = this.state.sceneCompanyId;
        }

        let query  = querystring.stringify(param);
        this.props.searchByCallNumber(query)
    }

    __initCountCallBackByUser = () => {
        api.telephone.getCountCallBack().then(res => {
            this.setState({
                callbackNotification  : res.data.data
            })
        })
    }

    //notification
    addNotification (data) {
        const notification = this.notificationSystem.current;
        if(notification){
            notification.addNotification({
                message: data.message,
                title: data.title,
                level: data.level,
                position : 'br'
            });
        }
    };

    addNotificationChrome = data => {
        let body = `고객명 : ${data.call_cust_name} \n상담전화 : ${data.call_tel} \n상담전화 : ${data.call_cust_info}`;
        let noti = new Notification('Notification', {
            body:body,
        });

        this.setState({
            notificationChrome : noti
        })
    }

    reloadAccessTokenCsta = () => {
        let {
            id,
            password
        } = this.state;

        let {user} = this.props;

        progressBar.start();
        webSocketApi.getAccessPermission(id,
            password,
            'wss://rtc.goodfone.co.kr:9807', // 2024-03-20 9807 -> 9850
            'https://rtc.goodfone.co.kr:9850', // 2024-03-20 9807 -> 9850
            this.csta_event_callback)
            .then(res => {
                console.log(res,'ressssss')
                // tiep tuc xet distub is_online

                let alert = {
                    title :  <FormattedMessage
                        id="dashboard.Succeed"
                        defaultMessage="Succeed"/>,
                    message :<FormattedMessage
                        id="dashboard.TelephoneCounselling.ConnectToServerSucceed"
                        defaultMessage="Connect to server succeed!"/>,
                    level : 'success'
                }
                progressBar.done();
                this.addNotification(alert);
                // luu cac gia tri cua CSTA
                this.setState({
                    myInfo : {
                        ...this.state.myInfo,
                        ...res,
                        "userid": user.csta_id, //  luu id cua csta
                    }
                })

                progressBar.done();
                console.log(( (1000 * res.expires_in) / 2) + RELOADCSTATIME,'time')
                setTimeout(this.reloadAccessTokenCsta, ( (1000 * res.expires_in) / 2) + RELOADCSTATIME);
                // setTimeout(this.reloadAccessTokenCsta, RELOADCSTATIME);
            })
            .catch(e => {

                let error = {
                    title : <FormattedMessage
                        id="dashboard.Error"
                        defaultMessage="Error"/>,
                    message : <FormattedMessage
                        id="dashboard.TelephoneCounselling.ConnectToServerFailed"
                        defaultMessage="Connect to server failed!"/>,
                    level : 'error'
                }
                this.addNotification(error);
                progressBar.done();
            });
    }

		change_lots_id = (lots_id) => {
			let params = {
					page: 1, 
					type: 'paginate',
			}
			// 상담 문자 전송 가능 2023-04-25
			api.predefinesms.fetchPredefineSMS(lots_id, params)
			.then(response => {
				console.log("response:",response);
				this.setState({
					...this.state,
					predefineSMS : response.data
				});
			})
			.catch(error => {
					console.log("ERROR");
			});
		}

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.isChangeScene && nextProps.sceneLostId !== this.state.sceneLostId && nextProps.typeOfChange === CHANGE_SCENE_LOT) {
            this.setState({
                sceneLostId: nextProps.sceneLostId,
                search_key: '',
            }, () => {
                this.resetFormCall()
                this.__initCallListRedux()
								this.change_lots_id(nextProps.sceneLostId)
            })
        }
        if(nextProps.isChangeScene && nextProps.sceneCompanyId !== this.state.sceneCompanyId && nextProps.typeOfChange === CHANGE_SCENE_COMPANY) {
            this.setState({
                sceneCompanyId: nextProps.sceneCompanyId,
                search_key: '',
            }, () => {
                this.resetFormCall();
                this.__initCallListRedux()
                // Get id of item in summary box
                let idOfFirstItemSummaryBox = null;
                let idOfSecondItemSummaryBox = null;
                api.infoMast.getInfoMastByCompany(nextProps.sceneCompanyId).then(res => {
                    let infoMasts = res.data.data;
                    infoMasts.map(infoMast => {
                        if (infoMast.m_name === '호응도') {
                            idOfFirstItemSummaryBox = infoMast.m_id
                        }
                        if (infoMast.m_name === '희망평형') {
                            idOfSecondItemSummaryBox = infoMast.m_id
                        }
                    });
                    this.setState({
                        idOfFirstItemSummaryBox,
                        idOfSecondItemSummaryBox,
                        infoMasts
                    })
                })
            })
        }
    }

    openConfirmModal = (index, item) => { // 관련 타이틀이 필요
        this.setState({
						send_sms_item :item,
            isConfirmModalOpen: !this.state.isConfirmModalOpen
        });
    };

		onConfirm = () => { // 문자 변경 대상
				const { send_sms_item,selCallId } = this.state;
				send_sms_item.call_id = selCallId;
        api.predefinesms.send(send_sms_item).then(response => {
						console.log('response:',response);
           this.setState({
               ...this.state,
               send_sms_item: [],
           }, () => {

							let phone_str = response.data.data.data.phone;
							let msg_body = response.data.data.data.msg_body;

							let arr_phone = phone_str.split('\n');

							arr_phone.map(phone => {
								
								let {deviceObject,accessToken} = this.state;
								let {user} = this.props;

								console.log('user:',user);
								console.log('deviceObject:',deviceObject);

								let data  = {
									user_id : user.csta_id,
									deviceObject,
									to_user : phone,
									oauth_token : accessToken,
									title : '상담내용 단문자 : '+user.usr_name,
									msg : msg_body
								}


								let dataMessageDirect = {
										lots_id : this.state.sceneLostId,
										phone : phone,
										contents : msg_body,
										image_messages : null,
								}
								api.bulkSending.SendSMSDirect(dataMessageDirect);

								//webSocketApi.SendSMS(data.user_id,data.deviceObject,data.to_user,data.msg,data.oauth_token).then(response => {
								//	console.log('response:',response);
								//});

								let dataMessageHistory = {};

								let message  = {
									content : data.msg,
									send_type: 1,
									image_messages: []
								}

								this.props.createMessageDirect(message).then(res => {
										dataMessageHistory = {
												mess_id : res.mess_id,
												rec_usr_id : user.usr_id,
												phone_number : data.to_user,
												send_type : 3, //0:auto sending,1:SMS/MMS,2:bulk sending
										}
										api.textManager.createMessageHistory(dataMessageHistory);
								})

							});


//								//if (response.realSendCount > 0) {
//								let insertIds = selCallId;
//								let dataMessageHistory = {
//										mess_id : insertIds,
//										rec_usr_id : user.usr_id,
//										phone_number : response.data.data.data.phone,
//										send_type : 1, //0:auto sending,1:SMS/MMS,2:bulk sending
//								}
//								console.log('dataMessageHistory:',dataMessageHistory);
//								api.textManager.createMessageHistory(dataMessageHistory);

								this.addNotification({
										title: <FormattedMessage
												id="dashboard.PredefineSMS.menu"
												defaultMessage="SMS send"/>,
										message: <FormattedMessage
												id="dashboard.PredefineSMS.menu.success"
												defaultMessage="SMS send successfully!"/>,
										level: 'success',
								})
								//}
								this.openConfirmModal(0, null);
							//});
           })
           progressBar.done();
        }).catch(error => {        
						this.addNotification({
								title: <FormattedMessage
										id="dashboard.PredefineSMS.menu"
										defaultMessage="Notice send"/>,
								message: <FormattedMessage
										id="dashboard.PredefineSMS.menu.fail"
										defaultMessage="Notice failed!"/>,
								level: 'error',
						})
						this.openConfirmModal(0, null);
            progressBar.done();
        });
    };

    componentDidMount = () => {
        // const reload = this.reloadAccessTokenCsta;
        const isRefresh = localStorage.getItem('is-refresh');
        if(isRefresh) {
            const call = localStorage.getItem('call');
            if(call) {
                this.props.setCall(JSON.parse(call));
            }
            localStorage.removeItem('is-refresh');
            localStorage.removeItem('call');
        }
        const checkOnlineStatus = async () => {
            try {
                const online = await api.testOffline.testOffline();
                return online.status >= 200 && online.status < 300; // either true or false
            } catch (err) {
                return false; // definitely offline
            }
        };

        interval = setInterval(async () => {
            const result = await checkOnlineStatus();
            if(!result) {
                localStorage.setItem('is-refresh', true);
                localStorage.setItem('call', JSON.stringify(this.props.call))
                clearInterval(interval);
                alert(`인터넷에 연결 끊어졌습니다.페이지를 새로고침 해주세요. `);
                window.location.reload();
            }

        }, 2000); // probably too often, try 30000 for every 30 seconds

        refreshInterval = setInterval(async () => {
            const result = await this.refreshCount();
        }, 5000); // probably too often, try 30000 for every 30 seconds

        window.addEventListener('load', function() {
            console.log('da vao')
            // console.log('du ma da vao')
            // var status = document.getElementById("status");
            // var log = document.getElementById("log");

            function updateOnlineStatus(event) {

                console.log(event,'event updateOnlineStatus')
                var condition = navigator.onLine ? "online" : "offline";

                console.log(condition,'condition')

                // status.className = condition;
                // status.innerHTML = condition.toUpperCase();

                // log.insertAdjacentHTML("beforeend", "Event: " + event.type + "; Status: " + condition);
                // if(condition === 'online') {
                //     reload();
                // }
            }

            window.addEventListener('online',  updateOnlineStatus);
            window.addEventListener('offline', updateOnlineStatus);

        });

        window.ononline = (event) => {
            console.log("You are now connected to the network.");
            // this.reloadAccessTokenCsta();
        };
        window.onoffline = (event) => {
            console.log("The network connection has been lost.");
        };

        let {user} = this.props;

        let {
            id, // id ben thu 3 cap
            password, //password ben thu 3 cap
        } = this.state;

        // set cuoc goi thu cong hay tu dong
        let receiveCallStatus = localStorage.getItem("receive-call-status");
        if(!receiveCallStatus){
            localStorage.setItem("receive-call-status",'manual');
            receiveCallStatus = 'manual';
        }

        // cho phep notification
        if (Notification.permission !== 'granted'){
            Notification.requestPermission();
        }

        let deviceObject = user.usr_tel;
        if (deviceObject) {
            deviceObject = deviceObject && deviceObject.replace(/\D/g, '');
        }

        var getDateLocal = localStorage.getItem("call-date");
        var dateSetting = getDateLocal ? moment(getDateLocal).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
        // set lai gio he thong

        this.setState({
            dateSetting, // set ngay he trong he thong CRM
            receiveCallStatus, // state che do thu cong hay tu dong
            deviceObject,
            acd : user.info_sale_lot.acd, // acd cua user trong he thong
        }, () => {

            this.__initCallListRedux();

            // Get id of item in summary box
            let idOfFirstItemSummaryBox = null;
            let idOfSecondItemSummaryBox = null;

            // get info masts
            if(hasRole(this.props.user, ['primarynet'])) {
                console.log('vao day 1');
                api.infoMast.getInfoMastByCompany(this.props.sceneCompanyId).then(res => {
                    let infoMasts = res.data.data;
                    infoMasts.map(infoMast => {
                        if (infoMast.m_name === '호응도') {
                            idOfFirstItemSummaryBox = infoMast.m_id
                        }
                        if (infoMast.m_name === '희망평형') {
                            idOfSecondItemSummaryBox = infoMast.m_id
                        }
                    });
                    this.setState({
                        idOfFirstItemSummaryBox,
                        idOfSecondItemSummaryBox,
                        infoMasts
                    })
                })
            } else {
                console.log('vao day 2');
                api.infoMast.getInfoMast().then(res => {
                    let infoMasts = res.data.data;
                    infoMasts.map(infoMast => {
                        if (infoMast.m_name === '호응도') {
                            idOfFirstItemSummaryBox = infoMast.m_id
                        }
                        if (infoMast.m_name === '희망평형') {
                            idOfSecondItemSummaryBox = infoMast.m_id
                        }
                    });
                    this.setState({
                        idOfFirstItemSummaryBox,
                        idOfSecondItemSummaryBox,
                        infoMasts
                    })
                })
            }

            // get districts
            api.districts.getDistrictsByCompany().then(res => {
                let districts = res.data.data;
                this.setState({
                    districts
                })
            })

            // check lan truy cap sau cung
            api.auth.getUserLastAccess().then(res => {
                let checkUser = res.data.data;
                if(user.last_access != checkUser.last_access) {
                    this.setState({
                        lastAccess : false
                    })
                }

            })
            // state forward
            // forwardModal : false, //modal forward
            //     forwardCall : '', // call forward
            //     forward : false, // check co foward hay khong
            //     forwardNumber : '', // xem forward so nao
            // check user forward
            let dataCheckForward = {
                "user_id": user.csta_id,
                "password": user.csta_pwd,
                "forward_number" : "",
                "job_type" : "S"
            }
            axios({
                method : 'post',
                url : 'https://rtc.goodfone.co.kr:9798/post/call_forward.json',
                data : JSON.stringify(dataCheckForward),
                crossDomain : true,
                dataType:"json",
            }).then(res => {
                let dataForward = res.data;

                //dataForward.current_cfw_type == "N" là chưa có forward
                //dataForward.current_cfw_type == "Y" là da có forward
                this.setState({
                    forward : dataForward.current_cfw_type,
                    forwardNumber : dataForward.current_cfw_no
                })
            }).catch(e => {
                // console.log(e.response);
            })

            progressBar.start();
            webSocketApi.getAccessPermission(id,
                password,
                'wss://rtc.goodfone.co.kr:9807', // 2024-03-20 9807 -> 9850
                'https://rtc.goodfone.co.kr:9850', // 2024-03-20 9807 -> 9850
                this.csta_event_callback)
                .then(res => {

                    console.log(res,'ressssss')

                    // tiep tuc xet distub is_online

                    let alert = {
                        title :  <FormattedMessage
                            id="dashboard.Succeed"
                            defaultMessage="Succeed"/>,
                        message :<FormattedMessage
                            id="dashboard.TelephoneCounselling.ConnectToServerSucceed"
                            defaultMessage="Connect to server succeed!"/>,
                        level : 'success'
                    }
                    progressBar.done();
                    this.addNotification(alert);


                    // luu cac gia tri cua CSTA
                    this.setState({
                        myInfo : {
                            ...this.state.myInfo,
                            ...res,
                            "userid": user.csta_id, //  luu id cua csta
                        }
                    })

                    // console.log(((1000 * res.expires_in) / 2) + RELOADCSTATIME,'time')
                    setTimeout(this.reloadAccessTokenCsta, ((1000 * res.expires_in) / 2) + RELOADCSTATIME);  //RELOADCSTATIME 1000
                    progressBar.done();
                })
                .catch(e => {

                    let error = {
                        title : <FormattedMessage
                            id="dashboard.Error"
                            defaultMessage="Error"/>,
                        message : <FormattedMessage
                            id="dashboard.TelephoneCounselling.ConnectToServerFailed"
                            defaultMessage="Connect to server failed!"/>,
                        level : 'error'
                    }
                    this.addNotification(error);
                    progressBar.done();
                });


            if(user.info_sale_lot.status_text == 1) {
                this.props.fetchMessages(user.info_sale_lot.lots_id);
                this.props.fetchMessageDirect();// get message direct
            }
        });// ket thuc xu li

        if(window.Echo){
            window.Echo.channel('callback-'+user.company_id)
                .listen('NotificationCallBack', (data) => {
                    console.log(data,'data')
                    let notificationCallback = {
                        title :  "Notification",
                        message :'1개의 콜백 전화가 있습니다. 번호는 '+data.phone_number+'입니다.',
                        level : 'warning'
                    }

                    // this.notificationSystem.current.addNotification(notificationCallback);

                    this.addNotification(notificationCallback);
                    this.callbackDebounce();
                })
        }

        this.__initCountCallBackByUser();

        if(this.state.sceneLostId) {
            this.change_lots_id(this.state.sceneLostId);						
        }
				
    }

    callbackDebounce = debounce(() => {
        this.__initCallListRedux();
        this.__initCountCallBackByUser();
    }, CALLBACK_DEBOUNCE_DELAY)

    handleConnectionChange = (event) => {
        console.log(111111)
        console.log(event,'event handleConnectionChange')
    }

    changeMyStatus = (select_val) => {
        //acd 0 : HUNT, 1 ACD
        var sub = '00';
        let {
            deviceObject, // so may ban
            accessToken // token ben thu 3 goi ve
        } = this.state;
        let {user} = this.props;
        webSocketApi.cstaSetFeature(deviceObject,user.csta_id,'',select_val,sub,accessToken);
    }

    // function nay luu redux call o trang thai manual
    saveReduxCallManualPickup = (call,callerType,dateSetting,pickupThePhone) => {

        let newCall = call; //state này lưu trữ tạm dữ liệu call để set vào redux ( Trường hợp manual )

        newCall = {
            ...newCall,
            call_check : callerType !== 'customer' ? 1 : 0, // comment lai xu li 1s
            call_date : dateSetting,
            pickupThePhone,
            call_cust_tel : newCall.call_tel
        }
        this.props.fetchCreateCall(newCall); // set du lieu lai redux
        this.__initSearchByCallNumber(newCall.call_tel) // fetch call search

        this.setState({
            callerType : 'customer', // set lai mac dinh
            dataCallTemporarilySave : {}, // tro ve mac dinh
        })

        let detailList = newCall.call_detail;
        if(detailList.length > 0 ) {
            this.setValueFirstAndSecondItemSummaryBox(detailList)
        }
    }

    saveReduxCallManual = (call,callerType,dateSetting,pickupThePhone) => {

        let newCall = call; //state này lưu trữ tạm dữ liệu call để set vào redux ( Trường hợp manual )

        newCall = {
            ...newCall,
            call_check : callerType !== 'customer' ? 1 : 0, // comment lai xu li 1s
            call_date : dateSetting,
            pickupThePhone
        }
        this.props.fetchCreateCall(newCall); // set du lieu lai redux
        this.__initSearchByCallNumber(newCall.call_tel) // fetch call search

        this.setState({
            callerType : 'customer', // set lai mac dinh
            dataCallTemporarilySave : {}, // tro ve mac dinh
        })

        let detailList = newCall.call_detail;
        if(detailList.length > 0 ) {
            this.setValueFirstAndSecondItemSummaryBox(detailList)
        }
    }

		send_auto_send_absent_call = (lots_id, call_type, call_number) => {

			// api ?
			let params = {
				lots_id,
				call_type,
				call_number
			};
			api.textManager.autoSendAbsentCall(params).then(response => { //api 결과에 따라 진행한다.

			}).catch(error => {

			})

		}

		send_auto_send_create_call = (call_data) => {

			// api ?
			let params = {
				lots_id: this.state.sceneLostId,
				call_data
			};

			console.log('CREATE_CALL',params);
			api.textManager.autoSendCreateCall(params).then(response => { //api 결과에 따라 진행한다.
				console.log('CREATE_CALL_SUCC',response);
			}).catch(error => {
				console.log('CREATE_CALL_FAIL');
			})

		}

		send_auto_send = (lots_id, call_type, call_number) => {

			// api ?
			let params = {
				lost_id: lots_id,
				call_type,
				call_number
			};
			api.textManager.autoSend(params).then(response => { //api 결과에 따라 진행한다.

				if (response.data.success)
				{
					console.log('자동전송 대상(O):',response);
					let {deviceObject,accessToken} = this.state;
					let {user} = this.props;

					console.log('user:',user);
					console.log('deviceObject:',deviceObject);

					let data  = {
						user_id : user.csta_id,
						deviceObject,
						to_user : call_number,
						oauth_token : accessToken,
						title : response.data.data.title,
						msg : response.data.data.content
					}

					console.log('response.data.data.image_messages:',response.data.data.image_messages);


					let dataMessageDirect = {};
						
					if(response.data.data.image_messages.length > 0 ){ // co anh thi gui mms
						
							dataMessageDirect = {
									lots_id : this.state.sceneLostId,
									phone : call_number,
									contents : response.data.data.content,
									image_messages : {},
							}
							response.data.data.image_messages.map((image, index) => {
									dataMessageDirect.image_messages[index] = {
											origin_name: image.image_name,
											upload_file:image.image_name,
											upload_path:image.image_path,
											type:"image"
									}
							});
							api.bulkSending.SendSMSDirect(dataMessageDirect);
					}else{ // khong co anh thi gui api sms
							dataMessageDirect = {
									lots_id : this.state.sceneLostId,
									phone : call_number,
									contents : response.data.data.content,
									image_messages : {},
							}
							api.bulkSending.SendSMSDirect(dataMessageDirect);
					}



// 새로운 방식으로 단문자
//					if(response.data.data.image_messages.length > 0 ){
//						response.data.data.image_messages.map((image, index) => {
//							data[`attach${index + 1}`] = {
//								origin_name: image.image_name,
//								upload_file:image.image_name,
//								upload_path:image.image_path,
//								type:"image"
//							}
//						});
//						console.log('SendMMS:',data);
//						webSocketApi.SendMMS(data);
//					}else{
//						console.log('SendSMS:',data);
//						webSocketApi.SendSMS(data.user_id,data.deviceObject,data.to_user,data.msg,data.oauth_token);
//					}

					let dataMessageHistory = {
							mess_id : response.data.data.mess_id,
							rec_usr_id : user.usr_id,
							phone_number : call_number,
							send_type : 0, //0:auto sending,1:SMS/MMS,2:bulk sending
					}
					console.log('dataMessageHistory:',dataMessageHistory);
					api.textManager.createMessageHistory(dataMessageHistory);
				} else {
					console.log('자동전송 대상(X):',response);
				}

	    }).catch(error => {

			})
			
		}

    csta_event_callback = (event_type, dataObject) => {

        console.log(event_type,'event_type')
        console.log(dataObject,'dataObject')

        let {
            myInfo
        } = this.state;
				
        if (event_type == 'SIGN-IN') {

            this.setState({
                accessToken : dataObject.access_token
            },()=>{
                // tiep theo

                let {
                    deviceObject, // so may ban
                    accessToken // token ben thu 3 goi ve
                } = this.state;

                let {user} = this.props;

                webSocketApi.cstaSetDoNotDisturb(
                    deviceObject,
                    user.dnd_type,
                    accessToken);

                webSocketApi.cstaMonitorStart(deviceObject,accessToken);

                this.setState({
                    dnd_type : user.dnd_type, //dndType:  1 tu choi cuoc goi || 2 chap nhan cuoc goi
                })


            })

        }
        else if (event_type == 'SIGN_OFF') {
            webSocketApi.cstaMonitorStop(this.state.deviceObject, this.state.accessToken);
        }
        else if (event_type == 'CSTA_CMD_MONITOR_START') {
            let crossRefID = dataObject.crossRefID;
            console.log('crossRefID CSTA_CMD_MONITOR_START');
            console.log('event CSTA_CMD_MONITOR_START');

            if(this.state.acd == 1) {
                let {
                    myInfo
                } = this.state;
                this.changeMyStatus(1,myInfo)
            }

        }
        else if (event_type == 'MonitorStartResponse') {
            console.log('event MonitorStartResponse');

            if(this.state.acd == 1) {
                let {
                    myInfo
                } = this.state;
                this.changeMyStatus(1,myInfo)
            }

        }else if(event_type == "MakeCallResponse"){
            this.setState({
                callerType : 'counselling' // change caller type is counselling
            })
        }
        else if (event_type == 'OriginatedEvent') {
            console.log('event OriginatedEvent (counselling gọi)', this.state.deviceObject);
            let callID = dataObject.originatedConnection[0].callID[0];
            let calledDn = dataObject.calledDevice[0].deviceIdentifier[0];
        }
        else if (event_type == 'NetworkReachedEvent') {
            // console.log('event NetworkReachedEvent');
            let callID = dataObject.outboundConnection[0].callID[0];



        }
        else if (event_type == 'DeliveredEvent') {
            console.log('event DeliveredEvent (bắt đầu cuộc gọi) '); // 수신

            // When a call delivered
            // Clear all data in recorded data
            let callID = dataObject.connection[0].callID[0];
            let alertingDevice = dataObject.alertingDevice[0].deviceIdentifier[0];
            let callingDn = dataObject.callingDevice[0].deviceIdentifier[0];
            let calledDn = dataObject.calledDevice[0].deviceIdentifier[0];

            let call_line_info = dataObject.lastRedirctionDevice[0].deviceID[0] === "undefined" ? ''
                : dataObject.lastRedirctionDevice[0].deviceID[0];

            if(alertingDevice != this.state.deviceObject) {
                this.setState({
                    callerType: 'counselling'
                })
            }

             //processDuplicate state này xử lí quá trình nhân đôi khi tư vấn viên gọi điện cho khách hàng
            //processDuplicate vì DeliveredEvent và OriginatedEvent chạy 2 lần cho nên xử lí như vậy

            const phone_number = callingDn !== this.state.deviceObject ? callingDn : calledDn;

            if(this.state.processDuplicate < 1 ){ // xử lí quá trình nhân đôi
                this.setState({
                    pickupThePhone : false, // xet truong hop co nhac may hay khong
                    isHasACall : 2,  // trang thai chuong dien thoai
                    processDuplicate : 1, //xử lí quá trình nhân đôi tư vấn viên điện cho khách hàng .
                    lastAccess : true, // set lai mac dinh bang true
                },() => {

                    // xu li tiep theo
                    //deviceObject.replace(/\D/g, '');
                    let {
                        receiveCallStatus, // cuoc goi thu cong hay tu dong
                        deviceObject, // so dien thoai ban
                        dateSetting,
                        callerType,
                        acdStatus
                    } = this.state;
                    let {user} = this.props;
                    let params = {
                        call_tel: callingDn !== deviceObject ? callingDn : calledDn,
                        call_line_info: call_line_info,
                        call_check: callerType === 'counselling' ? 5 : 3,
                        call_date : dateSetting,
                        call_id_api : callID,
                        receiveCallStatus,
                        alertingDevice,
                        last_access : user.last_access
                    };

                    const data = {
                        phone_number,
                        device_number: this.state.deviceObject,
                        event: 'The phone rings',
                        step: 1
                    }

                    api.logging.loggingCallNotify(data);

                    // tao cuoc goi moi
                    progressBar.start();
                    api.call.createCallNew(params).then(response => {
                        progressBar.done();
                        let newCall = response.data.data;

                        newCall = {
                            ...newCall,
                        }

                        this.setState({
                            //luu call id vao state de truong hop thu cong update du lieu (truong hop make call ko luu id)
                            //make call khong duoc luu id
                            callIdCurrent : callerType === 'counselling' ? '' :newCall.call_id,
                        },() => {

                            // xu li tiep theo
                            // hien thi notification cho web
                            let message;

                            callingDn === deviceObject ? message = this.props.intl.formatMessage({
                                id: 'dashboard.CallValidate.CallingTo',
                                defaultMessage: "You are calling to: "
                            }) : message = this.props.intl.formatMessage({
                                id: 'dashboard.CallValidate.HaveACall',
                                defaultMessage: "You have a phone call from: "
                            });

                            let phone = callingDn !== this.state.deviceObject ? callingDn : calledDn;

                            let messageChrome = {
                                call_tel : phone,
                                call_cust_name :newCall.call_cust_name ? newCall.call_cust_name : '',
                                call_line_info : newCall.info_line ? newCall.info_line.line_info : ''
                            };

                            let messageNoti =  `고객명 : ${messageChrome.call_cust_name} <br/> 상담전화 : ${messageChrome.call_tel} <br/> 회선정보 : ${messageChrome.call_line_info}`;


                            let notification = {
                                title :  <FormattedMessage
                                    id="dashboard.Notification"
                                    defaultMessage="Notification"/>,
                                message :<div dangerouslySetInnerHTML={{__html: messageNoti}}></div>,
                                level : 'success'
                            }
                            this.addNotification(notification);

                            const data = {
                                phone_number: phone,
                                device_number: this.state.deviceObject,
                                event: 'Show incoming call notifications',
                                step: 2
                            }

                            api.logging.loggingCallNotify(data);

                            // this.addNotificationChrome(messageChrome)

                            // xu li truong hop make call
                            if(callerType === 'counselling'){
                                let data = {
                                    call_date  : dateSetting, // lay call_date trong he thong
                                    call_id : newCall.call_id, // luu call_id de update du lieu
                                    call_check : newCall.call_check, // luu call_check = 5
                                    call_id_api : callID,
                                }

                                if(acdStatus == 1){
                                    this.setState({
                                        acdStatus : 3 // 3 : Khi ở trạng thái này là khi ban đầu nó trạng thái (AgentReadyEvent) có 1 cuộc gọi chưa bấm save thì vào đây. Khi save rồi chuyên trạng thái bằng AgentReadyEvent
                                    })
                                }

                                // truong hop nay thay doi cot trong redux
                                this.props.editCallColumn(data);// khong xu li o duoi
                            }

                            // neu che do tu dong thi tra du lieu cho man hinh
                            if(receiveCallStatus == 'auto'){
                                // set du lieu lai redux
                                this.props.fetchCreateCall({
                                    ...newCall,
                                });
                                this.__initSearchByCallNumber(newCall.call_tel) // fetch call search
                                // xy li 4 state trong he thong cu
                                let detailList = newCall.call_detail;
                                if(detailList.length > 0 ) {
                                    this.setValueFirstAndSecondItemSummaryBox(detailList)
                                }


                            }else if(receiveCallStatus == 'manual') {
                                this.setState({
                                    //// state này lưu trữ tạm dữ liệu call để set vào redux ( Trường hợp manual )
                                    dataCallTemporarilySave : {
                                        ...newCall,
                                    }
                                })
                            }

                        })
                    }).catch(e => {
                        const data = {
                            phone_number,
                            device_number: this.state.deviceObject,
                            event: 'There is a phone ringing, but no incoming call notification',
                            step: 3
                        }
                        api.logging.loggingCallNotify(data);
                        let error  = e.response;
                        console.log(error,'error')
                        if(error.status === 405) {
                            this.setState({
                                lastAccess : false,
                                isHasACall : 1
                            },()=>{
                                // this.lastAccessModalAction();
                                this.setState({
                                    lastAccessModal : true
                                })
                            })
                        }
                        progressBar.done();
                    })

                })
            }
            else {
                const data = {
                    phone_number,
                    device_number: this.state.deviceObject,
                    event: 'There is an incoming call, but no ringer and no notification.',
                    step: 4
                }
                api.logging.loggingCallNotify(data);
            }

        }
        else if (event_type == 'EstablishedEvent') {
            console.log('EstablishedEvent ')

            let callID = dataObject.establishedConnection[0].callID[0];
            let answeringDevice = dataObject.answeringDevice[0].deviceIdentifier[0];
            let callingDn = dataObject.callingDevice[0].deviceIdentifier[0];
            let calledDn = dataObject.calledDevice[0].deviceIdentifier[0];

            const phone_number = callingDn !== this.state.deviceObject ? callingDn : calledDn;
            const data = {
                phone_number,
                device_number: this.state.deviceObject,
                event: 'Answer the phone',
                step: 5
            }

//						let {user} = this.props;
//						let call_type = 0;
//						let call_type_descr = '';
//						if (this.state.deviceObject == callingDn) {
//							call_type = 2;call_type_descr = "수신";
//						} else if (this.state.deviceObject == calledDn) {
//							call_type = 1;call_type_descr = "발신";
//						}
//						if (call_type >0) 
//						{
//							console.log('자동전송형식 : ',call_type_descr);
//	            console.log('자동전송번호 : ',phone_number);
//							this.send_auto_send(user.info_sale_lot.lots_id,call_type,phone_number);
//						}

            api.logging.loggingCallNotify(data)

            this.setState({
                pickupThePhone : true, // truong hop da nhac may
                isHasACall : 3, // trang thai chuong dien thoai khi nhac may
                callTime : moment(),
            }, () => {
                // xu li tiep theo

                let {
                    receiveCallStatus, //  che do thu cong hay tu dong
                    callerType, // tu van vien hay khach hang dien thoai
                    dateSetting,
                    deviceObject,
                    pickupThePhone,
                    accessToken,
                    lastAccess,
                    dataCallTemporarilySave, //// state này lưu trữ tạm dữ liệu call để set vào redux ( Trường hợp manual )
                    myInfo,
                    acdStatus,  // 0 : trang thai normal chua lam gi ca, 1 :AgentReadyEvent, 2 : AgentNotReadyEvent , 3 : Khi ở trạng thái này là khi ban đầu nó trạng thái (AgentReadyEvent) có 1 cuộc gọi chưa bấm save thì vào đây. Khi save rồi chuyên trạng thái bằng AgentReadyEvent
                } = this.state;

                let {user} = this.props;

                if(this.state.notificationChrome){
                    this.state.notificationChrome.close();
                }

                if(lastAccess){ // truy cap sau cung se vo day


                    if(acdStatus == 1){
                        this.setState({
                            acdStatus : 3 // 3 : Khi ở trạng thái này là khi ban đầu nó trạng thái (AgentReadyEvent) có 1 cuộc gọi chưa bấm save thì vào đây. Khi save rồi chuyên trạng thái bằng AgentReadyEvent
                        })
                    }

                    if(callerType === 'counselling'){ //truong hop make call
                        let data = {
                            call_date  : dateSetting,
                            call_check : 1, // trang thai khach hang nhac may
                            pickupThePhone
                        }
                        this.props.editCallColumn(data);
                        if(Object.keys(dataCallTemporarilySave).length > 0) {
                            // function luu redux cho trang thai thu cong
                            this.saveReduxCallManual(dataCallTemporarilySave,callerType,dateSetting,pickupThePhone)

                        }
                    }else {
                        let {call} = this.props;

                        // webSocketApi.changeMyStatusForCsta(7,myInfo);
                        // truong hop khach hang goi den tu van vien
                        if(receiveCallStatus == 'auto'){ // auto
                            let changeCallRedux =  {
                                call_check : callerType !== 'customer' ? 1 : 0,
                                call_date : dateSetting
                            }


                            if(!call.id){
                                // truong hop nay xu li call pickup
                                let params = {
                                    call_tel: callingDn !== deviceObject ? callingDn : calledDn,
                                    call_check: 0,
                                    call_date : dateSetting,
                                    call_id_api : callID,
                                    last_access : user.last_access
                                };

                                api.call.createCallNew(params).then(response => {
                                    progressBar.done();
                                    let newCall = response.data.data;
                                    newCall  = {
                                        ...newCall,
                                        call_check : 0
                                    }

                                    let phone = callingDn !== deviceObject ? callingDn : calledDn;

                                    let messageChrome = {
                                        call_tel : phone,
                                        call_cust_name :newCall.call_cust_name ? newCall.call_cust_name : '',
                                        call_line_info : newCall.info_line ? newCall.info_line.line_info : ''
                                    };

                                    let messageNoti =  `고객명 : ${messageChrome.call_cust_name} <br/> 상담전화 : ${messageChrome.call_tel} <br/> 회선정보 : ${messageChrome.call_line_info}`;

                                    let notification = {
                                        title :  <FormattedMessage
                                            id="dashboard.Notification"
                                            defaultMessage="Notification"/>,
                                        message :<div dangerouslySetInnerHTML={{__html: messageNoti}}></div>,
                                        level : 'success'
                                    }
                                    this.addNotification(notification);

                                    this.props.fetchCreateCall(newCall); // set du lieu lai redux

                                    this.__initSearchByCallNumber(newCall.call_tel) // fetch call search
                                    // xy li 4 state trong he thong cu
                                    let detailList = newCall.call_detail;
                                    if(detailList.length > 0 ) {
                                        this.setValueFirstAndSecondItemSummaryBox(detailList)
                                    }

                                }).catch(e => {
                                    let error  = e.response;
                                    if(error.status === 405) {
                                        this.setState({
                                            lastAccess : false,
                                            isHasACall : 1
                                        },()=>{
                                            // this.lastAccessModalAction();
                                            this.setState({
                                                lastAccessModal : true
                                            })
                                        })
                                    }
                                    progressBar.done();
                                })

                            } else{
                                this.props.editCallColumn(changeCallRedux);

                                this.setState({
                                    callerType : 'customer' // set lai mac dinh
                                })
                            }

                        }else{ // thu cong

                            // let newCall = dataCallTemporarilySave; //state này lưu trữ tạm dữ liệu call để set vào redux ( Trường hợp manual )
                            // console.log(newCall,'newcall1')
                            if(Object.keys(dataCallTemporarilySave).length > 0) {
                                // function luu redux cho trang thai thu cong
                                this.saveReduxCallManual(dataCallTemporarilySave,callerType,dateSetting,pickupThePhone)

                            }else {
                                // truong hop nay xu li call pickup
                                let params = {
                                    call_tel: callingDn !== deviceObject ? callingDn : calledDn,
                                    call_check: 0,
                                    call_date : dateSetting,
                                    call_id_api : callID,
                                    last_access : user.last_access
                                };

                                // truong hop callpickup
                                api.call.createCallNew(params).then(response => {
                                    progressBar.done();
                                    let newCall = response.data.data;

                                    let phone = callingDn !== deviceObject ? callingDn : calledDn;

                                    let messageChrome = {
                                        call_tel : phone,
                                        call_cust_name :newCall.call_cust_name ? newCall.call_cust_name : '',
                                        call_line_info : newCall.info_line ? newCall.info_line.line_info : ''
                                    };

                                    let messageNoti =  `고객명 : ${messageChrome.call_cust_name} <br/> 상담전화 : ${messageChrome.call_tel} <br/> 회선정보 : ${messageChrome.call_line_info}`;

                                    let notification = {
                                        title :  <FormattedMessage
                                            id="dashboard.Notification"
                                            defaultMessage="Notification"/>,
                                        message :<div dangerouslySetInnerHTML={{__html: messageNoti}}></div>,
                                        level : 'success'
                                    }
                                    this.addNotification(notification);

                                    this.saveReduxCallManualPickup(newCall,callerType,dateSetting,pickupThePhone)

                                }).catch(e => {
                                    let error  = e.response;
                                    if(error.status === 405) {
                                        this.setState({
                                            lastAccess : false,
                                            isHasACall : 1
                                        },()=>{
                                            // this.lastAccessModalAction();
                                            this.setState({
                                                lastAccessModal : true
                                            })
                                        })
                                    }
                                    progressBar.done();
                                })
                            }
                        }

                    }
                }else{
                    this.lastAccessModalAction();
                }
            })

        }
        else if (event_type == 'ConnectionClearedEvent') {
            // When A call ended
						/*
							ConnectionClearedEvent
							통화종료시에 발생된다. Release Device 를 확인해야 한다. 이 Event 는 자신과
							상대방 2 회 발생하기 때문에 ReleasingDevice 가 자기 자신은 경우에만
							처리하도록 한다. cause 가 200 또는 0 이면 일반적인 통화종료를 의미한다.
							다른값인 경우 통화중, 거절, 무응답등으로 호가 연결되지 못했음을 의한다.
							cause 값은 http response code 와 matching 된다.
							{
							"event_type":["ConnectionClearedEvent"],
							"content":{
							"monitorCrossRefID":["247"],
							"droppedConnection":[{"callID":["216557"],"deviceID":["247"]}],
							"releasingDevice":[{"deviceIdentifier":["247"]}],
							"localConnectionInfo":[""],
							"cause":[""]
							}}
						*/
            console.log('ConnectionClearedEvent');
            let callID = dataObject.droppedConnection[0].callID[0];
            let droppedDeviceID = dataObject.droppedConnection[0].deviceID[0];
            let releasingDevice = dataObject.releasingDevice[0].deviceIdentifier[0];
            // let callingDn = dataObject.callingDevice[0].deviceIdentifier[0];
            // let calledDn = dataObject.calledDevice[0].deviceIdentifier[0];
            var cause = dataObject.cause[0];
						let {user} = this.props; // 사용자 정보 확인

            console.log('ConnectionClearedEvent',dataObject);

            console.log('callID',callID);
            console.log('droppedDeviceID',droppedDeviceID);
            console.log('releasingDevice',releasingDevice);
            console.log('cause',cause);
            // close notification

            if(this.state.notificationChrome){
                this.state.notificationChrome.close();
            }

						this.setState({
                isHasACall : 1, // trang thai chuong dien thoai khi tat may
                processDuplicate : 0, // xử lí nhân đôi cuộc gọi tư vấn viên điên cho khách hàng
                notificationChrome : '',
                dataCallTemporarilySave : {}, // tro ve mac dinh
            },() => {
                // xu li tiep theo

                let {
                    receiveCallStatus, //  che do thu cong hay tu dong
                    callerType, // tu van vien hay khach hang dien thoai
                    dateSetting,
                    deviceObject,
                    pickupThePhone, // truong hop co nhac may hay khong
                    callIdCurrent, // call id luu truong hop khong nhac may thi update
                    callTabReceived,
                    callTabSendingCall,
                    accessToken,
                    callTime, // thoi gian nhac may
                    lastAccess
                } = this.state;

                let {call} = this.props;

//								// 자동 옵션 처리
//            console.log('receiveCallStatus',receiveCallStatus);
//            console.log('callerType',callerType);
//            console.log('deviceObject',deviceObject);
//            console.log('pickupThePhone',pickupThePhone);
//            console.log('callIdCurrent',callIdCurrent);
//            console.log('callTabSendingCall',callTabSendingCall);
//            console.log('callTime',callTime);
//            console.log('lastAccess',lastAccess);
//            console.log('call',call);

				/*
        let {deviceObject,accessToken} = this.state;

        let  {user} = this.props;

        //submit
        let data  = {
            user_id : user.csta_id,
            deviceObject,
            to_user : tell,
            oauth_token : accessToken,
            msg : message.content
        }

        if(message.image_messages.length > 0 ){ // co anh thi gui mms
            message.image_messages.map((image, index) => {
                data[`attach${index + 1}`] = {
                    origin_name: image.image_name,
                    upload_file:image.image_name,
                    upload_path:image.image_path,
                    type:"image"
                }
            });
            webSocketApi.SendMMS(data)
        }else{ // khong co anh thi gui api sms
            webSocketApi.SendSMS(data.user_id,data.deviceObject,data.to_user,data.msg,data.oauth_token);
        }
				*/


								if(user.info_sale_lot.status_text == 1) { // 다음에서 Call 종류를 판별해야 한다.
									// 자동 옵션 처리 (SMS 사용중일때)
									let call_type = 0;
									let call_type_descr = '';
									let call_number = '';
									if(cause == 200) { // 정상 종료 (이벤트를 옮긴다)
										if (releasingDevice == deviceObject) // 070 쪽 이벤트로 처리한다
										{
											if (callIdCurrent) // 수신
											{
												call_type = 2; // 발신콜 (고객정보에서 취득)
												call_number = call.call_tel;
												call_type_descr = '발신콜';
											} else {
												call_type = 1; // 수신콜
												call_number = call.call_tel;
												call_type_descr = '수신콜';
											}
										}
									} else { // 에러 ? 수신거부/부재중전화 ? 487										

										if (releasingDevice != deviceObject) // 070 쪽 이벤트는 버린다.
										{
											if (callIdCurrent) // 수신
											{
												call_type = 3; // 수신 부재중
												call_number = releasingDevice;
												call_type_descr = '수신부재콜';
											}
										} else {
											if (!callIdCurrent) // 발신 부재콜이 되려면..callIdCurrent 가 없어야 한다.
											{
												call_type = 4; // 발신 부재중 (고객정보에서 취득)
												call_number = call.call_tel;
												call_type_descr = '발신부재콜';
												// 발신 부재시 자동전송이 필요 할수 있다.
												this.send_auto_send_absent_call(user.info_sale_lot.lots_id,call_type,call_number);
											}
										}
									}
									if (call_type > 0)
									{
										console.log('자동전송형식 : ',call_type_descr);
				            console.log('자동전송번호 : ',call_number);
										this.send_auto_send(user.info_sale_lot.lots_id,call_type,call_number);
									}
								}

                if(releasingDevice == deviceObject){
                    // set notification

                    if(lastAccess) { // truy cap sau cung se vo day
                        this.addNotification(notificationCallEnd);

                        if (!pickupThePhone) { // truong hop khong nhac may luu cuoc goi nho
                            let data = {
                                call_check: 2, // update to 2 :  unanswered  call,
                                call_id_api: callID,
                                receiveCallStatus,
                                call_date: dateSetting
                            }
                            // chỉ có cuộc gọi nhỡ khách hàng gọi đến mới vô trường hợp này.
                            if (callIdCurrent) {

                                setTimeout(() => {// xu li truong hop call pickup
                                    api.call.getCallCheck(callIdCurrent).then(res => {
                                        let currentCall = res.data.data;
                                        console.log(currentCall,'goi nho ')
                                        if(currentCall && currentCall.call_check != 6){
                                            api.call.updateCall(callIdCurrent, data).then(res => {
                                                if (callTabReceived == 2 || callTabSendingCall == 2) {
                                                    this.__initCallListRedux();
                                                }
                                            }).catch(e => {
                                                if (callTabReceived == 2 || callTabSendingCall == 2) {
                                                    this.__initCallListRedux();
                                                }
                                            })
                                        }
                                    })
                                },2000)
                            }
                        }

                        this.setState({
                            callerType : 'customer', // set lai mac dinh
                            pickupThePhone : false , // tat may
                            callTime : '',
                            callIdCurrent: '', // reset call_id
                        })
                    }

                } else {
                    const phone_number = releasingDevice;
                    const data = {
                        phone_number,
                        device_number: this.state.deviceObject,
                        event: 'Hanging up',
                        step: 6
                    }
                    api.logging.loggingCallNotify(data)
                }

/*
상담원이 CRM 을 통해 전화를 걸때
callID 306627083
TelephoneCounselling.js:1373 droppedDeviceID 07080950098
TelephoneCounselling.js:1374 releasingDevice 01033661036
TelephoneCounselling.js:1375 cause 200
TelephoneCounselling.js:1396 receiveCallStatus manual
TelephoneCounselling.js:1397 callerType customer
TelephoneCounselling.js:1398 deviceObject 07080950098
TelephoneCounselling.js:1399 pickupThePhone true
TelephoneCounselling.js:1400 callIdCurrent 
TelephoneCounselling.js:1401 callTabSendingCall 1

callID 306627083
TelephoneCounselling.js:1373 droppedDeviceID 07080950098
TelephoneCounselling.js:1374 releasingDevice 07080950098
TelephoneCounselling.js:1375 cause 200
TelephoneCounselling.js:1396 receiveCallStatus manual
TelephoneCounselling.js:1397 callerType customer
TelephoneCounselling.js:1398 deviceObject 07080950098
TelephoneCounselling.js:1399 pickupThePhone true
TelephoneCounselling.js:1400 callIdCurrent 
TelephoneCounselling.js:1401 callTabSendingCall 1
*/
/*
삼담원이 CRM 을 통해 전화를 받을때
callID 306627950
TelephoneCounselling.js:1373 droppedDeviceID 07080950098
TelephoneCounselling.js:1374 releasingDevice 01033661036
TelephoneCounselling.js:1375 cause 200
TelephoneCounselling.js:1396 receiveCallStatus manual
TelephoneCounselling.js:1397 callerType customer
TelephoneCounselling.js:1398 deviceObject 07080950098
TelephoneCounselling.js:1399 pickupThePhone true
TelephoneCounselling.js:1400 callIdCurrent 217566
TelephoneCounselling.js:1401 callTabSendingCall 1

callID 306627950
TelephoneCounselling.js:1373 droppedDeviceID 07080950098
TelephoneCounselling.js:1374 releasingDevice 07080950098
TelephoneCounselling.js:1375 cause 200
TelephoneCounselling.js:1396 receiveCallStatus manual
TelephoneCounselling.js:1397 callerType customer
TelephoneCounselling.js:1398 deviceObject 07080950098
TelephoneCounselling.js:1399 pickupThePhone true
TelephoneCounselling.js:1400 callIdCurrent 217566
TelephoneCounselling.js:1401 callTabSendingCall 1
*/

/* 
발신 부재중
callID 306629340
TelephoneCounselling.js:1373 droppedDeviceID 07080950098
TelephoneCounselling.js:1374 releasingDevice 07080950098
TelephoneCounselling.js:1375 cause 487
TelephoneCounselling.js:1396 receiveCallStatus manual
TelephoneCounselling.js:1397 callerType counselling
TelephoneCounselling.js:1398 deviceObject 07080950098
TelephoneCounselling.js:1399 pickupThePhone false
TelephoneCounselling.js:1400 callIdCurrent 
TelephoneCounselling.js:1401 callTabSendingCall 1
TelephoneCounselling.js:1402 callTime 

수신 부재중
callID 306640700
TelephoneCounselling.js:1373 droppedDeviceID 07080950098
TelephoneCounselling.js:1374 releasingDevice 07080950098
TelephoneCounselling.js:1375 cause 487
TelephoneCounselling.js:1406 receiveCallStatus manual
TelephoneCounselling.js:1407 callerType customer
TelephoneCounselling.js:1408 deviceObject 07080950098
TelephoneCounselling.js:1409 pickupThePhone false
TelephoneCounselling.js:1410 callIdCurrent 217569
TelephoneCounselling.js:1411 callTabSendingCall 4

*/


            })

        }
        else if (event_type == 'HeldEvent') {
            console.log('HeldEvent')
            let callID = dataObject.heldConnection[0].callID[0];

        }
        else if (event_type == 'RetrievedEvent') {
            console.log('RetrievedEvent')

            let callID = dataObject.retrievedConnection[0].callID[0];

        }
        else if (event_type == 'TransferredEvent') {
            console.log('TransferredEvent')
            let primaryOldCall = dataObject.primaryOldCall[0].callID[0];
            let secondaryOldCall = dataObject.secondaryOldCall[0].callID[0];
            let transferringDevice = dataObject.transferringDevice[0].deviceIdentifier[0];
            let transferredToDevice = dataObject.transferredToDevice[0].deviceIdentifier[0];

            // my device did transfer.
        }else if ( event_type == 'RetrievedEvent') {
            console.log('RetrievedEvent')
        }

        else if( event_type == "AgentLoggedOnEvent"){
            console.log('AgentLoggedOnEvent')

            if(this.state.acd == 1) {

            }
        }else if (event_type == "AgentReadyEvent") {
            console.log('AgentReadyEvent')
//            acdStatus,  // 0 : trang thai normal chua lam gi ca, 1 :AgentReadyEvent, 2 : AgentNotReadyEvent , 3 : Khi ở trạng thái này là khi ban đầu nó trạng thái (AgentReadyEvent) có 1 cuộc gọi chưa bấm save thì vào đây. Khi save rồi chuyên trạng thái bằng AgentReadyEvent

            let {call} = this.props;
            let {
                acdStatus,
            } = this.state;
            if(call.call_id && acdStatus == 3 ) {
                console.log('da vao doi trang thai bang 7 ')
                this.changeMyStatus(7);;
                return ;
            }

            webSocketApi.changeMyStatusForCsta(6,myInfo);


        }else if( event_type == "AgentNotReadyEvent"){
            console.log('AgentNotReadyEvent')
            var subcode = dataObject.privateData[0];
            console.log(subcode,'subcode')
            webSocketApi.changeMyStatusForCsta(3,myInfo);


        } else if( event_type == "AgentBusyEvent"){

            console.log("AgentBusyEvent");
        }else if( event_type == "AgentWorkingAfterCallEvent"){
            console.log('AgentWorkingAfterCallEvent')
            webSocketApi.changeMyStatusForCsta(7,myInfo);
        }


        else if( event_type == "CSTAErrorCode") { //error case
            var reason = dataObject.availability[0];
            var oper = dataObject.operation[0];
            console.log(reason,'reason CSTAErrorCode')
            console.log(oper,'oper CSTAErrorCode')
        }else if( event_type == "CSTA_CMD_MONITOR_START_FAIL") {

            let error = {
                title : <FormattedMessage
                    id="dashboard.Error"
                    defaultMessage="Error"/>,
                message : "CSTA Connect Fail",
                level : 'error'
            }
            this.addNotification(error);

        }else if (event_type == 'CSTA_CMD_AUTH_FAIL') {
            let error = {
                title : <FormattedMessage
                    id="dashboard.Error"
                    defaultMessage="Error"/>,
                message : "CSTA Connect Fail",
                level : 'error'
            }
            this.addNotification(error);
        }
        else if(event_type == "CallBack" ){
            console.log('CallBack')
            
        }else if(event_type == 'ws-close'){
            console.log('reload csta')

            this.reloadAccessTokenCsta();
        }
    };

    changeCallTab = tab => {
        this.setState({
            callTab : tab,
            search_key : '', // reset lai key search
        },()=>{
            this.__initCallListRedux();

        })
    }

    changeCallReceived = tab => {
        this.setState({
            callTabReceived : tab,
            search_key : '', // reset lai key search
        },()=>{

            this.__initCallListRedux();

        })
    }

    changeCallSendingCall = (tab) => {
        this.setState({
            callTabSendingCall : tab,
            search_key : '', // reset lai key search
        },()=>{
            this.__initCallListRedux();

        })
    }

    // change page call list
    changePageCallList = (page) => {
        this.__initCallListRedux(page);
    }

    // change page call list by number
    changePageCallListByNumber = (page) => {
        let {call} = this.props;
        this.__initSearchByCallNumber(call.call_tel,page)
    }

    setValueFirstAndSecondItemSummaryBox = (detailList) => { // detail cua cuoc goi
        let value1 = '';
        let value2 = '';
        detailList.map(detail => {
            if (+detail.call_m_id === this.state.idOfFirstItemSummaryBox) {
                value1 = detail.call_dtl_name
            }
            if (+detail.call_m_id === this.state.idOfSecondItemSummaryBox) {
                value2 = detail.call_dtl_name
            }
        });
        this.setState({
            valueOfFirstItemSummaryBox: value1,
            valueOfSecondItemSummaryBox: value2
        });
    }

    onClickIdCall = (itemCall) => {
        // console.log(itemCall, 'itemCall');
        // chuc nang nay click lay 1 call de set vao redux ( chinh sua id call do )
        //progressBar.start();

				// 최고 관리자는 상담현황 예약 처리 안된다. (실시간 변화)
				let idOfFirstItemSummaryBox = null;
				let idOfSecondItemSummaryBox = null;

				api.infoMast.getInfoMast().then(res_detail => {
					progressBar.start();
					let infoMasts = res_detail.data.data;
					infoMasts.map(infoMast => {
							if (infoMast.m_name === '호응도') {
									idOfFirstItemSummaryBox = infoMast.m_id
							}
							if (infoMast.m_name === '희망평형') {
									idOfSecondItemSummaryBox = infoMast.m_id
							}
					});
					progressBar.done();
					this.setState({

						idOfFirstItemSummaryBox,
						idOfSecondItemSummaryBox,
						infoMasts,
						selCallId: itemCall.call_id

					},()=> {

						this.props.fetchCall(itemCall.call_id).then(res => { // get id call and fetch redux call

							console.log('res :',res);

							let detailList = res.infoCall.call_detail;
							if(detailList.length > 0 ) {
									this.setValueFirstAndSecondItemSummaryBox(detailList)
							}

							// search by
							this.__initSearchByCallNumber(itemCall.call_tel);
							if(res.infoCall.callback == 1){
									this.__initCountCallBackByUser();
							}

						});

					});

        }).catch(e => {
            progressBar.done();
        });

    }

    // thay doi ngay he thong hoac co id call doi ngay cua cuoc goi do
    onChangeDateSetting = (date) => {
        let data = {
            call_date  : date
        }
        this.props.editCallColumn(data);

        // set lai gio he thong
        localStorage.setItem("call-date", date);

        this.setState({
            dateSetting : date
        },()=>{
            this.__initCallListRedux();
        })
    }

    // edit form set lai redux
    onChangeForm = data => {
        this.props.editCallColumn(data)
    }

    // edit call_detail set lai redux
    onChangeCallDetail = call_detail => {
        this.props.editCallColumn(call_detail)
    }

    // thay doi che do cuoc goi tu dong hay thu cong
    onChangeReceiveCallStatus = value => {
        localStorage.setItem("receive-call-status",value);
        this.setState({
            receiveCallStatus : value
        })
    }

    // thay doi che do cai dat du lieu 1 ngay hoac tat ca cac ngay
    onChangeDateSettingStatus = value => {
        this.setState({
            dateSettingStatus : value
        },()=>{
            this.__initCallListRedux();
        })
    }

    onChangeAbsentCall = value => {
        this.setState({
            absentCall : value
        })
    }

    // save call form
    onClickSaveFormCallWithOutChange = (e) => {
			e.preventDefault();
			this.setState({
				...this.state,
				saveWithoutChange: true
			},() => {
				this.onClickSaveFormCall(e);
			})
		}

    // save call form
    onClickSaveFormCall = (e) => {
        e.preventDefault();
        let {
            call,
        } = this.props;
        let {
            absentCall, // nut check box tren man hinh, xu li co luu cuoc goi nho hay ko
            acdStatus,
						saveWithoutChange
        } = this.state;


        // check loi co so dien thoai hay ko
        if(!call.call_tel || call.call_tel == '' || call.call_tel.length < 8){
            progressBar.done();
            return this.addNotification(callTellRequirement);
        }

        if(!call.call_agree){
            call.call_agree = 0;
        }

        progressBar.start(); console.log('SAVE_CALL_', call.call_id);
        if(call.call_id) { // update call

            // check truong hop makecall call_check = 5
            if(call['call_check'] == 5) {
                //comment chức năng absentCall tạm thời
                // if(!absentCall){
                //     progressBar.done();
                //     return this.addNotification(errorCheckBox);
                // }
                call['call_check'] = 4;

            }else{
                // fix call check khi call_check = 3 => 2
                call['call_check'] = call['call_check'] == 3 ? 2 : call['call_check'];
            }

						call['saveWithoutChange'] = saveWithoutChange;

            api.call.updateCall(call.call_id,call).then(res => {
							 this.send_auto_send_create_call(call); // 자동전송기능 ?
               progressBar.done();
               this.resetFormCall();
               this.__initCallListRedux();
               // acdStatus : 3 // 3 : Khi ở trạng thái này là khi ban đầu nó trạng thái (AgentReadyEvent) có 1 cuộc gọi chưa bấm save thì vào đây. Khi save rồi chuyên trạng thái bằng AgentReadyEvent
               if(acdStatus == 3){
                   // chuyển trạng thái acd
                   this.onClickCallACD();
               }
            }).catch(e => {
                const { errors } = e.response.data;
                const { message } = errors;
                for(const key of Object.keys(message)) {
                    this.addNotification(duplicateError(message[key][0]));
                }
                progressBar.done();
            })
        }else{ // create call

            let {
                callTab,   // 1 : tab danh sách cuộc gọi đến, 2 : tab danh sách cuộc gọi di
                callTabReceived, // tab danh sach khach hang goi den
                callTabSendingCall, // tab danh sach cuoc goi di
                dateSetting
            } = this.state;

            // truong hop save binh thuong
            switch (callTab) {
                case 1 : // 1 : tab danh sách cuộc gọi đến
                    call['call_check'] = callTabReceived
                    break;
                case 2: //2 : tab danh sách cuộc gọi di
                    call['call_check'] = callTabSendingCall
                    break
            }

            call['call_date'] = dateSetting;
            call['saveWithoutChange'] = false; // 생성시에는 반드시 변경

						console.log('SAVE_CALL_DEBORE', call);

            // 2023-08-24 새로 저장시 동의 비동의에 의한.. 자동 전송 기능 사용 ?
            api.call.createCall(call).then(res => {
								this.send_auto_send_create_call(call); // 자동전송기능 ?
                progressBar.done();
                this.resetFormCall();
                this.__initCallListRedux();
            }).catch(e => {
                const { errors } = e.response.data;
                const { message } = errors;
                for(const key of Object.keys(message)) {
                    this.addNotification(duplicateError(message[key][0]));
                }
                progressBar.done();
            })
        }
    }

    // reset All Form data
    resetFormCall = () => {
        this.props.resetCall();
        this.props.clearSearchCallByNumber();
        this.setState({
            valueOfSecondItemSummaryBox: '',
            valueOfFirstItemSummaryBox: '',
            absentCall : false
        })
    }

    onClickMakeCall = phoneNumber => {
        if(!phoneNumber || phoneNumber.length <= 7 ){
            progressBar.done();
            return this.addNotification(callRequirement);
        }

        // dien thoai

        let {
            deviceObject,
            accessToken
        } = this.state;

        webSocketApi.cstaMakeCall(deviceObject,
            phoneNumber,
            accessToken);
    }

    // modal

    callModalDeleteAction = () => this.setState({callModalDelete : !this.state.callModalDelete});

    reportModalAction = () =>  {

			let param =  {
					lots_id : this.state.sceneLostId?this.state.sceneLostId:'',
					company_id : this.state.sceneCompanyId?this.state.sceneCompanyId:'',
					see_all : localStorage.getItem("see_history") === '1' ? true : false
			}
			// data 를 api 로 가져온다.
			api.statistics.getDailyItemStatus(param)
			.then(response => {
				console.log("getDailyItemStatus response:",response);
        this.setState({
					dailyItemReport : response.data.data,
					reportModal : !this.state.reportModal
        },()=>{
					if(!this.state.reportModal){// reset report []
							this.props.resetReportTelephone();
					}
        });
			})
			.catch(error => {
				console.log("ERROR");
			});

    }

    messageModalAction = (event) => {
        this.setState({messageModal : !this.state.messageModal})
        if(event && event === 'close') {
            this.setState({
                callSmsNumber: ''
            })
        }
    }

    deleteCall = id => {
        api.call.deleteCallV2(id).then(res=> {
            this.callModalDeleteAction();
            this.__initCallListRedux();
        })
    }

    // chuc nang coi list xoa hay ko xoa
    isDeleteList = () => {
        this.setState({
            isDelete : !this.state.isDelete
        },()=>{
            this.__initCallListRedux();
            let {call} = this.props;

            if(call.call_id) { //  fetch lại list search call by number.
                this.__initSearchByCallNumber(call.call_tel,1);
            }
        })
    }

    submitDateReport = date => {
        let query = querystring.stringify(date)+'&lots_id='+(this.state.sceneLostId?this.state.sceneLostId:'')+'&company_id='+(this.state.sceneCompanyId?this.state.sceneCompanyId:'');
        return this.props.fetchReportTelephone(query)
    }

    submitMessage = (message,tell) => {
			
        let {messages} = this.props;
        // validate
        if(!tell || tell.length < 8){
            return this.addNotification(callRequirement);
        }
        // validate chua chon message
        if(Object.keys(message).length === 0){

            // neu chua chua click message nao ca thi roi vao truong hop nay
            // truong hop co message mac dinh trong he thong thi set message mac dinh he thong .
            if(messages.data && messages.data.length) {
                messages.data.map(item => {
                    if(item.status == 1) {
                        message = item;
                    }
                })
            }
            if(Object.keys(message).length === 0) {
                return this.addNotification(errorMessage);
            }
        }
        // validate khong co content
        if(!message.content) {
            return this.addNotification(errorContent);
        }

        let {deviceObject,accessToken} = this.state;

        let  {user} = this.props;

        //submit
        let data  = {
            user_id : user.csta_id,
            deviceObject,
            to_user : tell,
            oauth_token : accessToken,
            msg : message.content
        }

				let dataMessageDirect = {};
					
        if(message.image_messages.length > 0 ){ // co anh thi gui mms
            message.image_messages.map((image, index) => {
                data[`attach${index + 1}`] = {
                    origin_name: image.image_name,
                    upload_file:image.image_name,
                    upload_path:image.image_path,
                    type:"image"
                }
            });
						dataMessageDirect = {
								lots_id : this.state.sceneLostId,
								phone : tell,
								contents : message.content,
								image_messages : {},
						}
            message.image_messages.map((image, index) => {
                dataMessageDirect.image_messages[index] = {
                    origin_name: image.image_name,
                    upload_file:image.image_name,
                    upload_path:image.image_path,
                    type:"image"
                }
            });
            //webSocketApi.SendMMS(data)
						
						api.bulkSending.SendSMSDirect(dataMessageDirect).then(response => {
							 
							 //progressBar.done();
						}).catch(error => {        
								console.log("error:",error);
								this.addNotification({
										title: "문자전송 실패",
										message: "문자 전송 실패 (상담내역이 존재하지 않으면 상남내용을 병합할수 없습니다",
										level: 'error',
								})
								//progressBar.done();
						});
						
						//api.bulkSending.SendSMSDirect(dataMessageDirect);
        }else{ // khong co anh thi gui api sms
            //webSocketApi.SendSMS(data.user_id,data.deviceObject,data.to_user,data.msg,data.oauth_token);
						dataMessageDirect = {
								lots_id : this.state.sceneLostId,
								phone : tell,
								contents : message.content,
								image_messages : {},
						}
						api.bulkSending.SendSMSDirect(dataMessageDirect).then(response => {
						}).catch(error => {        
								console.log("error:",error);
								this.addNotification({
										title: "문자전송 실패",
										message: "문자 전송 실패 (상담내역이 존재하지 않으면 상남내용을 병합할수 없습니다",
										level: 'error',
								})
								//progressBar.done();
						});
        }

        // gui xong sms thi luu vao db history

        let {directMessage} = this.props;

        let dataMessageHistory = {};
        if(directMessage.data && directMessage.data.mess_id){ // user da cho direct message
            dataMessageHistory = {
                mess_id : directMessage.data.mess_id,
                rec_usr_id : user.usr_id,
                phone_number : tell,
                send_type : 1, //0:auto sending,1:SMS/MMS,2:bulk sending
            }
            api.textManager.createMessageHistory(dataMessageHistory);

        }else{// user chua co direct message, tao xong roi tao history message
            this.saveDirectMessage(message).then(res => {
                dataMessageHistory = {
                    mess_id : res.mess_id,
                    rec_usr_id : user.usr_id,
                    phone_number : tell,
                    send_type : 1, //0:auto sending,1:SMS/MMS,2:bulk sending
                }
                api.textManager.createMessageHistory(dataMessageHistory);
            })
        }

        // an modal
        this.messageModalAction();
    }

    saveDirectMessage = message => {
        if(!message.content) {
            return this.addNotification(errorContent);
        }

        let {directMessage} = this.props;

        if(directMessage.data && directMessage.data.mess_id){
            return this.props.updateMessageDirect(message);
        }else{ // create
            return this.props.createMessageDirect(message);
        }

    }

    onClickAbsenceOnline = dnd_type => {

        let set_dnd_type = dnd_type == 1 ? 2 : 1;  // 1 la tu choi cuoc goi, 2 la cho phep cuoc goi

        // 0 HUNT, 1 :ACD
        const {acd} = this.state;

        if(acd == 0) {
            this.props.disturbResponse({dnd_type : set_dnd_type}).then(res=> {

                let {accessToken,deviceObject} = this.state;

                webSocketApi.cstaSetDoNotDisturb(
                    deviceObject,
                    set_dnd_type,
                    accessToken);

                this.setState({
                    dnd_type : set_dnd_type
                })
            })
        }else if (acd == 1) {
            let {myInfo} = this.state;

            this.changeMyStatus(3);

            axios({
                method : "POST",
                url : "https://rtc.goodfone.co.kr"+":9852/patch",
                data:JSON.stringify({"url": "https://sky-rtc-f8169.firebaseio.com"+"/USERS/"+myInfo.company+"/"+myInfo.userid,"json":{"presence" : 1+""}, "oauth_token" : myInfo.access_token}),
                crossDomain : true,
                dataType:"json"
            }).then(res => {
                axios({
                    method : "POST",
                    url : "https://rtc.goodfone.co.kr"+":9852/patch",
                    data:JSON.stringify({"url": "https://sky-rtc-f8169.firebaseio.com"+"/USERS/"+myInfo.company+"/"+myInfo.userid,"json":{"presence" : 3+""}, "oauth_token" : myInfo.access_token}),
                    crossDomain : true,
                    dataType:"json"
                }).then(res => {

                });
            });

            this.setState({
                acdStatus : 2,// 0 : trang thai normal chua lam gi ca, 1 :AgentReadyEvent, 2 : AgentNotReadyEvent , 3 : Khi ở trạng thái này là khi ban đầu nó trạng thái (AgentReadyEvent) có 1 cuộc gọi chưa bấm save thì vào đây. Khi save rồi chuyên trạng thái bằng AgentReadyEvent
            })

        }

    }

    changeForwardCall = forwardCall => {
        this.setState({
            forwardCall
        })
    }

    forwardModalAction = () => {
        let {
            forward,
            forwardNumber
        } = this.state;
        let {user} = this.props;

        if(forward == "Y") { // xoa forward

            let dataCheckForward = {
                "user_id":user.csta_id,
                "password":user.csta_pwd,
                "forward_number" : forwardNumber,
                "job_type" : {
                    "FORWARD_ALL" : "0",
                    "FORWARD_TIMEOUT" : "0",
                    "FORWARD_BUSY" : "0",
                    "FORWARD_UNREGISTER" : "0"
                }
            }
            axios({
                method : 'post',
                url : 'https://rtc.goodfone.co.kr:9798/post/call_forwardv2.json',
                data : JSON.stringify(dataCheckForward),
                crossDomain : true,
                dataType:"json",
            }).then(res => {
                this.setState({
                    forward : "N",
                    forwardNumber : ''
                })
            }).catch(e => {
                // console.log(e.response);
            })
            return true;
        }

        this.setState({ forwardModal : !this.state.forwardModal });
    }

    submitForwardCall = () => {
        let {
            forwardCall,
            deviceObject,
            accessToken,
        } = this.state;

        let {user} = this.props;

        if(!forwardCall || forwardCall.length < 8){
            return this.addNotification(errorNumber);
        }

        //deviceObject, forwardType, parkNum, accessToken

        //0. hủy call forward, call forward vô điều kiện,
        // 1 forward vo dieu kien
        // 2 call forward âm dương ( khi thiết bị không truy cập được hoặc nguồn bị off thì sẽ chuyển đến số call forward đã được chỉ định trước đó),
        // 3. call forward khi đang nghe cuộc gọi (khi 1 khách hàng đang nghe đt sẽ chuyển đến số điện thoại khác đã được chỉ định sẵn),
        // 4 call forward không reply
        let dataForward = {
            "user_id":user.csta_id,
            "password":user.csta_pwd,
            "forward_number" : forwardCall,
            "job_type" : {
                "FORWARD_ALL" : "1",
                "FORWARD_TIMEOUT" : "0",
                "FORWARD_BUSY" : "0",
                "FORWARD_UNREGISTER" : "0"
            }
        }


        axios({
            method : 'post',
            url : 'https://rtc.goodfone.co.kr:9798/post/call_forwardv2.json',
            data : JSON.stringify(dataForward),
            crossDomain : true,
            dataType:"json",
        }).then(res => {
            this.setState({
                forward : "Y",
                forwardNumber :forwardCall
            })
        }).catch(e => {
            // console.log(e.response);
        })


        this.forwardModalAction();
    }

    openPopupTelephone = () => { // open popup
        // console.log('quanque')
    }

    recordingModalAction = () => {
        this.setState({
            recordingModal : !this.state.recordingModal
        },()=>{
            if(!this.state.recordingModal){
                this.setState({
                    namePatchRecoding : ''
                })
            }
        })
    }

    getIdRecording = (event,id) =>{
        event.preventDefault();
        // console.log(id)
        api.telephone.getRecording(id).then(res => {
            let data = res.data.data;
            this.setState({
                namePatchRecoding : data
            },()=>{
                this.recordingModalAction();// mo modal recording
            })

        }).catch(e =>{
            let error = e.response;
            if(error.status == 402 ){
                this.addNotification(notificationRecording);
            }
        })
    }

    changeManagerModalAction = () => {
        this.setState({
            isOpenChangeManagerModal : !this.state.isOpenChangeManagerModal
        },()=>{
            if(!this.state.isOpenChangeManagerModal){ // init
                this.setState({
									 selUsrId: '',
									 selLotsId: '',
									 selCallId: '',
                   recentChangeManagerList : []
                })
            }
        })
    }

		setSelUsrId = (usr_id) => {
       this.setState({
				 ...this.setState,
				 selUsrId: usr_id,
			 });
		}

		// 상담자 변경 모달
		changeManagerModal = (call_id, lots_id, usr_id) => {

			//event.preventDefault();
			// 상담원 리스트가 필요하다 ? 전체..
			let params = {
				lots_id : lots_id,
				per_page : 100000, 
				page : 1
			}
			
			api.user.getAllUsersBySaleLot(params).then(response => {

				 this.setState({
						 ...this.state,
						 selUsrId: usr_id,
						 selLotsId: lots_id,
						 selCallId: call_id,
						 managerList: response.data.data.data,
						 recentChangeManagerList: [] // 초기화
				 }, () => {
						// recentChangeManagerList 구해준다.
						let params_history = {
							call_id : call_id,
							per_page : 5, 
							page : 1
						}
						api.call.getCounselorChangeHistory(call_id, params_history).then(response => {
							console.log('getCounselorChangeHistory:',response);
							this.setState({
									...this.state,
									recentChangeManagerList: response.data.data.data
							},() => {
								this.changeManagerModalAction(); // OPEN
							});
							
						}, () => {
							// 에러 ?
						});
				 })
				 //progressBar.done();
			}).catch(error => {        
					this.addNotification({
							title: <FormattedMessage
									id="dashboard.datasetting.notice"
									defaultMessage="Notice send"/>,
							message: <FormattedMessage
									id="dashboard.notice.send.fail"
									defaultMessage="Notice failed!"/>,
							level: 'error',
					})
					//progressBar.done();
			});

		}

		// 변경내역을 반영한다...
		submitChange = (usr_id) => {

			let { selLotsId,selCallId } = this.state;
			console.log('usr_id:',usr_id);
			let call = {
				rec_usr_id: usr_id
			};
			this.changeManagerModalAction(); // CLOSE

      api.call.updateCallOnlyManager(selCallId,call).then(res => {				
				console.log('res:', res);
				// 다시 그려준다 ?
				this.changePageCallListByNumber(1);
			});

		}

    lastAccessModalAction = () => this.setState({ lastAccessModal : !this.state.lastAccessModal });

    searchKeyFilter = (nameField) => {
       const {call} = this.props;
       if(call && call[nameField]){
           const search_key = {
               'name' : nameField,
               'word' : call[nameField] 
           };
           let company_id = null
           if(this.state.sceneCompanyId) {
               company_id = this.state.sceneCompanyId;
           }

           if(call[nameField].length > 12) {
               return this.addNotification(errorNumber);
           }

           // truong hop search ô input serch tất cả các ngày nên dateSettingStatus chuyển thành all .
           this.setState({
               search_key,
               // dateSettingStatus : 'all', // search tat ca cac ngay
           },()=>{
               this.props.searchKeyCall(JSON.stringify(search_key), company_id).then(res => {

                   if(res){
                       /** callTabChild
                        * 0 : la cuoc goi khách hàng gọi đến tư vấn viên nhận
                        * 1 : là cuộc gọi tư vấn viên điện cho khách hàng nhận
                        * 2 : là cuộc gọi nhỡ khách hàng điện cho tư vấn viên
                        * 3 : cuộc gọi chờ, ở trạng thái DeliveredEvent khi kết thúc nó sẽ chuyển trạng thái.
                        * 4 : cuộc gọi nhỡ khách hàng tạo sau khi nhấn save ( cuộc gọi vắng mặt )
                        * 5 : cuoc goi cho tu van vien dien cho khach hang
                        * */
                           //1 : page, 2 :search key
                           // setState de load du lieu __initCallListRedux

                           //  callTab,// 1 : tab danh sách cuộc gọi đến, 2 : tab danh sách cuộc gọi di
                           // callTabReceived : 0,
                           //     callTabSendingCall : 2,
                           //dateSetting
                           //isDelete : type (boolean), // state the hien xoa hay ko xoa 0 : khong xoa, 1 xoa
                       let setCallTab = this.state.callTab;
                       let setCallTabReceived = this.state.callTabReceived;
                       let SetCallTabSedingCall = this.state.callTabSendingCall;
                       if(res.call_check == 1 || res.call_check == 2 || res.call_check == 4) {
                           setCallTab = 2;
                           SetCallTabSedingCall = parseInt(res.call_check);
                       }else if(res.call_check == 0){
                           setCallTab = 1;
                           setCallTabReceived = parseInt(res.call_check);
                       }

                       this.setState({
                           callTab : setCallTab,
                           callTabReceived : setCallTabReceived,
                           callTabSendingCall : SetCallTabSedingCall,
                           // dateSetting : moment(res.call_date).format('YYYY-MM-DD'),
                           isDelete : res.is_deleted == 1 ? true : false,
                       },()=>{
                           this.__initCallListRedux(1);
                       })
                   }else{
                       // truong hop tra ve null
                       // set lai redux init calllist
                       this.props.resetCallList();
                   }
               })

           })

       }
    }

    validateSearch = (type, value) => {
        switch (type) {
            case 'text':
                if(value && value.length > 12) {
                    return true
                }
                break;
            case 'number':
                if((value && value.length > 12)) {
                    return true
                }
            default:
                break;
        }
        return false;
    } 

    onClickCallACD = () => {
        if(this.state.acd == 1) {
            let {
                myInfo
            } = this.state;

            this.changeMyStatus(6);
            axios({
                method : "POST",
                url : "https://rtc.goodfone.co.kr"+":9852/patch",
                data:JSON.stringify({"url": "https://sky-rtc-f8169.firebaseio.com"+"/USERS/"+myInfo.company+"/"+myInfo.userid,"json":{"presence" : 6+""}, "oauth_token" : myInfo.access_token}),
                crossDomain : true,
                dataType:"json"
            });

            this.setState({
                acdStatus : 1, // 0 : trang thai normal chua lam gi ca, 1 :AgentReadyEvent, 2 : AgentNotReadyEvent , 3 : Khi ở trạng thái này là khi ban đầu nó trạng thái (AgentReadyEvent) có 1 cuộc gọi chưa bấm save thì vào đây. Khi save rồi chuyên trạng thái bằng AgentReadyEvent
            })

        }
    }

    componentWillUnmount() {
        this.props.resetCall();
        this.props.clearSearchCallByNumber();
        // this.notificationSystem = null;
        let {user} = this.props;
        window.Echo.leaveChannel('callback-'+user.company_id);
        clearInterval(interval);
        clearInterval(refreshInterval);
        // window.Echo = null;
    }

    setCallSmsNumber = (number) => {
        this.setState({
            callSmsNumber: number
        })
    }

    render() {

        // forwardModal : false, //modal forward
        //     forwardCall : '', // call forward
        //     forward : false, // check co foward hay khong
        //     forwardNumber : '', // xem forward so nao


            let {
            callTab,
            callTabReceived,
            callTabSendingCall,
            dateSetting, // ngay cai dat he thong
            infoMasts,
            districts,
            receiveCallStatus, // che do thu cong hay tu dong
            dateSettingStatus, // thay doi che do cai dat du lieu 1 ngay hoac tat ca cac ngay
            absentCall, // state nay dung de co luu cuoc goi nho hay khong (부재중콜) nut check box tren man hinh

            valueOfFirstItemSummaryBox, // 4 item he thong cu
            valueOfSecondItemSummaryBox, // 4 item he thong cu
            isHasACall, // trang thai chuong dien thoai
            callModalDelete,
            isDelete,
            reportModal,
            messageModal, // modal gui tin nhan
            accessToken, // accessToken ben thu 3
            dnd_type, // chuc nang tu choi cuoc goi

            forwardModal, // forward call
            forwardCall, // so de forward

            recordingModal,
            namePatchRecoding,
            lastAccessModal,
            callbackNotification, // calback notification
            forward,
            forwardNumber,
            search_key,
            acd,// 0 : HUNT, 1 ACD
            acdStatus,// 0 : trang thai normal chua lam gi ca, 1 :AgentReadyEvent, 2 : AgentNotReadyEvent , 3 : Khi ở trạng thái này là khi ban đầu nó trạng thái (AgentReadyEvent) có 1 cuộc gọi chưa bấm save thì vào đây. Khi save rồi chuyên trạng thái bằng AgentReadyEvent
            callSmsNumber,
						predefineSMS,
						send_sms_item,
            } = this.state;

        let {
            callList,
            call,
            callListSearchByCallNumber, // props khi click id serch so dien thoai hien thi list danh sach so do
            user,
            reportTelephone,
            messages,
            directMessage,
            changeMemo
        } = this.props;

				console.log("send_sms_item:",send_sms_item);
				let modal_title = send_sms_item?.name ? send_sms_item.name : "";

        return (
            <Fragment>
                <NotificationSystem
                    ref={this.notificationSystem}
                    allowHTML={this.state.notification.allowHTML}
                />
                <FormattedMessage id="dashboard.TelephoneCounselling"
                    defaultMessage="Telephone Counselling">
                    {message => <Helmet>
                        <title>{message}</title>
                    </Helmet>}
                </FormattedMessage>

                <div className="main_top_bar">
                    <div className="box_sum_col_top">
                        <div className="box_left conslution">
                            <Nav tabs className={'nav_tabs'}>
                                <NavItem className={'nav_item'}>
                                    <NavLink
                                      className={callTab == 1 ? 'active nav_link' : 'nav_link'}
                                      onClick={()=>this.changeCallTab(1)} // change tab bang 1 danh sach cuoc goi den
                                    >
                                        수신목록
                                    </NavLink>

                                </NavItem>
                                <NavItem className={'nav_item'}>
                                    <NavLink
                                      className={callTab == 2 ? 'active nav_link' : 'nav_link'}
                                      onClick={()=>this.changeCallTab(2)} // change tab bang 2 danh sach cuoc goi di
                                    >
                                        발신목록
                                    </NavLink>
                                </NavItem>
                                {callbackNotification && callbackNotification.count_call_id > 0 ?  <li><span className="callback_num">{callbackNotification.count_call_id}</span></li> : ''}

                            </Nav>
                        </div>
                        <div className="box_center">
                            <FunctionButtonCenter
                                resetFormCall={this.resetFormCall} // clear form call
                                onChangeReceiveCallStatus={this.onChangeReceiveCallStatus}
                                receiveCallStatus={receiveCallStatus} // chế độ thủ công hay auto
                                onChangeDateSettingStatus={this.onChangeDateSettingStatus} // thay doi trang thai cai dat ngay show ( all hoac day )
                                dateSettingStatus={dateSettingStatus}// state thay doi che do cai dat du lieu 1 ngay hoac tat ca cac ngay

                                call={call}
                                toggleCallModalDelete={this.callModalDeleteAction}
                                toggleReportModal={this.reportModalAction}

                                callTabReceived={callTabReceived}// tab
                                callTabSendingCall={callTabSendingCall} // tab
                                callTab={callTab}
                                role={hasRole(user,['primarynet','admin','manager'])}
                            />

                        </div>
                        <div className="box_right">
                            <FunctionButtonRight
                                isHasACall={isHasACall}
                                onClickAbsenceOnline={()=>this.onClickAbsenceOnline(dnd_type)}
                                dnd_type={dnd_type} // chuc nang tu choi cuoc goi
                                toggleForwardModal={this.forwardModalAction}
                                forward={forward} // check co foward hay khong
                                forwardNumber={forwardNumber}  // xem forward so nao
                                acd={acd} // 0 : HUNT, 1 ACD
                                onClickCallACD={this.onClickCallACD}
                                // acdStatus // 0 : trang thai normal chua lam gi ca, 1 :AgentReadyEvent, 2 : AgentNotReadyEvent , 3 : Khi ở trạng thái này là khi ban đầu nó trạng thái (AgentReadyEvent) có 1 cuộc gọi chưa bấm save thì vào đây. Khi save rồi chuyên trạng thái bằng AgentReadyEvent
                                acdStatus={acdStatus}
                            />
                        </div>
                    </div>
                </div>
                <div className="main_content">
                    <div className="left_col conslution">
                        <div className="tab_content tab-content" id="myTabContent">
                            <div className="tab-pane fade show  active" id="home" role="tabpanel" aria-labelledby="home-tab">

                                {callTab == 1 // cuoc goi den
                                  ?
                                    <NavTabReceived
                                        callTabReceived={callTabReceived}
                                        changeCallReceived={this.changeCallReceived}
                                    />
                                  : // callTab = 2
                                  <NavTabSendingCall
                                      callTabSendingCall={callTabSendingCall}
                                      changeCallSendingCall={this.changeCallSendingCall}
                                      openPopupTelephone={this.openPopupTelephone}
                                      sceneLotsId={this.state.sceneLostId}
                                  />
                                }

                                <div className="content_nav_child tab-content onli_margin">
                                    <div className="tab-pane fade  show active" id="child_tabs_1" role="tabpanel" aria-labelledby="profile-tab">
                                        <ListCall
                                          callList={callList && callList.data && callList.data.data.length > 0 ? callList.data.data : []}
                                          onClickIdCall={(itemCall)=>this.onClickIdCall(itemCall)}
                                          call={call}
                                          role={hasRole(user,['primarynet','admin'])}
                                        //   role={hasRole(user,['primarynet','admin','manager'])}
                                          isDeleteList={this.isDeleteList}
                                          isDelete={isDelete}
                                          callTab={callTab}
                                          callTabReceived={callTabReceived}// tab
                                          callTabSendingCall={callTabSendingCall} // tab
                                          search_key={search_key}
                                        />
                                        {callList?.data?.last_page ? <div className="pagina right">
                                            <ul className="custom_pagination pagination">
                                                <Pagination
                                                    itemClass="page-item"
                                                    linkClass="page-link"
                                                    activePage={callList?.data?.current_page}
                                                    itemsCountPerPage={callList?.data?.per_page}
                                                    totalItemsCount={callList?.data?.total || 0}
                                                    pageRangeDisplayed={5}
                                                    onChange={this.changePageCallList}
                                                />
                                            </ul>
                                        </div> : ''}

                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                    <div className="content_col">
                        <div className="row margin_top_10">
                            <div className="col-lg-9">
                                <FormCall
                                    dateSetting={dateSetting}
                                    call={call}
                                    onChangeDateSetting={this.onChangeDateSetting}
                                    districts={districts}
                                    onChangeForm={this.onChangeForm}
                                    absentCall={absentCall} // state nay dung de co luu cuoc goi nho hay khong (부재중콜) nut check box tren man hinh
                                    onChangeAbsentCall={this.onChangeAbsentCall} // change absentCall
                                    callTab={callTab} // check callTab
                                    callTabReceived={callTabReceived}// check co icon dien thoai hay ko
                                    onClickMakeCall={this.onClickMakeCall}
                                    // modal message
                                    toggleMessage={this.messageModalAction}
                                    user={user}
                                    searchKeyFilter={this.searchKeyFilter}
                                    setCallSmsNumber={this.setCallSmsNumber}

                                />
                            </div>
                            <div className="col-lg-3">
                                <ItemSummaryBox
                                    valueOfFirstItemSummaryBox={valueOfFirstItemSummaryBox}  // 4 item he thong cu
                                    valueOfSecondItemSummaryBox={valueOfSecondItemSummaryBox}  // 4 item he thong cu
                                    totalOfCounselling={callListSearchByCallNumber && callListSearchByCallNumber.data ? callListSearchByCallNumber.data.total : null}
                                    call={call}
                                />
                            </div>
                        </div>


                        <div className="row margin_top_15 margin_bottom_15">
                            {infoMasts.length ?
                                <InfoMastNoPagination
                                    infoMasts={infoMasts}
                                    call={call}
                                    onChangeCallDetail={this.onChangeCallDetail}
                                />
                            : ''}

                        </div>


                        <div className="row margin_top_15 margin_bottom_15">
                            <div className="col-lg-12">
                                <div className="box_remarks">
                                    <div className="title">
                                        <h3>특기사항</h3>
                                    </div>
                                    <div className="content">
                                        <textarea
                                            className="input_remark"
                                            value={call.call_desc ? call.call_desc: ''}
                                            name={'call_desc'}
                                            onChange={(e)=>{
                                                let call_desc = {
                                                    call_desc : e.target.value
                                                }
                                                this.onChangeForm(call_desc);
                                            }}
                                        />
                                    </div>
                                    <div className="footer">
                                        <button
                                            disabled={user.btn_save == 0 ? true : false}
                                            type="button"
                                            className="btn mr-1"
                                            onClick={this.onClickSaveFormCall}
                                        >
                                            상담내용 저장
                                        </button>
																		{ 
																			localStorage.getItem("change_manager") === '1' ? 
                                        <button
                                            disabled={user.btn_save == 0 ? true : false}
                                            type="button"
                                            className="btn"
                                            onClick={this.onClickSaveFormCallWithOutChange}
                                        >
                                            상담자 변경없이 저장
                                        </button>
																			: ''
																		}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {callListSearchByCallNumber?.data?.last_page ?
                            <div className="row margin_top_15 margin_bottom_15">
                                <div className="col-lg-12">
                                    <ListCalSearchByNumber
                                        callListSearchByCallNumber={callListSearchByCallNumber && callListSearchByCallNumber.data && callListSearchByCallNumber.data.data.length > 0 ? callListSearchByCallNumber.data.data : []}
                                        getIdRecording={this.getIdRecording}
                                        role={hasRole(user,['primarynet','admin'])}
                                        change_manager={ localStorage.getItem("change_manager") === '1' ? true : false }
																				changeManagerModal={ this.changeManagerModal }
                                    />

                                    <div className="card_box_footer">
                                        <ul className="custom_pagination pagination right_position">
                                            <Pagination
                                                itemClass="page-item"
                                                linkClass="page-link"
                                                activePage={callListSearchByCallNumber?.data?.current_page}
                                                itemsCountPerPage={callListSearchByCallNumber?.data?.per_page}
                                                totalItemsCount={callListSearchByCallNumber?.data?.total || 0}
                                                pageRangeDisplayed={5}
                                                onChange={this.changePageCallListByNumber}
                                            />
                                        </ul>
                                    </div>

                                </div>
                            </div> : ''}

                    </div>

                    <div className="right_col">
                        <div style={{display: "inline-block", padding: "10px", clear: "both", width: "100%"}}>
													<h3>상담내용 문자발송</h3>
												</div>
                        <div style={{display: "inline-block", padding: "10px", width: "100%"}}>
												{	
														predefineSMS?.data?.map((item, index) => {
															// cnt 포함 ?
															let display_str = item.name
															let display_val = item.cnt ? item.cnt : ''
															return (
																<Fragment>
																	<button type="button" style={{width:"32%", cornerRadius:"3px", margin: "2px", textAlign:"left"}} name="send_sms" className="btn_control" onClick={() => this.openConfirmModal(index,item)}>{index+1}.{display_str} <span style={{color:"red"}}>{display_val}</span></button>
																</Fragment>
																)
														})
												}
												</div>
												<div className="box_text_area margin_bottom_10px">
                            <ChangeMemoUser
                                user={user}
                                changeMemo={changeMemo}
                            />

                            {/*<div className="control_textarea">*/}
                            {/*    <textarea*/}
                            {/*        className="form_control"*/}
                            {/*        value={call.call_note ? call.call_note: ''}*/}
                            {/*        name={'call_note'}*/}
                            {/*        onChange={(e)=>{*/}
                            {/*            let call_note = {*/}
                            {/*                call_note : e.target.value*/}
                            {/*            }*/}
                            {/*            this.onChangeForm(call_note);*/}

                            {/*            let changeDataUser = {*/}
                            {/*                memo :  e.target.value*/}
                            {/*            }*/}

                            {/*            // api.*/}



                            {/*        }}*/}
                            {/*    />*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>

                <ModalDelete
                    isOpen={callModalDelete}
                    toggle={this.callModalDeleteAction}
                    item={call.call_id} // truyen id vao de xoa
                    deleteItem={this.deleteCall} // function de lay id
                />

                <ModalReportTelePhone
                    isOpen={reportModal}
                    toggle={this.reportModalAction}
                    submitDateReport={this.submitDateReport}
                    reportTelephone={reportTelephone.data ? reportTelephone.data : []}
				            sceneLostId={this.state.sceneLostId}
										sceneCompanyId={this.state.sceneCompanyId}
										statItem={this.state.dailyItemReport}
                />

                <ConfirmModal
                    isConfirmModalOpen={this.state.isConfirmModalOpen}
                    openConfirmModal={this.openConfirmModal}
                    title="상담문자 전송"
                    body={ modal_title + ' 님에게 설정된 내용의 문자를 발송 하시겠습니까 ?'}
                    onConfirm={this.onConfirm}
                />

                <ModalMessage
                    isOpen={messageModal}
                    toggle={this.messageModalAction}
                    messages={messages.data && messages.data.length > 0 ? messages.data : []}
                    accessToken={accessToken} // accessToken ben thu 3
                    submitMessage={this.submitMessage}
                    tell={callSmsNumber}
                    saveDirectMessage={this.saveDirectMessage}
                    directMessage={directMessage.data} // tin nhan direct cua user
                    modalStatus={messageModal}
                />


                {/* forward   */}
                <ModalForwardCall
                    isOpen={forwardModal}
                    toggle={this.forwardModalAction}
                    forwardCall={forwardCall}
                    changeForwardCall={this.changeForwardCall}
                    submitForwardCall={this.submitForwardCall}
                />

                <ModalChangeManager
                    isOpen={this.state.isOpenChangeManagerModal}
                    toggle={this.changeManagerModalAction}
                    recentChangeManagerList={this.state.recentChangeManagerList}
                    managerList={this.state.managerList}
										submitChange={this.submitChange}
										selUsrId={this.state.selUsrId}
										selLotsId={this.state.selLotsId}
										selCallId={this.state.selCallId}
										setSelUsrId={this.setSelUsrId}
                />

                <ModalRecording
                    isOpen={recordingModal}
                    toggle={this.recordingModalAction}
                    namePatchRecoding={namePatchRecoding}
                />

                <ModalLastAccess
                    isOpen={lastAccessModal}
                    toggle={this.lastAccessModalAction}
                />

            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.user,
        callList : state.callList,
        call : state.call.data,
        callListSearchByCallNumber : state.callListSearchByCallNumber,
        reportTelephone : state.reportTelephone,
        messages : state.messages,
        directMessage : state.directMessage,
        sceneCompanyId: state.sceneSelect.company_id,
        sceneLostId: state.sceneSelect.lots_id,
        typeOfChange: state.sceneSelect.typeOfChange,
        isChangeScene: state.sceneSelect.isChangeScene,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchCalls : (type = 0, query) => dispatch(fetchCallsAction(type,query)),
        fetchCall : id => dispatch(fetchCallAction(id)), // get call id
        editCallColumn : data => dispatch(editCallColumnAction(data)), // chinh sua cot trong redux call
        searchByCallNumber : query => dispatch(searchByCallNumberAction(query)), // khi click id call thi search danh sacch so dien thoai trung nhau
        resetCall : () => dispatch(resetCallAction()),
        clearSearchCallByNumber : () => dispatch(clearSearchCallByNumberAction()),
        fetchCreateCall :  data => dispatch(fetchCreateCallAction(data)), // fetch call co cuoc goi nhat
        fetchReportTelephone : query => dispatch(fetchReportTelephoneAction(query)), // fetch report
        fetchMessages : id => dispatch(fetchMessagesAction(id)),
        fetchMessageDirect : () => dispatch(fetchMessageDirectAction()),// fetch data message direct cua user
        createMessageDirect : data =>dispatch(createMessageDirectAction(data)), // create direct
        updateMessageDirect : data => dispatch(updateMessageDirectAction(data)), //update lai
        disturbResponse : data => dispatch(disturbResponseAction(data)), // thay doi trang thai disturb
        resetReportTelephone : () => dispatch(resetReportTelephoneAction()), // reset Data report
        searchKeyCall : (search_key, company_id) => dispatch(fetchSearchKeyCallAction(search_key, company_id)), // search_key
        resetCallList : () => dispatch(resetCallListAction()), // reset callist = null
        changeMemo : data => dispatch(changeMemoAction(data)),
        setCall: data => dispatch(setCallAction(data)),
    }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(TelephoneCounselling));
