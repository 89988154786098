import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Helmet } from 'react-helmet';
import { NavLink, Link } from "react-router-dom";
import { Modal, ModalHeader, ModalFooter, ModalBody, Button } from 'reactstrap';
import RichTextEditor from 'react-rte';
import TextareaAutosize from 'react-autosize-textarea';
import * as helpers from './../../utils/helpers';
const arrRoleExcept = [
    helpers.roles.primarynet,
    helpers.roles.admin,
    // helpers.roles.manager
]

class CreateUserModal extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    };

    render() {
        const { 
            userForm,
            roles
        } = this.props;
        return (
            <Fragment>
                <Modal
                    isOpen={this.props.isOpen}
                    toggle={this.props.toggle}
                    modalClassName='modal_add_preferences'
                    className="modal-dialog modal-dialog-scrollable"
                    contentClassName="modal-content"
                >
                    <div className="modal-header">
                        <h5 className="modal-title">
                            <FormattedMessage id="dashboard.Common.New" defaultMessage="New" />
                        </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.props.toggle}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>

                    <div className="modal-body">
                        <div className="box_add_ppreferences_1">
                            <div className="box_group_control">
                                <div className="heading_title">
                                    <FormattedMessage id="dashboard.userCode" defaultMessage="userCode" />
                                </div>
                                <div className="group_control_in">
                                    <input
                                        type="text"
                                        className="form_control"
                                        name="usr_code"
                                        value={userForm?.usr_code}
                                        onChange={this.props.handleChange}
                                        autoComplete="off"
                                    />
                                </div>
                            </div>

                            {userForm && userForm.role != 7 ? <div className="box_group_control">
                                <div className="heading_title">
                                    <FormattedMessage id="dashboard.NewPassword" defaultMessage="NewPassword" />
                                </div>
                                <div className="group_control_in">
                                    <input
                                        type="password"
                                        className="form_control"
                                        name="usr_password"
                                        value={userForm?.usr_password}
                                        onChange={this.props.handleChange}
                                        autoComplete="off"
                                    />
                                </div>
                            </div> :''}


                            <div className="box_group_control">
                                <div className="heading_title">
                                    <FormattedMessage id="dashboard.CounselorName" defaultMessage="CounselorName" />
                                </div>
                                <div className="group_control_in">
                                    <input
                                        type="text"
                                        className="form_control"
                                        name="usr_name"
                                        value={userForm?.usr_name}
                                        onChange={this.props.handleChange}
                                    />
                                </div>
                            </div>

                            <div className="box_group_control">
                                <div className="heading_title">
                                    <FormattedMessage id="dashboard.Phone" defaultMessage="Phone" />
                                </div>
                                <div className="group_control_in">
                                    <input
                                        type="text"
                                        className="form_control"
                                        name="usr_tel"
                                        value={userForm?.usr_tel}
                                        // onChange={this.props.handleChange}
                                        onChange={this.props.handlePhoneInput}
                                    />
                                </div>
                            </div>
                            { userForm && userForm.role != helpers.roles.callback && 
                                <React.Fragment>
                                    <div className="box_group_control">
                                        <div className="heading_title">
                                            <FormattedMessage id="dashboard.CSTA_ID" defaultMessage="CSTA_ID" />
                                        </div>
                                        <div className="group_control_in">
                                            <input
                                                type="text"
                                                className="form_control"
                                                name="csta_id"
                                                value={userForm?.csta_id}
                                                onChange={this.props.handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="box_group_control">
                                        <div className="heading_title">
                                            <FormattedMessage id="dashboard.CSTA_PWD" defaultMessage="CSTA_PWD" />
                                        </div>
                                        <div className="group_control_in">
                                            <input
                                                type="password"
                                                className="form_control"
                                                name="csta_pwd"
                                                value={userForm?.csta_pwd}
                                                onChange={this.props.handleChange}
                                            />
                                        </div>
                                    </div>
                                </React.Fragment>
                            }

                            <div className="box_group_control">
                                <div className="heading_title">
                                    <FormattedMessage id="dashboard.Authority" defaultMessage="Authority" />
                                </div>
                                <div className="group_control_in group_select">
                                    <select
                                        className="form_control"
                                        onChange={this.props.handleChange}
                                        name="role"
                                        value={userForm?.role || 4}
                                    >
                                    {
                                        roles.map((item, index) => {
                                            return (
                                                <option value={item.role_id} key={index}>{item.display_name}</option>
                                            )
                                        })
                                    }
                                    </select>
                                    <span className="item_dropdown" />
                                </div>
                            </div>
                            { userForm && userForm.role != helpers.roles.callback && 
                                <React.Fragment>
                                    <div className="box_group_control">
                                        <div className="heading_title">
                                            <FormattedMessage id="dashboard.BtnSave" defaultMessage="Button Save" />
                                        </div>
                                        <div className="group_control_in group_select">
                                            <select
                                                className="form_control"
                                                onChange={this.props.handleChange}
                                                name="btn_save"
                                                value={userForm?.btn_save || 1}
                                            >
                                                <option value={1}>ON</option>
                                                <option value={0}>OFF</option>
                                            </select>
                                            <span className="item_dropdown" />
                                        </div>
                                    </div>

                                    <div className="box_group_control">
                                        <div className="heading_title">
                                            <FormattedMessage id="dashboard.SeeCall" defaultMessage="SeeCall" />
                                        </div>
                                        <div className="group_control_in group_select">
                                            <select
                                                className="form_control"
                                                onChange={this.props.handleChange}
                                                name="see_call"
                                                value={userForm?.see_call || 0}
                                            >
                                                <option value={1}>ON</option>
                                                {
                                                    !arrRoleExcept.includes(+userForm?.role) && <option value={0}>OFF</option>
                                                }
                                            </select>
                                            <span className="item_dropdown" />
                                        </div>
                                    </div>


                                    <div className="box_group_control">
                                        <div className="heading_title">
                                            <FormattedMessage id="dashboard.ChangeManager" defaultMessage="Change Manager" />
                                        </div>
                                        <div className="group_control_in group_select">
                                            <select
                                                className="form_control"
                                                onChange={this.props.handleChange}
                                                name="change_manager"
                                                value={userForm?.change_manager || 0}
                                            >
                                                <option value={1}>ON</option>
                                                <option value={0}>OFF</option>
                                            </select>
                                            <span className="item_dropdown" />
                                        </div>
                                    </div>

                                    <div className="box_group_control">
                                        <div className="heading_title">
                                            <FormattedMessage id="dashboard.ViewAllHistory" defaultMessage="View All Call History" />
                                        </div>
                                        <div className="group_control_in group_select">
                                            <select
                                                className="form_control"
                                                onChange={this.props.handleChange}
                                                name="see_history"
                                                value={userForm?.see_history || 1}
                                            >
                                                <option value={1}>ON</option>
                                                <option value={0}>OFF</option>
                                            </select>
                                            <span className="item_dropdown" />
                                        </div>
                                    </div>

                                    <div className="box_group_control">
                                        <div className="heading_title">
                                            <FormattedMessage id="dashboard.Statistics" defaultMessage="SMS Setting Menu" />
                                        </div>
                                        <div className="group_control_in group_select">
                                            <select
                                                className="form_control"
                                                onChange={this.props.handleChange}
                                                name="en_menu_1"
                                                value={userForm?.en_menu_1 || 0}
                                            >
                                                <option value={1}>ON</option>
                                                <option value={0}>OFF</option>
                                            </select>
                                            <span className="item_dropdown" />
                                        </div>
                                    </div>

                                    <div className="box_group_control">
                                        <div className="heading_title">
                                            <FormattedMessage id="dashboard.CustomerManagement" defaultMessage="Customer Management" />
                                        </div>
                                        <div className="group_control_in group_select">
                                            <select
                                                className="form_control"
                                                onChange={this.props.handleChange}
                                                name="en_menu_2"
                                                value={userForm?.en_menu_2 || 0}
                                            >
                                                <option value={1}>ON</option>
                                                <option value={0}>OFF</option>
                                            </select>
                                            <span className="item_dropdown" />
                                        </div>
                                    </div>

                                    <div className="box_group_control">
                                        <div className="heading_title">
                                            <FormattedMessage id="dashboard.SmsSetting.TextManagement" defaultMessage="SMS Setting Menu" />
                                        </div>
                                        <div className="group_control_in group_select">
                                            <select
                                                className="form_control"
                                                onChange={this.props.handleChange}
                                                name="en_menu_3"
                                                value={userForm?.en_menu_3 || 0}
                                            >
                                                <option value={1}>ON</option>
                                                <option value={0}>OFF</option>
                                            </select>
                                            <span className="item_dropdown" />
                                        </div>
                                    </div>

                                    <div className="box_group_control">
                                        <div className="heading_title">
                                            <FormattedMessage id="dashboard.DataSetting" defaultMessage="Data Setting" />
                                        </div>
                                        <div className="group_control_in group_select">
                                            <select
                                                className="form_control"
                                                onChange={this.props.handleChange}
                                                name="en_menu_4"
                                                value={userForm?.en_menu_4 || 0}
                                            >
                                                <option value={1}>ON</option>
                                                <option value={0}>OFF</option>
                                            </select>
                                            <span className="item_dropdown" />
                                        </div>
                                    </div>

                                </React.Fragment>
                            }
                        </div>
                    </div>

                    <div className="modal-footer">
                        <button onClick={this.props.toggle}
                            type="button"
                            className="btn btn-outline-dark "
                            data-dismiss="modal"
                            aria-label="Close">
                            <FormattedMessage
                                id="dashboard.Cancel"
                                defaultMessage="Cancel" />
                        </button>
                        <button onClick={this.props.onCreateUser}
                            type="button"
                            className="btn btn-dark btn_add"
                            data-dismiss="modal"
                            aria-label="Close">
                            <FormattedMessage
                                id="dashboard.Save"
                                defaultMessage="Save" />
                        </button>
                    </div>
                </Modal>

            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateUserModal)
