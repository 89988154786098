import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from "react-intl";
import { Helmet } from 'react-helmet';
import RichTextEditor from 'react-rte';
// import 'react-rte/lib/Draft.global.css'
import './../../../components/Board/ReqBoard/CustomerEditer.css';
import {
    fetchCompaniesAction,
    createReqArticleAction,
    updateReqArticleAction,
    replyReqArticleAction,
    deleteReqArticleAction
} from "../../../store/actions";
import HasRole from "../../../utils/entrust/HasRole";
import progressBar from '../../../utils/progressBar';
import * as routerName from "../../../utils/routerName";
import { hasRole } from "../../../utils/entrust/entrust";
import api from '../../../utils/api';
import {renderMessage} from '../../../utils/helpers';
import Board from './../Board';
import Pagination from "react-js-pagination";
import NotificationSystem from 'react-notification-system';
import CreateModal from './../../../components/Board/ReqBoard/CreateModal';
import UpdateModal from './../../../components/Board/ReqBoard/UpdateModal';
import ReplyModal from './../../../components/Board/ReqBoard/ReplyModal';
import DeleteConfirmModal from '../../../components/DeleteConfirmModal/DeleteConfirmModal';
import * as helpers  from "../../../utils/helpers";
import queryString from 'query-string';

class ReqBoard extends Component {
    constructor(props) {
        super(props);
				console.log('props',props);
        this.state = {
            articles: {
                total: 0,
            },
            currentCompany: {},
            currentArticles:  {},
            articleForm: {},
            deletingRow: null,
						maxLength: 2000,
						curLength: 0,
            valueDraft: RichTextEditor.createValueFromString('</br></br></br></br></br></br></br></br></br></br></br></br>','html'),

            openModalCreate: false,
            openModalUpdate: false,
						openModalReply: false,
            isDeleteModalOpen: false,
            isDeleteConfirmModalOpen: false,
						usr_code: this.props.auth.user.usr_code,
						selection: [],
						checkAllTable: false,
            notification: {
                allowHTML: false,
            }
        };
    }

    notificationSystem = React.createRef();
    addNotification = (data) => {
      const notification = this.notificationSystem.current;
      notification.addNotification({
        message: data.message,
        title: data.title,
        level: data.level
      });
    };

    componentDidMount() {
        // paginate: get data Paginate Format  || list: get data List Format
        let params = { 
            type: 'list'
        }
        this.getCompanies(params);
    };

    handleClickItemCheckbox = (event, item) => {
        // event.stopPropagation();
        // event.nativeEvent.stopImmediatePropagation();
        // console.log('handleClickItemCheckbox');

        let {selection} = this.state;
        let value = event.target.checked;
        if (value) {
            if ( !selection.includes(item.article_id) )
            selection.push(item.article_id);
        } else {
            selection = helpers.removeItemInArray(selection, item.article_id)
        }

        this.setState({
            ...this.state,
            selection,
        }, () => {
            // console.log(this.state.selection, 'selection_af');
        })
    }

		deleteSelectItem = (e) => {
        e.preventDefault();

        let cond = {
            article_id: this.state.selection
        }
        let query = queryString.stringify(cond, {arrayFormat: 'bracket'});
        progressBar.start();

        api.articles.deleteMultiReqArticle(query).then(response => {
					this.setState({
							...this.state,
							selection: [],
					}, () => {
						this.getReqBoardListByCompany(this.state.currentCompany);
						this.addNotification({
								title: <FormattedMessage
										id="dashboard.Succeed"
										defaultMessage="Succeed!"/>,
								message: <FormattedMessage
										id="dashboard.Common.DeleteSucceed"
										defaultMessage="Delete successfully!"/>,
								level: 'success',
						})							
					})
					progressBar.done();
				}).catch(error => {        
					progressBar.done();
				});
		}

    handleCheckAllTable = (event) => {
        let valueCheck = event.target.checked;
        console.log(valueCheck, 'valueChek');
        // return;

        let { selection } = this.state;
        let { articles } = this.state
        let CurrentPage = articles?.data.map(item => {
            return item.article_id
        })

        if (valueCheck) {
            //check and add all
            CurrentPage.map(article_id => {
                if ( !selection.includes(article_id) )
                selection.push(article_id);
            })
      
        } else {
            //remove all
            CurrentPage.map(article_id => {
               selection = helpers.removeItemInArray(selection, article_id)
            })
        }

        this.setState({
            ...this.state,
            selection,
            checkAllTable: valueCheck,
        }, () => {
            // console.log(this.state.selection, 'selection_af');
        })
    }

    handlePrivateOption = (value) => {
			this.setState({
					...this.state,                     
            articleForm: {
                ...this.state.articleForm,
                secret : value ? 1 : 0,
            }
			}, () => {

			});
		}

    getCompanies = (params) => {
        progressBar.start();
        this.props.onFetchCompanies(params).then(response => {
            // call list salelots match company of current user ( Only used to role Admin )
            this.setState({
                ...this.state,
                currentCompany: response?.data[0] || null,
            }, () => {
                this.getReqBoardListByCompany(this.state.currentCompany)
            });

            progressBar.done();
        }).catch(error => {         
        });
    }

    getReqBoardListByCompany = (company) => {
        progressBar.start();   
        let params = {
            page: this.state.articles?.current_page || 1,
            type: 'paginate',
        }
        api.articles.getReqBoardListByCompany(company.company_id, params).then(response => {
           this.setState({
               ...this.state,
               currentCompany: company,
               articles: response.data.data
           }, () => {
            //    console.log(this.state.articles, 'articles');
           })
            progressBar.done();
        }).catch(error => {        
            progressBar.done();
        });
    }

    changePage = (pageNumber) => {
        this.setState({
            ...this.state,
            articles: {
                ...this.state.articles,
                current_page: pageNumber
            }
        }, () => {
            this.getReqBoardListByCompany(this.state.currentCompany);
        })
    }

    getInfoArticle = (articles) => {
				progressBar.start();
				if (articles.article_contents && articles.article_contents.length > 0) {
					articles.html_contents = articles.article_contents.replace(/\n/g, "<br />");
					//console.log('articles:',articles);
				}
        this.setState({
            // ...this.state,
            currentArticles: articles
        })
				// 조회수 처리 ?
        api.articles.reqHit(articles.article_id).then(response => {
            progressBar.done();
        }).catch(error => {        
            progressBar.done();
        });
    }

    /**
     *  Modal Function
     */
    // Modal SaleLot
    toggleModalCreate = () => {
        this.setState({
            ...this.state,
            valueDraft: RichTextEditor.createValueFromString('</br></br></br></br></br></br></br></br></br></br></br></br>','html'),
            openModalCreate: !this.state.openModalCreate,
        }, () => {
            // console.log(this.state, 'state_after');
        });
    };

    toggleModalUpdate = () => {
        this.setState({
            ...this.state,
            openModalUpdate: !this.state.openModalUpdate,
        }, () => {
            // console.log(this.state, 'state_after');
        });
    };


    toggleModalReply = () => {
        this.setState({
            ...this.state,
            valueDraft: RichTextEditor.createValueFromString('</br></br></br></br></br></br></br></br></br></br></br></br>','html'),
            openModalReply: !this.state.openModalReply,
        }, () => {
            // console.log(this.state, 'state_after');
        });
    };


    /**
     * CRUD SaleLot
    */
    handleChangeInput = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
//        const value = null;
//				if (target.type === 'checkbox') value = target.checked == true ? 1: 0;
//				else value = target.value;
        this.setState({
            ...this.state,
            articleForm: {
                ...this.state.articleForm,
                [name]: value,
            }
        });        
    };

    handleChangeEditor = (value) => {
				const {curLength,maxLength} = this.state;
				if (curLength >= maxLength) return false;

				const editState = this.state.valueDraft.getEditorState();
				const currentContent = editState.getCurrentContent();
				const currentContentLength = currentContent.getPlainText('').length;

        this.setState({
            ...this.state,
						curLength: currentContentLength,
            articleForm: {
                ...this.state.articleForm,
                article_contents: value.toString('html'),
            },
            valueDraft: value,
        }, () => {
        });        
    };

		handleChangeTextarea  = (event) => {

			const target = event.target;
			const value = target.type === 'checkbox' ? target.checked : target.value;
			const name = target.name;

			this.setState({
					...this.state,
					articleForm: {
							...this.state.articleForm,
							article_contents: value,
					},
					valueDraft: value,
			}, () => {

				if (target.name == 'content') { // 길이에 따른

					const {maxLength} = this.state; 
					var _byte=0;
					var str = target.value;
					var charStr='';
					var finalStr='';

					for (var i=0;i<str.length;i++){
							charStr=str.charAt(i);
							if(escape(charStr).length>4){
									_byte+=2;
							}else{
									_byte++;
							}
							if (_byte >= (maxLength - 1)) break;
							else finalStr += charStr;
					}

					console.log('length:', _byte);

					this.setState({
							...this.state,
							curLength: _byte,
							articleForm: {
									...this.state.articleForm,
									article_contents: finalStr,
							},
							valueDraft: finalStr,
					});

				}

			});

    };

		_getLengthOfSelectedText = () => {
			const editState = this.state.valueDraft.getEditorState();
			const currentSelection = editState.getSelection();
			const isCollapsed = currentSelection.isCollapsed();

			let length = 0;

			if (!isCollapsed) {
				const currentContent = editState.getCurrentContent();
				const startKey = currentSelection.getStartKey();
				const endKey = currentSelection.getEndKey();
				const startBlock = currentContent.getBlockForKey(startKey);
				const isStartAndEndBlockAreTheSame = startKey === endKey;
				const startBlockTextLength = startBlock.getLength();
				const startSelectedTextLength = startBlockTextLength - currentSelection.getStartOffset();
				const endSelectedTextLength = currentSelection.getEndOffset();
				const keyAfterEnd = currentContent.getKeyAfter(endKey);
				console.log(currentSelection)
				if (isStartAndEndBlockAreTheSame) {
					length += currentSelection.getEndOffset() - currentSelection.getStartOffset();
				} else {
					let currentKey = startKey;

					while (currentKey && currentKey !== keyAfterEnd) {
						if (currentKey === startKey) {
							length += startSelectedTextLength + 1;
						} else if (currentKey === endKey) {
							length += endSelectedTextLength;
						} else {
							length += currentContent.getBlockForKey(currentKey).getLength() + 1;
						}

						currentKey = currentContent.getKeyAfter(currentKey);
					};
				}
			}

			return length;
		}

		_handlePastedText = (pastedText) => {
			const editState = this.state.valueDraft.getEditorState();
			const currentContent = editState.getCurrentContent();
			const currentContentLength = currentContent.getPlainText('').length;
			const selectedTextLength = this._getLengthOfSelectedText();

			this.setState({
					...this.state,
					curLength :currentContentLength + pastedText.length - selectedTextLength
			});
			if (currentContentLength + pastedText.length - selectedTextLength > this.state.maxLength) {
				//console.log('you can type max ten characters');
				return 'handled';
			}
	  }

		_handleBeforeInput = () => {
			const editState = this.state.valueDraft.getEditorState();
			const currentContent = editState.getCurrentContent();
			const currentContentLength = currentContent.getPlainText('').length;
			const selectedTextLength = this._getLengthOfSelectedText();

			this.setState({
					...this.state,
					curLength :currentContentLength - selectedTextLength
			});
			if (currentContentLength - selectedTextLength > this.state.maxLength - 1) {
				console.log('you can type max ten characters');
				return 'handled';
			}
		}

    onCreate = () => {
        progressBar.start();
        let currentCompany = this.state.currentCompany;
        let article = this.state.articleForm;
        article.company_id = currentCompany.company_id;

        this.props.onCreate(article).then(response => {             
            this.toggleModalCreate();
            this.getReqBoardListByCompany(currentCompany);
            this.setState({
                ...this.state,
								curLength: 0,
                articleForm: {},
                notification: {
                    allowHTML: false,
                }
            }, () => {
                this.addNotification({
                    title: <FormattedMessage
                        id="dashboard.Succeed"
                        defaultMessage="Succeed!"/>,
                    message: <FormattedMessage
                        id="dashboard.Common.CreateSucceed"
                        defaultMessage="Add successfully!"/>,
                    level: 'success',
                })
                progressBar.done();
            });
        }).catch(error => {
            if (error.data) {
                this.setState({
                    ...this.state,
                    notification: {
                        allowHTML: true,
                    }
                }, () => {
                    this.addNotification({
                        title: <FormattedMessage
                                id="dashboard.Error"
                                defaultMessage="Error !"/>,
                        level: 'error',
                        message: renderMessage(error.data.errors.message),
                    })
                    progressBar.done();
                })
            }
        });
    };

    onReply = () => {
        progressBar.start();
        let currentCompany = this.state.currentCompany;
        let article = this.state.articleForm;
        article.company_id = currentCompany.company_id;

        this.props.onReply(article.article_id,article).then(response => {             
            this.toggleModalReply();
            this.getReqBoardListByCompany(currentCompany);
            this.setState({
                ...this.state,
								curLength: 0,
                articleForm: {},
                notification: {
                    allowHTML: false,
                }
            }, () => {
                this.addNotification({
                    title: <FormattedMessage
                        id="dashboard.Succeed"
                        defaultMessage="Succeed!"/>,
                    message: <FormattedMessage
                        id="dashboard.Common.ReplySucceed"
                        defaultMessage="Reply successfully!"/>,
                    level: 'success',
                })
                progressBar.done();
            });
        }).catch(error => {
            if (error.data) {
                this.setState({
                    ...this.state,
                    notification: {
                        allowHTML: true,
                    }
                }, () => {
                    this.addNotification({
                        title: <FormattedMessage
                                id="dashboard.Error"
                                defaultMessage="Error !"/>,
                        level: 'error',
                        message: renderMessage(error.data.errors.message),
                    })
                    progressBar.done();
                })
            }
        });
    };

    editArticle = (article) => {
        this.setState({
            ...this.state,
						curLength: 0,
            articleForm: article,
						valueDraft: ""
        }, () => {
            this.toggleModalUpdate();
        });
    };

    replyArticle = (article) => {
				// 제목에 내용 추가 
				let reply_article = {};
				reply_article.article_id = null; // 실제로 추가 ?
				reply_article.sort = article.sort; // 실제로 추가 ?
				reply_article.grp = article.grp; // 실제로 추가 ?
				reply_article.depth = article.depth; // 실제로 추가 ?
				reply_article.article_id = ""; // 실제로 추가 ?
				reply_article.article_title = 'Re: ' + article.article_title;
				reply_article.article_contents = "";

        this.setState({
            ...this.state,					
						curLength: 0,
            articleForm: reply_article,
						valueDraft: "", //.createValueFromString(article.article_contents, 'html')
        }, () => {
            this.toggleModalReply();
        });
    };

    onUpdate = () => {
        progressBar.start();
        let currentCompany = this.state.currentCompany; 
        let article = this.state.articleForm;

        this.props.onUpdate(article.article_id, article).then(response => {   
            this.toggleModalUpdate();
            this.getReqBoardListByCompany(currentCompany);
            this.getInfoArticle(article);  // re-fetch new value current saleLot selected

            this.setState({
                ...this.state,
								curLength: 0,
                articleForm: {},
                notification: {
                    allowHTML: false
                }
            }, () => {
                this.addNotification({
                    title: <FormattedMessage
                        id="dashboard.Succeed"
                        defaultMessage="Succeed!"/>,
                    message: <FormattedMessage
                        id="dashboard.Common.UpdateSucceed"
                        defaultMessage="Update successfully!"/>,
                    level: 'success',
                })
                progressBar.done();
            });
        }).catch(error => {
            if (error.data) {
                this.setState({
                    ...this.state,
                    notification: {
                        allowHTML: true,
                    }
                }, () => {
                    this.addNotification({
                        title: <FormattedMessage
                                id="dashboard.Error"
                                defaultMessage="Error !"/>,
                        level: 'error',
                        message: renderMessage(error.data.errors.message),
                    })
                    progressBar.done();
                })
            };
        });
    }; 

    /**
     *  Delete Sale lot
    */
    deleteArticleHandler = () => {
        progressBar.start();
        this.props.onDelete(this.state.deletingRow)
            .then(response => {                
                // check and re-set current page if delete last item of last page
                let {articles} = this.state; 
                let current_page = articles.from == articles.to ? articles.last_page - 1 : articles.current_page;
                
                //check and update saleLot after delete any item saleLot
                let currentArticles = this.state.currentArticles.article_id == this.state.deletingRow ? {} : this.state.currentArticles;
                this.setState({
                    ...this.state,
                    currentArticles,
                    deletingRow: null,
                    isDeleteConfirmModalOpen: false,
                    deleteCompanyOrAfCompany: null,
                    notification: {
                        allowHTML: false,
                    },
                    articles: {
                        ...this.state.articles,
                        current_page,
                    }
                }, () => {
                    this.getReqBoardListByCompany(this.state.currentCompany);

                    this.addNotification({
                        title: <FormattedMessage
                            id="dashboard.Succeed"
                            defaultMessage="Succeed!"/>,
                        message: <FormattedMessage
                            id="dashboard.Common.DeleteSucceed"
                            defaultMessage="Delete successfully!"/>,
                        level: 'success',
                    })
                    progressBar.done();
                })   
            })
            .catch(error => {              
            });
    };

    setDeletingRow = (value, type) => {
        this.setState({
            ...this.state,
            deletingRow: value,
        }, () => {
            this.openDeleteModal();
        });
    };

    openDeleteModal = () => {
        this.setState({
            isDeleteModalOpen: !this.state.isDeleteModalOpen
        });
    };

    openDeleteConfirmModal = () => {
        this.setState({
            isDeleteConfirmModalOpen: !this.state.isDeleteConfirmModalOpen
        });
    };

    render() {
        const { 
            articles,
            currentCompany,
            selection,
            checkAllTable,
        } = this.state;

        console.log(this.state.articles, 'articles');
        
        return (
            <Fragment>
                <NotificationSystem ref={this.notificationSystem} allowHTML={this.state.notification.allowHTML}/>

                <FormattedMessage id="BOARD.reqboard"
                    defaultMessage="ReqBoard">
                    {message => <Helmet>
                        <title>{message}</title>
                    </Helmet>}
                </FormattedMessage>

                <div className="main_top_bar static_bar">
                    <div className="box_sum_col_top">
                        <div className="box_left">
                            <ul className="nav nav_tabs">
                                <li className="nav_item">
                                    <span className="nav_link">
                                        <FormattedMessage
                                            id="BOARD.reqboard"
                                            defaultMessage="ReqBoard"
                                        />
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="main_content">
                    <div className="content_tabs_main content_static_tabs tab-content">
                        <div className="tabs_static_item tabs_item1">
                            <div className="box_content_static">
                                <div className="left_col left_col_customer_care">
                                    <Board 
                                        location={this.props.location}
                                    />
                                </div>

                                <div className="content_col_nopading">
                                    <div className="list_control_box_preferences">
                                        <div className="content_left">
                                            <div className="content_column">
                                                <div className="column_box">
                                                    <div className="heading_box">
                                                        <h5 className="title_preferences">
                                                            <FormattedMessage
                                                                id="BOARD.reqboard"
                                                                defaultMessage="Req Board"
                                                            />
                                                        </h5>
                                                    </div>
                                                    <div className="control_box_item">
                                                        <div className="card_header">
                                                            <div className="box_title">
                                                                <FormattedMessage
                                                                    id="BOARD.article"
                                                                    defaultMessage="Article List"
                                                                />
                                                            </div>
                                                            <div className="box_add">
																																{ hasRole(this.props.auth.user, ['primarynet']) ?
                                                                (<button type="button" style={{marginRight:"5px"}} className="btn btn_add" onClick={this.deleteSelectItem} 
                                                                    disabled={selection?.length>0 ? false : true}>
                                                                    <i className="fas fa-trash" />
                                                                </button>):''
																																}
                                                                <button type="button" className="btn btn_add" onClick={this.toggleModalCreate} 
                                                                    disabled={currentCompany.company_id ? false : true}>
                                                                    <i className="fas fa-plus" />
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="card_body">
                                                            <div className="custom_table">
                                                                <table className="table st_table_5">
                                                                    {/* render info articles list */}
																																		<tr>
																																		{ hasRole(this.props.auth.user, ['primarynet']) ?
																																			(<th style={{width:'12px'}} className="inline_action">                                                                  <input
																																				name="check_all_table"     
																																				type="checkbox"
																																				checked={ checkAllTable }
																																				onChange={this.handleCheckAllTable}
																																				// onClick={e => e.stopPropagation()}
																																				/>
																																			</th>):''
																																		 }
																																			<th style={{width:'15%'}} className="inline_action">게시일자</th>
																																			<th style={{width:'48%'}} className="inline_action">제목</th>
																																			<th style={{width:'15%'}} className="inline_action">작성자</th>
																																			<th style={{width:'10%'}} className="inline_action">Hit</th>
																																			<th style={{width:'12%'}} className="inline_action">관리</th>
																																		</tr>
                                                                    <tbody>
                                                                    {
                                                                       articles?.data?.map((item, index) => {
																																						var indent = 8;
																																						var head_str = '';
																																						var secret_str = '';
																																						for (var i=0;i<item.sort;i++)
																																						{
																																							indent += 16;
																																						}
																																						if (item.sort > 0) head_str = '<i class="fa fa-arrow-right"></i> ';
																																						else head_str = '';
																																						if (item.secret > 0) secret_str = '<i class="fa fa-lock"></i> ';
																																						else secret_str = '';
                                                                            let active = this.state.currentArticles?.article_id == item.article_id ? 'active' : '';
                                                                            return (
                                                                                <tr key={index} className={active} onClick={() => this.getInfoArticle(item)}>
																																									{ hasRole(this.props.auth.user, ['primarynet']) ?
																																										(<td className="align_center" style={{width: '12px'}} >
																																												<input type="checkbox" 
																																														checked={ selection.includes(item.article_id) ? true : false }
																																														ref={(input) => this[`checkbox_${item.article_id}`] = input}
																																														onChange={(e) => this.handleClickItemCheckbox(e, item)}
																																														onClick={e => e.stopPropagation()}
																																												/>
																																										</td>): ''
																																									}
                                                                                    <td style={{width:'15%'}} className="inline_action">{item.wdate}</td>
                                                                                    <td style={{width:'48%',paddingLeft:indent}} dangerouslySetInnerHTML={{ __html: secret_str + head_str +  item.article_title }}></td>
                                                                                    <td style={{width:'15%'}} className="inline_action">{item.wuser}</td>
                                                                                    <td style={{width:'10%'}} className="inline_action">{item.hit}</td>
                                                                                    <td style={{width:'12%'}} className="inline_action">
																																											<a onClick={() => this.replyArticle(item)}> <i className="fas fa-reply"/> </a>
																																									{
																																											item.wuser == this.state.usr_code || hasRole(this.props.auth.user, ['primarynet']) ?
																																											(
																																												<a onClick={() => this.editArticle(item)}> <i className="fas fa-edit"/> </a>
																																											)		: ''
																																									}
																																									{
																																											item.wuser == this.state.usr_code || hasRole(this.props.auth.user, ['primarynet']) ?
																																											(
		                                                                                    <a onClick={() => this.setDeletingRow(item.article_id, 1)}> <i className="fas fa-trash-alt"/> </a>
																																											)		: ''
																																									}
                                                                                    </td>                                                                                        
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        {
                                                            articles?.data?.length > 0 && (
                                                                <div className="car_footer">
                                                                    <nav aria-label="...">
                                                                        <ul className="custom_pagination pagination">
                                                                            <Pagination
                                                                                itemClass="page-item"
                                                                                linkClass="page-link"
                                                                                activePage={articles?.current_page}
                                                                                itemsCountPerPage={articles?.per_page}
                                                                                totalItemsCount={articles?.total || 0}
                                                                                pageRangeDisplayed={10}
                                                                                onChange={this.changePage}
                                                                            />
                                                                        </ul>
                                                                    </nav>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="content_right" style={{maxWidth:'500px'}}>
                                            <div className="heading_box">
                                                <h5 className="title_preferences">
                                                보기
                                                </h5>
                                            </div>
                                            <div className="heading_box">
																							{this.state.currentArticles?.article_title}
                                            </div>
                                            <div className="main_article_preferences" style={{maxWidth:'500px'}}>
																							<div dangerouslySetInnerHTML={{ __html: this.state.currentArticles?.html_contents }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <CreateModal
                    isOpen = {this.state.openModalCreate}
                    toggle = {this.toggleModalCreate}
                    currentCompany = {this.state.currentCompany}
                    articleForm = {this.state.articleForm}
                    valueDraft={this.state.valueDraft}
										curLength={this.state.curLength}
                    handleChangeInput = {this.handleChangeInput}
                    handleChangeEditor = {this.handleChangeEditor}
										handleChangeTextarea = {this.handleChangeTextarea}
										_handleBeforeInput={this._handleBeforeInput}
										_handlePastedText={this._handlePastedText}
										handlePrivateOption={this.handlePrivateOption}
                    onCreate = {this.onCreate}
                />

                <UpdateModal
                    isOpen = {this.state.openModalUpdate}
                    toggle = {this.toggleModalUpdate}
                    currentCompany = {this.state.currentCompany}
                    articleForm = {this.state.articleForm}
                    valueDraft={this.state.valueDraft}
										curLength={this.state.curLength}
                    handleChangeInput = {this.handleChangeInput}
                    handleChangeEditor = {this.handleChangeEditor}
										handleChangeTextarea = {this.handleChangeTextarea}
										_handleBeforeInput={this._handleBeforeInput}
										_handlePastedText={this._handlePastedText}
										handlePrivateOption={this.handlePrivateOption}
                    onUpdate = {this.onUpdate}
                />

                <ReplyModal
                    isOpen = {this.state.openModalReply}
                    toggle = {this.toggleModalReply}
                    currentCompany = {this.state.currentCompany}
                    articleForm = {this.state.articleForm}
                    valueDraft={this.state.valueDraft}
										curLength={this.state.curLength}
                    handleChangeInput = {this.handleChangeInput}
                    handleChangeEditor = {this.handleChangeEditor}
										handleChangeTextarea = {this.handleChangeTextarea}
										_handleBeforeInput={this._handleBeforeInput}
										_handlePastedText={this._handlePastedText}
										handlePrivateOption={this.handlePrivateOption}
                    onReply = {this.onReply}
                />

                <DeleteConfirmModal
                    isDeleteModalOpen={this.state.isDeleteModalOpen}
                    openDeleteModal={this.openDeleteModal}
                    isDeleteConfirmModalOpen={this.state.isDeleteConfirmModalOpen}
                    openDeleteConfirmModal={this.openDeleteConfirmModal}
                    deleteInfoHandler={this.deleteArticleHandler}
                />

            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        companies: state.companies,
        company: state.company,
        auth: state.auth
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchCompanies: (params={type:'paginate'}) => dispatch(fetchCompaniesAction(params)),
        
        onCreate: article => dispatch(createReqArticleAction(article)),
        onUpdate: (id, article) => dispatch(updateReqArticleAction(id, article)),
        onReply: (id, article) => dispatch(replyReqArticleAction(id, article)),
        onDelete: id => dispatch(deleteReqArticleAction(id))

    }
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ReqBoard));