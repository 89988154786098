import {
    SEARCH_CALL_BY_NUMBER,
    SEARCH_CALL_BY_NUMBER_SUCCESS,
    SEARCH_CALL_BY_NUMBER_FAIL,
    CLEAR_SEARCH_CALL_BY_NUMBER
} from './../type';
import api from "../../utils/api";




export const searchCallByNumberCalls = () => {
    return {
        type: SEARCH_CALL_BY_NUMBER
    }
};

export const searchCallByNumberSucceed = data => {
    return {
        type: SEARCH_CALL_BY_NUMBER_SUCCESS,
        payload: {
            data: data
        }
    }
};

export const searchCallByNumberFailed = error => {
    return {
        type: SEARCH_CALL_BY_NUMBER_FAIL,
        payload: {
            error: error
        }
    }
};

export const searchByCallNumberAction = (query) => dispatch => {
    dispatch(searchCallByNumberCalls())
    api.telephone.searchByCallNumber(query).then(response => {
				console.log('response:', response);
				//console.log('response.data.data.callList:',response.data.data.callList);
				if (response.data && response.data.data) {
	        dispatch(searchCallByNumberSucceed(response.data.data.callList));
		      return Promise.resolve(response.data.data.callList);
				} else {
	        dispatch(searchCallByNumberSucceed(response.data.callList));
		      return Promise.resolve(response.data.callList);
				}
    }).catch(error => {
				console.log('error:', error);
        dispatch(searchCallByNumberFailed(error?.response?.data?.errors?.message));
        return Promise.reject(error?.response)
    });
}

// clear data
export const clearSearchCallByNumber = () => {
    return {
        type: CLEAR_SEARCH_CALL_BY_NUMBER
    }
};

export const clearSearchCallByNumberAction = () => dispatch => {
    dispatch(clearSearchCallByNumber())
}