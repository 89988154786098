import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {setLocaleAction} from "../../store/actions";
import {authLogoutAction} from '../../store/actions/auth';
import { Helmet } from "react-helmet";
import { FormattedMessage, injectIntl } from "react-intl";
import { NavLink, Link } from "react-router-dom";
import * as routerName from "../../utils/routerName";
import HasRole from "../../utils/entrust/HasRole";
import * as myAction from './../../store/actions';
import progressBar from '../../utils/progressBar';
import api from '../../utils/api';
import LogoutModal from './../../components/ConfirmModal/LogoutModal';
import {
    fetchCompaniesAction,
} from "./../../store/actions";
import {changeSceneCompanyAction, changeSceneLotAction} from "../../store/actions/sceneSelect";
import * as webSocketApi from "../../utils/websocketApi";

class Dashboard extends Component {

    constructor(props) {
        super(props); 
				console.log('dashboard_props',props);
        this.state = {
						auth: [],
            visible: false,
            openModalLogout: false,
            dropdownOpen: false,
            companies: [],
            lots: [],
						company_id: localStorage.getItem("sel_company_id") ? localStorage.getItem("sel_company_id") : null,
						lots_id: localStorage.getItem("sel_lots_id") ? localStorage.getItem("sel_lots_id") : null,
						width: window.innerWidth,
						en_menu_1 : localStorage.getItem("menu_1") === '1' ? true : false,
						en_menu_2 : localStorage.getItem("menu_2") === '1' ? true : false,
						en_menu_3 : localStorage.getItem("menu_3") === '1' ? true : false,
						en_menu_4 : localStorage.getItem("menu_4") === '1' ? true : false,
        };
    }

    componentDidMount() {
        // paginate: get data Paginate Format  || list: get data List Format
        this.getCompanies();
        this.getSaleLots();
				console.log('dashboard_auth:',this.state.auth);
				window.addEventListener('resize', this.handleWindowSizeChange);
    };

		componentWillUnmount() {
			window.removeEventListener('resize', this.handleWindowSizeChange);
		};

		handleWindowSizeChange = () => {
			this.setState({ width: window.innerWidth });
		};

    getCompanies = () => {
        progressBar.start();
        api.company.getCompanies({}).then(response => {
					
						response?.data?.data?.map((item, index) => {
							if (localStorage.getItem("sel_company_id") && localStorage.getItem("sel_company_id") === item.company_id)
							{	
								this.props.changeSceneCompany(item.company_id);
								this.setState({
										company_id: item.company_id,
								}, () => {
										//localStorage.setItem('sel_company_id', item.company_id);
								});								
							}
						});
						
            this.setState({
                ...this.state,
                companies: response.data.data
            })
            progressBar.done();
        }).catch(error => {
            progressBar.done();
        });
    }

    getSaleLots = () => {
        progressBar.start();
        api.textManager.getSaleLotsOfUser().then(res => {

						res?.data?.data?.map((item, index) => {
							console.log('item.lots_id:',item.lots_id);
							console.log('sel_lots_id:',localStorage.getItem("sel_lots_id"));
							if (localStorage.getItem("sel_lots_id") && localStorage.getItem("sel_lots_id") === item.lots_id)
							{	
								this.props.changeSceneLot(item.lots_id);
								this.setState({
										lots_id: item.lots_id,
								}, () => {
										//localStorage.setItem('sel_lots_id', item.lots_id);
								});								
							}
						});
					
            this.setState({
                lots: res.data.data
            })
            progressBar.done();
        }).catch(e => {
            progressBar.done();
        })
    }


    toggleMenu = () => {
        this.setState({ visible: !this.state.visible });
    };

    userLogoutHandle = () => {
        webSocketApi.closeWebsocket()
        this.props.onUserLogout();
    };

    selectLanguageHandle = (event) => {
        this.props.onSetLocale(event.target.value);
    };

    toggleModalLogout = () => {
        this.setState({
            openModalLogout: !this.state.openModalLogout,
        }, () => {
            //console.log(this.state, 'statee');
        });
    };
    
    toggleDropDown = () => {
        this.setState((prevState) => ({
            dropdownOpen: !prevState.dropdownOpen,
        }));
    };

    selectCompanyHandler = (event) => {
        let { value } = event.target;
        if (value) {
            let company = JSON.parse(value);
            this.props.changeSceneCompany(company.company_id);
						this.setState({
								company_id: company.company_id,
						}, () => {
								localStorage.setItem('sel_company_id', company.company_id);
								//console.log(this.state, 'set_state');
						});
        } else {
            this.props.changeSceneCompany(null);
						this.setState({
								company_id: null,
						}, () => {
								localStorage.setItem('sel_company_id', '');
								//console.log(this.state, 'set_state');
						});
        }
    };

    selectLotHandler = e => {
        let { value } = e.target;
        if( value) {
            let lot = JSON.parse(value);
            this.props.changeSceneLot(lot.lots_id)
						this.setState({
								lots_id: lot.lots_id,
						}, () => {
								localStorage.setItem('sel_lots_id', lot.lots_id);
								//console.log('set_state', this.state);
						});
        } else {
            this.props.changeSceneLot(null)
						this.setState({
								lots_id: null,
						}, () => {
								localStorage.setItem('sel_lots_id', '');
								//console.log('set_state', this.state);
						});
        }
    }



    render() {

       //console.log('localStorage',localStorage);
       //console.log('localStorage.menu_1',localStorage.menu_1);
       //console.log('localStorage.getItem',localStorage.getItem("menu_1"));
			 //console.log('this.state.en_menu_1',this.state.en_menu_1);
//				this.setState({
//						...this.state,
//						en_menu_1 : localStorage.getItem("menu_1") === '1' ? true : false,
//						en_menu_2 : localStorage.getItem("menu_2") === '1' ? true : false,
//						en_menu_3 : localStorage.getItem("menu_3") === '1' ? true : false,
//						en_menu_4 : localStorage.getItem("menu_4") === '1' ? true : false
//				});

				// 가로폭을 체크하여 모바일 on/off 처리 한다.
			  const { width, company_id, lots_id } = this.state;
			  const isMobile = width <= 500;

        const { appState }  = this.props;
        const { companies, lots, en_menu_1, en_menu_2, en_menu_3, en_menu_4 }  = this.state;
        const { pathname} = window.location;
        const arrPath = pathname.split('/');
        const segment2nd =arrPath[2]; 

				return (
            <Fragment>
                <FormattedMessage id="dashboard.dashboard" defaultMessage="Dashboard">
                    {(message) => (
                        <Helmet>
                            <title>{message}</title>
                        </Helmet>
                    )}
                </FormattedMessage>         

                <header className="main_header">
                    <div className="box_menu_repo">
                        <span className="push_togle_menu">
                            <i className="fas fa-bars" />
                        </span>
                    </div>
                    <div className="container-fluid">
                        <div className="control_header">
                            <div className="float_left">
                                <HasRole role={['primarynet']}>
                                    <div className="select_version">
                                        <div className="control_select">
                                        <span className="txt_select">
                                            <FormattedMessage
                                                id="dashboard.scene"
                                                defaultMessage="Scene" />
                                        </span>
                                            <div className="box_control_input">
                                                <div className="box_select_input">
                                                    {/* selectbox list company */}
                                                    <select className="form_control" onChange={this.selectCompanyHandler} name="sltCompany">
                                                        <option value=''>
                                                            {this.props.intl.formatMessage({ id: 'dashboard.SelectCompany', defaultMessage: 'SelectCompany' })}
                                                        </option>
                                                        {
                                                            companies?.map((item, index) => {
                                                                return (item.company_id == company_id) ? 
																																(
                                                                    <option key={index} value={JSON.stringify(item)} selected>{item.company_name}</option>
                                                                )
                                                                :
																																(
                                                                    <option key={index} value={JSON.stringify(item)}>{item.company_name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <span className="drop_down" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </HasRole>
                                <HasRole role={['admin']}>
                                    <div className="select_version">
                                        <div className="control_select">
                                        <span className="txt_select">
                                            <FormattedMessage
                                                id="dashboard.scene"
                                                defaultMessage="Scene" />
                                        </span>
                                            <div className="box_control_input">
                                                <div className="box_select_input">
                                                    {/* selectbox list company */}
                                                    <select className="form_control" onChange={this.selectLotHandler} name="sltLot">
                                                        <option value=''>
                                                            {this.props.intl.formatMessage({ id: 'dashboard.SelectLot', defaultMessage: 'Select Lot' })}
                                                        </option>
                                                        {
                                                            lots?.map((item, index) => {
																																if (item.lots_id == lots_id) this.props.changeSceneLot(item.lots_id);
                                                                return (item.lots_id == lots_id) ? 
																																	(
                                                                    <option key={index} value={JSON.stringify(item)} selected>{item.lots_name}</option>
																																	)
																																	:
																																	(
                                                                    <option key={index} value={JSON.stringify(item)}>{item.lots_name}</option>
																																	)
                                                            })
                                                        }
                                                    </select>
                                                    <span className="drop_down" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </HasRole>
                                <HasRole role={['manager', 'telephone', 'visiting']}>
                                    <div className="select_version">
                                        <div className="control_select">
                                        <span className="txt_select">
                                            <FormattedMessage
                                                id="dashboard.scene"
                                                defaultMessage="Scene" />
                                        </span>
                                            <div className="box_control_input">
                                                <div className="box_select_input">
                                                    {/* selectbox list company */}
                                                    <select className="form_control" onChange={this.selectLotHandler} name="sltLot">
                                                        {																									
                                                            lots?.map((item, index) => {
																																console.log('map_item.lots_id:',item.lots_id);
																																console.log('map_lots_id:',lots_id);
																																if (item.lots_id == lots_id) this.props.changeSceneLot(item.lots_id);
                                                                return (item.lots_id == lots_id) ? 
																																	(
                                                                    <option key={index} value={JSON.stringify(item)} selected>{item.lots_name}</option>
																																	)
																																	:
																																	(
                                                                    <option key={index} value={JSON.stringify(item)}>{item.lots_name}</option>
																																	)
                                                            })
                                                        }
                                                    </select>
                                                    <span className="drop_down" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </HasRole>
                                <nav className="main_menu">
                                    <ul>
                                        <li>
                                            <NavLink to={`/${routerName.DASHBOARD}`}
                                                exact
                                                activeClassName="active">
                                                <FormattedMessage
                                                    id="dashboard.Home"
                                                    defaultMessage="Home" />
                                            </NavLink>
                                        </li>

                                        <HasRole role={['primarynet']}>
                                            <li>
                                                <NavLink to={`/${routerName.DASHBOARD}/${routerName.USER_REGISTRATION}`}
                                                    exact
                                                    activeClassName="active">
                                                    <FormattedMessage
                                                        id="dashboard.UserRegistration"
                                                        defaultMessage="User Registration" />
                                                </NavLink>
                                            </li>
                                        </HasRole>

                                        <HasRole role={['primarynet']}>
                                            <li>
                                                <NavLink to={`/${routerName.DASHBOARD}/${routerName.CUSTOMER_LIST}`}
                                                    exact
                                                    activeClassName="active">
                                                    <FormattedMessage
                                                        id="dashboard.CustomerList"
                                                        defaultMessage="Customer List" />
                                                </NavLink>
                                            </li>
                                        </HasRole>

                                        <HasRole role={['primarynet', 'admin', 'manager', 'telephone', 'visiting']}>
                                            <li>
                                                <NavLink to={`/${routerName.DASHBOARD}/${routerName.TELEPHONE}`}
                                                    exact
                                                    activeClassName="active">
                                                    <FormattedMessage
                                                        id="dashboard.TelephoneCounselling"
                                                        defaultMessage="Telephone Counselling" />
                                                </NavLink>
                                            </li>
                                        </HasRole>

                                        <HasRole role={['primarynet', 'admin', 'manager', 'telephone']}> {/*'primarynet', 'admin'*/}
																					{ localStorage.getItem("menu_2") === '1' ?
                                            <li>
                                                <NavLink to={`/${routerName.DASHBOARD}/${routerName.CUSTOMER_MANAGEMENT}/${routerName.COUNSELLING_DATA}`}
                                                    exact
                                                    className={ segment2nd  == 'customer-management' ? 'active' : 'no_active' }
                                                    activeClassName="active">
                                                    <FormattedMessage
                                                        id="dashboard.CustomerManagement"
                                                        defaultMessage="Customer Management" />
                                                </NavLink>
                                            </li>
																						: ''
																					}
                                        </HasRole>

                                        <HasRole role={['primarynet', 'admin', 'manager', 'telephone']}>{/*'primarynet', 'admin', 'manager'*/}
																					{ localStorage.getItem("menu_1") === '1' ?
                                            <li>
                                                <NavLink to={`/${routerName.DASHBOARD}/${routerName.STATISTICS}/${routerName.DAILY_REPORT}`}
                                                    exact
                                                    className={ segment2nd  == 'statistics' ? 'active' : 'no_active' }
                                                    activeClassName="active">
                                                    <FormattedMessage
                                                        id="dashboard.Statistics"
                                                        defaultMessage="Statistics" />
                                                </NavLink>
                                            </li>
																						: ''
																					}
                                        </HasRole>

                                        <HasRole role={['primarynet']}>
                                            <li>
                                                <NavLink to={`/${routerName.DASHBOARD}/${routerName.DATA_SETTING}/${routerName.COMPANY_REGISTRATION}`}
                                                    exact
                                                    className={ segment2nd  == 'data-setting' ? 'active' : 'no_active' }
                                                    activeClassName="active">
                                                    <FormattedMessage
                                                        id="dashboard.DataSetting"
                                                        defaultMessage="Data Setting" />
                                                </NavLink>
                                            </li>
                                        </HasRole>

                                        <HasRole role={['primarynet', 'admin', 'manager', 'telephone']}>{/*'admin'*/}
																				{ localStorage.getItem("menu_3") === '1' ?
                                            <li>
                                                <NavLink to={`/${routerName.DASHBOARD}/${routerName.SMS_SETTING}/${routerName.SMS_MMS}/${routerName.SMS_TEXT_MANAGEMENT}`}
                                                    exact                                                    
                                                    activeClassName="active">
                                                    <FormattedMessage
                                                        id="dashboard.SmsSetting"
                                                        defaultMessage="SMS Setting" />
                                                </NavLink>
                                            </li>                                         
																					: ''
																				}
                                        </HasRole>

                                        <HasRole role={['primarynet', 'admin', 'manager', 'telephone', 'visiting']}>
                                            <li>
                                                <NavLink to={`/${routerName.BOARD}/${routerName.FREE_BOARD}`}
                                                    exact
                                                    activeClassName="active">
                                                    <FormattedMessage
                                                        id="BOARD.board"
                                                        defaultMessage="Free Board" />
                                                </NavLink>
                                            </li>
                                        </HasRole>
																				
                                        <HasRole role={['admin']}>
																					{ localStorage.getItem("menu_4") === '1' ?
                                            <li>
                                                <NavLink to={`/${routerName.DASHBOARD}/${routerName.DATA_SETTING}/${routerName.AFFILITED_COMPANY_MANAGEMENT}`}
                                                    exact                                                    
                                                    activeClassName="active">
                                                    <FormattedMessage
                                                        id="dashboard.DataSetting"
                                                        defaultMessage="Data Setting" />
                                                </NavLink>
                                            </li>  
																						: ''
																					}
                                        </HasRole>
																				
                                    </ul>
                                </nav>
                            </div>
                            <div className="float_right">
                                <ul className="list_item_action">
                                    {process.env.NODE_ENV === 'development'
                                        ?
                                        <li>
                                            <select
                                                className="form-control form-control-sm"
                                                name="select"
                                                onChange={(event) => this.selectLanguageHandle(event)}
                                                value={appState.lang}
                                            >
                                                <option value='en' name='en'> English</option>
                                                <option value='ko' name='ko'> Korean</option>
                                            </select>
                                        </li>
                                        : ''}

                                    <li>
                                        <Link to="/#" className="zoom_now">
                                            <i className="fas fa-expand-arrows-alt" />
                                        </Link>
                                    </li>
                                    <li>
                                        <NavLink
                                            to={`/${routerName.DOCUMENTATION}/${routerName.DOCUMENTATION_GETTING_STARTED}`}
                                            className="document-page"
                                            activeClassName="active">
                                            <FormattedMessage
                                                id="dashboard.Documentation"
                                                defaultMessage="Documentation"/>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <Link to="/#" className="question">
                                            <i className="fas fa-question" />
                                        </Link>
                                    </li>
                                    <li>
                                        <a href="#" onClick={this.toggleModalLogout} data-toggle="modal" className="sign">
                                            <span className="show">
                                                <FormattedMessage
                                                    id="dashboard.Logout"
                                                    defaultMessage="Logout" />
                                            </span>
                                            <span className="hide">
                                                <i className="fas fa-sign-out-alt" />
                                            </span>
                                        </a>
                                        <LogoutModal
                                            isOpen={this.state.openModalLogout}
                                            toggle = {this.toggleModalLogout}
                                            onConfirm = {this.userLogoutHandle}
                                        />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </header>

                {this.props.children}
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        appState: state.appState,
        companies: state.companies,
        lots: state.lots,
        sel_company_id: state.company_id,
        sel_lots_id: state.lots_id,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSetLocale: (lang) => dispatch(setLocaleAction(lang)),
        onUserLogout: () => dispatch(authLogoutAction()),
        changeSceneCompany: companyId => dispatch(changeSceneCompanyAction(companyId)),
        changeSceneLot: lotsId => dispatch(changeSceneLotAction(lotsId)),
    }
    // onFetchCompanies: (params={type:'list'}) => dispatch(fetchCompaniesAction(params)),
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Dashboard));