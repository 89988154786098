import React from 'react';
import {Modal} from "reactstrap";
import {formatNumberPhone} from "../../../utils/helpers";

function ModalRecording (props) {

    const {
        isOpen,
        toggle,
        namePatchRecoding,
    } = props;


    let nameRecording = namePatchRecoding.split("_");
    let name = nameRecording.length > 1 ? nameRecording[nameRecording.length-2]+'_'+nameRecording[nameRecording.length-1] : ''

    return(
        <Modal
            isOpen={isOpen}
            toggle={()=>toggle()}
            className={'modal-dialog-scrollable'}
            wrapClassName={'modal_listen_play custom_modal_sum'}
        >
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">{name ? name : ''}</h5>
                    <button
                        type="button"
                        className="close"
                        onClick={()=>toggle()}
                    >
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="box_controlr_mp3">
                        <audio controls>
                            <source
                                src={`${process.env.REACT_APP_API_URL_DEV}/mp3_path/${namePatchRecoding}`}
                                type="audio/mpeg" />
                                Audio tag is unsupported
                            in this browser
                        </audio>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default ModalRecording;
