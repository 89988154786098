import React from 'react';


function FunctionButtonRight ({
                                    // isHasACall == 0 là trạng thái ACD AgentReadyEvent
                                  isHasACall,// 1: no call,  2: has a call, 3: during call (state cua chuông điện thoại)
                                  onClickAbsenceOnline,
                                  dnd_type,
                                  toggleForwardModal,
                                  forward, // check co foward hay khong
                                  forwardNumber,  // xem forward so nao
                                  acd, // 0 : HUNT, 1 ACD
                                  onClickCallACD,
                                  acdStatus, // 0 : trang thai normal chua lam gi ca, 1 :AgentReadyEvent, 2 : AgentNotReadyEvent , 3 : Khi ở trạng thái này là khi ban đầu nó trạng thái (AgentReadyEvent) có 1 cuộc gọi chưa bấm save thì vào đây. Khi save rồi chuyên trạng thái bằng AgentReadyEvent
}) {

    if(acd == 1) { // trang thai ACD moi vao
        if(acdStatus == 1) {
            // nếu acdStatus == 1 thì là trạng thái AgentReadyEvent đổi trạng thái isHasACall = 0 (đổi màu)
            if(isHasACall == 1){
                isHasACall = 0;
            }
        }else if(acdStatus == 2){
            dnd_type = 0
        }

        // console.log(isHasACall,'isHasACall')
    }




    return (
        <div className="box_control_right">
            <div className="box_left_r">
                {/*<span className="title">메모</span>*/}
            </div>
            <div className="box_right_r">
                <div className="list_btn_control tooltip-custom">
                    <button
                        type="button"
                        className={`btn_control phone btn_001 calling${isHasACall}`}
                        onClick={()=> onClickCallACD() }
                    >
                        <span></span>전화받기
                    </button>

                    <button
                        type="button"
                        className={`btn_control btn_whites whites-custom${dnd_type} `} // // 1 la tu choi cuoc goi, 2 la cho pheo cuoc goi class btn_003
                        onClick={()=>onClickAbsenceOnline()}
                        // disabled={isHasACall == 1 ? false : true}
                    >{dnd_type == 2 ?'수신거부' : '수신거부중'}</button>
                    {acd == 0 ?  <button
                        type="button"
                        className={`btn_control btn_blues ${forward == "N" ? 'blues' : 'blues2'}`}  //blues2
                        onClick={()=>toggleForwardModal()}
                    >
                        착신전환
                    </button> : ''}

                </div>
            </div>
        </div>
    )
}

export default FunctionButtonRight;