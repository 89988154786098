import React from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import {formatNumberPhone} from './../../utils/helpers';
class FormCall extends React.Component {

    onChangeDate = (date) => {
        let formatDate = moment(date).format('YYYY-MM-DD');
        this.props.onChangeDateSetting(formatDate);// thay doi ngay he thong hoac thay doi ngay cua cuoc goi do
    }

    onChangeForm = (event) => {
        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if(name === 'call_tel' || name === 'call_cust_hp' || name == 'call_cust_tel') {
            value = formatNumberPhone(value);
        }

        let data = {
            [name] :value
        }

        this.props.onChangeForm(data);

    }

    openSendSmsModal = (number) => {
        this.props.setCallSmsNumber(number);
        this.props.toggleMessage();
    }


    render(){
        let {
            dateSetting, // ngay cai dat he thong
            call,
            districts,
            absentCall, // props nay dung de co luu cuoc goi nho hay khong (부재중콜) nut check box tren man hinh
            callTab,
            toggleMessage, // modal gui tin nhan
            user,
            callTabReceived,
            searchKeyFilter
        } = this.props;


        return (
            <div
                className={'custom-form-telephone'}
            >
                <div className="form_group row">
                    <div className="label_title col-3">일자</div>
                    <div className="content_box_input col-9">
                        <div className="row">
                            <div className="col-12 col-xl-6 pr-xl-1 mb-1 mb-xl-0">
                                <div className="box_control_input">
                                    <div className="box_control_date">
                                        <DatePicker
                                            className={'form_control form-control-sm unstyled'}
                                            selected={call.call_date ? moment(call.call_date) :moment(dateSetting)}
                                            // dateFormat="YYYY-MM-DD"
                                            onChange={(date) =>this.onChangeDate(date)}
                                        />
                                        {/*<input type="text" placeholder="mm/dd/yyyy" className="form_control form-control-sm unstyled" />*/}
                                        <span className="icon_date" />
                                    </div>
                                </div>
                            </div>
                            <div className="content_box_input center col-12 col-xl-6 pl-xl-1">
                                <div className="box_control_input input_checkbox">
                                    <div className="box_check_group">
                                        <input
                                            type="checkbox"
                                            id="checkbox_input_1"
                                            onChange={event => {
                                                const target = event.target;
                                                const value = target.type === 'checkbox' ? target.checked : target.value;
                                                // const name = target.name;
                                                this.props.onChangeAbsentCall(value)
                                            }}
                                            //value={absentCall}
                                            checked={absentCall}
                                        />
                                        <label htmlFor="checkbox_input_1">부재콜</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>{/* form_group */}
                <div className="form_group row">
                    <div className="label_title col-3">고객명</div>
                    <div className="content_box_input col-9">
                        <div className="row">
                            <div className="col-12 col-xl-6 pr-xl-1 mb-1 mb-xl-0">
                                <div className="box_control_input">
                                    <input
                                        type="text"
                                        className="form_control form-control-sm"
                                        name={'call_cust_name'}
                                        value={call.call_cust_name ? call.call_cust_name: ''}
                                        onChange={this.onChangeForm}
                                        onKeyPress={(event) => {
                                            if (event.key === 'Enter') {
                                                searchKeyFilter('call_cust_name');
                                            }
                                        }}
                                    />
                                    <div className="input_group_inline">
                                        <button
                                            type="button"
                                            className="btn_form btn_succsec"
                                            onClick={()=>searchKeyFilter('call_cust_name')}
                                        >찾기</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-xl-6 pl-xl-1">
                                <div className="box_control_input">
                                    <input
                                        type="text"
                                        placeholder="상담전화"
                                        className="form_control form-control-sm"
                                        name={'call_tel'}
                                        value={call.call_tel ?call.call_tel : '' }
                                        onChange={this.onChangeForm}
                                    />
                                    <div className="input_group_inline">
                                        {/* button open message */}
                                        {user.info_sale_lot.status_text == 1 ?    <button
                                            type={'button'}
                                            onClick={(e) => this.openSendSmsModal(call.call_tel, e)}
                                            className="btn_form btn_danger"
                                        >
                                            <i className="fas fa-envelope" />
                                        </button> :'' }



                                        {callTab == 1 && callTabReceived == 2// check o day la chi co cuoc goi den den moi an
                                            ?  ''
                                            :<button
                                                className="btn_form btn_succsec"
                                                onClick={(e)=>{
                                                    e.preventDefault();
                                                    this.props.onClickMakeCall(call.call_tel)
                                                }}
                                            >
                                                <i className="fas fa-phone" />
                                            </button>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>{/* form_group */}
                <div className="form_group row">
                    <div className="label_title col-3">고객정보</div>
                    <div className="content_box_input col-9">

                        <div className="row">
                            <div className="col-12 col-xl-6 pr-xl-1 mb-1 mb-xl-0">
                                <div className="box_control_input">
                                    <input
                                        type="text"
                                        className="form_control form-control-sm"
                                        name={'call_cust_info'}
                                        value={call.call_cust_info ? call.call_cust_info : ''}
                                        onChange={this.onChangeForm}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-xl-6 pl-xl-1">
                                <div className="box_control_input">
                                    <input
                                        type="text"
                                        placeholder="회선정보"
                                        className="form_control form-control-sm"
                                        disabled
                                        value={call.info_line ?call.info_line.line_info : ''}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>{/* form_group */}
                <div className="form_group row">
                    <div className="label_title col-3">전화</div>
                    <div className="content_box_input col-9">
                        <div className="row">
                            <div className="col-12 col-xl-6 pr-xl-1 mb-1 mb-xl-0">
                                <div className="box_control_input bn_r">
                                    <input
                                        type="text"
                                        className="form_control form-control-sm"
                                        name={'call_cust_hp'}
                                        value={call.call_cust_hp ? call.call_cust_hp : ''}
                                        onChange={this.onChangeForm}
                                        placeholder={'핸드폰 번호'}
 
                                    />
                                    <div className="input_group_inline">
                                        {/* button open message */}
                                        {user.info_sale_lot.status_text == 1 ? <button
                                            type={'button'}
                                            className="btn_form btn_danger"
                                            onClick={(e) => this.openSendSmsModal(call.call_cust_hp, e)}
                                        >
                                            <i className="fas fa-envelope" />
                                        </button> : ''}


                                        {callTab == 1 && callTabReceived == 2 // check o day la chi co cuoc goi den den moi an
                                            ?  ''
                                            : <button
                                                className="btn_form btn_succsec"
                                                onClick={(e)=> {
                                                    e.preventDefault();
                                                    this.props.onClickMakeCall(call.call_cust_hp)
                                                }}
                                            >
                                                <i className="fas fa-phone" />
                                            </button>
                                        }

                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-xl-6 pl-xl-1">
                                <div className="box_control_input bn_r">
                                    <input
                                        type="text"
                                        placeholder="일반전화"
                                        className="form_control form-control-sm"
                                        name={'call_cust_tel'}
                                        value={call.call_cust_tel ? call.call_cust_tel : ''}
                                        onChange={this.onChangeForm}

                                    />
                                    <div className="input_group_inline">
                                        {/* button open message */}
                                        {user.info_sale_lot.status_text == 1 ?    <button
                                            type={'button'}
                                            onClick={(e) => this.openSendSmsModal(call.call_cust_tel, e)}
                                            className="btn_form btn_danger"
                                        >
                                            <i className="fas fa-envelope" />
                                        </button> :'' }



                                        {callTab == 1 && callTabReceived == 2// check o day la chi co cuoc goi den den moi an
                                            ?  ''
                                            :<button
                                                className="btn_form btn_succsec"
                                                onClick={(e)=>{
                                                    e.preventDefault();
                                                    this.props.onClickMakeCall(call.call_cust_tel)
                                                }}
                                            >
                                                <i className="fas fa-phone" />
                                            </button>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>{/* form_group */}
                <div className="form_group row">
                    <div className="label_title col-3">주소</div>
                    <div className="content_box_input col-9">
                        <div className="box_control_input bn_r">
                            <input
                                type="text"
                                className="form_control"
                                name={'call_cust_address'}
                                value={call.call_cust_address ? call.call_cust_address : ''}
                                onChange={this.onChangeForm}
                            />
                            {/*<div className="input_group_inline">*/}
                            {/*    <button className="btn_form btn_secondary"><i className="fas fa-search" /></button>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>{/* form_group */}
                <div className="form_group row">
                    <div className="label_title col-3">관리지역</div>
                    <div className="content_box_input col-9">
                        <div className="box_control_input ">
                            <div className="box_select_input">
                                <select
                                    className="form_control"
                                    onChange={this.onChangeForm}
                                    value={call.call_cust_dist ? call.call_cust_dist : ''}
                                    name={'call_cust_dist'}
                                >
                                    <option
                                        value=""
                                    >
                                        관리지역 선택
                                    </option>
                                    {districts.map((item,index) => {
                                        return (
                                            <option
                                                key={index}
                                                value={item.dist_id}
                                            >
                                                {item.dist_name}
                                            </option>
                                        )
                                    })}
                                </select>
                                <span className="drop_down" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form_group row">
                    <div className="label_title col-3">수신동의</div>
                    <div className="content_box_input col-9">
                        <div className="box_control_checkbox">
                            <div className="box_check">
                                <input
                                    type="radio"
                                    id="cb001"
                                    value={0}
                                    name="call_agree"
                                    checked={!call.call_agree || call.call_agree == 0 ? true : false}
                                    onChange={this.onChangeForm}
                                />

                                    <label className="action_check" htmlFor="cb001">동의함</label>
                            </div>
                            <div className="box_check">
                                <input
                                    type="radio"
                                    id="cb002"
                                    name="call_agree"
                                    value={1}
                                    checked={call.call_agree == 1 ? true : false}
                                    onChange={this.onChangeForm}
                                />
                                    <label className="action_check" htmlFor="cb002">동의안함</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default FormCall;