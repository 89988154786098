import React, { useRef } from "react";
import PropTypes from "prop-types";

ResultReportComponent.propTypes = {
  props: PropTypes.object,
};

function ResultReportComponent(props) {
  function scrollToBottom () {
    window.scrollTo(0,9999);
  }
  console.log(props.tableReport1, 'props.tableReport1');
  let el = null;
  if (props?.searchType && props.tableReport1) {
    el = document.createElement("div");
    el.innerHTML = props.tableReport1;
    scrollToBottom();
  }
  if (props?.searchType && props.tableReport2) {
    el = document.createElement("div");
    el.innerHTML = props.tableReport2;
    setTimeout(() => {
      scrollToBottom();
    }, 300);
  }
  if (props?.searchType && props.tableReport3) {
    el = document.createElement("div");
    el.innerHTML = props.tableReport3;
    setTimeout(() => {
      scrollToBottom();
    }, 300);
  }
  if (props?.searchType && props.tableReport4) {
    el = document.createElement("div");
    el.innerHTML = props.tableReport4;
    setTimeout(() => {
      scrollToBottom();
    }, 300);
  }
  if (props?.searchType && props.tableReport5) {
    el = document.createElement("div");
    el.innerHTML = props.tableReport5;
    setTimeout(() => {
      scrollToBottom();
    }, 300);
  }
  
  let loading = <div className="d-flex justify-content-center scroll-div">
                  <div className="spinner-border" role="status" style={{color: "#17A2B8"}}>
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
  return (
    <div 
      className="content_col"
      id="daily-report-print"
    >
      {/* {
        !props.tableReport1 ? (
          loading
        ) : ''
      } */}
      <div
        dangerouslySetInnerHTML={{ __html: props?.tableReport1?.data }}
      />
      {
        props.tableReport1 && (!props.tableReport2 && (props.searchData.report2x != 0 && props.searchData.report2y != 8888)) ? (
          loading
        ) : ''
      }
      
      <div
        dangerouslySetInnerHTML={{ __html: props?.tableReport2?.data }}
      />
      {
        props.tableReport2 && (!props.tableReport3 && (props.searchData.report3x != 0 && props.searchData.report3y != 8888)) ? loading : ''
      }
      <div
        dangerouslySetInnerHTML={{ __html: props?.tableReport3?.data }}
      />
      {
        props.tableReport3 && (!props.tableReport4 && props.searchData.report4x != 0) ? loading : ''
      }
      <div
        dangerouslySetInnerHTML={{ __html: props?.tableReport4?.data }}
      />
      {
        props.tableReport4 && (!props.tableReport5 && props.searchData.report5x != 0) ? loading : ''
      }
      <div
        dangerouslySetInnerHTML={{ __html: props?.tableReport5?.data }}
      />
    </div>
    
  );
}

export default ResultReportComponent;
