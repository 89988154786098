import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Helmet } from 'react-helmet';
import { NavLink, Link } from "react-router-dom";
import { Modal, ModalHeader, ModalFooter, ModalBody, Button } from 'reactstrap';

class UpdateInfoMastModal extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { currentCompany, infoMastForm } = this.props;
        let lots_desc = infoMastForm && infoMastForm.lots_desc ? infoMastForm.lots_desc : '';

        return (
            <Fragment>
                <FormattedMessage id="dashboard.ItemManagement"
                    defaultMessage="ItemManagement">
                    {message => <Helmet>
                        <title>{message}</title>
                    </Helmet>}
                </FormattedMessage>

                <Modal
                    isOpen={this.props.isOpen}
                    toggle={this.props.toggle}
                    modalClassName='modal_add_preferences'
                    className="modal-dialog modal-dialog-scrollable"
                    contentClassName="modal-content"
                >
                    <div className="modal-header">
                        <h5 className="modal-title">
                            <FormattedMessage id="dashboard.Common.Update" defaultMessage="Update" />
                        </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.props.toggle}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>

                    <div className="modal-body">
                        <div className="box_add_ppreferences_1">
                            {/* <div className="box_group_control">
                                <div className="heading_title">Company</div>
                                <div className="group_control_in">
                                    <input
                                        type="text"
                                        className="form_control"
                                        name="company_name"
                                        value={currentCompany?.company_name}
                                        onChange={this.props.handleChange}
                                        readOnly
                                    />
                                </div>
                            </div> */}

                            <div className="box_group_control">
                                <div className="heading_title">
                                    <FormattedMessage id="dashboard.Name" defaultMessage="Name" />
                                </div>
                                <div className="group_control_in">
                                    <input
                                        type="text"
                                        className="form_control"
                                        name="m_name"
                                        value={infoMastForm?.m_name || ''}
                                        onChange={this.props.handleChange}
                                    />
                                </div>
                            </div>

                            <div className="box_group_control">
                                <div className="heading_title">
                                    <FormattedMessage id="dashboard.Order" defaultMessage="Order" />
                                </div>
                                <div className="group_control_in">
                                    <input
                                        type="text"
                                        className="form_control"
                                        name="m_order"
                                        value={infoMastForm?.m_order || ''}
                                        onChange={this.props.handleChange}
                                    />
                                </div>
                            </div>

                            <div className="box_group_control">
                                <div className="heading_title">
                                    <FormattedMessage id="dashboard.Fix" defaultMessage="dashboard.Fix" />
                                </div>
                                <div className="group_control_in group_select">
                                    <select className="form_control" onChange={this.props.handleChange} name="m_fix" value={infoMastForm?.m_fix || ''}>
                                        <option value={1}>YES</option>
                                        <option value={0}>NO</option>
                                    </select>
                                    <span className="item_dropdown" />
                                </div>
                            </div>

                            <div className="box_group_control">
                                <div className="heading_title">
                                    <FormattedMessage id="dashboard.Show" defaultMessage="dashboard.Show" />
                                </div>
                                <div className="group_control_in group_select">
                                    <select className="form_control" onChange={this.props.handleChange} name="m_show" value={infoMastForm?.m_show || ''}>
                                        <option value={1}>표시</option>
                                        <option value={0}>숨김</option>
                                    </select>
                                    <span className="item_dropdown" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal-footer">
                        <button onClick={this.props.toggle}
                            type="button"
                            className="btn btn-outline-dark "
                            data-dismiss="modal"
                            aria-label="Close">
                            <FormattedMessage
                                id="dashboard.Cancel"
                                defaultMessage="Cancel" />
                        </button>
                        <button onClick={this.props.onUpdateInfoMast}
                            type="button"
                            className="btn btn-dark btn_add"
                            data-dismiss="modal"
                            aria-label="Close">
                            <FormattedMessage
                                id="dashboard.Save"
                                defaultMessage="Save" />
                        </button>
                    </div>
                </Modal>

            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateInfoMastModal)
