import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import * as routerName from "../../utils/routerName";
import { FormattedMessage } from "react-intl";
import { NavLink, Link, Switch } from 'react-router-dom';
import HasRole from "../../utils/entrust/HasRole";
import { hasRole } from '../../utils/entrust/entrust';
import { Collapse, Button, CardBody, Card } from 'reactstrap';
// import 'react-datepicker/dist/react-datepicker.css';


class Board extends Component {

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {

    }

    render() {
        const { location } = this.props;
        const { auth } = this.props;
        
        return (
            <Fragment>
                <ul className="list_menu_preferences">
                    <li className={location?.pathname == `/${routerName.BOARD}/${routerName.FREE_BOARD}` ? 'active' : ''}>

                        <HasRole role={['primarynet', 'admin', 'manager', 'telephone', 'visiting']}>
                            <NavLink
                                to={`/${routerName.BOARD}/${routerName.FREE_BOARD}`}
                                exact                               
                                activeClassName="active"> 
                                <FormattedMessage
                                    id="BOARD.freeboard"
                                    defaultMessage="Free Board" />
                            </NavLink>
                        </HasRole>

		                </li>

	                  <li className={location?.pathname == `/${routerName.BOARD}/${routerName.REQ_BOARD}` ? 'active' : ''}>

                        <HasRole role={['primarynet', 'admin', 'manager', 'telephone', 'visiting']}>
                            <NavLink
                                to={`/${routerName.BOARD}/${routerName.REQ_BOARD}`}
                                exact
                                activeClassName="active">
                                <FormattedMessage
                                    id="BOARD.reqboard"
                                    defaultMessage="Request Board" />
                            </NavLink>
                        </HasRole>

                    </li>
                </ul>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
};

const mapDispatchToProps = dispatch => {
    return {}
};


export default connect(mapStateToProps, mapDispatchToProps)(Board);
