import {FETCH_USER_MANAGER, FETCH_USER_MANAGER_SUCCEED, FETCH_USER_MANAGER_FAILED} from "./actionType";
import api from "../../../utils/api";
import { convertIObjectToLocationSearch } from './../../../utils/helpers'

export const fetchUsersManager = () => {
    return {
        type: FETCH_USER_MANAGER
    }
};

export const fetchUsersManagerSucceed = data => {
    return {
        type: FETCH_USER_MANAGER_SUCCEED,
        payload: {
            data: data
        }
    }
};

export const fetchUsersManagerFailed = error => {
    return {
        type: FETCH_USER_MANAGER_FAILED,
        payload: {
            error: error
        }
    }
};


/**
 * User Manager
 */
export const fetchUsersManagerAction = (page,limit = null, place_id = null, start_date = null, end_date = null, search_word = null) => dispatch => {
    dispatch(fetchUsersManager());
    let params = convertIObjectToLocationSearch({page,limit, place_id, start_date, end_date , search_word})
    return api.mgm.fetchUsersManager(params)
        .then(response => {
            console.log(response, 'fetchUsersManager');
            dispatch(fetchUsersManagerSucceed(response.data.data));
            return Promise.resolve(response.data);
        })
        .catch(error => {
            dispatch(fetchUsersManagerFailed(error.response.data.errors?.message));
            return Promise.reject(error.response)
        });
};


/***
 * Fetch Placement Approved
 * */

export const fetchPlacementAction = ()  => {
    return api.mgm.fetchPlacementUser()
        .then(response => {
            return Promise.resolve(response.data.data);
        })
        .catch(error => {
            return Promise.reject(error.response)
        });
};
/***
 * Delete
 * */
export const deleteUserPlacementApprovedAction = (id)  => {
    return api.mgm.deleteUserPlacementApproved(id)
        .then(response => {
            return Promise.resolve(response.data.data);
        })
        .catch(error => {
            return Promise.reject(error.response)
        });
};