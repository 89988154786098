import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from "react-intl";
import { Helmet } from 'react-helmet';
import RichTextEditor from 'react-rte';
// import 'react-rte/lib/Draft.global.css'
import './CustomerEditer.css';
import {
    fetchCompaniesAction,
    createCompanyAction,
    updateCompanyAction,
    deleteCompanyAction,
    createSaleLotsAction,
    updateSaleLotsAction,
    deleteSaleLotsAction
} from "../../../store/actions";
import DataSetting from './../DataSetting';
import { NavLink, Link } from "react-router-dom";
import { hasRole } from "../../../utils/entrust/entrust";
import progressBar from '../../../utils/progressBar';
import * as routerName from "../../../utils/routerName";
import api from '../../../utils/api';
import {renderMessage} from '../../../utils/helpers';
import Pagination from "react-js-pagination";
import NotificationSystem from 'react-notification-system';
import CreateCompanyModal from './../../../components/DataSetting/CompanyRegistration/CreateCompanyModal';
import UpdateCompanyModal from './../../../components/DataSetting/CompanyRegistration/UpdateCompanyModal';
import CreateSaleLotCompanyModal from './../../../components/DataSetting/CompanyRegistration/CreateSaleLotCompanyModal';
import UpdateSaleLotCompanyModal from './../../../components/DataSetting/CompanyRegistration/UpdateSaleLotCompanyModal';
import DeleteConfirmModal from '../../../components/DeleteConfirmModal/DeleteConfirmModal';

class CompanyRegistration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            companies: {},
            saleLots: {},
            currentCompany: {},
            currentSaleLot:  {},
            companyForm: {},
            saleLotForm: {},
            deletingRow: null,
            valueDraft: RichTextEditor.createEmptyValue(''),
            deleteCompanyOrAfCompany: null,   // 0: Del Company || 1: Del Salelot

            openModalCreateCompany: false,
            openModalUpdateCompany: false,
            openModalCreateSaleLot: false,
            openModalUpdateSaleLot: false,
            isDeleteModalOpen: false,
            isDeleteConfirmModalOpen: false,
            
            notification: {
                allowHTML: false,
            },
        };
    }

    notificationSystem = React.createRef();
    addNotification = (data) => {
      const notification = this.notificationSystem.current;
      notification.addNotification({
        message: data.message,
        title: data.title,
        level: data.level
      });
    };

    componentDidMount() {
        // paginate: get data Paginate Format  || list: get data List Format
        this.getCompanies();
    };

    getCompanies = () => {
        progressBar.start();
        let params = {
            page: this.state.companies?.current_page || 1,
            type: 'paginate',
        }
        console.log(params, 'params2');
        this.props.onFetchCompanies(params).then(response => {
            this.setState({
                ...this.state,
                companies: response.data
            })
            progressBar.done();
        }).catch(error => {
            progressBar.done();
        });
    }

    // must used to 2nd parram to determine Click from companies List or Paginate
    getSaleLotsByCompanyFunc = (company, pageSaleLot=1) => {
        progressBar.start();   
        let params = {
            // page: this.state.saleLots?.current_page || 1,
            page: pageSaleLot,
            type: 'paginate',
        }
        api.saleLots.getSaleLotsByCompany(company.company_id, params).then(response => {
           this.setState({
               ...this.state,
               currentCompany: company,
               saleLots: response.data.data
           }, () => {
            //    console.log(this.state.saleLots, 'saleLotss');
           })
            progressBar.done();
        }).catch(error => {
            progressBar.done();
        });
    }

    changePageCompany = (pageNumber) => {
        this.setState({
            ...this.state,
            companies: {
                ...this.state.companies,
                current_page: pageNumber
            }
        }, () => {
            this.getCompanies();
        })
    }

    changePageSaleLot = (pageNumber) => {
        // this.setState({
        //     ...this.state,
        //     saleLots: {
        //         ...this.state.saleLots,
        //         current_page: pageNumber
        //     }
        // }, () => {
        //     this.getSaleLotsByCompanyFunc(this.state.currentCompany, pageNumber);
        // })
        this.getSaleLotsByCompanyFunc(this.state.currentCompany, pageNumber);
    }

    getInfoSaleLot = (saleLot) => {
        this.setState({
            // ...this.state,
            currentSaleLot: saleLot
        })
    }



    /**
     *  Modal Function
     */
    // Modal Company
    toggleModalCreateCompany = () => { 
        this.setState({
            ...this.state,
            companyForm: {},
            openModalCreateCompany: !this.state.openModalCreateCompany,
        }, () => {
            // console.log(this.state, 'state_after');
        });
    };

    toggleModalUpdateCompany = () => {
        this.setState({
            ...this.state,
            openModalUpdateCompany: !this.state.openModalUpdateCompany,
        }, () => {
        });
    };

    // Modal SaleLot
    toggleModalCreateSaleLot = () => {
        this.setState({
            ...this.state,
            saleLotForm: {},
            valueDraft: RichTextEditor.createEmptyValue(''),
            openModalCreateSaleLot: !this.state.openModalCreateSaleLot,
        }, () => {
        });
    };

    toggleModalUpdateSaleLot = () => {
        this.setState({
            ...this.state,
            openModalUpdateSaleLot: !this.state.openModalUpdateSaleLot,
        }, () => {
            // console.log(this.state, 'state_after');
        });
    };


    /**
     * CRUD Company
     */
    handleChangeInputCompany = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            ...this.state,
            companyForm: {
                ...this.state.companyForm,
                [name]: value,
            }
        }, () => {
            // debugger
        });        
    };

    onCreateCompany = () => {
        progressBar.start();
        const company = this.state.companyForm;
        this.props.onCreateCompany(company).then(response => {
            this.toggleModalCreateCompany();          
            this.setState({
                ...this.state,
                companyForm: {}, 
                notification: {
                    allowHTML: false,
                }
            }, () => {
                this.getCompanies();
                this.addNotification({
                    title: <FormattedMessage
                            id="dashboard.Succeed"
                            defaultMessage="Succeed!"/>,
                    message: <FormattedMessage
                            id="dashboard.Common.CreateSucceed"
                            defaultMessage="Create Succeed!"/>,
                    level: 'success',
                })
                progressBar.done();
            });
        }).catch(error => {
            if (error.data) {
                this.setState({
                    ...this.state,
                    notification: {
                        allowHTML: true,
                    }
                }, () => {
                    this.addNotification({
                        title: <FormattedMessage
                                id="dashboard.Error"
                                defaultMessage="Error !"/>,
                        level: 'error',
                        message: renderMessage(error.data.errors.message),
                    })
                    progressBar.done();
                })
            }
        });
    };

    editCompany = (company) => {
        this.setState({
            ...this.state,
            companyForm: company,
        }, () => {
            this.toggleModalUpdateCompany();
        });
    };
    
    onUpdateCompany = () => {
        progressBar.start();
        const company = this.state.companyForm;

        this.props.onUpdateCompany(company.company_id, company).then(response => {
            this.toggleModalUpdateCompany();
            this.setState({
                ...this.state,
                companyForm: {},      
                notification: {
                    allowHTML: false,
                }       
            }, () => {
                this.getCompanies()
                this.addNotification({
                    title: <FormattedMessage
                            id="dashboard.Succeed"
                            defaultMessage="Succeed!"/>,
                    message: <FormattedMessage
                            id="dashboard.Common.UpdateSucceed"
                            defaultMessage="Update successfully!"/>,
                    level: 'success',
                })
                progressBar.done();
            });
        }).catch(error => {
            if (error.data) {
                this.setState({
                    ...this.state,
                    notification: {
                        allowHTML: true,
                    }
                }, () => {
                    this.addNotification({
                        title: <FormattedMessage
                                id="dashboard.Error"
                                defaultMessage="Error !"/>,
                        level: 'error',
                        message: renderMessage(error.data.errors.message),
                    })
                    progressBar.done();
                })
            }
        });
    };  


    /**
     * CRUD SaleLot
    */
    handleChangeInputSaleLot = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            ...this.state,
            saleLotForm: {
                ...this.state.saleLotForm,
                [name]: value,
            }
        });        
    };

    handleChangeEditor = (value) => {
        this.setState({
            ...this.state,
            saleLotForm: {
                ...this.state.saleLotForm,
                lots_desc: value.toString('html'),
            },
            valueDraft: value,
        }, () => {
        });        
    };

    onCreateSaleLot = () => {
        progressBar.start();
        let currentCompany = this.state.currentCompany;
        // let {company_id} = currentCompany;
        let saleLot = this.state.saleLotForm;
        saleLot.company_id = currentCompany.company_id;

        this.props.onCreateSaleLot(saleLot).then(response => {
            this.toggleModalCreateSaleLot();
            this.getSaleLotsByCompanyFunc(currentCompany, 1);
            this.setState({
                ...this.state,
                saleLotForm: {},
                notification: {
                    allowHTML: false,
                }
            }, () => {
                this.addNotification({
                    title: <FormattedMessage
                        id="dashboard.Succeed"
                        defaultMessage="Succeed!"/>,
                    message: <FormattedMessage
                        id="dashboard.Common.CreateSucceed"
                        defaultMessage="Add successfully!"/>,
                    level: 'success',
                })
                progressBar.done();
            });
        }).catch(error => {
            if (error.data) {
                this.setState({
                    ...this.state,
                    notification: {
                        allowHTML: true,
                    }
                }, () => {
                    this.addNotification({
                        title: <FormattedMessage
                                id="dashboard.Error"
                                defaultMessage="Error !"/>,
                        level: 'error',
                        message: renderMessage(error.data.errors.message),
                    })
                    progressBar.done();
                })
            }
        });
    };

    editSaleLot = (saleLot) => {
        // console.log(saleLot, 'saleLote');
        this.setState({
            ...this.state,
            saleLotForm: saleLot,
            valueDraft: RichTextEditor.createValueFromString(saleLot.lots_desc, 'html')
        }, () => {
            this.toggleModalUpdateSaleLot();
            // console.log(this.state, 'afterrrrr');
        });
    };

    onUpdateSaleLot = () => {
        progressBar.start();
        let currentCompany = this.state.currentCompany;
        let saleLot = this.state.saleLotForm;

        this.props.onUpdateSaleLot(saleLot.lots_id, saleLot).then(response => {    
            this.toggleModalUpdateSaleLot();
            this.getSaleLotsByCompanyFunc(currentCompany, this.state.saleLots.current_page);
            this.getInfoSaleLot(saleLot);  // re-fetch new value current saleLot selected

            this.setState({
                ...this.state,
                saleLotForm: {},
                notification: {
                    allowHTML: false
                }
            }, () => {
                this.addNotification({
                    title: <FormattedMessage
                        id="dashboard.Succeed"
                        defaultMessage="Succeed!"/>,
                    message: <FormattedMessage
                        id="dashboard.Common.UpdateSucceed"
                        defaultMessage="Update successfully!"/>,
                    level: 'success',
                })
                progressBar.done();
            });
        }).catch(error => {
            if (error.data) {
                this.setState({
                    ...this.state,
                    notification: {
                        allowHTML: true,
                    }
                }, () => {
                    this.addNotification({
                        title: <FormattedMessage
                                id="dashboard.Error"
                                defaultMessage="Error !"/>,
                        level: 'error',
                        message: renderMessage(error.data.errors.message),
                    })
                    progressBar.done();
                })
            }
        });
    }; 

    /**
     *  Aboth Company vs SaleLot Func;      deleteCompanyOrAfCompany:0 ->Del Company || 1->Del SaleLot
    */
    deleteCompanyHandler = () => {
        if (this.state.deleteCompanyOrAfCompany) {
            // Delete Sale lot
            progressBar.start();
            this.props.onDeleteSaleLot(this.state.deletingRow)
                .then(response => {
                    // check and re-set current page if delete last item of last page
                    let {saleLots} = this.state; 
                    let current_page = saleLots.from == saleLots.to ? saleLots.last_page - 1 : saleLots.current_page;

                    //check and update saleLot after delete any item saleLot
                    let currentSaleLot = this.state.currentSaleLot.lots_id == this.state.deletingRow ? {} : this.state.currentSaleLot;
                    this.setState({
                        ...this.state,
                        currentSaleLot,
                        deletingRow: null,
                        isDeleteConfirmModalOpen: false,
                        deleteCompanyOrAfCompany: null,
                        notification: {
                            allowHTML: false,
                        },
                        // saleLots: {
                        //     ...this.state.saleLots,
                        //     current_page,
                        // }
                    }, () => {
                        this.getSaleLotsByCompanyFunc(this.state.currentCompany, current_page);

                        this.addNotification({
                            title: <FormattedMessage
                                id="dashboard.Succeed"
                                defaultMessage="Succeed!"/>,
                            message: <FormattedMessage
                                id="dashboard.Common.DeleteSucceed"
                                defaultMessage="Delete successfully!"/>,
                            level: 'success',
                        })
                        progressBar.done();
                    })
                })
                .catch(error => {                   
                    progressBar.done();
                });
        } else {
            // Delete Company
            progressBar.start();
            this.props.onDeleteCompany(this.state.deletingRow).then(response => {
                this.props.onFetchCompanies().then(response => {
                    // check and re-set current page if delete last item of last page
                    let {companies} = this.state; 
                    let current_page = companies.from == companies.to ? companies.last_page - 1 : companies.current_page;

                    // //check and update List saleLot after delete any item company
                    // if (this.state.currentCompany.company_id == this.state.deletingRow) {
                    //     var currentCompanyUpdate = {};
                    //     var currentSaleLotUpdate = {}; 
                    //     var saleLotsUpdate = {
                    //         total: 0,
                    //     }
                    // } else {
                    //     var currentCompanyUpdate = this.state.currentCompany;
                    //     var currentSaleLotUpdate = this.state.currentSaleLot;
                    //     var saleLotsUpdate = this.state.saleLots;
                    // }

                    var currentCompanyUpdate = {};
                    var currentSaleLotUpdate = {}; 
                    var saleLotsUpdate = {
                        total: 0,
                    }

                    this.setState({
                        ...this.state,
                        companies: response,
                        currentCompany: currentCompanyUpdate,
                        currentSaleLot: currentSaleLotUpdate,
                        saleLots: saleLotsUpdate,

                        deletingRow: null,
                        isDeleteConfirmModalOpen: false,
                        deleteCompanyOrAfCompany: null,
                        notification: {
                            allowHTML: false,
                        },
                        // companies: {
                        //     ...this.state.companies,
                        //     current_page,
                        // }
                    }, () => {  
                        this.getCompanies();

                        this.addNotification({
                            title: <FormattedMessage
                                id="dashboard.Succeed"
                                defaultMessage="Succeed!"/>,
                            message: <FormattedMessage
                                id="dashboard.Common.DeleteSucceed"
                                defaultMessage="Delete successfully!"/>,
                            level: 'success',
                        })
                        progressBar.done();
                    })
                });
            })
            .catch(error => {                   
                progressBar.done();
            });
        }

    };

    setDeletingRow = (value, type) => {
        this.setState({
            ...this.state,
            deletingRow: value,
            deleteCompanyOrAfCompany: type
        }, () => {
            this.openDeleteModal();
        });
    };

    openDeleteModal = () => {
        this.setState({
            isDeleteModalOpen: !this.state.isDeleteModalOpen
        });
    };

    openDeleteConfirmModal = () => {
        this.setState({
            isDeleteConfirmModalOpen: !this.state.isDeleteConfirmModalOpen
        });
    };

    render() {
        const { 
            saleLots,
            companies,
            currentCompany,
            currentSaleLot,
        } = this.state;
        // console.log(this.props.location, 'location2');

        return (
            <Fragment>
                <NotificationSystem ref={this.notificationSystem} allowHTML={this.state.notification.allowHTML}/>

                <FormattedMessage id="dashboard.CompanyRegistration"
                    defaultMessage="CompanyRegistration">
                    {message => <Helmet>
                        <title>{message}</title>
                    </Helmet>}
                </FormattedMessage>

                <div className="main_top_bar static_bar">
                    <div className="box_sum_col_top">
                        <div className="box_left">
                            <ul className="nav nav_tabs">
                                <li className="nav_item">
                                    <span className="nav_link">
                                    <FormattedMessage
                                        id="dashboard.CompanyRegistration"
                                        defaultMessage="CompanyRegistration"
                                    />
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="main_content">
                    <div className="content_tabs_main content_static_tabs tab-content">
                        <div className="tabs_static_item tabs_item1">
                            <div className="box_content_static">
                                <div className="left_col left_col_customer_care">
                                    <DataSetting 
                                        location={this.props.location}
                                    />
                                </div>

                                <div className="content_col_nopading">
                                    <div className="list_control_box_preferences">
                                        <div className="content_left">
                                            <div className="content_column">

                                                {/* Company Box */}
                                                <div className="column_box">
                                                    <div className="heading_box">
                                                        <h5 className="title_preferences">
                                                            <FormattedMessage
                                                                id="dashboard.Company"
                                                                defaultMessage="Company"
                                                            />
                                                        </h5>
                                                    </div>
                                                    <div className="control_box_item">
                                                        <div className="card_header">
                                                            <div className="box_title">
                                                                <FormattedMessage
                                                                    id="dashboard.Name"
                                                                    defaultMessage="Name"
                                                                />
                                                            </div>
                                                            <div className="box_add">
                                                                <button type="button" className="btn btn_add" onClick={this.toggleModalCreateCompany}>
                                                                    <i className="fas fa-plus" />
                                                                </button>
                                                            </div>
                                                        </div>

                                                        <div className="card_body">
                                                            <div className="custom_table">
                                                                <table className="table st_table_5">
                                                                    <tbody>
                                                                    {/* render companies list */}
                                                                    {
                                                                        companies?.data?.length > 0 ? 
                                                                            companies.data.map((item, index) => {
                                                                                let active = '';
                                                                                return (
                                                                                    <tr 
                                                                                        key={index}
                                                                                        className={active}
                                                                                        onClick={() => this.getSaleLotsByCompanyFunc(item, 1)}
                                                                                    >
                                                                                        <td style={{width: '70%'}}>{item.company_name}</td>
                                                                                        <td className="inline_action">
                                                                                            <a onClick={() => this.editCompany(item)}> <i className="fas fa-edit"/> </a>
                                                                                            <a onClick={() => this.setDeletingRow(item.company_id, 0)} ><i className="fas fa-trash-alt"/></a>
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            }) :
                                                                            (
                                                                                <tr>
                                                                                    <td colSpan={2}>
                                                                                        <div className="nodata">No Data</div>
                                                                                    </td>
                                                                                </tr> 
                                                                            )
                                                                    }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>        
                                                        {/* render paginate companies list */}
                                                        {
                                                            companies?.data?.length > 0 && (
                                                                <div className="car_footer">
                                                                    <nav aria-label="...">
                                                                        <ul className="custom_pagination pagination">
                                                                            <Pagination
                                                                                itemClass="page-item"
                                                                                linkClass="page-link"
                                                                                activePage={companies?.current_page}
                                                                                itemsCountPerPage={companies?.per_page}
                                                                                totalItemsCount={companies?.total || 0}
                                                                                pageRangeDisplayed={10}
                                                                                onChange={this.changePageCompany}
                                                                            />
                                                                        </ul>
                                                                    </nav>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                                    
                                                {/* SaleLot Box */}
                                                <div className="column_box">
                                                    <div className="heading_box">
                                                        <h5 className="title_preferences">
                                                        <FormattedMessage
                                                            id="dashboard.AffiliatedCompanies"
                                                            defaultMessage="AffiliatedCompanies"
                                                        />
                                                        </h5>
                                                    </div>
                                                    <div className="control_box_item">
                                                        <div className="card_header">
                                                            <div className="box_title">
                                                            <FormattedMessage
                                                                id="dashboard.Name"
                                                                defaultMessage="Name"
                                                            />
                                                            </div>
                                                            <div className="box_add">
                                                                <button type="button" className="btn btn_add" onClick={this.toggleModalCreateSaleLot} 
                                                                    disabled={!currentCompany.company_id}>
                                                                    {/* disabled={this.state.currentCompany.company_id ? '' : 'disabled'}> */}
                                                                    <i className="fas fa-plus" />
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="card_body">
                                                            <div className="custom_table">
                                                                <table className="table st_table_5">
                                                                    <tbody>
                                                                    {/* render info saleLots list */}
                                                                    {
                                                                        saleLots?.data?.length > 0 ? 
                                                                            saleLots.data.map((item, index) => {
                                                                                let active = this.state.currentSaleLot?.lots_id == item.lots_id ? 'active' : '';
                                                                                return (
                                                                                    <tr 
                                                                                        key={index}
                                                                                        className={active}
                                                                                        onClick={() => this.getInfoSaleLot(item)}                                                                                      
                                                                                    >
                                                                                        <td style={{width: '70%'}}>{item.lots_name}</td>
                                                                                        <td className="inline_action">
                                                                                            <a onClick={() => this.editSaleLot(item)}> <i className="fas fa-edit"/> </a>
                                                                                            <a onClick={() => this.setDeletingRow(item.lots_id, 1)}> <i className="fas fa-trash-alt"/> </a>
                                                                                        </td>                                                                                        
                                                                                    </tr>
                                                                                )
                                                                            }) :
                                                                            (
                                                                                <tr>
                                                                                    <td colSpan={3}>
                                                                                        <div className="nodata">No Data</div>
                                                                                    </td>
                                                                                </tr> 
                                                                            )
                                                                    }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        {
                                                            saleLots?.data?.length > 0 && (
                                                                <div className="car_footer">
                                                                    <nav aria-label="...">
                                                                        <ul className="custom_pagination pagination">
                                                                            <Pagination
                                                                                itemClass="page-item"
                                                                                linkClass="page-link"
                                                                                activePage={saleLots?.current_page}
                                                                                itemsCountPerPage={saleLots?.per_page}
                                                                                totalItemsCount={saleLots?.total || 0}
                                                                                pageRangeDisplayed={10}
                                                                                onChange={this.changePageSaleLot}
                                                                            />
                                                                        </ul>
                                                                    </nav>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>

                                            </div>
                                        </div>


                                        <div className="content_right">
                                            <div className="heading_box">
                                                <h5 className="title_preferences">
                                                <FormattedMessage
                                                    id="dashboard.CompanyOverview"
                                                    defaultMessage="CompanyOverview"
                                                />
                                                </h5>
                                            </div>
                                            <article className="main_article_preferences custom" 
                                                     dangerouslySetInnerHTML={{ __html: this.state.currentSaleLot?.lots_desc }} >
                                               {/* {
                                                    this.state.isSaleLotEdit ?
                                                    <RichTextEditor
                                                        // value={this.state.valueDraft}
                                                        value={ RichTextEditor.createValueFromString(this.state.currentSaleLot?.lots_desc, 'html')}
                                                        onChange={this.changeAfOverviewValue}
                                                    />
                                                    : null
                                                }

                                                {
                                                    !this.state.isSaleLotEdit &&
                                                    this.state.currentSaleLot &&
                                                    (<div dangerouslySetInnerHTML={{ __html: this.state.currentSaleLot?.lots_desc }} />)
                                                }            */}

                                            </article>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                
                {/* Modal Component List  */}
                <CreateCompanyModal
                    isOpen = {this.state.openModalCreateCompany}
                    toggle = {this.toggleModalCreateCompany}
                    companyForm = {this.state.companyForm}
                    handleChange = {this.handleChangeInputCompany}
                    onCreateCompany = {this.onCreateCompany}
                />

                <UpdateCompanyModal
                    isOpen = {this.state.openModalUpdateCompany}
                    toggle = {this.toggleModalUpdateCompany}
                    companyForm = {this.state.companyForm}
                    handleChange = {this.handleChangeInputCompany}
                    onUpdateCompany = {this.onUpdateCompany}
                />

                <CreateSaleLotCompanyModal
                    isOpen = {this.state.openModalCreateSaleLot}
                    toggle = {this.toggleModalCreateSaleLot}
                    currentCompany = {this.state.currentCompany}
                    saleLotForm = {this.state.saleLotForm}
                    valueDraft={this.state.valueDraft}
                    handleChange = {this.handleChangeInputSaleLot}
                    handleChangeEditor = {this.handleChangeEditor}
                    onCreateSaleLot = {this.onCreateSaleLot}
                />

                <UpdateSaleLotCompanyModal
                    isOpen = {this.state.openModalUpdateSaleLot}
                    toggle = {this.toggleModalUpdateSaleLot}
                    currentCompany = {this.state.currentCompany}
                    saleLotForm = {this.state.saleLotForm}
                    valueDraft={this.state.valueDraft}
                    handleChange = {this.handleChangeInputSaleLot}
                    handleChangeEditor = {this.handleChangeEditor}
                    onUpdateSaleLot = {this.onUpdateSaleLot}
                />

                <DeleteConfirmModal
                    isDeleteModalOpen={this.state.isDeleteModalOpen}
                    openDeleteModal={this.openDeleteModal}
                    isDeleteConfirmModalOpen={this.state.isDeleteConfirmModalOpen}
                    openDeleteConfirmModal={this.openDeleteConfirmModal}
                    deleteInfoHandler={this.deleteCompanyHandler}
                />

            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        companies: state.companies,
        company: state.company,
        auth: state.auth
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchCompanies: (params={type:'paginate'}) => dispatch(fetchCompaniesAction(params)),
        onCreateCompany: company => dispatch(createCompanyAction(company)),
        onUpdateCompany: (id, company) => dispatch(updateCompanyAction(id, company)),
        onDeleteCompany: id => dispatch(deleteCompanyAction(id)),
        
        onCreateSaleLot: saleLots => dispatch(createSaleLotsAction(saleLots)),
        onUpdateSaleLot: (id, saleLots) => dispatch(updateSaleLotsAction(id, saleLots)),
        onDeleteSaleLot: id => dispatch(deleteSaleLotsAction(id))

    }
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CompanyRegistration));