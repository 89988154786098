import {
    FETCH_PLACEMENT_REGISTERED,
    FETCH_PLACEMENT_REGISTERED_SUCCESS,
    FETCH_PLACEMENT_REGISTERED_FAILED,
} from "./actionType";
import api from "../../../utils/api";
import { convertIObjectToLocationSearch } from './../../../utils/helpers'



// Action fetch company and placement search
export const fetchCompanyPlacementSearchAction = () => dispatch => {
    return api.mgm.fetchCompanyPlacementsSearch()
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            return Promise.reject(error.response)
        });
};


export const fetchPlacementRegistered = () => {
    return {
        type: FETCH_PLACEMENT_REGISTERED
    }
};

export const fetchPlacementRegisteredSucceed = data => {
    console.log(data, 'data fetchPlacementRegisteredSucceed');
    return {
        type: FETCH_PLACEMENT_REGISTERED_SUCCESS,
        payload: {
            data: data
        }
    }
};

export const fetchPlacementRegisteredFailed = error => {
    return {
        type: FETCH_PLACEMENT_REGISTERED_FAILED,
        payload: {
            error: error
        }
    }
};


export const fetchPlacementRegisteredAction = (page, limit = null, company_id = null, place_id = null) => dispatch => {
    dispatch(fetchPlacementRegistered());
    let params = convertIObjectToLocationSearch({page, limit, company_id, place_id})
    return api.mgm.fetchPlacementRegistered(params)
        .then(response => {
            dispatch(fetchPlacementRegisteredSucceed(response.data.data));
            return Promise.resolve(response.data);
        })
        .catch(error => {
            dispatch(fetchPlacementRegisteredFailed(error.response.data.errors.message));
            return Promise.reject(error.response)
        });
};


export const deletePlacementAction = (id) => dispatch => {
    return api.mgm.deletePlacement(id)
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            return Promise.reject(error.response)
        });
};

