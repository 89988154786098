import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Helmet } from "react-helmet";
import { NavLink, Link } from "react-router-dom";
import * as routerName from "../../../utils/routerName";
//import * as webSocketApi from '../../../utils/websocketApi'; // 2023-03-28 CSTA 처리
import DataSetting from "./../DataSetting";
import {
  fetchCompaniesAction,
  createUserAction,
  updateUserAction,
  deleteUserAction,
  fetchCounselorByInfoSaleLotAction,
  unmountUsersAction,
} from "../../../store/actions";
import HasRole from "../../../utils/entrust/HasRole";
import progressBar from "../../../utils/progressBar";
import { hasRole } from "../../../utils/entrust/entrust";
import NotificationSystem from "react-notification-system";
import Pagination from "react-js-pagination";
import AddModalComponent from "../../../components/DataSetting/CreatUpdateCustom/AddModalComponent";
import EditModalComponent from "../../../components/DataSetting/CreatUpdateCustom/EditModalComponent";
import DeleteConfirmModal from "../../../components/DeleteConfirmModal/DeleteConfirmModal";
import axios from "axios";

class CounselorManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      collapseMgmAdmin: false,
      companies: [],
      currentCompany: null,
      infoSaleLotList: [],
      currentInfoSaleLot: "",
      counselorList: [],
      currentCounselor: null,
      dbCounselor: null,
      searchName: "",
      activePage: 1,
      per_page: null,
      total: null,
      disableModalBtn: true,
      infoEdit: null,
      deletingRow: null,
      isDeleteModalOpen: false,
      isDeleteConfirmModalOpen: false,
      usrCodeDisable: false,
      from: 0,
      userCheck: false,
    };
    this.notificationSystem = React.createRef();
  }

  /**
   * get data api
   */
  componentDidMount() {
    console.log(this.props.auth);
    const notification = this.notificationSystem.current;
    progressBar.start();
    if (hasRole(this.props.auth.user, "admin")) {
      this.setState({
        userCheck: true,
      });
    }
    this.props
      .onFetchCompanies()
      .then((response) => {
        this.setState(
          {
            usrCodeDisable: !hasRole(this.props.auth.user, "primarynet")
              ? false
              : true,
            companies: response.data,
            currentCompany: hasRole(this.props.auth.user, "admin")
              ? response.data[0]
              : null, //if user role is admin then not display select company and default currentCompany is company belong to admin
            infoSaleLotList: hasRole(this.props.auth.user, "admin")
              ? response.data[0].info_sale_lots
              : null,
          },
          () => {
            if (hasRole(this.props.auth.user, "admin")) {
              this.setState({
                ...this.state,
                currentInfoSaleLot: this.state.infoSaleLotList
              }, () => {
                // this.selectInfoSaleLostHandler(
                //   this.state.infoSaleLotList[0].lots_id
                // );
              })
              
            }
          }
        );
        console.log(this.state, "ppp");
        progressBar.done();
      })
      .catch((error) => {
        notification.addNotification({
          title: (
            <FormattedMessage id="dashboard.Error" defaultMessage="Error" />
          ),
          message: (
            <FormattedMessage
              id="dashboard.ErrorMessage"
              defaultMessage="Sorry! Something wen't wrong. Retry!"
            />
          ),
          level: "error",
        });
        progressBar.done();
      });
  }

  /**
   * show company
   * @param {*} data
   */
  renderCompany = (data) => {
    console.log(data, "data cop,");
    if (data && data.length > 0) {
      return data.map((item, index) => {
        return (
          <option key={index} value={item.company_id}>
            {item.company_name}
          </option>
        );
      });
    }
  };

  /**
   * select company
   * @param {*} id
   */
  selectCompanyHandler = (id) => {
    console.log(id);
    let { companies } = this.state;

    if (id != "null") {
      if (companies && companies.length > 0) {
        companies.map((item, idex) => {
          if (item.company_id == id) {
            this.setState({
              infoSaleLotList: item.info_sale_lots,
              currentCompany: item,
              currentInfoSaleLot: "",
              counselorList: [],
            });
          }
        });
      }
    } else {
      this.setState({
        infoSaleLotList: [],
        currentCompany: null,
        currentInfoSaleLot: "",
        counselorList: [],
      });
    }
  };

  /**
   * show info sale lot
   * @param {*} data
   */
  renderInfoSaleLots = (data) => {
    console.log(data, "datasdcascsdc");
    if (data && data.length > 0) {
      return data.map((item, index) => {
        return (
          <option key={index} value={item.lots_id}>
            {item.lots_name}
          </option>
        );
      });
    }
  };

  /**
   * select InfoSaleLost Handler
   * @param {*} id
   */
  selectInfoSaleLostHandler = (id) => {
    console.log(id, 'selectInfoSaleLostHandler');
    let { infoSaleLotList } = this.state;
    let infoSaleLotData = [];
    if (infoSaleLotList && infoSaleLotList.length > 0) {
      console.log(infoSaleLotList, 'infoSaleLotList');
      infoSaleLotList.map((item, index) => {
        if (item.lots_id == id) {
          infoSaleLotData.push(item);
        }
      });
      this.setState({
        ...this.state,
        currentInfoSaleLot: infoSaleLotData,
      });
    }

    let data = {
      lots_id: id,
      page: 1,
    };
    if (hasRole(this.props.auth.user, "admin")) {
      this.setState({
        ...this.state,
        disableModalBtn: false,
      });
    } else {
      if (id != "") {
        this.setState({
          disableModalBtn: false,
        });
        progressBar.start();
      } else {
        this.setState({
          ...this.state,
          disableModalBtn: true,
        });
      }
    }

    this.fetchCounselorByInfoSaleLot(data);
  };

  /**
   * get Counselor By InfoSaleLot
   * @param {*} data
   */
  fetchCounselorByInfoSaleLot = (data) => {
    progressBar.start();
    const notification = this.notificationSystem.current;
    this.props
      .onFetchCounselorByInfoSaleLot(data)
      .then((response) => {
        this.setState({
          counselorList: response.data,
          activePage: response.current_page,
          per_page: response.per_page,
          total: response.total,
          from: response.from,
        });
        progressBar.done();
      })
      .catch((error) => {
        notification.addNotification({
          title: (
            <FormattedMessage id="dashboard.Error" defaultMessage="Error" />
          ),
          message: (
            <FormattedMessage
              id="dashboard.ErrorMessage"
              defaultMessage="Sorry! Something wen't wrong. Retry!"
            />
          ),
          level: "error",
        });
        progressBar.done();
      });
  };

  /**
   * show item CounselorList
   * @param {*} data
   */
  renderTableCounselorList = (data) => {
    if (data && data.length > 0) {
      return data.map((item, index) => {
        return (
          <tr key={index}>
            <td className="inline_action">
              <div>{item.usr_code}</div>
            </td>
            <td className="inline_action">
              <div>{item.usr_name}</div>
            </td>
						{/*<td>
              <div>{item.dnd_type === "1" ? (
                  <FormattedMessage
                    id="dashboard.Status.Empty"
                    defaultMessage="--"
                  />
                ) : item.send_type === "2" ? (
                  <FormattedMessage
                    id="dashboard.Status.Refuse"
                    defaultMessage="Yes"
                  />
                ) : (
                  <FormattedMessage
                    id="dashboard.Status.Empty"
                    defaultMessage="--"
                  />
                )}</div>
            </td>*/}
            <td className="inline_action">
              <div>{item.forward_number ? item.forward_number : ""}</div>
            </td>
            <td className="inline_action">
              <div>{item.forward_yn == "1" ? (
                  <FormattedMessage
                    id="dashboard.Status.Forward"
                    defaultMessage="Forward"
                  />
									) : "--"
								}
							</div>
            </td>
            <td className="inline_action">
              <a
                className=""
                type="button"
                className="btn btn_add"
                data-target="#modal_edit_information"
                data-toggle="modal"
                onClick={() => this.showModalEdit(item)}
              >
                <i className="fas fa-edit"></i>
              </a>
							{<a
                href="#"
                className=""
                onClick={() => this.selectRowDelete(item)}
              >
                <i className="fas fa-trash-alt"></i>
              </a>}
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan={5}>
            <div className="nodata">No Data</div>
          </td>
        </tr>
      );
    }
  };

  /**
   *
   * @param {*} pageNumber
   */
  handlePageChange = (pageNumber) => {
    this.setState(
      {
        ...this.state,
        activePage: pageNumber,
      },
      () => {
        let data = {
          lots_id: this.state.currentInfoSaleLot[0].lots_id,
          page: this.state.activePage,
        };
        this.fetchCounselorByInfoSaleLot(data);
      }
    );
  };

  /**
   * search Counselor
   * @param {*} value
   */
  handleSearchCounselor = (value) => {
    this.setState({
      ...this.state,
      searchName: value,
    });
    let data = {
      lots_id: this.state.currentInfoSaleLot[0].lots_id,
      key_word: value,
      page: 1,
    };
    this.fetchCounselorByInfoSaleLot(data);
  };

  /**
   * create user
   * @param {*} data
   */
  addModal = (data) => {
    console.log(this.state.currentInfoSaleLot);
    const notification = this.notificationSystem.current;

    Math.random(10, 9);
    let dataAdd = {
      company_id: this.state.currentCompany.company_id,
      usr_code: data.pr_id,
      usr_name: data.pr_content,
      lots_id: this.state.currentInfoSaleLot[0].lots_id,
      role: 4,
      usr_type_code: 4,
      usr_password: "password",
      csta_pwd: "password",
    };
    console.log(dataAdd);
    this.props
      .onCreateUser(dataAdd)
      .then((response) => {
        console.log(response, "response");
        let data = {
          lots_id: this.state.currentInfoSaleLot[0].lots_id,
          page: 1,
        };
        this.fetchCounselorByInfoSaleLot(data);
        notification.addNotification({
          title: (
            <FormattedMessage
              id="dashboard.Succeed"
              defaultMessage="Succeed!"
            />
          ),
          message: (
            <FormattedMessage
              id="dashboard.Counselor.CreateSucceed"
              defaultMessage="Add new Line successfully!"
            />
          ),
          level: "success",
        });
        progressBar.done();
      })
      .catch((error) => {
        console.log(error, "errrr");
        notification.addNotification({
          title: (
            <FormattedMessage id="dashboard.Error" defaultMessage="Error" />
          ),
          message: (
            <FormattedMessage
              id="dashboard.ErrorMessage"
              defaultMessage="Sorry! Something wen't wrong. Retry!"
            />
          ),
          level: "error",
        });
        progressBar.done();
      });
  };

  /**
   * event show modal edit
   * @param {*} item
   */
  showModalEdit = (item) => {

		console.log("showModalEdit", item);
		console.log("dbCounselor", this.state.dbCounselor);
		
		console.log("currentCounselor", this.state.currentCounselor);
		const notification = this.notificationSystem.current;

		//const {user} = this.props.auth;
		// 현재 정보를 반영한다.
		let dataCheckForward = {
				"user_id": item.csta_id,
				"password": this.state.dbCounselor?.usr_alias ? this.state.dbCounselor.usr_alias : item.usr_alias,
				"forward_number" : "",
				"job_type" : "S"
		}

		axios({
				method : 'post',
				url : 'https://rtc.goodfone.co.kr:9798/post/call_forward.json',
				data : JSON.stringify(dataCheckForward),
				crossDomain : true,
				dataType:"json",
		}).then(res => {

				let dataForward = res.data;
				// 현재 정보 반영 (edit box)
				//item.forward_number = dataForward.current_cfw_no;
				//item.forward_yn = dataForward.current_cfw_type == "Y" ? 1 : 0;
        this.setState({
            ...this.state,
						dbCounselor: item,
            currentCounselor: item,
        }, () => {
						this.setState({
							infoEdit: {
								...this.state,
								pr_id: item.usr_code,
								pr_csta_id: item.csta_id,
								pr_content: item.usr_name,
								pr_forward_number: dataForward.current_cfw_no,
								pr_forward_yn: dataForward.current_cfw_type == "Y" ? 1 : 0
							},				
							currentCounselor: item,
						});
						console.log('item_after:', item);				
            //this.toggleModalUpdate();
        });

		}).catch(e => {
				notification.addNotification({
					title: (
						<FormattedMessage
							id="dashboard.ForwardError"
							defaultMessage="Sorry, something went wrong!. Retry!"
						/>
					),
					message: "착신설정을 읽어 오는데 실패했습니다.",
					level: "error",
				});
				// console.log(e.response);
				this.setState({
					...this.state,
					infoEdit: {
						...this.state,
						pr_id: item.usr_code,
						pr_csta_id: item.csta_id,
						pr_content: item.usr_name,
						pr_forward_number: item.pr_forward_number,
						pr_forward_yn: item.pr_forward_yn,
					},
					dbCounselor: item,
					currentCounselor: item,
				}); // setState

		}); // axios


  };

  /**
   * edit User
   * @param {*} data
   */
  editModal = (data) => {

    console.log("data edit", data);
    const notification = this.notificationSystem.current;
    console.log("currentCounselor", this.state.currentCounselor);
    let infoEdit = {
      usr_code: data.pr_id,
      usr_name: data.pr_content,
      forward_number: data.pr_forward_number,
      forward_yn: data.pr_forward_yn,
      company_id: this.state.currentCompany.company_id,
			lots_id: this.state.currentCounselor.lots_id
    };
    let counselorID = this.state.currentCounselor.usr_id;
    //infoEdit["lots_id"] = this.state.currentCounselor.lots_id;
    console.log(counselorID, infoEdit);
    console.log("this.state.currentCounselor:", this.state.currentCounselor);

		// 착신설정
		let dataForward = {
				"user_id":data.pr_csta_id,
				"password":this.state.dbCounselor.usr_alias,
				"forward_number" : data.pr_forward_number,
				"job_type" : {
						"FORWARD_ALL" : data.pr_forward_yn,
						"FORWARD_TIMEOUT" : "0",
						"FORWARD_BUSY" : "0",
						"FORWARD_UNREGISTER" : "0"
				}
		}
		axios({
				method : 'post',
				url : 'https://rtc.goodfone.co.kr:9798/post/call_forwardv2.json',
				data : JSON.stringify(dataForward),
				crossDomain : true,
				dataType:"json",
		}).then(res => {

		}).catch(e => { // 실패 할수도 있다.
			notification.addNotification({
				title: (
					<FormattedMessage
						id="dashboard.ForwardError"
						defaultMessage="Sorry, something went wrong!. Retry!"
					/>
				),
				message: "착신전환 설정간 통신에러가 발생",
				level: "error",
			});
		})

    this.props
      .onUpdateUser(counselorID, infoEdit)
      .then((response) => {
        let counselorList = this.state.counselorList;
        counselorList = counselorList.map((row) => {
          return row.usr_id == counselorID ? response.data : row;
        });
        this.setState(
          {
            counselorList,
          },
          () => {
            notification.addNotification({
              title: (
                <FormattedMessage
                  id="dashboard.Succeed"
                  defaultMessage="Succeed!"
                />
              ),
              message: (
                <FormattedMessage
                  id="dashboard.Counselor.UpdateSucceed"
                  defaultMessage="Update Counselor successfully!"
                />
              ),
              level: "success",
            });
          }
        );

        progressBar.done();
      })
      .catch((error) => {
        notification.addNotification({
          title: (
            <FormattedMessage
              id="dashboard.Error"
              defaultMessage="Sorry, something went wrong!. Retry!"
            />
          ),
          message: error.data?.errors?.message,
          level: "error",
        });
        progressBar.done();
      });
  };

  /**
   * select item delete
   * @param {*} data
   */
  selectRowDelete = (data) => {
    this.setState(
      {
        deletingRow: data.usr_id,
      },
      () => {
        this.openDeleteModal();
      }
    );
  };

  /**
   *
   * modal delete first
   */
  openDeleteModal = () => {
    this.setState({
      isDeleteModalOpen: !this.state.isDeleteModalOpen,
    });
  };

  /**
   *
   * modal delete second
   */
  openDeleteConfirmModal = () => {
    this.setState({
      isDeleteConfirmModalOpen: !this.state.isDeleteConfirmModalOpen,
    });
  };

  /**
   *
   * function delete
   */
  deleteInfoHandler = () => {
    progressBar.start();
    const notification = this.notificationSystem.current;
    let usr_id = this.state.deletingRow;
    this.props
      .onDeleteUser(usr_id)
      .then((response) => {
        let data = {
          lots_id: this.state.currentInfoSaleLot[0].lots_id,
          page:
            this.state.total - this.state.from > 0
              ? this.state.activePage
              : this.state.activePage - 1,
        };
        this.fetchCounselorByInfoSaleLot(data);
        // const counselorList = this.state.counselorList.slice();
        // const index = counselorList.findIndex(
        //   (row) => row.usr_id === this.state.deletingRow
        // );
        // if (index > -1) {
        //   counselorList.splice(index, 1);
        // }
        this.setState({
          deletingRow: null,
          isDeleteConfirmModalOpen: false,
        });
        notification.addNotification({
          title: (
            <FormattedMessage
              id="dashboard.Succeed"
              defaultMessage="Succeed!"
            />
          ),
          message: (
            <FormattedMessage
              id="dashboard.Counselor.DeleteSucceed"
              defaultMessage="Delete Counselor successfully!"
            />
          ),
          level: "success",
        });
        progressBar.done();
      })
      .catch((error) => {
        notification.addNotification({
          title: (
            <FormattedMessage
              id="dashboard.Error"
              defaultMessage="Sorry, something went wrong!. Retry!"
            />
          ),
          message: (
            <FormattedMessage
              id="dashboard.ErrorMessage"
              defaultMessage="Sorry! Something wen't wrong. Retry!"
            />
          ),
          level: "error",
        });
        progressBar.done();
      });
  };

  /**
   *
   * set user name default
   */
  showModalAdd = () => {
    // this.set
  };
  /**
   * render
   */
  render() {
    const {
      companies,
      infoSaleLotList,
      counselorList,
      currentInfoSaleLot,
      activePage,
      per_page,
      total,
      searchName,
      disableModalBtn,
      infoEdit,
      usrCodeDisable,
      userCheck,
    } = this.state;
    console.log(currentInfoSaleLot, "currentInfoSaleLot");
    let usrNameDefault;
    if (counselorList && counselorList.length > 0) {
      usrNameDefault = `Counselor${total + 1}`;
    }

    return (
      <Fragment>
        <FormattedMessage
          id="dashboard.CounselorManagement"
          defaultMessage="Counselor Management"
        >
          {(message) => (
            <Helmet>
              <title>{message}</title>
            </Helmet>
          )}
        </FormattedMessage>
        <NotificationSystem ref={this.notificationSystem} />
        <div className="main_top_bar static_bar">
          <div className="box_sum_col_top">
            <div className="box_left">
              <ul className="nav nav_tabs">
                <li className="nav_item">
                  <span className="nav_link">
                    <FormattedMessage
                      id="dashboard.CounselorManagement"
                      defaultMessage="Counselor Management"
                    ></FormattedMessage>
                  </span>
                </li>
              </ul>
            </div>
            <div className="box_center">
              <div className="tabs_static_item">
                <div className="main_control_time_select" style={userCheck ? {display:"inline"} : {display: 'flex'}}>
                  <div className="main_select main_box">
                    <HasRole role={["primarynet"]}>
                      <div className="main_label">
                        <FormattedMessage
                          id="dashboard.Company"
                          defaultMessage="Company"
                        />
                      </div>
                      <div className="custom_select">
                        <select
                          className="form_control"
                          // value={currentCompany?.company_name || ""}
                          onChange={(e) =>
                            this.selectCompanyHandler(e.target.value)
                          }
                        >
                          <option value="null">
                            {this.props.intl.formatMessage({
                              id: "dashboard.SelectCompany",
                              defaultMessage: "Select Company",
                            })}
                          </option>

                          {this.renderCompany(companies)}
                        </select>
                        <span className="item_dropdown" />
                      </div>
                    </HasRole>
                  </div>
                  <div className="main_select main_box">
                    <div className="main_label">
                      <FormattedMessage
                        id="dashboard.AffiliatedCompany"
                        defaultMessage="Affiliated Company"
                      />
                    </div>
                    <div className="custom_select">
                      <select
                        className="form_control"
                        value={
                          currentInfoSaleLot ? currentInfoSaleLot.lots_id : ""
                        }
                        onChange={(e) =>
                          this.selectInfoSaleLostHandler(e.target.value)
                        }
                      >
                        <option value={""}>
                            {this.props.intl.formatMessage({
                              id: "dashboard.SelectAffiliatedCompany",
                              defaultMessage: "Select Affiliated Company",
                            })}
                          </option>

                        {this.renderInfoSaleLots(infoSaleLotList)}
                      </select>
                      <span className="item_dropdown" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main_content">
          <div className="content_tabs_main content_static_tabs tab-content">
            <div className="tabs_static_item tabs_item1">
              <div className="box_content_static">
                <div className="left_col left_col_customer_care">
                  <DataSetting location={this.props.location} />
                </div>

                <div className="content_col_nopading">
                  <div className="list_control_box_preferences">
                    <div className="content_left">
                      <div className="content_column">
                        <div className="column_box mw_50">
                          <div className="heading_box">
                            <h5 className="title_preferences">
                              <FormattedMessage
                                id="dashboard.Counselors"
                                defaultMessage="Counselors"
                              />
                            </h5>
                          </div>
                          <div className="control_box_item">
                            <div className="card_header">
                              <div className="box_search">
                                <div className="box_control_input ">
                                  <input
                                    name="keysearch"
                                    type="text"
                                    value={searchName}
                                    disabled={disableModalBtn}
                                    className="control"
                                    onChange={(e) =>
                                      this.handleSearchCounselor(e.target.value)
                                    }
                                  />
                                  <button className="btn_form btn_secondary">
                                    <i className="fas fa-search" />
                                  </button>
                                </div>
                              </div>
                              {!userCheck ? (
                                <div className="box_add">
                                  <button
                                    type="button"
                                    disabled={disableModalBtn}
                                    className="btn btn_add"
                                    data-target="#modal_add_preferences"
                                    data-toggle="modal"
                                    onClick={this.showModalAdd}
                                  >
                                    <i className="fas fa-plus" />
                                  </button>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="card_body">
                              <div className="custom_table">
                                <table className="table st_table_5">
                                  <thead>
                                    <tr>
                                      <th className="inline_action">
                                        <FormattedMessage
                                          id="dashboard.CounselorName"
                                          defaultMessage="Counselor Name"
                                        />
                                      </th>
                                      <th className="inline_action">
                                        <FormattedMessage
                                          id="dashboard.ModifiedName"
                                          defaultMessage="Modified Name"
                                        />
                                      </th>
																			{/*<th>
                                        수신거부
                                      </th>*/}
                                      <th className="inline_action">
                                        착신번호
                                      </th>
                                      <th className="inline_action">
                                        착신여부
                                      </th>
                                      <th className="inline_action">
                                        
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.renderTableCounselorList(
                                      counselorList
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div className="car_footer">
                              <nav aria-label="...">
                                <ul className="custom_pagination pagination">
                                  {counselorList && counselorList.length > 0 ? (
                                    <Pagination
                                      itemClass="page-item"
                                      linkClass="page-link"
                                      activePage={activePage ? activePage : 1}
                                      itemsCountPerPage={per_page}
                                      totalItemsCount={total}
                                      pageRangeDisplayed={10}
                                      onChange={this.handlePageChange}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </ul>
                              </nav>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AddModalComponent
          addModal={this.addModal}
          usrCodeDisable={usrCodeDisable}
          type={"counselorMgt"}
          usrNameDefault={usrNameDefault}
        />
        <EditModalComponent
          dataEdit={this.state.infoEdit}
          editModal={this.editModal}
          type={"counselorMgt"}
        />
        <DeleteConfirmModal
          isDeleteModalOpen={this.state.isDeleteModalOpen}
          openDeleteModal={this.openDeleteModal}
          isDeleteConfirmModalOpen={this.state.isDeleteConfirmModalOpen}
          openDeleteConfirmModal={this.openDeleteConfirmModal}
          deleteInfoHandler={this.deleteInfoHandler}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companies: state.companies,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchCompanies: () => dispatch(fetchCompaniesAction()),
    onFetchCounselorByInfoSaleLot: (infoSaleLotId) =>
      dispatch(fetchCounselorByInfoSaleLotAction(infoSaleLotId)),
    onCreateUser: (user) => dispatch(createUserAction(user)),
    onUpdateUser: (id, user) => dispatch(updateUserAction(id, user)),
    onDeleteUser: (id) => dispatch(deleteUserAction(id)),
    onUnmountUsers: () => dispatch(unmountUsersAction()),
  };
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(CounselorManagement)
);
