import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Helmet } from 'react-helmet';
import { NavLink, Link } from "react-router-dom";
import { Modal, ModalHeader, ModalFooter, ModalBody, Button } from 'reactstrap';

class UpdateDistrictModal extends Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
	};

	render() {
		const { districtForm } = this.props;

		return (
			<Fragment>

				<Modal
					isOpen={this.props.isOpen}
					toggle={this.props.toggle}
					modalClassName='modal_add_preferences'
					className="modal-dialog modal-dialog-scrollable"
					contentClassName="modal-content"
				>
					<div className="modal-header">
						<h5 className="modal-title">
							<FormattedMessage id="dashboard.Common.Update" defaultMessage="Update" />
						</h5>
						<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.props.toggle}>
							<span aria-hidden="true">×</span>
						</button>
					</div>

					<div className="modal-body">
						<div className="box_add_ppreferences_1">
							<div className="box_group_control">
								<div className="heading_title">
									<FormattedMessage id="dashboard.Name" defaultMessage="Name" />
								</div>
								<div className="group_control_in">
									<input
										type="text"
										className="form_control"
										name="dist_name"
										value={districtForm?.dist_name}
										onChange={this.props.handleChange}
									/>
								</div>
							</div>

							<div className="box_group_control">
								<div className="heading_title">
									<FormattedMessage id="dashboard.Order" defaultMessage="Order" />
								</div>
								<div className="group_control_in">
									<input
										type="text"
										className="form_control"
										name="dist_order"
										value={districtForm?.dist_order}
										onChange={this.props.handleChange}
									/>
								</div>
							</div>
						</div>
					</div>


					<div className="modal-footer">
						<button onClick={this.props.toggle}
							type="button"
							className="btn btn-outline-dark "
							data-dismiss="modal"
							aria-label="Close">
							<FormattedMessage
								id="dashboard.Cancel"
								defaultMessage="Cancel" />
						</button>
						<button onClick={this.props.onUpdateDistrict}
							type="button"
							className="btn btn-dark btn_add"
							data-dismiss="modal"
							aria-label="Close">
							<FormattedMessage
								id="dashboard.Save"
								defaultMessage="Save" />
						</button>
					</div>
				</Modal>

			</Fragment>
		)
	}
}

const mapStateToProps = state => {
	return {
	}
}

const mapDispatchToProps = dispatch => {
	return {
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateDistrictModal)
