import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Helmet } from "react-helmet";
import { NavLink, Link } from "react-router-dom";
import * as routerName from "../../../utils/routerName";
import DataSetting from "./../DataSetting";
import NotificationSystem from "react-notification-system";

import {
  fetchCompaniesAction,
  fetchInfoLinesAction,
  createInfoLineAction,
  updateInfoLineAction,
  deleteInfoLineAction,
} from "../../../store/actions";
import progressBar from "../../../utils/progressBar";
import HasRole from "../../../utils/entrust/HasRole";
import { hasRole } from "../../../utils/entrust/entrust";
import AddModalComponent from "../../../components/DataSetting/CreatUpdateCustom/AddModalComponent";
import EditModalComponent from "../../../components/DataSetting/CreatUpdateCustom/EditModalComponent";
import DeleteConfirmModal from "../../../components/DeleteConfirmModal/DeleteConfirmModal";
import Pagination from "react-js-pagination";

class LineInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      collapseMgmAdmin: false,
      companies: [],
      currentCompany: null,
      infoLineList: [],
      currentInfoDistrict: null,
      disableModalBtn: true,
      deletingRow: null,
      infoEdit: null,
      isDeleteModalOpen: false,
      isDeleteConfirmModalOpen: false,
      searchName: "",
      activePage: 1,
      per_page: null,
      total: null,
      from: 0,
      userCheck: false,
    };
    this.notificationSystem = React.createRef();
  }

  /**
   * get data
   */
  componentDidMount() {
    const notification = this.notificationSystem.current;
    progressBar.start();
    this.props
      .onFetchCompanies()
      .then((response) => {
        this.setState({
          companies: response.data,
          currentCompany: hasRole(this.props.auth.user, "admin")
            ? response.data[0]
            : null, //if user role is admin then not display select company and default currentCompany is company belong to admin
        });
        progressBar.done();
      })
      .catch((error) => {
        notification.addNotification({
          title: (
            <FormattedMessage id="dashboard.Error" defaultMessage="Error" />
          ),
          message: (
            <FormattedMessage
              id="dashboard.ErrorMessage"
              defaultMessage="문제가 발생하였습니다. 다시 시도해주세요."
            />
          ),
          level: "error",
        });
        progressBar.done();
      });
    if (hasRole(this.props.auth.user, "admin")) {
      this.setState(
        {
          userCheck: true,
          disableModalBtn: false
        },
        () => {
          let data = {
            company_id: this.props.auth.user.company_id,
          };
          this.onFetchInfoLinesByCompany(data);
        }
      );
    }
  }

  renderCompany = (data) => {
    if (data && data.length > 0) {
      return data.map((item, index) => {
        return (
          <option key={index} value={item.company_id}>
            {item.company_name}
          </option>
        );
      });
    }
  };

  /**
   *
   * select company let show data line
   */
  selectCompanyHandler = (id) => {
    progressBar.start();
    const notification = this.notificationSystem.current;
    if (id == "" || id == null) {
      this.setState({
        infoLineList: [],
        currentCompany: null,
        disableModalBtn: true,
      });
      return;
    }
    let data = {
      company_id: id,
    };
    let { companies } = this.state;
    if (companies && companies.length > 0) {
      companies.map((item, idex) => {
        if (item.company_id == id) {
          this.props
            .onFetchInfoLinesByCompany(data)
            .then((response) => {
              this.setState({
                infoLineList: response.data,
                currentCompany: item,
                disableModalBtn: false,
                activePage: response.current_page,
                per_page: response.per_page,
                total: response.total,
              });
              progressBar.done();
            })
            .catch((error) => {
              notification.addNotification({
                title: (
                  <FormattedMessage
                    id="dashboard.Error"
                    defaultMessage="Error"
                  />
                ),
                message: (
                  <FormattedMessage
                    id="dashboard.ErrorMessage"
                    defaultMessage="문제가 발생하였습니다. 다시 시도해주세요."
                  />
                ),
                level: "error",
              });
              progressBar.done();
            });
        }
      });
    }
  };

  /**
   *
   * show data line table
   */
  renderTableLineInformation = (data) => {
    if (data && data.length > 0) {
      return data.map((item, index) => {
        return (
          <tr key={index}>
            <td>
              <div>
                {item.line_info && item.line_no.length > 20
                  ? item.line_no.substring(0, 20) + "..."
                  : item.line_no}
              </div>
            </td>
            <td>
              <div>
                {item.line_info && item.line_info.length > 20
                  ? item.line_info.substring(0, 20) + "..."
                  : item.line_info}
              </div>
            </td>
            <td className="inline_action">
              <a
                className=""
                type="button"
                className="btn btn_add"
                data-target="#modal_edit_information"
                data-toggle="modal"
                onClick={() => this.showModalEdit(item)}
              >
                <i className="fas fa-edit"></i>
              </a>
              <a
                href="#"
                className=""
                onClick={() => this.selectRowDelete(item.line_no)}
              >
                <i className="fas fa-trash-alt"></i>
              </a>
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan={3}>
            <div className="nodata">No Data</div>
          </td>
        </tr>
      );
    }
  };

  /**
   *
   * show modal edit
   */
  showModalEdit = (data) => {
    this.setState({
      infoEdit: {
        pr_id: data.line_no,
        pr_content: data.line_info,
      },
    });
  };

  /**
   *
   * function create
   */
  addModal = (data) => {
    const notification = this.notificationSystem.current;
    let dataAdd = {
      line_no: data.pr_id,
      line_info: data.pr_content,
      company_id: this.state.currentCompany.company_id,
    };
    this.props
      .onCreateInfoLine(dataAdd)
      .then((response) => {
        let data = {
          company_id: this.state.currentCompany.company_id,
          page: 1,
        };
        this.onFetchInfoLinesByCompany(data);
        notification.addNotification({
          title: (
            <FormattedMessage id="dashboard.Succeed" defaultMessage="Succeed" />
          ),
          message: "회선번호를 성공적으로 추가되었습니다",
          level: "success",
        });
        progressBar.done();
        progressBar.done();
      })
      .catch((error) => {
        notification.addNotification({
          title: (
            <FormattedMessage id="dashboard.Error" defaultMessage="Error" />
          ),
          message: error.data.errors.message?.line_no,
          level: "error",
        });
        progressBar.done();
      });
  };

  /**
   *
   * function update
   */
  editModal = (data) => {
    const notification = this.notificationSystem.current;
    let infoLineID = this.state.infoEdit.pr_id;
    let dataEdit = {
      line_no: data.pr_id,
      line_info: data.pr_content,
    };
    // console.log(infoLineID,dataEdit, 'line_no');
    this.props
      .onUpdateInfoLine(infoLineID, dataEdit)
      .then((response) => {
        console.log(response, "responseresponseresponse");
        let infoLineList = this.state.infoLineList;
        infoLineList = infoLineList.map((row) => {
          return row.line_no == infoLineID ? response.data : row;
        });
        this.setState(
          {
            infoLineList,
          },
          () => {
            notification.addNotification({
              title: (
                <FormattedMessage
                  id="dashboard.Succeed"
                  defaultMessage="Succeed"
                />
              ),
              message: (
                <FormattedMessage
                  id="dashboard.LineInfo.UpdateSucceed"
                  defaultMessage="Update Line successfully!"
                />
              ),
              level: "success",
            });
          }
        );
        progressBar.done();
      })
      .catch((error) => {
        notification.addNotification({
          title: (
            <FormattedMessage id="dashboard.Error" defaultMessage="Error" />
          ),
          message: "회선번호 은(는) 이미 사용 중입니다.",
          level: "error",
        });
        progressBar.done();
      });
  };

  /**
   *
   * function delete
   */
  deleteInfoHandler = () => {
    progressBar.start();
    const notification = this.notificationSystem.current;
    let line_no = this.state.deletingRow;
    progressBar.start();
    this.props
      .onDeleteInfoLine(line_no)
      .then((response) => {
        console.log(response, "onDeleteInfoLine");
        let data = {
          company_id: response.data.company_id,
          page:
            this.state.total - this.state.from > 0
              ? this.state.activePage
              : this.state.activePage - 1,
        };
        this.onFetchInfoLinesByCompany(data);
        this.setState({
          deletingRow: null,
          isDeleteConfirmModalOpen: false,
        });
        notification.addNotification({
          title: (
            <FormattedMessage
              id="dashboard.Succeed"
              defaultMessage="Succeed!"
            />
          ),
          message: (
            <FormattedMessage
              id="dashboard.LineInfo.DeleteSucceed"
              defaultMessage="Delete Line successfully!"
            />
          ),
          level: "success",
        });
        progressBar.done();
      })
      .catch((error) => {
        notification.addNotification({
          title: (
            <FormattedMessage id="dashboard.Error" defaultMessage="Error" />
          ),
          message: (
            <FormattedMessage
              id="dashboard.ErrorMessage"
              defaultMessage="문제가 발생하였습니다. 다시 시도해주세요."
            />
          ),
          level: "error",
        });
        progressBar.done();
      });
  };

  selectRowDelete = (line_no) => {
    this.setState(
      {
        deletingRow: line_no,
      },
      () => {
        this.openDeleteModal();
      }
    );
  };

  /**
   *
   * modal delete first
   */
  openDeleteModal = () => {
    this.setState({
      isDeleteModalOpen: !this.state.isDeleteModalOpen,
    });
  };

  /**
   *
   * modal delete second
   */
  openDeleteConfirmModal = () => {
    this.setState({
      isDeleteConfirmModalOpen: !this.state.isDeleteConfirmModalOpen,
    });
  };

  /**
   * search line
   */
  handleSearchLine = (value) => {
    this.setState({
      ...this.state,
      searchName: value,
    });
    let data = {
      company_id: this.state.currentCompany.company_id,
      key_work: value,
    };
    this.onFetchInfoLinesByCompany(data);
  };

  /**
   *
   * change page
   */
  handlePageChange = (pageNumber) => {
    this.setState(
      {
        ...this.state,
        activePage: pageNumber,
      },
      () => {
        let data = {
          company_id: this.state.currentCompany.company_id,
          page: this.state.activePage,
        };
        this.onFetchInfoLinesByCompany(data);
      }
    );
  };

  onFetchInfoLinesByCompany = (data) => {
    const notification = this.notificationSystem.current;
    progressBar.start();
    this.props
      .onFetchInfoLinesByCompany(data)
      .then((response) => {
        this.setState({
          ...this.state,
          infoLineList: response.data,
          activePage: response.current_page,
          per_page: response.per_page,
          total: response.total,
          from: response.from,
        });
        progressBar.done();
      })
      .catch((err) => {
        notification.addNotification({
          title: (
            <FormattedMessage id="dashboard.Error" defaultMessage="Error" />
          ),
          message: (
            <FormattedMessage
              id="dashboard.ErrorMessage"
              defaultMessage="문제가 발생하였습니다. 다시 시도해주세요."
            />
          ),
          level: "error",
        });
      });
  };

  render() {
    const {
      companies,
      infoLineList,
      disableModalBtn,
      infoEdit,
      searchName,
      per_page,
      activePage,
      total,
      userCheck,
    } = this.state;
    return (
      <Fragment>
        <FormattedMessage
          id="dashboard.LineInformation"
          defaultMessage="Line Information"
        >
          {(message) => (
            <Helmet>
              <title>{message}</title>
            </Helmet>
          )}
        </FormattedMessage>
        <NotificationSystem ref={this.notificationSystem} />
        <div className="main_top_bar static_bar">
          <div className="box_sum_col_top">
            <div className="box_left">
              <ul className="nav nav_tabs">
                <li className="nav_item">
                  <span className="nav_link">
                    <FormattedMessage
                      id="dashboard.LineInformation"
                      defaultMessage="Line Information"
                    />
                  </span>
                </li>
              </ul>
            </div>
            <div className="box_center">
              <div className="tabs_static_item">
                {!userCheck ? (
                  <div className="main_control_time_select">
                    <div className="main_select main_box">
                      <div className="main_label">
                        <FormattedMessage
                          id="dashboard.Company"
                          defaultMessage="Company"
                        />
                      </div>
                      <div className="custom_select">
                        <select
                          className="form_control"
                          onChange={(e) =>
                            this.selectCompanyHandler(e.target.value)
                          }
                        >
                          <option value="">
                            {this.props.intl.formatMessage({
                              id: "dashboard.SelectCompany",
                              defaultMessage: "Select Company",
                            })}
                          </option>
                          {this.renderCompany(companies)}
                        </select>
                        <span className="item_dropdown" />
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="main_content">
          <div className="content_tabs_main content_static_tabs tab-content">
            <div className="tabs_static_item tabs_item1">
              <div className="box_content_static">
                <div className="left_col left_col_customer_care">
                  <DataSetting location={this.props.location} />
                </div>

                <div className="content_col_nopading">
                  <div className="list_control_box_preferences">
                    <div className="content_left">
                      <div className="content_column">
                        <div className="column_box mw_50">
                          <div className="heading_box">
                            <h5 className="title_preferences">
                              <FormattedMessage
                                id="dashboard.LineInformation"
                                defaultMessage="Line Information"
                              />
                            </h5>
                          </div>
                          <div className="control_box_item">
                            <div className="card_header">
                              <div className="box_search">
                                <div className="box_control_input ">
                                  <input
                                    name="keysearch"
                                    type="text"
                                    className="control"
                                    value={searchName}
                                    disabled={disableModalBtn}
                                    onChange={(e) =>
                                      this.handleSearchLine(e.target.value)
                                    }
                                  />
                                  <button className="btn_form btn_secondary">
                                    <i className="fas fa-search" />
                                  </button>
                                </div>
                              </div>
                              {!userCheck ? (
                                <div className="box_add">
                                  <button
                                    type="button"
                                    disabled={disableModalBtn}
                                    className="btn btn_add"
                                    data-target="#modal_add_preferences"
                                    data-toggle="modal"
                                  >
                                    <i className="fas fa-plus" />
                                  </button>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="card_body">
                              <div className="custom_table">
                                <table className="table st_table_5">
                                  <thead>
                                    <tr>
                                      <th>
                                        <FormattedMessage
                                          id="dashboard.Line"
                                          defaultMessage="Line #"
                                        />
                                      </th>
                                      <th>
                                        <FormattedMessage
                                          id="dashboard.LineText"
                                          defaultMessage="Line Text"
                                        />
                                      </th>
                                      <th />
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.renderTableLineInformation(
                                      infoLineList
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div className="car_footer">
                              <nav aria-label="...">
                                <ul className="custom_pagination pagination ">
                                  {infoLineList && infoLineList.length > 0 ? (
                                    <Pagination
                                      itemClass="page-item"
                                      linkClass="page-link"
                                      activePage={activePage ? activePage : 1}
                                      itemsCountPerPage={per_page}
                                      totalItemsCount={total}
                                      pageRangeDisplayed={10}
                                      onChange={this.handlePageChange}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </ul>
                              </nav>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AddModalComponent addModal={this.addModal} type={"lineInfo"} />
        <EditModalComponent
          dataEdit={infoEdit}
          editModal={this.editModal}
          type={"lineInfo"}
        />
        <DeleteConfirmModal
          isDeleteModalOpen={this.state.isDeleteModalOpen}
          openDeleteModal={this.openDeleteModal}
          isDeleteConfirmModalOpen={this.state.isDeleteConfirmModalOpen}
          openDeleteConfirmModal={this.openDeleteConfirmModal}
          deleteInfoHandler={this.deleteInfoHandler}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companies: state.companies,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchCompanies: () => dispatch(fetchCompaniesAction()),
    onFetchInfoLinesByCompany: (company_id) =>
      dispatch(fetchInfoLinesAction(company_id)),
    onCreateInfoLine: (infoLine) => dispatch(createInfoLineAction(infoLine)),
    onUpdateInfoLine: (id, infoLine) =>
      dispatch(updateInfoLineAction(id, infoLine)),
    onDeleteInfoLine: (id) => dispatch(deleteInfoLineAction(id)),
  };
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(LineInformation)
);
