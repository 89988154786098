import {
    AUTH_SUCCEED,
    AUTH_FAILED,
    AUTH_LOGOUT,
    AUTH_FETCH_CURRENT_USER,
    AUTH_USER,
    AUTH_CHANGE_DISTURB_USER,
    AUTH_CHANGE_INFO_SALE_LOT,
    AUTH_CHANGE_MEMO
} from '../actions/actionTypes';

const initalState = {
    token: null,
    isAuthenticated: false,
    user: null,
    error: null,
    loading: false
};

const auth = (state = initalState, action) => {
    switch (action.type) {
        case AUTH_USER: {
            return {
                ...state,
                loading: true
            }
        }
        case AUTH_SUCCEED: {
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                token: action.payload.token,
                user: action.payload.user
            }
        }
        case AUTH_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        }
        case AUTH_FETCH_CURRENT_USER: {
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                token: action.payload.token,
                user: action.payload.user
            }
        }
        case AUTH_LOGOUT: {
            return {
                ...state,
                isAuthenticated: false,
                loading: false,
                user: null,
                token: null
            }
        }
        case AUTH_CHANGE_DISTURB_USER : { // case nay doi trang thai disturb
            return {
                ...state,
                loading: false,
                user: {
                    ...state.user,
                    dnd_type : action.payload.dnd_type
                }
            }
        }
        case AUTH_CHANGE_INFO_SALE_LOT : {
            return {
                ...state,
                loading: false,
                user: {
                    ...state.user,
                    info_sale_lot : action.payload
                }
            }
        }
        case AUTH_CHANGE_MEMO : {
            console.log(action.payload,'memo')
            return {
                ...state,
                loading: false,
                user: {
                    ...state.user,
                    memo : action.payload
                }
            }
        }
        default:
            return state;
    }
};

export default auth;