import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Helmet } from 'react-helmet';
import { NavLink, Link } from "react-router-dom";
import { Modal, ModalHeader, ModalFooter, ModalBody, Button } from 'reactstrap';
import RichTextEditor from 'react-rte';
import TextareaAutosize from 'react-autosize-textarea';
import * as helpers from '../../../../utils/helpers';

class UpdateTextManagerModal extends Component {

    constructor(props) {
        super(props);
				//console.log('this.props:',this.props);
				//console.log('this.textManagerForm:',this.props.textManagerForm);
        this.state = {
					week: [],
					week_all: false,
        };
    }

    componentDidMount() {

    };

    renderListImages = (images) => {
        if (Array.isArray(images)) {
            return images.map((item, index) => {
                console.log(item, 'aaaaaaa');
                return (
                    <div className="item_file" key={index}>
                        <span className="close" onClick={() => this.props.handleRemoveImage(item)}><i className="fas fa-times" /></span>          

                        <div className="img">
                            <img src={item.image_path}/>
                        </div>
                        <div className="control_img">
                            <label>{item.image_name}</label>
                        </div>
                    </div>
                )
            })
        }
    }

		insertContents = (e, text) => {

			const {textManagerForm} = this.props;
			let prev_input = textManagerForm?.content;

			console.log('prev_input:',prev_input);

			const contentsRef = document.getElementById('contents');

			console.log('contentsRef:',contentsRef);

			const selectionStart = contentsRef.selectionStart;
			const selectionEnd = contentsRef.selectionEnd;

			let newValue =
				 prev_input.substring(0, selectionStart) +
				 text +
				 prev_input.substring(selectionEnd, prev_input.length);

			console.log('newValue:',newValue);

			this.props.handleContents(newValue);

		}

    render() {
        const { 
						week,
						week_all,
            textManagerForm,
						totalLength,
						saleLotConfig
         } = this.props;

				const {
				} = this.state;

        //console.log(textManagerForm, 'textManagerForms');
				let hours = [];
				for (let i=0; i < 24; i++) hours.push(<option value={i} key={i}>{i} 시</option>)

				let minutes = [];
				for (let i=0; i < 60; i+=5) minutes.push(<option value={i} key={i}>{i} 분</option>)

				let limits = [];
				limits.push(<option value='0' key='0'>전송 제한 없음</option>)
				for (let h=1 ; h<30 ; h++ ) limits.push(<option value={h}>{h} 일</option>)

        return (
            <Fragment>
                <Modal
                    isOpen={this.props.isOpen}
                    toggle={this.props.toggle}
                    modalClassName='modal_add_preferences'
                    className="modal-dialog modal-dialog-scrollable"
                    contentClassName="modal-content"
                >
                    <div className="modal-header">
                        <h5 className="modal-title">
                            <FormattedMessage id="dashboard.Common.Update" defaultMessage="Update" />
                        </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.props.toggle}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>

                    <div className="modal-body">
                        <div className="box_add_ppreferences_1">
                            {/* <div className="box_group_control">
                                <div className="heading_title">Company</div>
                                <div className="group_control_in">
                                    <input
                                        type="text"
                                        className="form_control"
                                        name="company_name"
                                        value={currentCompany?.company_name}
                                        onChange={this.props.handleChange}
                                        readOnly
                                    />
                                </div>
                            </div> */}

                            <div className="box_group_control">
                                <div className="heading_title">
                                    <FormattedMessage id="dashboard.TextManagement.Title" defaultMessage="Title" />
                                </div>
                                <div className="group_control_in">
                                    <input
                                        type="text"
                                        className="form_control"
                                        name="title"
                                        value={textManagerForm?.title}
                                        onChange={this.props.handleChange}
                                    />
                                </div>
                            </div>

                            <div className="box_group_control">
                                <div className="heading_title">
                                    <FormattedMessage id="dashboard.TextManagement.Status" defaultMessage="Status" />
                                </div>
                                <div className="group_control_in group_select">
                                    <select
                                        className="form_control"
                                        onChange={this.props.handleChange}
                                        name="status"
                                        value={textManagerForm?.status || 0}
                                    >
                                        <option value={1}>ON</option>
                                        <option value={0}>OFF</option>
                                    </select>
                                    <span className="item_dropdown" />
                                </div>
                            </div>
													{ textManagerForm?.status == 1 &&
														<React.Fragment>
                            <div className="box_group_control">
                                <div className="heading_title">
                                    <FormattedMessage id="dashboard.TextManagement.Option.Mode" defaultMessage="Mode" />
                                </div>
                                <div className="group_control_in group_select">
                                    <select
                                        className="form_control"
                                        onChange={this.props.handleChange}
                                        name="mode"
                                        value={textManagerForm?.mode || 0}
                                    >
                                        <option value={1}>수신</option>
                                        <option value={2}>발신</option>
                                        <option value={3}>수/발신</option>
                                        <option value={4}>부재건만</option>
                                        <option value={0}>모두</option>
                                    </select>
                                    <span className="item_dropdown" />
                                </div>
                            </div>

                            <div className="box_group_control">
                                <div className="heading_title">
                                    <FormattedMessage id="dashboard.TextManagement.Option.Week" defaultMessage="Week" />
                                </div>
                                <div className="group_control_in group_select">
																	<table>
																		<tr>
																			<td><input type="checkbox" name="week0" value="0" checked={ week.includes('0') ? true : false } onChange={(e) => this.props.handleWeek(e, '0')} onClick={e => e.stopPropagation()} ref={(input) => this[`checkbox_0`] = input} /> <FormattedMessage id="dashboard.Week.Sun" defaultMessage="Sun" /></td> 
																			<td><input type="checkbox" name="week1" value="1" checked={ week.includes('1') ? true : false } onChange={(e) => this.props.handleWeek(e, '1')} onClick={e => e.stopPropagation()} ref={(input) => this[`checkbox_1`] = input} /> <FormattedMessage id="dashboard.Week.Mon" defaultMessage="Mon" /></td> 
																			<td><input type="checkbox" name="week2" value="2" checked={ week.includes('2') ? true : false } onChange={(e) => this.props.handleWeek(e, '2')} onClick={e => e.stopPropagation()} ref={(input) => this[`checkbox_2`] = input} /> <FormattedMessage id="dashboard.Week.Tue" defaultMessage="Tue" /></td> 
																			<td><input type="checkbox" name="week3" value="3" checked={ week.includes('3') ? true : false } onChange={(e) => this.props.handleWeek(e, '3')} onClick={e => e.stopPropagation()} ref={(input) => this[`checkbox_3`] = input} /> <FormattedMessage id="dashboard.Week.Wed" defaultMessage="Wed" /></td> 
																			<td><input type="checkbox" name="week4" value="4" checked={ week.includes('4') ? true : false } onChange={(e) => this.props.handleWeek(e, '4')} onClick={e => e.stopPropagation()} ref={(input) => this[`checkbox_4`] = input} /> <FormattedMessage id="dashboard.Week.Thu" defaultMessage="Thu" /></td> 
																			<td><input type="checkbox" name="week5" value="5" checked={ week.includes('5') ? true : false } onChange={(e) => this.props.handleWeek(e, '5')} onClick={e => e.stopPropagation()} ref={(input) => this[`checkbox_5`] = input} /> <FormattedMessage id="dashboard.Week.Fri" defaultMessage="Fri" /></td> 
																			<td><input type="checkbox" name="week6" value="6" checked={ week.includes('6') ? true : false } onChange={(e) => this.props.handleWeek(e, '6')} onClick={e => e.stopPropagation()} ref={(input) => this[`checkbox_6`] = input} /> <FormattedMessage id="dashboard.Week.Sat" defaultMessage="Sat" /></td> 
																			<td><input type="checkbox" checked={ week_all ? true : false } name="week_all" onClick={(e) => this.props.handleWeekAll(e)} /> <FormattedMessage id="dashboard.CheckAll" defaultMessage="All" /></td> 
																		</tr>
																	</table>
                                </div>
                            </div>

                            <div className="box_group_control">
                                <div className="heading_title">
                                    <FormattedMessage id="dashboard.TextManagement.Option.Time" defaultMessage="Time" />
                                </div>
                                <div className="group_control_in group_select">
																	<table>
																		<tr>
																			<td>
																				<select
																						className="form_control"
																						onChange={this.props.handleChange}
																						name="start_hour"
																						value={textManagerForm?.start_hour || 0}
																				>
																						{ hours }
																				</select>
																				<span className="item_dropdown" />
																			</td> 
																			<td>
																				<select
																						className="form_control"
																						onChange={this.props.handleChange}
																						name="start_min"
																						value={textManagerForm?.start_min || 0}
																				>
																						{ minutes }
																				</select>
																				<span className="item_dropdown" />
																			</td> 
																			<td> - </td>
																			<td>
																				<select
																						className="form_control"
																						onChange={this.props.handleChange}
																						name="end_hour"
																						value={textManagerForm?.end_hour || 23}
																				>
																						{ hours }
																				</select>
																				<span className="item_dropdown" />
																			</td> 
																			<td>
																				<select
																						className="form_control"
																						onChange={this.props.handleChange}
																						name="end_min"
																						value={textManagerForm?.end_min || 0}
																				>
																						{ minutes }
																				</select>
																				<span className="item_dropdown" />
																			</td> 
																		</tr>
																	</table>
                                </div>
                            </div>

                            <div className="box_group_control">
                                <div className="heading_title">
                                    <FormattedMessage id="dashboard.TextManagement.Option.Duplicate" defaultMessage="Time" />
                                </div>
                                <div className="group_control_in group_select">
																		<select
																				className="form_control"
																				onChange={this.props.handleChange}
																				name="duplicate"
																				value={textManagerForm?.duplicate || 0}
																		>
																				{ limits }
																		</select>
																		<span className="item_dropdown" />
                                </div>
                            </div>
														</React.Fragment>
													}
														<div className="box_group_control">
                                <div className="heading_title">
                                    <FormattedMessage id="dashboard.TextManagement.Content" defaultMessage="Content" /> {totalLength} Bytes
                                </div>
                                <div className="group_control_in group_textarea">
                                    <textarea
                                        type="text"
																				id="contents"
                                        className="form_control"
                                        name="content"
                                        value={textManagerForm?.content}
                                        onChange={this.props.handleChange}
                                    />
                                </div>
                            </div>

														<div className="box_group_control">
                                <div className="heading_title">
                                    치환문구 (클릭시 삽입)
                                </div>
																<div className="group_control_in box_btn">
																		{
																			saleLotConfig?.profile_list?.length > 0 ?
																					saleLotConfig.profile_list.map((item, index) => {
																							return (
																								<button className="btn_add" style={{height:"30px",width:"auto",backgroundColor:"#17A2B8",color:"#ffffff",border:"none",fontSize:"14px"}} onClick={ (e) =>this.insertContents(e, '{@'+item.descr+'@}') }>{item.descr}</button>
																							)
																					}) :
																					(
																						""
																					)
																		}
																		<div className="" style={{clear: "both"}}></div>
																		{
																			saleLotConfig?.master_list?.length > 0 ?
																					saleLotConfig.master_list.map((item, index) => {
																							return (
																								<button className="btn_add" style={{height:"30px",width:"auto",backgroundColor:"#17A2B8",color:"#ffffff",border:"none",fontSize:"14px"}} onClick={ (e) =>this.insertContents(e, '{@'+item.descr+'@}') }>{item.descr}</button>
																							)
																					}) :
																					(
																						""
																					)
																		}
																</div>
														</div>

                            <div className="box_group_control">
                                <div className="heading_title">
                                    <FormattedMessage id="dashboard.Type" defaultMessage="Type" />
                                </div>
                                <div className="group_control_in group_select">
                                    <select
                                        className="form_control"
                                        onChange={this.props.handleChange}
                                        name="send_type"
                                        value={textManagerForm?.send_type || 0}
                                    >
                                        <option value={0}>SMS</option>
                                        <option value={2}>LMS</option>
                                        <option value={1}>MMS</option>
                                    </select>
                                    <span className="item_dropdown" />
                                </div>
                            </div>

														<div className="box_group_control input_file">
																<div className="box_input_file">
																		<div className="box_input">
																				<input type="file" 
																								id="input_file_add"
																								accept="image/*"
																								onChange={this.props.handleBrowseImage}
																								disabled={textManagerForm?.image_messages.length < 3 ? '' : 'disabled'} />
																				<label htmlFor="input_file_add">
																						{
																								textManagerForm?.currentImageBrowse?.name || 'Choose file...'
																						}    
																				</label>
																		</div>

																		<div className="box_btn">
																				<button 
																						className="btn_add"
																						onClick={this.props.addImageAPICRM}
																						disabled={textManagerForm?.image_messages.length < 3 ? '' : 'disabled'}> Add
																				</button>
																		</div>
																</div>
																<div className="box_list_images">
																		{this.renderListImages(textManagerForm?.image_messages)}
																</div>
														</div>

                        </div>
                    </div>

                    <div className="modal-footer">
                        <button onClick={this.props.toggle}
                            type="button"
                            className="btn btn-outline-dark "
                            data-dismiss="modal"
                            aria-label="Close">
                            <FormattedMessage
                                id="dashboard.Cancel"
                                defaultMessage="Cancel" />
                        </button>
                        <button onClick={this.props.onUpdateTextManager}
                            type="button"
                            className="btn btn-dark btn_add"
                            data-dismiss="modal"
                            aria-label="Close">
                            <FormattedMessage
                                id="dashboard.Save"
                                defaultMessage="Save" />
                        </button>
                    </div>
                </Modal>

            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateTextManagerModal)
