import {
  FETCH_CALL_LIST,
  FETCH_CALL_LIST_FAILED,
  FETCH_CALL_LIST_SUCCEED,
  RESET_CALL_LIST_DATA
} from './../../store/type';

const initialState = {
  data: null,
  loading: false,
  error: false
};

const callList = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CALL_LIST: {
      return {
        ...state,
        loading: true
      }
    }
    case FETCH_CALL_LIST_SUCCEED: {
      return {
        ...state,
        loading: false,
        data: action.payload.data
      }
    }
    case FETCH_CALL_LIST_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    }

    case RESET_CALL_LIST_DATA : {
      return {
        data: null,
        loading: false,
        error: false
      };
    }
    default:
      return state;
  }
}


export default callList;
