import {
    CREATE_SALE_LOTS,
    CREATE_SALE_LOTS_SUCCEED,
    CREATE_SALE_LOTS_FAILED,
    UPDATE_SALE_LOTS,
    UPDATE_SALE_LOTS_SUCCEED,
    UPDATE_SALE_LOTS_FAILED,
    DELETE_SALE_LOTS,
    DELETE_SALE_LOTS_SUCCEED,
    DELETE_SALE_LOTS_FAILED

} from '../actions/actionTypes';


const initialState = {
    data: null,
    loading: false,
    error: false
};

const saleLots = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_SALE_LOTS: {
            return {
                ...state,
                loading: true
            }
        }
        case CREATE_SALE_LOTS_SUCCEED: {
            return {
                ...state,
                loading: false,
                data: action.payload.saleLots
            }
        }
        case CREATE_SALE_LOTS_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        }
        case UPDATE_SALE_LOTS: {
            return {
                ...state,
                loading: true
            }
        }
        case UPDATE_SALE_LOTS_SUCCEED: {
            return {
                ...state,
                loading: false,
                data: action.payload.data
            }
        }
        case UPDATE_SALE_LOTS_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        }
        case DELETE_SALE_LOTS: {
            return {
                ...state,
                loading: true
            }
        }
        case DELETE_SALE_LOTS_SUCCEED: {
            return {
                ...state,
                loading: false,
                data: action.payload.data
            }
        }
        case DELETE_SALE_LOTS_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        }
        default:
            return state;
    }
};

export default saleLots;