import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { authAction } from '../../store/actions/';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from "react-intl";
import Notification from '../../components/Notification/Notification';
import NotificationSystem from 'react-notification-system';
import progressBar from "../../utils/progressBar";
import { Formik, Field } from 'formik';
import * as Yup from 'yup';

const ValidationSchema = Yup.object().shape({
    usr_code: Yup.string()
        .required(<FormattedMessage
            id="dashboard.User.Name.Required"
            defaultMessage='User name is required!' />),
    password: Yup.string()
        .required(<FormattedMessage
            id="dashboard.User.Password.Required"
            defaultMessage='Password is required!' />),
});

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            form: {
                // usr_code: 'primarynet',
                // password: 'primarynet123',
                remember: false,
                usr_code: '',
                password: '',
            },
            notification: {
                allowHTML: false,
            },
        };
    }

    notificationSystem = React.createRef();
    addNotification = (data) => {
        const notification = this.notificationSystem.current;
        notification.addNotification({
            message: data.message,
            title: data.title,
            level: data.level
        });
    };

    deleteAllCookies = () => {
        var cookies = document.cookie.split(";");

        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf("=");
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
    }


    _handleSubmit = (values, action) => {
        progressBar.start();
        this.props.onLogin(values).then(res => {
            if(values.remember) {
                localStorage.setItem('remember', values.usr_code);
            } else {
                localStorage.removeItem('remember')
            }
            // delete cookie
            // this.deleteAllCookies();
            // // delete cache
            // caches.keys().then(function(keyList) {
            //     console.log(keyList,'keyList')
            //     //do something with your keyList
            //     return Promise.all(keyList.map(function(key) {
            //         if(key){
            //             return caches.delete(key);
            //         }
            //     }));
            // });
            // console.log('delete cache and cookie')
            progressBar.done();
        })
            .catch(error => {
                if (error.data) {
                    this.addNotification({
                        title: <FormattedMessage
                            id="dashboard.Error"
                            defaultMessage="Error !" />,
                        message: error.data.errors.message,
                        level: 'error',
                    })

                } else {
                    this.addNotification({
                        title: <FormattedMessage
                            id="dashboard.Error"
                            defaultMessage="error !" />,
                        message: <FormattedMessage
                            id="dashboard.ErrorMessage"
                            defaultMessage="Sorry! Something wen't wrong. Retry!" />,
                        level: 'error',
                    })
                }
                progressBar.done();
            });
    };

    componentDidMount() {
        const remember = localStorage.getItem('remember');
        if(remember) {
            this.setState({
                form: {
                    usr_code: remember,
                    remember: true,
                }
            })
        }
    }

    render() {
        return (
            <Formik
                initialValues={this.state.form}
                enableReinitialize={true}
                validationSchema={ValidationSchema}
                // isInitialValid={false}
                onSubmit={(values, actions) => {
                    this._handleSubmit(values, actions);
                    // let { data } = values;
                    // this.setState({
                    //     ...values,
                    //     errors: {
                    //         ...values.errors,
                    //         atLeast: atLeastError,
                    //     }
                    // }, () => {
                    // })
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    isSubmitting,
                    isValid,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldTouched,
                }) => {
                    return (
                        <Fragment>
                            <NotificationSystem ref={this.notificationSystem} />

                            <FormattedMessage id="login.login"
                                defaultMessage="Login">
                                {message => <Helmet>
                                    <title>{message}</title>
                                </Helmet>}
                            </FormattedMessage>
                            <div className="container-fluid">
                                <div className="row no-gutter">
                                    <div className="d-none d-md-flex col-md-4 col-lg-6 bg-image" />
                                    <div className="col-md-8 col-lg-6 bg-light">
                                        <div className="login d-flex align-items-center py-5">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-md-9 col-lg-8 mx-auto">
                                                        <h3 className="login-heading mb-4 text-center" style={{ fontSize: '30px', fontWeight: 700 }}>아톡비즈 분양CRM</h3>
                                                        <form onSubmit={handleSubmit}>
                                                            <div className="form-label-group">
                                                                <input
                                                                    placeholder="ID" 
                                                                    className="form-control"
                                                                    id="inputID"
                                                                    type="text"
                                                                    name="usr_code"
                                                                    value={values.usr_code}
                                                                    onChange={handleChange}
                                                                    onBlur={() => setFieldTouched('usr_code')}
                                                                />
                                                                {touched.usr_code && errors.usr_code &&
                                                                    <span className="text-danger">{errors.usr_code}</span>
                                                                }

                                                                {/* <input type="id" id="inputID" className="form-control" placeholder="ID" required autofocus /> */}
                                                                <label htmlFor="inputID">ID</label>
                                                            </div>
                                                            <div className="form-label-group">
                                                                <input
                                                                    placeholder="Password"
                                                                    className="form-control"
                                                                    id="inputPassword"
                                                                    type="password"
                                                                    name="password"
                                                                    value={values.password}
                                                                    onChange={handleChange}
                                                                    onBlur={() => setFieldTouched('password')}
                                                                />
                                                                {touched.password && errors.password &&
                                                                    <span className="text-danger">{errors.password}</span>
                                                                }
                                                                
                                                                {/* <input type="password" id="inputPassword" className="form-control" placeholder="Password" required /> */}
                                                                <label htmlFor="inputPassword">Password</label>
                                                            </div>
                                                            <div className="custom-control custom-checkbox mb-3">
                                                                <input
                                                                    className="custom-control-input"
                                                                    type="checkbox"
                                                                    name="remember"
                                                                    id="customCheck1"
                                                                    value={values.remember}
                                                                    onChange={handleChange}
                                                                    checked={values.remember}
                                                                />

                                                                {/* <input type="checkbox" className="custom-control-input" id="customCheck1" /> */}
                                                                <label className="custom-control-label" htmlFor="customCheck1">아이디 저장</label>
                                                            </div>
                                                            <button className="btn btn-lg btn-primary btn-block btn-login text-uppercase font-weight-bold mb-2" type="submit">로그인</button>
                                                            <div className="text-center mt-4">
                                                                <img src="/crm_template/dist/images/icon/login.png" alt="" width="50px" /> (주)이플<br />
                                                                <a href="tel:1877-5271" style={{ fontSize: '26px', fontWeight: 700, fontFamily: '"oswald"' }}>1877-5271</a><br />
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </Fragment>
                    )
                }}
            </Formik>
        )
    }
}

const mapStateToProps = state => {
    return {}
};

const mapDispatchToProps = dispatch => {
    return {
        onLogin: credentials => dispatch(authAction(credentials))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
