import React,{useState} from 'react';
import {Modal} from "reactstrap";
import {formatNumberPhone} from "../../../utils/helpers";

function ModalChangeManager (props) {

    const {
        isOpen,
        toggle,
        recentChangeManagerList,
        managerList,
        submitChange,
				selUsrId,
				selLotsId,
				selCallId,
				setSelUsrId,
    } = props

		//console.log('managerList:',managerList);

		//const [usr_id,setUsrId] = useState(props.selUsrId);

    return(
        <Modal
            isOpen={isOpen}
            toggle={()=>toggle()}
						modalClassName='modal_add_preferences'
						className="modal-dialog modal-dialog-scrollable"
						contentClassName="modal-content"
        >
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">상담자 변경</h5>
                    <button
                        type="button" className="close"
                        onClick={()=>toggle()}
                    >
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="box_add_ppreferences_1">

												<div className="box_group_control">
														<div className="heading_title">
																상담자
														</div>
														<div className="group_control_in group_select">
															<select
																	className="form_control"
																	name="sel_usr_id"
																	value={selUsrId}
																	onChange={(e) => setSelUsrId(e.target.value)}
															>
															{
																managerList.map((item, index) => {
																	return (
																		<option key={index} value={item.usr_id}>
																				{item.usr_name}
																		</option>
																		)
																})
															}
															</select>
															<span className="item_dropdown" />
														</div>
												</div>
                        <div className="box_select_input">
														<table className="table st_table_3">
																<thead>
																<tr><th width="20%">이력일자</th>
																		<th width="80%">이력내용</th>
																</tr></thead>
																<tbody>
															{
																recentChangeManagerList.map((item, index) => {
																	return (
																		<tr
																				key={index}
																		>
                                      <td>{item.edit_time}</td>
                                      <td>{item.edit_descr}</td>
                                    </tr>
																		)
																})
															}
																</tbody>
														</table>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        className="btn btn_light "
                        onClick={()=>toggle()}
                    >
                        닫기
                    </button>
                    <button
                        type="button"
                        className="btn btn_blue"
                        onClick={()=>submitChange(selUsrId)}
                    >
                        입력
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default ModalChangeManager;