import {
    FETCH_CALL_LIST,
    FETCH_CALL_LIST_FAILED,
    FETCH_CALL_LIST_SUCCEED,
    RESET_CALL_LIST_DATA
} from './../type';
import api from './../../utils/api';

export const fetchCalls = type => {
    return {
        type: FETCH_CALL_LIST
    }
};

export const fetchCallsSucceed = data => {
    return {
        type: FETCH_CALL_LIST_SUCCEED,
        payload: {
            data: data
        }
    }
};

export const fetchCallsFailed = error => {
    return {
        type: FETCH_CALL_LIST_FAILED,
        payload: {
            error: error
        }
    }
};

export const fetchCallsAction = (type, query = null) => dispatch => {
    dispatch(fetchCalls());
    if (type === 0) { // get cuoc goi nhan
        return api.telephone.getReceivedCalls(query)
            .then(response => {
                dispatch(fetchCallsSucceed(response.data.data.callList));
                return Promise.resolve(response.data.data);
            })
            .catch(error => {
                dispatch(fetchCallsFailed(error.response.data.errors.message));
                return Promise.reject(error.response)
            });
    } else if (type === 1) { // lay danh sanh cuoc goi di
        return api.telephone.getSendingCalls(query)
            .then(response => {
                dispatch(fetchCallsSucceed(response.data.data.callList));
                return Promise.resolve(response.data.data);
            })
            .catch(error => {
                dispatch(fetchCallsFailed(error.response.data.errors.message));
                return Promise.reject(error.response)
            });


    } else if (type === 2) { // lay danh sanh cuoc goi nho
        return api.telephone.getUnansweredCalls(query)
            .then(response => {
                dispatch(fetchCallsSucceed(response.data.data.callList));
                return Promise.resolve(response.data.data);
            })
            .catch(error => {
                dispatch(fetchCallsFailed(error.response.data.errors.message));
                return Promise.reject(error.response)
            });
    } else if (type === 4) { // lay danh sach cuoc goi nho (vang mat)
        return api.telephone.getAbsentCalls(query)
            .then(response => {
                dispatch(fetchCallsSucceed(response.data.data.callList));
                return Promise.resolve(response.data.data);
            })
            .catch(error => {
                dispatch(fetchCallsFailed(error.response.data.errors.message));
                return Promise.reject(error.response)
            });
    }
}

export const resetCallListAction = () => dispatch => {
    dispatch({
        type : RESET_CALL_LIST_DATA
    })
}