import React, {Fragment} from 'react'
import Review from '../../assets/images/documentV2/statistics/review.png';
import DailyReport from '../../assets/images/documentV2/statistics/daily-report.png';
import Analysis from '../../assets/images/documentV2/statistics/analysis.png';
import Graph from '../../assets/images/documentV2/statistics/graph.png';




const DailyReportDoc = props => {

    return (
        <Fragment>
            <div className="row m-0">
                <div className="col-md-12 documentation-user-registration">
                    <h2 className="mt-3 mb-4">통계</h2><br />
                    <div className="main-user-registration">
                        <p><b>통계</b></p> <br />
                        <div className="row">
                            <div className="col-md-8">
                                <img src={Review} width='100%' />
                            </div>
                            <div className="col-md-4 pl-4 pr-0">
                                <p>3개의 리포트로 구성</p>
                                <p>(1) 업무일지</p>
                                <p>(2) 상담분석</p>
                                <p>(3) 그래프</p>
                            </div>
                        </div>
                        <p className="mt-5"><b>업무일지</b></p><br />
                        <div className="row ">
                            <div className="col-md-8">
                                <img src={DailyReport} width='100%'/>
                            </div>
                            <div className="col-md-4 pl-4 pr-0">
                                <p>(1) 일자별 검색</p>
                                <p>(2) 통계 별 조건 선택</p>
                                <p>(3) 선택한 조건 별로 "업무일지" 표시</p>
                                <p>(4) 엑셀파일로 출력 가능</p>
                            </div>
                        </div>

                        <p className="mt-5"><b>상담분석</b></p><br />
                        <div className="row ">
                            <div className="col-md-8">
                                <img src={Analysis} width='100%'/>
                            </div>
                            <div className="col-md-4 pl-4 pr-0">
                                <p>(1) 일자별 검색</p>
                                <p>(2) 통계 별 조건 선택</p>
                                <p>(3) 선택한 조건 별로 "업무일지" 표시</p>
                                <p>(4) 엑셀파일로 출력 가능</p>
                            </div>
                        </div>

                        <p className="mt-5"><b>그래프</b></p><br />
                        <div className="row ">
                            <div className="col-md-8">
                                <img src={Graph} width='100%'/>
                            </div>
                            <div className="col-md-4 pl-4 pr-0">
                                <p>(1) 기간 , 유형 선택 </p>
                                <p>(2) 그에 따른 세부항목 별 조건 선택</p>
                                <p>(3) 선택한 조건 별로 선 그래프 및  원 그래프표시</p>
                                <p>(4) 인쇄 및 엑셀 출력</p>
                            </div>
                        </div>

                        {/*<div className="row mt-4">*/}
                            {/*<div className="col-md-8">*/}
                                {/*<p> <b>Tương tự biểu đồ đường trong màn hình Biểu đồ phân tích tròn có các chức năng:</b></p>*/}
                                {/*<img src={GraphPie}/>*/}
                            {/*</div>*/}
                            {/*<div className="col-md-4 mt-4 pt-3">*/}
                                {/*<p>(1) Khung này là vị trí để người dùng chọn thời gian và các mục nội để tìm kiếm.*/}
                                    {/*Thời gian bắt đầu phải được chọn trước thời gian kết thúc. Sau khi người dùng chọn dữ liệu ở mục chọn.*/}
                                    {/*Một danh sách các mục con tương ứng sẽ xuất hiện. Người dùng chọn các mục nội dung cần tìm kiếm và bấm nút tìm kiếm*/}
                                {/*</p>*/}
                                {/*<p>(2) Kết quả tìm kiếm tương ứng sẽ được hiện thị trên biểu đồ</p>*/}
                            {/*</div>*/}
                        {/*</div>*/}


                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default DailyReportDoc;