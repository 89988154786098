import React from 'react';
import moment from 'moment';
import {Tooltip } from "reactstrap";




class ListCall extends React.Component {

    constructor(props) {
        super(props);
        this.state  = {
            tooltipCallback : {}
        }
    }

    onClickIdCall = (itemCall) => {
        this.props.onClickIdCall(itemCall);// props get id
    }

    tooltipCallbackToggle = call_id =>{
        this.setState({
            tooltipCallback : {
                ...this.state.tooltipCallback,
                ['tooltipCallback'+call_id] : !this.state.tooltipCallback['tooltipCallback'+call_id]
            }
        })
    }

    render() {

        let {
            callList,
            call,
            role,
            isDeleteList,
            isDelete,
            callTabReceived,
            callTabSendingCall,
            callTab, //// 1 : tab danh sách cuộc gọi đến, 2 : tab danh sách cuộc gọi di
            roleCheckDelete,
            search_key, // props nay check search input
        } = this.props;

        let {tooltipCallback} = this.state;

        let openCheckbox = true;

        if(callTab == 1) {
            if(callTabReceived == 2) {
                openCheckbox = false;
            }

        }

        return (
            <div className="custom_table nover">
                {role ? <div className="box_move_top">
                    <input
                        type="checkbox"
                        id="check_remove_call"
                        checked={isDelete}
                        onChange={isDeleteList}
                    />
                    <label className="check_remove_call" htmlFor="check_remove_call">삭제콜</label>
                </div> : '' }

                <table className="table st_table_1">
                    <thead>
                    <tr>
                        <th width="25%">이름</th>
                        <th width="25%">전화번호</th>
                        <th width="50%">일자</th>
                    </tr>
                    </thead>
                    <tbody>
                    {callList.length > 0 ? callList.map((item, index) => {
                        let call_date_update =  ''; // check thieu sot hay ngay truoc do
                        if(item.call_date_update == 0){
                            call_date_update = '전일';//'ngay truoc do';
                        }else if (item.call_date_update == 1){
                            call_date_update = '누락';//'thieu sot';
                        }

                        return (
                            <tr
                                key={index}
                                onClick={() => this.onClickIdCall(item)}
                                className={call.call_id == item.call_id ? 'active' : ''}
                            >
                                <td> {item.call_cust_name} </td>
                                <td>{item.call_tel}{item.count_call_tel ? item.count_call_tel > 1 ? `(${item.count_call_tel})` : '' : ''} </td>
                                <td>
                                    <div className="check_center">
                                        <span className="left">{call_date_update ? call_date_update : item.call_date}</span>
                                        {
                                            role ? item.is_deleted == 1 ? <i className="fas col_check fa-check-square"/> : '' : (
                                                ''
                                            )
                                        }
                                        {(callTabReceived == 2 || callTabSendingCall == 2) && item.callback == 1  ?
                                            <React.Fragment>
                                                &ensp;
                                                <a className="box_tooltip"
                                                   id={"Tooltip-"+item.call_id}
                                                >   
                                                    <i className="icontooltip">
                                                        <Tooltip
                                                            placement={'bottom'}
                                                            isOpen={tooltipCallback['tooltipCallback'+item.call_id]}
                                                            target={"Tooltip-"+item.call_id}
                                                            toggle={()=>this.tooltipCallbackToggle(item.call_id)}
                                                            popperClassName={'font-size-16'}
                                                        >
                                                            {item.user ? item.user.usr_name : ""}
                                                        </Tooltip>
                                                    </i>
                                                </a>
                                            </React.Fragment>

                                            :''}

                                    </div>
                                </td>
                            </tr>
                        )
                    }) :

                        <tr className={'text-center'}>
                            <td colSpan={3} >{search_key ? '결과값 없음' : 'No Data'}</td>
                        </tr>
                    }
                    </tbody>
                </table>
            </div>
        )
    }
}

export default ListCall;
