import React from 'react';
import {Nav, NavItem, NavLink} from "reactstrap";
import WindowOpener from './../../../containers/TelephoneCounselling/windown-opener'
function NavTabSendingCall (props) {
    const {
        callTabSendingCall,
        changeCallSendingCall,
        openPopupTelephone
    } = props;

    function sonResponse (err, res) {
        // if (err) {
        //     this.setState({ message: res })
        // }
        // this.setState({ message: res })
    }


    return (
        <Nav tabs className={'nav_tabs child'}>
            <NavItem className={'nav_item'}>
                <NavLink
                    className={callTabSendingCall == 2 ? 'active nav_link' : 'nav_link'}
                    onClick={()=>changeCallSendingCall(2)} // cuoc goi den nhỡ list all day
                >
                    대기
                </NavLink>
            </NavItem>
            <NavItem className={'nav_item'}>
                <NavLink
                    className={callTabSendingCall == 4 ? 'active nav_link' : 'nav_link'}
                    onClick={()=>changeCallSendingCall(4)} // cuoc goi nhỡ tư vấn viên điện cho khách hàng
                >
                    부재콜
                </NavLink>
            </NavItem>

            <NavItem className={'nav_item'}>
                <NavLink
                    className={callTabSendingCall == 1 ? 'active nav_link' : 'nav_link'}
                    onClick={()=>changeCallSendingCall(1)} // cuộc gọi tư vấn viên điện khách hàng nhận điện thoại
                >
                    통화콜
                </NavLink>
            </NavItem>


            <NavItem className={'nav_item'}>
                <WindowOpener
                    url={props.sceneLotsId ? `/popup?access=no&lots_id=${props.sceneLotsId}` : '/popup?access=no'}
                    openPopupTelephone={openPopupTelephone}
                    bridge={sonResponse}
               />
            </NavItem>

        </Nav>
    )
}

export default NavTabSendingCall;