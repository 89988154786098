import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Helmet } from 'react-helmet';
import { NavLink, Link } from "react-router-dom";
import * as routerName from "../../../utils/routerName";
import moment from "moment/moment";
import queryString from 'query-string';
import DatePicker from 'react-datepicker';
import NotificationSystem from 'react-notification-system';
import progressBar from '../../../utils/progressBar';
import * as helpers from './../../../utils/helpers';
import ReactToPrint from "react-to-print";
import api from "../../../utils/api";
import {LineChart, PieChart, Line, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Pie, ResponsiveContainer } from 'recharts';
import {
    unmountCallListAction,
    unmountInfoMastAction,
    fetchInfoMastsAction,
    unmountInfoDetailAction,
} from '../../../store/actions';
import {CHANGE_SCENE_COMPANY, CHANGE_SCENE_LOT} from "../../../store/type";
import isEmpty from 'lodash/isEmpty';

class ContentPrint extends React.Component {
    noData = () => {
        return (
            <div style={{
                display: 'flex',
                width: '100%',
                minHeight: '200px',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                No  Data
            </div>
        )
    }

    render() {
        // console.log(this.props.graphPie, 'pieeee');
        return (
            <Fragment>
                <div className='content-print-t100-50'>
                    <div className="box_report_graph">
                        {this.props.graphLine ? this.props.graphLine : this.noData()}
                    </div>
                    <div className="box_report_graph">
                        {this.props.graphPie ? this.props.graphPie : this.noData()}
                    </div>
                </div>
            </Fragment>
        )
    }
};

class Graph extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cond: {
                startDate: moment().subtract(1, "months"),
                endDate: moment(),     
                // startDate: moment('2021-01-01'),
                // endDate: moment('2021-01-07'),     
                // export_excel: '',
            },
            infoMasts: [],
            infoMastSelected: null,
            // infoDetails: null,
            infoDetailsSelected: [],

            graphLineData: null,
            graphPieData: null,
            lineColorList: [],
            checkAll: false,

            notification: {
                allowHTML: false,
            },
            graphIsNull: false,
						viewHideOption: false           
        }
    }

    notificationSystem = React.createRef();
    addNotification = (data) => {
        const notification = this.notificationSystem.current;
        notification.addNotification({
            message: data.message,
            title: data.title,
            level: data.level,
        });
    };

    componentDidMount() {
        progressBar.start();
        this.getData(this.props.sceneCompanyId);
    }

    // UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    //     if(nextProps.isChangeScene && nextProps.typeOfChange === CHANGE_SCENE_COMPANY) {
    //         this.resetCondition();
    //         this.getData(nextProps.sceneCompanyId)
    //     }
    //     if(nextProps.isChangeScene && nextProps.typeOfChange === CHANGE_SCENE_LOT && !isEmpty(this.state.infoDetailsSelected)) {
    //         this.getInfoChartHandler(nextProps.sceneCompanyId, nextProps.sceneLostId);
    //     }
    // }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.isChangeScene && nextProps.typeOfChange === CHANGE_SCENE_COMPANY) {
            this.resetCondition();
            this.getData(nextProps.sceneCompanyId)
            this.setState({
                graphLineData: null,
                graphPieData: null,
            })
        }
        if(nextProps.isChangeScene && nextProps.typeOfChange === CHANGE_SCENE_LOT) {
            this.setState({
                graphLineData: null,
                graphPieData: null,
            })
        }
    }

    resetCondition = () => {
        this.setState({
            infoMasts: [],
            infoMastSelected: null,
            // infoDetails: null,
            infoDetailsSelected: [],
            checkAll: false,
        })
    }

    handleViewHideOption = (value) => {
			console.log('value:', value);
			this.setState({
					...this.state,
					viewHideOption: value == true ? 1 : 0,
			}, () => {
					console.log(this.state.viewHideOption, 'viewHideOption');
			});

		}
	
		getData = companyId => {
        this.props.onFetchInfoMast(companyId).then(response => {
            progressBar.done();
            this.setState({
                infoMasts: response
            })
        }).catch(error => {
            this.setState({
                ...this.state,
                notification: {
                    allowHTML: false
                }
            }, () => {
                this.addNotification({
                    title: <FormattedMessage
                        id="dashboard.Error"
                        defaultMessage="Error!"/>,
                    message: <FormattedMessage
                        id="dashboard.ErrorMessage"
                        defaultMessage="Sorry! Something wen't wrong. Retry!"/>,
                    level: 'error',
                })
            });
            progressBar.done();
        });
    }



    // componentDidMount() {
    //     progressBar.start();
    //     this.props.onFetchInfoMast().then(response => {          
    //         progressBar.done();
    //         if (response && Array.isArray(response)) {
    //             let infoMasts = response;
    //             let infoMastSelected= infoMasts?.[0];
    //             let infoDetailsSelected = infoMastSelected?.info_detail.map((item, index) => {
    //                 return +item.m_dtl_id
    //             });
    //             this.setState({
    //                 ...this.state,
    //                 infoMasts,
    //                 infoMastSelected,
    //                 infoDetailsSelected,
    //                 checkAll: true
    //             }, () => {
    //                 // console.log(this.state.infoMast, 'state_af');
    //                 this.getInfoChartHandler();
    //             })
    //         } 
    //     }).catch(error => {
    //         this.setState({
    //             ...this.state,
    //             notification: {
    //                 allowHTML: false
    //             }              
    //         }, () => {
    //             this.addNotification({
    //                 title: <FormattedMessage
    //                         id="dashboard.Error"
    //                         defaultMessage="Error!"/>,
    //                 message: <FormattedMessage
    //                         id="dashboard.ErrorMessage"
    //                         defaultMessage="Sorry! Something wen't wrong. Retry!"/>,
    //                 level: 'error',
    //             })
    //         });
    //         progressBar.done();
    //     });
    // }

    componentWillUnmount() {
        this.props.onUnmountCallList();
        this.props.onUnmountInfoMast();
        this.props.onUnmountInfoDetail();
    }

    changeDateHandler = ({startDate, endDate}) => {
        startDate = startDate || this.state.cond.startDate;
        endDate = endDate || this.state.cond.endDate;

        if (startDate !== null && startDate.isAfter(endDate)) {
            endDate = startDate
        }

        this.setState({
            ...this.state,
            cond: {
                ...this.state.cond,
                startDate,
                endDate,
            },
            graphLineData: null,
            graphPieData: null,
        })
    };

    changeStartDateHandler = (startDate) => {
        this.changeDateHandler({ startDate });
    };

    changeEndDateHandler = (endDate) => {
        this.changeDateHandler({ endDate })
    };

    // handleClickOptions = (event) => {
    //     let { infoDetailsSelected } = this.state;
    //     let { target } = event;
    //     if (target.checked) {
    //         if (!this.state.infoDetailsSelected.includes(+target.value) ) {
    //             infoDetailsSelected.push(+target.value)            
    //         }
    //     } else {
    //         infoDetailsSelected = helpers.removeItemInArray(infoDetailsSelected, +target.value)
    //     }

    //     this.setState({
    //         ...this.state,
    //         infoDetailsSelected           
    //     }, () => {
    //         console.log(this.state, 'state_af_click_optioon');
    //     });
    // }

    handleClickOptions = (event) => {
        let { target } = event;
        let { infoDetailsSelected, infoMastSelected } = this.state; 

        if (target.checked) {
            if (target.name === 'all') {
                infoDetailsSelected = infoMastSelected?.info_detail_with_counter.map((item, index) => {
                    return +item.m_dtl_id
                });
            } else {
                infoDetailsSelected.push(+target.value);
            }
        } else {
            if (target.name === 'all') {
                infoDetailsSelected = [];
            } else {
                //find and remove item from columnOptions
                infoDetailsSelected = helpers.removeItemInArray(infoDetailsSelected, +target.value);
            }
        }

        this.setState({
            // ...this.state,     
            infoDetailsSelected,     
            checkAll: target.name === 'all' && target.checked ? true : false,
        }, () => {
            // console.log(this.state.infoDetailsSelected, 'infoDetailsSelected_af');
        });
    }

    selectInfoMastHandler = (event) => {
        // infoMasts: [],
        // infoMastSelected: null,
        // infoDetails: null,
        // infoDetailsSelected: [],
				console.log('event:', event);
        let { value } = event.target;
				console.log('value:', value);
        if (value) {
            let infoMastSelected = JSON.parse(value);
            this.setState({
                ...this.state,
                infoMastSelected,
                infoDetailsSelected: [],
                checkAll: false,
                graphLineData: null,
                graphPieData: null,
            }, () => {
                console.log(this.state.infoMastSelected, 'infoMastSelected');
            })
        } else {
            this.setState({
                ...this.state,
                infoMastSelected: null,
                infoDetailsSelected: [],
                checkAll: false,
                graphLineData: null,
                graphPieData: null,
            })
        }
    };
    
    renderCustomizedLabel = ({cx, cy, midAngle, innerRadius, outerRadius, percent, index}) => {
        let addNumber = index%3;
        const RADIAN = Math.PI / 180;
        // const radius = innerRadius + (outerRadius - innerRadius) * helpers.randomInRange(0.4, 0.8);
        const radius = innerRadius + (outerRadius - innerRadius) * (0.5 + addNumber/10);
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    exportExcelHandler = () => {
        let scene = {};
        if(this.props.sceneCompanyId) {
            scene['company_id'] = this.props.sceneCompanyId
        }
        if(this.props.sceneLostId) {
            scene['lots_id'] = this.props.sceneLostId
        }
        let params = {
            ...{...this.state.cond, ...scene},
            infoMast: this.state.infoMastSelected?.m_id,
            infoDetails: this.state.infoDetailsSelected,
            isExport: true,
            token:localStorage.getItem('token')
        };
        let query = queryString.stringify(params, {arrayFormat: 'bracket'});     
        let url = process.env.NODE_ENV === 'development' ?
            process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD;

        url = url + `/statistics/allGraph?${query}`;
        window.location.href = url;
        progressBar.done();
            

        // api.statistics.getAllGraph(query).then(res => {
        //     let url = process.env.NODE_ENV === 'development' ?
        //         process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD;

        //     url = url + `/statistics/allGraph?${query}`;
        //     window.location.href = url;
        //     progressBar.done();
        // }).catch(error => {
        //     this.setState({
        //         ...this.state,
        //         notification: {
        //             allowHTML: false
        //         }              
        //     }, () => {
        //         this.addNotification({
        //             title: <FormattedMessage
        //                     id="dashboard.Error"
        //                     defaultMessage="Error!"/>,
        //             message: <FormattedMessage
        //                     id="dashboard.ErrorMessage"
        //                     defaultMessage="Sorry! Something wen't wrong. Retry!"/>,
        //             level: 'error',
        //         })
        //     });
        //     progressBar.done();
        // })
    };

    afFunc = () => {
        console.log('af_func');
    }

    getInfoChartHandler = (companyId, lotsId) => {
        progressBar.start();
        let scene = {};
        if(companyId) {
            scene['company_id'] = companyId
        }
        if(lotsId) {
            scene['lots_id'] = lotsId
        }
        let params = {
            ...{...this.state.cond, ...scene},
            infoMast: this.state.infoMastSelected?.m_id,
            infoDetails: this.state.infoDetailsSelected
        };
        let query = queryString.stringify(params, {arrayFormat: 'bracket'});
        api.statistics.getAllGraph(query).then(res => {
            let lineColorList = [];
            this.state.infoDetailsSelected.map((item, key) => {
                lineColorList.push(helpers.arrayColor[key]);
            });
            this.setState({
                ...this.state,
                lineColorList,
                graphLineData: res?.data?.data?.graphLine || null,
                graphPieData: res?.data?.data?.graphPie|| null,
                graphIsNull: res?.data?.data?.is_null || false,
            }, () => {
                console.log(this.state.infoMast, 'infoMast_1');
            });
            progressBar.done();
        }).catch(err => {
            this.setState({
                ...this.state,
                notification: {
                    allowHTML: false
                }              
            }, () => {
                this.addNotification({
                    title: <FormattedMessage
                            id="dashboard.Error"
                            defaultMessage="Error!"/>,
                    message: <FormattedMessage
                            id="dashboard.ErrorMessage"
                            defaultMessage="Sorry! Something wen't wrong. Retry!"/>,
                    level: 'error',
                })
            });
            progressBar.done();
        });
    };

    render() {
        const {
            infoMasts,
            infoMastSelected,
            infoDetailsSelected,
            cond,
            graphLineData,
            graphPieData,
            lineColorList,
            checkAll,
            graphIsNull,
						viewHideOption
        } = this.state;
        console.log(graphLineData, graphPieData, "data_chart");

        let graphLine = '';
        if (graphLineData && !graphIsNull) {
            graphLine = (
                <ResponsiveContainer width="95%" height={400}>
                    <LineChart 
                        data={graphLineData}
                        margin={{top: 30, bottom: 15}}
                    >
                        <CartesianGrid strokeDasharray="3 3"/>
                        <XAxis dataKey="time"
                        // padding={{left: 30, right: 30}}
                        />
                        <YAxis/>
                        <Tooltip/>
                        <Legend/>
                        {
                            // loop all Key info_detail 
                            graphLineData && Object.keys(graphLineData[0]).map((key, index) => {
                                if (key !== 'time') {
                                    return (<Line key={key}
                                                type="monotone"
                                                dataKey={key}
                                                activeDot={{r: 8}}
                                                stroke={lineColorList[index - 1]}
                                    />)
                                }
                                return null
                            })
                        }
                    </LineChart>
                </ResponsiveContainer>
            );
        } else {
            graphLine = '';
        }

        
        let graphPie = '';
        if (graphPieData && !graphIsNull) {
            const graphPieDataFilter = graphPieData.filter((item, index) => {
                return item.total > 0
            })
            graphPie = (
                <ResponsiveContainer width="95%" height={400}>
                    <PieChart
                        margin={{top: 10, bottom:20}}
                    >
                        <Pie
                            data={graphPieDataFilter}
                            // cx={400}
                            // cy={120}
                            cx="50%" cy="50%"
                            labelLine={false}
                            label={this.renderCustomizedLabel}
                            outerRadius={160}
                            fill="#8884d8"
                            dataKey={'total'}
                        >
                             
                        {
                            // loop used to custom color one by one info_detail (able ignore)
                            graphPieDataFilter.map((entry, index) => {
                                if (entry.name === "Other") {
                                    return <Cell key={index} fill={'#FF8042'}/>
                                } else {
                                    return <Cell key={index} fill={lineColorList[index]}/>
                                }
                            })
                        }
                        </Pie>
                        <Legend iconType='circle'
                                iconSize={15}
                        />
                        <Tooltip/>
                    </PieChart>
                </ResponsiveContainer>
            );
        } else {
            graphPie = '';
        }

        return (
            <Fragment>
                <NotificationSystem ref={this.notificationSystem} allowHTML={this.state.notification.allowHTML} />

                <FormattedMessage id="dashboard.Graph"
                    defaultMessage="Graph">
                    {message => <Helmet>
                        <title>{message}</title>
                    </Helmet>}
                </FormattedMessage>

                <div className="main_top_bar static_bar">
                    <div className="box_sum_col_top">
                        <div className="box_left">
                            <ul className="nav nav_tabs nav-tabs" id="myTab" role="tablist">
                                <li className="nav_item nav-item">
                                    <NavLink
                                        to={`/${routerName.DASHBOARD}/${routerName.STATISTICS}/${routerName.DAILY_REPORT}`}
                                        className="nav_link nav-link"
                                        activeClassName="active">
                                        <FormattedMessage
                                            id="dashboard.DailyReport"
                                            defaultMessage="Daily report" />
                                    </NavLink>
                                </li>                               
                                <li className="nav_item nav-item">
                                    <NavLink
                                        to={`/${routerName.DASHBOARD}/${routerName.STATISTICS}/${routerName.ANALYSIS}`}
                                        className="nav_link nav-link"
                                        activeClassName="active">
                                        <FormattedMessage
                                            id="dashboard.Analysis"
                                            defaultMessage="Analysis" />
                                    </NavLink>
                                </li>
                                <li className="nav_item nav-item">
                                    <NavLink
                                        to={`/${routerName.DASHBOARD}/${routerName.STATISTICS}/${routerName.GRAPH}`}
                                        className="nav_link nav-link"
                                        activeClassName="active">
                                        <FormattedMessage
                                            id="dashboard.Graph"
                                            defaultMessage="Graph" />
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                        <div className="box_center tab-content">
                            <div className="main_control_time_select justify_content">
                                <div className="main_control_time_select">                                   
                                    <div className="main_time main_box">
                                        <ul className="list_time">
                                            <li>
                                                <FormattedMessage id="dashboard.Period" defaultMessage="Period" />
                                            </li>
                                            <li>
                                                <div className="box_control_date">
                                                    <span className="icon_date" />
                                                    <DatePicker
                                                        dateFormat="YYYY/MM/DD"
                                                        selected={cond.startDate}
                                                        selectsStart
                                                        disable
                                                        id="FromDatePicker"
                                                        name="FromDatePicker"
                                                        className="form-control"
                                                        startDate={cond.startDate}
                                                        endDate={cond.endDate}
                                                        onChange={this.changeStartDateHandler}
                                                    />
                                                </div>
                                            </li>
                                            <li>~</li>
                                            <li>
                                                <div className="box_control_date">
                                                    <span className="icon_date" />
                                                    <DatePicker
                                                        dateFormat="YYYY/MM/DD"
                                                        selected={cond.endDate}
                                                        selectsStart
                                                        disable
                                                        id="FromDatePicker"
                                                        name="FromDatePicker"
                                                        className="form-control"
                                                        startDate={cond.startDate}
                                                        endDate={cond.endDate}
                                                        onChange={this.changeEndDateHandler}
                                                    />
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="main_select main_box">
                                        <div className="main_label">
                                            <FormattedMessage id="dashboard.Items" defaultMessage="Items" />
                                        </div>
                                        <div className="custom_select">
                                            <select className="form_control" onChange={this.selectInfoMastHandler} value={infoMastSelected ? JSON.stringify(infoMastSelected) : ''}>
                                                <option value=''>
                                                    {this.props.intl.formatMessage({id: 'dashboard.SelectItem',defaultMessage: 'SelectItem'})}
                                                </option>
                                                {
                                                    infoMasts?.map((item, index) => {
                                                        return viewHideOption || item.m_show == 1 ? (
                                                            <option key={index} value={JSON.stringify(item)}>{item.m_name}</option>
                                                        ) : ''
                                                    })
                                                }
                                            </select>
                                            <span className="item_dropdown" />
                                        </div>
                                    </div>
                                </div>
                                <div className="main_select main_box">
																		<div className="custom_select">
																				<input type="checkbox" 
																						name="view_hide_item"
																						type="checkbox"
																						onChange={(e) =>this.handleViewHideOption(e.target.checked)}
																						checked = {viewHideOption ? true : false}
																						id="check0002"
																				/>
																				<label className="control_label_checkbox" htmlFor="check0002">숨김처리 항목 표시</label>
																		</div>
                                </div>

                                <div className="box_search">
                                    <button className="btn_fillter"
                                            disabled={infoDetailsSelected?.length < 1}
                                            onClick={(e) => this.getInfoChartHandler(this.props.sceneCompanyId, this.props.sceneLostId, e)}
                                    >
                                        <FormattedMessage
                                            id="dashboard.Search"
                                            defaultMessage="Search"
                                        />
                                    </button>
                                </div>  
                            </div>  
                        </div>
                    </div>
                </div>

                <div className="main_content">
                    <div className="content_tabs_main content_static_tabs tab-content">
                        <div className="tabs_static_item " id="static2">
                            <div className="box_content_static">
                                <div className="left_col">
                                    <div className="heading title_left">
                                        <h3>
                                            <FormattedMessage id="dashboard.SubItems" defaultMessage="SubItems"/>
                                        </h3>
                                    </div>
                                    {
                                        infoMastSelected && (
                                            <div className="box_check_all">
                                                <div className="group_checkbox">
                                                <input
                                                    name="all"     
                                                    type="checkbox"
                                                    onChange={this.handleClickOptions}
                                                    checked={ checkAll }
                                                    id="check0001"
                                                />
                                                <label className="control_label_checkbox" htmlFor="check0001">
                                                    <FormattedMessage id="dashboard.CheckAll" defaultMessage="CheckAll" />
                                                </label>
                                                </div>
                                            </div>
                                        )
                                    }
                                    
                                    <ul className="list_check">
                                        {
                                            infoMastSelected?.info_detail_with_counter?.map((option, index) => {
                                                let isChecked = infoDetailsSelected.includes(option.m_dtl_id) ? true : false;
                                                return (
                                                    <li key={index}>
                                                          <div className="group_checkbox">
                                                            <input
                                                                name={option.m_dtl_id}
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                onChange={this.handleClickOptions}
                                                                checked={isChecked}
                                                                value={option.m_dtl_id}
                                                                id={option.m_dtl_id}
                                                            />
                                                            <label className="control_label_checkbox" htmlFor={option.m_dtl_id}>
                                                                {option.m_dtl_name}
                                                            </label>
                                                        </div>
                                                    </li>
                                                )
                                            })
                                        }
                                 
                                    </ul>
                                </div>
                                <div className="content_col">
                                    <div className="row_table_custom">
                                        <div className="heading flex_justcontent">
                                            <div className="txt_heading">Report Graph</div>
                                            <div className="box_list_btn">
                                                <ReactToPrint
                                                    trigger={() =>                                                     
                                                        <button 
                                                            disabled={(graphLineData && !graphIsNull) ? false : true}
                                                            className="btn_pri" type="button">
                                                            <FormattedMessage id="dashboard.Print" defaultMessage="Print"/>
                                                        </button>
                                                    }
                                                    content={() => this.componentRef}
                                                    onAfterPrint={this.afFunc}
                                                />
                                     
                                                <button 
                                                    className="btn_pri"
                                                    type="button"
                                                    disabled={(graphLineData && !graphIsNull) ? false : true}
                                                    onClick={this.exportExcelHandler}>
                                                    <FormattedMessage id="dashboard.ExcelDownload" defaultMessage="ExcelDownload"/>
                                                </button>
                                            </div>
                                        </div>

                                        <div className="content report_graph">
                                            <ContentPrint
                                                graphLine={graphLine}
                                                graphPie={graphPie}
                                                ref={el => (this.componentRef = el)}
                                            />                                       
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        graph2: state.calls,
        isChangeScene: state.sceneSelect.isChangeScene,
        sceneCompanyId: state.sceneSelect.company_id,
        sceneLostId: state.sceneSelect.lots_id,
        typeOfChange: state.sceneSelect.typeOfChange,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onUnmountCallList: () => dispatch(unmountCallListAction()),
        onUnmountInfoMast: () => dispatch(unmountInfoMastAction()),
        onUnmountInfoDetail: () => dispatch(unmountInfoDetailAction()),
        onFetchInfoMast: (id) => dispatch(fetchInfoMastsAction(id)),
    }
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Graph));
