import React from 'react';

function FunctionButtonCenter ({
                                   resetFormCall,
                                   onChangeReceiveCallStatus, // thay doi thu cong hay auto
                                   receiveCallStatus, // props che do thu cong hay auto
                                   onChangeDateSettingStatus, //  props thay doi che do cai dat du lieu 1 ngay hoac tat ca cac ngay
                                   dateSettingStatus, // props che do cai dat du lieu 1 ngay hoac tat ca cac ngay
                                   toggleCallModalDelete, //modal delete call
                                   call,
                                   toggleReportModal,
                                   callTabReceived,
                                   callTabSendingCall,
                                   callTab,
                                   role

}) {
    let openCheckbox = true;
    if(callTab == 1) {
        if(callTabReceived == 2) {
            openCheckbox = false;
        }

    }

    return(
        <ul className="list_nav">
            <li className="item_nav">
                <a href="#">
                    <div
                        className="show"
                        onClick={()=>toggleReportModal()}
                    >
                        상담현황
                    </div>
                    <div className="hide"><i className="fas fa-book-open" /></div>
                </a>
            </li>
            <li className="item_nav">
                <a
                    href={"#"}
                    onClick={()=>{
                        resetFormCall(); // reset form
                    }}
                >
                    <div
                        className="show"
                    >
                        화면 정리
                    </div>
                    <div className="hide"><i className="fas fa-sync-alt" /></div>
                </a>
            </li>
            {role && openCheckbox ? <li className="item_nav">
                <a
                    href="#"
                    onClick={()=>{
                        if(call.call_id) toggleCallModalDelete()
                    }}
                >
                    <div className="show">삭제</div>
                    <div className="hide"><i className="far fa-trash-alt" /></div>
                </a>
            </li> : '' }


            <li className="item_nav">
                <a className="btn_dropdown nav-link" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
                    <div className="show">환경설정</div>
                    <div className="hide"><i className="fas fa-cogs" /></div>
                </a>
                <div className="dropdown-menu dropdown_menu">
                    <div className="box_group_top">
                        <div className="heading_lable">전화받기</div>
                        <div className="group_option">
                            <div className="item_option">
                                <input
                                    name="cooa"
                                    type="radio"
                                    id="c001"
                                    value={'auto'}
                                    onChange={event => onChangeReceiveCallStatus(event.target.value)}
                                    checked={receiveCallStatus == 'auto' ? true  : false}
                                />
                                <label htmlFor="c001">자동</label>
                            </div>
                            <div className="item_option">
                                <input
                                    name="cooa"
                                    type="radio"
                                    id="c002"
                                    value={'manual'}
                                    onChange={event => onChangeReceiveCallStatus(event.target.value)}
                                    checked={receiveCallStatus == 'manual' ? true  : false}
                                />
                                <label htmlFor="c002">수동</label>
                            </div>
                        </div>
                    </div>
                    <div className="dropdown-divider" />
                    <div className="box_group_top">
                        <div className="heading_lable">날짜설정</div>
                        <div className="group_option">
                            <div className="item_option">
                                <input
                                    name="date"
                                    type="radio"
                                    id="c003"
                                    value={'all'}
                                    onChange={(event) => onChangeDateSettingStatus(event.target.value)}
                                    checked={dateSettingStatus == 'all' ? true  : false}
                                />
                                <label htmlFor="c003">All</label>
                            </div>
                            <div className="item_option">
                                <input name="date"
                                       type="radio"
                                       id="c004"
                                       value={'day'}
                                       onChange={(event) => onChangeDateSettingStatus(event.target.value)}
                                       checked={dateSettingStatus == 'day' ? true  : false}

                                />
                                <label htmlFor="c004">Date</label>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    )
}

export default FunctionButtonCenter;