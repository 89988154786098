import React, {Fragment} from 'react'

import {FormattedMessage, injectIntl} from "react-intl";
import DatePicker from 'react-datepicker';

import {Card, Button} from 'reactstrap';
import moment from "moment";
import {
    EditingState,
    DataTypeProvider,
    PagingState,
    CustomPaging, IntegratedPaging, IntegratedSorting,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    Table,
    TableHeaderRow,
    TableEditRow,
    TableEditColumn,
    PagingPanel,
} from '@devexpress/dx-react-grid-bootstrap4';
import Select from 'react-select';
import DeleteConfirmModal from '../../../components/DeleteConfirmModal/DeleteConfirmModal'
import CommandButton from './CommandButton'
import Notification from '../../../components/Notification/Notification';


const getRowId = row => row.id;


const commandComponentProps = {
    delete: {
        icon: 'trash',
        hint: 'Delete row',
        color: 'text-danger',
        button: 'btn btn-danger btn-sm'
    },
};


class RealEstateAgent extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            row:[],
            totalCount: 0,
            currentPage: 0,
            pageSize: 0,
            pageSizes: [5, 10, 15],
            columns: [],
            places : [],
            currentPlacement : null,
            buttonColumns : ['button'],
            placementColumns : ['place_name'],
            companyColumns : ['usr_company'],
            ceoColumns : ['usr_name'],
            mobileColumns : ['usr_cellphone'],
            businessColumns : ['company_registration_no'],
            emailColumns : ['usr_email'],
            start_date : null,
            end_date : null,
            search_word : '',
            isExecute : true,


            isDeleteModalOpen: false,
            isDeleteConfirmModalOpen: false,
            deletingRowId:'',

            notification: {
                type: 'client',
                isAlert: false,
                title: '',
                message: '',
                level: '',
                position: ''
            },
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let newState = {}
        if (nextProps.users && nextProps.users.data) {
            if(nextProps.users.data.length > 0)
            {
                nextProps.users.data.map((item,index)=>{
                    item['id'] = index+1
                    console.log(item.id,'me no cho')
                })
            }
            newState = {
                ...newState,
                rows: nextProps.users.data,
                totalCount: nextProps.users.total,
                pageSize: +nextProps.users.per_page,
                currentPage: +nextProps.users.current_page - 1,
            }
        }


        if (nextProps.places && nextProps.places.length > 0 ) {
            let places = nextProps.places
            if(places[0].place_id){
                let itemAll = {place_id : '',place_name : 'All' }
                places.unshift(itemAll)
            }
            newState = {
                ...newState,
                places :places
            }
        }


        if (nextProps.intl) {
            newState = {
                ...newState,
                columns: [
                    {
                        name: 'button',
                        title : '  '
                    },
                    {
                        name: 'place_name',
                        title: nextProps.intl.formatMessage({
                            id: 'mgm.admin.place_name',
                            defaultMessage: 'Placement'
                        })
                    },
                    {
                        name: 'usr_company',
                        title: nextProps.intl.formatMessage({
                            id: 'mgm.admin.usr_company',
                            defaultMessage: 'Company Name'
                        })
                    },
                    {
                        name: 'usr_name',
                        title: nextProps.intl.formatMessage({
                            id: 'mgm.admin.usr_name',
                            defaultMessage: 'Ceo Name'
                        })
                    },
                    {
                        name: 'usr_cellphone',
                        title: nextProps.intl.formatMessage({
                            id: 'mgm.admin.usr_cellphone',
                            defaultMessage: 'Mobile phone'
                        })
                    },
                    {
                        name: 'company_registration_no',
                        title: nextProps.intl.formatMessage({
                            id: 'mgm.admin.company_registration_no',
                            defaultMessage: 'Business license'
                        })
                    },
                    {
                        name: 'usr_email',
                        title: nextProps.intl.formatMessage({
                            id: 'mgm.admin.email',
                            defaultMessage: 'Email'
                        })
                    },
                ]
            }
        }
        return newState
    }

    // commitChanges = ({ added, changed, deleted }) => {
    //     if (deleted) {
    //         let del = this.state.rows.filter((row) => {
    //             if (deleted.includes(row.id)) {
    //                 return row
    //             }
    //         })
    //         this.setState({
    //             deletingRowId: deleted[0],
    //             isDeleteModalOpen: true
    //         });
    //     }
    // }


    Command = (isExecute, { id, onExecute }) => {
        return <CommandButton
            {...commandComponentProps[id]}
            onExecute={onExecute}
            isExecute={isExecute}
        />
    }


    openDeleteModal = () => {
        this.setState({
            isDeleteModalOpen: !this.state.isDeleteModalOpen
        });
    };
    openDeleteConfirmModal = () => {
        this.setState({
            isDeleteConfirmModalOpen: !this.state.isDeleteConfirmModalOpen
        });
    };


    placementFormatter = (rows) => {
        switch (rows.row.use_status) {
            case 0 :
                return rows.row.place_name+' 현장 승인 대기중'
            case 1 :
                return rows.row.place_name
            case 4 :
                return rows.row.place_name+' 현장 거절'
            default :
                return '미신청'
        }
    }

    companyFormatter = (rows) => {
        return rows.row.usr_company ? rows.row.usr_company : ''
    }

    ceoFormatter = (rows) => {
        return rows.row.usr_name ? rows.row.usr_name : ''
    }

    mobileFormatter = (rows) => {
        return rows.row.usr_cellphone ? rows.row.usr_cellphone : ''
    }

    businessFormatter = (rows) => {
        return rows.row.company_registration_no ? rows.row.company_registration_no : ''
    }

    emailFormatter = (rows) => {
        return rows.row.usr_email ? rows.row.usr_email : ''
    }

    buttonFormatter = (rows) => {
        return rows.row.link_id ?
                <button className={'btn btn-danger btn-sm'}  onClick={()=>this.onClickButtonDelete(rows.row.link_id)}>
                    <i className="fa fa-trash"></i>
                </button>
            : null
    }

    onClickButtonDelete = (id) =>{

        this.setState({
            deletingRowId: id,
            isDeleteModalOpen: true
        });
    }

    selectPlacement = (currentPlacement) => {
        this.setState({
            currentPlacement
        })
    }

    handleChangeDate = ({start_date, end_date}) => {
        // start_date = start_date || this.state.start_date
        // end_date = end_date || this.state.end_date

        if (typeof start_date === 'undefined') {
            start_date = this.state.start_date
        } else if (start_date === null) {
            start_date = null
        }
        if (typeof end_date === 'undefined') {
            end_date = this.state.end_date
        } else if (end_date === null) {
            end_date = null
        }

        this.setState({
            start_date,
            end_date
        })
    }

    _convertDateToString = (date, format) => {
        date = new Date(date);
        date = moment(date);
        date = date.format(format);

        return date
    }

    handleChangeStart = (start_date) =>
        this.handleChangeDate({start_date});

    handleChangeEnd = (end_date) =>
        this.handleChangeDate({end_date})

    changeCurrentPage = (currentPage) => {
        let startDateString = this.state.start_date ? this._convertDateToString(this.state.start_date, "YYYY-MM-DD") : ''
        let endDateString = this.state.end_date ? this._convertDateToString(this.state.end_date, "YYYY-MM-DD") : ''
        let place_id = this.state.currentPlacement ? this.state.currentPlacement.place_id : null
        let search_word = this.state.search_word
        this.props.fetchUserManager(currentPage + 1,null,place_id,startDateString,endDateString,search_word)
    }

    _search = (event) => {
        event.preventDefault();
        let startDateString = this.state.start_date ? this._convertDateToString(this.state.start_date, "YYYY-MM-DD") : ''
        let endDateString = this.state.end_date ? this._convertDateToString(this.state.end_date, "YYYY-MM-DD") : ''
        let place_id = this.state.currentPlacement ? this.state.currentPlacement.place_id : null
        let search_word = this.state.search_word
        this.props.search(place_id,startDateString,endDateString,search_word)

    }

    searchWord = (e) => {
        this.setState({
            search_word : e.target.value
        })
    }


    deleteUser = () =>{
        let startDateString = this.state.start_date ? this._convertDateToString(this.state.start_date, "YYYY-MM-DD") : ''
        let endDateString = this.state.end_date ? this._convertDateToString(this.state.end_date, "YYYY-MM-DD") : ''
        let place_id = this.state.currentPlacement ? this.state.currentPlacement.place_id : null
        let search_word = this.state.search_word
        this.props.deleteUserPlacement(this.state.deletingRowId).then(res => {
            if (this.state.rows.length <= 1) {
                this.props.fetchUserManager(this.state.currentPage,null,place_id,startDateString,endDateString,search_word)
                    .then(() => {
                        this.setState({
                            deletingRowId: '',
                            isDeleteConfirmModalOpen: false,
                            notification: {
                                isAlert: true,
                                title: <FormattedMessage
                                    id="dashboard.Succeed"
                                    defaultMessage="Succeed!"/>,
                                message: <FormattedMessage
                                    id="dashboard.ItemValidate.DeleteSucceed"
                                    defaultMessage="Delete item successfully!"/>,
                                level: 'success',
                                position: 'tr',
                                allowHTML: false
                            }
                        })
                    })
            }else{
                this.props.fetchUserManager(this.state.currentPage + 1,null,place_id,startDateString,endDateString,search_word)
                    .then(() => {
                        this.setState({
                            deletingRowId: '',
                            isDeleteConfirmModalOpen: false,
                            notification: {
                                isAlert: true,
                                title: <FormattedMessage
                                    id="dashboard.Succeed"
                                    defaultMessage="Succeed!"/>,
                                message: <FormattedMessage
                                    id="dashboard.ItemValidate.DeleteSucceed"
                                    defaultMessage="Delete item successfully!"/>,
                                level: 'success',
                                position: 'tr',
                                allowHTML: false
                            }
                        })
                    })
            }
        }).catch(error=>{
            this.setState({
                notification: {
                    type: 'server',
                    isAlert: true,
                    title: <FormattedMessage
                        id="dashboard.Error"
                        defaultMessage="Error!"/>,
                    message: error.data.errors.message,
                    level: 'error',
                    position: 'tr',
                    allowHTML: true
                }
            });
        })
    }

    removeNotification = () => {
        this.setState(prevState => ({
            notification: {
                ...prevState.notification,
                isAlert: false
            }
        }))
    };

    render(){
        const {
            rows,
            columns,
            placementColumns,
            companyColumns,
            ceoColumns,
            mobileColumns,
            businessColumns,
            emailColumns,
            currentPage,
            pageSize,
            totalCount,
            isExecute,
            buttonColumns
        } = this.state
        const TableComponent = ({...restProps}) => (
            <Table.Table
                {...restProps}
                className="table-bordered table-hover-cursor"
            />
        );
        return (
            <Fragment>
                <Notification
                    {...this.state.notification}
                    onAdd={this.removeNotification}
                />
                <div className="col-sm-12 col-lg-10 item-management call-history padding-mb-0">
                    <div className="row m-0">
                        <div className="main-select">
                            <div className="row main-content-select change-style">
                                <div className="col-md-3">
                                    <div className="row item-change">
                                        <div className="col-md-3 pr-0 text-center">
                                            <label className="title-label item-select space-nowrap m-0">
                                                <FormattedMessage
                                                    id="mgm.admin.placement"
                                                    defaultMessage="Placement"
                                                />
                                            </label>
                                        </div>
                                        <div className="col-md-9 pl-0">
                                            <Select
                                                id="company-select"
                                                searchable={true}
                                                clearable={false}
                                                value={this.state.currentPlacement}
                                                options={this.state.places}
                                                valueKey={'place_id'}
                                                labelKey={'place_name'}
                                                placeholder={
                                                    <FormattedMessage
                                                        id="dashboard.SelectCompany"
                                                        defaultMessage="Select Company"
                                                    />
                                                }
                                                onChange={this.selectPlacement}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-5">
                                    <div className="main-search estate-date">
                                        <div className="datetime item-search row">
                                            <div className="search-input col-md-2">
                                                <span>
                                                    <FormattedMessage
                                                        id="mgm.admin.period"
                                                        defaultMessage="Period"
                                                    />
                                                </span>
                                            </div>

                                            <div className="fromdate col-md-5">
                                                <DatePicker
                                                    dateFormat="YYYY/MM/DD"
                                                    id="FromDatePicker"
                                                    name="FromDatePicker"
                                                    className="form-control"
                                                    selected={this.state.start_date}
                                                    onChange={this.handleChangeStart}
                                                    selectsStart
                                                    startDate={this.state.start_date}
                                                    endDate={this.state.end_date}
                                                />
                                                <Button id='start-day-call'
                                                    // onClick={this.focusFromDateHandler}
                                                >
                                                    <i className="fa fa-calendar"></i>
                                                </Button>
                                            </div>

                                            <div className="todate col-md-5">
                                                <DatePicker
                                                    dateFormat="YYYY/MM/DD"
                                                    id="ToDatePicker"
                                                    name="ToDatePicker"
                                                    className="form-control"
                                                    selected={this.state.end_date}
                                                    onChange={this.handleChangeEnd}
                                                    selectsEnd
                                                    startDate={this.state.start_date}
                                                    endDate={this.state.end_date}

                                                />
                                                <Button id='end-day-call'
                                                    // onClick={this.focusToDateHandler}
                                                >
                                                    <i className="fa fa-calendar"></i>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={'col-md-3'}>
                                    <input type="text"
                                           className={'form-control'}
                                           placeholder={
                                               this.props.intl.formatMessage({
                                                   id: 'mgm.admin.search_word',
                                                   defaultMessage: 'Search Word'
                                               })
                                           }
                                           onChange={this.searchWord}
                                    />
                                </div>

                                <div className={'col-md-1 text-center'}>
                                    <Button color="info"
                                        onClick={this._search}
                                    >
                                        <FormattedMessage
                                            id="dashboard.mgm.Search"
                                            defaultMessage="Search"
                                        />
                                    </Button>
                                </div>

                            </div>
                            <div className="row m-0 main-table">
                                <div className="col-lg-12 content-left">
                                    <div className="main-info-left">
                                        <div className="">
                                            <div className="table-responsive table-call-history">
                                                <Card className="call-history-table ">
                                                    <Grid
                                                        rows={rows ? rows : []}
                                                        columns={columns}
                                                        getRowId={getRowId}
                                                    >
                                                        {/*<EditingState*/}
                                                            {/*onCommitChanges={this.commitChanges}*/}
                                                        {/*/>*/}

                                                        <DataTypeProvider
                                                            formatterComponent={this.placementFormatter}
                                                            for={placementColumns}
                                                        />

                                                        <DataTypeProvider
                                                            formatterComponent={this.companyFormatter}
                                                            for={companyColumns}
                                                        />

                                                        <DataTypeProvider
                                                            formatterComponent={this.ceoFormatter}
                                                            for={ceoColumns}
                                                        />

                                                        <DataTypeProvider
                                                            formatterComponent={this.mobileFormatter}
                                                            for={mobileColumns}
                                                        />

                                                        <DataTypeProvider
                                                            formatterComponent={this.businessFormatter}
                                                            for={businessColumns}
                                                        />

                                                        <DataTypeProvider
                                                            formatterComponent={this.emailFormatter}
                                                            for={emailColumns}
                                                        />
                                                        <DataTypeProvider
                                                            formatterComponent={this.buttonFormatter}
                                                            for={buttonColumns}
                                                        />



                                                        <PagingState
                                                            currentPage={currentPage}
                                                            onCurrentPageChange={this.changeCurrentPage}
                                                            pageSize={pageSize}
                                                            // onPageSizeChange={this.changePageSize}
                                                        />
                                                        <CustomPaging
                                                            totalCount={totalCount}
                                                        />

                                                        <Table
                                                            tableComponent={TableComponent}
                                                        />
                                                        <TableHeaderRow />
                                                        {/*<TableEditRow />*/}
                                                        {/*<TableEditColumn*/}
                                                            {/*showDeleteCommand*/}
                                                            {/*commandComponent={*/}
                                                                {/*({ id, onExecute }) =>*/}
                                                                    {/*this.Command(isExecute, { id, onExecute })*/}
                                                            {/*}*/}
                                                        {/*/>*/}
                                                        <PagingPanel/>
                                                    </Grid>
                                                    <DeleteConfirmModal
                                                        isDeleteModalOpen={this.state.isDeleteModalOpen}
                                                        openDeleteModal={this.openDeleteModal}
                                                        isDeleteConfirmModalOpen={this.state.isDeleteConfirmModalOpen}
                                                        openDeleteConfirmModal={this.openDeleteConfirmModal}
                                                        deleteInfoHandler={this.deleteUser}
                                                    />
                                                </Card>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default injectIntl(RealEstateAgent)