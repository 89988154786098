import React, {Fragment} from 'react';
import {Modal, ModalHeader, ModalFooter, ModalBody, Button} from 'reactstrap';
import {FormattedMessage} from 'react-intl';

const ConfirmModal = props => {
    return (
        <Fragment>
            <Modal isOpen={props.isConfirmModalOpen} toggle={props.openConfirmModal}>
                <ModalHeader toggle={props.openConfirmModal}>
                    {props.title}
                </ModalHeader>
                <ModalBody>
                    {props.body}
                </ModalBody>
                <ModalFooter>
                    <Button color="warning"
                            onClick={props.onConfirm}>
                        <FormattedMessage
                            id="dashboard.Yes"
                            defaultMessage="Yes"/>
                    </Button>
                    <Button color="primary" onClick={props.openConfirmModal}>
                        <FormattedMessage
                            id="dashboard.Cancel"
                            defaultMessage="Cancel"/>
                    </Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    )
};
export default ConfirmModal;