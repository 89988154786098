import React from 'react';
import {renderStatusCall} from './../../utils/helpers'

function ListCalSearchByNumber ({
                                    callListSearchByCallNumber,
                                    getIdRecording,
                                    role,
																		change_manager,
																		changeManagerModal
})  {

    function compareValues (key, order = 'asc') {
        return function innerSort(a, b) {
            if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
                return 0;
            }

            const varA = (typeof a[key] === 'string')
                ? a[key].toUpperCase() : a[key];
            const varB = (typeof b[key] === 'string')
                ? b[key].toUpperCase() : b[key];

            let comparison = 0;
            if (varA > varB) {
                comparison = 1;
            } else if (varA < varB) {
                comparison = -1;
            }
            return (
                (order === 'desc') ? (comparison * -1) : comparison
            );
        };
    }

    function formatCallDetail (call_detail) {
        let content = '';
        if(call_detail && call_detail.length > 0) {
            let arraySort = [];
            call_detail.map(item => {
                if(item.info_detail && item.info_detail.info_mast) {
                    arraySort.push({
                        mast_name : item.info_detail.info_mast.m_name,
                        detail_name : item.info_detail.m_dtl_name,
                        order : item.info_detail.info_mast.m_order
                    })
                }
            })
            arraySort.sort(compareValues('order','asc'))
            if(arraySort.length > 0){
                content  = arraySort.map((a) => `${a.mast_name + ': ' + a.detail_name}`).join('. ');
            }

        }
        return content;
    }



    return(
        <div className="custom_table">
            <table className="table st_table_4">
                <thead>
                <tr>
                    <th className="inline_action">구분</th>
                    <th className="inline_action">일자</th>
                    <th className="inline_action">특기사항</th>
                    <th className="inline_action" width={'40%'}>상담내용</th>
                    <th className="inline_action" width={'100px'}>고객명</th>
                    <th className="inline_action" width={'130px'}>상담자</th>
                    <th className="inline_action">녹취</th>
                </tr>
                </thead>
                <tbody>

                {callListSearchByCallNumber.map((item,index) => {
										//console.log('item.user:',item.user);
										//console.log('item.user?.usr_name:',item.user?.usr_name);
										let usr_name = item.user ? item.user?.usr_name : '---';
										usr_name = usr_name.length > 6 ? usr_name.slice(0,6)+'...' : usr_name;
                    return (
                        <tr key={index}>
                            <td className="inline_action">{renderStatusCall(item.call_check)}</td>
                            <td className="inline_action">
                              {item.call_date} {item.is_deleted == 1 ? <i className="fas col_check fa-check-square ml-1"/> : ''}
                                {/*   0 : //'ngay truoc do'; 1 : thieu sot */}
                                {item.call_date_update == 0 ? <span className={'ml-3'}>전일</span> : ''}
                                {item.call_date_update == 1 ? <span className={'ml-3'}>누락</span> : ''}
                            </td>
                            <td>{item.call_desc ? item.call_desc : ''}</td>
                            <td>{formatCallDetail(item.call_detail)}</td>
                            <td className="inline_action">{item.call_cust_name && item.call_cust_name.length > 6 ? item.call_cust_name.slice(0,6)+'..' : item.call_cust_name}</td>
                            <td className="inline_action">									
														{
															change_manager ? 
																(<button 
																	type="button" 
																	className="" 
																	onClick={()=> changeManagerModal(item.call_id,item.lots_id,item.user.usr_id)}
																> 
																{usr_name} 
																</button>)
																:
																<span>{usr_name}</span>
														}
														</td>
                            {role ?
                                <td className="inline_action">
                                {item.call_check == 1 || item.call_check ==0
                                    ? <button
                                        type="button"
                                        className="btn_play"
                                        onClick={(event)=>{
                                            getIdRecording(event,item.call_id);
                                        }}
                                    >
                                        <i className="fas fa-play"/>
                                    </button>
                                    : <label className="icon_no"></label>
																	}
                                </td>
                                :
                                <td className="inline_action">
                                {
																	item.call_check == 1 || item.call_check == 0
                                    ? <label className="icon_yes"></label>
                                    : <label className="icon_no"></label>
                                }
                            </td>
													}
                        </tr>
                    )
                })}

                </tbody>
            </table>

        </div>
    )
}

export default ListCalSearchByNumber;