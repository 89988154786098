import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import NotificationSystem from "react-notification-system";
import { FormattedMessage } from "react-intl";

TextOnOfComponent.propTypes = {
  props: PropTypes.object,
};

function TextOnOfComponent(props) {
  const [status, setStatus] = useState(props.statusText);
  const [statusNew, setStatusNew] = useState(null);
  const notificationSystem = useRef();
  const refCloseModal = useRef();

  function handleChangeStatus(status) {
    // setStatus(status);
    setStatusNew(status);
  }

  useEffect(() => {
    setStatus(props.statusText);
    setStatusNew(props.statusText);
  }, [props.statusText]);

  function handleClick() {
    if (statusNew != null && status != statusNew) {
      props.changeStatus(statusNew);
    }
    refCloseModal.current.click();
  }

  function handleClose() {
    setStatus(props.statusText);
    setStatusNew(props.statusText);
  }

  return (
    <div
      className="modal fade modal_edit_salelots"
      id="modal_edit_salelots"
      tabIndex={-1}
      aria-labelledby="logoutModalLabel"
      aria-hidden="true"
      style={{ display: "none" }}
    >
      <NotificationSystem ref={notificationSystem} />
      <div className="modal-dialog modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Update</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="box_add_ppreferences_1">
              <div className="box_group_control">
                <div className="heading_title">
                  <FormattedMessage
                    id="dashboard.DataSetting.TextOnOff"
                    defaultMessage="	
                        전화상담 문자 기능"
                  />
                </div>
                <div className="group_control_in">
                  <div className="custom_select">
                    <select
                      className="form_control"
                      value={statusNew}
                      onChange={(e) => handleChangeStatus(e.target.value)}
                    >
                      <option value={1}>ON</option>
                      <option value={0}>OFF</option>
                    </select>
                    <span className="item_dropdown" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-outline-dark "
              data-dismiss="modal"
              aria-label="Close"
              ref={refCloseModal}
              onClick={handleClose}
            >
              <FormattedMessage id="dashboard.Cancel" defaultMessage="Cancel" />
            </button>
            <button
              type="button"
              className="btn btn-dark btn_add"
              onClick={handleClick}
            >
              <FormattedMessage id="dashboard.Add" defaultMessage="Add" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TextOnOfComponent;
