import * as XLSX from 'xlsx';
//f = file
import utf8 from 'utf8'

function convertToJson(csv) {
    var lines = csv.split("\n");

    var result = [];

    var headers = lines[0].split(",");

    for (var i = 1; i < lines.length; i++) {
        var obj = {};
        var currentline = lines[i].split(",");

        for (var j = 0; j < headers.length; j++) {
            obj[headers[j]] = currentline[j];
        }

        result.push(obj);
    }

    //return result; //JavaScript object
    return {
        columns : headers,
        data : result
    }; //JSON
}




function s2ab(s) {

    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;

}

function csvFileToJSON(file) {
    const self = this;

    return new Promise(function(resolve, reject) {
        var reader = new FileReader();

        reader.onerror = function(err) {
            reject(err);
        };

        reader.onload = function() {
            const data = CSVToArray(reader.result);
            resolve(data);
        };
        reader.readAsText(file);
    });
}

function CSVToArray( strData, strDelimiter ){
    strDelimiter = (strDelimiter || ",");
    let objPattern = new RegExp(
        (
            "(\\" + strDelimiter + "|\\r?\\n|\\r|^)" +

            "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +

            "([^\"\\" + strDelimiter + "\\r\\n]*))"
        ),
        "gi");

    let arrData = [];
    let headers = [];
    let headersFound = false;
    let headerIndex = 0;

    let arrMatches = null;

    while(arrMatches = objPattern.exec( strData )) {
        let strMatchedDelimiter = arrMatches[ 1 ];
        if (strMatchedDelimiter.length && strMatchedDelimiter !== strDelimiter) {
            arrData.push( {} );
            headersFound = true;
            headerIndex = 0;
        }

        let strMatchedValue;
        if (arrMatches[2]) {
            strMatchedValue = arrMatches[2].replace(new RegExp( "\"\"", "g" ),"\"");
        } else {
            strMatchedValue = arrMatches[3];
        }

        if (!headersFound) {
            headers.push(strMatchedValue);
        } else {
            arrData[arrData.length -1][headers[headerIndex]] = strMatchedValue;
            headerIndex ++;
        }
    }
    return arrData;
}

function convertToArray(json) {
    var columns = json[0];
    columns = columns.map(item => item.trim());
    var result = [];
    for (var i = 1; i < json.length; i++) {
        var obj = {};
        for (var j = 0; j < columns.length; j++) {
            if(json[i][j]) {
                obj[columns[j]] = json[i][j]
            } else {
                obj[columns[j]] = null;
            }
        }
        result.push(obj);
    }
    return {
        columns,
        data: result
    }
}

export default (f,onProcessedCallback) =>{ // onProcessedCallback return { columns, data}
    // var wb = XLSX.readFile(f);
    // var ws = wb.Sheets[wb.SheetNames[0]];
    // console.log(XLSX.utils.sheet_to_csv(ws));



    var dataExcel = [];
    var name = f.name;
    var extendName = name.split('.').pop();

    if(extendName === 'csv'){
        return csvFileToJSON(f).then(data => {
            const columns = Object.keys(data ? data[0] : [])
            onProcessedCallback({
                columns : columns,
                data : data
            })
        })

    }else if(extendName === 'xlsx'){
        const reader = new FileReader();
        reader.onload = (evt) => {
            // evt = on_file_select event
            /* Parse data */
            let bstr = evt.target.result;
            console.log('bstr',bstr)

            let wb = XLSX.read(bstr, { type: "binary" });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            console.log('wsname',wsname)
            console.log('ws',ws)
            //const data = XLSX.utils.sheet_to_json(ws,{header : 1, cellDates : true, dateNF: "yyyy-mm-dd hh:mm:ss"});
            const data = XLSX.utils.sheet_to_json(ws,{header : 1});
            console.log('data',data)
            try{
                onProcessedCallback(convertToArray(data));
            }catch (e) {
                onProcessedCallback({error : true})
            }
        };
        reader.readAsBinaryString(f);
        return reader;
    }
}