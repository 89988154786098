
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from "react-intl"
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Spinner from '../../components/Spinner/Spinner';
import {
    unmountCallListAction,
    searchByNameAction,
    exportCallListAction,
    importCallListAction,
    sampleDownloadExcelAction, fetchCustomerListAction, deleteCustomerAction,
    importExcelCustomerAction
} from "../../store/actions/calls";
import { fetchInfoMastsAction, unmountInfoMastAction } from "../../store/actions/infoMasts";
import { fetchInfoDetailByInfoMastAction, unmountInfoDetailAction } from "../../store/actions/infoDetail";
import readXlsxFile from 'read-excel-file'
import _, { countBy } from 'lodash';
import moment from "moment/moment";
import queryString from 'query-string';
import DatePicker from 'react-datepicker';
import NotificationSystem from 'react-notification-system';
import Pagination from "react-js-pagination";
import api from "../../utils/api";
import * as helpers from "../../utils/helpers";
import progressBar from '../../utils/progressBar';
import * as routerName from "../../utils/routerName";
import DeleteConfirmModal from './../../components/DeleteConfirmModal/DeleteConfirmModal';
import UploadExcelModal from './../../components/CustomerManagement/UploadExcelModal';
import exportToExcel from './../../components/Excel/ExportToExcel';
import readToExcel from './../../components/Excel/ReadFileExcel';
const callListTypes = [
    { value: 'received', text: {id: 'dashboard.CounsellingData.Received', defaultMessage: 'Received'} },
    { value: 'sending', text: {id: 'dashboard.CounsellingData.Sending', defaultMessage: 'Sending'} },
    { value: 'all', text: {id: 'dashboard.CounsellingData.All', defaultMessage: 'All'} },
    { value: 'all_with_unanswer', text: {id: 'dashboard.CounsellingData.AllWithUnAnswer', defaultMessage: 'AllWithUnAnswer'} },
    { value: 'all_in', text: {id: 'dashboard.CounsellingData.AllIn', defaultMessage: 'AllIn'} },
]

class CustomerData extends Component {
    constructor(props) {
        super(props)
        this.state = {
            notification: {
                allowHTML: false,
            },
            cond: {
                startDate: moment().subtract(2, 'months'),
                endDate: moment(), 
                name: '',
                address: '',
                phone: '',
                info_mast: '',
                info_detail: '',
                rec_system: '',
                call_line_info: '',
                sub_search:'',
                callListType:'all',
            },
            infoDetails: [],
            resultSearch: {
                current_page: 1,
                total: 0,
            },
            selection: [],
            checkAllTable: false,

            isDeleteModalOpen: false,
            isDeleteConfirmModalOpen: false,
            openModalUploadExcel: false,
            file: null,
						renderFirst: true,
						uploadFirst: true,
            dataTypes: [
                {
                    title: 'ALL',
                    rec_system: ''
                },
                {
                    title: 'CRM',
                    rec_system: '0'
                },
                {
                    title: 'MGM',
                    rec_system: '1'
                },
            ],
            columns: [
                { name: 'call_cust_name', title: <FormattedMessage id="dashboard.Customer_Name" defaultMessage="Name" /> },
                { name: 'call_tel', title: <FormattedMessage id="dashboard.ReceivedPhone" defaultMessage="Received Phone #" /> },
                { name: 'call_check', title: <FormattedMessage id="dashboard.Type" defaultMessage="Type" /> },
                { name: 'call_cust_hp', title: <FormattedMessage id="dashboard.MobilePhone" defaultMessage="Mobile phone #" /> },
                { name: 'call_line_info', title: <FormattedMessage id="dashboard.LineInformation" defaultMessage="LineInformation" /> },
                { name: 'call_cust_tel', title: <FormattedMessage id="dashboard.Telephone" defaultMessage="Telephone #" /> },
                { name: 'call_post_code', title: <FormattedMessage id="dashboard.Postcode" defaultMessage="Post code" /> },
                { name: 'call_cust_address', title: <FormattedMessage id="dashboard.Address" defaultMessage="Address" /> },
                { name: 'call_cust_info', title: <FormattedMessage id="dashboard.CustomerInformation" defaultMessage="Customer Info" /> },
                { name: 'call_agree', title: <FormattedMessage id="dashboard.ReceiveCallAgree" defaultMessage="Receive Call Agree" /> },
            ],
            infoMastOptions: [],
            callIDGroups: [],
            downloading: false,
						uploading: false,
            districts : []
        }
        this.notificationSystem = React.createRef();
    }



    addNotification = (data) => {
        const notification = this.notificationSystem.current;
        notification.addNotification({
            message: data.message,
            title: data.title,
            level: data.level,
        });
    };

    componentDidMount = async() => {
        progressBar.start();
        // let result1 = await this.getInfoMastFunc();
        // let result2 = await this.getInfoLinesFunc();
        // let result3 = await this.getCustomerDataFunc();
        // console.log(result1, 'result1');
        // console.log(result2, 'result2');
        // console.log(result3, 'result3');
        new Promise(resolve => {
            this.getInfoLinesFunc(this.props.sceneCompanyId);
            this.getInfoMastFunc(this.props.sceneCompanyId);
            resolve();
        }).then(res => {
            this.getCustomerDataFunc();
        })
        this.getDistrict(this.props.sceneCompanyId);
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.isChangeScene) {
            new Promise(resolve => {
                this.resetCondition();
                this.getInfoLinesFunc(nextProps.sceneCompanyId);
                this.getInfoMastFunc(nextProps.sceneCompanyId);
                resolve();
            }).then(res => {
                this.getCustomerDataFunc();
            })
            this.getDistrict(nextProps.sceneCompanyId);
        }
    }

    getDistrict  = (companyId) => {
        // get districts
        api.districts.getDistrictsByCompany(companyId).then(res => {
            let districts = res.data.data;
            this.setState({
                districts
            })
        })
    }

    getInfoMastFunc = async (companyId) => {
        progressBar.start();
        try {
						let renderFirst = this.state.renderFirst;
						console.log("renderFirst:",renderFirst);
            let response = {};
            if(companyId) {
                response = await api.infoMast.getInfoMastByCompany(companyId);
            } else {
                response = await api.infoMast.getInfoMast();
            }
            let moreColumnOptions = [];
            let columns = [];
						
						console.log('response:',response);

            response?.data?.data.map((item, key) => {
                columns.push(
                    {
                        name: item.m_name,
                        title: item.m_name,
                    }
                )
                // moreColumnOptions.push(item.m_name);
            })
						console.log('columns:',columns);

						if (this.state.renderFirst) {
							this.setState({
									infoMastOptions: response?.data?.data || [],
									cond: {
											...this.state.cond,
											// columnOptions: [
											//     ...this.state.cond.columnOptions,
											//     ...moreColumnOptions
											// ]
									},
									renderFirst: false, // toggle False/True
									columns: [
											...this.state.columns,
											...columns
									]
							}, () => {
									console.log(this.state, 'state_af');
							});
						}
            progressBar.done();
        } catch (e) {
            progressBar.done();
        }
    }

    getInfoLinesFunc = async (companyId) => {
        progressBar.start();
        try {
            let response = {};
            if(companyId) {
                response = await api.infoLine.getInfoLineListByCompany(companyId);
            } else {
                response = await api.infoLine.getInfoLineList();
            }
            this.setState({
                ...this.state,
                infoLines: response?.data?.data
            });
            progressBar.done();
        } catch (e) {
            progressBar.done();
        }
    }

    getCustomerDataFunc = async () => {
        progressBar.start();
        let scene = {};
        if(this.props.sceneCompanyId) {
            scene['company_id'] = this.props.sceneCompanyId
        }
        if(this.props.sceneLostId) {
            scene['lots_id'] = this.props.sceneLostId
        }
        let params = {
            ...{...this.state.cond, ...scene},
            page: this.state.resultSearch?.current_page || 1
        }
        let query = queryString.stringify(params);

        return await this.props.onSearchForm(query).then(response => {
            // return response.data.data;

            // check all = true if all row checked
            let resultSearch = response.data.mainData            
            let allCallIDCurrentPage = resultSearch?.data.map(item => {
                return item.call_id
            })
            let { selection } = this.state;
            let checker = helpers.checkEveryIncArray(allCallIDCurrentPage, selection) && selection?.length > 0;

            let {callIDGroups} = response?.data;       
            // console.log(callIDGroups, 'callIDGroups');

            this.setState({
                ...this.state,
                resultSearch,
                callIDGroups,
                checkAllTable: checker
            }, () => {
                // console.log(this.state.callIDGroups, 'max_call');
            })
            progressBar.done();
        }).catch(error => {
            progressBar.done();
        });
    }

    onChangeValueForm = event => {
        this.setState({
            ...this.state,
            cond: {
                ...this.state.cond,
                [event.target.name]: event.target.value
            }
        }, () => {
            // console.log(this.state.cond, 'conddd');
        });
    }


    onChangeInfoMast = (event) => {
        let {target}  = event;
        let objectInfo = target[target.selectedIndex].getAttribute('data-info');  
        let infoDetails =  objectInfo ? JSON.parse(objectInfo).info_detail : [];   

        this.setState({
            ...this.state,
            infoDetails,
            cond: {
                ...this.state.cond,
                [target.name]: target.value,
                'info_detail': ''
            }
        }, () => {
            // console.log(this.state, 'state_af');
        });
    }

    onChangeInfoLine = (event)=> {
        let {target}  = event;
        this.setState({
            ...this.state,
            cond: {
                ...this.state.cond,
                [target.name]: target.value,
            }
        }, () => {
            console.log(this.state, 'state_af2');
        });
    }

    handleSearch = () => {
        this.setState({
            ...this.state,
            resultSearch: {
                ...this.state.resultSearch,
                current_page: 1
            },
            checkAllTable: false,
            selection: [],
        }, () => {
            this.getCustomerDataFunc();
        })
    }

    handleSubSearch = (event) => {
        this.setState({
            ...this.state,
            cond: {
                ...this.state.cond,
                'sub_search': event.target.value
            },
            resultSearch: {
                ...this.state.resultSearch,
                current_page: 1
            },
            checkAllTable: false,
            selection: [],
        }, () => {
            if (this.state.cond.sub_search.length > 1 || this.state.cond.sub_search.length == 0) {
                this.getCustomerDataFunc();
            }
        });
    }


    onSampleDownload = (e) => {

        const {
            infoMastOptions,
        } = this.state;

//				let columnCallList = [
//						'상담일시',//call_date
//						'상담구분',//call_check
//						'고객이름',//call_cust_name
//						'상담전화',// call_tel
//						'일반전화',// call_cust_tel
//						'휴대전화',// call_cust_hp
//						'우편번호', //ma buu chinh + call_cust_address
//						'주소',// call_cust_address
//						'관리지역', // khu vuc quan li
//						'특기사항', // note
//						'상담자', //  tu van vien
//						'회선 정보', // line_info
//						'고객정보', // call_cust_info
//						'수신동의', // call_agree
//				];
				let columnCallList = [];

				// push infoMast vao columnCallList de check cac truong
				infoMastOptions.map(item => {
						columnCallList.push(item.m_name)
				})

				console.log('columnCallList:',columnCallList);
				console.log('columnCallList.toString():',columnCallList.toString());

        progressBar.start();
        this.props.onSampleDownload(columnCallList.toString()).then(res => {
            this.setState({
                ...this.state,
                notification: {
                    allowHTML: false,
                }                
            }, () => {
                this.addNotification({
                    title: <FormattedMessage
                            id="dashboard.Succeed"
                            defaultMessage="Succeed!"/>,
                    message: <FormattedMessage
                            id="dashboard.SampleDownload.Succeed"
                            defaultMessage="Download sample excel successfully!"/>,
                    level: 'success',
                })
                progressBar.done();
            })
        }).catch(error => {
            this.setState({
                ...this.state,
                notification: {
                    allowHTML: false,
                }                
            }, () => {
                this.addNotification({
                    title: <FormattedMessage
                            id="dashboard.Error"
                            defaultMessage="Error!"/>,
                    message: <FormattedMessage
                        id="dashboard.ErrorMessage"
                        defaultMessage="Sorry! Something wen't wrong. Retry!"/>,
                    level: 'success',
                })
                progressBar.done();
            })
        });
    };

    // onExport = (event) =>{
    //     let cond = {
    //         ...this.state.cond,
    //         export:true
    //     }
    //     let query = queryString.stringify(cond, {arrayFormat: 'bracket'});
    //     let token = window.localStorage.getItem('token');
    //     let url = process.env.NODE_ENV === 'development' ?
    //         process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD;
    //     url =  url + `/customer-manage/export-customer-data?${query}&token=` + token;
    //     return window.open(url,'_blank');
        
    // }

    fixedFormatter = value => {
        let arrReceived = [0];
        let arrSending  = [1];
        let arrUnAnswer = [2, 3, 4, 5];
        if (arrReceived.includes(parseInt(value))) {
            return (
                <span className="badge badge-success">
                     <FormattedMessage id="dashboard.Received" defaultMessage="Received"/>
                </span>
            )
        }
        if (arrSending.includes(parseInt(value))) {
            return (
                <span className="badge badge-secondary">
                     <FormattedMessage id="dashboard.Hanging" defaultMessage="Hanging"/>
                </span>
            )
        }
        if (arrUnAnswer.includes(parseInt(value))) {
            return (
                <span className="badge badge-danger">
                     <FormattedMessage id="dashboard.Unanswered" defaultMessage="Unanswered"/>
                </span>
            )
        }
    };

    fixedFormatter_2 = value => {
        let arrReceived = [0];
        let arrSending  = [1];
        let arrUnAnswer = [2, 3, 4, 5];
        if (arrReceived.includes(parseInt(value))) {
            return '수신콜'
        }
        if (arrSending.includes(parseInt(value))) {
            return '발신콜'
        }
        if (arrUnAnswer.includes(parseInt(value))) {
            return '부재콜'
        }
    };

    callAgreeFormatter = value => {
        if(value === '0') {
            return '동의함'
        }
        if(value === '1') {
            return '동의안함'
        }
    }

    resetCondition = () => {
        this.setState({
            cond: {
                name: '',
                address: '',
                phone: '',
                info_mast: '',
                info_detail: '',
                rec_system: '',
                call_line_info: '',
                sub_search:'',
                callListType:'all',
            },
            resultSearch: {
                current_page: 1,
                total: 0,
            },
        })
    }

    onExport = (event) =>{
        progressBar.start();
        this.setState({
            ...this.state,
            downloading: true,
        })
        let scene = {};
        if(this.props.sceneCompanyId) {
            scene['company_id'] = this.props.sceneCompanyId
        }
        if(this.props.sceneLostId) {
            scene['lots_id'] = this.props.sceneLostId
        }
        let cond = {
            ...{...this.state.cond, ...scene},
            export:true,
            type: 'list'
        }
        let query = queryString.stringify(cond, {arrayFormat: 'bracket'});

        return api.customerManagement.getExportCustomerData(query).then(response => {
            // let mainData = response.data.data.map(item => {
            //     return {
            //         '이름': item.call_cust_name,
            //         '상담전화': item.call_tel,
            //         '휴대전화': item.call_cust_hp,
            //         '회선 정보': item.call_cust_tel,
            //         '일반전화': item.call_line_info,
            //         '주소': item.call_cust_address,
            //     }
            // });
            // exportToExcel(mainData, 'CustomerList')
            // progressBar.done();

            let mainData =  response.data.data;
            let newData = mainData.map(item => {
                return {
                    ...item,
                    infoMast2: this.state.infoMastOptions
                }
             })
            //  console.log(newData, 'newData1');
            
            newData = newData.map(item => { 
                let call_detail_obj = {};
                (item.call_detail2 || []).map((e) => {
                    call_detail_obj[+e.call_m_id] = e
                });
                // console.log(call_detail_obj, 'call_detail_obj');
                // let infoMast2 = item.infoMast2.map(e2 => {
                //     return {
                //         ...e4,
                //         subObjInfoMast: call_detail_obj[+e2.m_id] || null
                //     }
                // })

                return {
                    ...item,
                    infoMast2: item.infoMast2.map((e) => ({
                        ...e,
                        subObjInfoMast: call_detail_obj[+e.m_id] || null
                    })),
                }
            });
            // console.log(newData, 'newData2');

            let finalData = newData.map(item =>{
                let infoMastMatch = {};

                item.infoMast2.map(subInfoMast2 => {
                    infoMastMatch = {
                        ...infoMastMatch,
                        [subInfoMast2.m_name] : subInfoMast2.subObjInfoMast ? subInfoMast2.subObjInfoMast.call_dtl_name : ''
                    }
                });
                // console.log(infoMastMatch, 'infoMastMatch2');

                return {
                    '유형 ': this.fixedFormatter_2(item.call_check),
                    '이름 ': item.call_cust_name,
                    '상담전화 ': item.call_tel,
                    '휴대전화 ': item.call_cust_hp,
                    // '회선 정보': item.call_line_info,
                    '회선 정보 ': item?.info_line?.line_info,
                    '일반전화 ': item.call_cust_tel,
                    '주소 ': item.call_cust_address,
                    '고객정보 ': item.call_cust_info,
                    '수신동의 ': this.callAgreeFormatter(item.call_agree),
                    ...infoMastMatch
                }
            });
            exportToExcel(finalData, 'counselling');
            this.setState({
                ...this.state,
                downloading: false,
            })
            
            progressBar.done();
        })
    }

    selectCallListType = (event) => {
        let { value } = event.target;
        this.setState({
            ...this.state,
           cond: {
               ...this.state.cond,
               callListType: value,
           }
        }, () => {
            // this.getCounsellingDataFunc();
        })
    }


    changePage = (pageNumber) => {
        this.setState({
            ...this.state,
            resultSearch: {
                ...this.state.resultSearch,
                current_page: pageNumber
            },
        }, () => {
            this.getCustomerDataFunc();
        })
    }

    handleClickItemCheckbox = (event, item) => {
        let { selection } = this.state;
        let value = event.target.checked;
        if (value) {
            if ( !selection.includes(item.call_id) )
            selection.push(item.call_id);
        } else {
            selection = helpers.removeItemInArray(selection, item.call_id)
        }

        // check all = true if all row of current page checked
        let allCallIDCurrentPage = this.state.resultSearch?.data.map(item => {
            return item.call_id
        })
        let selectionCurrentPage = selection.filter(call_id => {
            return allCallIDCurrentPage.includes(call_id);
        })
        let checker = _.isEqual(allCallIDCurrentPage.sort(), selectionCurrentPage.sort());

        this.setState({
            ...this.state,
            selection,
            checkAllTable: checker
        }, () => {
            console.log(this.state.selection, 'selection_af');
        })
    }

    selectRowTable = (item) => {
        let value = !(this[`checkbox_${item.call_id}`].checked);
        this[`checkbox_${item.call_id}`].checked = value;  

        let { selection } = this.state;
        if (value) {
            if ( !selection.includes(item.call_id) )
            selection.push(item.call_id);
        } else {
            selection = helpers.removeItemInArray(selection, item.call_id)
        }

        // check all = true if all row of current page checked
        let allCallIDCurrentPage = this.state.resultSearch?.data.map(item => {
            return item.call_id
        })
        let selectionCurrentPage = selection.filter(call_id => {
            return allCallIDCurrentPage.includes(call_id);
        })
        let checker = _.isEqual(allCallIDCurrentPage.sort(), selectionCurrentPage.sort());

        this.setState({
            ...this.state,
            selection,
            checkAllTable: checker
        }, () => {
            // console.log(this.state.selection, 'seclectt');
        })
    }

    handleCheckAllTable = (event) => {
        let valueCheck = event.target.checked;

        let { selection } = this.state;
        let { resultSearch } = this.state
        let allCallIDCurrentPage = resultSearch?.data.map(item => {
            return item.call_id
        })

        if (valueCheck) {
            //check and add all call_id of current page to selection[]
            allCallIDCurrentPage.map(call_id => {
                if ( !selection.includes(call_id) )
                selection.push(call_id);
            })
      
        } else {
            //remove all call_id of current page in selection[]
            allCallIDCurrentPage.map(call_id => {
               selection = helpers.removeItemInArray(selection, call_id)
            })
        }

        this.setState({
            ...this.state,
            selection,
            checkAllTable: valueCheck,
        }, () => {
            // console.log(this.state.selection, 'selection_af');
        })
    }

    handleChangeFile = (event) => {
        let file = event.target.files[0];
        if (file) {
            this.setState({
                ...this.state,
                file,
            })
        }
    }

    areEqual = (first, second) => {
        console.log(first,'fiest')
        console.log(second,'second')
        if(first.length !== second.length){
            return false;
        };
        for(let i = 0; i < first.length; i++){
            console.log(first[i],'first[i]')
            if(!second.includes(first[i])){
                return false;
            };
        };
        return true;
    };

    onUploadExcel = (e) => {      
        // const formData = new FormData();
        // formData.append('file', this.state.file);
				// this.handleFiles(this.state.file)
				console.log('CLICKED:',e);
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
				//e.stopImmediatePropagation();
				e.preventDefault();

				console.log('this.state.uploadFirst:',this.state.uploadFirst);
				
				if (!this.state.uploadFirst) {
					return false;
				}

        this.setState({
            ...this.state,
            uploadFirst: false,
        }, () => {

						const {
								infoMastOptions,
								districts,
						} = this.state; // infoMast day

						const notificationCheckColumn ={
								title: <FormattedMessage
										id="dashboard.Error"
										defaultMessage="Error !"/>,
								level: 'error',
								message: "파일의 컴럼내용이 올바르지 않습니다.",
						};

						const notificationFileType ={
								title: <FormattedMessage
										id="dashboard.Error"
										defaultMessage="Error !"/>,
								level: 'error',
								message: "파일 형식이 올바르지 않습니다.",
						};

						const notificationProcessError ={
								title: <FormattedMessage
										id="dashboard.Error"
										defaultMessage="Error !"/>,
								level: 'error',
								message: "파일을 읽는 도중 에러 발생.",
						};


						readToExcel(this.state.file,(dataurl)=>  {

								console.log('this.state.uploadFirst:',this.state.uploadFirst);

								console.log("file:",this.state.file);
								console.log("dataurl:",dataurl);
								if(dataurl.error){
										if (!this.state.uploadFirst) {
												return false;
										}
										return this.addNotification(notificationFileType);
								}

								if(dataurl){ // onProcessedCallback return { columns, data}
										let columnCallList = [
												'상담일시',//call_date
												'상담구분',//call_check
												'고객이름',//call_cust_name
												'상담전화',// call_tel
												'일반전화',// call_cust_tel
												'휴대전화',// call_cust_hp
												'우편번호', //ma buu chinh + call_cust_address
												'주소',// call_cust_address
												'관리지역', // khu vuc quan li
												'특기사항', // note
												'상담자', //  tu van vien
												'회선 정보', // line_info
												'고객정보', // call_cust_info
												'수신동의', // call_agree
										];
										let resultExcel = dataurl.data;
										let columnExcel = dataurl.columns;

										console.log('resultExcel:', resultExcel);
										console.log('columnExcel:', columnExcel);

										// push infoMast vao columnCallList de check cac truong
										infoMastOptions.map(item => {
												if (item.m_show > 0) columnCallList.push(item.m_name);
										})
										// let checkCallList = true; // bien nay dung de check columnCallList co trong excel hay khong co thi qua khong co thi phanh'
										// columnCallList.map(column => {
										//     if(!_.includes(columnExcel, column)){
										//         checkCallList = false
										//     }
										// })
										//
										// let checkInfoMast = true; // bien nay dung de check infoMast neu co du infoMast trong file excel thi qua khong du thi phanh nha'
										// infoMastOptions.map(infoMast => {
										//     if(!_.includes(columnExcel, infoMast.m_name)){
										//         checkInfoMast = false
										//     }
										// })

										// if(!checkCallList || !checkInfoMast){// du lieu ecel khong khop voi columnCallList
										//     return this.addNotification(notificationError);
										// }
										// console.log(districts,'districts')



										let checkExcel = this.areEqual(columnCallList,columnExcel)
										if(!checkExcel){
												return this.addNotification(notificationCheckColumn);
										}

										console.log('resultExcel.lengthA:', resultExcel.length);

										if(resultExcel.length > 0) {
												//resultExcel.splice(resultExcel.length -1,1)// xoa phan tu mang
												// format lai
												console.log('resultExcel.lengthB:', resultExcel.length);
												resultExcel.map(item => {
														console.log('상담일시A:', item["상담일시"]);
														console.log('상담일시B:', moment(item["상담일시"]).format('YYYY-MM-DD HH:mm:ss'));
														item["상담일시"] = item["상담일시"] ? moment(item["상담일시"]).format('YYYY-MM-DD HH:mm:ss') : ''; // format gio
														console.log('상담일시C:', item["상담일시"]);
														//console.log('상담일시D:', item["상담일시"].format("YYYY-MM-DD HH:mm:ss"));
														item["상담전화"] = item["상담전화"] ? item["상담전화"].replaceAll("-","") : ''; // replace phone
														item["일반전화"] = item["일반전화"] ? item["일반전화"].replaceAll("-","") : ''; // replace phone
														item["휴대전화"] = item["휴대전화"] ? item["휴대전화"].replaceAll("-","") : ''; //replace phone
														item['infoMast'] = {}
														Object.keys(item).map(function (key) {
																infoMastOptions.map(infoMast => {
																		if(_.includes(key, infoMast.m_name)){
																				item['infoMast'] = {
																						...item['infoMast'],
																						[infoMast.m_id] : item[key]
																				};
																		}
																})
														})
														item['상담구분'] = item['상담구분'] == '받기' ? 0 : item['상담구분'] == '걸기' ? 1 : 2;
														item['수신동의'] = item['수신동의'] == '동의함' ? 0 : item['수신동의'] == '동의안함' ? 1 : 2;
												})
													
												console.log(resultExcel, 'resultExcelB:');

												// change name callList
												//
												// '상담일시',//call_date
												//     '상담구분',//call_check
												//     '고객이름',//call_cust_name
												//     '상담전화',// call_tel
												//     '일반전화',// call_cust_tel
												//     '휴대전화',// call_cust_hp
												//     '주소',// call_cust_address
												resultExcel.map(change => {
														//change['call_date'] = change['상담일시'].format("YYYY-MM-DD HH:mm:ss");
														change['call_date'] = change['상담일시'];
														change['call_check'] = change['상담구분'];
														change['call_cust_name'] = change['고객이름'];
														change['call_tel'] = change['상담전화'];
														change['call_cust_tel'] = change['일반전화'];
														change['call_cust_hp'] = change['휴대전화'];
														change['call_cust_address'] = change['우편번호'] ? change['우편번호'] + ' ' + change['주소'] : change['주소']; //'우편번호', //ma buu chinh + call_cust_address
														change['call_desc'] = change['특기사항'];

														change['usr_name'] = change['상담자'];

														change['call_cust_dist'] = ''
														if(districts.length > 0) {
																districts.map((district) => {
																		if(district.dist_name == change['관리지역']){ // khu vuc quan li
																				change['call_cust_dist'] = district.dist_id
																		}
																})
														}
														change['call_line_info'] = change['회선 정보'];
														change['call_cust_info'] = change['고객정보'];
														change['call_agree'] = change['수신동의'];


														delete change['상담일시'];
														delete change['상담구분'];
														delete change['고객이름'];
														delete change['상담전화'];
														delete change['일반전화'];
														delete change['휴대전화'];
														delete change['주소'];
														delete change['특기사항'];
														delete change['상담자'];// tu van vien.
														delete change['회선 정보'];// tu van vien.
														delete change['고객정보'];// tu van vien.
														delete change['수신동의'];// tu van vien.

												})
												console.log(resultExcel,'resultExcelC');

												// xử lí infomast details  không có trong DB, nếu không có thì tạo ra.
												// console.log(infoMastOptions,'infoMastOptions')
												let infoMastDetailNotHaveDb = [];
												resultExcel.map(mast => {
														Object.keys(mast.infoMast).map(function (key) {
																let find = _.find(infoMastOptions, function(o) { return o.m_id == key; }); // tim
																// neu co find va  mast.infoMast[key] thi vo day
																if(find && mast.infoMast[key]) {
																		let findDetail = _.find(find.info_detail, function(o) { return o.m_dtl_name == mast.infoMast[key]; }); // tim ten cua infomast detail
																		if(!findDetail){// neu khong co thi luu vo mang
																				infoMastDetailNotHaveDb.push({
																						[find.m_id] : mast.infoMast[key]
																				})
																		}

																}
														})
												})

												console.log(resultExcel,'resultExcelD');

												console.log(infoMastDetailNotHaveDb,'infoMastDetailNotHaveDb');
												
												if(infoMastDetailNotHaveDb.length > 0) {
														infoMastDetailNotHaveDb = _.uniqWith(infoMastDetailNotHaveDb, _.isEqual); // remove duplicate
												}

												let data = {
														infoMastDetailNotHaveDb,
														resultExcel
												}
												console.log(data,'data');
												progressBar.start();
												api.customerManagement.importExcelCustomer(data).then(res => {
														console.log(res,'res')
														this.toggleModalUploadExcel();
														
 													  this.setState({
																...this.state,
																// resultSearch: response.data,
										            uploadFirst: true,
																notification: {
																		allowHTML: false,
																},
																file: null
														}, () => {
																this.getCustomerDataFunc();

																this.addNotification({
																		title: <FormattedMessage
																				id="dashboard.Succeed"
																				defaultMessage="Succeed !"/>,
																		message: <FormattedMessage
																				id="dashboard.ImportExcel.Succeed"
																				defaultMessage="Import excel successfully!"/>,
																		level: 'success',
																})
														})
														progressBar.done();
												}).catch(e => {
														progressBar.done();
														this.addNotification(notificationProcessError);
												})

										}
								}
						})							
							
        })							

    }

    toggleModalUploadExcel = () => {
        this.setState({
            ...this.state,
            infoDetailForm: {},
						uploadFirst: true,
            openModalUploadExcel: !this.state.openModalUploadExcel,
        }, () => {
        });
    };

    openDeleteModal = () => {
        this.setState({
            isDeleteModalOpen: !this.state.isDeleteModalOpen
        });
    };

    openDeleteConfirmModal = () => {
        this.setState({
            isDeleteConfirmModalOpen: !this.state.isDeleteConfirmModalOpen
        });
    };

    deleteCustomerHandler = (e) => {
        e.preventDefault();
        
        // convert all element one by one array to number
        let {callIDGroups} = this.state;
        callIDGroups = callIDGroups.map(group => {
            let tmp = group.split(",");                
            return tmp.map(item => {
                return parseInt(item);
            })
        })

        // get all call_ids in group_call_id matching
        let selections = this.state.selection;
        // let selections = [73108, 72449];
        let callIDs = [];
        selections.map(callID => {
            let dataGroup = callIDGroups.find(group => {
                if (group.includes(callID)) {
                    return group
                }
            })
            if (dataGroup) {
                callIDs = [...callIDs, ...dataGroup]
            }
        })

        console.log(callIDs, 'callIDs');


        let cond = {
            // call_id: this.state.selection
            call_id: callIDs
        }
        let query = queryString.stringify(cond, {arrayFormat: 'bracket'});

        progressBar.start();
        this.props.onDeleteCustomer(query).then(response => {
            // check and re-set current page if delete last item of last page
            let {resultSearch, selection} = this.state; 
            let current_page = (resultSearch.from + (selection.length) - 1) == resultSearch.to
                            ? resultSearch.last_page - 1
                            : resultSearch.current_page;

            this.setState({
                ...this.state,
                selection: [],
                isDeleteConfirmModalOpen: false,
                notification: {
                    allowHTML: false,
                },
                resultSearch: {
                    ...this.state.resultSearch,
                    current_page,
                }
            }, () => {
                this.getCustomerDataFunc();

                this.addNotification({
                    title: <FormattedMessage
                        id="dashboard.Succeed"
                        defaultMessage="Succeed!"/>,
                    message: <FormattedMessage
                        id="dashboard.Customer.DeleteSucceed"
                        defaultMessage="Delete customer successfully!"/>,
                    level: 'success',
                })
                progressBar.done();
            })
        }).catch(error => {
            progressBar.done();
        });
    }

    componentWillUnmount(){
        this.props.onUnmountCallList();
        this.props.onUnmountInfoMast();
        this.props.onUnmountInfoDetail();
    }

		// 추가된 날짜 입력 핸들러
    changeStartDateHandler = (startDate) => { 
        this.changeHandler({ startDate });
    };

    changeEndDateHandler = (endDate) => {
        this.changeHandler({ endDate })
    };

    changeHandler = ({startDate, endDate}) => {
        startDate = startDate || this.state.cond.startDate;
        endDate = endDate || this.state.cond.endDate;

        if (startDate !== null && startDate.isAfter(endDate)) {
            endDate = startDate
        }
        this.setState({
            ...this.state,
            cond: {
                ...this.state.cond,
                startDate,
                endDate,
            }
        })
    };

    selectCallListType = (event) => {
        let { value } = event.target;
        this.setState({
            ...this.state,
           cond: {
               ...this.state.cond,
               callListType: value,
           }
        }, () => {
        })
    }


    render() {
        const {
            resultSearch,
            selection,
            checkAllTable,
            infoDetails,
            dataTypes,
            cond,
            columns,
            infoMastOptions,
            infoLines,
            downloading,
        } = this.state;

        return (
            <Fragment>
                <NotificationSystem ref={this.notificationSystem} allowHTML={this.state.notification.allowHTML}/>

                <FormattedMessage id="dashboard.CustomerData"
                    defaultMessage="CustomerData">
                    {message => <Helmet>
                        <title>{message}</title>
                    </Helmet>}
                </FormattedMessage>

                <div className="main_top_bar static_bar">
                    <div className="box_sum_col_top">
                        <div className="box_left">
                            <ul className="nav nav-tabs nav_tabs">
                                <li className="nav_item nav-item">
                                    <NavLink
                                        to={`/${routerName.DASHBOARD}/${routerName.CUSTOMER_MANAGEMENT}/${routerName.COUNSELLING_DATA}`}
                                        className="nav_link nav-link"
                                        activeClassName="active">
                                        <FormattedMessage
                                            id="dashboard.CounsellingData"
                                            defaultMessage="Counselling Data" />
                                    </NavLink>
                                </li>
                                <li className="nav_item nav-item">
                                    <NavLink
                                        to={`/${routerName.DASHBOARD}/${routerName.CUSTOMER_MANAGEMENT}/${routerName.CUSTOMER_DATA}`}
                                        className="nav_link nav-link"
                                        activeClassName="active">
                                        <FormattedMessage
                                            id="dashboard.CustomerData"
                                            defaultMessage="Customer data" />
                                    </NavLink>
                                </li>
                                <li className="nav_item nav-item">
                                    <NavLink
                                        to={`/${routerName.DASHBOARD}/${routerName.CUSTOMER_MANAGEMENT}/${routerName.IVR_DATA}`}
                                        className="nav_link nav-link"
                                        activeClassName="active">
                                        <FormattedMessage
                                            id="dashboard.IVRData"
                                            defaultMessage="IVR Data" />
                                    </NavLink>
                                </li>
                            </ul>
                        </div>

                        <div className="box_center">
                            <div className="tabs_static_item">

                                <div className="main_control_time_select">

                                    <div className="filler_to_from">
																		</div>
                                    <div className="main_select main_box margin_left_50">
                                        <div className="main_label">
                                            <FormattedMessage id="dashboard.Type" defaultMessage="Type" />
                                        </div>
                                        <div className="custom_select">
                                            <select className="form_control"
                                                name="call_check"
                                                onChange={this.selectCallListType}
                                                value={this.state.cond.callListType}>
                                                {
                                                    callListTypes?.map((item, index) => {
                                                        return (
                                                            <option key={index} value={item.value}>
                                                                {this.props.intl.formatMessage(item.text)}
                                                            </option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            <span className="item_dropdown" />
                                        </div>
                                        <div className="control_btn">
                                            <button className="btn_control btn_002" 
                                                disabled={!this.state.cond.endDate}
                                                onClick={() => this.handleSearch()}
                                            >
                                                <FormattedMessage id="dashboard.Search" defaultMessage="Search" />
                                            </button>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main_content">
                    <div className="content_tabs_main content_static_tabs tab-content">
                        <div className="tabs_static_item tabs_item1 tab-pane fade show  active " id="static1">
                            <div className="box_content_static">

                                {/* BOX CONDITION SEARCH */}
                                <div className="left_col">
                                    <div className="heading title_left">
                                        <h3>
                                            <FormattedMessage id="dashboard.SearchFilters" defaultMessage="Search Filters"/>
                                        </h3>
                                    </div>
                                    <div className="box_count">
                                        <div className="heading">
                                            <FormattedMessage id="dashboard.CustomerName" defaultMessage="CustomerName"/>
                                        </div>
                                        <div className="content">
                                            <div className="custom_group_colum">
                                                <div className="group_item">
                                                    <input type="text"
                                                        name="name"
                                                        value={cond.name}
                                                        onChange={this.onChangeValueForm}
                                                        className="input_group form_control"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>{/* box_count */}
                                    <div className="box_count">
                                        <div className="heading">
                                            <FormattedMessage id="dashboard.LineInformation" defaultMessage="LineInformation"/>
                                        </div>
                                        <div className="content">
                                            <div className="custom_group_colum">
                                                <div className="group_item select_item">
                                                <select className="form_control" onChange={this.onChangeInfoLine} name="call_line_info" value={cond.call_line_info}>
                                                    <option value="">
                                                        {this.props.intl.formatMessage({id: 'dashboard.All',defaultMessage: 'All'})}
                                                    </option>
                                                    {
                                                        infoLines?.map((item, index) => {
                                                            return (
                                                                <option key={index} value={item.line_no} >{item.line_info}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                                    <span className="item_dropdown" />
                                                </div>
                                            </div>



                                            {/* <div className="custom_group_colum">
                                                <div className="group_item">
                                                    <input type="text"
                                                        name="call_line_info"
                                                        value={cond.call_line_info}
                                                        onChange={this.onChangeValueForm}
                                                        className="input_group form_control"/>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>{/* box_count */}
                                    <div className="box_count">
                                        <div className="heading">
                                            <FormattedMessage id="dashboard.Items" defaultMessage="Items"/>
                                        </div>
                                        <div className="content">
                                            <div className="custom_group_colum">
                                                <div className="group_item select_item">
                                                <select className="form_control" onChange={this.onChangeInfoMast} name="info_mast" value={cond.info_mast}>
                                                    <option value="">
                                                        {this.props.intl.formatMessage({id: 'dashboard.All',defaultMessage: 'All'})}
                                                    </option>
                                                    {
                                                        infoMastOptions?.map((item, index) => {
                                                            return (
                                                                <option key={index} value={item.m_id} data-info={JSON.stringify(item)}>{item.m_name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                                    <span className="item_dropdown" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>{/* box_count */}
                                    <div className="box_count">
                                        <div className="heading">
                                            <FormattedMessage id="dashboard.Address" defaultMessage="Address"/>
                                        </div>
                                        <div className="content">
                                            <div className="custom_group_colum">
                                                <div className="group_item">
                                                    <input type="text"
                                                        name="address"
                                                        value={cond.address}
                                                        onChange={this.onChangeValueForm}
                                                        className="input_group form_control"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>{/* box_count */}
                                    <div className="box_count">
                                        <div className="heading">
                                            <FormattedMessage id="dashboard.SubItems" defaultMessage="SubItems"/>
                                        </div>
                                        <div className="content">
                                            <div className="custom_group_colum">
                                                <div className="group_item select_item">
                                                    <select className="form_control" onChange={this.onChangeValueForm} name="info_detail" value={cond.info_detail}>
                                                        <option value="">
                                                            {this.props.intl.formatMessage({id: 'dashboard.All',defaultMessage: 'All'})}
                                                        </option>
                                                        {
                                                            infoDetails?.map((item, index) => {
                                                                return (
                                                                    <option key={index} value={item.m_dtl_id}>{item.m_dtl_name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <span className="item_dropdown" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>{/* box_count */}
                                    <div className="box_count">
                                        <div className="heading">
                                            <FormattedMessage id="dashboard.ReceivedPhone" defaultMessage="ReceivedPhone"/>
                                        </div>
                                        <div className="content">
                                            <div className="custom_group_colum">
                                                <div className="group_item">
                                                    <input type="text"
                                                        name="phone"
                                                        value={cond.phone}
                                                        onChange={this.onChangeValueForm}
                                                        className="input_group form_control"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>{/* box_count */}
                                    <div className="box_count">
                                        <div className="heading">Data type</div>
                                        <div className="content">
                                            <div className="custom_group_colum">
                                                <div className="group_item select_item">
                                                <select className="form_control" onChange={this.onChangeValueForm} name="rec_system" value={cond.rec_system}>
                                                    {
                                                        dataTypes?.map((item, index) => {
                                                            return (
                                                                <option key={index} value={item.rec_system}>{item.title}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                                    <span className="item_dropdown" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>{/* box_count */}

                                    <div className="box_count">
                                        <div className="heading"> <FormattedMessage id="dashboard.Type" defaultMessage="Type" /></div> 
                                        <div className="content">
                                            <div className="custom_group_colum">
                                                <div className="group_item select_item">
                                                {/* <select className="form_control" onChange={this.onChangeValueForm} name="rec_system" value={cond.rec_system}>
                                                    {
                                                        dataTypes?.map((item, index) => {
                                                            return (
                                                                <option key={index} value={item.rec_system}>{item.title}</option>
                                                            )
                                                        })
                                                    }
                                                </select> */}

                                                <select className="form_control"
                                                    name="callListType"
                                                    onChange={this.onChangeValueForm}
                                                    value={cond.callListType}>
                                                    {
                                                        callListTypes?.map((item, index) => {
                                                            return (
                                                                <option key={index} value={item.value}>
                                                                    {this.props.intl.formatMessage(item.text)}
                                                                </option>
                                                            )
                                                        })
                                                    }
                                                </select>

                                                    <span className="item_dropdown" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>{/* box_count */}
                                    <div className="box_count list_btn left">
                                        <button className="btn_custom" onClick={this.handleSearch}>
                                            <FormattedMessage id="dashboard.Search" defaultMessage="Search"/>
                                        </button>
                                    </div>
                                </div>
                                                        
                                {/* BOX MAIN SEARCH */}
                                <div className="content_col">
                                    <div className="row_table_custom">
                                        <div className="content">
                                            <div className="card_heading">
                                                <ul className="list_custom_btn">
                                                    <li>
                                                        <button 
                                                            type="button"
                                                            className="btn_control btn_001"
                                                            onClick={this.openDeleteModal}
                                                            disabled={selection.length == 0}
                                                        >
                                                            <FormattedMessage id="dashboard.Delete" defaultMessage="Delete"/>
                                                        </button>
                                                    </li>
                                                    <li>
                                                        <button 
                                                            type="button"
                                                            className="btn_control btn_002"
                                                            onClick={this.toggleModalUploadExcel}
                                                        >
                                                            <FormattedMessage id="dashboard.UploadExcel" defaultMessage="UploadExcel"/>
                                                        </button>
                                                    </li>
                                                    <li>
                                                        <button type="button" className="btn_control btn_002" onClick={this.onSampleDownload}>
                                                            <FormattedMessage id="dashboard.SampleDownload" defaultMessage="SampleDownload"/>
                                                        </button>
                                                    </li>
                                                    <li>
                                                        <button 
                                                            type="button"
                                                            className="btn_control btn_002"
                                                            onClick={this.onExport}
                                                            disabled={ downloading ? true : false}
                                                        >
                                                            <FormattedMessage id="dashboard.ExcelDownload" defaultMessage="ExcelDownload"/>
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="control_box_item">
                                                <div className="card_header">
                                                    <div className="box_search">
                                                        <div className="box_control_input ">
                                                            <input type="text"
                                                                name="sub_search"
                                                                value={cond.sub_search}
                                                                onChange={this.handleSubSearch}
                                                                className="control"/>
                                                            <button className="btn_form btn_secondary">
                                                                <i className="fas fa-search" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card_body">
                                                    <div className="custom_table">
                                                        <table className="table st_table_5">
                                                            <thead>
                                                                <tr>
                                                                    <th className="align_center">
                                                                        <input
                                                                            name="all"     
                                                                            type="checkbox"
                                                                            checked={ checkAllTable }
                                                                            onChange={this.handleCheckAllTable}
                                                                            // onClick={e => e.stopPropagation()}
                                                                        />
                                                                    </th>
                                                                    {
                                                                        columns.map((column, index) => {      
                                                                            return (
                                                                                <th key={index} style={{width:''}}>
                                                                                    {column.title}
                                                                                </th>
                                                                            );
                                                                        })
                                                                    } 


                                                                    {/* <th><FormattedMessage id="dashboard.Customer_Name" defaultMessage="Name" /></th>
                                                                    <th><FormattedMessage id="dashboard.ReceivedPhone" defaultMessage="ReceivedPhone" /></th>
                                                                    <th><FormattedMessage id="dashboard.MobilePhone" defaultMessage="MobilePhone" /></th>
                                                                    <th><FormattedMessage id="dashboard.LineInformation" defaultMessage="LineInformation" /></th>
                                                                    <th><FormattedMessage id="dashboard.Telephone" defaultMessage="Telephone" /></th>
                                                                    <th><FormattedMessage id="dashboard.Address" defaultMessage="Address" /></th> */}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                            {/* render customerData list */}

                                                            {
                                                                resultSearch?.data?.length > 0 && resultSearch?.data?.length > 0 ?
                                                                    resultSearch.data.map((itemCustomer, index) => {
                                                                        // console.log(itemCustomer, 'itemCustomer');
                                                                        return (
                                                                            <tr key={index} onClick={() => this.selectRowTable(itemCustomer)}>
                                                                                <td className="align_center" style={{width: '12px'}} >
                                                                                    <input type="checkbox" 
                                                                                        checked={ selection.includes(itemCustomer.call_id) ? true : false }
                                                                                        ref={(input) => this[`checkbox_${itemCustomer.call_id}`] = input}
                                                                                        onChange={(e) => this.handleClickItemCheckbox(e, itemCustomer)}
                                                                                        onClick={e => e.stopPropagation()}
                                                                                    />
                                                                                </td>                                                                         
                                                                                
                                                                                {/* render value column matching one by one TITLE COLUMN */}
                                                                                {
                                                                                    // columns: arr List columns with multi languages
                                                                                    // columnOptions: arr List column name IS Checked
                                                                                    columns.map((column, col_index) => {
                                                                                        // if (columnOptions.includes(column.name)) {
                                                                                            if (column.name == 'call_line_info') {
                                                                                                return (
                                                                                                    <td key={col_index}>
                                                                                                        {itemCustomer?.info_line?.line_info}
                                                                                                        {/* { itemCustomer.call_line_info } */}
                                                                                                    </td>
                                                                                                )
                                                                                            }

                                                                                        if (column.name == 'call_check') {
                                                                                            return (
                                                                                                <td key={col_index}>
                                                                                                    { this.fixedFormatter(itemCustomer.call_check) }
                                                                                                </td>
                                                                                            )
                                                                                        }                                                                                        

                                                                                        if (column.name == 'call_agree') {
                                                                                            return (
                                                                                                <td key={col_index}>
                                                                                                    {
                                                                                                        itemCustomer.call_agree == '0'
                                                                                                            ? <FormattedMessage id="dashboard.Agree" defaultMessage="Agree"/>
                                                                                                            : <FormattedMessage id="dashboard.Disagree" defaultMessage="Disagree"/>
                                                                                                    }
                                                                                                </td>
                                                                                            )
                                                                                        }

                                                                                            // check and get Value CALL_DETAIL matching with COLUMN
                                                                                            let infoMast = helpers.findObjectInArray(infoMastOptions, column.name, 'm_name');
                                                                                            if (infoMast) {
                                                                                                let matchCallDetail = helpers.findObjectInArray(itemCustomer.call_detail2, infoMast.m_id, 'call_m_id');
                                                                                                if (matchCallDetail) {
                                                                                                    return (
                                                                                                        <td key={col_index}>
                                                                                                            {matchCallDetail.call_dtl_name}  
                                                                                                            {/* <br></br>-{itemCustomer.call_id}  */}
                                                                                                            {/* <br></br>-{itemCustomer.call_date} */}
                                                                                                        </td>
                                                                                                    )
                                                                                                } else { 
                                                                                                    return (
                                                                                                        <td key={col_index}></td>
                                                                                                    )
                                                                                                }
                                                                                            }

                                                                                            return (
                                                                                                <td key={col_index}>
                                                                                                    {/* <FormattedMessage id="dashboard.Date" defaultMessage="Date"/> */}
                                                                                                    { helpers.limitString(itemCustomer[`${column.name}`], 20) }
                                                                                                </td>
                                                                                            );
                                                                                        // }
                                                                                    })
                                                                                } 
                                                                            </tr>
                                                                        )
                                                                    }) :
                                                                    (
                                                                        <tr>
                                                                            <td colSpan={columns.length + 1}>
                                                                                <div className="nodata">No Data</div>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                            }




                                                            {/* { 
                                                                resultSearch?.data?.length > 0 ? 
                                                                    resultSearch.data.map((item, index) => {
                                                                        return (
                                                                            <tr key={index} onClick={() => this.selectRowTable(item)} >
                                                                                <td className="align_center">
                                                                                    <input type="checkbox" 
                                                                                        checked={ selection.includes(item.call_id) ? true : false }
                                                                                        ref={(input) => this[`checkbox_${item.call_id}`] = input}                                                                                       
                                                                                        onChange={(e) => this.handleClickItemCheckbox(e, item)}
                                                                                        onClick={e => e.stopPropagation()}
                                                                                    />
                                                                                </td>   
                                                                                <td>{item.call_cust_name}</td>
                                                                                <td>{item.call_tel}</td>        
                                                                                <td>{item.call_cust_hp}</td>
                                                                                <td>{item.call_line_info}</td>
                                                                                <td>{item.call_cust_tel}</td>
                                                                                <td>{item.call_cust_address}</td>
                                                                            </tr>
                                                                        )
                                                                    }) :
                                                                    (
                                                                        <tr>
                                                                            <td colSpan={7}>
                                                                                <div className="nodata">No Data</div>
                                                                            </td>
                                                                        </tr> 
                                                                    )
                                                            } */}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                

                                                <div className="car_footer btw">
                                                <div className="txt_sum_col">
                                                    <strong> <FormattedMessage id="dashboard.TotalCount" defaultMessage="TotalCount" /> </strong> : {resultSearch?.total || 0}
                                                </div>
                                                {
                                                    resultSearch?.data?.length > 0 && (
                                                        <nav aria-label="...">
                                                            <ul className="custom_pagination pagination">
                                                                <Pagination
                                                                    itemClass="page-item"
                                                                    linkClass="page-link"
                                                                    activePage={resultSearch?.current_page}
                                                                    itemsCountPerPage={resultSearch?.per_page}
                                                                    totalItemsCount={resultSearch?.total || 0}
                                                                    pageRangeDisplayed={10}
                                                                    onChange={this.changePage}
                                                                />
                                                            </ul>
                                                        </nav>
                                                    )
                                                }
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <UploadExcelModal
                    isOpen = {this.state.openModalUploadExcel}
                    toggle = {this.toggleModalUploadExcel}
                    currentFile = {this.state.file}
                    handleChangeFile = {this.handleChangeFile}
                    onUploadExcel = {this.onUploadExcel}
										uploading = {this.state.uploading}
                />

                <DeleteConfirmModal
                    isDeleteModalOpen={this.state.isDeleteModalOpen}
                    openDeleteModal={this.openDeleteModal}
                    isDeleteConfirmModalOpen={this.state.isDeleteConfirmModalOpen}
                    openDeleteConfirmModal={this.openDeleteConfirmModal}
                    deleteInfoHandler={this.deleteCustomerHandler}
                />
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        // resultSearch: state.calls,
        isChangeScene: state.sceneSelect.isChangeScene,
        sceneCompanyId: state.sceneSelect.company_id,
        sceneLostId: state.sceneSelect.lots_id,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onSampleDownload: (data) => dispatch(sampleDownloadExcelAction(data)),
        importData: (data) => dispatch(importCallListAction(data)),
        // onExportCustomerData: (query) => dispatch(exportCallListAction(query)),

        onSearchForm: (query) => dispatch(searchByNameAction(query)),
        // onFetchInfoMast: (id) => dispatch(fetchInfoMastsAction(id)),
        // onFetchCustomerList: (query) => dispatch(fetchCustomerListAction(query)),
        // onFetchInfoDetailByInfoMast: (id = 0) => dispatch(fetchInfoDetailByInfoMastAction(id)),
        onDeleteCustomer: (query) => dispatch(deleteCustomerAction(query)),

        onUnmountCallList: () => dispatch(unmountCallListAction()),
        onUnmountInfoMast: () => dispatch(unmountInfoMastAction()),
        onUnmountInfoDetail: () => dispatch(unmountInfoDetailAction()),
        importExcelCustomer : (data) => dispatch(importExcelCustomerAction(data)),
    }
};


export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CustomerData));
