import React from 'react';
import {Modal} from "reactstrap";
import {formatNumberPhone} from "../../../utils/helpers";

function ModalLastAccess (props) {

    const {
        isOpen,
        toggle,
    } = props;


    return(
        <Modal
            isOpen={isOpen}
            toggle={()=>toggle()}
            className={'login_duplicate'}
            backdrop={"static"}
            // wrapClassName={'modal_listen_play custom_modal_sum'}
        >
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">중복 로그인</h5>
                    <button
                        type="button"
                        className="close"
                        onClick={()=>toggle()}
                    >
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <span>해당 계정은 다른 데에 로그인되어 있습니다.</span>
                </div>
            </div>
        </Modal>
    )
}

export default ModalLastAccess;
