import {
    FETCH_COUNSELOR_BY_SALE_LOT,
    FETCH_COUNSELOR_BY_SALE_LOT_SUCCEED,
    FETCH_COUNSELOR_BY_SALE_LOT_FAILED,
    CREATE_USER,
    CREATE_USER_SUCCEED,
    CREATE_USER_FAILED,
    UPDATE_USER,
    UPDATE_USER_SUCCEED,
    UPDATE_USER_FAILED,
    DELETE_USER,
    DELETE_USER_SUCCEED,
    DELETE_USER_FAILED, UNMOUNT_USERS,
    FETCH_USERS_BY_SALE_LOT,
    FETCH_USERS_BY_SALE_LOT_SUCCEED,
    FETCH_USERS_BY_SALE_LOT_FAILED
} from '../actions/actionTypes';
import api from "../../utils/api";

export const fetchCounselorBySaleLot = () => {
    return {
        type: FETCH_COUNSELOR_BY_SALE_LOT
    }
};

export const fetchCounselorBySaleLotSucceed = data => {
    return {
        type: FETCH_COUNSELOR_BY_SALE_LOT_SUCCEED,
        payload: {
            data: data
        }
    }
};

export const fetchCounselorBySaleLotFailed = error => {
    return {
        type: FETCH_COUNSELOR_BY_SALE_LOT_FAILED,
        payload: {
            error: error
        }
    }
};

export const createUser = () => {
    return {
        type: CREATE_USER
    }
};

export const createUserSucceed = user => {
    return {
        type: CREATE_USER_SUCCEED,
        payload: {
            user
        }
    }
};

export const createUserFailed = error => {
    return {
        type: CREATE_USER_FAILED,
        payload: {
            error: error
        }
    }
};

export const updateUser = () => {
    return {
        type: UPDATE_USER
    }
};

export const updateUserSucceed = user => {
    return {
        type: UPDATE_USER_SUCCEED,
        payload: {
            user
        }
    }
};

export const updateUserFailed = error => {
    return {
        type: UPDATE_USER_FAILED,
        payload: {
            error: error
        }
    }
};

export const deleteUser = () => {
    return {
        type: DELETE_USER
    }
};

export const deleteUserSucceed = user => {
    return {
        type: DELETE_USER_SUCCEED,
        payload: {
            user
        }
    }
};

export const deleteUserFailed = error => {
    return {
        type: DELETE_USER_FAILED,
        payload: {
            error: error
        }
    }
};

export const unmountUsers = () => {
    return {
        type: UNMOUNT_USERS
    }
};


export const fetchUsersBySaleLot = () => {
    return {
        type: FETCH_USERS_BY_SALE_LOT
    }
};

export const fetchUsersBySaleLotSucceed = data => {
    return {
        type: FETCH_USERS_BY_SALE_LOT_SUCCEED,
        payload: {
            data: data
        }
    }
};

export const fetchUsersBySaleLotFailed = error => {
    return {
        type: FETCH_USERS_BY_SALE_LOT_FAILED,
        payload: {
            error: error
        }
    }
};

/**
 * Fetch Counselor by info sale lot
 */
export const fetchCounselorByInfoSaleLotAction = (infoSaleLotId = null) => dispatch => {
    dispatch(fetchCounselorBySaleLot());
    return api.user.getAllUsersBySaleLot(infoSaleLotId)
        .then(response => {
            dispatch(fetchCounselorBySaleLotSucceed(response.data.data));
            return Promise.resolve(response.data.data);
        })
        .catch(error => {
            dispatch(fetchCounselorBySaleLotFailed(
                error.response.data.errors.message));
            return Promise.reject(error.response)
        });
};


export const unmountUsersAction = () => dispatch => {
    dispatch(unmountUsers())
};

/**
 * Create user
 */
export const createUserAction = user => dispatch => {
    dispatch(createUser());
    return api.user.createUser(user)
        .then(response => {
            dispatch(createUserSucceed(response.data.data));
            return Promise.resolve(response.data);
        })
        .catch(error => {
            dispatch(createUserFailed(error.response.data.errors.message));
            return Promise.reject(error.response)
        });
};

/**
 * Update user
 */
export const updateUserAction = (id, user) => dispatch => {
    dispatch(updateUser());
    return api.user.updateUser(id, user)
        .then(response => {
            dispatch(updateUserSucceed(response.data.data));
            return Promise.resolve(response.data);
        })
        .catch(error => {
            dispatch(updateUserFailed(error.response.data.errors.message));
            return Promise.reject(error.response)
        });
};

/**
 * Delete user
 */
export const deleteUserAction = userId => dispatch => {
    dispatch(deleteUser());
    const id = {
        params: {
            usr_id: userId
        }
    };
    return api.user.deleteUser(id)
        .then(response => {
            dispatch(deleteUserSucceed(response.data.data));
            return Promise.resolve(response.data);
        })
        .catch(error => {
            dispatch(deleteUserFailed(error.response.data.errors.message));
            return Promise.reject(error.response)
        });
};

/**
 * Fetch users by info sale lot
 */
export const fetchUsersByInfoSaleLotAction = (infoSaleLotId = null) => dispatch => {
    dispatch(fetchUsersBySaleLot());
    return api.user.getUsersBySaleLot(infoSaleLotId)
        .then(response => {
            dispatch(fetchUsersBySaleLotSucceed(response.data.data));
            return Promise.resolve(response.data.data);
        })
        .catch(error => {
            dispatch(fetchUsersBySaleLotFailed(
                error.response.data.errors.message));
            return Promise.reject(error.response)
        });
};





