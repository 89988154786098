import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import store from './store/store';
// import { BrowserRouter as Router } from "react-router-dom";
import {BrowserRouter} from "react-router-dom";
import registerServiceWorker from './registerServiceWorker';
import {createStore, applyMiddleware} from 'redux';
import {Provider} from 'react-redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import reducer from './store/reducers';
import thunk from 'redux-thunk';
import { IntlProvider, addLocaleData } from 'react-intl'
// import en from 'react-intl/locale-data/en';
// import ko from 'react-intl/locale-data/ko';
import ConnectedIntlProvider from './utils/connectedIntlProvider';
import setAuthorizationHeader from './utils/setAuthorizationHeader';
import api from './utils/api';
import {authFetchCurrentUser} from './store/actions/auth';
import {setLocaleAction} from "./store/actions";
import {useRouteMatch} from 'react-router-dom';
import {changeSceneLotAction} from "./store/actions/sceneSelect";
// addLocaleData([...en, ...ko]);

export const store = createStore(reducer,
    process.env.NODE_ENV === 'production' ? applyMiddleware(thunk)
        : composeWithDevTools(applyMiddleware(thunk)));

//Set Default Lang
if (localStorage.lang) {
    process.env.NODE_ENV === 'development' ?
        store.dispatch(setLocaleAction(localStorage.lang)) :
        store.dispatch(setLocaleAction('ko'));
}
else {
    store.dispatch(setLocaleAction('ko'));
}
const lang = localStorage.lang;

// Remove console.log
function noop() {}
 if (process.env.NODE_ENV !== 'development') {
     //console.log = noop;
     //console.warn = noop;
     //console.error = noop;
 }

function getData(access = "yes") {
    let response = api.auth.getCurrentUser(access);
    return response;
}

function renderApp() {
    return ReactDOM.render(
        <Provider store={store}>
            <ConnectedIntlProvider>
                <BrowserRouter>
                    <App languages={lang}
                    />
                </BrowserRouter>
            </ConnectedIntlProvider>
        </Provider>
        , document.getElementById('root'));
    registerServiceWorker();
}

// Get Current User Info if Token exists
if (localStorage.token) {
    setAuthorizationHeader(localStorage.token);

    const queryString = window.location.search;

    const urlParams = new URLSearchParams(queryString);

    let access = urlParams.get('access')

    console.log(access,'access')

    if(!access){
        access = "yes"
    }

    getData(access).then(response => {
        if(response.data.data.token != localStorage.token){
            localStorage.setItem("token", response.data.data.token);
            setAuthorizationHeader(response.data.data.token);
        }

        const data = {
            token: response.data.data.token,
            user: response.data.data
        };

//				localStorage.setItem("menu_1", response.data.data.en_menu_1);
//				localStorage.setItem("menu_2", response.data.data.en_menu_2);
//				localStorage.setItem("menu_3", response.data.data.en_menu_3);
//				localStorage.setItem("menu_4", response.data.data.en_menu_4);
//				localStorage.setItem("change_manager", response.data.data.change_manager);
//				localStorage.setItem("see_history", response.data.data.see_history);

				const roles = response.data.data.roles;
        store.dispatch(authFetchCurrentUser(data));
        if(roles[0].name !== 'admin' && roles[0].name !== 'primarynet') {
            store.dispatch(changeSceneLotAction(response.data.data.lots_id))
        }
        renderApp();
    }).catch(() => {
        localStorage.removeItem('token');
        setAuthorizationHeader();
        renderApp();
    })
} else {
    renderApp();
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
