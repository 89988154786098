import {
    CHANGE_SCENE,
    CHANGE_SCENE_COMPANY,
    CHANGE_SCENE_LOT, CHANGE_TYPE_OF_CHANGE
} from "../type";

const initialState = {
    company_id: null,
    lots_id: null,
    isChangeScene: false,
    typeOfChange: null,
};

const sceneSelect = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_SCENE_COMPANY: {
            return {
                ...state,
                lots_id: null,
                company_id: action.payload,
            }
        }
        case CHANGE_SCENE_LOT: {
            return {
                ...state,
                lots_id: action.payload,
                company_id: null,
            }
        }
        case CHANGE_SCENE: {
            return {
                ...state,
                isChangeScene: action.payload
            }
        }
        case CHANGE_TYPE_OF_CHANGE: {
            return {
                ...state,
                typeOfChange: action.payload
            }
        }
        default: {
            return state;
        }

    }
}

export default sceneSelect;