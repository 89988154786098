import {LOCALE_SET} from "../actions/actionTypes";

const initialState = {
    lang: "en"
};

const appState = (state = initialState, action = {}) => {
    switch (action.type) {
        case LOCALE_SET:
            return {
                lang: action.payload.lang
            }
        default:
            return state;
    }
};

export default appState;