import {
    FETCH_TEXT_MANAGER,
    FETCH_TEXT_MANAGER_SUCCEED,
    FETCH_TEXT_MANAGER_FAILED,
    UNMOUNT_TEXT_MANAGER,
} from './actionTypes'

import api from '../../utils/api';

export const fetchTextManager = () => {
    return {
        type: FETCH_TEXT_MANAGER,
    }
};

export const fetchTextManagerSucceed = data => {
    return {
        type: FETCH_TEXT_MANAGER_SUCCEED,
        payload: {
            data: data
        }
    }
};

export const fetchTextManagerFailed = error => {
    return {
        type: FETCH_TEXT_MANAGER_FAILED,
        payload: {
            error: error
        }
    }
};

export const unmountTextManager = () => {
    return {
        type: UNMOUNT_TEXT_MANAGER
    }
};


export const fetchTextManagerAction = (id, params) => dispatch => {
    dispatch(fetchTextManager());
    return api.textManager.fetchTextManager(id, params)
        .then(response => {
            dispatch(fetchTextManagerSucceed(response.data));
            return Promise.resolve(response.data);
        })
        .catch(error => {
            dispatch(fetchTextManagerFailed(error.response.data.errors.message));
            return Promise.reject(error.response)
        });
};


export const unmountTextManagerAction = () => dispatch => {
    dispatch(unmountTextManager())
};


// Create TextManagerAction

export const createTextManagerAction = (data) => dispatch => {
    // dispatch(fetchTextManager());
    return api.textManager.creteTextManager(data)
        .then(response => {
            // dispatch(fetchTextManagerSucceed(response.data));
            return Promise.resolve(response.data);
        })
        .catch(error => {
            // dispatch(fetchTextManagerFailed(error.response.data.errors.message));
            return Promise.reject(error.response)
        });
};


// delete textManagerAction

export const deleteTextManagerAction = (id) => dispatch => {
    return api.textManager.deleteTextManager(id)
        .then(response => {
            return Promise.resolve(response.data);
        })
        .catch(error => {
            return Promise.reject(error.response)
        });
};

// Find text manager
export const findTextManagerAction = (id) => dispatch => {
    return api.textManager.findTextManager(id)
        .then(response => {
            return Promise.resolve(response.data.data);
        })
        .catch(error => {
            return Promise.reject(error.response)
        });
};

// Delete image detail
export const deleteImageDetailAction = (id) => dispatch => {
    return api.textManager.deleteImageDetail(id)
        .then(response => {
            return Promise.resolve(response.data.data);
        })
        .catch(error => {
            return Promise.reject(error.response)
        });
};


// Update

export const updateTextManagerAction = (id,data) => dispatch => {
    return api.textManager.updateTextManager(id,data)
        .then(response => {
            return Promise.resolve(response.data.data);
        })
        .catch(error => {
            return Promise.reject(error.response)
        });
};


// fetch auto sending

export const autoSendingAction = (id) => dispatch => {
    return api.textManager.fetchAutoSending(id)
        .then(response => {
            return Promise.resolve(response.data.data);
        })
        .catch(error => {
            return Promise.reject(error.response)
        });
};


export const createDirectAction = (data) =>dispatch => {
    return api.textManager.createDirect(data)
        .then(response => {
            return Promise.resolve(response.data.data);
        })
        .catch(error => {
            return Promise.reject(error.response)
        });
}

export const fetchDirectAction = () => dispatch => {
    return api.textManager.fetchDirect()
        .then(response => {
            return Promise.resolve(response.data.data);
        })
        .catch(error => {
            return Promise.reject(error.response)
        });
}


export const createMessageHistoryAction = (data) => dispatch => {
    return api.textManager.createMessageHistory(data)
        .then(response => {
            return Promise.resolve(response.data.data);
        })
        .catch(error => {
            return Promise.reject(error.response)
        });
}

export const removeOneImageAction = (id) => dispatch => {
    return api.textManager.removeOneImage(id)
        .then(response => {
            return Promise.resolve(response.data.data);
        })
        .catch(error => {
            return Promise.reject(error.response)
        });
}
// import excel

export const importExcelAction = (data) => dispatch => {
    return api.textManager.importExcel(data)
        .then(response => {
            return Promise.resolve(response.data.data);
        })
        .catch(error => {
            return Promise.reject(error.response)
        });
}



// get infoSaleLot


export const getInfoSaleLotAction = (company_id) => dispatch => {
    return api.textManager.getInfoSaleLot(company_id)
        .then(response => {
            return Promise.resolve(response.data.data);
        })
        .catch(error => {
            return Promise.reject(error.response)
        });
}
