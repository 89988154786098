import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Helmet } from 'react-helmet';
import DataSetting from './../DataSetting';
import NotificationSystem from 'react-notification-system';
import Pagination from "react-js-pagination";
import progressBar from '../../../utils/progressBar';
import api from "../../../utils/api";
import HasRole from "../../../utils/entrust/HasRole";
import {hasRole} from "../../../utils/entrust/entrust";
import {renderMessage} from '../../../utils/helpers';
import DeleteConfirmModal from '../../../components/DeleteConfirmModal/DeleteConfirmModal';
import CreateInfoMastModal from './../../../components/DataSetting/ItemManagement/CreateInfoMastModal';
import UpdateInfoMastModal from './../../../components/DataSetting/ItemManagement/UpdateInfoMastModal';
import CreateInfoDetailModal from './../../../components/DataSetting/ItemManagement/CreateInfoDetailModal';
import UpdateInfoDetailModal from './../../../components/DataSetting/ItemManagement/UpdateInfoDetailModal';
import {
    createInfoMastAction,
    updateInfoMastAction,
    deleteInfoMastAction,
    createInfoDetailAction,
    updateInfoDetailAction,
    deleteInfoDetailAction
} from "../../../store/actions";

class ItemManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            companies: [],
            currentCompany: {} ,
            currentInfoMast: {},
            currentInfoDetail: {},
            infoMastList: {},
            infoDetailList: {},
            infoMastForm: {},
            infoDetailForm: {},
            infoMastKeyword:'',
            infoDetailKeyword:'',
            deletingRow: null,
            deleteInfoMastOrInfoDetail: null,   // 0: Del Info Mast || 1: Del Info Detail

            openModalCreateInfoMast: false,
            openModalUpdateInfoMast: false,
            openModalCreateInfoDetail: false,
            openModalUpdateInfoDetail: false,
            isDeleteModalOpen: false,
            isDeleteConfirmModalOpen: false,

            // Notification
            notification: {           
                allowHTML: false
            },
        }
    }

    notificationSystem = React.createRef();
    addNotification = (data) => {
      const notification = this.notificationSystem.current;
      notification.addNotification({
        message: data.message,
        title: data.title,
        level: data.level,
        autoDismiss: data.autoDismiss || 4
      });
    }; 

    notificationSystemSimple = React.createRef();
    addNotificationSimple = (data) => {
      const notificationSimple = this.notificationSystemSimple.current;
      notificationSimple.addNotification({
        message: data.message,
        title: data.title,
        level: data.level,
        autoDismiss: data.autoDismiss || 4
      });
    };
    
    componentDidMount() {
        //if user role is primarynet then get list company selectbox
        const {user} = this.props.auth;
        if (hasRole(user, 'primarynet')) {
            this.getCompanies();
        }

        //if user role is admin then display items belong to currentCompany
        if (hasRole(user, 'admin')) {
            this.setState({
                ...this.state,
                currentCompany: {
                    company_id: user.company_id
                }
            }, () => {
                console.log(this.state.currentCompany, 'currentCompany1');
                this.getInfoMastByCompanyFunc();
            })
        }
    }

    selectCompanyHandler = (event) => {
        let infoMastList = {
            ...this.state.infoMastList,
            current_page: 1
        };
        let infoDetailList = {};

        let value = event.target.value;
        if (value) {
            let company = JSON.parse(value);   
            this.setState({
                ...this.state,
                currentCompany: company,
                infoMastList,
                infoDetailList
            },() => {
                this.getInfoMastByCompanyFunc();
            })
        } else {
            this.setState({
                ...this.state,
                currentCompany: {},
                infoMastList,
                infoDetailList
            },() => {
                this.getInfoMastByCompanyFunc();
            })
        }
    };

    getCompanies = () => {
        progressBar.start();
        let params = { 
            type: 'list'
        }
        api.company.getCompanies(params).then(response => {
            this.setState({
                ...this.state,
                companies: response.data.data,
                //if user role is admin then not display select company and default currentCompany is company belong to admin
                currentCompany: hasRole(this.props.auth.user, 'admin') ? response.data[0] : {} 
            })
            progressBar.done();
        }).catch(error => {
            progressBar.done();
        });
    }

    getInfoMastByCompanyFunc = () => {
        progressBar.start();
        let params = {
            // keyword: this.state.infoMastKeyword?.toString(),
            keyword: this.state.infoMastKeyword,
            page: this.state.infoMastList?.current_page || 1,
            option: 'all',
            type: 'paginate',
        }
        const {company_id}= this.state.currentCompany;
        api.infoMast.getInfoMastByCompany(company_id, params).then(response => {
            console.log(this.state.currentCompany, 'currentCompany2_1');

            this.setState({
                ...this.state,
                infoMastList: response.data.data,
                // infoDetailList: {},
                // currentInfoMast: {},
                // currentCompany: company,                
            }, () => {
                console.log(this.state, 'after222333');
            });
            progressBar.done();
        }).catch(error => {                
            progressBar.done();
        });
    }

    getInfoDetailByInfoMastFunc = (infoMast) => {
        progressBar.start();
        let params = {
            keyword: this.state.infoDetailKeyword?.toString(), 
            page: this.state.infoDetailList?.current_page || 1, 
            option: 'all',
            type: 'paginate',
        }
        let {m_id}= infoMast;
        api.infoDetail.getInfoDetailByInfoMast(m_id, params).then(response => {
            this.setState({
                infoDetailList: response.data.data,
                currentInfoMast: infoMast
                // currentInfoDetail: {},
            }, () => {
                // console.log(this.state, 'after222');
            });
            progressBar.done();
        }).catch(error => {                
            progressBar.done();
        });
    }

    handleSearchInfoMast = (event) => {
        let infoMastKeyword = event.target.value;
        this.setState({
            ...this.state,
            infoMastKeyword,
            infoMastList: {
                ...this.state.infoMastList,
                current_page: 1
            }
        }, () => {
            this.getInfoMastByCompanyFunc();
        })
    }

    handleSearchInfoDetail = (event) => {
        let infoDetailKeyword = event.target.value;
        this.setState({
            ...this.state,
            infoDetailKeyword,
            infoDetailList: {
                ...this.state.infoDetailList,
                current_page: 1
            }
        }, () => {
            this.getInfoDetailByInfoMastFunc(this.state.currentInfoMast);
        })
    }

    changePageInfoMast = (pageNumber) => {
        this.setState({
            ...this.state,
            infoMastList: {
                ...this.state.infoMastList,
                current_page: pageNumber
            }
        }, () => {
            this.getInfoMastByCompanyFunc();
        })
    }

    changePageInfoDetail = (pageNumber) => {
        this.setState({
            ...this.state,
        infoDetailList: {
                ...this.state.infoDetailList,
                current_page: pageNumber
            }
        }, () => {
            this.getInfoDetailByInfoMastFunc(this.state.currentInfoMast); 
        })
    }

    
    /**
     *  Modal Function
     */
    // Modal InfoMast
    toggleModalCreateInfoMast = () => {
        this.setState({
            ...this.state,
            infoMastForm: {},
            openModalCreateInfoMast: !this.state.openModalCreateInfoMast,
        }, () => {
            // console.log(this.state, 'state_after');
        });
    };

    toggleModalUpdateInfoMast = () => {
        this.setState({
            ...this.state,
            openModalUpdateInfoMast: !this.state.openModalUpdateInfoMast,
        }, () => {
        });
    };

    // Modal InfoDetail
    toggleModalCreateInfoDetail = () => {
        this.setState({
            ...this.state,
            infoDetailForm: {},
            openModalCreateInfoDetail: !this.state.openModalCreateInfoDetail,
        }, () => {
        });
    };

    toggleModalUpdateInfoDetail = () => {
        this.setState({
            ...this.state,
            openModalUpdateInfoDetail: !this.state.openModalUpdateInfoDetail,
        }, () => {
            // console.log(this.state, 'state_after');
        });
    };


    /**
     * CRUD InfoMast
     */
    handleChangeInputInfoMast = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            ...this.state,
            infoMastForm: {
                ...this.state.infoMastForm,
                [name]: value,
            }
        }, () => {
            // console.log(this.state.infoMastForm, 'infoMastForm2');
            // debugger
        });        
    };

    onCreateInfoMast = () => {
        progressBar.start();
        const infoMast = {...this.state.infoMastForm, company_id: this.state.currentCompany.company_id};
        this.props.onCreateInfoMast(infoMast)
            .then(response => {
                this.toggleModalCreateInfoMast();
                this.getInfoMastByCompanyFunc();
                this.setState({
                    ...this.state,
                    infoMastForm: {}, 
                }, () => {
                    this.addNotificationSimple({
                        title: <FormattedMessage
                                id="dashboard.Succeed"
                                defaultMessage="Succeed!"/>,
                        message: <FormattedMessage
                                id="dashboard.Common.CreateSucceed"
                                defaultMessage="Add new item successfully!"/>,
                        level: 'success',
                    })
                    progressBar.done();
                });
            })
            .catch(error => {
                if (error.data) {
                    this.addNotification({
                        title: <FormattedMessage
                                id="dashboard.Error"
                                defaultMessage="Error !"/>,
                        level: 'error',
                        message: renderMessage(error.data.errors.message),
                    })
                    progressBar.done();
                }
            });
    };

    editInfoMast = (infoMast) => {
        this.setState({
            ...this.state,
            infoMastForm: infoMast,
        }, () => {
            // console.log(this.state.infoMastForm, 'forrr');
            this.toggleModalUpdateInfoMast();
        });
    };
    
    onUpdateInfoMast = () => {
        progressBar.start();
        const infoMast = {...this.state.infoMastForm, company_id: this.state.currentCompany.company_id};

        this.props.onUpdateInfoMast(this.state.infoMastForm.m_id, infoMast)
        .then(response => {
            this.toggleModalUpdateInfoMast();
            this.getInfoMastByCompanyFunc();
            this.setState({
                ...this.state,
                infoMastForm: {}, 
                notification: {
                    allowHTML: false,
                }
            }, () => {
                this.addNotificationSimple({
                    title: <FormattedMessage
                            id="dashboard.Succeed"
                            defaultMessage="Succeed!"/>,
                    message: <FormattedMessage
                            id="dashboard.Common.UpdateSucceed"
                            defaultMessage="Update item successfully!"/>,
                    level: 'success',
                })
                progressBar.done();
            });
        })
        .catch(error => {
            if (error.data) {
                this.addNotification({
                    title: <FormattedMessage
                            id="dashboard.Error"
                            defaultMessage="Error !"/>,
                    level: 'error',
                    message: renderMessage(error.data.errors.message),
                })
                progressBar.done();
            }
        });
    };  


     /**
     * CRUD InfoDetail
     */
    handleChangeInputInfoDetail = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            ...this.state,
            infoDetailForm: {
                ...this.state.infoDetailForm,
                [name]: value,
            }
        }, () => {
            // console.log(this.state.infoDetailForm, 'infoDetailForm2');
            // debugger
        });        
    };

    onCreateInfoDetail = () => {
        progressBar.start();
        const infoMast = {...this.state.infoDetailForm, m_id: this.state.currentInfoMast.m_id};
        this.props.onCreateInfoDetail(infoMast)
            .then(response => {
                this.toggleModalCreateInfoDetail();
                this.getInfoDetailByInfoMastFunc(this.state.currentInfoMast);
                this.setState({
                    ...this.state,
                    infoDetailForm: {}, 
                }, () => {
                    this.addNotificationSimple({
                        title: <FormattedMessage
                                id="dashboard.Succeed"
                                defaultMessage="Succeed!"/>,
                        message: <FormattedMessage
                                id="dashboard.Common.CreateSucceed"
                                defaultMessage="Add new item successfully!"/>,
                        level: 'success',
                    })
                    progressBar.done();
                });
            })
            .catch(error => {
                if (error.data) {
                    this.addNotification({
                        title: <FormattedMessage
                                id="dashboard.Error"
                                defaultMessage="Error !"/>,
                        level: 'error',
                        message: renderMessage(error.data.errors.message),
                    })
                    progressBar.done();
                }
            });
    };

    editInfoDetail = (infoDetail) => {
        this.setState({
            ...this.state,
            infoDetailForm: infoDetail,
        }, () => {
            this.toggleModalUpdateInfoDetail();
        });
    };
    
    onUpdateInfoDetail = () => {
        progressBar.start();
        const infoDetail = {...this.state.infoDetailForm, m_id: this.state.currentInfoMast.m_id};

        this.props.onUpdateInfoDetail(this.state.infoDetailForm.m_dtl_id, infoDetail).then(response => {
            this.toggleModalUpdateInfoDetail();
            this.getInfoDetailByInfoMastFunc(this.state.currentInfoMast);
            this.setState({
                ...this.state,
                infoDetailForm: {}, 
            }, () => {
                this.addNotificationSimple({
                    title: <FormattedMessage
                            id="dashboard.Succeed"
                            defaultMessage="Succeed!"/>,
                    message: <FormattedMessage
                            id="dashboard.Common.UpdateSucceed"
                            defaultMessage="Update item successfully!"/>,
                    level: 'success',
                })
                progressBar.done();
            });
        })
        .catch(error => {
            if (error.data) {
                this.addNotification({
                    title: <FormattedMessage
                            id="dashboard.Error"
                            defaultMessage="Error !"/>,
                    level: 'error',
                    message: renderMessage(error.data.errors.message),
                })
                progressBar.done();              
            }
        });
    };  




    /**
     *  Aboth InfoMast vs InfoDetail Func;     deleteInfoMastOrInfoDetail:0 ->Del InfoMast || 1->Del InfoDetail
    */
    deleteInfoHandler = () => {
        if (this.state.deleteInfoMastOrInfoDetail) {
            // Delete InfoDetail 
            progressBar.start();
            this.props.onDeleteInfoDetail(this.state.deletingRow).then(response => {
                // check and re-set current page if delete last item of last page
                let {infoDetailList} = this.state; 
                let current_page = infoDetailList.from == infoDetailList.to ? infoDetailList.last_page - 1 : infoDetailList.current_page;

                //check and update InfoDetail after delete any item InfoDetail
                let currentInfoDetail = this.state.currentInfoDetail.m_id == this.state.deletingRow  ? {} : this.state.currentInfoDetail;
                this.setState({
                    ...this.state,                      
                    currentInfoDetail,

                    deletingRow: null,
                    isDeleteConfirmModalOpen: false,
                    deleteInfoMastOrInfoDetail: null,
                    notification: {
                        allowHTML: false,
                    },
                    infoDetailList: {
                        ...this.state.infoDetailList,
                        current_page,
                    },
                }, () => {  
                    this.getInfoDetailByInfoMastFunc(this.state.currentInfoMast);                  
                    this.addNotificationSimple({
                        title: <FormattedMessage
                            id="dashboard.Succeed"
                            defaultMessage="Succeed!"/>,
                        message: <FormattedMessage
                            id="dashboard.Common.DeleteSucceed"
                            defaultMessage="Delete item successfully!" />,
                        level: 'success',
                    })

                    progressBar.done();
                })
            })
            .catch(error => {
                progressBar.done();
            });

        } else {
            // Delete Info Mast
            progressBar.start();
            this.props.onDeleteInfoMast(this.state.deletingRow)
                .then(response => {
                    // check and re-set current page if delete last item of last page
                    let {infoMastList} = this.state; 
                    let current_page = infoMastList.from == infoMastList.to ? infoMastList.last_page - 1 : infoMastList.current_page;

                    // //check and update List InfoDetail after delete any item InfoMast
                    // if (this.state.currentInfoMast.m_id == this.state.deletingRow) {
                    //     var currentInfoMastUpdate = {};
                    //     var currentInfoDetailUpdate = {}; 
                    //     var infoDetailListUpdate = {
                    //         total: 0,
                    //     }
                    // } else {
                    //     var currentInfoMastUpdate = this.state.currentInfoMast;
                    //     var currentInfoDetailUpdate = this.state.currentInfoDetail;
                    //     var infoDetailListUpdate = this.state.infoDetail;
                    // }
                    var currentInfoMastUpdate = this.state.currentInfoMast;
                    var currentInfoDetailUpdate = this.state.currentInfoDetail;
                    var infoDetailListUpdate = this.state.infoDetail;       

                    this.setState({
                        ...this.state,
                        currentInfoMast: currentInfoMastUpdate,
                        currentInfoDetail: currentInfoDetailUpdate,
                        infoDetailList: infoDetailListUpdate,                      

                        deletingRow: null,
                        isDeleteConfirmModalOpen: false,
                        deleteInfoMastOrInfoDetail: null,
                        notification: {
                            allowHTML: false,
                        },
                        infoMastList: {
                            ...this.state.infoMastList,
                            current_page,
                        },
                    }, () => {  
                        this.getInfoMastByCompanyFunc();

                        this.addNotificationSimple({
                            title: <FormattedMessage
                                id="dashboard.Succeed"
                                defaultMessage="Succeed!"/>,
                            message: <FormattedMessage
                                id="dashboard.Common.DeleteSucceed"
                                defaultMessage="Delete item successfully!" />,
                            level: 'success',
                        })
                        progressBar.done();
                    })
                })
        }
    };

    setDeletingRow = (value, type) => {
        this.setState({
            ...this.state,
            deletingRow: value,
            deleteInfoMastOrInfoDetail: type
        }, () => {
            this.openDeleteModal();
        });        
    };

    openDeleteModal = () => {
        this.setState({
            isDeleteModalOpen: !this.state.isDeleteModalOpen
        });
    };

    openDeleteConfirmModal = () => {
        this.setState({
            isDeleteConfirmModalOpen: !this.state.isDeleteConfirmModalOpen
        });
    };


        render() {
        const {
            companies,
            infoMastList,
            infoDetailList,
        } = this.state;
        // console.log(this.state.currentCompany);
        console.log(this.state.currentInfoMast, 'cr_infoMast');

        return (
            <Fragment>
                <NotificationSystem ref={this.notificationSystem} allowHTML={true}/>
                <NotificationSystem ref={this.notificationSystemSimple} allowHTML={false}/>

                <FormattedMessage id="dashboard.ItemManagement"
                    defaultMessage="ItemManagement">
                    {message => <Helmet>
                        <title>{message}</title>
                    </Helmet>}
                </FormattedMessage>

                <div className="main_top_bar static_bar">
                    <div className="box_sum_col_top">
                        <div className="box_left">
                            <ul className="nav nav_tabs">
                                <li className="nav_item">
                                    <span className="nav_link">
                                        <FormattedMessage
                                            id="dashboard.ItemManagement"
                                            defaultMessage="ItemManagement"
                                        />
                                    </span>
                                </li>
                            </ul>
                        </div>

                        <HasRole role={['primarynet']}>
                        <div className="box_center">
                            <div className="tabs_static_item">
                                <div className="main_control_time_select">
                                    <div className="main_select main_box">
                                        <div className="main_label">
                                            <label className="title-label item-select">
                                                <FormattedMessage
                                                    id="dashboard.Company"
                                                    defaultMessage="Company"
                                                />
                                            </label>
                                        </div>                                       
                                        <div className="custom_select">
                                            <select className="form_control" onChange={this.selectCompanyHandler} name="sltCompany">
                                                <option>
                                                    {this.props.intl.formatMessage({id: 'dashboard.SelectCompany',defaultMessage: 'SelectCompany'})}
                                                </option>
                                                {
                                                    companies?.map((item, index) => {
                                                        return (
                                                            <option key={index} value={JSON.stringify(item)}>{item.company_name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            <span className="item_dropdown" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </HasRole>
                    </div>
                </div>

                <div className="main_content">
                    <div className="content_tabs_main content_static_tabs tab-content">
                        <div className="tabs_static_item tabs_item1">
                            <div className="box_content_static">
                                <div className="left_col left_col_customer_care">
                                    <DataSetting 
                                        location={this.props.location}
                                    />
                                </div>

                                <div className="content_col_nopading">
                                    <div className="list_control_box_preferences">
                                        <div className="content_left">
                                            <div className="content_column">
                                                <div className="column_box">
                                                    <div className="heading_box">
                                                        <h5 className="title_preferences">
                                                            <FormattedMessage id="dashboard.Items" defaultMessage="Items" />
                                                        </h5>
                                                    </div>
                                                    <div className="control_box_item">
                                                        <div className="card_header">
                                                            <div className="box_search">
                                                                <div className="box_control_input ">
                                                                    <input type="text" className="control" name="infoMastKeyword" onChange={this.handleSearchInfoMast} value={this.state.infoMastKeyword}/>
                                                                    <button className="btn_form btn_secondary"><i className="fas fa-search" /></button>
                                                                </div>
                                                            </div>
                                                            <div className="box_add">
                                                                <button type="button" className="btn btn_add" onClick={this.toggleModalCreateInfoMast}
                                                                        disabled={this.state.currentCompany?.company_id ? '' : 'disabled'}>
                                                                    <i className="fas fa-plus" />
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="card_body">
                                                            <div className="custom_table">
                                                                <table className="table st_table_5">
                                                                    <thead>
                                                                        <tr>
                                                                            <th style={{width: '60%'}}>
                                                                                <FormattedMessage
                                                                                    id="dashboard.Items"
                                                                                    defaultMessage="Items"/>
                                                                            </th>
                                                                            <th>
                                                                                <FormattedMessage
                                                                                    id="dashboard.Show"
                                                                                    defaultMessage="Show"/>
                                                                            </th>
                                                                            <th>
                                                                                <FormattedMessage
                                                                                    id="dashboard.Order"
                                                                                    defaultMessage="Order"/>
                                                                            </th>
                                                                            <th>
                                                                                <FormattedMessage
                                                                                    id="dashboard.Fix"
                                                                                    defaultMessage="Fix"/>
                                                                            </th>
                                                                            <th style={{width: '10%'}}/>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {/* render infoMast list */}
                                                                    { 
                                                                        infoMastList?.data?.length > 0 ? 
                                                                            infoMastList.data.map((item, index) => {
                                                                                let active = this.state.currentInfoMast?.m_id == item.m_id ? 'active' : '';
                                                                                return (
                                                                                    <tr key={index} className={active} onClick={() => this.getInfoDetailByInfoMastFunc(item)} >
                                                                                        <td>{item.m_name}</td>
                                                                                        <td>{item.m_show == 1 ? '표시' : '숨김'}</td>
                                                                                        <td>{item.m_order}</td>
                                                                                        <td>{item.m_fix == 1 ? 'Yes' : 'No'}</td>
                                                                                        <td className="inline_action">
                                                                                            <a onClick={() => this.editInfoMast(item)}> <i className="fas fa-edit"/> </a>
                                                                                            <a onClick={() => this.setDeletingRow(item.m_id, 0)} ><i className="fas fa-trash-alt"/></a>
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            }) :
                                                                            (
                                                                                <tr>
                                                                                    <td colSpan={4}>
                                                                                        <div className="nodata">No Data</div>
                                                                                    </td>
                                                                                </tr> 
                                                                            )
                                                                    }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        {  
                                                            infoMastList?.total > 100 && (
                                                                <div className="car_footer">
                                                                    <nav aria-label="...">
                                                                        <ul className="custom_pagination pagination">
                                                                            <Pagination
                                                                                itemClass="page-item"
                                                                                linkClass="page-link"
                                                                                activePage={infoMastList?.current_page}
                                                                                itemsCountPerPage={infoMastList?.per_page}
                                                                                totalItemsCount={infoMastList?.total || 0}
                                                                                pageRangeDisplayed={10}
                                                                                onChange={this.changePageInfoMast}
                                                                            />
                                                                        </ul>
                                                                    </nav>
                                                                 </div>
                                                            )
                                                        }                                                      
                                                    </div>
                                                </div>


                                                <div className="column_box">
                                                    <div className="heading_box">
                                                        <h5 className="title_preferences">
                                                            <FormattedMessage id="dashboard.SubItems" defaultMessage="SubItems" />
                                                        </h5>
                                                    </div>
                                                    <div className="control_box_item">
                                                        <div className="card_header">
                                                            <div className="box_search">
                                                                <div className="box_control_input ">
                                                                    <input 
                                                                        type="text"
                                                                        className="control"
                                                                        onChange={this.handleSearchInfoDetail} 
                                                                        value={this.state.infoDetailKeyword}
                                                                        // disabled={this.state.currentInfoMast?.m_id ? false : true}
                                                                    />
                                                                    <button className="btn_form btn_secondary"><i className="fas fa-search" /></button>
                                                                </div>
                                                            </div>
                                                            <div className="box_add">
                                                                <button type="button" className="btn btn_add" onClick={this.toggleModalCreateInfoDetail}
                                                                        disabled={this.state.currentInfoMast.m_id ? '' : 'disabled'}>
                                                                    <i className="fas fa-plus" />
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="card_body">
                                                            <div className="custom_table">
                                                                <table className="table st_table_5">
                                                                    <thead>
                                                                        <tr>
                                                                            <th style={{width: '60%'}}>
                                                                                <FormattedMessage
                                                                                    id="dashboard.SubItems"
                                                                                    defaultMessage="Sub Items"/>
                                                                            </th>
                                                                            <th>
                                                                                <FormattedMessage
                                                                                    id="dashboard.Limit"
                                                                                    defaultMessage="Limit"/>
                                                                            </th>
                                                                            <th>
                                                                                <FormattedMessage
                                                                                    id="dashboard.Order"
                                                                                    defaultMessage="Order"/>
                                                                            </th>
                                                                            <th>
                                                                                <FormattedMessage
                                                                                    id="dashboard.Use"
                                                                                    defaultMessage="Use"/>
                                                                            </th>
                                                                            <th style={{width: '10%'}}/>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {/* render infoDetail list */}
                                                                    { 
                                                                        infoDetailList?.data?.length > 0 ? 
                                                                            infoDetailList.data.map((item, index) => {
                                                                                let active = this.state.currentInfoDetail?.m_dtl_id == item.m_dtl_id ? 'active' : '';
                                                                                return (
                                                                                    <tr key={index} className={active}>
                                                                                        <td>{item.m_dtl_name}</td>
                                                                                        <td>{item.m_dtl_limit}</td>
                                                                                        <td>{item.m_dtl_order}</td>
                                                                                        <td>{item.m_dtl_use == 1 ? 'Yes' : ''}</td>
                                                                                        <td className="inline_action">
                                                                                            <a onClick={() => this.editInfoDetail(item)}> <i className="fas fa-edit"/> </a>
                                                                                            <a onClick={() => this.setDeletingRow(item.m_dtl_id, 1)} ><i className="fas fa-trash-alt"/></a>
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            }) :
                                                                            (
                                                                                <tr>
                                                                                    <td colSpan={4}>
                                                                                        <div className="nodata">No Data</div>
                                                                                    </td>
                                                                                </tr> 
                                                                            )
                                                                    }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        {  
                                                            infoDetailList?.total > 100 && (
                                                                <div className="car_footer">
                                                                    <nav aria-label="...">
                                                                        <ul className="custom_pagination pagination">
                                                                            <Pagination
                                                                                itemClass="page-item"
                                                                                linkClass="page-link"
                                                                                activePage={infoDetailList?.current_page}
                                                                                itemsCountPerPage={infoDetailList?.per_page}
                                                                                totalItemsCount={infoDetailList?.total || 0}
                                                                                pageRangeDisplayed={10}
                                                                                onChange={this.changePageInfoDetail}
                                                                            />
                                                                        </ul>
                                                                    </nav>
                                                                 </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* Modal Component*/}
                <CreateInfoMastModal
                    isOpen = {this.state.openModalCreateInfoMast}
                    toggle = {this.toggleModalCreateInfoMast}
                    currentCompany = {this.state.currentCompany}
                    infoMastForm = {this.state.infoMastForm}
                    handleChange = {this.handleChangeInputInfoMast}
                    onCreateInfoMast = {this.onCreateInfoMast}
                />

                <UpdateInfoMastModal
                    isOpen = {this.state.openModalUpdateInfoMast}
                    toggle = {this.toggleModalUpdateInfoMast}
                    currentCompany = {this.state.currentCompany}
                    infoMastForm = {this.state.infoMastForm}
                    handleChange = {this.handleChangeInputInfoMast}
                    onUpdateInfoMast = {this.onUpdateInfoMast}
                />

                <CreateInfoDetailModal
                    isOpen = {this.state.openModalCreateInfoDetail}
                    toggle = {this.toggleModalCreateInfoDetail}
                    currentInfoMast = {this.state.currentInfoMast}
                    infoDetailForm = {this.state.infoDetailForm}
                    handleChange = {this.handleChangeInputInfoDetail}
                    onCreateInfoDetail = {this.onCreateInfoDetail}
                />

                <UpdateInfoDetailModal
                    isOpen = {this.state.openModalUpdateInfoDetail}
                    toggle = {this.toggleModalUpdateInfoDetail}
                    currentInfoMast = {this.state.currentInfoMast}
                    infoDetailForm = {this.state.infoDetailForm}
                    handleChange = {this.handleChangeInputInfoDetail}
                    onUpdateInfoDetail = {this.onUpdateInfoDetail}
                />

                <DeleteConfirmModal
                    isDeleteModalOpen={this.state.isDeleteModalOpen}
                    openDeleteModal={this.openDeleteModal}
                    isDeleteConfirmModalOpen={this.state.isDeleteConfirmModalOpen}
                    openDeleteConfirmModal={this.openDeleteConfirmModal}
                    deleteInfoHandler={this.deleteInfoHandler}
                />
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        companies: state.companies,
        auth: state.auth
    }
};

const mapDispatchToProps = dispatch => {
    return {
        // onFetchCompanies: () => dispatch(fetchCompaniesAction()),
        // onFetchInfoMastList: company_id => dispatch(fetchInfoMastsAction(company_id)),
        
        onCreateInfoMast: infoMast => dispatch(createInfoMastAction(infoMast)),
        onUpdateInfoMast: (id, infoMast) => dispatch(updateInfoMastAction(id, infoMast)),
        onDeleteInfoMast: id => dispatch(deleteInfoMastAction(id)),

        onCreateInfoDetail: infoDetail => dispatch(createInfoDetailAction(infoDetail)),
        onUpdateInfoDetail: (id, infoDetail) => dispatch(updateInfoDetailAction(id, infoDetail)),
        onDeleteInfoDetail: id => dispatch(deleteInfoDetailAction(id))
    }
};


export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ItemManagement));