import React, {Fragment, useState} from "react";
import {FormattedMessage} from "react-intl";
import Pagination from "react-js-pagination";
import _ from "lodash";




function ContentHomeTable(props) {

    let {
        historyListV2,
        handleSearch,
        keyword
    } = props;

    const [paginate, setPaginate] = useState({
        itemsCountPerPage : 5, // tong item cua 1 phan trang
        pageRangeDisplayed : 3, // do keo dai cua 1 phan trang ex : 123....456
        activePage : 1, // active cua phan trang
        totalItemsCount : 0, // du lieu co may item,
        data : [], // xu li data de phan trang data xet lai cua pros
    });


    React.useEffect(() => {
        let receivedTotal = _.sumBy(historyListV2, function (total) {
            return +total.received + +total.received_thieu_sot ;
        });

        let sendingTotal = _.sumBy(historyListV2, function (total) {
            return +total.sending + +total.sending_thieu_sot;
        });

        let missedCallTotal = _.sumBy(historyListV2, function (total) {
            return +total.missed_call ;
        });


        if(historyListV2.length > 0) {
            historyListV2.push({
                received : receivedTotal,
                sending : sendingTotal,
                missed_call : missedCallTotal,
                time : 'Total'
            })
        }


        let dataPagination = _.dropRight(historyListV2,historyListV2.length - paginate.itemsCountPerPage);

        setPaginate({
            ...paginate,
            totalItemsCount : historyListV2.length,
            data :dataPagination,
            activePage : 1, // set lai pagination khi click
        })

        return () => {
            // Clean up the subscription

        };

    },[historyListV2]);

    function changePage (page) {
        let dataPagination = [];
        let oldPage = page - 1;
        dataPagination = _.drop(historyListV2,paginate.itemsCountPerPage * oldPage) // xoa du lieu pagination cu~
        dataPagination = _.dropRight(dataPagination,dataPagination.length - paginate.itemsCountPerPage) //lay du lieu hien tai
        setPaginate({
            ...paginate,
            data :dataPagination,
            activePage : page
        })
    }




    return(
        <div className="content">
            <div className="control_box_item">
                <div className="card_header">
                    <div className="box_search">
                        <div className="box_control_input ">
                            <input
                                type="text"
                                className="control"
                                name="keyword"
                                onChange={handleSearch}
                                value={keyword}
                            />
                            <button className="btn_form btn_secondary"><i className="fas fa-search" /></button>
                        </div>
                    </div>
                </div>
                <div className="card_body">
                    <div className="custom_table">
                        <table className="table st_table_5">
                            <thead>
                            <tr>
                                <th><FormattedMessage id="dashboard.Time" defaultMessage="Time" /></th>
                                <th><FormattedMessage id="dashboard.Home.Received" defaultMessage="Received" /></th>
                                <th><FormattedMessage id="dashboard.Home.SendingCalls" defaultMessage="SendingCalls" /></th>
                                <th><FormattedMessage id="dashboard.Home.UnansweredCalls" defaultMessage="UnansweredCalls" /></th>
                                <th><FormattedMessage id="dashboard.TotalCalls" defaultMessage="TotalCalls" /></th>
                            </tr>
                            </thead>
                            <tbody>
                            {paginate.totalItemsCount > 0 ? paginate.data.map((item, index) => {
                                if(item.time_truoc_9h){
                                    return (
                                        <tr key={index}>
                                            <td>{item.date+' 9시 이전'}</td>
                                            <td>{item.received_thieu_sot}</td>
                                            <td>{item.sending_thieu_sot}</td>
                                            <td>{item.missed_call}</td>
                                            <td>{+item.received_thieu_sot + +item.sending_thieu_sot + +item.missed_call }</td>
                                        </tr>
                                    )
                                }
                                    return (
                                        <tr key={index}>
                                            <td>{item.time == 'Total' ?'총': item.time+'시'}</td>
                                            <td>{item.received}</td>
                                            <td>{item.sending}</td>
                                            <td>{item.missed_call}</td>
                                            <td>{+item.received + +item.sending + +item.missed_call }</td>
                                        </tr>
                                    )
                                }) : (
                                    <tr>
                                        <td colSpan={5}>
                                            <div className="nodata">No Data</div>
                                        </td>
                                    </tr>
                                )
                            }
                            </tbody>
                        </table>
                    </div>
                </div>

                {paginate.totalItemsCount > 0 ? <div className="car_footer ">
                    <nav aria-label="...">
                        <Pagination
                            itemClass="page-item"
                            linkClass="page-link"
                            innerClass={'custom_pagination pagination'}
                            activePage={paginate.activePage}
                            itemsCountPerPage={paginate.itemsCountPerPage}
                            totalItemsCount={paginate.totalItemsCount}
                            pageRangeDisplayed={paginate.pageRangeDisplayed}
                            onChange={changePage}
                            hideNavigation
                        />
                    </nav>
                </div> : '' }
            </div>
        </div>
    )
}

export default ContentHomeTable;