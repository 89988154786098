import React, {Fragment} from 'react';
import Pagination from "react-js-pagination";
import _ from 'lodash';
import ModalDelete from "./Modals/ModalDelete";


class InfoMast extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            paginationInfoMast : {
                itemsCountPerPage : 7, // tong item cua 1 phan trang
                pageRangeDisplayed : 3, // do keo dai cua 1 phan trang ex : 123....456
                activePage : 1, // active cua phan trang
                totalItemsCount : 0, // du lieu co may item,
                data : [], // xu li data de phan trang data xet lai cua pros: infoMasts
            },

            paginationInfoMastDetail : {
                itemsCountPerPage : 7, // tong item cua 1 phan trang
                pageRangeDisplayed : 3, // do keo dai cua 1 phan trang ex: 123....456
                activePage : 1, // active cua phan trang
                totalItemsCount : 0, // du lieu co may item,
                data : [], // xu li data de phan trang data xet lai state: infoMastDetail ( infoMastDetail.info_detail )
            },

            infoMastDetail : {}, // dữ liệu khi click item info mast

            // dữ liệu này là dữ liệu call_detail của redux call
            // khi click item infoMastDetail se show du lieu neu thay doi se thay doi trong redux call : info_detail
            callDetailResult : [],
            itemModalDetailDelete : false, // modal delete
            itemTemporarilySave : null, // item luu tam mo modal se setState con dong modal se null
        }
    }

    componentDidMount() {
        let {paginationInfoMast} = this.state;
        let {infoMasts} = this.props;
        let dataPaginationInfoMast = [];
        // xu li du lieu info mast
        dataPaginationInfoMast = _.dropRight(infoMasts,infoMasts.length - paginationInfoMast.itemsCountPerPage)
        this.setState({
            infoMasts,
            paginationInfoMast : {
                ...this.state.paginationInfoMast,
                totalItemsCount : infoMasts.length,
                data : dataPaginationInfoMast
            }
        })

    }

    // change page info mast
    changePageInfoMast = (page) => {
        let {paginationInfoMast} = this.state;
        let {infoMasts} = this.props;
        let dataPaginationInfoMast = [];
        let oldPage = page - 1;
        dataPaginationInfoMast = _.drop(infoMasts,paginationInfoMast.itemsCountPerPage * oldPage) // xoa du lieu pagination cu~
        dataPaginationInfoMast = _.dropRight(dataPaginationInfoMast,dataPaginationInfoMast.length - paginationInfoMast.itemsCountPerPage) //lay du lieu hien tai

        this.setState({
            paginationInfoMast : {
                ...this.state.paginationInfoMast,
                data : dataPaginationInfoMast,
                activePage : page
            }
        })
    }

    // change page info mast detail
    changePageInfoMastDetail = (page) => {
        let {
            paginationInfoMastDetail,
            infoMastDetail
        } = this.state;
        let dataPaginationInfoMastDetail = [];
        let oldPage = page - 1;
        dataPaginationInfoMastDetail =  _.drop(infoMastDetail.info_detail,paginationInfoMastDetail.itemsCountPerPage * oldPage) // xoa du lieu pagination cu~
        dataPaginationInfoMastDetail =  _.dropRight(dataPaginationInfoMastDetail,dataPaginationInfoMastDetail.length - paginationInfoMastDetail.itemsCountPerPage) //lay du lieu hien tai
        this.setState({
            paginationInfoMastDetail : {
                ...this.state.paginationInfoMastDetail,
                data : dataPaginationInfoMastDetail,
                activePage : page
            }
        })

    }

    // du item infomast detail
    onClickInfoMastDetail = (infoMastDetail) => {
        let {paginationInfoMastDetail} = this.state; // pagination cua paginationInfoMastDetail

        // set lai du lieu de pagination
        let dataPaginationInfoMastDetail = [];
        dataPaginationInfoMastDetail = _.dropRight(infoMastDetail.info_detail,infoMastDetail.info_detail.length - paginationInfoMastDetail.itemsCountPerPage)

        this.setState({
            infoMastDetail,
            paginationInfoMastDetail : {
                ...this.state.paginationInfoMastDetail,
                totalItemsCount : infoMastDetail.info_detail.length,
                data : dataPaginationInfoMastDetail,
                activePage : 1, // active cua phan trang
            }
        })
    }

    // xu li change call_detail set lai vao redux call
    changeCallDetail = (detail) => {
        let {call_detail} = this.props.call;
        let check = true
        if(call_detail.length > 0) {
            call_detail.map((item,index) => {
                if(detail.m_id == item.call_m_id){
                    check = false;
                    call_detail[index] = {
                        call_m_id : detail.m_id,
                        call_m_dtl_id : detail.m_dtl_id,
                        call_dtl_name : detail.m_dtl_name
                    }
                }
            });
        }
        if(check){
            call_detail.push({
                call_m_id : detail.m_id,
                call_m_dtl_id : detail.m_dtl_id,
                call_dtl_name : detail.m_dtl_name
            })
        }

        let data = {
            call_detail : call_detail
        }

        this.props.onChangeCallDetail(data)
    }

    onClickDeleteItemDetail = detail => {
       if(detail){
           this.setState({
               itemTemporarilySave : detail
           },()=>{
               this.itemModalDetailDeleteAction();
           })
       }
    }

    itemModalDetailDeleteAction = () => {
        this.setState({
            itemModalDetailDelete : !this.state.itemModalDetailDelete,
        })
    }

    deleteDetailItem = detail => {
        let {call_detail} = this.props.call;
        if(detail){
            call_detail.map((item,index) => {
                if(item.call_m_id ==detail.call_m_id){
                    call_detail.splice(index,1)
                }
            })
        }
        let data = {
            call_detail : call_detail
        }
        this.props.onChangeCallDetail(data);
        this.itemModalDetailDeleteAction(); // an modal
    }

    render() {
        let {
            paginationInfoMast, // pagination cua info mast
            paginationInfoMastDetail, // pagination cua info mast detail
            infoMastDetail, // du lieu co khi click item info mast
            itemModalDetailDelete,
            itemTemporarilySave,// luu tam dong modal se null
        } = this.state;

        const {call_detail} = this.props.call;

        return(
           <React.Fragment>
               <div className="col-lg-12">
                   <div className="box_sum_card">
                       <div className="card_box_item">
                           <div className="custom_table">
                               <table className="table st_table_2">
                                   <thead>
                                   <tr>
                                       <th>상담항목</th>
                                       <th>선택값</th>
                                       <th></th>
                                   </tr>
                                   </thead>
                                   <tbody>
                                   {paginationInfoMast.data.map((item,index) => {
                                       // console.log(item,'vkl')
                                       // them call_detail vao du lieu de map ra
                                       item['call_detail'] = null;
                                       if(call_detail.length > 0){
                                           call_detail.map(detail =>{
                                               if(item.m_id == detail.call_m_id){
                                                   item['call_detail'] = detail
                                               }
                                           })
                                       }

                                       return(
                                           <tr
                                               key={index}
                                           >
                                               <td
                                                   onClick={()=>this.onClickInfoMastDetail(item)}
                                                   className={infoMastDetail.m_id == item.m_id ? 'active' : ''}
                                               >
                                                   {item.m_name}
                                               </td>
                                               {item.call_detail
                                                   ? <td onClick={()=>this.onClickInfoMastDetail(item)}>{item.call_detail.call_dtl_name}</td>

                                                   : <td onClick={()=>this.onClickInfoMastDetail(item)}></td>}

                                               <td
                                                   className="inline_action"
                                                   onClick={()=>this.onClickDeleteItemDetail(item.call_detail)}
                                               >
                                                   <i className="fas fa-trash-alt"></i>
                                               </td>
                                           </tr>
                                       )
                                   })}

                                   </tbody>
                               </table>
                           </div>
                           <div className="card_box_footer">
                               <nav aria-label="...">
                                   <ul className="custom_pagination pagination">
                                       <Pagination
                                           itemClass="page-item"
                                           linkClass="page-link"
                                           activePage={paginationInfoMast.activePage}
                                           itemsCountPerPage={paginationInfoMast.itemsCountPerPage}
                                           totalItemsCount={paginationInfoMast.totalItemsCount}
                                           pageRangeDisplayed={paginationInfoMast.pageRangeDisplayed}
                                           onChange={this.changePageInfoMast}
                                       />
                                   </ul>
                               </nav>
                           </div>
                       </div>
                       <div className="card_box_item">
                           <div className="custom_table ">
                               <table className="table st_table_2">
                                   <thead>
                                   <tr>
                                       <th>세부항목</th>
                                   </tr>
                                   </thead>
                                   <tbody>
                                   {paginationInfoMastDetail.data.map((item,index) => {
                                       return (
                                           <tr
                                               key={index}
                                               // xu li change call_detail cua redux call
                                               onClick={()=>this.changeCallDetail(item)}
                                           >
                                               <td>{item.m_dtl_name}</td>
                                           </tr>
                                       )
                                   })}
                                   </tbody>
                               </table>
                           </div>
                           <div className="card_box_footer">
                               <nav aria-label="...">
                                   <ul className="custom_pagination pagination">
                                       <Pagination
                                           itemClass="page-item"
                                           linkClass="page-link"
                                           activePage={paginationInfoMastDetail.activePage}
                                           itemsCountPerPage={paginationInfoMastDetail.itemsCountPerPage}
                                           totalItemsCount={paginationInfoMastDetail.totalItemsCount}
                                           pageRangeDisplayed={paginationInfoMastDetail.pageRangeDisplayed}
                                           onChange={this.changePageInfoMastDetail}
                                       />
                                   </ul>
                               </nav>
                           </div>
                       </div>
                   </div>
               </div>
               <ModalDelete
                   isOpen={itemModalDetailDelete}
                   toggle={this.itemModalDetailDeleteAction}
                   item={itemTemporarilySave} // truyen id vao de xoa
                   deleteItem={this.deleteDetailItem} // function de lay id
               />
           </React.Fragment>
        )
    }
}

export default InfoMast;