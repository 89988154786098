import {
    FETCH_REPORT_TELEPHONE,
    FETCH_REPORT_TELEPHONE_SUCCESS,
    FETCH_REPORT_TELEPHONE_FAIL,
    RESET_REPORT_TELEPHONE
} from './../../store/type';

const initialState = {
    data: [],
    loading: false,
    error: false
};


const reportTelephone = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_REPORT_TELEPHONE: {
            return {
                ...state,
                loading: true
            }
        }
        case FETCH_REPORT_TELEPHONE_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: action.payload.data
            }
        }
        case FETCH_REPORT_TELEPHONE_FAIL: {
            return {
                data : [],
                loading: false,
                error: action.payload.error
            }
        }

        case RESET_REPORT_TELEPHONE: {
            return {
                data: [],
                loading: false,
                error: false
            }
        }

        default:
            return state;
    }
}


export default reportTelephone;
