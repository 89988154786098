import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Route, Redirect} from "react-router-dom";
import { hasRole, hasPermission } from '../utils/entrust/entrust';
import Page403 from '../components/ErrorPage/page403';





const UserRoute = ({isAuthenticated, auth,test, component: Component, layout: Layout, ...rest}) => {

    if (!isAuthenticated) {
        return <Route
            {...rest}
            render={props => <Redirect to="/login"/>}
        />
    };
    const role = !rest.role || (rest.role && hasRole(auth.user, rest.role));
    const permission = !rest.permission || (rest.permission && hasPermission(auth.user, rest.permission))

    if (!role || !permission) {
        return (
            <Route
                {...rest}
                render   ={props => <Page403 {...props} />}
            />
        )
    }

    // console.log(auth,'yy')

    // if(auth.user && auth.user.roles.length > 0)
    // {
    //     return window.location.href = "http://stackoverflow.com";
    // }

    // If have layout
    if(Layout) {
        return (
            <Route
                {...rest}
                render={props => <Layout><Component {...props}/> </Layout>}
            />
        )
    }

    return (
        <Route
            {...rest}
            render={props => <Component {...props} />}
        />
    )

};


UserRoute.propTypes = {
    // component: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired
};

const mapStateToProps = state => {
    return {
        isAuthenticated: !!state.auth.token,
        auth: state.auth
    };
};

export default connect(mapStateToProps)(UserRoute);
