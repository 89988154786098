import React, { Fragment, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Helmet } from "react-helmet";
import DataSetting from "./../DataSetting";
import PropTypes from "prop-types";
import Pagination from "react-js-pagination";
import {
  fetchCompanyPlacementSearchAction,
  fetchPlacementRegisteredAction,
  deletePlacementAction,
} from "./../../../store/actions/MgmAdmin/placementRegistered";
import moment from "moment";

import useCompareDate from "../../../components/hooks/useCompareDate";
import DeleteConfirmModal from "../../../components/DeleteConfirmModal/DeleteConfirmModal";
import NotificationSystem from "react-notification-system";
import { set } from "lodash";
import progressBar from "../../../utils/progressBar";

RegisteredPlacement.propTypes = {
  props: PropTypes.object,
};
function RegisteredPlacement(props) {
  const [pagination, setPagination] = useState({
    activePage: 1,
    per_page: null,
    total: null,
  });
  const [companies, setCompanies] = useState([]);
  const [currentCompany, setCurrentCompany] = useState([]);
  const [currentPlacement, setCurrentPlacement] = useState(null);
  const [placements, setPlacements] = useState([]);
  const [deleteRowID, setDeleteRowID] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(null);
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] = useState(
    null
  );
  const notificationSystem = useRef();
  useEffect(() => {
    progressBar.start();
    getCompanyPlacement();
    props
      .placementRegister(1)
      .then((response) => {
        setPagination({
          activePage: response.data.current_page,
          per_page: response.data.per_page,
          total: response.data.total,
        });
        progressBar.done();
      })
      .catch((err) => {
        progressBar.done();
      });
  }, []);

  function getCompanyPlacement() {
    props.companyPlacement().then((response) => {
      setCompanies(response);
    });
  }

  function renderCompany(data) {
    if (data && data.length > 0) {
      return data.map((item, index) => {
        return (
          <option key={index} value={item.company_id}>
            {item.company_name}
          </option>
        );
      });
    }
  }

  function selectCompanyHandler(id) {
    if (companies.length > 0) {
      companies.map((item, index) => {
        if (item.company_id == id) {
          setCurrentCompany(item);
          setPlacements(item?.info_placements || []);
        }
      });
    }
    props.placementRegister(1, null, id);
  }

  function renderPlacements(data) {
    if (data && data.length > 0) {
      return data.map((item, index) => {
        return (
          <option key={index} value={item.place_id}>
            {item.place_name}
          </option>
        );
      });
    }
  }

  function selectPlacementHandler(id) {
    let data = {
      page: pagination.activePage,
      company_id: currentCompany.company_id,
      place_id: id,
    };
    props.placementRegister(data.page, null, data.company_id, data.place_id);
    if (placements && placements.length > 0) {
      placements.map((item, index) => {
        if (item.place_id == id) {
          setCurrentPlacement(item);
        }
      });
    }
  }

  function statusFormatter(data) {
    const { place_from, place_to, rec_date } = data;
    let today = moment().format("YYYY-MM-DD");
    if (place_from > today) {
      return (
        <span className="badge badge-info">
          <FormattedMessage
            id="mgm.admin.future_schedule"
            defaultMessage="Future Schedule"
          />
        </span>
      );
    }
    if (place_from <= today && today <= place_to) {
      return (
        <span className="badge badge-danger">
          <FormattedMessage
            id="mgm.admin.in_progress"
            defaultMessage="In progress"
          />
        </span>
      );
    }
    if (today >= place_to) {
      return (
        <span className="badge badge-success">
          <FormattedMessage id="mgm.admin.end" defaultMessage="End" />
        </span>
      );
    }
  }

  function attachmentFormatter(data) {
    if (data && data.length > 0) {
      return data.map((file, key) => {
        let filename = file.file_path.replace(
          "uploads/admin-uploads/mgm_admin/",
          ""
        );
        console.log(process.env.NODE_ENV, "process.env.NODE_ENV");
        if (process.env.NODE_ENV === "production") {
          return (
            <p key={key} className="m-0 pull-left">
              <a
                target="_blank"
                href={`${process.env.REACT_APP_REDIRECT_PROD}/${file.file_path}`}
              >
                <i className="fa fa-download"></i>{" "}
                {filename.length > 20
                  ? filename.slice(0, 20) + "..."
                  : filename}
              </a>
            </p>
          );
        } else {
          return (
            <p key={key} className="m-0 pull-left">
              <a
                target="_blank"
                href={`${process.env.REACT_APP_REDIRECT_DEV}/${file.file_path}`}
              >
                <i className="fa fa-download"></i>{" "}
                {filename.length > 20
                  ? filename.slice(0, 20) + "..."
                  : filename}
              </a>
            </p>
          );
        }
      });
    }
  }

  function selectRowDelete(id) {
    setDeleteRowID(id);
    openDeleteModal();
  }

  /**
   *
   * modal delete first
   */
  function openDeleteModal() {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  }

  /**
   *
   * modal delete second
   */
  function openDeleteConfirmModal() {
    setIsDeleteConfirmModalOpen(!isDeleteConfirmModalOpen);
  }

  function deleteInfoHandler() {
    progressBar.start();
    const notification = notificationSystem.current;
    props.deletePlacement(deleteRowID).then((response) => {
      setIsDeleteConfirmModalOpen(null);
      if (response) {
        let data = {
          page: pagination.activePage,
          company_id: currentCompany.company_id,
          place_id: currentPlacement?.place_id || null,
        };
        notification.addNotification({
          title: (
            <FormattedMessage
              id="dashboard.Succeed"
              defaultMessage="Succeed!"
            />
          ),
          message: (
            <FormattedMessage
              id="dashboard.ItemValidate.DeleteSucceed"
              defaultMessage="Delete item successfully!"
            />
          ),
          level: "success",
        });
        
        props.placementRegister(
          data.page,
          null,
          data.company_id,
          data.place_id
        );
        progressBar.done();
      } else {

        notification.addNotification({
          title: (
            <FormattedMessage id="dashboard.Error" defaultMessage="Error!" />
          ),
          message: (
            <FormattedMessage
              id="dashboard.ErrorMessage"
              defaultMessage="Sorry! Something went wrong. Retry!"
            />
          ),
          level: "error",
        });
        progressBar.done();
      }
    });
  }

  function renderTablePlaces(data) {
    if (data && data.data && data.data.length > 0) {
      return data.data.map((item, index) => {
        return (
          <tr key={index}>
            <td>
              <div>{item?.info_company?.company_name || ""}</div>
            </td>
            <td>
              <div>{item.place_name}</div>
            </td>
            <td>
              <div>{item.place_district}</div>
            </td>
            <td>
              <div>
                {item.place_from} ~ {item.place_to}
              </div>
            </td>
            <td>
              <div>{statusFormatter(item)}</div>
            </td>
            <td>{attachmentFormatter(item.info_place_files)}</td>
            <td className="inline_action">
              <a
                href="#"
                className=""
                onClick={() => selectRowDelete(item.place_id)}
              >
                <i className="fas fa-trash-alt"></i>
              </a>
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan={8}>
            <div className="nodata">No Data</div>
          </td>
        </tr>
      );
    }
  }

  /**
   *
   * change page
   */
  function handlePageChange(pageNumber) {
    progressBar.start();
    setPagination({
      ...pagination,
      activePage: pageNumber,
    });
    let data = {
      page: pageNumber,
      company_id: currentCompany?.company_id || null,
      place_id: currentPlacement?.place_id || null,
    };
    props.placementRegister(data.page, null, data.company_id, data.place_id);
    progressBar.done();
  }

  return (
    <Fragment>
      <FormattedMessage
        id="mgm.admin.registered_placement"
        defaultMessage="Registered placement"
      >
        {(message) => (
          <Helmet>
            <title>{message}</title>
          </Helmet>
        )}
      </FormattedMessage>

      <div className="main_top_bar static_bar">
        <div className="box_sum_col_top">
          <div className="box_left">
            <ul className="nav nav_tabs">
              <li className="nav_item">
                <span className="nav_link">
                  <FormattedMessage
                    id="mgm.admin.mgm_management"
                    defaultMessage="MGM management"
                  />
                  /{" "}
                  <FormattedMessage
                    id="mgm.admin.registered_placement"
                    defaultMessage="Registered placement"
                  />
                </span>
              </li>
            </ul>
          </div>
          <div className="box_center">
            <div className="tabs_static_item">
              <div className="main_control_time_select">
                <div className="main_select main_box">
                  <div className="main_label">
                    <FormattedMessage
                      id="dashboard.Company"
                      defaultMessage="Company"
                    />
                  </div>
                  <div className="custom_select">
                    <select
                      className="form_control"
                      onChange={(e) => selectCompanyHandler(e.target.value)}
                    >
                      <option value="">
                        {props.intl.formatMessage({
                          id: "dashboard.SelectCompany",
                          defaultMessage: "Select Company",
                        })}
                      </option>
                      {renderCompany(companies)}
                    </select>
                    <span className="item_dropdown" />
                  </div>
                </div>
                <div className="main_select main_box">
                  <div className="main_label">
                    <FormattedMessage
                      id="mgm.admin.placement"
                      defaultMessage="Placement"
                    />
                  </div>
                  <div className="custom_select">
                    <select
                      className="form_control"
                      onChange={(e) => selectPlacementHandler(e.target.value)}
                    >
                      <option value="">
                        {props.intl.formatMessage({
                          id: "mgm.admin.placement",
                          defaultMessage: "Placement",
                        })}
                      </option>
                      {renderPlacements(placements)}
                    </select>
                    <span className="item_dropdown" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main_content">
        <div className="content_tabs_main content_static_tabs tab-content">
          <div className="tabs_static_item tabs_item1">
            <div className="box_content_static">
              <div className="left_col left_col_customer_care">
                <DataSetting 
                  location={props.location}
                />
              </div>
              <NotificationSystem ref={notificationSystem} />
              <div className="content_col_nopading">
                <div className="list_control_box_preferences">
                  <div className="content_left">
                    <div className="content_column">
                      <div className="column_box ">
                        <div className="control_box_item">
                          <div className="card_body">
                            <div className="custom_table">
                              <table className="table st_table_5">
                                <thead>
                                  <tr>
                                    
                                    <th>
                                      <FormattedMessage
                                        id="mgm.admin.usr_company_register"
                                        defaultMessage="Company Name Register"
                                      />
                                    </th>
                                    <th>
                                      <FormattedMessage
                                        id="mgm.admin.placement"
                                        defaultMessage="Placement"
                                      />
                                    </th>
                                    <th>
                                      <FormattedMessage
                                        id="mgm.admin.location"
                                        defaultMessage="Location"
                                      />
                                    </th>
                                    <th>
                                      <FormattedMessage
                                        id="mgm.admin.period"
                                        defaultMessage="Period"
                                      />
                                    </th>
                                    <th>
                                      <FormattedMessage
                                        id="mgm.admin.status"
                                        defaultMessage="Status"
                                      />
                                    </th>
                                    <th>
                                      <FormattedMessage
                                        id="mgm.admin.attachment"
                                        defaultMessage="Attachment"
                                      />
                                    </th>
                                    <th></th>
                                    
                                  </tr>
                                </thead>
                                <tbody>{renderTablePlaces(props.places)}</tbody>
                              </table>
                            </div>
                          </div>
                          <div className="car_footer">
                            <nav aria-label="...">
                              <ul className="custom_pagination pagination">
                                {props.places && props.places.data && props.places.data.length > 0 ? (
                                  <Pagination
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    activePage={
                                      pagination.activePage
                                        ? pagination.activePage
                                        : 1
                                    }
                                    itemsCountPerPage={pagination.per_page}
                                    totalItemsCount={pagination.total}
                                    pageRangeDisplayed={10}
                                    onChange={handlePageChange}
                                  />
                                ) : (
                                  ""
                                )}
                              </ul>
                            </nav>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DeleteConfirmModal
        isDeleteModalOpen={isDeleteModalOpen}
        openDeleteModal={openDeleteModal}
        isDeleteConfirmModalOpen={isDeleteConfirmModalOpen}
        openDeleteConfirmModal={openDeleteConfirmModal}
        deleteInfoHandler={deleteInfoHandler}
      />
    </Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    places: state.placementRegistered,
  };
};

const mapDispatchToProps = (dispatch) => ({
  companyPlacement: () => dispatch(fetchCompanyPlacementSearchAction()),
  placementRegister: (page, limit = null, company_id = null, place_id = null) =>
    dispatch(fetchPlacementRegisteredAction(page, limit, company_id, place_id)),
  deletePlacement: (id) => dispatch(deletePlacementAction(id)),
});

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(RegisteredPlacement)
);
