import {
  FETCH_INFO_MAST_LIST,
  FETCH_INFO_MAST_LIST_SUCCEED,
  FETCH_INFO_MAST_LIST_FAILED,
  CREATE_INFO_MAST,
  CREATE_INFO_MAST_SUCCEED,
  CREATE_INFO_MAST_FAILED,
  UPDATE_INFO_MAST,
  UPDATE_INFO_MAST_SUCCEED,
  UPDATE_INFO_MAST_FAILED,
  DELETE_INFO_MAST,
  DELETE_INFO_MAST_SUCCEED,
  DELETE_INFO_MAST_FAILED,
  UNMOUNT_INFO_MAST,
  FETCH_TABLE_REPORT_SUCCESS,
} from "../actions/actionTypes";
import api from "../../utils/api";

export const fetchInfoMasts = () => {
  return {
    type: FETCH_INFO_MAST_LIST,
  };
};

export const fetchInfoMastsSucceed = (data) => {
  return {
    type: FETCH_INFO_MAST_LIST_SUCCEED,
    payload: {
      data: data,
    },
  };
};

export const fetchInfoMastsFailed = (error) => {
  return {
    type: FETCH_INFO_MAST_LIST_FAILED,
    payload: {
      error: error,
    },
  };
};

export const createInfoMast = () => {
  return {
    type: CREATE_INFO_MAST,
  };
};

export const createInfoMastSucceed = (infoMast) => {
  return {
    type: CREATE_INFO_MAST_SUCCEED,
    payload: {
      infoMast,
    },
  };
};

export const createInfoMastFailed = (error) => {
  return {
    type: CREATE_INFO_MAST_FAILED,
    payload: {
      error: error,
    },
  };
};

export const updateInfoMast = () => {
  return {
    type: UPDATE_INFO_MAST,
  };
};

export const updateInfoMastSucceed = (infoMast) => {
  return {
    type: UPDATE_INFO_MAST_SUCCEED,
    payload: {
      infoMast,
    },
  };
};

export const updateInfoMastFailed = (error) => {
  return {
    type: UPDATE_INFO_MAST_FAILED,
    payload: {
      error: error,
    },
  };
};

export const deleteInfoMast = () => {
  return {
    type: DELETE_INFO_MAST,
  };
};

export const deleteInfoMastSucceed = (infoMast) => {
  return {
    type: DELETE_INFO_MAST_SUCCEED,
    payload: {
      infoMast,
    },
  };
};

export const deleteInfoMastFailed = (error) => {
  return {
    type: DELETE_INFO_MAST_FAILED,
    payload: {
      error: error,
    },
  };
};

export const unmountInfoMast = () => {
  return {
    type: UNMOUNT_INFO_MAST,
  };
};

export const fetchTableReportSuccess = (data) => {
  return {
    type: FETCH_TABLE_REPORT_SUCCESS,
    payload: {
      data,
    },
  };
};

/**
 * Action
 */

/**
 * Fetch InfoMast List
 */
export const fetchInfoMastsAction = (company_id = null) => (dispatch) => {
  dispatch(fetchInfoMasts());
  if (company_id !== null) {
    return api.infoMast
      .getInfoMastByCompany(company_id)
      .then((response) => {
        dispatch(fetchInfoMastsSucceed(response.data.data));
        return Promise.resolve(response.data.data);
      })
      .catch((error) => {
        dispatch(fetchInfoMastsFailed(error.response.data.errors.message));
        return Promise.reject(error.response);
      });
  } else {
    return api.infoMast
      .getInfoMast()
      .then((response) => {
        dispatch(fetchInfoMastsSucceed(response.data.data));
        return Promise.resolve(response.data.data);
      })
      .catch((error) => {
        dispatch(fetchInfoMastsFailed(error.response.data.errors?.message));
        return Promise.reject(error.response);
      });
  }
};

/**
 * fetchNoteReportAction
 * @param {*} infoMast
 */
export const fetchNoteReportAction = (data) => (dispatch) => {
  return api.statistics
    .getNoteReport(data)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

export const fetchDailyReportAction = (data) => (dispatch) => {
  return api.statistics
    .getDailyReport(data)
    .then((response) => {
      dispatch(fetchTableReportSuccess(response));
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

export const createDailyReportNoteAction = (data) => (dispatch) => {
    return api.statistics
    .createDailyReportNote(data)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};
/**
 * Create info mast
 */
export const createInfoMastAction = (infoMast) => (dispatch) => {
  dispatch(createInfoMast());
  return api.infoMast
    .createInfoMast(infoMast)
    .then((response) => {
      dispatch(createInfoMastSucceed(response.data.data));
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      dispatch(createInfoMastFailed(error.response.data.errors.message));
      return Promise.reject(error.response);
    });
};

/**
 * Update info mast
 */
export const updateInfoMastAction = (id, infoMast) => (dispatch) => {
  dispatch(updateInfoMast());
  return api.infoMast
    .updateInfoMast(id, infoMast)
    .then((response) => {
      dispatch(updateInfoMastSucceed(response.data.data));
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      dispatch(updateInfoMastFailed(error.response.data.errors.message));
      return Promise.reject(error.response);
    });
};

/**
 * Delete info mast
 */
export const deleteInfoMastAction = (infoMastId) => (dispatch) => {
  dispatch(deleteInfoMast());
  const id = {
    params: {
      m_id: infoMastId,
    },
  };
  return api.infoMast
    .deleteInfoMast(id)
    .then((response) => {
      dispatch(deleteInfoMastSucceed(response.data.data));
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      dispatch(deleteInfoMastFailed(error.response.data.errors.message));
      return Promise.reject(error.response);
    });
};

export const unmountInfoMastAction = () => (dispatch) => {
  dispatch(unmountInfoMast());
};
