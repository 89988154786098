import {
    FETCH_CALL,
    FETCH_CALL_SUCCEED,
    FETCH_CALL_FAILED,
    EDIT_CALL_COLUMN, // chinh sua cot trong redux.
    RESET_CALL,
    FETCH_CALL_CREATE_NEW, // fetch call khi co cuoc goi den set lai redux
    FETCH_CALL_KEY_SEARCH,
    FETCH_CALL_KEY_SEARCH_SUCCESS,
    FETCH_CALL_KEY_SEARCH_FAILED, SET_CALL,
} from './../type';


const initialState = {
    data:{
        "call_id":"",
        "company_id":"",
        "call_date":"",
        "call_tel":"",
        "call_cust_name":"",
        "call_cust_info":'',
        "call_cust_hp":'',
        "call_cust_tel":"",
        "call_post_code":'',
        "call_cust_address":'',
        "call_cust_dist":'',
        "call_agree":0,
        "call_check":"",
        "call_desc":"",
        "call_contents":"",
        "rec_usr_id":"",
        "rec_idate":"",
        "rec_date":"",
        "rec_stat":"",
        "lots_id":"",
        "call_line_info":'',
        "is_imported":"",
        "rec_system":"",
        "call_id_api":"",
        "call_detail":[],
        "call_note" : '',
        'call_date_update' : null, // xu li chuc nang ngay truoc do va thieu sot
        // "call_details":[], // cai state dùng để khi upload lên server sẽ thay đổi call_detail

    },
    loading: false,
    error: false
};

const dauSo = ['010','011','016','017','019'];

const call = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CALL: {
            return {
                ...state,
                loading: true
            }
        }
        case FETCH_CALL_SUCCEED: {
            let call = action.payload.data;
            // xu li khi cuoc goi khong co  call_cust_hp va call_cust_tel thi mac dinh 1 so
            if(!call.call_cust_hp && !call.call_cust_tel){
                var sliceString = call.call_tel.slice(0,3);
                if(dauSo.find(element => element == sliceString)){// tim
                    call.call_cust_hp = call.call_tel
                }else{
                    call.call_cust_tel  = call.call_tel
                }

            }
            return {
                ...state,
                loading: false,
                data: {
                    ...state.data,
                    ...call,
                }
            }
        }
        case FETCH_CALL_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        }
        case EDIT_CALL_COLUMN : {
            let data = action.payload.data;
            localStorage.setItem('call', JSON.stringify({
                data: {
                    ...state.data,
                    ...data
                }
            }));

            return {
                ...state,
                data: {
                    ...state.data,
                    ...data
                }
            }
        }
        case RESET_CALL : {
            let resetData = {
                ...initialState,
               data : {
                   ...initialState.data,
                   call_detail:[],
               }
            }

            return resetData;
        }
        case FETCH_CALL_CREATE_NEW : {// fetch call khi co cuoc goi den set lai redux
            return {
                ...state,
                loading: false,
                data: {
                    ...state.data,
                    ...action.payload.data,
                }
            }
        }
        case FETCH_CALL_KEY_SEARCH_SUCCESS: {
            let call = action.payload.data;
            // xu li khi cuoc goi khong co  call_cust_hp va call_cust_tel thi mac dinh 1 so
            if(!call.call_cust_hp && !call.call_cust_tel){
                var sliceString = call.call_tel.slice(0,3);
                if(dauSo.find(element => element == sliceString)){// tim
                    call.call_cust_hp = call.call_tel
                }else{
                    call.call_cust_tel  = call.call_tel
                }

            }
            return {
                ...state,
                loading: false,
                data: {
                    ...state.data,
                    ...call,
                }
            }
        }
        case FETCH_CALL_KEY_SEARCH_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        }
        case SET_CALL: {
            return {
                ...state,
                loading: false,
                data: {
                    ...state.data,
                    ...action.payload,
                }
            }
        }
        default:
            return state;
    }
}

export default call;