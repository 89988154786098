import React, {Fragment} from 'react'
import Logo from '../../../assets/css/documentation/img/logo.png';
import { Link} from 'react-scroll';
import HasRole from "../../../utils/entrust/HasRole";
import {NavLink} from 'react-router-dom';
import {FormattedMessage, injectIntl} from "react-intl";
import * as routerName from "../../../utils/routerName";
const sideBar = props => {

    return (
        <Fragment>
            <nav className="navbar navbar-expand-lg navbar-dark fixed-top" id="sideNav">
                <NavLink className="navbar-brand js-scroll-trigger" to="/">
                    {/*<span className="d-block d-lg-none"></span>*/}
                    {/*<span className="d-none d-lg-block">*/}
                        {/*<img className="img-fluid img-profile rounded-circle mx-auto mb-2" src={Logo} alt="" />*/}
                    {/*</span>*/}
                </NavLink>
                {/*<a className="navbar-brand js-scroll-trigger" href="#page-top">*/}
                    {/*<span className="d-block d-lg-none"></span>*/}
                    {/*<span className="d-none d-lg-block">*/}
          {/*<img className="img-fluid img-profile rounded-circle mx-auto mb-2" src={Logo} alt="" />*/}
        {/*</span>*/}
                {/*</a>*/}
                <button className="navbar-toggler" type="button" data-toggle="collapse"
                        onClick={() => props.toggleMenu()}
                        data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className={props.visible ? "collapse navbar-collapse display-block" : "collapse navbar-collapse display-nones"}
                     id="navbarSupportedContent">
                    <ul className="main-menu">
                        <li>
                            <p><FormattedMessage
                                id="dashboard.Start"
                                defaultMessage="Start"
                            /></p>
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <Link activeClass="active" className="nav-link js-scroll-trigger" onClick={() => props.toggleMenu()} to="about" spy={true} smooth={true} offset={0} duration={500} onSetActive={() => props.handleSetActive}>
                                        <FormattedMessage
                                            id="dashboard.Introduction"
                                            defaultMessage="Introduction"
                                        />
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    {/*<a className="nav-link js-scroll-trigger" onClick={() => props.handleTopClick('experience')} href="#experience">Experience</a>*/}
                                    <Link activeClass="active" className="nav-link js-scroll-trigger" onClick={() => props.toggleMenu()} to="experience" spy={true} smooth={true} offset={0} duration={500} onSetActive={() => props.handleSetActive}>
                                        <FormattedMessage
                                            id="dashboard.Configuration"
                                            defaultMessage="Configuration"
                                        />
                                    </Link>
                                </li>
                                {/*<li className="nav-item">*/}
                                    {/*/!*<a className="nav-link js-scroll-trigger" onClick={() => props.handleTopClick('education')} href="#education">Education</a>*!/*/}
                                    {/*<Link activeClass="active" className="nav-link js-scroll-trigger" onClick={() => props.toggleMenu()} to="education" spy={true} smooth={true} offset={0} duration={500} onSetActive={() => props.handleSetActive}>*/}
                                        {/*Education*/}
                                    {/*</Link>*/}
                                {/*</li>*/}
                                {/*<li className="nav-item">*/}
                                    {/*/!*<a className="nav-link js-scroll-trigger" onClick={() => props.handleTopClick('skills')} href="#skills">Skills</a>*!/*/}
                                    {/*<Link activeClass="active" className="nav-link js-scroll-trigger" onClick={() => props.toggleMenu()} to="skills" spy={true} smooth={true} offset={0} duration={500} onSetActive={() => props.handleSetActive}>*/}
                                        {/*Skills*/}
                                    {/*</Link>*/}
                                {/*</li>*/}
                                {/*<li className="nav-item">*/}
                                    {/*/!*<a className="nav-link js-scroll-trigger" onClick={() => props.handleTopClick('interests')} href="#interests">Interests</a>*!/*/}
                                    {/*<Link activeClass="active" className="nav-link js-scroll-trigger" onClick={() => props.toggleMenu()} to="interests" spy={true} smooth={true} offset={0} duration={500} onSetActive={() => props.handleSetActive}>*/}
                                        {/*Interests*/}
                                    {/*</Link>*/}
                                {/*</li>*/}
                                {/*<li className="nav-item">*/}
                                    {/*/!*<a className="nav-link js-scroll-trigger" onClick={() => props.handleTopClick('awards')} href="#awards">Awards</a>*!/*/}
                                    {/*<Link activeClass="active" className="nav-link js-scroll-trigger" onClick={() => props.toggleMenu()} to="awards" spy={true} smooth={true} offset={0} duration={500} onSetActive={() => props.handleSetActive}>*/}
                                        {/*Awards*/}
                                    {/*</Link>*/}
                                {/*</li>*/}
                            </ul>
                        </li>
                        <li>
                            <p><FormattedMessage
                                id="dashboard.Category"
                                defaultMessage="Category"
                            /></p>
                            <ul className="navbar-nav">
                                <HasRole role={'primarynet'}>
                                    <li className="nav-item">
                                        <NavLink to={`/${routerName.DOCUMENTATION}/${routerName.INSTRUCTIONS}/${routerName.USER_REGISTRATION}`}
                                                 className="nav-link js-scroll-trigger"
                                                 exact
                                                 activeClassName="active">
                                            <FormattedMessage
                                                id="dashboard.UserRegistration"
                                                defaultMessage="User Registration"
                                            />
                                        </NavLink>
                                    </li>
                                </HasRole>
                                <HasRole role={'primarynet'}>
                                    <li className="nav-item">
                                        <NavLink to={`/${routerName.DOCUMENTATION}/${routerName.INSTRUCTIONS}/${routerName.CUSTOMER_LIST}`}
                                                 className="nav-link js-scroll-trigger"
                                                 exact
                                                 activeClassName="active">
                                            <FormattedMessage
                                                id="dashboard.CustomerList"
                                                defaultMessage="Customer List"
                                            />
                                        </NavLink>
                                    </li>
                                </HasRole>
                                <HasRole role={['primarynet', 'manager', 'admin' ,'telephone', 'visiting']}>
                                    <li className="nav-item">
                                        <NavLink to={`/${routerName.DOCUMENTATION}/${routerName.INSTRUCTIONS}/${routerName.TELEPHONE}`}
                                                 className="nav-link js-scroll-trigger"
                                                 exact
                                                 activeClassName="active">
                                            <FormattedMessage
                                                id="dashboard.TelephoneCounselling"
                                                defaultMessage="Telephone Counselling"
                                            />
                                        </NavLink>
                                    </li>
                                </HasRole>
                                <HasRole role={['primarynet', 'admin', 'manager']}>
                                    <li className="nav-item">
                                        <NavLink to={`/${routerName.DOCUMENTATION}/${routerName.INSTRUCTIONS}/${routerName.STATISTICS}`}
                                                 className="nav-link js-scroll-trigger"
                                                 exact
                                                 activeClassName="active">
                                            <FormattedMessage
                                            id="dashboard.Statistics"
                                            defaultMessage="Statistics"
                                            />
                                        </NavLink>
                                    </li>
                                </HasRole>
                                <HasRole role={['primarynet', 'admin']}>
                                    <li className="nav-item">
                                        <NavLink to={`/${routerName.DOCUMENTATION}/${routerName.INSTRUCTIONS}/${routerName.CUSTOMER_MANAGEMENT}`}
                                                 className="nav-link js-scroll-trigger"
                                                 exact
                                                 activeClassName="active">
                                            <FormattedMessage
                                                id="dashboard.CustomerManagement"
                                                defaultMessage="Customer Management"
                                            />
                                        </NavLink>
                                    </li>
                                </HasRole>
                                <HasRole role={['primarynet', 'admin']}>
                                    <li className="nav-item">
                                        <NavLink to={`/${routerName.DOCUMENTATION}/${routerName.INSTRUCTIONS}/${routerName.DATA_SETTING}`}
                                                 className="nav-link js-scroll-trigger"
                                                 exact
                                                 activeClassName="active">
                                            <FormattedMessage
                                                id="dashboard.DataSetting"
                                                defaultMessage="Data Setting"
                                            />
                                        </NavLink>
                                    </li>
                                </HasRole>
                                <HasRole role={['primarynet', 'admin', 'manager']}>
                                    <li className="nav-item">
                                        <NavLink to={`/${routerName.DOCUMENTATION}/${routerName.INSTRUCTIONS}/${routerName.SMS_SETTING}`}
                                                 className="nav-link js-scroll-trigger"
                                                 exact
                                                 activeClassName="active">
                                            <FormattedMessage
                                                id="dashboard.SmsSetting"
                                                defaultMessage="SMS/MMS"
                                            />
                                        </NavLink>
                                    </li>
                                </HasRole>
                            </ul>
                        </li>
                        <li className="main-back">
                            <NavLink className="btn back-home" to="/">
                                <i className="fa fa-arrow-left"></i>
                                <FormattedMessage
                                    id="dashboard.BackToHome"
                                    defaultMessage="BackToHome"
                                />
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </nav>
        </Fragment>
    )
};


export default sideBar;
