import React from 'react';
import {Nav, NavItem, NavLink} from "reactstrap";

function NavTabReceived (props) {

    const {
        callTabReceived,
        changeCallReceived
    } = props;

    return (
        <Nav tabs className={'nav_tabs child'}>
            <NavItem className={'nav_item'}>
                <NavLink
                    className={callTabReceived == 0 ? 'active nav_link' : 'nav_link'}
                    onClick={()=>changeCallReceived(0)} // cuoc goi den
                >
                    수신콜
                </NavLink>
            </NavItem>
            <NavItem className={'nav_item'}>
                <NavLink
                    className={callTabReceived == 2 ? 'active nav_link' : 'nav_link'}
                    onClick={()=>changeCallReceived(2)} // cuoc goi den
                >
                    금일 부재콜
                </NavLink>
            </NavItem>
        </Nav>
    )
}

export default NavTabReceived;