import React from 'react';


function ItemSummaryBox ({
                             valueOfFirstItemSummaryBox,
                             valueOfSecondItemSummaryBox,
                             totalOfCounselling,
                            call
}) {
    return (
        <div className="custom_table">
            <table className="table st_table_3">
                <tbody>
                <tr>
                    <th>호응도</th>
                    <td >
                        {valueOfFirstItemSummaryBox}
                    </td>
                </tr>
                <tr>
                    <th>희망평형</th>
                    <td >
                        {valueOfSecondItemSummaryBox}
                    </td>
                </tr>
                <tr>
                    <th>상담횟수</th>
                    <td>{totalOfCounselling}</td>
                </tr>
                <tr>
                    <th>수신동의</th>
                    <td>
                        {call && call.call_agree === '1' ? 'No' : (call.call_agree === null ? '' : 'Yes')}
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    )
}

export default ItemSummaryBox;