import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import * as routerName from "../../utils/routerName";
import { FormattedMessage } from "react-intl";
import { NavLink, Link, Switch } from 'react-router-dom';
import HasRole from "../../utils/entrust/HasRole";
import { hasRole } from '../../utils/entrust/entrust';
import { Collapse, Button, CardBody, Card } from 'reactstrap';
// import 'react-datepicker/dist/react-datepicker.css';


class DataSetting extends Component {

    constructor(props) {
        super(props);
        console.log(this.props, 'this.props2');


        // let currentPath = this.props.location.pathname.substr(this.props.location.pathname.lastIndexOf('/') + 1);
        // let userPath = '';
        // if (hasRole(this.props.auth.user, 'primarynet')) {
        //     userPath = routerName.COMPANY_REGISTRATION;
        // } else {
        //     userPath = routerName.AFFILITED_COMPANY_MANAGEMENT;
        // }

        // if (currentPath === (`${routerName.DATA_SETTING}`)) {
        //     currentPath = currentPath === routerName.DATA_SETTING ? userPath : currentPath;
        //     this.props.history.push(`/${routerName.DASHBOARD}/${routerName.DATA_SETTING}/${currentPath}`);
        // }

        this.state = {
            collapseSMS: false,
            collapseMgmAdmin: false
        };
    }
    toggleSMS = () => {
        // debugger
        // this.props.history.push(`/${routerName.DASHBOARD}/${routerName.DATA_SETTING}/${routerName.SMS_MMS}/${routerName.SMS_TEXT_MANAGEMENT}`);
        this.setState({
            collapseSMS: !this.state.collapseSMS,
            collapseMgmAdmin: false
        }, () => {
            console.log(this.state, 'sssss');
        });
    }
    toggleMgmAdmin = () => {
        // this.props.history.push(`/${routerName.DASHBOARD}/${routerName.DATA_SETTING}/${routerName.MGM_ADMIN}/${routerName.MGM_USER_MANGER}`);
        this.setState({
            collapseMgmAdmin: !this.state.collapseMgmAdmin,
            collapse: false
        });
    }

    componentDidMount() {
        // let currentPath = this.props.location.pathname.substr(this.props.location.pathname.lastIndexOf('/') + 1);
        // if (routerName.MGM_USER_MANGER === currentPath || routerName.MGM_PLACEMENT === currentPath) {
        //     this.setState({
        //         collapseMgmAdmin: true
        //     })
        // }

        // if (routerName.SMS_BULK_SENDING === currentPath
        //     || routerName.SMS_TEXT_MANAGEMENT === currentPath
        //     || routerName.SMS_HISTORY === currentPath
        //     || routerName.TEXT_ON_OFF === currentPath) {
        //     this.setState({
        //         collapse: true
        //     })
        // }
    }

    removeActive = () => {
        this.setState({
            collapseMgmAdmin: false,
            collapse: false
        })
    }

    checkPathSMS = (path) => {
        let basePath = `/${routerName.DASHBOARD}/${routerName.DATA_SETTING}/${routerName.SMS_MMS}`
        return path.includes(basePath);
    }
    
    checkPathMGM = (path) => {
        let basePath = `/${routerName.DASHBOARD}/${routerName.DATA_SETTING}/${routerName.MGM_ADMIN}`
        return path.includes(basePath);
    }

    render() {
        const { location } = this.props;
        const { auth } = this.props;
        
        return (
            <Fragment>
                <ul className="list_menu_preferences">
                    <li className={location?.pathname == `/${routerName.DASHBOARD}/${routerName.DATA_SETTING}/${routerName.COMPANY_REGISTRATION ||
                                `/${routerName.DASHBOARD}/${routerName.DATA_SETTING}/${routerName.AFFILITED_COMPANY_MANAGEMENT}` }` ? 'active' : '' }>
                        <HasRole role={['primarynet']}>
                            <NavLink
                                to={`/${routerName.DASHBOARD}/${routerName.DATA_SETTING}/${routerName.COMPANY_REGISTRATION}`}
                                exact                               
                                activeClassName="active"> 
                                <FormattedMessage
                                    id="dashboard.CompanyRegistration"
                                    defaultMessage="Company registration" />
                            </NavLink>
                        </HasRole>
                        <HasRole role={['admin']}>
                            <NavLink
                                to={`/${routerName.DASHBOARD}/${routerName.DATA_SETTING}/${routerName.AFFILITED_COMPANY_MANAGEMENT}`}
                                exact
                                activeClassName="active">
                                <FormattedMessage
                                    id="dashboard.AffiliatedCompanyManagement"
                                    defaultMessage="Affiliated Company Management" />
                            </NavLink>
                        </HasRole>
                    </li>
                    <li className={location?.pathname == `/${routerName.DASHBOARD}/${routerName.DATA_SETTING}/${routerName.ITEM_MANAGEMENT}` ? 'active' : ''}>
                        <NavLink
                            to={`/${routerName.DASHBOARD}/${routerName.DATA_SETTING}/${routerName.ITEM_MANAGEMENT}`}
                            exact
                            activeClassName="active">
                            <FormattedMessage
                                id="dashboard.ItemManagement"
                                defaultMessage="Items management" />
                        </NavLink>
                    </li>
                    <li className={location?.pathname == `/${routerName.DASHBOARD}/${routerName.DATA_SETTING}/${routerName.TERRITORY_MANAGEMENT}` ? 'active' : ''}>
                        <NavLink
                            to={`/${routerName.DASHBOARD}/${routerName.DATA_SETTING}/${routerName.TERRITORY_MANAGEMENT}`}
                            exact
                            activeClassName="active">
                            <FormattedMessage
                                id="dashboard.TerritoryManagement"
                                defaultMessage="Territory management" />
                        </NavLink>
                    </li>
                    <li className={location?.pathname == `/${routerName.DASHBOARD}/${routerName.DATA_SETTING}/${routerName.CALL_HISTORY}` ? 'active' : ''}>
                        <NavLink
                            to={`/${routerName.DASHBOARD}/${routerName.DATA_SETTING}/${routerName.CALL_HISTORY}`}
                            exact
                            activeClassName="active">
                            <FormattedMessage
                                id="dashboard.CallHistory"
                                defaultMessage="Call history" />
                        </NavLink>
                    </li>
                    <li className={location?.pathname == `/${routerName.DASHBOARD}/${routerName.DATA_SETTING}/${routerName.COUNSELOR_MANAGEMENT}` ? 'active' : ''}>
                        <NavLink
                            to={`/${routerName.DASHBOARD}/${routerName.DATA_SETTING}/${routerName.COUNSELOR_MANAGEMENT}`}
                            exact
                            activeClassName="active">
                            <FormattedMessage
                                id="dashboard.CounselorManagement"
                                defaultMessage="Counselor management" />
                        </NavLink>
                    </li>
                    <li className={location?.pathname == `/${routerName.DASHBOARD}/${routerName.DATA_SETTING}/${routerName.LINE_INFORMATION}` ? 'active' : ''}>
                        <NavLink
                            to={`/${routerName.DASHBOARD}/${routerName.DATA_SETTING}/${routerName.LINE_INFORMATION}`}
                            exact
                            activeClassName="active">
                            <FormattedMessage
                                id="dashboard.LineInformation"
                                defaultMessage="Line Information" />
                        </NavLink>
                    </li>

                    <HasRole role={['primarynet']}>
                        <li>
                            <NavLink
                                to={`/${routerName.DASHBOARD}/${routerName.DATA_SETTING}/${routerName.MGM_ADMIN}/${routerName.MGM_USER_MANGER}`} 
                                activeClassName="active">
                                <FormattedMessage
                                    id="mgm.admin.mgm_management"
                                    defaultMessage="MGM management" />
                            </NavLink>
                            <Collapse isOpen={this.checkPathMGM(location?.pathname)} >
                                <ul className="dropdown_preferents show">
                                    <li className={location?.pathname == `/${routerName.DASHBOARD}/${routerName.DATA_SETTING}/${routerName.MGM_ADMIN}/${routerName.MGM_USER_MANGER}` ? 'active' : ''}>
                                        <NavLink to={`/${routerName.DASHBOARD}/${routerName.DATA_SETTING}/${routerName.MGM_ADMIN}/${routerName.MGM_USER_MANGER}`}
                                            exact
                                            activeClassName="active">
                                            <FormattedMessage
                                                id="mgm.admin.real_estate_agent"
                                                defaultMessage="Real estate agent" />
                                        </NavLink>
                                    </li>
                                    <li className={location?.pathname == `/${routerName.DASHBOARD}/${routerName.DATA_SETTING}/${routerName.MGM_ADMIN}/${routerName.MGM_PLACEMENT}` ? 'active' : ''}>
                                        <NavLink to={`/${routerName.DASHBOARD}/${routerName.DATA_SETTING}/${routerName.MGM_ADMIN}/${routerName.MGM_PLACEMENT}`}
                                            exact
                                            activeClassName="active">
                                            <FormattedMessage
                                                id="mgm.admin.registered_placement"
                                                defaultMessage="Registered placement" />
                                        </NavLink>
                                    </li>
                                </ul>
                            </Collapse>
                        </li>
                    </HasRole>

                    <HasRole role={['primarynet']}>
                        <li>
                            <NavLink
                                to={`/${routerName.DASHBOARD}/${routerName.DATA_SETTING}/${routerName.NOTICE}`} 
                                activeClassName="active">
                                <FormattedMessage
                                    id="dashboard.datasetting.notice"
                                    defaultMessage="Notice" />
                            </NavLink>
                        </li>
                    </HasRole>

                </ul>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
};

const mapDispatchToProps = dispatch => {
    return {}
};


export default connect(mapStateToProps, mapDispatchToProps)(DataSetting);
