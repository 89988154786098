import React from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

const ModalDelete = (props) => {
    const {
        isOpen,
        toggle,
        item,
        deleteItem ,// function
    } = props;
    return (
        <Modal
            isOpen={isOpen}
            toggle={()=>toggle()}
            className={'modal-dialog-scrollable'}
            wrapClassName={'modal_notification_edit custom_modal_sum'}
        >
            <div className="modal-header">
                <h5 className="modal-title">Warning</h5>
                <button
                    type="button" className="close"
                    onClick={()=>toggle()}
                >
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div className="modal-body">
                <div className="box_notification">
                    선택된 데이터는 삭제됩니다.
                </div>
            </div>
            <div className="modal-footer">
                <button
                    type="button"
                    className="btn btn_light "
                    onClick={()=>toggle()}
                >취소</button>
                <button
                    type="button"
                    className="btn btn_primary"
                    onClick={()=>deleteItem(item)}
                >확인</button>
            </div>
        </Modal>
    )
}

export default ModalDelete;