import React, {Fragment} from 'react'
import company_registration from '../../assets/images/document/data_setting/company_registration.png';
import item_management from '../../assets/images/document/data_setting/item_management.png';
import item_management_admin from '../../assets/images/document/data_setting/admin/item_management.png';
import territory_management from '../../assets/images/document/data_setting/territory_management.png';
import territory_management_admin from '../../assets/images/document/data_setting/admin/territory_management.png';
import call_history from '../../assets/images/document/data_setting/call_history.png';
import call_history_admin from '../../assets/images/document/data_setting/admin/call_history.png';
import counselor_management from '../../assets/images/document/data_setting/counselor_management.png';
import counselor_management_admin from '../../assets/images/document/data_setting/admin/counselor_management.png';
import line_info from '../../assets/images/document/data_setting/line_info.png';
import line_info_admin from '../../assets/images/document/data_setting/admin/line_info.png';
import affiliated_company_management from '../../assets/images/document/data_setting/admin/affiliated_company_management.png';
import HasRole from "../../utils/entrust/HasRole";

import DataSetting_1 from '../../assets/images/documentV2/data_setting/data_setting_1.png';
import DataSetting_2 from '../../assets/images/documentV2/data_setting/data_setting_2.png';
import DataSetting_3 from '../../assets/images/documentV2/data_setting/data_setting_3.png';
import DataSetting_4 from '../../assets/images/documentV2/data_setting/data_setting_4.png';
import DataSetting_5 from '../../assets/images/documentV2/data_setting/data_setting_5.png';
import DataSetting_6 from '../../assets/images/documentV2/data_setting/data_setting_6.png';
import DataSetting_7 from '../../assets/images/documentV2/data_setting/data_setting_7.png';
import DataSetting_8 from '../../assets/images/documentV2/data_setting/data_setting_8.png';
import DataSetting_9 from '../../assets/images/documentV2/data_setting/data_setting_9.png';
import DataSetting_10 from '../../assets/images/documentV2/data_setting/data_setting_10.png';
import DataSetting_11 from '../../assets/images/documentV2/data_setting/data_setting_11.png';
import DataSetting_12 from '../../assets/images/documentV2/data_setting/data_setting_12.png';

const DataSettingDoc = props => {

    return (
        <Fragment>
            {/* <div className="row m-0">
                <div className="col-md-12 documentation-user-registration">
                    <h3 className='text-align-center pt-3 pb-5'>환경설정</h3>
                    <HasRole role={'admin'}>
                        <div className='row mb-4'>
                            <div className='col-sm-12'>
                                <h5><span className='fa fa-square mr-2'></span>업체명 등록</h5>
                                <div className="row">
                                    <div className='col-sm-8'>
                                        <img className='pull-left' src={affiliated_company_management} alt='' width='100%'/>
                                    </div>
                                    <div className='col-sm-4'>
                                        <ol className='pull-left' style={{listStyle: 'none'}}>
                                            <li>(1) 추가</li>
                                            <li>(2) 수정</li>
                                            <li>(3) 삭제</li>
                                            <li>(4) 선택된 데이터 수정</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </HasRole>
                    <HasRole role={'primarynet'}>
                        <div className='row mb-4'>
                            <div className='col-sm-12'>
                                <h5><span className='fa fa-square mr-2'></span>업체명 등록</h5>
                                <div className="row">
                                    <div className='col-sm-8'>
                                        <img className='pull-left' src={company_registration} alt='' width='100%'/>
                                    </div>
                                    <div className='col-sm-4'>
                                        <ol className='pull-left' style={{listStyle: 'none'}}>
                                            <li>(1) 업체 리스트</li>
                                            <li>(2) 등록현장 리스트</li>
                                            <li>(3) 분양 개요
                                                - 등록현장 선택 후 수정 아이콘 클릭 한 후 데이터 입력 가능</li>
                                            <li>(4) 추가아이콘<br/>
                                                - 업체 혹은 등록현장 한 개씩 추가함
                                                - 동일한 업체명 혹은 등록현장명은 등록이 안됨
                                            </li>
                                            <li>
                                                (5) 삭제아이콘<br/>
                                                - 업체 혹은 등록현장 한 개씩 삭제함
                                                - 삭제 시 업체와 연관된 관리지역, 상담항목, 상담원이 모두 삭제 됨
                                            </li>
                                            <li>(6) 수정아이콘
                                                - 업체 혹은 등록현장을 수정함
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </HasRole>
                    <div className='row mb-4'>
                        <div className='col-sm-12'>
                            <h5><span className='fa fa-square mr-2'></span>상담항목 설정</h5>
                            <div className="row">
                                <div className='col-sm-8'>
                                    <HasRole role={['primarynet', 'manager','telephone', 'visiting']}>
                                        <img className='pull-left' src={item_management} alt='' width='100%'/>
                                    </HasRole>
                                    <HasRole role={['admin']}>
                                        <img className='pull-left' src={item_management_admin} alt='' width='100%'/>
                                    </HasRole>

                                </div>
                                <div className='col-sm-4'>
                                    <HasRole role={['primarynet']}>
                                    <ol className='pull-left'  style={{listStyle: 'none'}}>
                                        <li>(1) 업체를 먼저 선택함</li>
                                        <li>(2) 선택한 업체의 상담항목 표시<br/>
                                            - +아이콘: 상담항목 추가<br/>
                                            - 상당항목: 상담항목명 입력 (빈칸을 입력하면 안되고, 이름이 중복되면 안됨)<br/>
                                            - 순서: 화면에 표시되는 순서 (숫자, 빈칸 상관없음)<br/>
                                            - 고정: Yes/No 화면 표시 여부<br/>
                                        </li>
                                        <li>(3) 업체 선택 시 해당 세부항목 표시<br/>
                                            - + 아이콘: 세부항목 추가<br/>
                                            - 세부항목: 세부항목명 입력 (빈칸을 입력하면 안되고, 이름이 중복되면 안됨)<br/>
                                            - 순서: 화면에 표시되는 순서(숫자, 빈간 상관없음)<br/>
                                            - 사용: Yes/No 사용 여부<br/>
                                        </li>
                                    </ol>
                                    </HasRole>
                                    <HasRole role={['admin']}>
                                        <ol className='pull-left'  style={{listStyle: 'none'}}>
                                            <li>(1) 선택한 업체의 상담항목 표시<br/>
                                                - +아이콘: 상담항목 추가<br/>
                                                - 상당항목: 상담항목명 입력 (빈칸을 입력하면 안되고, 이름이 중복되면 안됨)<br/>
                                                - 순서: 화면에 표시되는 순서 (숫자, 빈칸 상관없음)<br/>
                                                - 고정: Yes/No 화면 표시 여부<br/>
                                            </li>
                                            <li>(2) 업체 선택 시 해당 세부항목 표시<br/>
                                                - + 아이콘: 세부항목 추가<br/>
                                                - 세부항목: 세부항목명 입력 (빈칸을 입력하면 안되고, 이름이 중복되면 안됨)<br/>
                                                - 순서: 화면에 표시되는 순서(숫자, 빈간 상관없음)<br/>
                                                - 사용: Yes/No 사용 여부<br/>
                                            </li>
                                        </ol>
                                    </HasRole>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mb-4'>
                        <div className='col-sm-12'>
                            <h5><span className='fa fa-square mr-2'></span>관리지역 설정</h5>
                            <div className="row">
                                <div className='col-sm-8'>
                                    <HasRole role={['primarynet', 'manager','telephone', 'visiting']}>
                                        <img className='pull-left' src={territory_management} alt='' width='100%' />
                                    </HasRole>
                                    <HasRole role={['admin']}>
                                        <img className='pull-left' src={territory_management_admin} alt='' width='100%'/>
                                    </HasRole>
                                </div>
                                <div className='col-sm-4'>
                                    <HasRole role={['admin']}>
                                        <ol className='pull-left'  style={{listStyle: 'none'}}>
                                            <li>(1) 관리지역 추가<br/>
                                                - 이름: 관리지역명 입력 (빈칸을 입력하면 안되고, 이름이 중복되면 안됨)<br/>
                                                - 순서: 화면에 표시되는 순서 (숫자,빈칸 상관없음)<br/>
                                            </li>
                                            <li>(2) 관리지역 수정</li>
                                            <li>(3) 관리지역 삭제</li>
                                        </ol>
                                    </HasRole>
                                    <HasRole role={['primarynet']}>
                                        <ol className='pull-left'  style={{listStyle: 'none'}}>
                                            <li>(1) 업체 선택</li>
                                            <li>(2) 관리지역 추가<br/>
                                                - 이름: 관리지역명 입력 (빈칸을 입력하면 안되고, 이름이 중복되면 안됨)<br/>
                                                - 순서: 화면에 표시되는 순서 (숫자,빈칸 상관없음)<br/>
                                            </li>
                                            <li>(3) 관리지역 수정</li>
                                            <li>(4) 관리지역 삭제</li>
                                        </ol>
                                    </HasRole>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mb-4'>
                        <div className='col-sm-12'>
                            <h5><span className='fa fa-square mr-2'></span>통화이력 조회</h5>
                            <div className="row">
                                <div className='col-sm-8'>
                                    <HasRole role={['primarynet', 'manager','telephone', 'visiting']}>
                                        <img className='pull-left' src={call_history} alt='' width='100%' />
                                    </HasRole>
                                    <HasRole role={['admin']}>
                                        <img className='pull-left' src={call_history_admin} alt='' width='100%'/>
                                    </HasRole>
                                </div>
                                <div className='col-sm-4'>
                                    <ol className='pull-left'  style={{listStyle: 'none'}}>
                                        <li>(1) 유형: 시간, 일, 월별 데이터 유형 선택</li>
                                        <li>(2) 일자: 시작일과 종료일을 선택</li>
                                        <li>(3) 상담내역 이력 조회</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row mb-4'>
                        <div className='col-sm-12'>
                            <h5><span className='fa fa-square mr-2'></span>상담원 설정</h5>
                            <div className="row">
                                <div className='col-sm-8'>
                                    <HasRole role={['primarynet', 'manager','telephone', 'visiting']}>
                                        <img className='pull-left' src={counselor_management} alt='' width='100%' />
                                    </HasRole>
                                    <HasRole role={['admin']}>
                                        <img className='pull-left' src={counselor_management_admin} alt='' width='100%'/>
                                    </HasRole>
                                </div>
                                <div className='col-sm-4'>
                                    <HasRole role={['admin']}>
                                        <ol className='pull-left'  style={{listStyle: 'none'}}>
                                            <li>(1) 등록현장 선택</li>
                                            <li>(2) 상담원 추가<br/>
                                            </li>
                                            <li>(3) 상담원 수정</li>
                                            <li>(4) 상담원 삭제</li>
                                            <li>(5) 상담원 ID:  타 상담원과 중복 불가. 빈칸 등록 안됨. </li>
                                            <li>(6) 상담원명</li>
                                        </ol>
                                    </HasRole>
                                    <HasRole role={['primarynet']}>
                                        <ol className='pull-left'  style={{listStyle: 'none'}}>
                                            <li>(1) 업체 선택</li>
                                            <li>(2) 등록현장 선택</li>
                                            <li>(3) 상담원 추가<br/>
                                            </li>
                                            <li>(4) 상담원 수정</li>
                                            <li>(5) 상담원 삭제</li>
                                            <li>(6) 상담원 ID:  타 상담원과 중복 불가. 빈칸 등록 안됨. </li>
                                            <li>(7) 상담원명</li>
                                        </ol>
                                    </HasRole>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row mb-4'>
                        <div className='col-sm-12'>
                            <h5><span className='fa fa-square mr-2'></span>회선 정보</h5>
                            <div className="row">
                                <div className='col-sm-8'>
                                    <HasRole role={['primarynet', 'manager','telephone', 'visiting']}>
                                        <img className='pull-left' src={line_info} alt='' width='100%' />
                                    </HasRole>
                                    <HasRole role={['admin']}>
                                        <img className='pull-left' src={line_info_admin} alt='' width='100%'/>
                                    </HasRole>
                                </div>
                                <div className='col-sm-4'>

                                    <HasRole role={['primarynet', 'manager','telephone', 'visiting']}>
                                        <ol className='pull-left'  style={{listStyle: 'none'}}>
                                            <li>(1) 업체 선택</li>
                                            <li>(2) 회선정보 추가</li>
                                            <li>(3) 회선정보 수정</li>
                                            <li>(4) 회선정보 삭제</li>
                                            <li>(5) 회선번호: 데이터 중복 불가</li>
                                            <li>(6) 회선명: (빈칸 가능)</li>
                                        </ol>
                                    </HasRole>
                                    <HasRole role={['admin']}>
                                        <ol className='pull-left'  style={{listStyle: 'none'}}>
                                            <li>(1) 회선정보 추가</li>
                                            <li>(2) 회선정보 수정</li>
                                            <li>(3) 회선정보 삭제</li>
                                            <li>(4) 회선번호: 데이터 중복 불가</li>
                                            <li>(5) 회선명: (빈칸 가능)</li>
                                        </ol>
                                    </HasRole>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="row m-0">
                <div className="col-md-12 documentation-user-registration">
                    <h2 className="mt-3 mb-5">고객 관리</h2>
                    <div className="main-user-registration">
                        <p className="mt-5"><b>업체명 등록</b></p> <br />
                        <div className="row">
                            <div className="col-md-8 ">
                                <img width='100%' src={DataSetting_1}/>
                            </div>
                            <div className="col-md-4 pl-4 pr-0">
                                <p>(1) 업체 리스트</p>
                                <p>(2) 등록현장 리스트</p>
                                <p>(3) 분양 개요 - 등록현장 선택 후 수정 아이콘 클릭 한 후 데이터 입력 가능</p>
                                <p>(4) 추가아이콘 <br />
                                - 업체 혹은 등록현장 한 개씩 추가함 - 동일한 업체명 혹은 등록현장명은 등록이 안됨<br />
                                </p>
                                <p>(5) 삭제아이콘<br />
                                - 업체 혹은 등록현장 한 개씩 삭제함 - 삭제 시 업체와 연관된 관리지역, 상담항목, 상담원이 모두 삭제 됨</p>
                                <p>(6) 수정아이콘 - 업체 혹은 등록현장을 수정함</p>
                            </div>
                        </div>
                        <p className="mt-5"><b>상담항목 설정</b></p><br />
                        <div className="row">
                            <div className="col-md-8">
                                <img width='100%' src={DataSetting_2}/>
                            </div>
                            <div className="col-md-4 pl-4 pr-0">
                                <p>(1) 업체를 먼저 선택함</p>
                                <p>
                                (2) 선택한 업체의 상담항목 표시<br />
                                    +아이콘: 상담항목 추가<br />
                                    - 상당항목: 상담항목명 입력 (빈칸을 입력하면 안되고, 이름이 중복되면 안됨)<br />
                                    - 순서: 화면에 표시되는 순서 (숫자, 빈칸 상관없음)<br />
                                    - 고정: Yes/No 화면 표시 여부
                                </p>
                                <p>
                                    (3) 업체 선택 시 해당 세부항목 표시<br />
                                    + 아이콘: 세부항목 추가<br />
                                    - 세부항목: 세부항목명 입력 (빈칸을 입력하면 안되고, 이름이 중복되면 안됨)<br />
                                    - 순서: 화면에 표시되는 순서(숫자, 빈간 상관없음)<br />
                                    - 사용: Yes/No 사용 여부
                                </p>
                            </div>
                        </div>

                        <p className="mt-5"><b>관리지역 설정</b></p><br />
                        <div className="row">
                            <div className="col-md-8">
                                <img width='100%' src={DataSetting_3}/>
                            </div>
                            <div className="col-md-4 pl-4 pr-0">
                                <p>(1) 업체 선택</p>
                                <p>
                                (2) 관리지역 추가<br />
                                - 이름: 관리지역명 입력 (빈칸을 입력하면 안되고, 이름이 중복되면 안됨)<br />
                                - 순서: 화면에 표시되는 순서 (숫자,빈칸 상관없음)<br />
                                </p>
                                <p>(3) 관리지역 수정</p>
                                <p>(4) 관리지역 삭제</p>
                            </div>
                        </div>
                        <p className="mt-5"><b>통화이력 조회</b></p><br />
                        <div className="row">
                            <div className="col-md-8">
                                <img width='100%' src={DataSetting_4}/>
                            </div>
                            <div className="col-md-4 pl-4 pr-0">
                              <p>(1) 유형: 시간, 일, 월별 데이터 유형 선택</p>
                              <p>(2) 일자: 시작일과 종료일을 선택</p>
                              <p>(3) 상담내역 이력 조회</p>
                            </div>
                        </div>
                        <p className="mt-5"><b>상담원 설정</b></p><br />
                        <div className="row">
                            <div className="col-md-8">
                                <img width='100%' src={DataSetting_5}/>
                            </div>
                            <div className="col-md-4 pl-4 pr-0">
                                <p>(1) 업체 선택</p>
                                <p>(2) 등록현장 선택</p>
                                <p>(3) 상담원 추가</p>
                                <p>(4) 상담원 수정</p>
                                <p>(5) 상담원 삭제</p>
                                <p>(6) 상담원 : 타 상담원과 중복 불가. 빈칸 등록 안됨.</p>
                                <p>(7) 상담원명</p>
                            </div>
                        </div>
                        <p className="mt-5"><b>회선 정보</b></p><br />
                        <div className="row">
                            <div className="col-md-8">
                                <img width='100%' src={DataSetting_6}/>
                            </div>
                            <div className="col-md-4 pl-4 pr-0">
                                <p>(1) 업체 선택</p>
                                <p>(2) 회선정보 추가</p>
                                <p>(3) 회선정보 수정</p>
                                <p>(4) 회선정보 삭제</p>
                                <p>(5) 회선번호: 데이터 중복 불가</p>
                                <p>(6) 회선명: (빈칸 가능)</p>
                            </div>
                        </div>
                        <p className="mt-5"><b>문자설정 / 문자 관리</b></p><br />
                        <div className="row">
                            <div className="col-md-8">
                                <img width='100%' src={DataSetting_7}/>
                            </div>
                            <div className="col-md-4 pl-4 pr-0">
                                <p>(1) 업체 선택</p>
                                <p>(2) 등록현장 선택</p>
                                <p>(3) 예제 문자메시지 추가</p>
                                <p>(4) 상담원 수정</p>
                                <p>(5) 예제 문자메시지 삭제</p>
                                <p>(6)  예제 문자메시지 이름</p>
                                <p>(7) 발송 자동옵션: ON/OFF<br />
                                    - 현장 당 1개의 문자메시지를 위한 ON로 설정됨 혹은 모두 OFF로 해야 함</p>
                                <p>(8) 예제 문자메시지 내용</p>
                                <p>(9) 메시지 종류: SMS, MMS. <br />
                                - SMS : 텍스트만 가능<br />
                                - MMS : 텍스트 및 이미지 가능</p>
                            </div>
                        </div>
                        <p className="mt-5"><b>문자설정 / 대량발송</b></p><br />
                        <div className="row">
                            <div className="col-md-8 ">
                                <img width='100%' src={DataSetting_8}/>
                            </div>
                            <div className="col-md-4 pl-4 pr-0">

                                <p>(1) 예제 문자메시지 선택</p>
                                <p>(2) 전화번호 파일 업로드</p>
                                <p>(3) 파일추가 -클릭하여 업로드된 전화파일에 전화번호 리스트 표시</p>
                                <p>(4) 추가된 리스트 삭제</p>
                                <p>(5) 예제파일 다운로드</p>
                                <p>(6) 문자 발송</p>
                                <p>(7) 업로드된 전화번호 리스트</p>
                            </div>
                        </div>
                        <p className="mt-5"><b>문자설정/ 발송이력</b></p><br />
                        <div className="row">
                            <div className="col-md-8">
                                <img width='100%' src={DataSetting_9}/>
                            </div>
                            <div className="col-md-4 pl-4 pr-0">
                                <p>(1) 조회 시간 선택</p>
                                <p>(2) 발송이력은 일자, 전화번호, 이름, SMS, 발송내용이 포함</p>
                            </div>
                        </div>
                        <p className="mt-5"><b>문자설정/ 등록현장별 문자 이용 관리</b></p><br />
                        <div className="row">
                            <div className="col-md-8 ">
                                <img width='100%' src={DataSetting_10}/>
                            </div>
                            <div className="col-md-4 pl-4 pr-0">
                                <p>(1) 업체 선택</p>
                                <p>(2) 등록현장 및 등록현장별 문자 이용 관리 <br />
                                - 등록현장별 문자 이용 관리 : ON/OFF</p>
                                <p>(3) ON/OFF 상태 변환</p>
                            </div>
                        </div>
                        <p className="mt-5"><b>MGM 관리/ 회원관리</b></p><br />
                        <div className="row">
                            <div className="col-md-8">
                                <img width='100%' src={DataSetting_11}/>
                            </div>
                            <div className="col-md-4 pl-4 pr-0">
                            <p>(1) 업체 선택</p>
                                <p>(2) 조회 시간 선택</p>
                                <p>(3) 검색어 입력</p>
                                <p>(4) MGM 시스템에 링크하여 내용 표시</p>
                                <p>(5) 회원 삭제</p>
                            </div>
                        </div>
                        <p className="mt-5"><b>MGM 관리/ 등록현장</b></p><br />
                        <div className="row ">
                            <div className="col-md-8 ">
                                <img width='100%' src={DataSetting_12}/>
                            </div>
                            <div className="col-md-4 pl-4 pr-0">
                                <p>(1) 업체 선택</p>
                                <p>(2) 현장 선택</p>
                                <p>(3) MGM시스템에 링크하여 내용 표시</p>
                                <p>(4) 현장삭제</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default DataSettingDoc;