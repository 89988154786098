import moment from 'moment';
import _ from "lodash";
import * as routerName from "./../utils/routerName";

export const findIndex = (stores, id, name) => {
    let result = -1;
    stores.forEach((store, index) => {
        if (store[name] === id) {
            result = index;
        }
    });
    return result;
};


// export const colorPicker = () => {
//     let Colors = {};
//     Colors.names = {
//         aqua: "#00ffff",
//         //azure: "#f0ffff",
//         //beige: "#f5f5dc",
//         black: "#000000",
//         blue: "#0000ff",
//         brown: "#a52a2a",
//         cyan: "#00ffff",
//         darkblue: "#00008b",
//         darkcyan: "#008b8b",
//         darkgrey: "#a9a9a9",
//         darkgreen: "#006400",
//         darkkhaki: "#bdb76b",
//         darkmagenta: "#8b008b",
//         darkolivegreen: "#556b2f",
//         darkorange: "#ff8c00",
//         darkorchid: "#9932cc",
//         darkred: "#8b0000",
//         darksalmon: "#e9967a",
//         darkviolet: "#9400d3",
//         fuchsia: "#ff00ff",
//         gold: "#ffd700",
//         green: "#008000",
//         indigo: "#4b0082",
//         khaki: "#f0e68c",
//         lightblue: "#add8e6",
//         //lightcyan: "#e0ffff",
//         lightgreen: "#90ee90",
//         //lightgrey: "#d3d3d3",
//         lightpink: "#ffb6c1",
//         //lightyellow: "#ffffe0",
//         lime: "#00ff00",
//         magenta: "#ff00ff",
//         maroon: "#800000",
//         navy: "#000080",
//         olive: "#808000",
//         orange: "#ffa500",
//         pink: "#ffc0cb",
//         purple: "#800080",
//         violet: "#800080",
//         red: "#ff0000",
//         silver: "#c0c0c0",
//         //white: "#ffffff",
//         yellow: "#ffff00"
//     };
//     let result;
//     let count = 0;
//     for (let prop in Colors.names)
//         if (Math.random() < 1 / ++count)
//             result = prop;
//     return Colors.names[result];
// };


export const colorPicker = () => {
    let Colors = {};
    Colors.names = {
        a1: '#3366CC',
        a2: '#DC3912',
        a3: '#FF9900',
        a4: '#109618',
        a5: '#990099',
        a6: '#3B3EAC',
        a7: '#0099C6',
        a8: '#DD4477',
        a9: '#66AA00',
        a10: '#B82E2E',
        a11: '#316395',
        a12: '#994499',
        a13: '#22AA99',
        a14: '#AAAA11',
        a15: '#6633CC',
        a16: '#E67300',
        a17: '#8B0707',
        a18: '#329262',
        a19: '#5574A6',
        a20: '#3B3EAC',
    };
    let result;
    let count = 0;
    for (let prop in Colors.names)
        if (Math.random() < 1 / ++count)
            result = prop;
    return Colors.names[result];
};

export const arrayColor = [
        '#3366CC',
        '#DC3912',
        '#FF9900',
        '#109618',
        '#990099',
        '#3B3EAC',
        '#0099C6',
        '#DD4477',
        '#66AA00',
        '#B82E2E',
        '#316395',
        '#994499',
        '#22AA99',
        '#AAAA11',
        '#6633CC',
        '#E67300',
        '#8B0707',
        '#329262',
        '#5574A6',
        '#3B3EAC',
    ];


export function compare_dates(date1, date2, compare = '>') {
    date1 = new Date(date1)
    date1 = date1.getTime()
    date2 = new Date(date2)
    date2 = date2.getTime()

    if (compare == '>') {
        if (date1 > date2) return true
        return false
    }
    if (compare == '>=') {
        if (date1 >= date2) return true
        return false
    }
    if (compare == '=') {
        if (date1 == date2) return true
        return false
    }
    if (compare == '<=') {
        if (date1 <= date2) return true
        return false
    }
    if (compare == '<') {
        if (date1 < date2) return true
        return false
    }
}


export function convertIObjectToLocationSearch(params) {
    var queryString = Object.keys(params)
        .filter((param, index) => params[param] != null)
        .map(key => key + '=' + params[key]).join('&');

    return queryString
}

export function renderMessage(message) {
    let errorMessage = '';
    if (typeof message === 'object') {
        for (let key in message) {
            //let errorMessage = '';
            if (message.hasOwnProperty(key)) {
                message[key].map(row => {
                    errorMessage = errorMessage + row + '<br/>'
                });
            }
        }      
    } else {
        errorMessage = message;
    }

    // if (this._notificationSystem && errorMessage !== '') {
    //     this._notificationSystem.addNotification({
    //         ...this.props,
    //         title: this.props.title,
    //         message: errorMessage,
    //         level: this.props.level,
    //         position: this.props.position,
    //         onAdd: () => this.props.onAdd(),
    //         // ...this.props
    //     })
    // }

    return errorMessage
}

export function removeItemInArray(arr, item, deleteCount=1) {
    let index = arr.indexOf(item);
    arr.splice(index, deleteCount);
    return arr;
}


export function removeObjectInArray(arr, value, objectKey='name') {
    return arr.filter(item =>  {
        return item[`${objectKey}`] !== value;  
    });
}

export function checkObjectInArray(arr, value, objectKey='name') {
    return arr.findIndex(item => {
        return item[`${objectKey}`] == value;  
  })
}

export function findObjectInArray(arr, value, objectKey='name') {
    if (Array.isArray(arr)) {
        return arr.find(item => {
            return item[`${objectKey}`] == value;  
      })
    }
    return null
}

export function formatNumberPhone (phone)  {
    let valuePhone = '';
    phone = phone.replace(/[^0-9]/gi, '')
    // if(phone.length >= 10) {
    //     let arrayPhone = phone.split("");
    //     arrayPhone.map((item,index) => {
    //         // valuePhone += item
    //         if(index == 3) {
    //             valuePhone += '-'+item
    //         }else if(index == 7){
    //             valuePhone += '-'+item
    //         }
    //             // else if(index == 11){
    //             //     valuePhone += '-'+item
    //         // }
    //         else{
    //             valuePhone += item
    //         }
    //     })
    //     // console.log(valuePhone,'value ')
    //     return valuePhone;
    // }
    return phone
}

export function limitString(string, number) {
    if ( typeof string === 'string') {
        if (string.length <= number) {
            return string;
        } else {
            return string.substr(0, number) + '...';
        }
    }
    return string
}

export function renderStatusCall (call_check) {
    let content = '';
    if(call_check == 0){
        content = '수신콜'
    }else if(call_check == 1) {
        content = '발신콜'
    }else if(call_check == 2){
        content = '부재콜'
    }else if ( call_check == 4) {
        content = '부재중'
    }

    return content;
}


export function parseParamArray (params,type) {
    let parseJson = {};
    for (let i=0; i<params.length; i++) {
        parseJson = {
            ...parseJson,
            [`${type}[${i}]`] : params[i]
        }
    }
    return parseJson;
}

export function checkEveryIncArray(array, mainArray) {
    let checker = array.every(value => mainArray.includes(value));
    return checker;
}

export const roles = {
    'primarynet': 1,
    'admin'     : 2,
    'manager'   : 3,
    'telephone' : 4,
    'visiting'  : 5,
    'mgm'       : 6,
    'callback'  : 7,
}

export function phoneFormat_2 (phoneNumber) {
    // Strip all characters from the phoneNumber except digits
    phoneNumber = phoneNumber?.toString().replace(/\D/g,'');  
    console.log(phoneNumber, 'phoneNumber');  
    
    // Trim the remaining phoneNumber to ten characters, to preserve phone number format
    // phoneNumber = phoneNumber.substring(0,10);

    // Based upon the length of the string, we add formatting as necessary
    var size = phoneNumber.length;
    if (size == 0) {
        phoneNumber = phoneNumber;
    } else if (size < 4) {
        phoneNumber = phoneNumber;
    } else if (size <= 7) {
        phoneNumber = phoneNumber.substring(0, 3) + '-' + phoneNumber.substring(3, 7);
    } else {
        phoneNumber = phoneNumber.substring(0, 3) + '-' + phoneNumber.substring(3, 7) + '-' + phoneNumber.substring(7);
    }
    return phoneNumber;
}

export function checkValidPhone (phoneNumber) {
    // Strip all characters from the phoneNumber except digits
    phoneNumber = phoneNumber?.toString().replace(/\D/g,'');
    
    if (phoneNumber.length == 0) {
        return true
    } else if (phoneNumber.length <= 11) {
        // let checker = phoneNumber.match("^([0-9]|[\-])+$")
        let checker = phoneNumber.match("^([0-9])+$")
        return checker ? true : false;
    }

    return false
}

export function randomInRange(min, max) {
    return Math.random() < 0.5 ? ((1-Math.random()) * (max-min) + min) : (Math.random() * (max-min) + min);
}

export function getInfoDetailName(infoDetail) {
    if (infoDetail) {
        let tmp = infoDetail.split("__");
        let detailName = tmp[1];
        return detailName;
    }
    return ''
}

export function makeRandomChar(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }
 