import React,{useState} from 'react';
import {Modal} from 'reactstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import Pagination from "react-js-pagination";
import api from "../../../utils/api";
import _ from 'lodash';

function ModalReportTelePhone (props) {
    const {
        isOpen,
        toggle,
        submitDateReport,
        reportTelephone,
        sceneLostId,
				sceneCompanyId,
				statItem
    } = props;

		//this.state = {
		//		see_all: localStorage.see_history === '1' ? true : false,
		//};

    // const [startDate, setStartDate] = useState(moment().startOf('day'));
    // const [endDate, setEndDate] = useState(moment().startOf('hour').add(1, 'hour'));

    const [startDate, setStartDate] = useState(moment().startOf('day'));
    const [endDate, setEndDate] = useState(moment().startOf('hour').add(1, 'hour'));
    const [search, setSearch] = useState('');
    const [see_all, setSeeAll] = useState(localStorage.getItem("see_history") === '1' ? true : false);
		const [seeHistory, setSeeHistory] = useState(localStorage.getItem("see_history") === '1' ? true : false);

    const [paginate, setPaginate] = useState({
        itemsCountPerPage : 100, // tong item cua 1 phan trang
        pageRangeDisplayed : 3, // do keo dai cua 1 phan trang ex : 123....456
        activePage : 1, // active cua phan trang
        totalItemsCount : 0, // du lieu co may item,
        data : [], // xu li data de phan trang data xet lai cua pros
    });

    React.useEffect(() => {

			console.log("MODAL OPEN:reportTelephone",reportTelephone);
			console.log("MODAL OPEN:statItem",statItem);
			console.log("MODAL OPEN:statItem.length",statItem.length);

		},[statItem]);

//    React.useEffect(() => {
//			let param =  {
//					lots_id : sceneLostId,
//					company_id : sceneCompanyId,
//					see_all : see_all
//			}
//			// data 를 api 로 가져온다.
//			api.statistics.getDailyItemStatus(param)
//			.then(response => {
//				console.log("getDailyItemStatus response:",response);
//				setStatItem(response.data.data);
//			})
//			.catch(error => {
//					console.log("ERROR");
//			});
//
//		},[]);

    React.useEffect(() => {

        let receivedTotal = _.sumBy(reportTelephone, function (total) {
            return +total.received + +total.received_thieu_sot ;
        });

        let sendingTotal = _.sumBy(reportTelephone, function (total) {
            return +total.sending + +total.sending_thieu_sot;
        });

        // custom row cuoi total
        if(reportTelephone.length > 0) {
            reportTelephone.push({
                received : receivedTotal,
                sending : sendingTotal,
                time : 'Total'
            })
        }


        let dataPagination = _.dropRight(reportTelephone,reportTelephone.length - paginate.itemsCountPerPage);

        setPaginate({
            ...paginate,
            totalItemsCount : reportTelephone.length,
            data :dataPagination,
            activePage : 1, // set lai pagination khi click
        })

        return () => {
            // Clean up the subscription
            // setStartDate(moment().startOf('day'));
            // setEndDate(moment().startOf('hour').add(1, 'hour'));
            // setSearch('');
            // setPaginate({
            //     itemsCountPerPage : 10, // tong item cua 1 phan trang
            //     pageRangeDisplayed : 3, // do keo dai cua 1 phan trang ex : 123....456
            //     activePage : 1, // active cua phan trang
            //     totalItemsCount : 0, // du lieu co may item,
            //     data : [], // xu li data de phan trang data xet lai cua pros
            // })

        };


    },[reportTelephone]);

    function submitDate() {
        let data =  {
            date_start : moment(startDate).format("YYYY-MM-DD"),
            date_end : moment(endDate).format("YYYY-MM-DD"),
            search_key : search,
						see_all : see_all
        }
        submitDateReport(data)
    }

		function toggleAll(event) {
			event.preventDefault();
			setSeeAll(!see_all);
		}

    function changePage (page) {
        let dataPagination = [];
        let oldPage = page - 1;
        dataPagination = _.drop(reportTelephone,paginate.itemsCountPerPage * oldPage) // xoa du lieu pagination cu~
        dataPagination = _.dropRight(dataPagination,dataPagination.length - paginate.itemsCountPerPage) //lay du lieu hien tai
        setPaginate({
            ...paginate,
            data :dataPagination,
            activePage : page
        })
    }

    function onChangeSearch (e) {
        let value = e.target.value
        setSearch(value);
        if(reportTelephone.length > 0) {
            let reportTelephoneSearch = [];
            reportTelephone.map(item => {
                reportTelephoneSearch.push({
                    ...item,
                    searchYear : item.date.split("-")[0],
                    searchMonth : item.date.split("-")[1],
                    searchDate : item.date.split("-")[2]
                })
            })

        }
        let data =  {
            date_start : moment(startDate).format("YYYY-MM-DD"),
            date_end : moment(endDate).format("YYYY-MM-DD"),
						see_all : see_all,
            search_key : value
        }
        submitDateReport(data)

    }

    function checkTime(time) {
        let dateTime;
        if (time) {
            if (time == 24) {
                dateTime = `${time}시 ~ 0시` ;
            }else{
                dateTime = `${+time}시 ~ ${+time + 1}시` ;
            }
            return dateTime;
        }else{
            return '';
        }
    }

    function closeModal() {
				// 초기화
			if (localStorage.getItem("see_history") === '1') setSeeAll(true);
			else setSeeAll(false);

			setStartDate(moment().startOf('day'));
			setEndDate(moment().startOf('hour').add(1, 'hour'));
			setSearch('');
			setPaginate({
					itemsCountPerPage : 100, // tong item cua 1 phan trang
					pageRangeDisplayed : 3, // do keo dai cua 1 phan trang ex : 123....456
					activePage : 1, // active cua phan trang
					totalItemsCount : 0, // du lieu co may item,
					data : [], // xu li data de phan trang data xet lai cua pros
			})
    }


		function render_list_noheader(statItem) {

			console.log('statItem:',statItem);

			if (!statItem || statItem.length == 0) {
				return (
						<>
						<tr className={'text-center'}><td colSpan={3}>No Data</td></tr>
						</>
				)
			}
			var prev_item_name = ''; // init

			statItem.map((item,index) => {

				console.log('item:', item);
				console.log('prev_item_name:', prev_item_name);

				if (item.m_name != prev_item_name) {
					prev_item_name = item.m_name;
					return (
						<>
							<tr>
									<th width="50%">{item.m_name}</th>
									<th width="25%">금일</th>
									<th width="25%">누계</th>
							</tr>
							<tr key={index}>
									<td>{item.m_dtl_name}</td>
									<td>{item.count}</td>
									<td>{item.totalCount}</td>
							</tr>
						</>
						)
				} else {
					return (
						<>
							<tr key={index}>
									<td>{item.m_dtl_name}</td>
									<td>{item.count}</td>
									<td>{item.totalCount}</td>
							</tr>
						</>
						)
				}

			});

		}
//
//		function render_list(item) {
//
//			if (!item || item.length == 0) {
//				return (
//				<table className="table st_table_5">
//						<thead>
//						<tr>
//								<th width="35%">항목</th>
//								<th width="30%">세부</th>
//								<th width="15%">금일</th>
//								<th width="20%">누계</th>
//						</tr>
//						</thead>
//						<tbody>
//						<tr className={'text-center'}><td colSpan={4}>No Data</td></tr>
//						</tbody>
//				</table>
//				)
//
//			}
//
//			let prev_item_name = ''; // init
//
//			statItem.map((item,index) => {
//
//				if (item.m_name != prev_item_name) { // make header
//					if (prev_item_name != "") {
//						prev_item_name = item.m_name;
//						return (
//							</table>
//							<table className="table st_table_5">
//									<thead>
//									<tr>
//											<th width="35%">항목</th>
//											<th width="30%">세부</th>
//											<th width="15%">금일</th>
//											<th width="20%">누계</th>
//									</tr>
//									</thead>
//									<tbody>
//									<tr key={index}>
//											<td>{item.m_name}</td>
//											<td>{item.m_dtl_name}</td>
//											<td>{item.count}</td>
//											<td>{item.totalCount}</td>
//									</tr>
//							)
//					} else {
//						prev_item_name = item.m_name;
//						return (
//							<table className="table st_table_5">
//									<thead>
//									<tr>
//											<th width="35%">항목</th>
//											<th width="30%">세부</th>
//											<th width="15%">금일</th>
//											<th width="20%">누계</th>
//									</tr>
//									</thead>
//									<tbody>
//									<tr key={index}>
//											<td>{item.m_name}</td>
//											<td>{item.m_dtl_name}</td>
//											<td>{item.count}</td>
//											<td>{item.totalCount}</td>
//									</tr>
//							)
//					}
//				} else {
//					prev_item_name = item.m_name;
//					return (
//								<tr key={index}>
//										<td>{item.m_name}</td>
//										<td>{item.m_dtl_name}</td>
//										<td>{item.count}</td>
//										<td>{item.totalCount}</td>
//								</tr>
//						)
//
//				}
//
//			});
//
//		}

    return(
        <Modal
            isOpen={isOpen}
            toggle={()=>toggle()}
            className={'modal-xl modal-dialog-scrollable'}
            wrapClassName={'modal_report custom_modal_sum'}
            onClosed={closeModal}
        >
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">상담건수 통계자료</h5>
                    <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={()=>toggle()}
                    >
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="modal-body" >
                  <div className="row">
                    <div className="box_report col-7">
                        <div className="box_fitle">
                            <div className="box_left">
                                <div className="filler_to_from">
                                    <div className="i_tem txt_form">일자</div>
                                    <div className="i_tem box_control_input">
                                        <div className="box_control_date" style={{width:"150px"}}>
                                            <DatePicker
                                                className={'from_date form-control flatpickr-input'}
                                                popperClassName={'custom-width-poper'}
                                                selected={startDate}
                                                dateFormat="YYYY-MM-DD"
                                                // showTimeSelect
                                                onChange={(date) =>{
                                                    setStartDate(date);
                                                    if(date > endDate){
                                                        setEndDate(moment(date).startOf('hour').add(1, 'hour'))
                                                    }
                                                }}
                                                // timeFormat="HH:mm"
                                                startDate={startDate}
                                                endDate={endDate}
                                                selectsStart
                                                // timeIntervals={60}
                                                // minDate={startDate}
                                                // maxDate={endDate}
                                            />
                                            <span className="icon_date" />
                                        </div>
                                    </div>
                                    <div className="i_tem txt_form hide">~</div>
                                    <div className="i_tem box_control_input">
                                        <div className="box_control_date" style={{width:"150px"}}>
                                            <DatePicker
                                                className={'to_date form-control flatpickr-input'}
                                                popperClassName={'custom-width-poper'}
                                                selected={endDate}
                                                dateFormat="YYYY-MM-DD"
																								 style={{width:"140px"}}
                                                // showTimeSelect
                                                // timeFormat="HH:mm"
                                                onChange={(date) =>setEndDate(date)}
                                                startDate={startDate}
                                                endDate={endDate}
                                                selectsEnd
                                                minDate={startDate}
                                                // timeIntervals={60}
                                                // maxDate={endDate}
                                                // minTime={startDate}
                                                // maxTime={moment(startDate)}
                                            />
                                            <span className="icon_date" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="box_right">
														{
															seeHistory ?
															<button className="btn_control btn_002" onClick={toggleAll}>{`${see_all === true ? "모든콜" : "본인콜"}`}</button>
															: ''
														}
                            </div>
                            <div className="box_right">
                                <button className="btn_control btn_003" onClick={submitDate}>찾기</button>
                            </div>
                        </div>
                        <div className="box_body">
                            <div className="content" style={{
                                minHeight: '230px'
                            }}>
                                <div className="card_header">
                                    <div className="box_search">
                                        <div className="box_control_input ">
                                            <input
                                                name="keysearch"
                                                type="text"
                                                className="control"
                                                value={search}
                                                onChange={e=>onChangeSearch(e)}
                                            />
                                            <button
                                                className="btn_form btn_secondary"
                                            ><i className="fas fa-search" /></button>
                                        </div>
                                    </div>
                                </div>
                                <div className="custom_table limit_row" >
                                    <table className="table st_table_5">
                                        <thead>
                                        <tr>
                                            <th width="25%">시간</th>
                                            <th width="25%">수신콜</th>
                                            <th width="25%">발신콜</th>
                                            <th width="25%">전체 콜 수</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {paginate.totalItemsCount > 0 ? paginate.data.map((item,index) => {
                                            if(item.time_truoc_9h){
                                                return (
                                                    <tr key={index}>
                                                        <td>{item.date+' 9시 이전'}</td>
                                                        <td>{item.received_thieu_sot}</td>
                                                        <td>{item.sending_thieu_sot}</td>
                                                        <td>{+item.received_thieu_sot + +item.sending_thieu_sot}</td>
                                                    </tr>
                                                )
                                            }
                                            return (
                                                <tr key={index}>
                                                    <td>{item.time == 'Total' ? '이하 합산' : `${item.date}  ${checkTime(item.time_hour)}`}</td>
                                                    <td>{item.received}</td>
                                                    <td>{item.sending}</td>
                                                    <td>{+item.received + +item.sending}</td>
                                                </tr>
                                            )
                                        }) : (<tr className={'text-center'}><td colSpan={4}>No Data</td></tr>)}
                                        </tbody>
                                    </table>
                                </div>
                                {paginate.totalItemsCount > paginate.itemsCountPerPage ? <div className="car_footer ">
                                    <nav aria-label="...">
                                        <Pagination
                                            itemClass="page-item"
                                            linkClass="page-link"
                                            innerClass={'custom_pagination pagination'}
                                            activePage={paginate.activePage}
                                            itemsCountPerPage={paginate.itemsCountPerPage}
                                            totalItemsCount={paginate.totalItemsCount}
                                            pageRangeDisplayed={paginate.pageRangeDisplayed}
                                            onChange={changePage}
                                            hideNavigation
                                        />
                                    </nav>
                                </div> : '' }

                            </div>
                        </div>
                    </div>
                    <div className="box_report col-5">
                        <div className="box_fitle">
                            <div className="box_left">상담항목</div>
												</div>
                        <div className="box_body">
                            <div className="content" style={{
                                minHeight: '230px'
                            }}>
                                <div className="custom_table limit_row">

																	{/*<table className="table st_table_5">*/}
																	{/* render_list_noheader(statItem) */}
																	{/*
																		statItem.length > 0 ? statItem.map((item,index) => {
																			(
																				<tr key={index}>
																						<td>{item.m_dtl_name}</td>
																						<td>{item.count}</td>
																						<td>{item.totalCount}</td>
																				</tr>
																			)
																		}) : (<tr className={'text-center'}><td colSpan={3}>No Data</td></tr>)
																	}
                                  </table>*/}

                                  {statItem.length > 0 ? statItem.map((item,index) => {
																		console.log('item:',item);
																		console.log('index:',index);
																		return (
																		<table className="table st_table_5">
                                      <thead>
                                        <tr>
                                            <th width="50%">{item.length > 0 ? item[0].m_name : 'N/A'}</th>
                                            <th width="25%">금일</th>
                                            <th width="25%">누계</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {item.length > 0 ? item.map((sitem,sindex) => {
                                                return (
                                                    <tr key={sindex}>
                                                        <td>{sitem.m_dtl_name}</td>
                                                        <td>{sitem.count}</td>
                                                        <td>{sitem.totalCount}</td>
                                                    </tr>
                                                )
                                        }) : (<tr className={'text-center'}><td colSpan={3}>Items No Data</td></tr>)}
                                      </tbody>
                                    </table>
																		)

																	}) : (<table className="table st_table_5"><tr className={'text-center'}><td colSpan={3}>Status No Data</td></tr></table>)}

																	{/*	<table className="table st_table_5">
                                      <thead>
                                        <tr>
                                            <th width="35%">항목</th>
                                            <th width="30%">세부</th>
                                            <th width="15%">금일</th>
                                            <th width="20%">누계</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {statItem.length > 0 ? statItem.map((item,index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{item.m_name}</td>
                                                        <td>{item.m_dtl_name}</td>
                                                        <td>{item.count}</td>
                                                        <td>{item.totalCount}</td>
                                                    </tr>
                                                )
                                        }) : (<tr className={'text-center'}><td colSpan={4}>No Data</td></tr>)}
                                      </tbody>
                                    </table>*/}
                                </div>
														</div>
												</div>
										</div>
									</div>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        className="btn btn-info"
                        onClick={()=>toggle()}
                    >
                        취소
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default ModalReportTelePhone;