import {
    FETCH_TABLE_REPORT_SUCCESS
} from '../actions/actionTypes';

const initialState = {
    data: null,
    loading: false,
    error: false
};

const report = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TABLE_REPORT_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: action.payload.data.data
            }
        }
        default:
            return state;
    }
};

export default report;