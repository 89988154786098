import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Helmet } from 'react-helmet';
import { NavLink, Link } from "react-router-dom";
import { Modal, ModalHeader, ModalFooter, ModalBody, Button } from 'reactstrap';
import RichTextEditor from 'react-rte';
import TextareaAutosize from 'react-autosize-textarea';
import * as helpers from '../../../../utils/helpers';

class UpdatePredefineSMSModal extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {

    };

    render() {
        const { 
            predefineSMSForm,
						totalLength
         } = this.props;

				const {
				} = this.state;

        return (
            <Fragment>
                <Modal
                    isOpen={this.props.isOpen}
                    toggle={this.props.toggle}
                    modalClassName='modal_add_preferences'
                    className="modal-dialog modal-dialog-scrollable"
                    contentClassName="modal-content"
                >
                    <div className="modal-header">
                        <h5 className="modal-title">
                            <FormattedMessage id="dashboard.Common.Update" defaultMessage="Update" />
                        </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.props.toggle}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>

                    <div className="modal-body">
                        <div className="box_add_ppreferences_1">

                            <div className="box_group_control">
                                <div className="heading_title">
                                    <FormattedMessage id="dashboard.PredefineSMS.Board.Name" defaultMessage="Name" />
                                </div>
                                <div className="group_control_in">
                                    <input
                                        type="text"
																				maxLength="4"
                                        className="form_control"
                                        name="name"
                                        value={predefineSMSForm?.name}
                                        onChange={this.props.handleChange}
                                    />
                                </div>
                            </div>

                            <div className="box_group_control">
                                <div className="heading_title">
                                    <FormattedMessage id="dashboard.PredefineSMS.Board.Phonee" defaultMessage="Phone" /> (한줄에 하나씩 입력, 중복이나 특수문자는 저장시 자동정리 됩니다)
                                </div>
                                <div className="group_control_in">
                                    <textarea
                                        type="text"
                                        className="form_control"
                                        name="phone"
																				value={predefineSMSForm?.phone}
                                        onChange={this.props.handleChange}
																				rows={30}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="modal-footer">
                        <button onClick={this.props.toggle}
                            type="button"
                            className="btn btn-outline-dark "
                            data-dismiss="modal"
                            aria-label="Close">
                            <FormattedMessage
                                id="dashboard.Cancel"
                                defaultMessage="Cancel" />
                        </button>
                        <button onClick={this.props.onUpdate}
                            type="button"
                            className="btn btn-dark btn_add"
                            data-dismiss="modal"
                            aria-label="Close">
                            <FormattedMessage
                                id="dashboard.Save"
                                defaultMessage="Save" />
                        </button>
                    </div>
                </Modal>

            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePredefineSMSModal)
