import $ from 'jquery';


var main_domain = "rtc.goodfone.co.kr"


$("#sms_mobile_send_btn").click(function(){

    //If there is no image file, only send a text

    if($("#input_sms_image_layout").find("i").length == 0){

        // sendCrmSms();

    }else{		//If there is images, send image first

        sendCrmSmsImage();

    }

    $('#csta_sms_message_byte').html('0 byte / SMS');

});



function crmSmsOpenFile(){

    var prependData = '<input id="mms-title" class="form-control" style="margin-bottom:10px;width:98%;" placeholder="Select a file">';

    $.each($('input[id="crmSmsUploadFile"]'), function(i) {

        if(i+1 == $('input[id="crmSmsUploadFile"]').length){

            $(this).trigger("click");

            $(this).removeAttr("multiple");

            if($("#mms-title").length == 0){

                $("#input_target_layout").find("div:first").prepend(prependData);

                $("#mms-title").hide();

            }

        }

    });

}



var crmSmsInputNo = 0;

var crmSmsFileIndex = 0;



function crmSmsChgFile(item){

    var defaultSmsCnt = 3;

    var cnt = $('input[id="crmSmsUploadFile"]')[item].files.length;

    var for_cnt = 0;

    var total_img = 3;

    if(cnt  > 0){		//Call a preview screen if there is an file to uplaod.

        $("#mms-title").show();

        $("#input_sms_image_layout").show();

        var items = "";

        var multi_conf = "";



        for_cnt = defaultSmsCnt - $("#input_sms_image_layout").find("i").length;



        for(var i = 0; i < for_cnt; i++){		//Call all of input files according to item number

            if($("#input_sms_image_layout").find("i").length < total_img){			// Count the total uplaoding files

                if($('#crm_sms_image_'+crmSmsFileIndex).length == 0){			//Ignore the same file name

                    var reader = new FileReader();

                    var file = $('input[id="crmSmsUploadFile"]')[item].files[i];

                    if(file != undefined){

                        reader.readAsDataURL(file);

                        reader.onload = function(e) {

                            items = "<div id='crm_sms_file_"+crmSmsFileIndex+"' class='col-md-4 col-xs-4 col-sm-4 text-center' style='margin-top:5px;'>"

                                +"<i class='fa fa-times-circle-o' style='position:absolute;top:5px;right:5px;font-size:20px;color:red;cursor:pointer;' onclick='removeCrmSmsImg("+crmSmsFileIndex+")'></i>"

                                +"<img id='crm_sms_image_"+crmSmsFileIndex+"' src="+e.target.result+" style='width:100%;'>"

                                +"</div>";

                            $("#input_sms_image_layout").append(items);

                            crmSmsFileIndex = parseInt(crmSmsFileIndex)  + 1;		//marking the accessed file number

                            $("#csta_sms_layout").css("height","430px");

                        };

                    }

                }

            }

            if(i+1 == cnt){	//Once the uploading file is done,

                multi_conf = "multiple=''";

                $("#crmSmsInputList").append('<input type="file" name="crmSmsUploadFile" id="crmSmsUploadFile" '+multi_conf+' style="display:none;" onChange="crmSmsChgFile('+(parseInt(item)+1)+');" accept="image/jpeg">');

                crmSmsInputNo = parseInt(crmSmsInputNo)  + 1; 	//create dynamice input file

            }

        }

    }

}



function setNumFormat(item){

    if(parseInt(item) < 10){

        item = "0"+item;

    }

    return item;

}



function dataURItoBlob(dataURI) {

    var byteString;

    if (dataURI.split(',')[0].indexOf('base64') >= 0)

        byteString = atob(dataURI.split(',')[1]);

    else

        byteString = unescape(dataURI.split(',')[1]);



    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];



    var ia = new Uint8Array(byteString.length);

    for (var i = 0; i < byteString.length; i++) {

        ia[i] = byteString.charCodeAt(i);

    }



    return new Blob([ia], {type:mimeString});

}



var crmSmsMaxWidth = 1200;

var crmSmsMaxHeight = 1200;

var thumb_crmSmsMaxWidth = 150;

var thumb_crmSmsMaxHeight = 150;

var crmUpImgArr = new Array();			//This is array for uploading image



export function sendCrmSmsImage(file,token) {
    var promiseObj = new Promise(function(resolve, reject){
        //console.log("sendCrmSmsImage");
        // var text_msg = $("#sms_input_content").val();

        var crmSmsUpIndex = 0;		// Index for total process
        var crmUploadFileIndex = 0;	// Index for uplading file
        var attachAjax = "";			//Ajax Text for uplading file
        crmUpImgArr.length = 0; // array initialization
        //console.log(j,"만큼 루프");
        //console.log(j,"의 보유 파일 : ",i);
        // if($("#input_sms_image_layout").find('img[id="crm_sms_image_'+crmSmsUpIndex+'"]').length > 0){	//Checking it's in the list
        //console.log(crmSmsUpIndex, "대기열에 있음.");
        // console.log(file,'test file')
        var data = new FormData();
        var fileDate = new Date();
        var file_time = fileDate.getFullYear()+""+setNumFormat((fileDate.getMonth() + 1))+""+setNumFormat(fileDate.getDate())+""+setNumFormat(fileDate.getHours())+""+setNumFormat(fileDate.getMinutes())+""+setNumFormat(fileDate.getSeconds())+""+fileDate.getMilliseconds();
        var file_ext = file.name.slice(file.name.lastIndexOf(".")+1);	//get the file extension
        var file_name = 'jhkim'+"_"+crmSmsUpIndex+"_"+file_time;		//name+ index+time(year&month&day&hour&second&mi1lisecound)
        var uploadName = "";
        var width = 150;
        var file_types = "image";
        var msg = "https://"+main_domain+"/mms/"+file_name+"."+file_ext;
        var size = file.size;
        var message_origin = file.name.substring(0, file.name.lastIndexOf("."));

        //image : Thumbnail process in the screen
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function() {
            //make the thumbnail image
            var tempImage = new Image(); //drawImage 메서드에 넣기 위해 이미지 객체화
            tempImage.src = reader.result; //data-uri를 이미지 객체에 주입
            // console.log(reader.result,'dasdsa')

            tempImage.onload = function() {
                var width = tempImage.width;	//real width of image
                var height = tempImage.height;	//real height of image file
                var thumb_width = tempImage.width;		//Thumbnail width
                var thumb_height = tempImage.height;		//Thumbnail height
                var ratio = 0;		//ration
                var data = []

                if(thumb_width > thumb_crmSmsMaxWidth){	//check the width position
                    ratio = thumb_crmSmsMaxWidth / thumb_width;
                    thumb_height = thumb_height * ratio;
                    thumb_width = thumb_crmSmsMaxWidth;
                }

                if(thumb_height > thumb_crmSmsMaxHeight){	//check the height position
                    ratio = thumb_crmSmsMaxHeight / thumb_height;
                    thumb_width = thumb_width * ratio;
                    thumb_height = thumb_crmSmsMaxHeight;
                }

                //This is for resizing the canvas

                var canvas = document.createElement('canvas');
                var canvasContext = canvas.getContext("2d");

                //Make the canvas size
                canvas.width = thumb_width; //width
                canvas.height = thumb_height; //height
                canvasContext.drawImage(this, 0, 0, thumb_width, thumb_height);//이미지를 캔버스에 그리기
                var dataURI = canvas.toDataURL('image/jpeg'); //캔버스에 그린 이미지를 다시 data-uri 형태로 변환

                //Send it after making the canvas to image

                var dataURL = canvas.toDataURL('image/jpeg', 0.5);

                var blob = dataURItoBlob(dataURL);
                // console.log(blob,'dsadsad')

                var data2 = new FormData();
                data2.append('uploadFile', blob);
                data2.append('changeName', file_name);
                data2.append('changeExt', ".jpg");
                data2.append('oauth_token', token);
                data2.append('uploadFolder', "mms/");

                /**

                 *	Resizing the imag file

                 *  Need to adjust the image file is over 300KB

                 **/

                //파일 전송

                var data3 = new FormData();
                if(width > crmSmsMaxWidth){	//Check the width position
                    ratio = crmSmsMaxWidth / width;
                    height = height * ratio;
                    width = crmSmsMaxWidth;
                }

                if(height > crmSmsMaxHeight){	//Check the height position
                    ratio = crmSmsMaxHeight / height;
                    width = width * ratio;
                    height = crmSmsMaxHeight;
                }

                //Create canvas object for resizing
                //
                var canvas2 = document.createElement('canvas');
                var canvasContext2 = canvas2.getContext("2d");

                //Set the canvas size
                canvas2.width = width; //가로
                canvas2.height = height; //세로
                canvasContext2.drawImage(this, 0, 0, width, height);//draw the image to canvas
                dataURI = canvas2.toDataURL('image/jpeg'); //Change the image on the canvase as the type of data-uri


                //send it after creating the canvas to image

                dataURL = canvas2.toDataURL('image/jpeg', 0.5);
                blob = dataURItoBlob(dataURL);
                data3.append('uploadFile', blob);
                data3.append('changeName', file_name);
                data3.append('changeExt', ".jpg");
                data3.append('oauth_token', token);
                data3.append('uploadFolder', "mms/");

                //
                $.ajax({
                    url: "https://"+main_domain+"/upload/",
                    type: "post",
                    dataType: "json",
                    data: data3,
                    crossDomain : true,
                    processData: false,
                    contentType: false,
                    success: function(re_data2) {
                        resolve(re_data2);
                        // data.push(re_data2)
                        // crmUploadFileIndex++;

                        //When the message is sent, the information of the image to be registered is stored in the array

                        //메세지 전송시 같이 등록할 이미지의 정보를 배열에 저장

                        // crmUpImgArr.push(message_origin+".jpg", main_domain+"/mms/"+file_name+".jpg", "image", file_name+".jpg");


                        // When the upload to the last image is completed, the actual message is sent

                        //마지막 이미지까지 업로드가 완료되면 실제 메세지를 전송

                        // if(crmUploadFileIndex == $("#input_sms_image_layout").find("i").length){
                        //
                        //     $("#input_sms_image_layout").empty().hide();		//initialization of preview screen of image uplaoding 이미지 업로드 미리보기 창 초기화
                        //
                        //     // sendCrmMms();
                        //
                        // }
                    }
                });

            };
        }

    });

    return promiseObj;
}