import {
    FETCH_INFO_LINE_LIST,
    FETCH_INFO_LINE_LIST_SUCCEED,
    FETCH_INFO_LINE_LIST_FAILED,
    CREATE_INFO_LINE,
    CREATE_INFO_LINE_SUCCEED,
    CREATE_INFO_LINE_FAILED,
    UPDATE_INFO_LINE,
    UPDATE_INFO_LINE_SUCCEED,
    UPDATE_INFO_LINE_FAILED,
    DELETE_INFO_LINE,
    DELETE_INFO_LINE_SUCCEED,
    DELETE_INFO_LINE_FAILED
} from '../actions/actionTypes';
import api from "../../utils/api";

export const fetchInfoLines = () => {
    return {
        type: FETCH_INFO_LINE_LIST
    }
};

export const fetchInfoLinesSucceed = data => {
    return {
        type: FETCH_INFO_LINE_LIST_SUCCEED,
        payload: {
            data: data
        }
    }
};

export const fetchInfoLinesFailed = error => {
    return {
        type: FETCH_INFO_LINE_LIST_FAILED,
        payload: {
            error: error
        }
    }
};

export const createInfoLine = () => {
    return {
        type: CREATE_INFO_LINE
    }
};

export const createInfoLineSucceed = infoLine => {
    return {
        type: CREATE_INFO_LINE_SUCCEED,
        payload: {
            infoLine
        }
    }
};

export const createInfoLineFailed = error => {
    return {
        type: CREATE_INFO_LINE_FAILED,
        payload: {
            error: error
        }
    }
};

export const updateInfoLine = () => {
    return {
        type: UPDATE_INFO_LINE
    }
};

export const updateInfoLineSucceed = infoLine => {
    return {
        type: UPDATE_INFO_LINE_SUCCEED,
        payload: {
            infoLine
        }
    }
};

export const updateInfoLineFailed = error => {
    return {
        type: UPDATE_INFO_LINE_FAILED,
        payload: {
            error: error
        }
    }
};

export const deleteInfoLine = () => {
    return {
        type: DELETE_INFO_LINE
    }
};

export const deleteInfoLineSucceed = infoLine => {
    return {
        type: DELETE_INFO_LINE_SUCCEED,
        payload: {
            infoLine
        }
    }
};

export const deleteInfoLineFailed = error => {
    return {
        type: DELETE_INFO_LINE_FAILED,
        payload: {
            error: error
        }
    }
};

/**
 * Action
 */

/**
 * Fetch InfoLine List
 */
export const fetchInfoLinesAction = (data) => dispatch => {
    console.log(data, 'data');
    dispatch(fetchInfoLines());
    if(data.company_id !== null) {
        return api.infoLine.getInfoLineByCompany(data)
            .then(response => {
                dispatch(fetchInfoLinesSucceed(response.data.data));
                return Promise.resolve(response.data.data);
            })
            .catch(error => {
                dispatch(fetchInfoLinesFailed(
                    error.response.data.errors.message));
                return Promise.reject(error.response)
            });
    } else {
        return api.infoLine.getInfoLine()
            .then(response => {
                dispatch(fetchInfoLinesSucceed(response.data.data));
                return Promise.resolve(response.data.data);
            })
            .catch(error => {
                dispatch(fetchInfoLinesFailed(
                    error.response.data.errors.message));
                return Promise.reject(error.response)
            });
    }

};

/**
 * Create info line
 */
export const createInfoLineAction = infoLine => dispatch => {
    dispatch(createInfoLine());
    return api.infoLine.createInfoLine(infoLine)
        .then(response => {
            dispatch(createInfoLineSucceed(response.data.data));
            return Promise.resolve(response.data);
        })
        .catch(error => {
            dispatch(createInfoLineFailed(error.response.data.errors.message));
            return Promise.reject(error.response)
        });
};

/**
 * Update info line
 */
export const updateInfoLineAction = (id, infoLine) => dispatch => {
    dispatch(updateInfoLine());
    return api.infoLine.updateInfoLine(id, infoLine)
        .then(response => {
            dispatch(updateInfoLineSucceed(response.data.data));
            return Promise.resolve(response.data);
        })
        .catch(error => {
            dispatch(updateInfoLineFailed(error.response.data.errors.message));
            return Promise.reject(error.response)
        });
};

/**
 * Delete info line
 */
export const deleteInfoLineAction = infoLineId => dispatch => {
    dispatch(deleteInfoLine());
    const id = {
        params: {
            line_no: infoLineId
        }
    };
    return api.infoLine.deleteInfoLine(id)
        .then(response => {
            dispatch(deleteInfoLineSucceed(response.data.data));
            return Promise.resolve(response.data);
        })
        .catch(error => {
            dispatch(deleteInfoLineFailed(error.response.data.errors.message));
            return Promise.reject(error.response)
        });
};