import React, {Fragment} from 'react'
import Customer_1 from '../../assets/images/documentV2/customer_management/customer_1.png';
import Customer_2 from '../../assets/images/documentV2/customer_management/customer_2.png';
import Customer_3 from '../../assets/images/documentV2/customer_management/customer_3.png';




const CustomerManagementDoc = props => {

    return (
        <Fragment>
            <div className="row m-0">
                <div className="col-md-12 documentation-user-registration">
                    <h2 className="mt-3 mb-5">고객 관리</h2>
                    <div className="main-user-registration">
                                <p className="mt-5"><b>고객관리</b></p> <br />
                        <div className="row">
                            <div className="col-md-8 ">
                                <img src={Customer_1} width='100%'/>
                            </div>
                            <div className="col-md-4 pl-4 pr-0">
                                <p>(1)상담데이터</p>
                                <p>(2)고객 데이터</p>
                            </div>
                        </div>
                        <p className="mt-5"><b>상담데이터</b></p><br />
                        <div className="row">
                            <div className="col-md-8">
                                <img src={Customer_2} width='100%'/>
                            </div>
                            <div className="col-md-4 pl-4 pr-0">
                                <p>(1) 기간 별 조건 선택</p>
                                <p>(2) 전화상담시 기재된 항목들과 상담항목들을 선택하여 원하는 상담데이터를 <br />구성함</p>
                                <p>(3) 검색어를 입력하면, 조회된 상담데이터 내에서 조건에 맞는 데이터를 표시함</p>
                                <p>(4) 데이터 선택 및 삭제</p>
                                <p>(5) 엑셀파일로 출력 가능</p>
                            </div>
                        </div>
                        <p className="mt-5"><b>상담데이터</b></p><br />
                        <div className="row">
                            <div className="col-md-8">
                                <img src={Customer_3} width='100%'/>
                            </div>
                            <div className="col-md-4 pl-4 pr-0">
                                <p>(1) 고객명, 주소, 전화번호, 상담항목, 세부항목 별 건색 조건 선택
                                </p>
                                <p>(2) 검색 조건에 의한 고객 데이터가 표시됨</p>
                                <p>(3) - 파일 업로드: 먼저 샘플을 다운로드 하여 추가할 데이터를 해당 파일에 작성. 파일을 업로드 하면, 해당 데이터가 데이터베이스에 저장됨<br />
                                    - 샘플 다운로드: 파일 업로드를 위한 샘플 엑셀 파일<br />
                                    - 엑셀 출력: 조회된 데이터 엑셀파일로 출력 가능
                                </p>
                                <p>(4) 데이터 선택 및 삭제</p>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default CustomerManagementDoc;