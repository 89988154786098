
export const LOGIN = 'login';
export const HOME = 'home';

// Dashboard
export const DASHBOARD = 'dashboard';
export const USER = 'user';
export const USER_REGISTRATION = 'user-registration';
export const AFFILITED_COMPANY_MANAGEMENT = 'affiliated-company-management';
export const TELEPHONE = 'telephone-counselling';
export const CUSTOMER_LIST = 'customer-list';
export const STATISTICS = 'statistics';
export const DAILY_REPORT = 'daily-report';
export const ANALYSIS = 'analysis';
export const GRAPH = 'graph';

// export const GRAPH_2 = 'graph-2';
export const CUSTOMER_MANAGEMENT = 'customer-management';
export const COUNSELLING_DATA = 'counselling-data';
export const CUSTOMER_DATA = 'customer-data';
export const IVR_DATA = 'ivr-data';
export const DATA_SETTING = 'data-setting';
export const SMS_SETTING = 'sms-setting';
export const ITEM_MANAGEMENT = 'item-management';
export const TERRITORY_MANAGEMENT = 'territory-management';
export const COUNSELOR_MANAGEMENT = 'counselor-management';
export const CALL_HISTORY = 'call-history';
export const COMPANY_REGISTRATION = 'company-registration';
export const LINE_INFORMATION = 'line-information';
export const SMS_MMS = 'SMS-MMS';
export const SMS_HISTORY = 'SMS-History';
export const SMS_HISTORY_V2 = 'SMS-History-V2';
export const SMS_TEXT_MANAGEMENT = 'SMS-Text-Management';
export const SMS_BULK_SENDING = 'SMS-Bulk-Sending';
export const SMS_AUTO_SEND = 'SMS-Auto-Send';
export const NOTICE = 'notice';
export const PREDEFINE_SMS = 'predefine-sms';

export const REJECT_LIST = 'reject-list';

export const IVR_TRACE = 'ivr-trace';

// 게시판
export const BOARD = 'board';
export const FREE_BOARD = 'free-board';
export const REQ_BOARD = 'req-board';

// Text on off
export const TEXT_ON_OFF = 'text-on-off'

// Documentation

export const DOCUMENTATION = 'documentation';
export const DOCUMENTATION_GETTING_STARTED = 'getting-started';

export const INSTRUCTIONS  = 'instructions';
export const DOWNLOADEXCEL  = 'excel-downloads';


// Function MGM Admin
export const MGM_ADMIN = 'mgm-admin'
export const MGM_USER_MANGER = 'user-manager'
export const MGM_PLACEMENT = 'mgm-placement'