import React, {Component, Fragment} from 'react';
import {withRouter} from "react-router-dom";
import {connect} from 'react-redux';
import {Helmet} from 'react-helmet';
import {FormattedMessage} from "react-intl";


// CSS documentation
import '../../../assets/css/devicons/css/devicons.min.css';
import '../../../assets/css/documentation/resume.min.css';
import '../../../assets/css/documentation/styles.css';
// import '../../../assets/css/jquery-easing/jquery.easing.min';
// import '../../../assets/css/jquery-easing/resume.min';


import SideBar from './SideBar';
import * as Scroll from 'react-scroll';
import {  Link,Events,scroller, animateScroll as scroll, scrollSpy } from 'react-scroll'
import * as routerName from "../../../utils/routerName";


class DocLayout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false
        };
    }
    toggleMenu = (to) =>{
        let currentPath = this.props.location.pathname.substr(this.props.location.pathname.lastIndexOf('/') + 1);
        currentPath = currentPath === routerName.DOCUMENTATION_GETTING_STARTED ? routerName.DOCUMENTATION_GETTING_STARTED: currentPath;
        this.props.history.push(`/${routerName.DOCUMENTATION}/${routerName.DOCUMENTATION_GETTING_STARTED}`);
        console.log(to)
        this.setState({visible: !this.state.visible})
    };
     smoothScroll = {
        timer: null,

        stop: function () {
            clearTimeout(this.timer);
        },

        scrollTo: function (id, callback) {
            var settings = {
                duration: 700,
                easing: {
                    outQuint: function (x, t, b, c, d) {
                        return c*((t=t/d-1)*t*t*t*t + 1) + b;
                    }
                }
            };
            var percentage;
            var startTime;
            var node = document.getElementById(id);
            var nodeTop = node.offsetTop;
            var nodeHeight = node.offsetHeight;
            var body = document.body;
            var html = document.documentElement;
            var height = Math.max(
                body.scrollHeight,
                body.offsetHeight,
                html.clientHeight,
                html.scrollHeight,
                html.offsetHeight
            );
            var windowHeight = window.innerHeight
            var offset = window.pageYOffset;
            var delta = nodeTop - offset;
            var bottomScrollableY = height - windowHeight;
            var targetY = (bottomScrollableY < delta) ?
                bottomScrollableY - (height - nodeTop - nodeHeight + offset):
                delta;

            startTime = Date.now();
            percentage = 0;

            if (this.timer) {
                clearInterval(this.timer);
            }

            function step () {
                var yScroll;
                var elapsed = Date.now() - startTime;

                if (elapsed > settings.duration) {
                    clearTimeout(this.timer);
                }

                percentage = elapsed / settings.duration;

                if (percentage > 1) {
                    clearTimeout(this.timer);

                    if (callback) {
                        callback();
                    }
                } else {
                    yScroll = settings.easing.outQuint(0, elapsed, offset, targetY, settings.duration);
                    window.scrollTo(0, yScroll);
                    this.timer = setTimeout(step, 10);
                }
            }

            this.timer = setTimeout(step, 10);
        }
    };
    handleTopClick = (id) => {
        this.smoothScroll.scrollTo(id);
    }

    componentDidMount  (){

        Events.scrollEvent.register('begin', function(to, element) {
        });

        Events.scrollEvent.register('end', function(to, element) {
        });

        scrollSpy.update();


    }
    componentWillUnmount() {
        Events.scrollEvent.remove('begin');
        Events.scrollEvent.remove('end');
    }

    scrollToTop() {
        scroll.scrollToTop(0);
    }
    scrollToBottom() {
        scroll.scrollToBottom(0);
    }
    scrollTo() {
        scroll.scrollTo(0);
        // scroller.scrollTo('scroll-to-element', {
        //     duration: 800,
        //     delay: 0,
        //     smooth: 'easeInOutQuart'
        // })
    }
    scrollMore() {
        scroll.scrollMore(0);
    }
    handleSetActive(to) {
        console.log(to)
    }


    render() {
        return (
            <Fragment>
                <div className="documentation">
                    <FormattedMessage id="dashboard.Documentation"
                                      defaultMessage="Documentation">
                        {message => <Helmet>
                            <title>{message}</title>
                        </Helmet>}
                    </FormattedMessage>
                    <SideBar
                        handleSetActive={this.handleSetActive}
                        toggleMenu={this.toggleMenu}
                        visible={this.state.visible}
                    />
                    <div className="container-fluid p-0">
                        {this.props.children}
                    </div>
                </div>
            </Fragment>
        )
    }
}


const mapStateToProps = state => {
    return {}
};


const mapDispatchToProps = dispatch => {
    return {}
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DocLayout));