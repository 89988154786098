
import {
    FETCH_CALL,
    FETCH_CALL_SUCCEED,
    FETCH_CALL_FAILED,
    EDIT_CALL_COLUMN, // chinh sua cot trong redux
    RESET_CALL,
    FETCH_CALL_CREATE_NEW,
    FETCH_CALL_KEY_SEARCH,
    FETCH_CALL_KEY_SEARCH_SUCCESS,
    FETCH_CALL_KEY_SEARCH_FAILED, SET_CALL,
} from './../type';
import api from "../../utils/api";


export const fetchCall = type => {
    return {
        type: FETCH_CALL
    }
};

export const fetchCallSucceed = data => {
    return {
        type: FETCH_CALL_SUCCEED,
        payload: {
            data: data
        }
    }
};

export const fetchCallFailed = error => {
    return {
        type: FETCH_CALL_FAILED,
        payload: {
            error: error
        }
    }
};

export const fetchCallAction = (id) => dispatch => {
    dispatch(fetchCall());
    return api.call.getCall(id).then(response => {
        dispatch(fetchCallSucceed(response.data.data.infoCall));
        return Promise.resolve(response.data.data);
    }).catch(error => {
        dispatch(fetchCallFailed(error.response.data.errors.message));
        return Promise.reject(error.response)
    })
}

// edit call column
export const editCallColumn = data => {
    return {
        type: EDIT_CALL_COLUMN,
        payload: {
            data: data
        }
    }
}
export const editCallColumnAction = (data) => dispatch => {
    dispatch(editCallColumn(data))
}

// reset call trong redux
export const resetCall = () => {
    return {
        type: RESET_CALL,
    }
}
export const resetCallAction = () => dispatch => {
    dispatch(resetCall())
}

// fetch call set lai trong redux khi tao cuoc goi moi
export const fetchCreateCall = data => {
    return {
        type: FETCH_CALL_CREATE_NEW,
        payload: {
            data: data
        }
    }
}


export const fetchCreateCallAction = data => dispatch => {
    dispatch(fetchCreateCall(data));
}


// fetch search key call


export const fetchSearchKeyCallAction = (search_key, company_id) => dispatch => {

    return api.call.searchKeyCall(search_key, company_id).then(response => {
        if(response.data.data) {
            dispatch(success(response.data.data))
        }
        return Promise.resolve(response.data.data);
    }).catch(error => {
        console.log(error)
        dispatch(failure(error.response.data.errors.message));
        return Promise.reject(error.response)
    });


    function success(data) {
        return {
            type: FETCH_CALL_KEY_SEARCH_SUCCESS,
            payload: {
                data
            }
        }
    }

    function failure(errors) {
        return {
            type: FETCH_CALL_KEY_SEARCH_FAILED,
            payload: {
                error: errors
            }
        }
    }
}

export const setCall = data => {
    return {
        type: SET_CALL,
        payload: data
    }
}

export const setCallAction = (data) => dispatch => {
    dispatch(setCall(data));
}