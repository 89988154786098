//Auth
export const AUTH_USER = "AUTH_USER";
export const AUTH_SUCCEED = "AUTH_SUCCEED";
export const AUTH_FAILED = "AUTH_FAILED";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_INIT_LOGOUT = "AUTH_INIT_LOGOUT";
export const AUTH_FETCH_CURRENT_USER = "AUTH_FETCH_CURRENT_USER";
export const AUTH_CHANGE_DISTURB_USER = 'AUTH_CHANGE_DISTURB_USER';
export const AUTH_CHANGE_INFO_SALE_LOT = 'AUTH_CHANGE_INFO_SALE_LOT';
export const AUTH_CHANGE_MEMO = 'AUTH_CHANGE_MEMO';

//Locate
export const LOCALE_SET = 'LOCALE_SET';
export const LOCALE_SET_SUCCEED = 'LOCALE_SET_SUCCEED';

//User
export const FETCH_COUNSELOR_BY_SALE_LOT = 'FETCH_COUNSELOR_BY_SALE_LOT';
export const FETCH_COUNSELOR_BY_SALE_LOT_SUCCEED = 'FETCH_COUNSELOR_BY_SALE_LOT_SUCCEED';
export const FETCH_COUNSELOR_BY_SALE_LOT_FAILED = 'FETCH_COUNSELOR_BY_SALE_LOT_FAILED';

export const FETCH_USERS_BY_SALE_LOT = 'FETCH_USERS_BY_SALE_LOT';
export const FETCH_USERS_BY_SALE_LOT_SUCCEED = 'FETCH_USERS_BY_SALE_LOT_SUCCEED';
export const FETCH_USERS_BY_SALE_LOT_FAILED = 'FETCH_USERS_BY_SALE_LOT_FAILED';

export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCEED = 'DELETE_USER_SUCCEED';
export const DELETE_USER_FAILED = 'DELETE_USER_FAILED';

export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_SUCCEED = 'CREATE_USER_SUCCEED';
export const CREATE_USER_FAILED = 'CREATE_USER_FAILED';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCEED = 'UPDATE_USER_SUCCEED';
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED';
export const UNMOUNT_USERS = 'UNMOUNT_USERS';

//Info Detail
export const FETCH_INFO_DETAIL_LIST_BY_INFO_MAST = 'FETCH_INFO_DETAIL_LIST_BY_INFO_MAST';
export const FETCH_INFO_DETAIL_LIST_BY_INFO_MAST_SUCCEED = 'FETCH_INFO_DETAIL_LIST_BY_INFO_MAST_SUCCEED';
export const FETCH_INFO_DETAIL_LIST_BY_INFO_MAST_FAILED = 'FETCH_INFO_DETAIL_LIST_BY_INFO_MAST_FAILED';

export const CREATE_INFO_DETAIL = 'CREATE_INFO_DETAIL';
export const CREATE_INFO_DETAIL_SUCCEED = 'CREATE_INFO_DETAIL_SUCCEED';
export const CREATE_INFO_DETAIL_FAILED = 'CREATE_INFO_DETAIL_FAILED';

export const UPDATE_INFO_DETAIL = 'UPDATE_INFO_DETAIL';
export const UPDATE_INFO_DETAIL_SUCCEED = 'UPDATE_INFO_DETAIL_SUCCEED';
export const UPDATE_INFO_DETAIL_FAILED = 'UPDATE_INFO_DETAIL_FAILED';

export const DELETE_INFO_DETAIL = 'DELETE_INFO_DETAIL';
export const DELETE_INFO_DETAIL_SUCCEED = 'DELETE_INFO_DETAIL_SUCCEED';
export const DELETE_INFO_DETAIL_FAILED = 'DELETE_INFO_DETAIL_FAILED';

export const UNMOUNT_INFO_DETAIL = 'UNMOUNT_INFO_DETAIL';

//District
export const FETCH_DISTRICTS = 'FETCH_DISTRICTS';
export const FETCH_DISTRICTS_SUCCEED = 'FETCH_DISTRICTS_SUCCEED';
export const FETCH_DISTRICTS_FAILED = 'FETCH_DISTRICTS_FAILED';

export const FETCH_DISTRICTS_BY_COMPANY = 'FETCH_DISTRICTS_BY_COMPANY';
export const FETCH_DISTRICTS_BY_COMPANY_SUCCEED = 'FETCH_DISTRICTS_BY_COMPANY_SUCCEED';
export const FETCH_DISTRICTS_BY_COMPANY_FAILED = 'FETCH_DISTRICTS_BY_COMPANY_FAILED';

export const CREATE_DISTRICT = 'CREATE_DISTRICT';
export const CREATE_DISTRICT_SUCCEED = 'CREATE_DISTRICT_SUCCEED';
export const CREATE_DISTRICT_FAILED = 'CREATE_DISTRICT_FAILED';

export const UPDATE_DISTRICT = 'UPDATE_DISTRICT';
export const UPDATE_DISTRICT_SUCCEED = 'UPDATE_DISTRICT_SUCCEED';
export const UPDATE_DISTRICT_FAILED = 'UPDATE_DISTRICT_FAILED';

export const DELETE_DISTRICT = 'DELETE_DISTRICT';
export const DELETE_DISTRICT_SUCCEED = 'DELETE_DISTRICT_SUCCEED';
export const DELETE_DISTRICT_FAILED = 'DELETE_DISTRICT_FAILED';

export const UNMOUNT_DISTRICTS = 'UNMOUNT_DISTRICTS';

//Info Mast
export const FETCH_INFO_MAST_LIST = 'FETCH_INFO_MAST_LIST';
export const FETCH_INFO_MAST_LIST_SUCCEED = 'FETCH_INFO_MAST_LIST_SUCCEED';
export const FETCH_INFO_MAST_LIST_FAILED = 'FETCH_INFO_MAST_LIST_FAILED';

export const CREATE_INFO_MAST = 'CREATE_INFO_MAST';
export const CREATE_INFO_MAST_SUCCEED = 'CREATE_INFO_MAST_SUCCEED';
export const CREATE_INFO_MAST_FAILED = 'CREATE_INFO_MAST_FAILED';

export const UPDATE_INFO_MAST = 'UPDATE_INFO_MAST';
export const UPDATE_INFO_MAST_SUCCEED = 'UPDATE_INFO_MAST_SUCCEED';
export const UPDATE_INFO_MAST_FAILED = 'UPDATE_INFO_MAST_FAILED';

export const DELETE_INFO_MAST = 'DELETE_INFO_MAST';
export const DELETE_INFO_MAST_SUCCEED = 'DELETE_INFO_MAST_SUCCEED';
export const DELETE_INFO_MAST_FAILED = 'DELETE_INFO_MAST_FAILED';

export const UNMOUNT_INFO_MAST = 'UNMOUNT_INFO_MAST';

//Companies
export const FETCH_COMPANIES = 'FETCH_COMPANIES';
export const FETCH_COMPANIES_SUCCEED = 'FETCH_COMPANIES_SUCCEED';
export const FETCH_COMPANIES_FAILED = 'FETCH_COMPANIES_FAILED';

export const CREATE_COMPANY = 'CREATE_COMPANY';
export const CREATE_COMPANY_SUCCEED = 'CREATE_COMPANY_SUCCEED';
export const CREATE_COMPANY_FAILED = 'CREATE_COMPANY_FAILED';

export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const UPDATE_COMPANY_SUCCEED = 'UPDATE_COMPANY_SUCCEED';
export const UPDATE_COMPANY_FAILED = 'UPDATE_COMPANY_FAILED';

export const DELETE_COMPANY = 'DELETE_COMPANY';
export const DELETE_COMPANY_SUCCEED = 'DELETE_COMPANY_SUCCEED';
export const DELETE_COMPANY_FAILED = 'DELETE_COMPANY_FAILED';

//Sale Lots
export const FETCH_SALE_LOT_OF_CURRENT_USER = 'FETCH_SALE_LOT_OF_CURRENT_USER';
export const FETCH_SALE_LOT_OF_CURRENT_USER_SUCCEED = 'FETCH_SALE_LOT_OF_CURRENT_USER_SUCCEED';
export const FETCH_SALE_LOT_OF_CURRENT_USER_FAILED = 'FETCH_SALE_LOT_OF_CURRENT_USER_FAILED';

export const CREATE_SALE_LOTS = 'CREATE_SALE_LOTS';
export const CREATE_SALE_LOTS_SUCCEED = 'CREATE_SALE_LOTS_SUCCEED';
export const CREATE_SALE_LOTS_FAILED = 'CREATE_SALE_LOTS_FAILED';

export const UPDATE_SALE_LOTS = 'UPDATE_SALE_LOTS';
export const UPDATE_SALE_LOTS_SUCCEED = 'UPDATE_SALE_LOTS_SUCCEED';
export const UPDATE_SALE_LOTS_FAILED = 'UPDATE_SALE_LOTS_FAILED';

export const DELETE_SALE_LOTS = 'DELETE_SALE_LOTS';
export const DELETE_SALE_LOTS_SUCCEED = 'DELETE_SALE_LOTS_SUCCEED';
export const DELETE_SALE_LOTS_FAILED = 'DELETE_SALE_LOTS_FAILED';

// 게시판
export const CREATE_FREE_ARTICLE = 'CREATE_FREE_ARTICLE';
export const CREATE_FREE_ARTICLE_SUCCEED = 'CREATE_FREE_ARTICLE_SUCCEED';
export const CREATE_FREE_ARTICLE_FAILED = 'CREATE_FREE_ARTICLE_FAILED';

export const UPDATE_FREE_ARTICLE = 'UPDATE_FREE_ARTICLE';
export const UPDATE_FREE_ARTICLE_SUCCEED = 'UPDATE_FREE_ARTICLE_SUCCEED';
export const UPDATE_FREE_ARTICLE_FAILED = 'UPDATE_FREE_ARTICLE_FAILED';

export const REPLY_FREE_ARTICLE = 'REPLY_FREE_ARTICLE';
export const REPLY_FREE_ARTICLE_SUCCEED = 'REPLY_FREE_ARTICLE_SUCCEED';
export const REPLY_FREE_ARTICLE_FAILED = 'REPLY_FREE_ARTICLE_FAILED';

export const DELETE_FREE_ARTICLE = 'DELETE_FREE_ARTICLE';
export const DELETE_FREE_ARTICLE_SUCCEED = 'DELETE_FREE_ARTICLE_SUCCEED';
export const DELETE_FREE_ARTICLE_FAILED = 'DELETE_FREE_ARTICLE_FAILED';

export const CREATE_REQ_ARTICLE = 'CREATE_REQ_ARTICLE';
export const CREATE_REQ_ARTICLE_SUCCEED = 'CREATE_REQ_ARTICLE_SUCCEED';
export const CREATE_REQ_ARTICLE_FAILED = 'CREATE_REQ_ARTICLE_FAILED';

export const UPDATE_REQ_ARTICLE = 'UPDATE_REQ_ARTICLE';
export const UPDATE_REQ_ARTICLE_SUCCEED = 'UPDATE_REQ_ARTICLE_SUCCEED';
export const UPDATE_REQ_ARTICLE_FAILED = 'UPDATE_REQ_ARTICLE_FAILED';

export const REPLY_REQ_ARTICLE = 'REPLY_REQE_ARTICLE';
export const REPLY_REQ_ARTICLE_SUCCEED = 'REPLY_REQ_ARTICLE_SUCCEED';
export const REPLY_REQ_ARTICLE_FAILED = 'REPLY_REQ_ARTICLE_FAILED';

export const DELETE_REQ_ARTICLE = 'DELETE_REQ_ARTICLE';
export const DELETE_REQ_ARTICLE_SUCCEED = 'DELETE_REQ_ARTICLE_SUCCEED';
export const DELETE_REQ_ARTICLE_FAILED = 'DELETE_REQ_ARTICLE_FAILED';

//Info Line
export const FETCH_INFO_LINE_LIST = 'FETCH_INFO_LINE_LIST';
export const FETCH_INFO_LINE_LIST_SUCCEED = 'FETCH_INFO_LINE_LIST_SUCCEED';
export const FETCH_INFO_LINE_LIST_FAILED = 'FETCH_INFO_LINE_LIST_FAILED';

export const CREATE_INFO_LINE = 'CREATE_INFO_LINE';
export const CREATE_INFO_LINE_SUCCEED = 'CREATE_INFO_LINE_SUCCEED';
export const CREATE_INFO_LINE_FAILED = 'CREATE_INFO_LINE_FAILED';

export const UPDATE_INFO_LINE = 'UPDATE_INFO_LINE';
export const UPDATE_INFO_LINE_SUCCEED = 'UPDATE_INFO_LINE_SUCCEED';
export const UPDATE_INFO_LINE_FAILED = 'UPDATE_INFO_LINE_FAILED';

export const DELETE_INFO_LINE = 'DELETE_INFO_LINE';
export const DELETE_INFO_LINE_SUCCEED = 'DELETE_INFO_LINE_SUCCEED';
export const DELETE_INFO_LINE_FAILED = 'DELETE_INFO_LINE_FAILED';

export const FETCH_CALL_HISTORY_LIST = 'FETCH_CALL_HISTORY_LIST';
export const FETCH_CALL_HISTORY_LIST_SUCCEED = 'FETCH_CALL_HISTORY_LIST_SUCCEED';
export const FETCH_CALL_HISTORY_LIST_FAILED = 'FETCH_CALL_HISTORY_LIST_FAILED';


// Customer Management

export const SEARCH_CUSTOMER_DATA = 'SEARCH_CUSTOMER_DATA';
export const SEARCH_CUSTOMER_DATA_FAILED = 'SEARCH_CUSTOMER_DATA_FAILED';
export const EXPORT_CUSTOMER_DATA = 'EXPORT_CUSTOMER_DATA';
export const IMPORT_CUSTOMER_DATA = 'IMPORT_CUSTOMER_DATA';


// Statistics / Analysis
export const FETCH_ANALYSIS_DATA = 'FETCH_ANALYSIS_DATA';
export const FETCH_ANALYSIS_DATA_SUCCEED = 'FETCH_ANALYSIS_DATA_SUCCEED';
export const FETCH_ANALYSIS_DATA_FAILED = 'FETCH_ANALYSIS_DATA_FAILED';

export const FETCH_STATISTICS_GRAPH = 'FETCH_STATISTICS_GRAPH';
export const FETCH_STATISTICS_GRAPH_SUCCEED = 'FETCH_STATISTICS_GRAPH_SUCCEED';
export const FETCH_STATISTICS_GRAPH_FAILED = 'FETCH_STATISTICS_GRAPH_FAILED';


//Telephone Counselling
export const FETCH_CALL = 'FETCH_CALL';
export const FETCH_CALL_SUCCEED = 'FETCH_CALL_SUCCEED';
export const FETCH_CALL_FAILED = 'FETCH_CALL_FAILED';

export const FETCH_CALL_LIST = 'FETCH_CALL_LIST';
export const FETCH_CALL_LIST_SUCCEED = 'FETCH_CALL_LIST_SUCCEED';
export const FETCH_CALL_LIST_FAILED = 'FETCH_CALL_LIST_FAILED';

export const DELETE_CALL = 'DELETE_CALL';
export const DELETE_CALL_SUCCEED = 'DELETE_CALL_SUCCEED';
export const DELETE_CALL_FAILED = 'DELETE_CALL_FAILED';

export const UNMOUNT_CALL_LIST = 'UNMOUNT_CALL_LIST';
export const UNMOUNT_CALL = 'UNMOUNT_CALL';


// TEXT MANAGER
export const FETCH_TEXT_MANAGER = 'FETCH_TEXT_MANAGER'
export const FETCH_TEXT_MANAGER_SUCCEED = 'FETCH_TEXT_MANAGER_SUCCEED'
export const FETCH_TEXT_MANAGER_FAILED = 'FETCH_TEXT_MANAGER_FAILED'
export const UNMOUNT_TEXT_MANAGER = 'UNMOUNT_TEXT_MANAGER';

// PREDEFINE_SMS
export const FETCH_PREDEFINE_SMS = 'FETCH_PREDEFINE_SMS'
export const FETCH_PREDEFINE_SMS_SUCCEED = 'FETCH_PREDEFINE_SMS_SUCCEED'
export const FETCH_PREDEFINE_SMS_FAILED = 'FETCH_PREDEFINE_SMS_FAILED'
export const UNMOUNT_PREDEFINE_SMS = 'UNMOUNT_PREDEFINE_SMS';

//SMS HISTORY
export const FETCH_SMSHISTORY = 'FETCH_SMSHISTORY';
export const FETCH_SMSHISTORY_SUCCEED = 'FETCH_SMSHISTORY_SUCCEED';
export const FETCH_SMSHISTORY_FAILED = 'FETCH_SMSHISTORY_FAILED';
export const UNMOUNT_SMSHISTORY = 'UNMOUNT_SMSHISTORY';

//SMS HISTORY V2
export const FETCH_SMSHISTORY_V2 = 'FETCH_SMSHISTORY_V2';
export const FETCH_SMSHISTORY_SUCCEED_V2 = 'FETCH_SMSHISTORY_SUCCEED_V2';
export const FETCH_SMSHISTORY_FAILED_V2 = 'FETCH_SMSHISTORY_FAILED_V2';
export const UNMOUNT_SMSHISTORY_V2 = 'UNMOUNT_SMSHISTORY_V2';

//REJECT LIST
export const FETCH_REJECTLIST = 'FETCH_REJECTLIST';
export const FETCH_REJECTLIST_SUCCEED = 'FETCH_REJECTLIST_SUCCEED';
export const FETCH_REJECTLIST_FAILED = 'FETCH_REJECTLIST_FAILED';
export const UNMOUNT_REJECTLIST = 'UNMOUNT_REJECTLIST';

//BULKSENDING
export const FETCH_SMS_BULKSENDING = 'FETCH_SMS_BULKSENDING';
export const FETCH_SMS_BULKSENDING_SUCCEED = 'FETCH_SMS_BULKSENDING_SUCCEED';
export const FETCH_SMS_BULKSENDING_FAILED = 'FETCH_SMS_BULKSENDING_FAILED';

// export const DELETE_CALL = 'DELETE_CALL';
// export const DELETE_CALL_SUCCEED = 'DELETE_CALL_SUCCEED';
// export const DELETE_CALL_FAILED = 'DELETE_CALL_FAILED';

export const FETCH_CALLS_BY_USER = 'FETCH_CALLS_BY_USER';
export const FETCH_CALLS_BY_USER_SUCCEED = 'FETCH_CALLS_BY_USER_SUCCEED';
export const FETCH_CALLS_BY_USER_FAILED = 'FETCH_CALLS_BY_USER_FAILED';

// export const FETCH_CALL_LIST = 'FETCH_CALL_LIST';
// export const FETCH_CALL_LIST_SUCCEED = 'FETCH_CALL_LIST_SUCCEED';
// export const FETCH_CALL_LIST_FAILED = 'FETCH_CALL_LIST_FAILED';

// export const UNMOUNT_CALL_LIST = 'UNMOUNT_CALL_LIST';
// export const UNMOUNT_CALL = 'UNMOUNT_CALL';

export const FETCH_TABLE_REPORT_SUCCESS = 'FETCH_TABLE_REPORT_SUCCESS';