import axios from "./axios";
const queryString = require("query-string");
export default {
  auth: {
    login: (credentials) => axios.post("auth/login", credentials),
    logout: (token) => axios.post("auth/logout", token),
    getCurrentUser: (access = "yes") => axios.get("auth/get-current-user?access="+access),
    disturbResponse : data => axios.post('auth/disturb-response',data),
    getUserLastAccess : () => axios.get('auth/get-user-last-access'),
  },
  user: {
    getCounselorBySaleLot: (data) =>
      axios.get(`users/get-counselors?${queryString.stringify(data)}`),
    getAllUsersBySaleLot: (data) =>
      axios.get(`users/get-users?${queryString.stringify(data)}`),
    createUser: (user) => axios.post("users", user),
    updateUser: (id, user) => axios.put(`users/update/${id}`, user),
    deleteUser: (id) => axios.delete("users/delete", id),

    // get user by sale lot or search by name
    getUsersBySaleLot: (id) => axios.get(`users?${id}`),
    changeDataUser : data => axios.post('/change-data-user',data),
  },
  telephone: {
    getReceivedCalls: (query) => axios.get(`telephone/received-calls?${query}`),
    getUnansweredCalls: (query) =>
      axios.get(`telephone/unanswered-calls-v2?${query}`),
    getSendingCalls: (query) =>
      axios.get(`telephone/sending-calls?${query}`),
    getAbsentCalls : (query) =>
        axios.get(`telephone/absent-calls-v2?${query}`),

    searchByCallNumber: query =>
        axios.get(`telephone/search-call-by-call-number?${query}`),
    // getInfoDetailByInfoMast: (id) =>
    //     axios.get(`telephone/info-detail/${id}`),

    getInfoDetailByInfoMast: (id) =>
        axios.get(`telephone/info-detail/${id}`),

    getReportTelePhone:  query =>
        axios.get(`telephone/v2-report-telephone?${query}`),
    getRecording : id =>  axios.get(`telephone/get-recording/${id}`),
    getCountCallBack : () => axios.get(`count-callback`)
    // getCallList: (query) =>
    //     axios.get(`telephone?${query}`),
    // getCallListByCustomerName: query =>
    //     axios.get(`telephone/search-call-by-name?${query}`),

    // sendingCall: query =>
    //     axios.post('telephone/sending-call', query),


  },

  districts: {
    getDistricts: () => axios.get("info-districts"),
    getDistrictsByCompany: (id, params) =>
      axios.get(`info-districts/get-district-by-company${id ? "/" + id : ""}`, {
        params,
      }),
    createDistrict: (district) => axios.post("info-districts", district),
    updateDistrict: (id, district) =>
      axios.put(`info-districts/${id}`, district),
    deleteDistrict: (id) => axios.delete("info-districts/delete", id),
  },

  call: {
    getCall: (id) => axios.get(`telephone/info-call/${id}`),
    createCall: (call) => axios.post("telephone/v2-store", call), //create call tu tao
    updateCall: (id, call) => axios.post(`telephone/v2/${id}`, call), // update call
		getCounselorChangeHistory: (id, param) => axios.post(`telephone/change-history/${id}`, param), // search change history for call
    updateCallOnlyManager: (id, call) => axios.post(`telephone/v2-manager/${id}`, call), // update call
    createCallNew : data => axios.post(`telephone/v2-create-call-new`, data), // tu dong tao cuoc goi khi co cuoc goi den
    findCallIDApi : (query) => axios.get(`/telephone/v2-find-call-id-api?${query}`),
    deleteCallV2 : id =>axios.get(`/telephone/v2-delete-call/${id}`), // xoa call v2
    updateCallDetail: (id, callDetails) =>
      axios.post(`telephone/update-call-details/${id}`, callDetails),
    deleteCall: (id) => axios.delete(`telephone/delete`, id),
    getCurentNumber: (number) =>
      axios.get(`/get-current-number?call_tel=${number}`),
    searchKeyCall : (search_key = '', company_id = null ) => axios.get(`/search-key?search_key=${search_key}&company_id=${company_id}`),
    getCallCheck : id => axios.get(`/get-call-check/${id}`)
  },
  callList: {
    getCallList: () => axios.get("call-list/get-all-list"),
    callHistory: (query) => axios.get(`call-list/call-history?${query}`),
    getCustomerBySaleLot: (query) => axios.get(`call-list?${query}`),
    deleteCustomer: (query) => axios.delete(`call-list/delete?${query}`),
    exportCustomerList: (query) =>
      axios.get(`call-list/export-customer-list?${query}`),
    callHistoryV2: (query) => axios.get(`call-list/call-history-v2?${query}`),
    callHistoryChartV2: (query) => axios.get(`call-list/call-history-chart-v2?${query}`),
		exportIVRDataList: (query) =>
			axios.get(`call-list/export-ivrdata-list?${query}`),
  },
  infoMast: {
    getInfoMast: () => axios.get("info-mast/get-info-mast-by-company"),
    getInfoMastByCompany: (company_id='', params={}) => {
      let apiEndPointString = 'info-mast/get-info-mast-by-company';
      if (company_id) apiEndPointString = apiEndPointString + `/${company_id}`
      return axios.get(apiEndPointString, { params })
    },
    createInfoMast: (infoMast) => axios.post("info-mast", infoMast),
    updateInfoMast: (id, infoMast) => axios.put(`info-mast/${id}`, infoMast),
    deleteInfoMast: (id) => axios.delete("info-mast/delete", id),
  },
  infoDetail: {
    createInfoDetail: (infoDetail) => axios.post("info-detail", infoDetail),
    updateInfoDetail: (id, infoDetail) =>
      axios.put(`info-detail/${id}`, infoDetail),
    deleteInfoDetail: (id) => axios.delete("info-detail/delete", id),
    getInfoDetailByInfoMast: (m_id, params) =>
      axios.get(`info-detail/get-detail-by-mast/${m_id}`, { params }),
  },
  company: {
    getCompanies: (params) => axios.get("info-company", { params }),
    createCompany: (company) => axios.post("info-company", company),
    updateCompany: (id, company) => axios.put(`info-company/${id}`, company),
    deleteCompany: (id) => axios.delete("info-company/delete", id),
    getCompaniesCheckAuth: () =>
      axios.get("/info-company/fetch-company-check-auth"), // function sms/mms
  },
  saleLots: {
    createSaleLots: (saleLots) => axios.post("info-sale-lot", saleLots),
    updateSaleLots: (id, saleLots) =>
      axios.put(`info-sale-lot/${id}`, saleLots),
    deleteSaleLots: (id) => axios.delete("info-sale-lot/delete", id),
    getSaleLotsByCompany: (company_id, params) =>
      axios.get(`info-sale-lot/sale-lot-by-company/${company_id}`, { params }),
  },
  notice: {
    send: (params) => axios.post("notice", params),
    check: () => axios.get("notice", {}),
	},
  articles: {
		// 자유게시판
    createFreeArticle: (articles) => axios.post("freeboard", articles),
    updateFreeArticle: (id, articles) =>
      axios.put(`freeboard/${id}`, articles),
    replyFreeArticle: (id, articles) =>
      axios.post(`freeboard/reply`, articles),
    deleteMultiArticle: (article_id) =>
      axios.post(`freeboard/multi`, article_id),
    hit: (id) =>
      axios.get(`freeboard/hit/${id}`),
    deleteFreeArticle: (id) => axios.delete("freeboard/delete", id),
    getFreeBoardListByCompany: (company_id, params) =>
      axios.post(`freeboard/list`,  params),
		// 건의게시판
		createReqArticle: (articles) => axios.post("reqboard", articles),
    updateReqArticle: (id, articles) =>
      axios.put(`reqboard/${id}`, articles),
    replyReqArticle: (id, articles) =>
      axios.post(`reqboard/reply`, articles),
    reqHit: (id) =>
      axios.get(`reqboard/hit/${id}`),
    deleteReqArticle: (id) => axios.delete("reqboard/delete", id),
    getReqBoardListByCompany: (company_id, params) =>
      axios.post(`reqboard/list`,  params),
    deleteMultiReqArticle: (article_id) =>
      axios.post(`reqboard/multi`, article_id),
  },
  infoLine: {
    getInfoLine: () => axios.get("info-line/get-lines-by-company"),
    getInfoLineByCompany: (data) =>
      axios.get(
        `info-line/get-lines-by-company?${queryString.stringify(data)}`
      ),
    createInfoLine: (infoLine) => axios.post("info-line", infoLine),
    updateInfoLine: (id, infoLine) => axios.put(`info-line/${id}`, infoLine),
    deleteInfoLine: (id) => axios.delete("info-line/delete", id),
    getInfoLineByLineNo: (info_no) =>
      axios.get(`info-line/get-lines-by-line-no/${info_no}`),
    getInfoLineList: () => axios.get("info-line/get-lines-by-company-list"),
    getInfoLineListByCompany: (companyId) =>
      axios.get(`info-line/get-lines-by-company-list/${companyId}`)
    
  },
  customerManagement: {
    getSearchCustomerData: (query) =>
      axios.get(`customer-manage/customer-data?${query}`),
    getSearchCounsellingData: (query) =>
      axios.get(`customer-manage/counselling-data?${query}`),
    getSearchIVRData: (query) =>
      axios.get(`customer-manage/ivr-data?${query}`),
    getExportCustomerData: (query) =>
      axios.get(`customer-manage/export-customer-data?${query}`),
    getExportCounsellingData: (query) =>
      axios.get(`customer-manage/export-counselling-data?${query}`),
    getExportIVRData: (query) =>
      axios.get(`customer-manage/export-ivr-data?${query}`),
    getSampleDownloadExcel: (query) => axios.get(`customer-manage/sample-download?header=${query}`),
    import: (data) =>
      axios.post(`customer-manage/import-excel`, data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      }),
    importExcelCustomer : (data) => {
      return axios.post(`customer-manage/import-excel-v2`, data);
    }
  },
  statistics: {
    getAnalysisData: (query) => axios.get(`statistics/analysis?${query}`),
    getStatisticsData: (query) => axios.get(`statistics/graph?${query}`),
    getStatisticsDataPie: (query) => axios.get(`statistics/graphPie?${query}`),
    getAllGraph: (query) => axios.get(`statistics/allGraph?${query}`),
    getExportAnalysis: (query) =>
      axios.get(`statistics/analysis/export-excel?${query}`),
    getDailyReport: (data) => axios.get(`statistics/daily-report?${queryString.stringify(data)}`),
    createDailyReportNote: (data) =>
      axios.post("statistics/daily-report-note", data),
    getNoteReport: (data) => axios.get(`statistics/get-note-report?${queryString.stringify(data)}`),
		getDailyItemStatus: (data) => axios.get(`statistics/get-daily-item-report?${queryString.stringify(data)}`),
  },
  dashboard: {
    getIndex: () => axios.get("dashboard"),
  },
  errorTracking: {
    errorFrontEndReport: (error) => axios.post("error-tracking", error),
  },

  mgm: {
    login: (data) => {
      let URL =
        process.env.NODE_ENV === "production"
          ? `${process.env.REACT_APP_API_MGM_PROD}`
          : `${process.env.REACT_APP_API_MGM_DEV}`;
      return axios({
        method: "post",
        url: `${URL + "/login"}`,
        data: data,
      });
    },
    fetchUsersManager: (query) => {
      return axios.get(`mgm-admin/fetch-user-manager?${query}`);
    },
    fetchCompanyPlacementsSearch: () => {
      return axios.get(`mgm-admin/fetch-company-placement`);
    },
    fetchPlacementRegistered: (query) => {
      return axios.get(`mgm-admin/fetch-placement-registered?${query}`);
    },
    deletePlacement: (id) => {
      return axios.post(`mgm-admin/delete-placement/${id}`);
    },
    fetchPlacementUser: (query) => {
      return axios.get(`mgm-admin/fetch-placement?${query}`);
    },
    deleteUserPlacementApproved: (id) => {
      return axios.post(`mgm-admin/delete-user-placement-approved/${id}`);
    },
  },

	bulkSending: {
    getSendList: (params) =>
      axios.get(`sms-send/sms-sendList`, { params }),
    getFilterList: (data) =>
      axios.post(`sms-send/sms-filterList`, data),
		getSMSHeader: (params) =>
			axios.get(`sms-send/sms-header`, { params }),
		getSMSConfig: (params) =>
      axios.get(`sms-send/sms-config`, { params }),
		setSMSConfig: (params) =>
      axios.post(`sms-send/sms-config-save`, params),
		getSMSSendList: (params) =>
      axios.get(`sms-send/sms-send-list`, { params }),
		setSMSControl: (params) =>
      axios.post(`sms-send/sms-config-control`, params),
		createSMSSendList: (params) =>
      axios.post(`sms-send/sms-send-list-create`, params),
		deleteSMSSendList: (params) =>
      axios.post(`sms-send/sms-send-list-delete`, params),
		SendMMSV2: (params) =>
      axios.post(`sms-send/sms-send-v2`, params),
		SendSMSDirect: (params) =>
      axios.post(`sms-send/sms-send-direct`, params),
	},

  //Reject List
  rejectList: {
    getRejectList: (query) =>
      axios.get(`reject-list?${queryString.stringify(query)}`),
    exportExcel: (query) =>
      axios.get(`reject-list/exportExcel?${queryString.stringify(query)}`),
		deleteRow: (param)  =>
      axios.get(`reject-list/delete-list?${queryString.stringify(param)}`),
  },

  //SMS History
  smsHistory: {
    getSMSHistory: (query) =>
      axios.get(`sms-history?${queryString.stringify(query)}`),
    getSMSBulkSending: (params) =>
      axios.get(`sms-history/sms-bulkSending`, { params }),
    exportExcel: (query) =>
      axios.get(`sms-history/exportExcel?${queryString.stringify(query)}`),
    getSMSHistoryV2: (query) =>
      axios.get(`sms-history-v2?${queryString.stringify(query)}`),
    exportExcelV2: (query) =>
      axios.get(`sms-history-v2/exportExcel?${queryString.stringify(query)}`),
  },

  textManager: {
    fetchTextManager: (id, params) => {
      return axios.get(`sms-mms/fetch-text-manager?lost_id=${id}`, { params });
    },
    autoSend: (data) => {
      return axios.post(`sms-mms/auto-send`, data);
    },
    autoSendAbsentCall: (data) => {
      return axios.post(`sms-mms/auto-send-absent-call`, data);
    },
    autoSendCreateCall: (data) => {
      return axios.post(`sms-mms/auto-send-create-call`, data);
    },
    creteTextManager: (data) => {
      return axios.post(`sms-mms/create-text-manager`, data);
    },
    deleteTextManager: (id) => {
      return axios.post(`sms-mms/delete-text-message/${id}`);
    },
    findTextManager: (id) => {
      return axios.get(`sms-mms/find-text-manager/${id}`);
    },
    deleteImageDetail: (id) => {
      return axios.post(`sms-mms/remove-image-message/${id}`);
    },
    updateTextManager: (id, data) => {
      return axios.post(`sms-mms/update-text-manager/${id}`, data);
    },
    fetchAutoSending: (id) => {
      return axios.get(`sms-mms/auto-sending?lost_id=${id}`);
    },
    createDirect: (data) => {
      return axios.post(`sms-mms/create-direct`, data);
    },
    fetchDirect: () => {
      return axios.get(`sms-mms/fetch-direct`);
    },
    createMessageHistory: (data) => {
      return axios.post(`sms-mms/create-message-history`, data);
    },
    removeOneImage: (id) => {
      return axios.post(`sms-mms/remove-image-message/${id}`);
    },
    importExcel: (data) => {
      return axios.post(`sms-mms/import-bulk-sending`, data);
    },
    getInfoSaleLot: (id) => {
      return axios.get(`info-sale-lot/get-sale-lot/${id}`);
    },
    getSaleLotsOfUser: () => {
      return axios.get('get-sale-lots-of-user');
    },

    createDirectV2 : data => axios.post(`sms-mms/create-direct-v2`, data),
    updateDirectV2 : data => axios.post(`sms-mms/update-direct-v2`, data)

  },

  predefinesms: {
		send: (item) => {
			return axios.post(`sms-mms/send-predefine-sms`, item);
		},
    fetchPredefineSMS: (id, params) => {
      return axios.get(`sms-mms/fetch-predefine-sms?lots_id=${id}`, { params });
    },
    cretePredefineSMS: (data) => {
      return axios.post(`sms-mms/create-predefine-sms`, data);
    },
    deletePredefineSMS: (id) => {
      return axios.post(`sms-mms/delete-predefine-sms/${id}`);
    },
    findPredefineSMS: (id) => {
      return axios.get(`sms-mms/find-predefine-sms/${id}`);
    },
    updatePredefineSMS: (id, data) => {
      return axios.post(`sms-mms/update-predefine-sms/${id}`, data);
    },
    getInfoSaleLot: (id) => {
      return axios.get(`info-sale-lot/get-sale-lot/${id}`);
    },
    getConfigSaleLot: (id) => {
      return axios.get(`info-sale-lot/get-config-sale-lot/${id}`);
    },
    setConfigSaleLot: (data) => {
      return axios.post(`info-sale-lot/set-config-sale-lot`, data);
    }
  },

  exportV2 : {
    outgoingCall : query => axios.get(`/export-excel-outgoing-call?${query}`),
    callCounselors : query => axios.get(`/call-counselors?${query}`),
    callOfDay : query => axios.get(`/call-of-day?${query}`),
  },


  testOffline: {
    testOffline: () => axios.get('/test-offline?test-offline=true'),
  },


  logging: {
    loggingCallNotify: data => axios.post('logging-notify', data)
  }
};
