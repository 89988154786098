import $ from 'jquery';
import ReconnectingWebSocket from './../assets/js/reconnecting-websocket';

var csta_url;
var resultCallback;
var ws;
var rest_api_root;

var firebaseServer = "https://sky-rtc-f8169.firebaseio.com";
var url_host = 'https://rtc.goodfone.co.kr';

export function start_websocket_if(websock_url, accessToken) {
    var ReceivedPacket = '';

    if (ws != undefined) {

        resultCallback('SIGN-IN', {access_token: accessToken});
        return;
    }

    csta_url = websock_url;

    ws = new ReconnectingWebSocket(csta_url);
    // ws.timeoutInterval = 7000;
    ws.debug = true;
    ws.maxReconnectAttempts = 1000;

    ws.onopen = function () {
        resultCallback('SIGN-IN', {access_token: accessToken});
    };

    ws.onmessage = function (evt) {

        ReceivedPacket += evt.data;

        if (ReceivedPacket.length <= 8) return;

        while (1) {

            var index = ReceivedPacket.indexOf('\r\n\r\n');

            if (index == -1) {
                return;
            }

            //console.log('index = ' + index + ', str length = ' + ReceivedPacket.length);


            var json_data = ReceivedPacket.substring(0, index + 4);

            var NewReceivedPacket = ReceivedPacket.slice(index + 4, ReceivedPacket.length);

            ReceivedPacket = NewReceivedPacket;

            var jsonObj = JSON.parse(json_data);
            resultCallback(jsonObj.event_type, jsonObj.content);
        }
    };

    ws.onclose = function (e) {
        console.log('Socket is closed. Reconnect will be attempted in 1 second.');
        const { reason } = e
        // websocket is closed.
        ws.close();
        ws = null;
        if(reason !== 'terminate')
        {
            setTimeout(function() {
                resultCallback('ws-close',e)
            }, 1000);
        }

    };

    ws.refresh = function() {
        console.log('ws refresh')
    }

    ws.onerror = function(err) {
        console.log(err,'err onerror')
        console.error('Socket encountered error: ', err.message, 'Closing socket');
        ws.close();
    };
}

export function getAccessPermission(id, password, websock_url, rest_url, rstCallback) {
    // console.log(rstCallback, 'rstCallback');
    resultCallback = rstCallback;
    rest_api_root = rest_url;
    var objectGetAccessPermission = {id, password, websock_url, rest_url, rstCallback}
    return $.ajax({
        type: "POST",
        url: rest_api_root + "/post/extraService/access_permission.json", // 2024-03-20 get_access_permission.json -> post/extraService/access_permission.json
        data: '{"user_id" : "' + id + '", "password" : "' + password + '", "item_cod" : "201334"}', // 2024-03-20 코드 고정 (분양 CRM)
        //data: '{"user_id" : "' + id + '", "password" : "' + password + '"}', // 2024-03-20 변경전
        crossDomain: true,
        dataType: "json",
        success: function (result) {
            start_websocket_if(websock_url, result.access_token,objectGetAccessPermission);
        },
        error: function (error) {
            // if (error.readyState == 0) {
            //     $('#main_loading_progress').hide();
            //     alert('네트워크가 끊겼습니다.');
            // } else {
            //     $('#main_loading_progress').hide();
            //     alert('로그인에 실패 하였습니다.\n아이디 또는 비밀번호를 확인하세요.');
            // }
        }
    });
}



export function GetCallHistory(id, deviceID, start, end, limit, company, RemoteNumber, CallType, accessToken) {

    var param = {

        call_start_time: start,
        call_end_time: end,
        remote_device_id: RemoteNumber,
        call_type: CallType,
        local_device_id: deviceID,
        sLimit: "0",
        eLimit: limit,
        Company: company,
        userid: id,
        oauth_token: accessToken
    };

    $.ajax({
        type: "POST",
        url: rest_api_root + "/get_call_history.json",
        data: JSON.stringify(param), //'{"user_id" : "'+id+'", "password" : "'+password+'"}',
        crossDomain: true,
        dataType: "json",
        success: function (result) {
            console.log('sms thanh cong')
            if (resultCallback != undefined)
                resultCallback('GetCallHistory', result);
        },
        error: function (error) {

            if (resultCallback != undefined)
                resultCallback('ErrorGetCallHistory', error);
        }
    });
}

export function GetSMSHistory(deviceObject, limit, accessToken) {

    var param = {

        local_device_id: deviceObject,
        sLimit: "0",
        eLimit: limit,
        oauth_token: accessToken
    }

    $.ajax({
        type: "POST",
        url: rest_api_root + "/get_sms_history.json",
        data: JSON.stringify(param), //'{"user_id" : "'+id+'", "password" : "'+password+'"}',
        crossDomain: true,
        dataType: "json",
        success: function (result) {

            console.log('result = ' + result);

            if (resultCallback != undefined)
                resultCallback('GetSMSHistory', result);
        },
        error: function (error) {

            if (resultCallback != undefined)
                resultCallback('ErrorGetSMSHistory', error);
        }
    });
}

export function SendSMS(user_id, deviceObject, peers, message, accessToken) {
    var param = {
        user_id: user_id,
        deviceObject: deviceObject,
        to_user: peers,
        msg: message,
        oauth_token: accessToken
    };

    $.ajax({
        type: "POST",
        url: rest_api_root + "/send_sms.json",
        data: JSON.stringify(param), //'{"user_id" : "'+id+'", "password" : "'+password+'"}',
        crossDomain: true,
        dataType: "json",
        success: function (result) {
            if (resultCallback != undefined){
                resultCallback('SendSMS', result);
                console.log('SEND SMS')

            }
        },
        error: function (error) {
            if (resultCallback != undefined)
                resultCallback('ErrorSendSMS', error);
        }
    });
}

export function SendSMSBulk(user_id, deviceObject, peers, message, accessToken) {
    return new Promise((resolve,reject) => {
        var param = {
            user_id: user_id,
            deviceObject: deviceObject,
            to_user: peers,
            msg: message,
            oauth_token: accessToken
        };

        $.ajax({
            type: "POST",
            url: rest_api_root + "/send_sms.json",
            data: JSON.stringify(param), //'{"user_id" : "'+id+'", "password" : "'+password+'"}',
            crossDomain: true,
            dataType: "json",
            success: function (result) {
                if (resultCallback != undefined){
                    resultCallback('SendSMS', result);
                    console.log('SEND SMS SUCCESS')
                    resolve(result);
                    // return 'succees'

                }
            },
            error: function (error) {
                if (resultCallback != undefined)
                    resultCallback('ErrorSendSMS', error);
                // reject(error);
            }
        });
    })


}



export function SendMMS(data) {
    $.ajax({
        type: "POST",
        url: rest_api_root + "/send_mms.json",
        data:  JSON.stringify(data),
        crossDomain: true,
        dataType: "json",
        processData: false,
        async: true,
        success: function (result) {
            if (resultCallback != undefined)
            {
                console.log(result,'API SEND MMS')
                resultCallback('SendMMS', result);
            }

        },
        error: function (error) {
            console.log('that bai ',error)
            if (resultCallback != undefined)
                resultCallback('ErrorSendSMS', error);
        }
    });

    /** example data **/
    // data = {
    //     "user_id" : "아톡 사용자 ID",
    //     "deviceObject" : "아톡 전화번호",
    //     "to_user" : "SMS 메시지 수신번호”,
    //     "msg" : "전송할 메시지",
    //     "oauth_token" : "access token"
    //     "attach1":{
    //         "origin_name":"원본파일명 ex) 10.jpg",
    //         "upload_file":"서버에 저장할 unique 한 파일명 ex) yourid_20181101162334789.jpg",
    //         "upload_path":"https://rtc.goodfone.co.kr/mms/yourid_20181101162334789.jpg ",
    //         "type":"image"
    //         },
    //         "attach2": {
    //             ......
    //         },
    //         "attach3": {
    //             ......
    //         }
    //     }
}





export function SendMMSBulk(data) {
    return new Promise((resolve,reject) => {
        $.ajax({
            type: "POST",
            url: rest_api_root + "/send_mms.json",
            data:  JSON.stringify(data),
            crossDomain: true,
            dataType: "json",
            processData: false,
            async: true,
            success: function (result) {
                if (resultCallback != undefined)
                {
                    console.log(result,'API SEND MMS')
                    resultCallback('SendMMS', result);
                    resolve(result);
                }

            },
            error: function (error) {
                console.log('that bai ',error)
                if (resultCallback != undefined)
                    resultCallback('ErrorSendSMS', error);
            }
        });
    })
}


export function cstaMonitorStart(monitorDeviceObject, accessToken) {

    var json = {
        uacsta_cmd: "uacsta_monitorstart",
        deviceObject: monitorDeviceObject,
        oauth_token: accessToken
    };

    ws.send(JSON.stringify(json) + '\r\n\r\n');
}

export function cstaMonitorStop(monitorDeviceObject, accessToken) {

    // do nothing

    //ws.close();

    var json = {
        uacsta_cmd: "uacsta_monitorstop",
        deviceObject: monitorDeviceObject,
        oauth_token: accessToken
    };

    ws.send(JSON.stringify(json) + '\r\n\r\n');
}

export function cstaMakeCall(deviceObject, calledDirectoryNumber, accessToken) {

    var json = {
        uacsta_cmd: "uacsta_makecall",
        callingDevice: deviceObject,
        calledDirectoryNumber: calledDirectoryNumber,
        oauth_token: accessToken
    };

    ws.send(JSON.stringify(json) + '\r\n\r\n');
}

export function cstaClearConnection(deviceObject, callID, accessToken) {

    var json = {
        uacsta_cmd: "uacsta_clearconnection",
        deviceID: deviceObject,
        callID: callID,
        oauth_token: accessToken
    };

    ws.send(JSON.stringify(json) + '\r\n\r\n');
}

export function cstaAnswerCall(deviceObject, callID, accessToken) {

    var json = {
        uacsta_cmd: "uacsta_answercall",
        deviceID: deviceObject,
        callID: callID,
        oauth_token: accessToken
    };

    ws.send(JSON.stringify(json) + '\r\n\r\n');
}

export function cstaHoldCall(deviceObject, callID, accessToken) {

    var json = {
        uacsta_cmd: "uacsta_holdcall",
        deviceID: deviceObject,
        callID: callID,
        oauth_token: accessToken
    };

    ws.send(JSON.stringify(json) + '\r\n\r\n');
}

export function cstaRetrieveCall(deviceObject, callID, accessToken) {

    var json = {
        uacsta_cmd: "uacsta_retrievecall",
        deviceID: deviceObject,
        callID: callID,
        oauth_token: accessToken
    };

    ws.send(JSON.stringify(json) + '\r\n\r\n');
}

export function cstaConsultationCall(deviceObject, primaryCallID, consultedDevice, uui, accessToken) {

    var json = {
        uacsta_cmd: "uacsta_consultationcall",
        deviceID: deviceObject,
        callID: primaryCallID,
        consultedDevice: consultedDevice,
        uui: uui,
        oauth_token: accessToken
    };

    ws.send(JSON.stringify(json) + '\r\n\r\n');
}

export function cstaTransferCall(deviceObject, heldCallID, activeCallID, transferringDeviceID, accessToken) {

    //console.log('firstRemoteDeviceID : ' + transferringDeviceID);
    var json = {
        uacsta_cmd: "uacsta_transfercall",
        deviceID: deviceObject,
        heldCallID: heldCallID,
        activeCallID: activeCallID,
        transferringDeviceID: transferringDeviceID,
        oauth_token: accessToken
    };

    ws.send(JSON.stringify(json) + '\r\n\r\n');
}

export function cstaReconnectCall(deviceObject, heldCallID, activeCallID, accessToken) {

    var json = {
        uacsta_cmd: "uacsta_reconnectcall",
        deviceID: deviceObject,
        heldCallID: heldCallID,
        activeCallID: activeCallID,
        oauth_token: accessToken
    };

    ws.send(JSON.stringify(json) + '\r\n\r\n');
}

export function cstaAlternateCall(deviceObject, heldCallID, activeCallID, accessToken) {

    var json = {
        uacsta_cmd: "uacsta_alternatecall",
        deviceID: deviceObject,
        heldCallID: heldCallID,
        activeCallID: activeCallID,
        oauth_token: accessToken
    };

    ws.send(JSON.stringify(json) + '\r\n\r\n');
}

export function cstaDeflectCall(deviceObject, activeCallID, newDestination, accessToken) {

    var json = {
        uacsta_cmd: "uacsta_deflectcall",
        deviceID: deviceObject,
        callID: activeCallID,
        newDestination: newDestination,
        oauth_token: accessToken
    };

    ws.send(JSON.stringify(json) + '\r\n\r\n');
}

export function cstaSingleStepTransferCall(deviceObject, activeCallID, transferredTo, accessToken) {

    var json = {
        uacsta_cmd: "uacsta_singlesteptransfercall",
        deviceID: deviceObject,
        callID: activeCallID,
        transferredTo: transferredTo,
        oauth_token: accessToken
    };

    ws.send(JSON.stringify(json) + '\r\n\r\n');
}


export function cstaRecordStart(deviceObject, activeCallID, businessCD, accessToken) {

    var json = {
        uacsta_cmd: "uacsta_recordstartmessage",
        deviceID: deviceObject,
        callID: activeCallID,
        business_cd: businessCD,
        oauth_token: accessToken
    };

    ws.send(JSON.stringify(json) + '\r\n\r\n');
}

export function cstaRecordStop(deviceObject, activeCallID, accessToken) {

    var json = {
        uacsta_cmd: "uacsta_recordstopmessage",
        deviceID: deviceObject,
        callID: activeCallID,
        oauth_token: accessToken
    };

    ws.send(JSON.stringify(json) + '\r\n\r\n');
}

export function cstaSetForward(deviceObject, forwardType, parkNum, accessToken) {

    var json = {
        uacsta_cmd: "uacsta_setforwarding",
        deviceID: deviceObject,
        mode: forwardType,
        park: parkNum,
        oauth_token: accessToken
    };

    ws.send(JSON.stringify(json) + '\r\n\r\n');
}

export function cstaSetDoNotDisturb(deviceObject, dndType, accessToken) {
    var json = {
        uacsta_cmd: "uacsta_setdnd",
        deviceID: deviceObject,
        mode: dndType,
        oauth_token: accessToken
    };

    return ws.send(JSON.stringify(json) + '\r\n\r\n');
}

export function cstaSetFeature(deviceObject, agentID, groupID, status,substatus, accessToken) {

    var json = {
        uacsta_cmd: "uacsta_setfeature",
        deviceID: deviceObject,
        agentID: agentID,
        groupID: groupID,
        agentStatus: status,
        agentStatusSub : substatus,
        oauth_token: accessToken
    };

    ws.send(JSON.stringify(json) + '\r\n\r\n');
}


export function changeMyStatusForCsta(eventStatus,myInfo){
    // console.log(rest_api_root,'rest_api_root')
    $.ajax({
        type:"post",
        url : url_host+":9852/patch",
        data:JSON.stringify({"url": firebaseServer+"/USERS/"+myInfo.company+"/"+myInfo.userid,"json":{"presence" : eventStatus}, "oauth_token" : myInfo.access_token}),
        crossDomain : true,
        dataType:"json",
        success:function(result){
            // console.log(result,'resutl changeMyStatusForCsta')
            // myInfo.presence = eventStatus;
        },error:function(error){
            // console.log(error,'error changeMyStatusForCsta')
        }
    });
}

export function closeWebsocket() {
    if(ws != undefined) ws.close(1000, 'terminate')
}
