import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Helmet } from 'react-helmet';
import { NavLink, Link } from "react-router-dom";
import { Modal, ModalHeader, ModalFooter, ModalBody, Button } from 'reactstrap';
import RichTextEditor from 'react-rte';

class UpdateModal extends Component {

    constructor(props) {
        super(props);
    }


    componentDidMount() {

		};

    render() {

        const { articleForm } = this.props;
				console.log('articleForm:',articleForm);

        let article_contents = articleForm && articleForm.article_contents ? articleForm.article_contents : '';

        return (
            <Fragment>
                <Modal
                    isOpen={this.props.isOpen}
                    toggle={this.props.toggle}
                    modalClassName='modal_add_preferences'
                    className="modal-dialog modal-dialog-scrollable"
                    contentClassName="modal-content"
                >
                    <div className="modal-header">
                        <h5 className="modal-title">
                            <FormattedMessage id="BOARD.edit" defaultMessage="Update" />
                        </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.props.toggle}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>

                    <div className="modal-body">
                        <div className="box_add_ppreferences_1">
                            {/* <div className="box_group_control">
                                <div className="heading_title">Company</div>
                                <div className="group_control_in">
                                    <input
                                        type="text"
                                        className="form_control"
                                        name="company_name"
                                        value={currentCompany?.company_name}
                                        onChange={this.props.handleChange}
                                        readOnly
                                    />
                                </div>
                            </div> */}

                            <div className="box_group_control">
                                <div className="heading_title">
                                    <FormattedMessage id="BOARD.title" defaultMessage="Title" />
                                </div>
                                <div className="group_control_in">
                                    <input
                                        type="text"
																				maxLength={50}
                                        className="form_control"
                                        name="article_title"
                                        value={articleForm?.article_title}
                                        onChange={this.props.handleChangeInput}
                                    />
                                </div>
                            </div>
                            <div className="box_group_control">
                                <div className="heading_title">
																	<table style={{width: "120px"}}><tr><td><FormattedMessage id="BOARD.private_article" defaultMessage="Private" /></td>
																	<td>
																	<input
																			type="checkbox"
																			className="form_control"
																			style={{width:"32px",height:"32px",margin:"15px",transform:"scale(1.0)"}}
																			name="secret"
																			onChange={ (e) =>this.props.handlePrivateOption(e.target.checked) }
																			checked={ articleForm?.secret == 1 || articleForm?.secret == true ? true : false }                                    
																	/>
																	</td></tr></table>
                                </div>
                            </div>
                            <div className="box_group_control">
                                <div className="heading_title">
                                    <FormattedMessage id="BOARD.content" defaultMessage="Contents" /> {this.props.curLength} Bytes
                                </div>
                                <div className="group_control_in">
                                    <textarea
                                        type="text"
                                        className="form_control"
																				style={{height:'600px'}}
                                        name="content"
                                        value={articleForm?.article_contents}
                                        onChange={this.props.handleChangeTextarea}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal-footer">
                        <button onClick={this.props.toggle}
                            type="button"
                            className="btn btn-outline-dark "
                            data-dismiss="modal"
                            aria-label="Close">
                            <FormattedMessage
                                id="dashboard.Cancel"
                                defaultMessage="Cancel" />
                        </button>
                        <button onClick={this.props.onUpdate}
                            type="button"
                            className="btn btn-dark btn_add"
                            data-dismiss="modal"
                            aria-label="Close">
                            <FormattedMessage
                                id="dashboard.Save"
                                defaultMessage="Save" />
                        </button>
                    </div>
                </Modal>

            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateModal)
